import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './PesajeForm';

export default class EditarPesoProducto extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        peso_manual: true
    };
    componentWillMount(){
        const {item} = this.props;
        const paramid = this.props.match.params.id;   
        this.props.getProducto(paramid); 
    }
    componentDidMount(){
        const conf = this.props.configuraciones;
        const me = this.props.me;
        if (me) {
            if (conf) {
                if (me.acopio) {
                    if (conf.peso_ingreso_manual_acopio === false) {
                        window.interval = setInterval(() => this.props.pesosBascula(), 500)
                        this.setState({peso_manual: false});
                    }
                } else {
                    if (conf.peso_ingreso_manual === false) {
                        window.interval = setInterval(() => this.props.pesosBascula(), 500)
                        this.setState({peso_manual: false});
                    }
                }
            }
        }
    }

    componentWillUnmount(){
        clearInterval(window.interval)
    }
    render() {
        const { loader, producto, configuraciones } = this.props;
        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="recepciones_activo"
                    titulo="BÁSCULA"
                    subtitulo="Recepción"
                    col="12">
                    <LoadMask loading={loader} blur
                        title="ESPERE..." message="Se están completando las operaciones"
                    >
                        <Form datos={producto} {...this.props} peso_manual={this.state.peso_manual}/>
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
