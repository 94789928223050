import React, { Fragment } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { actions } from "../../../../../redux/modules/catalogo_proveedores";

/**
 * Se tendran los siguiente filtros:
 * Estado: si es activo o inactivo
 */
export const Filtros = (props) => {
    const { loader, generarReporte, obtener_data_excel, loader_excel } = props;

    const {
        formState: { errors },
        control,
        register,
        handleSubmit,
    } = useForm();

    const estado_propietario_options = [
        { value: true, label: "ACTIVO" },
        { value: false, label: "INACTIVO" },
    ];

    const onSubmit = (data) => generarReporte(data);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="pb-3">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="form-group">
                        <label htmlFor="anio">Estado</label>
                        <Controller
                            name="estado_propietario"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                                <Select
                                    {...field}
                                    options={estado_propietario_options}
                                    placeholder="Todos"
                                    noOptionsMessage={() => "No hay opciones"}
                                    isClearable
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="row justify-content-md-end">
                <div className="col-12 col-md-6 ">
                    <div className="row justify-content-md-end ">
                        <div className="col-16 col-md-4">
                            <button
                                type="submit"
                                className="btn btn-primary w-100 text-nowrap "
                            >
                                Generar reporte
                            </button>
                        </div>
                        <div className="col-16 col-md-4">
                            <button
                                type="button"
                                className="btn btn-primary w-100 text-nowrap "
                                onClick={(e) => {
                                    e.preventDefault();
                                    obtener_data_excel();
                                }}
                                disabled={loader_excel}
                            >
                                {loader_excel ? (
                                    <Fragment>
                                        Obteniendo excel...{" "}
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </Fragment>
                                ) : (
                                    <Fragment>Generar excel</Fragment>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const mapStateToProps = (state) => ({
    ...state.cdp,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filtros);
