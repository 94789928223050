import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './IngresoForm';
import EditarIngresoForm from './IngresoForm';
import { api } from '../../../../../utility/api';

export default class IngresoCrear extends Component {
  state = {
      editar: false,
      peso_manual: true
  };
    componentWillMount(){
        this.props.initialLoad();
        this.props.cleanForm();
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
            this.setState({editar: true});
        }
    }

    componentDidMount(){
        const me = this.props.me;
        if (me) {
            if (me.acopio) {
                if (me.peso_ingreso_manual_acopio === false) {
                    console.log("Se esta tomando el peso con bascula")
                    this.interval = setInterval(() => this.props.pesosBascula(), 500)
                    this.setState({peso_manual: false});
                }
            } else {
                if (me.peso_ingreso_manual === false) {
                    console.log("Se esta tomando el peso con bascula")
                    this.interval = setInterval(() => this.props.pesosBascula(), 500)
                    this.setState({peso_manual: false});
                }
            }
        }
    }

    componentWillUnmount(){
        clearInterval(this.interval)
    }

    actualizar = (data) =>{
        this.props.editar(this.props.match.params.id, data)
    };
    /**
     * Crea y recupera el nuevo registro, y redirecciona a la página de impresión de la boleta, siempre que se haya creado.
     * Modifica el historial para que al hacer un 'back' se rediriga a '/ingresos'
     */
    crearYRecuperar = async (data) => {
        await this.props.crearYRecuperar(data);
        const item = this.props.item;
        if (item.id) {
            this.props.history.replace(`/ingresos`);
            this.props.history.push(`/ingreso/${item.id}/boleta_ingreso`);
        }
    }
    render() {
        const { updateData, loader, crear, configuraciones, establecerKilogramosIngreso } = this.props;
        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="recepciones_activo"
                    titulo="RECEPCIÓN"
                    subtitulo="Nuevo"
                    col="12">
                    <LoadMask loading={loader} blur>
                        {
                            this.state.editar ?
                                <EditarIngresoForm
                                    onSubmit={this.actualizar}
                                    updateData={updateData}
                                    editando={true}
                                    peso_manual={this.state.peso_manual}
                                    />
                                :
                                <Form onSubmit={this.crearYRecuperar} peso_manual={this.state.peso_manual} {...this.props}/>
                        }
                    </LoadMask>
                </CardForm>
        </div>
        )
    }
}
