import React, { Fragment } from "react";
import CardEmpty from "Utils/Cards/CardEmpty";
import { TableHeaderColumn } from "react-bootstrap-table";
import moment from "moment/moment";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import Grid from "../../../Utils/Grid";
import {
    RenderKilogramo,
    RenderCurrency,
    RenderNumber,
    RenderDollar,
} from "../../../Utils/renderField/renderReadField";
import {
    FLETE_EXTERNO_COSTO_POR_KG,
    FLETE_INTERNO_COSTO_POR_KG,
    SOLIDO,
    LATEX,
    TIPO_COSTO_FLETE,
    FLETE_EXTERNO_COSTO_FIJO,
} from "../../../../../utility/constants";
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts";
import { renderLegend, renderLegendCustom, renderLegendReporteAnual } from "../components";

const GENERAL = {
    [SOLIDO]: "SOLIDO",
    [LATEX]: "LATEX",
};
/**
 * Especifica cuál de todos los kg. guardados en el DetalleOrdenCompra se utilizará como base para el cálculo del costo_flete.
 */
let KILOS_RECEPCION = "kilos_humedos";

const renderTooltip = (props) => {
    const { active, payload, label } = props;
    if (active && payload) {
        return (
            <CardEmpty noShadow>
                <div className="d-flex flex-column mb-2">
                    <span className="mb-2">Fecha: {label}</span>
                    {payload.map((item, index) => {
                        const dataKey = item.dataKey.replace("y_value_", "");
                        const kilos_recepcion =
                            item.payload[dataKey].kilos_recepcion;
                        const totalQ_por_kg =
                            kilos_recepcion === 0
                                ? 0
                                : item.payload[dataKey].flete_quetzales /
                                  kilos_recepcion;
                        const totalS_por_kg =
                            kilos_recepcion === 0
                                ? 0
                                : item.payload[dataKey].flete_dolares /
                                  kilos_recepcion;
                        return (
                            <div
                                key={index}
                                className="d-flex flex-row justify-content-between"
                            >
                                <span className="mr-2">{dataKey}:</span>
                                <span className="mr-3">
                                    <RenderKilogramo value={kilos_recepcion} />
                                </span>
                                <span className="mr-2">TOTAL (POR KG.):</span>
                                <span className="mr-2">
                                    <RenderDollar
                                        value={totalS_por_kg}
                                        decimalScale={3}
                                    />
                                </span>
                                <span className="mr-2">
                                    (
                                    <RenderCurrency
                                        value={totalQ_por_kg}
                                        decimalScale={3}
                                    />
                                    )
                                </span>
                            </div>
                        );
                    })}
                </div>
            </CardEmpty>
        );
    }
    return null;
};
const calcularCostoFlete = (ingresosLiquidados = []) => {
    ingresosLiquidados = ingresosLiquidados.map((ingreso) => {
        let costoFleteQ = 0,
            costoFleteS = 0,
            costoFleteUnitarioQ = 0,
            costoFleteUnitarioS = 0;
        let totalKgSecos = 0,
            totalKgHumedos = 0,
            totalKgFinca = 0,
            totalKilosRecepcion = 0;
        ingreso.materias &&
            ingreso.materias.forEach((materia) => {
                let kilos_recepcion = materia[KILOS_RECEPCION];
                totalKilosRecepcion += kilos_recepcion;
                totalKgSecos += materia.kilos_secos;
                totalKgHumedos += materia.kilos_humedos;
                totalKgFinca += materia.kilos_finca;
                // Según 'tipo_costo_flete' el valor de 'costo_flete' puede hacer referencia a un valor total o que requiere ser calculado
                // El detalle de la orden compra puede ser de un Sólido o de un Látex
                switch (materia.tipo_costo_flete) {
                    case FLETE_INTERNO_COSTO_POR_KG: // Este costo es unitario y en dólares
                        costoFleteS = kilos_recepcion * materia.costo_flete;
                        costoFleteQ = costoFleteS * materia.cambio_dia;
                        costoFleteUnitarioS = materia.costo_flete;
                        costoFleteUnitarioQ =
                            costoFleteUnitarioS * materia.cambio_dia;
                        break;
                    // Los siguientes tipos de precios son en quetzales
                    case FLETE_EXTERNO_COSTO_POR_KG:
                        costoFleteQ = kilos_recepcion * materia.costo_flete;
                        costoFleteS = costoFleteQ / materia.cambio_dia;
                        costoFleteUnitarioQ = materia.costo_flete;
                        costoFleteUnitarioS =
                            costoFleteUnitarioQ / materia.cambio_dia;
                        break;
                    case FLETE_EXTERNO_COSTO_FIJO: // Este costo es del total de flete
                        costoFleteQ = materia.costo_flete;
                        costoFleteS = costoFleteQ / materia.cambio_dia;
                        costoFleteUnitarioQ =
                            materia.costo_flete / kilos_recepcion;
                        costoFleteUnitarioS =
                            costoFleteUnitarioQ / materia.cambio_dia;
                        break;
                }
            });
        ingreso.totales = {
            flete_quetzales: costoFleteQ,
            flete_dolares: costoFleteS,
            flete_unitario_quetzales: costoFleteUnitarioQ,
            flete_unitario_dolares: costoFleteUnitarioS,
            kilos_secos: totalKgSecos,
            kilos_humedos: totalKgHumedos,
            kilos_finca: totalKgFinca,
            kilos_recepcion: totalKilosRecepcion,
        };
        return ingreso;
    });
};

const Reporte = (props) => {
    const {
        pageChange,
        page,
        loader,
        reporte_costo_fletes: reporte,
        changeTipoFleteGrafico,
        tipo_flete_grafica,
    } = props;
    let mensajeError = "";
    let diferenciaDias = 0;
    let listaObjs = [],
        dataChart = [];
    let fecha_inicial = null,
        fecha_final = null;
    const ATRIBUTOS_BASE = {
        kilos_secos: 0,
        kilos_humedos: 0,
        kilos_finca: 0,
        kilos_recepcion: 0,
        flete_quetzales: 0,
        flete_dolares: 0,
    };
    let totales = { ...ATRIBUTOS_BASE };
    const TIPO_TRANSPORTE = reporte.tipo_transporte;
    switch (TIPO_TRANSPORTE) {
        case "PROPIO":
            fecha_inicial = moment(
                moment(reporte.ersa_fecha_inicial).format("YYYY-MM-DD") +
                    " 00:00:00"
            );
            fecha_final = moment(
                moment(reporte.ersa_fecha_final).format("YYYY-MM-DD") +
                    " 00:00:00"
            );
            KILOS_RECEPCION = "kilos_humedos";
            break;
        case "EXTERNO":
            fecha_inicial = moment(
                moment(reporte.externo_fecha_inicial).format("YYYY-MM-DD") +
                    " 00:00:00"
            );
            fecha_final = moment(
                moment(reporte.externo_fecha_final).format("YYYY-MM-DD") +
                    " 00:00:00"
            );
            KILOS_RECEPCION = "kilos_secos";
            break;
    }
    if (!fecha_inicial.isValid() || !fecha_final.isValid()) {
        mensajeError = "FECHA INICIAL O FINAL NULO";
    } else if (fecha_final.diff(fecha_inicial, "days") < 0) {
        mensajeError = "FILTRO DE FECHAS NO VÁLIDO";
    } else {
        // Procesamiento de la información para generar la gráfica de barras
        diferenciaDias = fecha_final.diff(fecha_inicial, "days");
        listaObjs = Array(diferenciaDias + 1);
        let iterFecha = moment(fecha_inicial).clone();
        // Construcción del array con los datos para el gráfico, inicialmente todos con cero
        for (let index = 0; index < listaObjs.length; index++) {
            listaObjs[index] = {
                fecha: iterFecha.format("D/M/YY"),
                num_dia: "Día " + (index + 1),
                ...ATRIBUTOS_BASE,
            };
            listaObjs[index][GENERAL[SOLIDO]] = { ...ATRIBUTOS_BASE };
            listaObjs[index][GENERAL[LATEX]] = { ...ATRIBUTOS_BASE };
            iterFecha.add(1, "days");
        }
        // Cuando la data esté realmente cargada
        let cpFechaInicio = moment(
            fecha_inicial.format("YYYY-MM-DD") + " 00:00:00"
        );
        // Calculando los costos de flete, en quetzales y dólares
        const ingresosLiquidados = reporte.costo_fletes.results;
        ingresosLiquidados && calcularCostoFlete(ingresosLiquidados);
        const ingresosLiquidadosAll = reporte.costo_fletes.all_results;
        ingresosLiquidadosAll && calcularCostoFlete(ingresosLiquidadosAll);
        diferenciaDias >= 0 &&
            ingresosLiquidadosAll &&
            ingresosLiquidadosAll.forEach((ingreso) => {
                let cpFechaIngreso = moment(
                    moment(ingreso.fecha_entrada).format("YYYY-MM-DD") +
                        " 00:00:00"
                );
                const index = cpFechaIngreso.diff(cpFechaInicio, "days");
                if (index < listaObjs.length && index >= 0) {
                    listaObjs[index].kilos_secos += ingreso.totales.kilos_secos;
                    listaObjs[index].kilos_humedos +=
                        ingreso.totales.kilos_humedos;
                    listaObjs[index].kilos_finca += ingreso.totales.kilos_finca;
                    listaObjs[index].kilos_recepcion +=
                        ingreso.totales.kilos_recepcion;
                    listaObjs[index].flete_quetzales +=
                        ingreso.totales.flete_quetzales;
                    listaObjs[index].flete_dolares +=
                        ingreso.totales.flete_dolares;
                    let materiaObj =
                        listaObjs[index][
                            ingreso.tipo_materia.tipo === SOLIDO
                                ? "SOLIDO"
                                : "LATEX"
                        ];
                    if (materiaObj !== undefined && materiaObj !== null) {
                        materiaObj.kilos_secos += ingreso.totales.kilos_secos;
                        materiaObj.kilos_humedos +=
                            ingreso.totales.kilos_humedos;
                        materiaObj.kilos_finca += ingreso.totales.kilos_finca;
                        materiaObj.kilos_recepcion +=
                            ingreso.totales.kilos_recepcion;
                        materiaObj.flete_quetzales +=
                            ingreso.totales.flete_quetzales;
                        materiaObj.flete_dolares +=
                            ingreso.totales.flete_dolares;
                    }
                }
            });
        listaObjs.forEach((obj, i) => {
            if (i == 0) {
                const data = {
                    ...obj,
                    num_dia: obj.num_dia,
                    x_value: obj.fecha,
                };
                data[GENERAL[SOLIDO]] = { ...obj[GENERAL[SOLIDO]] };
                data[GENERAL[LATEX]] = { ...obj[GENERAL[LATEX]] };
                data[`y_value_${GENERAL[SOLIDO]}`] =
                    obj[GENERAL[SOLIDO]].kilos_recepcion === 0
                        ? 0
                        : obj[GENERAL[SOLIDO]].flete_dolares /
                          obj[GENERAL[SOLIDO]].kilos_recepcion;
                data[`y_value_${GENERAL[LATEX]}`] =
                    obj[GENERAL[LATEX]].kilos_recepcion === 0
                        ? 0
                        : obj[GENERAL[LATEX]].flete_dolares /
                          obj[GENERAL[LATEX]].kilos_recepcion;
                dataChart.push(data);
                totales.kilos_secos += obj.kilos_secos;
                totales.kilos_humedos += obj.kilos_humedos;
                totales.kilos_finca += obj.kilos_finca;
                totales.kilos_recepcion += obj.kilos_recepcion;
                totales.flete_quetzales += obj.flete_quetzales;
                totales.flete_dolares += obj.flete_dolares;
            } else if (
                obj[GENERAL[LATEX]].kilos_humedos != 0 &&
                obj[GENERAL[SOLIDO]].kilos_humedos != 0
            ) {
                const data = {
                    ...obj,
                    num_dia: obj.num_dia,
                    x_value: obj.fecha,
                };
                data[GENERAL[SOLIDO]] = { ...obj[GENERAL[SOLIDO]] };
                data[GENERAL[LATEX]] = { ...obj[GENERAL[LATEX]] };
                data[`y_value_${GENERAL[SOLIDO]}`] =
                    obj[GENERAL[SOLIDO]].kilos_recepcion === 0
                        ? 0
                        : obj[GENERAL[SOLIDO]].flete_dolares /
                          obj[GENERAL[SOLIDO]].kilos_recepcion;
                data[`y_value_${GENERAL[LATEX]}`] =
                    obj[GENERAL[LATEX]].kilos_recepcion === 0
                        ? 0
                        : obj[GENERAL[LATEX]].flete_dolares /
                          obj[GENERAL[LATEX]].kilos_recepcion;
                dataChart.push(data);
                totales.kilos_secos += obj.kilos_secos;
                totales.kilos_humedos += obj.kilos_humedos;
                totales.kilos_finca += obj.kilos_finca;
                totales.kilos_recepcion += obj.kilos_recepcion;
                totales.flete_quetzales += obj.flete_quetzales;
                totales.flete_dolares += obj.flete_dolares;
            } else if (obj[GENERAL[LATEX]].kilos_humedos != 0) {
                const data = {
                    ...obj,
                    num_dia: obj.num_dia,
                    x_value: obj.fecha,
                };
                data[GENERAL[LATEX]] = { ...obj[GENERAL[LATEX]] };
                data[`y_value_${GENERAL[LATEX]}`] =
                    obj[GENERAL[LATEX]].kilos_recepcion === 0
                        ? 0
                        : obj[GENERAL[LATEX]].flete_dolares /
                          obj[GENERAL[LATEX]].kilos_recepcion;
                dataChart.push(data);
                totales.kilos_secos += obj.kilos_secos;
                totales.kilos_humedos += obj.kilos_humedos;
                totales.kilos_finca += obj.kilos_finca;
                totales.kilos_recepcion += obj.kilos_recepcion;
                totales.flete_quetzales += obj.flete_quetzales;
                totales.flete_dolares += obj.flete_dolares;
            } else if (obj[GENERAL[SOLIDO]].kilos_humedos != 0) {
                const data = {
                    ...obj,
                    num_dia: obj.num_dia,
                    x_value: obj.fecha,
                };
                data[GENERAL[SOLIDO]] = { ...obj[GENERAL[SOLIDO]] };
                data[`y_value_${GENERAL[SOLIDO]}`] =
                    obj[GENERAL[SOLIDO]].kilos_recepcion === 0
                        ? 0
                        : obj[GENERAL[SOLIDO]].flete_dolares /
                          obj[GENERAL[SOLIDO]].kilos_recepcion;
                dataChart.push(data);
                totales.kilos_secos += obj.kilos_secos;
                totales.kilos_humedos += obj.kilos_humedos;
                totales.kilos_finca += obj.kilos_finca;
                totales.kilos_recepcion += obj.kilos_recepcion;
                totales.flete_quetzales += obj.flete_quetzales;
                totales.flete_dolares += obj.flete_dolares;
            }
        });
    }
    return (
        <div>
            <div className="mb-4">
                <CardEmpty>
                    <LoadMask loading={loader} blur>
                        <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mt-4 mb-4">
                            <div className="col-12 col-md-12 d-flex flex-wrap justify-content-center align-items-center">
                                <div className="col-12 col-md-2 p-0 d-flex flex-row justify-content-center justify-content-md-start align-items-center">
                                    <h6 className="m-0 mb-3 mb-md-0 bold">
                                        TOTALES
                                    </h6>
                                </div>
                                <div className="col-12 row col-md-10 d-flex flex-row flex-wrap justify-content-center align-items-center p-0">
                                    <div className="col-12 col-md-4 col-sm-4 d-flex flex-row justify-content-between justify-content-md-between align-items-center">
                                        <span className="m-0 ml-0 ml-md-1 bold">
                                            {reporte.tipo_transporte ===
                                            "EXTERNO"
                                                ? "Kg. Secos:"
                                                : "Kg. Húmedos:"}
                                        </span>
                                        <h3
                                            className="mb-1 ml-2 gris"
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            {
                                                <RenderKilogramo
                                                    value={
                                                        totales.kilos_recepcion
                                                    }
                                                />
                                            }
                                        </h3>
                                    </div>
                                    <div className="col-12 col-md-4 col-sm-4 d-flex flex-row justify-content-between justify-content-md-between align-items-center">
                                        <span className="m-0 ml-0 ml-md-1 bold">
                                            TOTAL:
                                        </span>
                                        <h3
                                            className="mb-1 ml-2 gris"
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            {
                                                <RenderCurrency
                                                    value={
                                                        totales.flete_quetzales
                                                    }
                                                />
                                            }
                                        </h3>
                                    </div>
                                    <div className="col-12 col-md-4 col-sm-4 d-flex flex-row justify-content-between justify-content-md-between align-items-center">
                                        <span className="m-0 ml-0 ml-md-1 bold">
                                            TOTAL:
                                        </span>
                                        <h3
                                            className="mb-1 ml-2 gris"
                                            style={{ whiteSpace: "nowrap" }}
                                        >
                                            {
                                                <RenderDollar
                                                    value={
                                                        totales.flete_dolares
                                                    }
                                                />
                                            }
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                </CardEmpty>
            </div>
            <div className="mb-4">
                <CardEmpty>
                    <LoadMask loading={loader} blur>
                        <div className="d-flex flex-column">
                            <div className="flex-fill d-flex justify-content-center">
                                {mensajeError.length === 0 ? (
                                    <ResponsiveContainer
                                        width="85%"
                                        height="100%"
                                        minWidth={500}
                                        minHeight={300}
                                    >
                                        <AreaChart data={dataChart}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="x_value" />
                                            <YAxis />
                                            {tipo_flete_grafica.value ===
                                                LATEX && (
                                                <Area
                                                    connectNulls
                                                    dataKey={`y_value_${GENERAL[LATEX]}`}
                                                    value={"LATEX"}
                                                    type="monotone"
                                                    stroke="#359d9e"
                                                    fill="#359d9e"
                                                    strokeWidth={3}
                                                    activeDot={{ r: 8 }}
                                                    dot={{ stroke: "#359d9e" }}
                                                />
                                            )}
                                            {tipo_flete_grafica.value ===
                                                SOLIDO && (
                                                <Area
                                                    connectNulls
                                                    dataKey={`y_value_${GENERAL[SOLIDO]}`}
                                                    value={"SOLIDO"}
                                                    type="monotone"
                                                    stroke="#215273"
                                                    fill="#215273"
                                                    strokeWidth={3}
                                                    activeDot={{ r: 8 }}
                                                    dot={{ stroke: "#215273" }}
                                                />
                                            )}
                                            <Tooltip content={renderTooltip}
                                            grafica_reporte={tipo_flete_grafica}
                                            />
                                            <Legend
                                                content={renderLegendCustom}
                                                align="left"
                                                verticalAlign="middle"
                                                layout="vertical"
                                                seleccionGrafica
                                                iconType="circle"
                                                title={`GRÁFICA DE FLETES (POR KG.) `}
                                                optionsCustom={TIPO_COSTO_FLETE}
                                                graficaReporte={changeTipoFleteGrafico}
                                                grafica_reporte={tipo_flete_grafica}
                                            />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                ) : (
                                    <div className="mb-3 rojo bold">
                                        {mensajeError}
                                    </div>
                                )}
                            </div>
                        </div>
                    </LoadMask>
                </CardEmpty>
            </div>
            {/* TABLA CON LOS RESÚMENES */}
            <div className="mb-5">
                <CardEmpty>
                    <Grid
                        hover
                        striped
                        loading={loader}
                        data={reporte.costo_fletes}
                        pagination
                        onPageChange={pageChange}
                        page={page}
                        className="pb-4 pt-3"
                    >
                        <TableHeaderColumn
                            dataField="id"
                            isKey
                            width="2%"
                            dataFormat={(cell) => <div></div>}
                        />
                        <TableHeaderColumn dataField="id" width="9%">
                            ID INGRESO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_entrada"
                            width="9%"
                            dataFormat={(cell) =>
                                cell ? moment(cell).format("DD/MM/YYYY") : "--"
                            }
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="finca"
                            dataFormat={(cell) => cell.nombre || "--"}
                        >
                            FINCA
                        </TableHeaderColumn>
                        {reporte.tipo_transporte === "EXTERNO" && (
                            <TableHeaderColumn
                                dataField="transportista"
                                dataFormat={(cell) =>
                                    cell ? cell.nombre_empresa : "--"
                                }
                            >
                                TRANSPORTISTA
                            </TableHeaderColumn>
                        )}
                        {reporte.tipo_transporte === "EXTERNO" && (
                            <TableHeaderColumn
                                dataField="totales"
                                dataFormat={(cell) => (
                                    <RenderCurrency
                                        value={
                                            cell.flete_unitario_quetzales *
                                            cell.kilos_recepcion
                                        }
                                        decimalScale={2}
                                    />
                                )}
                            >
                                TOTAL Q. FLETE
                            </TableHeaderColumn>
                        )}
                        <TableHeaderColumn
                            dataField="materias"
                            dataFormat={(cell) => {
                                let nombresMateria = "";
                                cell &&
                                    cell.forEach((materia) => {
                                        nombresMateria += `${
                                            nombresMateria.length > 0
                                                ? "; "
                                                : ""
                                        }${materia.materia_prima.nombre}`;
                                    });
                                return nombresMateria;
                            }}
                        >
                            MATERIA(S) PRIMA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="totales"
                            dataAlign="right"
                            width="12%"
                            dataFormat={(cell) => (
                                <div className="d-flex justify-content-end">
                                    <RenderKilogramo
                                        value={cell.kilos_recepcion}
                                    />
                                </div>
                            )}
                        >
                            {reporte.tipo_transporte === "EXTERNO"
                                ? "KG. SECOS"
                                : "KG. HÚMEDOS"}
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="totales"
                            dataAlign="right"
                            dataFormat={(cell, row) => (
                                <div className="row justify-content-end">
                                    <div className="col-xl-3 col-sm-2"></div>
                                    <div className="col-xl-9 col-sm-8 text-right">
                                        {
                                            <div className="d-flex justify-content-between">
                                                <span>Q.</span>
                                                {
                                                    <RenderNumber
                                                        value={
                                                            cell.flete_unitario_quetzales
                                                        }
                                                        decimalScale={3}
                                                    />
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                        >
                            TOTAL Q. POR KG.
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="totales"
                            dataAlign="right"
                            dataFormat={(cell, row) => (
                                <div className="row justify-content-end">
                                    <div className="col-xl-3 col-sm-2"></div>
                                    <div className="col-xl-9 col-sm-8 text-right">
                                        {
                                            <div className="d-flex justify-content-between">
                                                <span>$.</span>
                                                {
                                                    <RenderNumber
                                                        value={
                                                            cell.flete_unitario_dolares
                                                        }
                                                        decimalScale={3}
                                                    />
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            )}
                        >
                            TOTAL $. POR KG.
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            width="2%"
                            dataFormat={(cell) => <div></div>}
                        ></TableHeaderColumn>
                    </Grid>
                </CardEmpty>
            </div>
        </div>
    );
};

export default Reporte;
