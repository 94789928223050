import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { Field, FieldArray, formValueSelector, reduxForm, } from 'redux-form';
import {
    renderField,
    renderNumber,
    renderFieldRadio,
    renderFieldCheck,
    renderCurrency
} from '../../../Utils/renderField/renderField';
import {RenderCurrency, RenderDollar,RenderKilogramo } from 'Utils/renderField/renderReadField';
import CardEmpty from "Utils/Cards/CardEmpty";
import Modal from 'react-responsive-modal';
import { NotificationManager } from "react-notifications";
import moment from "moment/moment";
import { LATEX, SOLIDO, LABORATORIO, PRECIO_INTROSA, PRECIO_ERSA, FLETE_COSTO_NO_DEFINIDO} from "../../../../../utility/constants";
import { RenderCambio, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';
import {formatActivo} from "Utils/renderField/renderReadField"
import Alerta from '../../../Utils/Alerta/Alerta';

const ver_img = require('assets/img/icons/ver.png');
var number = 150780.5315;

export const renderDatosDRC = ({ fields, openModal, item, loadDataModal, ordenCompraTemp, tipo_drc, finca_nombre, ingresosValue}) => {
    return (
        <div className="col-12 responsive-table-fix">
            <div className="table-responsive table-no-border-fix mt-3">
                <table className="table table-striped table-bordered table-hover">
                    <div className="react-bs-container-header">
                        <thead className="table-header">
                            <tr>
                                <th className="text-center">VALIDADO</th>
                                <th/>
                                <th style={{whiteSpace: 'pre'}}>ID RECEPCIÓN</th>
                                <th style={{whiteSpace: 'pre'}}>FECHA DE RECEPCIÓN</th>
                                <th style={{whiteSpace: 'pre'}}>MATERIA PRIMA</th>
                                <th style={{whiteSpace: 'pre'}}>KILOS HÚMEDOS</th>
                                <th>DRC</th>
                                <th style={{whiteSpace: 'pre'}}>KILOS SECOS</th>
                                <th style={{whiteSpace: 'pre'}}>SUBTOTAL $.</th>
                                <th>PRECIO</th>
                                <th style={{whiteSpace: 'pre'}}>SUBTOTAL Q.</th>
                                <th>LUGAR</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                ingresosValue && (
                                    ingresosValue.map((dato_actual, index) => {
                                        let todos = ingresosValue;
                                        todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                                        todos[index]['ingreso'] = (dato_actual && dato_actual.analisis && dato_actual.analisis[0].ingreso && dato_actual.analisis[0].ingreso.id) ? dato_actual.analisis[0].ingreso.id : 0;
                                        todos[index]['fecha'] = (dato_actual && dato_actual.analisis && dato_actual.analisis[0].ingreso && dato_actual.analisis[0].ingreso.fecha) ? moment(dato_actual.analisis[0].ingreso.fecha).format('DD-MM-YYYY') : 'No hay fecha registrada';
                                        todos[index]['drc_compra'] = (dato_actual && dato_actual.analisis && dato_actual.analisis[0].drc_final && dato_actual.analisis[0].drc_final !== undefined) ? dato_actual.analisis[0].drc_final : 0;
                                        todos[index]['drc_promedio'] = item ? (item.finca ? item.finca.drc_promedio : 0) : 0;
                                        todos[index]['drc_anterior'] = item ? (item.finca ? item.finca.drc_anterior : 0) : 0;
                                        todos[index]['tipo_drc'] = tipo_drc;
                                        //todos[index]['tipo_precio'] =
                                        // (dato_actual.tipo_materia === LATEX ?
                                        //     (item ? (item.finca ? item.finca.tipo_precio_latex : 20) : 20)
                                        //     :
                                        //     (item ? (item.finca ? item.finca.tipo_precio_solido : 20) : 20)
                                        // )
                                        todos[index]['precio_ersa'] =
                                        (dato_actual.tipo_materia === LATEX ?
                                            (item ? (item.finca ? item.finca.precio_definido_latex : 10) : 10)
                                            :
                                            (item ? (item.finca ? item.finca.precio_definido_solido : 10) : 10)
                                        )
                                        todos[index]['diferencial'] =
                                        (dato_actual.tipo_materia === LATEX ?
                                            (item ? (item.finca ? item.finca.diferencial_latex : 10) : 10)
                                            :
                                            (item ? (item.finca ? item.finca.diferencial_solido : 10) : 10)
                                        )
                                        todos[index]['materia_prima'] = (dato_actual && dato_actual.materia_prima) ? dato_actual.materia_prima: 0;
                                        todos[index]['total_quetzales'] =
                                        (dato_actual.tipo_materia === LATEX ?
                                            (dato_actual.tipo_precio === PRECIO_INTROSA ?
                                                (((dato_actual.kilos_ingresados.total * dato_actual.analisis[0].drc_final) * (dato_actual.precios_latex.precio_kilogramo + dato_actual.diferencial_introsa)) * dato_actual.tipo_cambio)
                                                :
                                                (((dato_actual.kilos_ingresados.total * dato_actual.analisis[0].drc_final) * dato_actual.precio_kilogramo_ersa) * dato_actual.tipo_cambio)
                                            )
                                            :
                                            (dato_actual.tipo_precio === PRECIO_INTROSA ?
                                                (((dato_actual.kilos_ingresados.total * dato_actual.analisis[0].drc_final) * (dato_actual.precios_solido.precio_kilogramo + dato_actual.diferencial_introsa)) * dato_actual.tipo_cambio)
                                                :
                                                (((dato_actual.kilos_ingresados.total * dato_actual.analisis[0].drc_final) * dato_actual.precio_kilogramo_ersa) * dato_actual.tipo_cambio)
                                            )
                                        )
                                        todos[index]['total_dolares'] =
                                        (dato_actual.tipo_materia === LATEX ?
                                            (dato_actual.tipo_precio === PRECIO_INTROSA ?
                                                (((dato_actual.kilos_ingresados.total * dato_actual.analisis[0].drc_final) * (dato_actual.precios_latex.precio_kilogramo + dato_actual.diferencial_introsa)))
                                                :
                                                (((dato_actual.kilos_ingresados.total * dato_actual.analisis[0].drc_final) * dato_actual.precio_kilogramo_ersa))
                                            )
                                            :
                                            (dato_actual.tipo_precio === PRECIO_INTROSA ?
                                                (((dato_actual.kilos_ingresados.total * dato_actual.analisis[0].drc_final) * (dato_actual.precios_solido.precio_kilogramo + dato_actual.diferencial_introsa)))
                                                :
                                                (((dato_actual.kilos_ingresados.total * dato_actual.analisis[0].drc_final) * dato_actual.precio_kilogramo_ersa))
                                            )
                                        )
                                        todos[index]['kilos_secos'] = (dato_actual.kilos_ingresados.total * (dato_actual.analisis[0] ? dato_actual.analisis[0].drc_final : 0));
                                        todos[index]['kilos_humedos'] = dato_actual.kilos_ingresados.total;
                                        todos[index]['cambio_dia'] = dato_actual.tipo_cambio;
                                        todos[index]['precio_venta'] =
                                            ((dato_actual ? dato_actual.tipo_precio : PRECIO_INTROSA) === PRECIO_INTROSA ?
                                                (dato_actual.tipo_materia === LATEX ?
                                                    (dato_actual.precios_latex.precio_kilogramo + dato_actual.diferencial_introsa)
                                                    :
                                                    (dato_actual.precios_solido.precio_kilogramo + dato_actual.diferencial_introsa)
                                                )
                                            :
                                            (
                                                (dato_actual.precio_kilogramo_ersa)
                                            )
                                        );
                                        // Cálculo de subtotales: kilos secos y valores monetarios
                                        let subtotal_kilos_secos = (dato_actual.kilos_ingresados.total * ((dato_actual.analisis[0] && dato_actual.analisis[0].drc_final) ? dato_actual.analisis[0].drc_final : 0));
                                        let subtotal_dolares = dato_actual.tipo_materia === LATEX
                                            ? (dato_actual.tipo_precio === PRECIO_INTROSA
                                                ? (((dato_actual.kilos_ingresados.total * dato_actual.analisis[0].drc_final) * (dato_actual.precios_latex.precio_kilogramo + dato_actual.diferencial_introsa)))
                                                : (((dato_actual.kilos_ingresados.total * dato_actual.analisis[0].drc_final) * dato_actual.precio_kilogramo_ersa))
                                            ) : (dato_actual.tipo_precio === PRECIO_INTROSA
                                                ? (((dato_actual.kilos_ingresados.total * dato_actual.analisis[0].drc_final) * (dato_actual.precios_solido.precio_kilogramo + dato_actual.diferencial_introsa)))
                                                : (((dato_actual.kilos_ingresados.total * dato_actual.analisis[0].drc_final) * dato_actual.precio_kilogramo_ersa))
                                            );
                                        subtotal_dolares = parseFloat(subtotal_dolares);
                                        // Sumatoria del costo de flete, si el vehículo es de la finca
                                        if (dato_actual.vehiculo.transportista === null) {
                                            subtotal_dolares+= (dato_actual.tipo_costo_flete === FLETE_COSTO_NO_DEFINIDO
                                                ? item.finca[`costo_flete_kg_interno_${dato_actual.tipo_materia === LATEX ? 'latex' : 'solido'}`]
                                                : dato_actual.costo_flete)*subtotal_kilos_secos;
                                        }
                                        let subtotal_quetzales = (subtotal_dolares * dato_actual.tipo_cambio);

                                        return (
                                            <tr key={index} >
                                                <td className="text-center">
                                                    <img
                                                        src={dato_actual.validado ? require('assets/img/icons/activo.png') : require('assets/img/icons/inactivo.png')}
                                                        alt={dato_actual.validado ? "Activo" : "Inactivo"}
                                                        className="action_img"
                                                    />
                                                </td>
                                                <td className="text-center sin-borde-top">
                                                    <button className="btn btn-outline-info py-0"
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            openModal('actualizar_drc')
                                                            loadDataModal(
                                                                dato_actual, dato_actual.finca = finca_nombre,
                                                                dato_actual.index = index
                                                            )
                                                        }}>
                                                        VALIDAR
                                                    </button>
                                                </td>
                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                    <span>{(dato_actual && dato_actual.analisis && dato_actual.analisis[0].ingreso && dato_actual.analisis[0].ingreso.id) ? dato_actual.analisis[0].ingreso.id : 0}</span>
                                                </td>
                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                    <span>{(dato_actual && dato_actual.analisis && dato_actual.analisis[0].ingreso && dato_actual.analisis[0].ingreso.fecha) ? moment(dato_actual.analisis[0].ingreso.fecha).format('DD-MM-YYYY') : 'No hay fecha registrada'}</span>
                                                </td>
                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                    <span>{dato_actual.materia_prima_nombre} </span>
                                                </td>
                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                    <RenderKilogramo value={
                                                        (dato_actual.kilos_ingresados.total)
                                                    } />
                                                </td>
                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                    {(dato_actual.analisis[0] && dato_actual.analisis[0].drc_final) ? <RenderNumberPercentage value={dato_actual.analisis[0].drc_final} /> : '--'}
                                                </td>
                                                {/* <td style={{ width: "10%" }}
                                                    className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                    <span>{(dato_actual && dato_actual.analisis && dato_actual.analisis[0].ingreso && dato_actual.analisis[0].ingreso.id) ? moment(dato_actual.analisis[0].ingreso.fecha).format("DD/MM/YYYY") : 0}</span>
                                                </td> */}
                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                    <RenderKilogramo value={
                                                        (dato_actual.kilos_ingresados.total * ((dato_actual.analisis[0] && dato_actual.analisis[0].drc_final) ? dato_actual.analisis[0].drc_final : 0))
                                                    } />
                                                </td>
                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                    <RenderDollar value={subtotal_dolares} />
                                                </td>
                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                    <RenderDollar value={dato_actual.precio_venta} decimalScale={5} />
                                                </td>
                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                    <RenderCurrency value={subtotal_quetzales} />
                                                </td>
                                                <td className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                                    {dato_actual.acopio ? dato_actual.acopio.nombre : 'Planta ERSA'}
                                                </td>
                                            </tr>
                                        )
                                    })
                                )
                            }
                        </tbody>
                    </div>
                </table>
            </div>
        </div>
    )
};

let ComprasForm = props => {
    const { handleSubmit, item, fecha_inicio, fecha_final, clearIndexValidados, loader, habilitarGuardar } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="d-flex  justify-content-center mb-4">
                    <div className="col-8">
                        <div className="row col-12">
                            <div className="col-12 d-flex justify-content-between align-items-baseline">
                                <h6 className="mb-4">DATOS GENERALES DE RECEPCIÓN</h6>
                            </div>
                        </div>
                        <div className="row col-md-12 p-0">
                            <div className="col-md-6 p-0">
                                <div className="col-12 d-flex flex-column">
                                    <label className="p-0 m-0">FECHA INICIO</label>
                                    <span className="mb-1">{moment(fecha_inicio).format("DD/MM/YYYY")}</span>
                                    <label className="p-0 m-0">FECHA FINAL</label>
                                    <span className="mb-1">{moment(fecha_final).format("DD/MM/YYYY")}</span>
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-12 d-flex flex-column">
                                    <label className="p-0 m-0">FINCA</label>
                                    <span className="mb-1">{item ? (item.finca ? item.finca.nombre : " ") : " "}</span>
                                    <label className="p-0 m-0">RÉGIMEN</label>
                                    <span className="mb-1">{item ? (item.finca ? item.finca.regimen_fiscal_nombre : " ") : " "}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">
                        <div className="row col-12 m-0 py-3">
                        <FieldArray name="ingresos" component={renderDatosDRC} {...props} />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/compras"
                        onClick={e => {
                            clearIndexValidados()
                        }}
                    >
                        CANCELAR
                    </Link>
                    { (!loader && habilitarGuardar) && <button type="submit" className="btn btn-primary">GUARDAR</button> }
                </div>
                <div className="buttons-box mt-3 d-flex justify-content-center">
                    <Alerta texto="Asegurese de tener la información correcta antes de guardar." />
                </div>
            </div>
        </form>
    )
};
ComprasForm = reduxForm({
    form: 'ComprasForm',
    initialValues:{
        drc_radioB: LABORATORIO,
        liquidar: false
    }
})(ComprasForm);

const selector = formValueSelector('ComprasForm');
ComprasForm = connect(state => {
    const usuario = state.login.me;
    const finca_nombre = state.compras.item.finca;
    const  ingresosValue = selector(state, 'ingresos');
    let mostrar_liquidacion = true;

    if (usuario.rol === 30) {
        mostrar_liquidacion = false;
    }
    if (usuario.rol === 10) {
        mostrar_liquidacion = true;
    }

    return {
        mostrar_liquidacion,
        finca_nombre,
        ingresosValue
    }
})(ComprasForm);

export default ComprasForm
