import React, { Component, Fragment } from 'react';
import styles from './test.css';
import CardEmpty from '../Utils/Cards/CardEmpty';
import LoadMask from '../Utils/LoadMask/LoadMask';
import Grid from '../Utils/Grid';
import {RenderKilogramo, RenderNumber, RenderCurrency, RenderDollar, RenderNumberPercentage} from '../Utils/renderField/renderReadField';
import FiltrosDashboard from '../Home/FiltrosDashboard';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine } from 'recharts';
import { renderLegend } from '../App/Reportes/components';
import moment from 'moment/moment';
import GoogleMapReact from 'google-map-react';
import MarkerLogo from 'assets/img/icons/google_map_marker.svg';
import { GOOGLE_MAPS_API_KEY, meses } from '../../../utility/constants';
import { Link } from 'react-router-dom';
import TabBar from 'rc-tabs/lib/TabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import Tabs, {TabPane} from 'rc-tabs';
import ProduccionSubmateria from './ProduccionSubmateria';

const renderTooltipKgSecos = (props) => {
    const { active, payload, label, grafica_reporte } = props;
    if (active) {
        let nombre = "", mes = "";
        let value = 0;
        if (payload && payload[0].payload) {
            nombre = "KG. Secos";
            value = payload[0].payload.kg_secos;
            mes = payload[0].payload.mes;
        }
        return (
            <CardEmpty noShadow>
                <div className="d-flex flex-column pb-2">
                    <span className="mb-0">Mes: {mes}</span>
                    <span className="mb-2">{nombre}: <RenderKilogramo value={value} decimalScale={2} /></span>
                </div>
            </CardEmpty>
        );
    }
    return null;
};


const renderTooltipDrc = (props) => {
    const { active, payload, label, grafica_reporte } = props;
    if (active) {
        let nombre = "", mes = "";
        let drc_minimo, drc_maximo, drc_ponderado = 0;
        if (payload && payload[0].payload) {
            drc_minimo = payload[0].payload.drc_minimo;
            drc_maximo = payload[0].payload.drc_maximo;
            drc_ponderado = payload[0].payload.drc_ponderado;
            mes = payload[0].payload.mes;
        }
        return (
            <CardEmpty noShadow>
                <div className="d-flex flex-column pb-2">
                    <span className="mb-0">Mes: {mes}</span>
                    <span className="mb-2">{"DRC Minimo"}: <RenderNumber value={drc_minimo} suffix="%" decimalScale={2} /></span>
                    <span className="mb-2">{"DRC Máximo"}: <RenderNumber value={drc_maximo} suffix="%" decimalScale={2} /></span>
                    <span className="mb-2">{"DRC Ponderado"}: <RenderNumber value={drc_ponderado} suffix="%" decimalScale={2} /></span>
                </div>
            </CardEmpty>
        );
    }
    return null;
};

const renderTooltipFletes = (props) => {
    const { active, payload, label, grafica_reporte } = props;
    if (active) {
        let nombre_ersa = "", mes = "", nombre_prov = "";
        let ersa = 0, proveedor = 0;
        if (payload && payload[0].payload) {
            nombre_ersa = "Costo flete ERSA";
            nombre_prov = "Costo flete FINCA";
            ersa = payload[0].payload.total_flete_ersa;
            proveedor = payload[0].payload.total_flete_proveedor;
            mes = payload[0].payload.mes;
        }
        return (
            <CardEmpty noShadow>
                <div className="d-flex flex-column pb-2">
                    <span className="mb-0">Mes: {mes}</span>
                    <span className="mb-2">{nombre_ersa}: <RenderDollar value={ersa} decimalScale={3} /></span>
                    <span className="mb-2">{nombre_prov}: <RenderDollar value={proveedor} decimalScale={3} /></span>
                </div>
            </CardEmpty>
        );
    }
    return null;
};

const renderTooltipVfa = (props) => {
    const { active, payload, label, grafica_reporte } = props;
    if (active) {
        let nombre = "", mes = "";
        let value = 0;
        if (payload && payload[0].payload) {
            nombre = "VFA";
            value = payload[0].payload.vfa;
            mes = payload[0].payload.mes;
        }
        return (
            <CardEmpty noShadow>
                <div className="d-flex flex-column pb-2">
                    <span className="mb-0">Mes: {mes}</span>
                    <span className="mb-2">{nombre}: <RenderNumberPercentage value={value/100} decimalScale={3} /></span>
                </div>
            </CardEmpty>
        );
    }
    return null;
};

const renderTooltipNh3 = (props) => {
    const { active, payload, label, grafica_reporte } = props;
    if (active) {
        let nombre = "", mes = "";
        let value = 0;
        if (payload && payload[0].payload) {
            nombre = "NH3";
            value = payload[0].payload.nh3;
            mes = payload[0].payload.mes;
        }
        return (
            <CardEmpty noShadow>
                <div className="d-flex flex-column pb-2">
                    <span className="mb-0">Mes: {mes}</span>
                    <span className="mb-2">{nombre}: <RenderNumberPercentage value={value/100} decimalScale={3} /></span>
                </div>
            </CardEmpty>
        );
    }
    return null;
};


const InformacionFinca = (props) => {
    const { finca } = props;
    return (
        <div className="d-flex flex-column google-maps-marker">
            <img src={MarkerLogo} alt="Marcador" width={18} height={20} className="marker_indicator"/>
            <div className="marker_content">
                <CardEmpty noPadding noShadow>
                    <div className="d-flex flex-column justify-content-start align-items-start mx-3 my-3">
                        <label className="pl-0 verde-secundario">{finca.nombre ? finca.nombre.toUpperCase() : '--'}</label>
                        <div className="d-flex flex-column justify-content-start align-items-start">
                            <span className="gris">{finca.departamento_text} / {finca.municipio_text}</span>
                            <div className="d-flex flex-row">
                                <div className="d-flex flex-column justify-content-start align-items-start">
                                    <span className="gris">Área cultivada:</span>
                                    <span className="gris">Área total:</span>
                                </div>
                                <div className="d-flex flex-column justify-content-end align-items-end ml-1">
                                    <span className="gris">{finca.area_cultivada} {`${finca.medida_area_cultivada_text}${parseFloat(finca.area_cultivada) === 1 ? '' : 's'}`}</span>
                                    <span className="gris">{finca.area_finca} {`${finca.medida_area_finca_text}${parseFloat(finca.area_finca) === 1 ? '' : 's'}`}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardEmpty>
            </div>
        </div>
    );
};

const DashboardGerencial = (props) => {
    const {
        tipoMateriaDashboardChange,
        tipo_materia_dashboard,
        // FILTROS LATEX
        filtroFincaDashboardLatex,
        filtroMesDashboardLatex,
        filtroAnioDashboardLatex,
        filtroMateriaPrimaDashboardLatex,
        // VARIABLES LATEX
        finca_dashboard_latex,
        mes_dashboard_latex,
        anio_dashboard_latex,
        materia_prima_dashboard_latex,
        // FILTROS SOLIDO
        filtroFincaDashboardSolido,
        filtroMesDashboardSolido,
        filtroAnioDashboardSolido,
        filtroMateriaPrimaDashboardSolido,
        // VARIABLES SOLIDO
        finca_dashboard_solido,
        mes_dashboard_solido,
        anio_dashboard_solido,
        materia_prima_dashboard_solido,
        data, loader, me,
    } = props;
    const data_materia_mas_entregada = data.results_materia_mas_entregada ? data.results_materia_mas_entregada : [];
    const data_top_recepciones_latex = data.results_top_recepciones_latex ? data.results_top_recepciones_latex : [];
    const data_top_recepciones_solido = data.results_top_recepciones_solido ? data.results_top_recepciones_solido : [];
    const data_fletes = data.results_totales_flete ? data.results_totales_flete : [];
    const data_grafica_drc = data.results_totales_drc ? data.results_totales_drc : [];
    
    const data_kilos_secos = data.totales_kg_secos ? data.totales_kg_secos: []
    const data_grafica = data.results_grafica ? data.results_grafica : [];
    const fincas_mapa = data.results_mapa && data.results_mapa.results ? data.results_mapa.results.slice() : [];
    // Data de dashboard de proveedores.
    const configuraciones = data.configuraciones ? data.configuraciones : [];
    const usuario = data.usuario ? data.usuario : [];

    // Variables de la definicion de la materia.
    let filtroMesDashboard = null, filtroAnioDashboard = null, filtroFincaDashboard = null, filtroMateriaPrimaDashboard = null;
    let mes_dashboard = null, anio_dashboard = null, finca_dashboard = null, materia_prima_dashboard = null;
    switch(tipo_materia_dashboard) {
        case 'LATEX':
            filtroMesDashboard = filtroMesDashboardLatex;
            filtroAnioDashboard = filtroAnioDashboardLatex;
            filtroFincaDashboard = filtroFincaDashboardLatex;
            filtroMateriaPrimaDashboard = filtroMateriaPrimaDashboardLatex;

            mes_dashboard = mes_dashboard_latex;
            anio_dashboard = anio_dashboard_latex;
            finca_dashboard = finca_dashboard_latex;
            materia_prima_dashboard = materia_prima_dashboard_latex;
            break;
        case 'SOLIDO':
            filtroMesDashboard = filtroMesDashboardSolido;
            filtroAnioDashboard = filtroAnioDashboardSolido;
            filtroFincaDashboard = filtroFincaDashboardSolido;
            filtroMateriaPrimaDashboard = filtroMateriaPrimaDashboardSolido;

            mes_dashboard = mes_dashboard_solido;
            anio_dashboard = anio_dashboard_solido;
            finca_dashboard = finca_dashboard_solido;
            materia_prima_dashboard = materia_prima_dashboard_solido;
            break;
    }

    // Varibles de totalizados y data de las graficas.
    let total_recepciones_latex = data_grafica.count_latex ? data_grafica.count_latex : 0;
    let total_recepciones_solido = data_grafica.count_solido ? data_grafica.count_solido : 0;
    let total_recepciones = total_recepciones_latex + total_recepciones_solido;
    // DATA de la grafica
    let datos_grafica_results_latex = data_grafica.results_latex ? data_grafica.results_latex : [];
    let datos_grafica_results_solido = data_grafica.results_solido ? data_grafica.results_solido : [];
    const datos_grafica_kg_secos_latex = data_grafica.results_latex_x_mes ? data_grafica.results_latex_x_mes : [];
    const datos_grafica_kg_secos_solido = data_grafica.results_solido_x_mes ? data_grafica.results_solido_x_mes : [];

    // Variables de totalizados.
    let kg_humedos_totales = 0;
    let kg_humedos_totales_latex = 0;
    let kg_humedos_totales_solido = 0;
    let kg_secos_totales = 0;
    let kg_secos_totales_latex = 0;
    let kg_secos_totales_solido = 0;
    let vfa_latex = 0;
    let nh3_latex = 0;

    // Variables para el mapa
    const center = {
        lat: 14.973960,
        lng: -90.420665,
    };
    const zoom = 8;

    // Arrays de Data
    let dataChartVfa = [];
    let dataChartNh3 = [];
    let dataChartDrc = [];
    let dataChartFlete = [];
    let dataChartLatex = [];
    let dataChartSolido = [];

    // Lista de objetos
    let listaObjsRecepcionesLatex = Array(datos_grafica_kg_secos_latex.length);
    let listaObjsRecepcionesSolido = Array(datos_grafica_kg_secos_solido.length);
    let listaObjsVfa = Array(datos_grafica_kg_secos_latex.length);
    let listaObjsNh3 = Array(datos_grafica_kg_secos_latex.length);
    let listaObjsFlete = Array(data_fletes.length);
    let listaObjsDrc = Array(data_grafica_drc.length);

    // Variables
    let totalKilosSecosComprados = 0
    let totalKilosSecosProducidos = 0
    let totalDiferenciaKilosSecos = 0
    let totalPorcentajeKilosSecos = 0

    // Asignacion de data en los Arrays de la data
    // Grafica de kilos secos LATEX
    for (let index = 0; index < listaObjsRecepcionesLatex.length; index++) {
        listaObjsRecepcionesLatex[index] = {
            num_mes: (mes_dashboard_latex && mes_dashboard_latex.value) ? mes_dashboard_latex.value : index + 1,
            mes: '',
            kg_secos: 0,
        };
    }

    listaObjsRecepcionesLatex.forEach(obj => {
        let kg_secos = 0;
        let mes = meses.filter((item) => item.value === obj.num_mes);
        for (let i = 0; i < datos_grafica_kg_secos_latex.length; i++) {
            for (let j = 0; j < datos_grafica_kg_secos_latex[i].results.length; j++) {
                if (datos_grafica_kg_secos_latex[i].mes[0] === obj.num_mes) {
                    kg_secos += datos_grafica_kg_secos_latex[i].results[j].peso_neto * (datos_grafica_kg_secos_latex[i].results[j].analisis_laboratorio.drc_promedio ? datos_grafica_kg_secos_latex[i].results[j].analisis_laboratorio.drc_promedio : 0);
                }
            }
        }

        let data = {
            num_mes: obj.num_mes,
            mes: mes[0].label,
            kg_secos: kg_secos
        }
        dataChartLatex.push(data)
    })
    // Grafica de kilos secos SOLIDO
    for (let index = 0; index < listaObjsRecepcionesSolido.length; index++) {
        listaObjsRecepcionesSolido[index] = {
            num_mes: (mes_dashboard_solido && mes_dashboard_solido.value) ? mes_dashboard_solido.value : index + 1,
            mes: '',
            kg_secos: 0,
        };
    }

    listaObjsRecepcionesSolido.forEach(obj => {
        let kg_secos = 0;
        let mes = meses.filter((item) => item.value === obj.num_mes);
        for (let i = 0; i < datos_grafica_kg_secos_solido.length; i++) {
            for (let j = 0; j < datos_grafica_kg_secos_solido[i].results.length; j++) {
                if (datos_grafica_kg_secos_solido[i].mes[0] === obj.num_mes) {
                    kg_secos += datos_grafica_kg_secos_solido[i].results[j].peso_neto * (datos_grafica_kg_secos_solido[i].results[j].analisis_laboratorio.drc_promedio ? datos_grafica_kg_secos_solido[i].results[j].analisis_laboratorio.drc_promedio : 0);
                }
            }
        }

        let data = {
            num_mes: obj.num_mes,
            mes: mes[0].label,
            kg_secos: kg_secos
        }
        dataChartSolido.push(data)
    })
    // Grafica de VFA
    for (let index = 0; index < listaObjsVfa.length; index++) {
        listaObjsVfa[index] = {
            num_mes: (mes_dashboard_latex && mes_dashboard_latex.value) ? mes_dashboard_latex.value : index + 1,
            mes: '',
            vfa: 0,
        };
    }

    listaObjsVfa.forEach(obj => {
        let vfa = 0;
        let total_recep = 0;
        let mes = meses.filter((item) => item.value === obj.num_mes);
        for (let i = 0; i < datos_grafica_kg_secos_latex.length; i++) {
            for (let j = 0; j < datos_grafica_kg_secos_latex[i].results.length; j++) {
                if (datos_grafica_kg_secos_latex[i].mes[0] === obj.num_mes) {
                    vfa += datos_grafica_kg_secos_latex[i].results[j].analisis_laboratorio.vfa ? datos_grafica_kg_secos_latex[i].results[j].analisis_laboratorio.vfa : 0;
                    total_recep += 1;
                }
            }
        }

        let data = {
            num_mes: obj.num_mes,
            mes: mes[0].label,
            vfa: vfa !== 0 && total_recep !== 0 ? vfa/total_recep : 0
        }
        dataChartVfa.push(data)
    })
    // Grafica de NH3
    for (let index = 0; index < listaObjsNh3.length; index++) {
        listaObjsNh3[index] = {
            num_mes: (mes_dashboard_latex && mes_dashboard_latex.value) ? mes_dashboard_latex.value : index + 1,
            mes: '',
            nh3: 0,
        };
    }

    listaObjsNh3.forEach(obj => {
        let nh3 = 0;
        let total_recep = 0;
        let mes = meses.filter((item) => item.value === obj.num_mes);
        for (let i = 0; i < datos_grafica_kg_secos_latex.length; i++) {
            for (let j = 0; j < datos_grafica_kg_secos_latex[i].results.length; j++) {
                if (datos_grafica_kg_secos_latex[i].mes[0] === obj.num_mes) {
                    nh3 += datos_grafica_kg_secos_latex[i].results[j].analisis_laboratorio.nh3 ? datos_grafica_kg_secos_latex[i].results[j].analisis_laboratorio.nh3 : 0;
                    total_recep += 1;
                }
            }
        }

        let data = {
            num_mes: obj.num_mes,
            mes: mes[0].label,
            nh3: nh3 !== 0 && total_recep !== 0 ? nh3/total_recep : 0
        }
        dataChartNh3.push(data)
    })

    // GRAFICA DE COSTO FLETES
    for (let index = 0; index < listaObjsFlete.length; index++) {
        listaObjsFlete[index] = {
            num_mes: (mes_dashboard && mes_dashboard.value) ? mes_dashboard.value : index + 1,
            mes: '',
            total_flete_ersa: 0,
            total_flete_proveedor: 0
        };
    }

    listaObjsFlete.forEach(obj => {
        let total_ersa, total_provedor = 0;
        let total_recep = 0;
        let mes = meses.filter((item) => item.value === obj.num_mes);
        for (let i = 0; i < data_fletes.length; i++) {
            if (data_fletes[i].mes[0] === obj.num_mes) {
                total_ersa = data_fletes[i].total_flete_ersa;
                total_provedor = data_fletes[i].total_flete_proveedor;
            }
        }

        let data = {
            num_mes: obj.num_mes,
            mes: mes[0].label,
            total_flete_ersa: total_ersa,
            total_flete_proveedor: total_provedor
        }
        dataChartFlete.push(data)
    })

    // GRAFICA DE DRC
    for (let index = 0; index < listaObjsDrc.length; index++) {
        listaObjsDrc[index] = {
            num_mes: (mes_dashboard && mes_dashboard.value) ? mes_dashboard.value : index + 1,
            mes: '',
            drc_minimo: 0,
            drc_maximo: 0,
            drc_ponderado: 0
        };
    }

    listaObjsDrc.forEach(obj => {
        data_grafica_drc
        let drc_minimo = 0, drc_maximo = 0, drc_ponderado = 0;
        let mes = meses.filter((item) => item.value === obj.num_mes);
        for (let i = 0; i < data_grafica_drc.length; i++) {
            if (data_grafica_drc[i].mes[0] === obj.num_mes) {
                drc_minimo = data_grafica_drc[i].drc_minimo;
                drc_maximo = data_grafica_drc[i].drc_maximo;
                drc_ponderado = data_grafica_drc[i].drc_ponderado;
            }
        }

        let data = {
            num_mes: obj.num_mes,
            mes: mes[0].label,
            drc_minimo: drc_minimo,
            drc_maximo: drc_maximo,
            drc_ponderado: drc_ponderado
        }
        dataChartDrc.push(data)
    })

    // Data para la seccion de Latex en la grafica de recepciones.
    for (let i = 0; i < datos_grafica_results_latex.length; i++) {
        kg_humedos_totales_latex += datos_grafica_results_latex[i].peso_neto;
        kg_secos_totales_latex += datos_grafica_results_latex[i].peso_neto * (datos_grafica_results_latex[i].analisis_laboratorio.drc_promedio ? datos_grafica_results_latex[i].analisis_laboratorio.drc_promedio : 0);
    }
    // Data para la seccion de Solidos en la grafica de recepciones.
    for (let i = 0; i < datos_grafica_results_solido.length; i++) {
        kg_humedos_totales_solido += datos_grafica_results_solido[i].peso_neto;
        kg_secos_totales_solido += datos_grafica_results_solido[i].peso_neto * (datos_grafica_results_solido[i].analisis_laboratorio.drc_promedio ? datos_grafica_results_solido[i].analisis_laboratorio.drc_promedio : 0);
    }
    // DATA DE LAS GRAFICAS
    kg_humedos_totales = kg_humedos_totales_latex + kg_humedos_totales_solido;
    kg_secos_totales = kg_secos_totales_latex + kg_secos_totales_solido;

    // PROCESADO DE LOS TOTALES PARA LA DIFERENCIA DE KG SOLIDO
    data_kilos_secos.forEach(kilos => {
        totalKilosSecosComprados += kilos.kg_comprados
        totalKilosSecosProducidos += kilos.kg_producidos
        totalDiferenciaKilosSecos += kilos.diferencia
    })

    totalPorcentajeKilosSecos = (totalKilosSecosProducidos/totalKilosSecosComprados) - 1

    return (
        <div className="col-12 row p-0 m-0">
            <div className="d-flex align-items-baseline ml-3 col-12">
                <img src={require(`assets/img/icons/dashboard_activo.png`)} alt="Usuario" className="title_img mr-2" />
                <img src={require('assets/img/icons/linea.png')} alt="Linea" className="title_img mr-2" />
                <h2 className="mr-2 titulo">{"DASHBOARD"}</h2>
                <span className="subtitulo">{"GERENCIAL"}</span>
            </div>

            <div className="col-12 col-md-12 mb-2">
                <Tabs
                    activeKey={tipo_materia_dashboard}
                    tabBarPosition="top"
                    onChange={tipo_materia_dashboard =>{tipoMateriaDashboardChange(tipo_materia_dashboard)}}
                    renderTabBar={() => <TabBar />}
                    renderTabContent={() => <TabContent />}
                >
                    <TabPane tab="LÁTEX" key="LATEX"><div></div></TabPane>
                    <TabPane tab="SÓLIDOS" key="SOLIDO"><div></div></TabPane>
                </Tabs>
                <CardEmpty noShadow>
                    <FiltrosDashboard {...props}
                        filtroMateriaChangeFiltrada={filtroMateriaPrimaDashboard} materia_id_filtrada={materia_prima_dashboard}
                        filtroFincaChange={filtroFincaDashboard} finca_id={finca_dashboard}
                        filtroMesChange={filtroMesDashboard} mes={mes_dashboard}
                        filtroAnioChange={filtroAnioDashboard} anio_dashboard={anio_dashboard}
                        dashboardG={true} tipo_materia={tipo_materia_dashboard}
                    />
                </CardEmpty>
            </div>
            <LoadMask loading={loader} blur
                title={"ESPERE"} message={"Cargando información..."}
            >
            <div className="col-12 col-md-12 mb-2">
                <CardEmpty noShadow>
                    <div className="col-12">
                        <div className="d-flex align-items-center justify-content-center col-12 p-0">
                            <div className="d-flex flex-wrap align-items-center mr-5">
                                <div className="mb-3 mt-3">
                                    <img src={require(`assets/img/icons/recepciones_activo.png`)} alt="Recepcion" className="dashboard_img mr-2" />
                                </div>
                                <div className="mb-3 mt-3 d-flex flex-column text-right">
                                    <span className="bold verde-secundario">TOTAL RECEPCIONES</span>
                                    {tipo_materia_dashboard === 'LATEX' && (
                                        <h3 className="mb-1 ml-2 gris" style={{ whiteSpace: 'nowrap' }}>{total_recepciones_latex}</h3>
                                    )}
                                    {tipo_materia_dashboard === 'SOLIDO' && (
                                        <h3 className="mb-1 ml-2 gris" style={{ whiteSpace: 'nowrap' }}>{total_recepciones_solido}</h3>
                                    )}
                                </div>
                            </div>
                            <div className="fondo-totales mr-2 mb-2 mr-5">
                                <div className="d-flex justify-content-between align-items-end">
                                    <label className="gris mr-3">Kg. Humedos</label>
                                    {tipo_materia_dashboard === 'LATEX' && (
                                        <span className="text-md verde-secundario"><RenderKilogramo value={kg_humedos_totales_latex} /></span>
                                    )}
                                    {tipo_materia_dashboard === 'SOLIDO' && (
                                        <span className="text-md verde-secundario"><RenderKilogramo value={kg_humedos_totales_solido} /></span>
                                    )}
                                </div>
                            </div>
                            <div className="fondo-totales mr-2 mb-2">
                                <div className="d-flex justify-content-between align-items-end">
                                    <label className="gris mr-3">Kg. Secos</label>
                                    {tipo_materia_dashboard === 'LATEX' && (
                                        <span className="text-md verde-secundario"><RenderKilogramo value={kg_secos_totales_latex} /></span>
                                    )}
                                    {tipo_materia_dashboard === 'SOLIDO' && (
                                        <span className="text-md verde-secundario"><RenderKilogramo value={kg_secos_totales_solido} /></span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </CardEmpty>
            </div>
            <div className="col-12 row mb-2">
                <div className="col-12 col-md-12 mb-2">
                    <CardEmpty noShadow>
                        <div className="col-12">
                            <div className="d-flex justify-content-between mb-3">
                                <div className="d-flex align-items-center col-9 p-0">
                                    <img src={require(`assets/img/icons/ubicacion_fincas.png`)} alt="Usuario" className="title_img mr-2" />
                                    <span className="bold verde-secundario">UBICACIÓN DE FINCAS</span>
                                </div>
                                <div className="col-3">
                                    <Link className="btn btn-secondary mr-5" to="/reporte/ubicacion_geografica_fincas">VER REPORTE</Link>
                                </div>
                            </div>
                        </div>
                        <div className="flex-1" style={{
                            width: '100%', height: '100%',
                        }}
                        >
                            <CardEmpty noPadding noShadow>
                                <div style={{ height: '30vh', width: '100%' }}>
                                    <GoogleMapReact
                                        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
                                        defaultCenter={center}
                                        defaultZoom={zoom}
                                    >
                                        {fincas_mapa && fincas_mapa.map(finca => (
                                            <InformacionFinca key={finca.id} lat={finca.lat} lng={finca.long} finca={finca} />
                                        ))}
                                    </GoogleMapReact>
                                </div>
                            </CardEmpty>
                        </div>
                        <div className="mb-3"></div>
                    </CardEmpty>
                </div>
                <div className="col-12 col-md-12">
                    <CardEmpty noShadow>
                        <div className="d-flex flex-column">
                            <div className="flex-fill d-flex justify-content-center">
                                <ResponsiveContainer width="85%" height="100%" minWidth={450} minHeight={350}>
                                    <BarChart width={730} height={250} data={dataChartDrc}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="mes" />
                                        <YAxis unit=" %" />
                                        <Tooltip content={renderTooltipDrc} />
                                        <Legend content={renderLegend} align="center" verticalAlign="top" layout="vertical" iconType="circle" title="GRÁFICA DE DRC" />
                                        <Bar dataKey={`drc_minimo`} value={'MINIMO'} fill={'#359d9e'} />
                                        <Bar dataKey={`drc_maximo`} value={'MÁXIMO'} fill={'#215273'} />
                                        <Bar dataKey={`drc_ponderado`} value={'PONDERADO'} fill={'#2e719e'} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </CardEmpty>
                </div>
            </div>
            <div className="col-12 row mb-2">
                <div className="col-12 col-md-6">
                    <CardEmpty noShadow>
                        <div className="d-flex flex-column">
                            <div className="flex-fill d-flex justify-content-center">
                                <ResponsiveContainer width="85%" height="100%" minWidth={450} minHeight={350}>
                                    <BarChart width={730} height={250} data={tipo_materia_dashboard === 'LATEX' ? dataChartLatex : dataChartSolido}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="mes" />
                                        <YAxis unit=" KG" />
                                        <Tooltip content={renderTooltipKgSecos} />
                                        <Legend content={renderLegend} align="center" verticalAlign="top" layout="vertical" iconType="circle" title="GRÁFICA DE KILOS SECOS" />
                                        {tipo_materia_dashboard === 'LATEX' && (
                                            <Bar dataKey={`kg_secos`} value={'LÁTEX'} fill={'#359d9e'} />
                                        )}
                                        {tipo_materia_dashboard === 'SOLIDO' && (
                                            <Bar dataKey={`kg_secos`} value={'SOLIDOS'} fill={'#215273'} />
                                        )}
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </CardEmpty>
                </div>
                <div className="col-12 col-md-6">
                    <CardEmpty noShadow>
                        <div className="d-flex flex-column">
                            <div className="flex-fill d-flex justify-content-center">
                                <ResponsiveContainer width="85%" height="100%" minWidth={450} minHeight={350}>
                                    <BarChart width={730} height={250} data={dataChartFlete}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="mes" />
                                        <YAxis unit=" $" />
                                        <Tooltip content={renderTooltipFletes} />
                                        <Legend content={renderLegend} align="center" verticalAlign="top" layout="vertical" iconType="circle" title="GRÁFICA COSTO FLETES" />
                                        <Bar dataKey={`total_flete_ersa`} value={'ERSA'} fill={'#359d9e'} />
                                        <Bar dataKey={`total_flete_proveedor`} value={'FINCA'} fill={'#215273'} />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </CardEmpty>
                </div>
            </div>
            {tipo_materia_dashboard === 'LATEX' && (
                <div className="col-12 row mb-2">
                    <div className="col-12 col-md-6">
                        <CardEmpty noShadow>
                            <div className="d-flex flex-column">
                                <div className="flex-fill d-flex justify-content-center">
                                    <ResponsiveContainer width="85%" height="100%" minWidth={450} minHeight={350}>
                                        <BarChart width={730} height={250} data={dataChartNh3}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="mes" />
                                            <YAxis unit=" %" />
                                            <Tooltip content={renderTooltipNh3} />
                                            <Legend content={renderLegend} align="center" verticalAlign="top" layout="vertical" iconType="circle" title="GRÁFICA DE NH3" />
                                            <Bar dataKey={`nh3`} value={'LATEX'} fill={'#359d9e'} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </CardEmpty>
                    </div>
                    <div className="col-12 col-md-6">
                        <CardEmpty noShadow>
                            <div className="d-flex flex-column">
                                <div className="flex-fill d-flex justify-content-center">
                                    <ResponsiveContainer width="85%" height="100%" minWidth={450} minHeight={350}>
                                        <BarChart width={730} height={250} data={dataChartVfa}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="mes" />
                                            <YAxis unit=" %" />
                                            <Tooltip content={renderTooltipVfa} />
                                            <ReferenceLine y={0.060} stroke="#000" />
                                            <Legend content={renderLegend} align="center" verticalAlign="top" layout="vertical" iconType="circle" title="GRÁFICA DE VFA" />
                                            <Bar dataKey={`vfa`} value={'LATEX'} fill={'#359d9e'} />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </CardEmpty>
                    </div>
                </div>
            )}
            <div className="col-12 row mb-2">
                <br/>
                {tipo_materia_dashboard === 'SOLIDO' && (
                    <Fragment>
                        <ProduccionSubmateria tipo_materia_dashboard={tipo_materia_dashboard} />
                        <div className="col-12 col-md-6">
                            <CardEmpty noShadow>
                                <div className="col-12">
                                    <div className="d-flex align-items-center col-12 mb-3 p-0">
                                        <img src={require(`assets/img/icons/ubicacion_fincas.png`)} alt="Usuario" className="title_img mr-2" />
                                        <span className="bold verde-secundario">DIFERENCIA KG SOLIDOS</span>
                                    </div>
                                </div>
                                <div className="inicio-tabla form-group np-r p-0 mb-1">
                                    <Grid hover striped bordered={false} loading={loader} data={{results: data_kilos_secos}} pagination={false}>
                                        <TableHeaderColumn
                                            dataField="id"
                                            isKey
                                            hidden
                                        />
                                        <TableHeaderColumn
                                            dataField="mes"
                                        >
                                            MES
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="kg_comprados"
                                            dataFormat={cell => <RenderKilogramo value={cell}/>}
                                        >
                                            KG COMPRADOS
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="kg_producidos"
                                            dataFormat={cell => <RenderKilogramo value={cell}/>}
                                        >
                                            KG PRODUCIDOS
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="diferencia"
                                            dataFormat={cell => <RenderKilogramo value={cell}/>}
                                        >
                                            DIFERENCIA
                                        </TableHeaderColumn>
                                        <TableHeaderColumn
                                            dataField="porcentaje"
                                            dataFormat={cell => {
                                                if (cell < 0) {
                                                    return <span className="rojo"><RenderNumberPercentage value={cell}/></span>
                                                }
                                                return <RenderNumberPercentage value={cell}/>
                                            }}
                                        >
                                            PORCENTAJE
                                        </TableHeaderColumn>
                                    </Grid>
                                    <div className="react-bs-table-container total-final">
                                        <div className="react-bs-table">
                                            <div className="react-bs-container-header table-header-wrapper">
                                                <table className="table table-hover table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <th className="text-left">TOTAL</th>
                                                            <th className="text-left"><RenderKilogramo value={totalKilosSecosComprados}/></th>
                                                            <th className="text-left"><RenderKilogramo value={totalKilosSecosProducidos}/></th>
                                                            <th className="text-left"><RenderKilogramo value={totalDiferenciaKilosSecos}/></th>
                                                            <th className="text-left"><RenderNumberPercentage value={totalPorcentajeKilosSecos}/></th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardEmpty>
                        </div>
                    </Fragment>
                )}
                <br/>   
                <div className="col-12 col-md-6">
                {tipo_materia_dashboard === 'LATEX' && (
                    <CardEmpty noShadow>
                        <div className="col-12">
                            <div className="d-flex align-items-center col-12 mb-3 p-0">
                                <img src={require(`assets/img/icons/recepciones_activo.png`)} alt="Usuario" className="title_img mr-2" />
                                <span className="bold verde-secundario">TOP 10 FINCAS LÁTEX</span>
                            </div>
                        </div>
                        <Grid hover striped loading={loader} data={{ "results": data_top_recepciones_latex }} pagination={false}
                            className="pb-4"
                        >
                            <TableHeaderColumn
                                dataField="id"
                                isKey
                                hidden
                            />
                            <TableHeaderColumn width="5" />
                            <TableHeaderColumn
                                dataField="nombre"
                                dataSort
                                width="30"
                            >
                                NOMBRE
                                        </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total_kilos_secos_latex"
                                dataSort
                                dataAlign="right"
                                width="30"
                                dataFormat={(cell) => <RenderKilogramo value={cell} />}
                            >
                                KG. SECOS
                                        </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="drc_promedio_latex"
                                dataSort
                                dataAlign="right"
                                width="30"
                                dataFormat={(cell) => <RenderNumberPercentage value={cell} decimalScale={2} />}
                            >
                                DRC PROMEDIO
                                        </TableHeaderColumn>
                            <TableHeaderColumn width="5" />
                        </Grid>
                    </CardEmpty>
                    )}
                    {tipo_materia_dashboard === 'SOLIDO' && (
                    <CardEmpty noShadow>
                        <div className="col-12">
                            <div className="d-flex align-items-center col-12 mb-3 p-0">
                                <img src={require(`assets/img/icons/recepciones_activo.png`)} alt="Usuario" className="title_img mr-2" />
                                <span className="bold verde-secundario">TOP 10 FINCAS SÓLIDOS</span>
                            </div>
                        </div>
                        <Grid hover striped loading={loader} data={{ "results": data_top_recepciones_solido }} pagination={false}
                            className="pb-4"
                        >
                            <TableHeaderColumn
                                dataField="id"
                                isKey
                                hidden
                            />
                            <TableHeaderColumn width="5" />
                            <TableHeaderColumn
                                dataField="nombre"
                                dataSort
                                width="30"
                            >
                                NOMBRE
                                        </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total_kilos_secos_solido"
                                dataSort
                                dataAlign="right"
                                width="30"
                                dataFormat={(cell) => <RenderKilogramo value={cell} />}
                            >
                                KG. SECOS
                                        </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="drc_promedio_solido"
                                dataSort
                                dataAlign="right"
                                width="30"
                                dataFormat={(cell) => <RenderNumberPercentage value={cell} decimalScale={2} />}
                            >
                                DRC PROMEDIO
                                        </TableHeaderColumn>
                            <TableHeaderColumn width="5" />
                        </Grid>
                    </CardEmpty>
                    )}
                </div>
            </div>
            </LoadMask>
        </div>
    )
};

export default DashboardGerencial;
