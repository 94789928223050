import React from 'react';
import moment from 'moment/moment';
import _ from 'lodash';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import Grid from '../../../Utils/Grid';
import { RenderKilogramo, RenderCurrency, RenderDollar } from '../../../Utils/renderField/renderReadField';
import { FACTURA_ESPECIAL, PAGOS_TRIMESTRALES, PAGOS_DIRECTOS, PEQUEÑO_CONTRIBUYENTE, EXPORTADOR, FLETE_INTERNO_COSTO_POR_KG } from '../../../../../utility/constants';

const expandComponent = (row) => {
    let _data = row.detalles ? row.detalles.slice() : [];
    return(
        <div className="row d-flex flex-row justify-content-center">
            <div className="col-11 ml-5">
                <div className="" style={{width: "100%"}}>
                    <BootstrapTable data={[row]} headerStyle={ { backgroundColor: '#e24647' } }>
                        <TableHeaderColumn dataField="finca" isKey
                            dataFormat={cell => cell && cell.razon_social ? cell.razon_social : ''}
                        >
                            BENEFICIARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="finca"
                            dataFormat={cell => cell && cell.perfil && cell.perfil.nombre ? cell.perfil.nombre : ''}
                        >
                            PERFIL
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="finca"
                            dataFormat={cell => cell && cell.municipio_text ? cell.municipio_text : ''}
                        >
                            MUNICIPIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="finca"
                            dataAlign="right"
                            dataFormat={ (cell, row)=> row && row.regimen_fiscal_compra ? row.regimen_fiscal_compra : (cell.regimen_fiscal_nombre ? cell.regimen_fiscal_nombre : '') }
                        >
                            REGIMEN FISCAL
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        </div>
    )
};

const getValorCalculoIva = (row, variables_calculo_impuestos) => {
    // Se determina el valor a utilizar para calcular el total sin IVA.
    // El valor se toma desde las constantes definidas en el Backend, que son configurables en caso de que cambien.
    // Por ejemplo, si el porcentaje de IVA es 12% entonces el valor sin IVA se calcula como
    //     VALOR_SIN_IVA = VALOR / (1 + PORCENTAJE_IVA)
    // No se resta el valor de IVA para los regímenes de finca EXPORTADOR y PEQUEÑO CONTRIBUYENTE.
    let regimen_fiscal = _.get(row, 'finca.regimen_fiscal', 0);
    const regimen_fiscal_compra = _.get(row, 'orden_compra.regimen_fiscal', 0);
    regimen_fiscal = regimen_fiscal_compra !== 0 ? regimen_fiscal_compra : regimen_fiscal;
    let porcentaje_iva = 1;
    switch(regimen_fiscal) {
        case FACTURA_ESPECIAL:
            porcentaje_iva+= _.get(variables_calculo_impuestos, 'REGIMEN_FACTURA_ESPECIAL.PORCENTAJE_DESCUENTO_IVA', 0);
            break;
        case PAGOS_TRIMESTRALES:
            porcentaje_iva+= _.get(variables_calculo_impuestos, 'REGIMEN_PAGOS_TRIMESTRALES.PORCENTAJE_DESCUENTO_IVA', 0);
            break;
        case PAGOS_DIRECTOS:
            porcentaje_iva+= _.get(variables_calculo_impuestos, 'REGIMEN_PAGOS_DIRECTOS.PORCENTAJE_DESCUENTO_BASE_IVA', 0);
            break;
        case PEQUEÑO_CONTRIBUYENTE:
        case EXPORTADOR:
        default:
            // No se resta el IVA al valor total
            break;
    }
    return porcentaje_iva;
}

const Detalle = (props) => {
    const {loader, data, item, page, pageChange, variables_calculo_impuestos} = props;
    return (
        <div>
            <div className="mb-4">
                <CardEmpty noShadow>
                    <div className=" col-12 form-group np-r p-0 mb-1 inicio-tabla text-xs">
                        <Grid hover striped loading={loader} data={item}
                            pagination onPageChange={pageChange} page={page}
                            className="pt-3" expandableRow={()=>true}
                            expandComponent={expandComponent}
                        >
                            <TableHeaderColumn
                                dataField="ingreso"
                                dataSort
                                isKey
                                dataFormat={(cell,row) => cell && cell.id ? cell.id : ""}
                                // width="17"
                            >
                                NO. RECEP.
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="ingreso"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.fecha_entrada ? moment(cell.fecha_entrada).format('DD-MM-YYYY') : "#"+0}
                                // width="15"
                            >
                                FECHA INGRESO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="orden_compra"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.fecha_liquidacion ? moment(cell.fecha_liquidacion).format('DD-MM-YYYY') : "#"+0}
                                // width="15"
                            >
                                FECHA LIQ.
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="finca"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.nombre ? cell.nombre : ""}
                                // width="17"
                            >
                                FINCA
                            </TableHeaderColumn>
                            {/* <TableHeaderColumn
                                dataField="finca"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.nombre ? cell.nombre : ""}
                                // width="17"
                            >
                                BENEFICIARIO
                            </TableHeaderColumn> */}
                            <TableHeaderColumn
                                dataField="materia_prima"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.nombre ? cell.nombre : ""}
                                // width="17"
                            >
                                PRODUCTO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="kilos_humedos"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderKilogramo value={cell ? cell : 0}/>}
                                // width="17"
                            >
                                KG. HUMEDOS
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="kilos_secos"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderKilogramo value={cell ? cell : 0}/>}
                                // width="17"
                            >
                                KG. SECOS
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total_dolares"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderDollar value={cell ? cell : 0}/>}
                                // width="17"
                            >
                                TOTAL $
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="precio_venta"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell, row) => {
                                    // El precio_venta (precio unitario) debe incluir el precio unitario de flete
                                    const { precio_venta=0, costo_flete=0, tipo_costo_flete=0 } = row;
                                    const total_precio_venta = precio_venta + (tipo_costo_flete === FLETE_INTERNO_COSTO_POR_KG ? costo_flete : 0);
                                    return <RenderDollar value={total_precio_venta} decimalScale={2}/>
                                }}
                                // width="17"
                            >
                                PRECIO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total_quetzales"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                // width="17"
                            >
                                TOTAL Q
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="orden_compra"
                                dataSort
                                dataFormat={(cell,row) => cell && cell.id ? cell.id : ""}
                                // width="17"
                            >
                                NO. LIQ.
                            </TableHeaderColumn>
                            {/* <TableHeaderColumn
                                dataField="finca"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.perfil && cell.perfil.nombre ? cell.perfil.nombre : ""}
                                // width="17"
                            >
                                PERFIL
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="finca"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.nombre ? cell.nombre : ""}
                                // width="17"
                            >
                                MUNICIPIO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="finca"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.regimen_fiscal_nombre ? cell.regimen_fiscal_nombre: ""}
                                // width="17"
                            >
                                REGIMEN
                            </TableHeaderColumn> */}
                            <TableHeaderColumn
                                dataField="cambio_dia"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                // width="17"
                            >
                                TIPO CAMBIO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total_dolares"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderDollar value={cell ? cell / getValorCalculoIva(row, variables_calculo_impuestos) : 0}/>}
                                // width="17"
                            >
                                TOTAL $ SIN IVA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total_quetzales"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderCurrency value={cell ? cell / getValorCalculoIva(row, variables_calculo_impuestos) : 0}/>}
                                // width="17"
                            >
                                TOTAL Q SIN IVA
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </CardEmpty>
            </div>
        </div>
    )
};

export default Detalle;
