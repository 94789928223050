import React from 'react'
import {ProgressBar} from "react-step-progress-bar";

let InfoPila = props => {
    const {handleSubmit, activar, closeModal} = props;
    return (
        <div className="d-flex flex-column flex-lg-row  justify-content-between mb-4">
            <div className="d-flex align-items-baseline mr-4">
                <h6 className="mr-2">CAPACIDAD TOTAL</h6>
                <h4 className="gris mr-2">360</h4>
                <h6>CM</h6>
                <img src={require('assets/img/icons/linea.png')} alt="Linea" className="title_img mx-3"/>
                <h6 className="mr-2">CAPACIDAD DISPONIBLE</h6>
                <h4 className="gris mr-2">96</h4>
                <h6>CM</h6>
            </div>

            <div className="d-flex flex-1 align-items-baseline">
                <div className="flex-1">
                    <ProgressBar
                        percent={75}
                        height={20}
                        filledBackground="linear-gradient(to right, #035192, #55c4d4)"
                        className="flex-1"
                    />
                </div>
                <h4 className="gris mr-2">75%</h4>
                <h6>COMPLETADO</h6>
            </div>
        </div>
    )};
export default InfoPila;
