import {handleActions} from 'redux-actions';
import {api} from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import moment from 'moment';

const LOADER = 'LOADER_ANALISIS_BODEGA';
const DATA = 'DATA_ANALISIS_BODEGA';
const ITEM_DATA = 'ITEM_ANALISIS_BODEGA';
const ITEM_BODEGA_AB = 'ITEM_BODEGA_AB';
const PAGE = 'PAGE_ANALISIS_BODEGA';
const ORDERING = 'ORDERING_ANALISIS_BODEGA';
const SEARCH = 'SEARCH_ANALISIS_BODEGA';
const TIPOS_VEHICULOS_ANALISIS_BODEGA = 'TIPOS_VEHICULOS_ANALISIS_BODEGA';
const PRODUCTOS_LATEX = 'PRODUCTOS_LATEX';
const PRODUCTOS_SOLIDO = 'PRODUCTOS_SOLIDO';
const DRC_TEMPORAL = 'DRC_TEMPORAL';
const VFA_TEMPORAL = 'VFA_TEMPORAL';
const TAB_ANALISIS_BODEGA = 'TAB_ANALISIS_BODEGA';
const FINALIZADOS_PAGE = 'ANALISIS_BODEGA_FINALIZADOS_PAGE';
const FINALIZADOS_FECHA_INICIAL = 'ANALISIS_BODEGA_FINALIZADOS_FECHA_INICIAL';
const FINALIZADOS_FECHA_FINAL = 'ANALISIS_BODEGA_FINALIZADOS_FECHA_FINAL';

const DATA_PILAS_ANALISIS = "DATA_PILAS_ANALISIS";
const DATA_PILAS_VALIDAR = "DATA_PILAS_VALIDAR";
const DATA_PILAS_HISTORICO = "DATA_PILAS_HISTORICO";
// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setItemBodega = item_bodega => ({
    type: ITEM_BODEGA_AB,
    item_bodega,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setPageFinalizados = page_finalizados => ({
    type: FINALIZADOS_PAGE,
    page_finalizados,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setDataPilasAnalisis = data_pilas_analisis => ({
    type: DATA_PILAS_ANALISIS,
    data_pilas_analisis,
});

const setDataPilasValidar = data_pilas_validar => ({
    type: DATA_PILAS_VALIDAR,
    data_pilas_validar,
});

const setDataPilasHistorico = data_pilas_historico => ({
    type: DATA_PILAS_HISTORICO,
    data_pilas_historico,
});


// ------------------------------------
// Actions
// ------------------------------------
const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().analisis_bodegas;
    const params = {page};
    params.ordering = resource.ordering;
    params.search = resource.search;
    switch(resource.tab) {
        case 'POR_VALIDAR':
            params.no_paginar = true;
            params.estado = 'validar';
            break;
        case 'FINALIZADAS':
            params.estado = 'finalizados';
            if (resource.fecha_inicial_finalizados && resource.fecha_final_finalizados) {
                params.fecha_inicial = moment(resource.fecha_inicial_finalizados).format("YYYY-MM-DD") + ' 00:00:00';
                params.fecha_final = moment(resource.fecha_final_finalizados).format("YYYY-MM-DD") + ' 23:59:59';
            }
            break;
        case 'ACTIVAS':
        default:
            params.no_paginar = true;
            break;
    }
    params.de_bodega = true;
    dispatch(setLoader(true));
    api.get('laboratorio', params).then((response) => {
        switch(resource.tab){
            case 'ACTIVAS': dispatch(setDataPilasAnalisis(response));
                break;
            case 'POR_VALIDAR': dispatch(setDataPilasValidar(response));
                break;
            case 'FINALIZADAS': dispatch(setDataPilasHistorico(response));
                break;
        }
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leerEdicion = (id, row) => (dispatch, getStore) => {
    dispatch(push(`/laboratorio/analisis_pilas/${id}/editar`));
    dispatch(setLoader(true));
    const resource = getStore().analisis_bodegas;
    let params = {'de_bodega': true};
    api.get(`${'laboratorio'}/${id}`, params).then((response) => {
        dispatch(setItem(response));
        response.info_bodega_analisis && (dispatch(setItemBodega(response.info_bodega_analisis)));
        dispatch(initializeForm('AnalisisBodegasForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = (id, row, estado = false) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const resource = getStore().analisis_bodegas;
    let params = {'de_bodega': true};
    if (estado)
        params.estado = estado;
    api.get(`${'laboratorio'}/${id}`, params).then((response) => {
        dispatch(setItem(response));
        response.info_bodega_analisis && (dispatch(setItemBodega(response.info_bodega_analisis)));
        dispatch(initializeForm('AnalisisBodegasForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const crear = data => (dispatch) => {
    data.vehiculo = data.vehiculo.id;
    data.finca = data.finca.id;
    data.piloto = data.piloto.id;
    data.tipo_materia = data.tipo_materia.id;

    dispatch(setLoader(true));
    let params = {'de_bodega': true};

    api.post('laboratorio', data, params).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => async (dispatch) => {
    dispatch(setLoader(true));
    let params = {'de_bodega': true};
    try {
        await api.put(`${'laboratorio'}/${id}`, data, params);
        NotificationManager.success('Análisis de pila actualizado con éxito', 'Éxito', 3000);
    } catch(error) {
        NotificationManager.error(error && error.detail ? error.detail : 'Error al actualizar el análisis de pila', 'ERROR', 3000);
        // 
    } finally {
        dispatch(setLoader(false));
    }
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${'laboratorio'}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().analisis_bodegas.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const getProductosLatex = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get('materia_prima', {search: "Latex", no_paginar: true,}).then((data) => {
        const productos = data.results;
        if (productos.length > 0) {
            //Mutar cada producto pra que tenga value y label
            productos.forEach((producto) => {
                producto['value'] = producto.id;
                producto['label'] = producto.nombre
            })
        }
        dispatch({type: PRODUCTOS_LATEX, productos_latex: productos})
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getProductosSolido = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('materia_prima', {search: "Sólido", no_paginar: true,}).then((data) => {
        const productos = data.results;
        if (productos.length > 0) {
            //Mutar cada producto pra que tenga value y label
            productos.forEach((producto) => {
                producto['value'] = producto.id;
                producto['label'] = producto.nombre
            })
        }
        dispatch({type: PRODUCTOS_SOLIDO, productos_solido: productos})
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const setTab = (tab, page = 1) => (dispatch) => {
    dispatch({type: TAB_ANALISIS_BODEGA, tab});
    dispatch(listar(page));
};

const initialLoad = () => (dispatch) => {
    dispatch(getProductosLatex());
    dispatch(getProductosSolido());
};

const setDRCTemp = (key, value) => (dispatch, getStore) => {
    const store = getStore().analisis_bodegas;
    const drc_temporal = _.cloneDeep(store.drc_temporal);
    drc_temporal[key] = value;
    dispatch({type: DRC_TEMPORAL, drc_temporal});
};

const setVFATemp = (key, value) => (dispatch, getStore) => {
    const store = getStore().analisis_bodegas;
    const vfa_temporal = _.cloneDeep(store.vfa_temporal);
    vfa_temporal[key] = value;
    dispatch({type: VFA_TEMPORAL, vfa_temporal});
};

const clearDRCTemp = () => (dispatch) => {
    const vacio = {
        peso_seco: "",
        peso_plato: "",
        peso_humedo: ""
    };
    dispatch({type: DRC_TEMPORAL, drc_temporal: vacio})
};

const clearVFATemp = () => (dispatch) => {
    const vacio = {
        ml_bureta: "",
        peso: ""
    };
    dispatch({type: VFA_TEMPORAL, vfa_temporal: vacio})
};

const loadDataModal = (data) => (dispatch) => {
    dispatch({type: DRC_TEMPORAL, drc_temporal: {...data}});
    dispatch(establecerLaboratorista(data.laboratorista, data));
};

const loadDataModal2 = (data) => (dispatch) => {
    dispatch({type: VFA_TEMPORAL, vfa_temporal: {...data}});
    dispatch(establecerLaboratorista2(data.laboratorista, data));
};

const updateData = (data, key) => (dispatch, getStore) => {
    const storeForm = getStore().form.AnalisisBodegasForm;
    const valores = _.cloneDeep(storeForm.values);
    valores[`datos_${key}`][data.index] = data;
    dispatch(initializeForm('AnalisisBodegasForm', valores))
};

const establecerLaboratorista = (id, dato_actual) => (dispatch, getStore) => {
    const temp = getStore().analisis_bodegas.drc_temporal;
    api.get("user", {no_paginar: true,}).then(data => {
        const laboratorista = data.results;
        if (laboratorista.length > 0) {
            const usuario = _.find(laboratorista, {id: dato_actual.laboratorista.id ? dato_actual.laboratorista.id : dato_actual.laboratorista});
            temp.laboratorista = usuario;
            dispatch({type: DRC_TEMPORAL, drc_temporal: temp})
        }
    });
};

const establecerLaboratorista2 = (id, dato_actual) => (dispatch, getStore) => {
    const temp = getStore().analisis_bodegas.vfa_temporal;
    api.get("user", {no_paginar: true,}).then(data => {
        const laboratorista = data.results;
        if (laboratorista.length > 0) {
            const usuario = _.find(laboratorista, {id: dato_actual.laboratorista.id ? dato_actual.laboratorista.id : dato_actual.laboratorista});
            temp.laboratorista = usuario;
            dispatch({type: VFA_TEMPORAL, vfa_temporal: temp})
        }
    });
};

const enviarVaciado = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post('laboratorio/marcar_vaciado', {id}).then(data => {
        NotificationManager.success('Ingreso Listo para vaciar', 'Éxito', 3000);
        dispatch(push('/laboratorio/analisis_pilas'))
    }).catch(() => {
        NotificationManager.error('Error al marcar el ingreso para vacido', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    })
};

const validar_drc = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`laboratorio/${id}/pilas_validar_drc`).then(data => {
        NotificationManager.success('DRC validado correctamente', 'Éxito', 3000);
        dispatch(push('/laboratorio/validar_drc_pilas'))
    }).catch(() => {
        NotificationManager.error('Error al validar DRC', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    })
};
const rechazar_drc = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`laboratorio/${id}/rechazar_drc`).then(data => {
        NotificationManager.success('El análisis se a enviado de nuevo a laboratorio ', 'Éxito', 3000);
        dispatch(push('/laboratorio/validar_drc_pilas'))
    }).catch(() => {
        NotificationManager.error('Error al enviar de nuevo a laboratorio', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    })
};

const enviar_a_validacion = (id) => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`laboratorio/${id}/enviar_a_validacion`).then(data => {
        NotificationManager.success('Análisis enviado para volver a validarse', 'Éxito', 3000);
        dispatch(push('/laboratorio/analisis_pilas'))
    }).catch(() => {
        NotificationManager.error('Error al enviar a validación', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    })
};

/**
 * ----- ANULAR INGRESO -----
 * Con este método se envía a anular el ingreso / recepción siempre y cuando aún no tenga ninguno de sus pesos
 * registrados (movimientos)
 * @param id, es el id del ingreso
 * @param data, es la data donde irá la justificación de la anulación
 * @returns Status de la petición 200 si anulación con éxito, 400 si fallo
 */
const anularIngreso = (id, data = null) => (dispatch) => {
    api.put(`recepcion/${id}/anular_ingreso`, data).then(() => {
        dispatch(push('/laboratorio/analisis_pilas'));
        NotificationManager.success(`La recepción No. ${id} fue anulada.`, 'Éxito', 3000);
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    })
};

const pageFinalizadosChange = page_finalizados => (dispatch) => {
    dispatch(setPageFinalizados(page_finalizados));
    dispatch(listar(page_finalizados));
}
const fechaInicialFinalizadosChange = fecha_inicial_finalizados => (dispatch) => {
    dispatch({type: FINALIZADOS_FECHA_INICIAL, fecha_inicial_finalizados})
    dispatch(listar())
}
const fechaFinalFinalizadosChange = fecha_final_finalizados => (dispatch) => {
    dispatch({type: FINALIZADOS_FECHA_FINAL, fecha_final_finalizados})
    dispatch(listar())
}

export const actions = {
    leer,
    crear,
    editar,
    listar,
    setTab,
    eliminar,
    setDRCTemp,
    setVFATemp,
    updateData,
    initialLoad,
    leerEdicion,
    validar_drc,
    clearVFATemp,
    clearDRCTemp,
    onSortChange,
    rechazar_drc,
    searchChange,
    anularIngreso,
    enviarVaciado,
    loadDataModal,
    loadDataModal2,
    enviar_a_validacion,
    establecerLaboratorista,
    establecerLaboratorista2,
    pageFinalizadosChange,
    fechaInicialFinalizadosChange,
    fechaFinalFinalizadosChange,
};

export const reducers = {
    [LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, {data}) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, {item}) => {
        return {
            ...state,
            item,
        };
    },
    [ITEM_BODEGA_AB]: (state, {item_bodega}) => {
        return {
            ...state,
            item_bodega,
        };
    },
    [PAGE]: (state, {page}) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, {ordering}) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, {search}) => {
        return {
            ...state,
            search,
        };
    },
    [TIPOS_VEHICULOS_ANALISIS_BODEGA]: (state, {tipos_laboratorio}) => {
        return {
            ...state,
            tipos_laboratorio,
        };
    },
    [PRODUCTOS_LATEX]: (state, {productos_latex}) => {
        return {
            ...state,
            productos_latex,
        };
    },
    [PRODUCTOS_SOLIDO]: (state, {productos_solido}) => {
        return {
            ...state,
            productos_solido,
        };
    },
    [DRC_TEMPORAL]: (state, {drc_temporal}) => {
        return {
            ...state,
            drc_temporal,
        };
    },
    [VFA_TEMPORAL]: (state, {vfa_temporal}) => {
        return {
            ...state,
            vfa_temporal,
        };
    },
    [TAB_ANALISIS_BODEGA]: (state, {tab}) => {
        return {
            ...state,
            tab,
        };
    },
    [FINALIZADOS_PAGE]: (state, {page_finalizados}) => {
        return {
            ...state,
            page_finalizados,
        };
    },
    [FINALIZADOS_FECHA_INICIAL]: (state, {fecha_inicial_finalizados}) => {
        return {
            ...state,
            fecha_inicial_finalizados,
        };
    },
    [FINALIZADOS_FECHA_FINAL]: (state, {fecha_final_finalizados}) => {
        return {
            ...state,
            fecha_final_finalizados,
        };
    },
    [DATA_PILAS_ANALISIS]: (state, { data_pilas_analisis }) => {
        return {
            ...state,
            data_pilas_analisis,
        };
    },
    [DATA_PILAS_VALIDAR]: (state, { data_pilas_validar }) => {
        return {
            ...state,
            data_pilas_validar,
        };
    },
    [DATA_PILAS_HISTORICO]: (state, { data_pilas_historico }) => {
        return {
            ...state,
            data_pilas_historico,
        };
    },
};

export const initialState = {
    data_pilas_analisis:{
        results: [],
        count: 0,
    },
    data_pilas_validar:{
        results: [],
        count: 0,
    },
    data_pilas_historico:{
        results: [],
        count: 0,
    },
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    productos_latex: [],
    productos_solido: [],
    item: {},
    item_bodega: {},
    page: 1,
    ordering: '',
    search: '',
    tipos_laboratorio: {},
    drc_temporal: {},
    vfa_temporal: {},
    tab: 'ACTIVAS',
    page_finalizados: 1,
    fecha_inicial_finalizados: moment().startOf('month'),
    fecha_final_finalizados: moment().endOf('month'),
};

export default handleActions(reducers, initialState);
