import React, { Component } from 'react'
import moment from 'moment'
import CardEmpty from '../../../../Utils/Cards/CardEmpty'
import Grid from '../../../../Utils/Grid'
import { Link } from 'react-router-dom'
import { TableHeaderColumn } from 'react-bootstrap-table'
import { RenderCambio, RenderCurrency, RenderDollar, RenderKilogramo, RenderNumberPercentage } from '../../../../Utils/renderField/renderReadField'

const sumatoria_totales = (recepciones, retencion, retencionISR) => {
    let totales = {
        kilosHumedos: 0,
        kilosSecos: 0,
        totalDolares: 0,
        totalQuetzales: 0,
        totalALiquidar: 0
    }
    recepciones.forEach(recepcion => {
        totales.kilosHumedos += recepcion.pesokh
        totales.kilosSecos += recepcion.pesoks
        totales.totalDolares += recepcion.totald
        totales.totalQuetzales += recepcion.totalq
    })
    totales.totalALiquidar = totales.totalQuetzales - retencion - retencionISR
    return totales
}
class DetalleLiquidacionForm extends Component {
    render() {
        const { item_liquidacion, recepciones_liquidacion } = this.props
        const totales = sumatoria_totales(recepciones_liquidacion.results, item_liquidacion.retencion, item_liquidacion.retencionISR)
        return (
            <form>
                <div className="form-group grid-container">
                    <div className="d-flex justify-content-center mb-4">
                        <div className="col-8">
                            <div className="row col-12">
                                <h6 className="mb-4">DATOS GENERALES DE LIQUIDACION HISTÓRICO</h6>
                            </div>
                            <div className="row col-12 col-md-12 p-0">
                                <div className="col-md-6 p-0">
                                    <div className="col-12 d-flex flex-column">
                                        <label className="p-0 m-0">ID LIQUIDACIÓN</label>
                                        <span className="mb-1">{item_liquidacion.codliqu ? item_liquidacion.codliqu : ''}</span>
                                        <label className="p-0 m-0">FECHA DE LIQUIDACIÓN</label>
                                        <span className="mb-1">{item_liquidacion.fechaliquidacion ? moment(item_liquidacion.fechaliquidacion).format("DD/MM/YYYY") : ''}</span>
                                    </div>
                                </div>
                                <div className="col-md-6 p-0">
                                    <div className="col-12 d-flex flex-column">
                                        <label className="p-0 m-0">FINCA</label>
                                        <span className="mb-1">{item_liquidacion.finca ? item_liquidacion.finca : ''}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-12">
                            <Grid
                                striped
                                data={recepciones_liquidacion}
                                pagination={false}
                            >
                                <TableHeaderColumn
                                    dataField="codentr"
                                    width="10%"
                                    isKey
                                >
                                    ID RECEPCIÓN
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="fecharegistro"
                                    width="9%"
                                    dataFormat={cell => moment(cell).format('DD/MM/YYYY')}
                                >
                                    FECHA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="producto"
                                    width="14%"
                                >
                                    MATERIA PRIMA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="pesokh"
                                    width="12%"
                                    dataFormat={cell => <RenderKilogramo value={cell}/>}
                                >
                                    KILOS HÚMEDOS
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="drcPromedio"
                                    width="9%"
                                    dataFormat={cell => <RenderNumberPercentage value={cell/100}/>}
                                >
                                    DRC
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="pesoks"
                                    width="11%"
                                    dataFormat={cell => <RenderKilogramo value={cell}/>}
                                >
                                    KILOS SECOS
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="precioK"
                                    width="8%"
                                    dataFormat={cell => <RenderDollar value={cell}/>}
                                >
                                    PRECIO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="totald"
                                    width="9%"
                                    dataFormat={cell => <RenderDollar value={cell}/>}
                                >
                                    SUBTOTAL $
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="cambio"
                                    width="8%"
                                    dataFormat={(cell, row) => <RenderCambio value={row.totalq / row.totald}/>}
                                >
                                    CAMBIO
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="totalq"
                                    width="10%"
                                    dataFormat={cell => <RenderCurrency value={cell}/>}
                                >
                                    SUBTOTAL Q
                                </TableHeaderColumn>
                            </Grid>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <CardEmpty noShadow noPadding>
                                <div className="row col-12 m-0 py-3">
                                    <div className="wor col-1" />
                                    <div className="row col-10 p-0">
                                        <h6>TOTALES</h6>
                                    </div>
                                </div>
                                <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mb-4">
                                    <div className="row col-12 col-md-10 d-flex flex-wrap justify-content-center align-items-end">
                                        <div className="col-12 col-md-4 p-0">
                                            <div className="col-md-12 col-sm-12">
                                                <label className="m-0">RETENCIÓN</label>
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <RenderCurrency className="form-control" value={item_liquidacion.retencion}/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 p-0">
                                            <div className="col-md-12 col-sm-12">
                                                <label className="m-0">RETENCION ISR</label>
                                            </div>
                                            <div className="col-md-12 form-group">
                                                <RenderCurrency className="form-control" value={item_liquidacion.retencionISR}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mt-4 mb-4">
                                    <div className="row col-12 col-md-8 d-flex flex-wrap justify-content-center align-items-end">
                                        <div className="col-12 col-md-4 p-0">
                                            <div className="col-md-12 col-sm-12">
                                                <label className="m-0">KILOS HÚMEDOS</label>
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <h6 className="mb-1 ml-3 gris"><b>{totales.kilosHumedos.toFixed(2) + ' kilos'}</b></h6>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 p-0">
                                            <div className="col-md-12 col-sm-12">
                                                <label className="m-0">KILOS SECOS</label>
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <h6 className="mb-1 ml-3 gris"><b>{totales.kilosSecos.toFixed(2) + ' kilos'}</b></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mt-4 mb-4">
                                    <div className="row col-12 col-md-8 d-flex flex-wrap justify-content-center align-items-end">
                                        <div className="col-12 col-md-4 p-0">
                                            <div className="col-md-12 col-sm-12">
                                                <label className="m-0">TOTAL DÓLARES</label>
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <h6 className="mb-1 ml-3 gris"><b>{<RenderDollar value={totales.totalDolares}/>}</b></h6>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 p-0">
                                            <div className="col-md-12 col-sm-12">
                                                <label className="m-0">TOTAL QUETZALES</label>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <h6 className="mb-1 ml-3 gris"><b>{<RenderCurrency value={totales.totalQuetzales}/>}</b></h6>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-4 p-0">
                                            <div className="col-md-12 col-sm-12">
                                                <label className="m-0">TOTAL A LIQUIDAR</label>
                                            </div>
                                            <div className="col-md-12 col-sm-12">
                                                <h6 className="mb-1 ml-3 gris"><b>{<RenderCurrency value={totales.totalALiquidar}/>}</b></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardEmpty>
                        </div>
                    </div>
                    <div className="buttons-box mt-5">
                        <Link to="/historico/liquidaciones" className="btn btn-outline-dark mr-5 px-5">
                            REGRESAR
                        </Link>
                        <Link className="btn btn-secondary mr-5" to={`/historico/liquidaciones/${item_liquidacion.codliqu}/boleta`}>
                            IMPRIMIR BOLETA
                        </Link>
                    </div>
                </div>
            </form>
        )
    }
}

export default DetalleLiquidacionForm