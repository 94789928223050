import React, { Component } from 'react';
import classNames from 'classnames';
import AsyncSelect from 'react-select/lib/Async';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { api } from 'api';

const getFincas = (search) => {
    return api.get('finca', {search})
        .then((data) => data ? data.results : [])
        .catch(() => []);
};

export default class HeaderCompras extends Component {
    render() {
        const {
            finca_id, fecha_inicial, fecha_final,
            filtroFincaChange, fechaInicialChange, fechaFinalChange, getDataCompras,
        } = this.props;
        return (
            <div className="d-flex">
                <div className="d-flex flex-1">
                    <div className="row col-12">
                        {(filtroFincaChange !== undefined && filtroFincaChange !== null) && (
                            <div className="col-12 col-lg-3 my-3">
                                <AsyncSelect
                                    className={classNames('react-select-container')}
                                    backspaceRemovesValue={false}
                                    isSearchable={true}
                                    isClearable
                                    placeholder="Finca"
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={getFincas}
                                    onChange={e => filtroFincaChange(e ? e.id : -1)}
                                    value={finca_id}
                                    getOptionValue={(option) => (option['id'])}
                                    getOptionLabel={(option) => (option['nombre'])}
                                />
                            </div>
                        )}
                        {(fechaInicialChange !== undefined && fechaInicialChange !== null) && (
                            <div className="d-flex col-12 col-lg-3 my-3" style={{borderRadius: '1em'}}>
                                <div className="col-3 col-lg-5 p-0">
                                    <label className="my-1 mr-2 pl-0">Fecha inicio</label>
                                </div>
                                <div className="col-9 col-lg-7 p-0">
                                    <DatePicker
                                        showPopperArrow={false}
                                        dateFormat="DD/MM/YYYY"
                                        placeholderText="Fecha de inicio"
                                        onChange={(value) => {fechaInicialChange(value)}}
                                        selected={fecha_inicial}
                                    />
                                </div>
                            </div>
                        )}
                        {(fechaFinalChange !== undefined && fechaFinalChange !== null) && (
                            <div className="d-flex col-12 col-lg-3 my-3" style={{borderRadius: '1em'}}>
                                <div className="col-3 col-lg-5 p-0">
                                    <label className="my-1 mr-2 pl-0">Fecha final</label>
                                </div>
                                <div className="col-9 col-lg-7 p-0">
                                    <DatePicker
                                        showPopperArrow={false}
                                        dateFormat="DD/MM/YYYY"
                                        placeholderText="Fecha final"
                                        onChange={(value) => {fechaFinalChange(value)}}
                                        selected={fecha_final}
                                    />
                                </div>
                            </div>
                        )}
                        <div className="col-12 col-lg-3 my-3 text-right" style={{borderRadius: '1em'}}>
                            <button className="btn btn-primary" onClick={() => getDataCompras && getDataCompras()}>FILTRAR</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
