import { connect } from 'react-redux'
import { actions } from '../../../../../redux/modules/despachos'
import despachoVerBoleta from './despachoVerBoleta'

const ms2p = state => {
    return {
        ...state.despachos
    }
}

const md2p = { ...actions }

export default connect(ms2p, md2p)(despachoVerBoleta)