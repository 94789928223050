import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { renderField, renderSwitch, SelectField } from '../../../Utils/renderField/renderField';
import { tipos_laboratorio } from '../../../../../utility/constants';
import { Link } from 'react-router-dom'
import { validate, validators } from 'validate-redux-form';

const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
    'Email Inválido' : undefined

class LaboratoristaForm extends Component {
    render() {
        const { handleSubmit, editando } = this.props
        return (
            <form onSubmit={handleSubmit}>
                { editando == true ?
                    <h4>Editar Laboratorista</h4>
                    :
                    <h4>Agregar Laboratorista</h4>
                }
                <section className="d-flex">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="nombre" className="m-0">NOMBRE COMPLETO</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="nombre"
                                component={renderField}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="laboratorio" className="m-0">LABORATORIO</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="laboratorio"
                                component={SelectField}
                                options={tipos_laboratorio}
                            />
                        </div>
                    </div>
                </section>
                <section className="d-flex">
                    <div className="col-md-6 p-0">
                        <div className="col-12">
                            <label htmlFor="email" className="m-0">EMAIL</label>
                        </div>
                        <div className="col-md-12 form-group">
                            <Field
                                name="email"
                                component={renderField}
                                validate={email}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-6 form-group d-flex pt-4">
                            <label htmlFor="activo" className="pt-2 pr-2">LABORATORISTA ACTIVO</label>
                            <Field
                                name="activo"
                                component={renderSwitch}
                            />
                        </div>
                    </div>
                </section>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/laboratoristas">CANCELAR</Link> 
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </form>
        )
    }
}

export default reduxForm({
    form: 'laboratoristaForm',
    validate: data => {
        return validate(data, {
            nombre: validators.exists()('Campo requerido'),
            laboratorio: validators.exists()('Campo requerido'),
        });
    }
})(LaboratoristaForm)