import { connect } from 'react-redux';
import {actions} from '../../../redux/modules/recepciones';
import Home from './Home';


const ms2p = (state) => {
  return {
    ...state.recepciones,
    me: state.login.me,
    proveedor: state.login.proveedor,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Home);
