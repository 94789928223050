import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/tipos_vehiculo/tipos_vehiculo';
import TipoVehiculoCrear from './TipoVehiculoCrear';

const mstp = state => {
    return {...state.tipos_vehiculo}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(TipoVehiculoCrear)
