import React, { Component } from 'react';
import Card from '../../../Utils/Cards/Card';
import Grid from '../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import Header from "Utils/Grid/Header";
import { standardActions } from '../../../Utils/Grid/StandardActions';
import { tipos_laboratorio } from '../../../../../utility/constants';

const verificarActivo = (activo, value) => {
    if(activo) {
        return `<p style="color: #5b5b5;" class="pt-0 pb-0 m-0">${value}</p>`;
    }
    else {
        return `<p style="color: #868686;" class="pt-0 pb-0 m-0">${cell}</p>`;
    }
}

const typeFormatter = (cell, row) => {
    if (row.activo) {
        return `<p style="color: #5b5b5;" class="pt-0 pb-0 m-0">
            ${tipos_laboratorio.find(tipo => tipo.value == cell).label}
        </p>`
    }
    else {
        return `<p style="color: #868686;" class="pt-0 pb-0 m-0">
            ${tipos_laboratorio.find(tipo => tipo.value == cell).label}
        </p>`
    }
}

const stateFormatter = (cell, row) => {
    if(row.activo) {
        return `<p style="color: #5b5b5;" class="pt-0 pb-0 m-0">${cell}</p>`;
    }
    else {
        return `<p style="color: #868686;" class="pt-0 pb-0 m-0">${cell}</p>`;
    }
}

const emailFormatter = (cell, row) => {
    if(cell !== null) {
        if(row.activo) {
            return `<p style="color: #5b5b5;" class="pt-0 pb-0 m-0">${cell}</p>`;
        }
        else {
            return `<p style="color: #868686;" class="pt-0 pb-0 m-0">${cell}</p>`;
        }
    }
    else {
        if(row.activo) {
            return `<p style="color: #5b5b5;" class="pt-0 pb-0 m-0">No hay correo</p>`;
        }
        else {
            return `<p style="color: #868686;" class="pt-0 pb-0 m-0">No hay correo</p>`;
        }
    }
}

class LaboratoristasList extends Component {
    componentDidMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props;
        const { data, loader, page } = this.props;
        return (
            <div className="mb-4 col-12">
                <Card
                    icon="configuraciones_activo"
                    titulo="LABORATORISTAS"
                    subtitulo=""
                    noBorder
                >
                    <section className="d-flex justify-content-center mt-4">
                        <div
                            className="card p-3"
                            style={{width: '90%'}}
                        >
                            <Header to="laboratoristas/crear" textBtn="CREAR LABORATORISTA" {...this.props}/>
                                <Grid
                                    hover
                                    striped
                                    data={data}
                                    loading={loader}
                                    onPageChange={onPageChange}
                                    onSortChange={onSortChange}
                                    page={page}
                                >
                                    <TableHeaderColumn
                                        dataField="id"
                                        dataAlign="center"
                                        dataSort
                                        width="10%"
                                        dataFormat={standardActions({ editar: "laboratoristas"})}
                                    />
                                    <TableHeaderColumn
                                        isKey
                                        dataField="nombre"
                                        dataSort
                                        dataFormat={stateFormatter}
                                    >
                                        NOMBRE Y APELLIDO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="laboratorio"
                                        dataSort
                                        dataFormat={typeFormatter}
                                    >
                                        TIPO DE LABORATORIO
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="email"
                                        dataSort
                                        dataFormat={emailFormatter}
                                    >
                                        EMAIL
                                    </TableHeaderColumn>
                                </Grid>
                        </div>
                    </section>
                </Card>
            </div>
        )
    }
}

export default LaboratoristasList