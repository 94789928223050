import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './PropietarioForm';
import EditarPropietarioForm from './PropietarioForm';

export default class PropietarioCrear extends Component {
    state = {
        editar: false,
    };
    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
            this.setState({editar: true});
        }
    }
    actualizar = (data) =>{
        this.props.editar(this.props.match.params.id, data)
    };
    render() {
        const { crear, editar } = this.props;
        const { updateData, loader } = this.props;
        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="configuraciones_activo"
                    titulo="PROPIETARIO"
                    subtitulo="Nuevo">
                    <LoadMask loading={loader} blur>
                        {
                            this.state.editar ?
                                <EditarPropietarioForm
                                    onSubmit={this.actualizar}
                                    updateData={updateData}
                                    editando={true} />
                                :
                                <Form onSubmit={crear}/>
                        }
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
