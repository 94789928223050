import React from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom';
import {Field, FieldArray, formValueSelector, reduxForm,} from 'redux-form';
import {renderNumber, SelectField} from '../../../../Utils/renderField/renderField';
import validador from './validate'
import CardEmpty from "../../../../Utils/Cards/CardEmpty";
import {RenderKilogramo} from "../../../../Utils/renderField/renderReadField";

const renderRecipientes = ({fields, meta: {error, submitFailed}, getDatoRecipiente, recipientes, materiales_bodega, getDatoDiferencia, eliminarRecipiente, openModal}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                    <tr>
                        <th style={{width: "5%"}}/>
                        <th style={{width: "14%"}} className="text-xs">DESTINO</th>
                        <th style={{width: "13%"}} className="text-xs text-center">MATERIA PRIMA</th>
                        <th style={{width: "15%"}} className="text-xs text-center">PESO ACTUAL (ORIGEN)</th>
                        <th style={{width: "13%"}} className="text-xs text-center">A TRANSFERIR</th>
                        <th style={{width: "13%"}} className="text-xs text-center">PESO RESTANTE (ORIGEN)</th>
                    </tr>
                    </thead>
                </table>
            </div>
            {/*Contenido de la tabla*/}
            <div>
                <table className="table table-sm  m-0 table-striped">
                    <tbody>
                    {fields.map((recipiente, index) => {
                        let todos = fields.getAll();
                        todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                        const prod_actual = fields.get(index); //Obtiene el recipiente actual
                        return (
                            <tr key={index}>
                                <td className="text-center sin-borde-top" style={{width: "5%"}}>
                                    {index > 0 && (
                                        <img src={require('assets/img/icons/borrar.png')} alt="Borrar"
                                             title="Borrar" className="action_img"
                                             onClick={() => {
                                                 eliminarRecipiente(fields, index)
                                             }}
                                        />
                                    )}
                                </td>
                                <td className="sin-borde-top bold" style={{width: "14%"}}>
                                    <Field name={`${recipiente}.id`} component={SelectField}
                                           options={recipientes}
                                           valueKey={'id'}
                                           className="form-control"/>
                                </td>
                                <td className="sin-borde-top bold" style={{width: "13%"}}>
                                    <Field name={`${recipiente}.id_materia`} component={SelectField}
                                           options={materiales_bodega}
                                           onCambio={(e) => getDatoRecipiente(e, index)}
                                           valueKey={'value'}
                                           className="form-control"/>
                                </td>
                                <td className="sin-borde-top text-center bold" style={{width: "13%"}}>
                                    <Field name={`${recipiente}.peso_inicial`}
                                           type="number"
                                           disabled
                                           decimalScale={4}
                                           suffix=" kgs"
                                           component={renderNumber}
                                           parse={val => parseFloat(val)}/>
                                </td>
                                <td className="sin-borde-top text-center" style={{width: "13%"}}>
                                    <Field name={`${recipiente}.a_transferir`}
                                           type="number"
                                           suffix=" kgs"
                                           decimalScale={2}
                                           onCambio={(e) => getDatoDiferencia(e, index)}
                                           component={renderNumber}
                                           parse={val => parseFloat(val)}/>

                                </td>
                                <td style={{width: "13%"}} className="sin-borde-top text-center">
                                    <Field name={`${recipiente}.peso_final`}
                                           type="number"
                                           disabled
                                           decimalScale={4}
                                           suffix=" kgs"
                                           component={renderNumber}
                                           parse={val => parseFloat(val)}/>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            {/*<div className="d-flex justify-content-center mt-2">*/}
            {/*    <button type="button" className="btn btn-outline-info py-0" onClick={() => fields.push({})}>*/}
            {/*        AGREGAR RECIPIENTE DESTINO*/}
            {/*    </button>*/}
            {/*    /!*<Alerta*!/*/}
            {/*    /!*    danger*!/*/}
            {/*    /!*    texto={`La cantidad de centímetros a sacar de la pila de origen es mayor a la cantidad de existencia`}/>*!/*/}
            {/*</div>*/}
        </div>
    </div>
);

let SalidaForm = props => {
    const {handleSubmit, open, crearSalida, getDatoRecipiente, openModal, closeModal, materiales_bodega, recipientes, getDatoDiferencia, eliminarRecipiente, item} = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="row col-12">
                <div className="my-3 w-100 d-flex align-items-center justify-content-center">
                    <div>
                        <div className="mb-3">
                            <span className="verde-primario text-sm">INGRESAR DESTINO DE SALIDA</span>
                        </div>
                        <img src={require('assets/img/icons/linea.png')} alt="Linea"
                             className="title_img mr-2"/>
                        <span className="text-xs mr-2">EXISTENCIA INICIAL DE MATERIA PRIMA</span>
                        <span className="bold">
                            <RenderKilogramo value={item.peso_total}/>
                        </span>
                    </div>
                </div>
            </div>
            <div className="form-group grid-container">
                <FieldArray
                    name="recipientes"
                    recipientes={recipientes}
                    component={renderRecipientes}
                    materiales_bodega={materiales_bodega}
                    getDatoDiferencia={getDatoDiferencia}
                    getDatoRecipiente={getDatoRecipiente}
                    eliminarRecipiente={eliminarRecipiente}/>
                <div className="row col-md-12 p-0 form-group grid-container d-flex align-items-center flex-column">
                    <div className="col-md-7">
                        <CardEmpty noShadow>
                            <div className="col-12 pl-5 pb-3">
                                <div>
                                    <span className="verde-primario text-sm">SALIDA {item.nombre}</span>
                                </div>
                                <div className="row">
                                    <span htmlFor="altura_final_origen" className="col-md-6 pt-2 bold text-xs">EXISTENCIA FINAL DE
                                        MATERIA PRIMA</span>
                                    <div className="col-md-5">
                                        <Field name="peso_final_origen"
                                               type="number"
                                               disabled
                                               decimalScale={4}
                                               suffix=" kgs"
                                               component={renderNumber}
                                               parse={val => parseFloat(val)}/>
                                    </div>
                                </div>
                            </div>

                        </CardEmpty>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to={item && item.id ? `/almacen/${item.id}` : '/almacenes'}>CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GENERAR SALIDA</button>
                </div>
            </div>
        </form>
    )
};
SalidaForm = reduxForm({
    form: 'SalidaForm',
    asyncBlurFields: [],
    validate: validador,
    initialValues: {
        id_origen: 0,
        recipientes: [{}],
        peso_inicial_origen: 0,
        peso_final_origen: 0,
    },
})(SalidaForm);

const selector = formValueSelector('SalidaForm');
SalidaForm = connect((state, props) => {
    const form = state.form.SalidaForm;
    let initialValues = {
        id_origen: props.item.id,
        recipientes: [{}],
        peso_final_origen: props.item.peso_total,
        peso_inicial_origen: props.item.peso_total,
    };
    return {initialValues}
})(SalidaForm);

export default SalidaForm
