import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/laboratorio';
import ReporteNh3Drc from './ReporteNh3Drc';

const ms2p = state => {
    return {
        ...state.laboratorio,
        proveedor: state.login.proveedor,
        me: state.login.me,
    }
}

const md2p = { ...actions };

export default connect(ms2p, md2p)(ReporteNh3Drc);