import React from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import { connect } from "react-redux";
import Grid from "../../../Utils/Grid";
import { actions } from "../../../../../redux/modules/canal_reporte";

import moment from "moment";

const formatearFecha = (fecha, args) => {
    return moment(fecha).format("DD/MM/YYYY");
};

export const TablaCanal = (props) => {
    const { page, data, generarReporte } = props;

    return (
        <Grid
            hover
            striped
            data={data}
            className="pb-4 pt-3"
            page={page}
            pagination={true}
            onPageChange={generarReporte}
        >
            <TableHeaderColumn
                dataField="id"
                isKey
                width="2%"
                dataFormat={(cell) => <div></div>}
            />
            <TableHeaderColumn dataField="id_recepcion_original">
                ID RECEPCION ORIGEN
            </TableHeaderColumn>
            {/* <TableHeaderColumn dataField="placa_vehiculo">
                #PLACA
            </TableHeaderColumn>
            <TableHeaderColumn dataField="nombre_piloto">
                PILOTO
            </TableHeaderColumn> */}
            <TableHeaderColumn dataField="peso_bruto_origen">
                PESO BRUTO ORIGEN
            </TableHeaderColumn>
            <TableHeaderColumn dataField="peso_tara_origen">
                PESO TARA ORIGEN
            </TableHeaderColumn>
            <TableHeaderColumn dataField="peso_neto_origen">
                PESO NETO ORIGEN
            </TableHeaderColumn>
            <TableHeaderColumn dataField="peso_bruto">
                PESO BRUTO
            </TableHeaderColumn>
            <TableHeaderColumn dataField="peso_tara">
                PESO TARA
            </TableHeaderColumn>
            <TableHeaderColumn dataField="peso_neto">
                PESO NETO
            </TableHeaderColumn>
            <TableHeaderColumn dataField="diferencia">
                DIFERENCIA KG
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="fecha_entrada"
                dataFormat={formatearFecha}
            >
                FECHA ENTRADA CANAL
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="fecha_salida"
                dataFormat={formatearFecha}
            >
                FECHA SALIDA CANAL
            </TableHeaderColumn>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    ...state.canal_reporte,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaCanal);
