import React, { Component, Fragment } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import CardEmpty from "Utils/Cards/CardEmpty";
import {Link} from "react-router-dom";
import moment from 'moment';


class DetalleBitacora extends Component {
    componentWillMount(){
        this.props.getDetailBitacora(this.props.match.params.id);
    }

    render() {
        const { crear, item, loader } = this.props;
        const detalle = item && item.detalle ? JSON.parse(item.detalle) : {};
        const detalle_anterior = item && item.detalle_anterior ? JSON.parse(item.detalle_anterior) : {};

        console.log(detalle_anterior);

        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="reportes_activo"
                    titulo="BITÁCORA"
                    col="12"
                    subtitulo="Detalle">
                    <LoadMask loading={loader} blur>
                        <div className="col-12 row">
                            <div className="col-12 mb-3 d-flex flex-wrap justify-content-center align-items-center">
                                <div className="col-10 mb-5">
                                    <h5 className="bold mb-3"> {item && item.accion ? item.accion.toUpperCase() : ""}</h5>
                                    <div className="col-12 d-flex flex-wrap justify-content-between pl-0 row">
                                        <div className="col-6">
                                            <label className="mr-4 pl-0">FECHA Y HORA</label>
                                            <label className="gris pl-0">{item && item.creado ? moment(item.creado).format('DD/MM/YYYY HH:mm:ss A') : ""}</label>
                                        </div>
                                        <div className="col-6">
                                            <label className="mr-4">RESPONSABLE</label>
                                            <label className="gris">{item && item.usuario && item.usuario.nombre ? item.usuario.nombre : ""}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-10 row pl-0">
                                    {Object.entries(detalle_anterior).length !== 0 && (
                                        <div className="col-6 d-flex flex-wrap flex-column">
                                                <b className="mb-3">ESTADO ANTERIOR</b>
                                                {Object.keys(detalle_anterior).map(item => {
                                                    return(
                                                        <div className="d-flex flex-wrap flex-column mb-2">
                                                            <label className={detalle[item] === detalle_anterior[item] ? "pl-0 gris" : "pl-0 rojo"}>{item}:</label>
                                                            <span className={detalle[item] === detalle_anterior[item] ? "pl-0 gris" : "pl-0 rojo"}>{detalle_anterior[item] ? detalle_anterior[item]: "---"}</span>
                                                        </div>
                                                    )
                                                })}

                                        </div>
                                    )}
                                    <div className="col-6 d-flex flex-wrap flex-column">
                                        <b className="mb-3">ESTADO ACTUAL</b>
                                        {Object.keys(detalle).map(item => {
                                            return(
                                                <div className="d-flex flex-wrap flex-column mb-2">
                                                    <label className="gris pl-0">{item}:</label>
                                                    <span className="gris">{detalle[item] ? detalle[item]: "---"}</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 text-center mb-3 pr-0">
                                <Link className="btn btn-outline-dark mr-5" to="/reporte/bitacora">SALIR</Link>
                            </div>
                        </div>
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}

export default DetalleBitacora;
