import React, { Component, Fragment } from 'react'
import moment from 'moment/moment';
import Grid from '../../../Utils/Grid'
import { RenderKilogramo, RenderNumber } from 'Utils/renderField/renderReadField';
import { PrintContainer, actions as printActions } from 'Utils/Print';
import 'react-step-progress-bar/styles.css';
import { RenderNumberPercentage, RenderDollar, RenderCurrency } from '../../../Utils/renderField/renderReadField';
import CardEmpty from "Utils/Cards/CardEmpty";
import LoadMask from 'Utils/LoadMask/LoadMask';
import { FLETE_INTERNO_COSTO_POR_KG } from '../../../../../utility/constants';

export default class BoletaLiquidacion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            message: '',
            name_print_component: 'boleta-liquidacion',
        }
    }
    componentDidMount() {
        const { item } = this.props;
        if (item && !item.boleta_liquidacion) {
            this.setState({ title: 'CARGANDO...', message: 'Se esta generando la boleta de liquidación', });
            this.crearPDF();
        }
        else {
            console.log("La boleta ya fue guardada y enviada");
        }
    }

    imprimirBoleta = (event) => {
        printActions.print(this.state.name_print_component);
    }
    crearPDF() {
        printActions.exportToPDF(this.state.name_print_component).then(output => {
            this.props.guardarBoletaPDFLiquidaciones(this.props.match.params.id, btoa(output));
            this.setState({ title: 'ESPERE...', message: 'Se esta enviando la boleta de liquidación por email', });
        }).catch(error => {
            console.log('Error al intentar generar pdf:', error);
        });
    }

    render() {
        const { item, loader } = this.props;
        return (
            <div>
                <LoadMask loading={loader} blur title={this.state.title} message={this.state.message}>
                    <CardEmpty>
                    <div className="print-only mt-0">
                        <PrintContainer name={this.state.name_print_component}>
                            <div className="col-12 p-0 mt-0 media-carta-arriba">
                                <div className="contenido-impresion verde-secundario mt-0">
                                    <div className="col-12 mt-0">
                                        <div className="col-12 d-flex justify-content-center m-0 p-0">
                                            <div className="col-12 pl-2">
                                                <div className="col-12 d-flex justify-content-center mt-0 mb-1">
                                                    <div className="col-12 pl-0 m-0">
                                                        <div className="text-left mb-2 m-0 p-0">
                                                        <div className="col-12">
                                                            <img src={require('../../../../../../assets/img/logo.png')}
                                                                className="d-inline-block align-top"
                                                                alt="Logo"
                                                                height="60px"
                                                            />
                                                            {item && item.fecha_liquidacion ? 
                                                            <Fragment>
                                                                <span className="rojo bold">FECHA ESTIMADA DE PAGO: </span>
                                                                <span className="rojo bold">
                                                                    {item.fecha_estimada_pago && item.fecha_estimada_pago != "" ? 
                                                                        moment(item.fecha_estimada_pago).format('DD/MM/YYYY'):
                                                                        moment(item.fecha_liquidacion).add(item.dias,'days').format('DD/MM/YYYY')}
                                                                </span>
                                                            </Fragment>
                                                              :
                                                              ""
                                                        }
                                                          
                                                        </div>

                                                        </div>
                                                        <div className="col-12 p-0 mt-0 d-flex justify-content-between align-items-end">
                                                            <div className="d-flex align-items-center col-3">
                                                                <img src={require(`../../../../../../assets/img/icons/recepciones_activo.png`)} alt="Usuario" className="title_img mr-2" />
                                                                <img src={require('../../../../../../assets/img/icons/linea.png')} alt="Linea" className="title_img mr-2" />
                                                                <h6 className="text-black mb-0">BOLETA DE LIQUIDACIÓN</h6>
                                                            </div>
                                                            <div className="d-flex flex-column text-left col-3">
                                                                <span className="text-black pl-2">Fecha de liquidacion:</span>
                                                                <span className="text-black pl-2">{item && item.fecha_liquidacion ? moment(item.fecha_liquidacion).format('DD/MM/YYYY') : "--"}</span>
                                                            </div>
                                                            <div className="d-flex flex-column text-left col-3">
                                                                <span className="text-black pl-2">Fecha de impresión:</span>
                                                                <span className="text-black pl-2">{moment().format('DD/MM/YYYY HH:mm:ss A')}</span>
                                                            </div>
                                                            <div className="d-flex flex-column text-left col-3">
                                                                <span className="text-black pl-2">Semana</span>
                                                                <span className="text-black pl-2 bold">{item && item.fecha_liquidacion ? moment(item.fecha_liquidacion).isoWeek() : '--'}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0 mt-2 d-flex justify-content-between align-items-start">
                                                            <div className="d-flex flex-column col-3">
                                                                <label className="pl-2 m-0 mt-1">ID LIQUIDACIÓN</label>
                                                                <h5 className="text-black bold pl-2 mb-0">{item ? item.id : ""}</h5>
                                                                <label className="pl-2 m-0 mt-1">FINCA:</label>
                                                                <span className="pl-2 text-black">{item ? (item.finca ? item.finca.nombre : "") : ""}</span>
                                                            </div>
                                                            <div className="d-flex flex-column col-3">
                                                                <label className="pl-2 m-0 mt-1">BENEFICIARIO</label>
                                                                <span className="pl-2 text-black">{item ? (item.finca ? item.finca.razon_social : "") : ""}</span>
                                                                <label className="pl-2 m-0 mt-2">REGIMEN</label>
                                                                <span className="pl-2 text-black">{item ? (item.finca ? item.finca.regimen_fiscal_nombre : "") : ""}</span>
                                                            </div>
                                                            <div className="d-flex flex-column col-3">
                                                                <label className="pl-2 m-0 mt-1">LUGAR DE RECEPCIÓN</label>
                                                                <span className="pl-2 text-black">{item.detalles && item.detalles[0] && item.detalles[0].ingreso && item.detalles[0].ingreso.acopio ? item.detalles[0].ingreso.acopio.nombre : 'Planta ERSA'}</span>
                                                                <label className="pl-2 m-0 mt-2">TIPO DE PAGO</label>
                                                                <span className="pl-2 text-black">{item ? (item.finca ? (item.finca.tipo_pago ? item.finca.tipo_pago.nombre : "") : "") : ""}</span>
                                                            </div>
                                                            <div className="d-flex flex-column col-3">
                                                                <label className="pl-2 m-0 mt-1">CUENTA NO.</label>
                                                                <span className="pl-2 text-black">{item ? (item.finca ? item.finca.numero_cuenta : "") : ""}</span>
                                                                <label className="pl-2 m-0 mt-2">BANCO</label>
                                                                <span className="pl-2 text-black">{item ? (item.finca ? (item.finca.banco ? item.finca.banco.nombre : "") : "") : ""}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 mt-2 columna-derecha m-0">
                                                            <div className=" col-12 form-group np-r p-0 mb-1 inicio-tabla">
                                                                <Grid striped bordered={false} data={item ? (item.detalles ? { "results": item.detalles } : []) : []} pagination={false} bordered={false} >
                                                                    <TableHeaderColumn
                                                                        dataField="ingreso"
                                                                        isKey
                                                                        width="7%"
                                                                        dataFormat={cell => cell && cell.id ? cell.id : '--'}
                                                                    >
                                                                        ID
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="ingreso"
                                                                        width="10%"
                                                                        dataFormat={cell => cell && cell.fecha_entrada ? moment(cell.fecha_entrada).format("DD/MM/YYYY") : '--'}
                                                                    >
                                                                        FECHA
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="materia_prima"
                                                                        width="15%"
                                                                        dataFormat={cell => cell ? cell.nombre : ""}
                                                                    >
                                                                        PRODUCTO
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="kilos_humedos"
                                                                        width="12%"
                                                                        dataAlign="right"
                                                                        dataFormat={cell => <RenderNumber value={cell} decimalScale={2} />}
                                                                    >
                                                                        KG. HUMEDOS
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="drc_compra"
                                                                        width="10%"
                                                                        dataAlign="right"
                                                                        dataFormat={cell => <RenderNumberPercentage value={cell} />}
                                                                    >
                                                                        DRC %
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="kilos_secos"
                                                                        width="11%"
                                                                        dataAlign="right"
                                                                        dataFormat={cell => <RenderNumber value={cell} decimalScale={2} />}
                                                                    >
                                                                        KG. SECOS
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="precio_venta"
                                                                        width="9%"
                                                                        dataAlign="right"
                                                                        dataFormat={(cell, row) => {
                                                                            // El precio_venta (precio unitario) debe incluir el precio unitario de flete
                                                                            const { precio_venta=0, costo_flete=0, tipo_costo_flete=0 } = row;
                                                                            const total_precio_venta = precio_venta + (tipo_costo_flete === FLETE_INTERNO_COSTO_POR_KG ? costo_flete : 0);
                                                                            return <RenderNumber value={total_precio_venta} decimalScale={4}/>
                                                                        }}
                                                                    >
                                                                        PRECIO
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="total_dolares"
                                                                        width="10%"
                                                                        dataAlign="right"
                                                                        dataFormat={cell => <RenderNumber value={cell} decimalScale={2}/>}
                                                                    >
                                                                        TOTAL $.
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="cambio_dia"
                                                                        width="8%"
                                                                        dataAlign="right"
                                                                        dataFormat={cell => <RenderNumber value={cell} decimalScale={5}/>}
                                                                    >
                                                                        CAMBIO
                                                                    </TableHeaderColumn>
                                                                    <TableHeaderColumn
                                                                        dataField="total_quetzales"
                                                                        width="13%"
                                                                        dataAlign="right"
                                                                        dataFormat={cell => <RenderNumber value={cell} decimalScale={2}/>}
                                                                    >
                                                                        TOTAL Q.
                                                                    </TableHeaderColumn>
                                                                </Grid>
                                                                <div className="react-bs-table-container total-final">
                                                                    <div className="react-bs-table">
                                                                        <div className="react-bs-container-header table-header-wrapper">
                                                                            <table className="table table-hover table-bordered">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th style={{ width: "32%" }} className="text-left">TOTAL</th>
                                                                                        <th style={{ width: "12%" }} className="text-right">{item ? <RenderKilogramo value={item.kilos_humedos} decimalScale={2}/> : ""}</th>
                                                                                        <th style={{ width: "21%" }} className="text-right">{item ? <RenderKilogramo value={item.kilos_secos} decimalScale={2}/> : ""}</th>
                                                                                        <th style={{ width: "19%" }} className="text-right">{item ? <RenderDollar value={item.total_dolares} decimalScale={2}/> : ""}</th>
                                                                                        <th style={{ width: "21%" }} className="text-right">{item ? <RenderCurrency value={item.total_quetzales} decimalScale={2}/> : ""}</th>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex justify-content-center">
                                                            <div className="col-9 m-0 p-0 mt-3">
                                                                <CardEmpty noPadding noShadow>
                                                                    <div className="col-12 mb-1 mt-1">
                                                                        <div className="col-12 p-0 mt-3 m-0 d-flex justify-content-between flex-wrap">
                                                                            <div className="col-12">
                                                                                <h6 className="text-black mb-0 ml-2">DATOS PARA FACTURAR</h6>
                                                                            </div>
                                                                            <div className="col-3 d-flex flex-column">
                                                                                <label className="pl-2 m-0 mt-1">A NOMBRE DE</label>
                                                                                <span className="pl-2 text-black">ENTRE RIOS S.A.</span>
                                                                            </div>
                                                                            <div className="col-3 d-flex flex-column">
                                                                                <label className="pl-2 m-0 mt-1">NIT</label>
                                                                                <span className="pl-2 text-black">564260-4</span>
                                                                            </div>
                                                                            <div className="col-6 d-flex flex-column">
                                                                                <label className="pl-2 m-0 mt-1">DIRECCIÓN</label>
                                                                                <span className="pl-2 text-black">22 Avenida 7-29 Zona 15 Colonia Vista Hermosa Uno,Guatemala</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </CardEmpty>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 d-flex justify-content-center columna-izquierda mt-2 p-0 m-0 mb-5">
                                            <div className="col-11 p-0 m-0">
                                                <div className="col-12 p-0 mt-3">
                                                    <div className="col-12 m-0">
                                                        <span className="text-black">Retencion de ISR de 0 a 30,000 se retiene el 5% - Mayor a 30,000 se retiene el 7%</span>
                                                    </div>
                                                    {/* <div className="col-3 p-0">
                                                        <div className="col-12">
                                                            <span className="text-black">Preparado por: Liquidaciones</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 p-0">
                                                        <div className="col-12 text-center">
                                                            <span className="text-black">Revisado por: Depto. de Compras</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 p-0">
                                                        <div className="col-12 text-center">
                                                            <span className="text-black">Vo. Bo. Contabilidad</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 p-0">
                                                        <div className="col-12 text-right">
                                                            <span className="text-black">ERSA-F-GO-025-12</span>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 p-0 mt-0 media-carta-abajo">
                                <div className="contenido-impresion azul mt-0">
                                    <div className="col-12 mt-0">
                                        <div className="col-12 d-flex justify-content-center m-0 p-0">
                                            <div className="col-12 pl-2">
                                                <div className="col-12 d-flex justify-content-center mt-0 mb-1">
                                                    <div className="col-12 pl-0 m-0">
                                                        <div className="text-left mb-2 m-0 p-0">
                                                        <div className="col-12">
                                                            <img src={require('../../../../../../assets/img/logo.png')}
                                                                className="d-inline-block align-top"
                                                                alt="Logo"
                                                                height="60px"
                                                            />
                                                        </div>
                                                        </div>
                                                        <div className="col-12 p-0 mt-0 d-flex justify-content-between align-items-center">
                                                            <div className="d-flex align-items-center col-6">
                                                                <img src={require(`../../../../../../assets/img/icons/recepciones_activo.png`)} alt="Usuario" className="title_img mr-2" />
                                                                <img src={require('../../../../../../assets/img/icons/linea.png')} alt="Linea" className="title_img mr-2" />
                                                                <h6 className="text-black mb-0">BOLETA DE INTEGRACIÓN DE PAGO</h6>
                                                            </div>
                                                            <div className="d-flex flex-column text-left col-3">
                                                                <span className="text-black pl-2">Fecha de liquidacion:</span>
                                                                <span className="text-black pl-2">{item && item.fecha_liquidacion ? moment(item.fecha_liquidacion).format('DD/MM/YYYY') : '--'}</span>
                                                            </div>
                                                            <div className="d-flex flex-column text-left col-3">
                                                                <span className="text-black">Fecha de impresión:</span>
                                                                <span className="text-black">{moment().format('DD/MM/YYYY HH:mm:ss A')}</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 p-0 mt-2 d-flex justify-content-between align-items-start">
                                                            <div className="d-flex flex-column col-8">
                                                                <div className="d-flex mb-1">
                                                                    <div className="col-3">
                                                                        <label className="mb-0 pl-2">FINCA:</label>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <span className="text-black pl-2">{item ? (item.finca ? item.finca.nombre : "") : ""}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex mb-1">
                                                                    <div className="col-3">
                                                                        <label className="mb-0 pl-2">BENEFICIARIO:</label>
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <span className="text-black pl-2">{item ? (item.finca ? item.finca.razon_social : "") : ""}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center col-3">
                                                                <label className="pr-2 pl-0">INTEGRACIÓN</label>
                                                                <h5 className="text-black bold pr-2">{item ? item.id : ""}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 p-0 mt-3 m-0 d-flex justify-content-center ">
                                                    <div className="col-11 p-0 columna-derecha">
                                                        <div className=" col-12 form-group np-r p-0 mb-1 inicio-tabla">
                                                            <div className="react-bs-table-container">
                                                                <div className="react-bs-table">
                                                                    <div className="react-bs-container-header table-header-wrapper">
                                                                        <table className="table table-hover table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th style={{ width: "15%" }}>DOCUMENTO</th>
                                                                                    <th style={{ width: "12%" }}>NUMERO</th>
                                                                                    <th style={{ width: "15%" }}>BANCO</th>
                                                                                    <th style={{ width: "13%" }}>FECHA</th>
                                                                                    <th style={{ width: "15%" }}>VALOR FACTURA</th>
                                                                                    <th style={{ width: "15%" }}>VALOR DESCUENTO</th>
                                                                                    <th style={{ width: "15%" }}>TOTAL</th>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <table className="table table-striped table-bordered table-hover mb-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: "15%" }} className="bold">Factura</td>
                                                                        <td style={{ width: "12%" }} className="bold">{item ? (item.numero_factura ? item.numero_factura : "") : ""}</td>
                                                                        <td style={{ width: "15%" }} className="bold">{item ? (item.finca ? (item.finca.banco ? item.finca.banco.nombre : "") : "") : ""}</td>
                                                                        <td style={{ width: "13%" }} className="bold">{moment().format('DD/MM/YYYY')}</td>
                                                                        <td style={{ width: "15%" }} className="bold">{item ? (item.total_quetzales ? <RenderCurrency value={item.total_quetzales} decimalScale={2}/> : "") : ""}</td>
                                                                        <td style={{ width: "30%" }}/>

                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div className="react-bs-table-container total">
                                                                <div className="react-bs-table">
                                                                    <div className="react-bs-container-header table-header-wrapper">
                                                                        <table className="table table-hover table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th style={{ width: "15%" }}>DESCUENTOS</th>
                                                                                    <th style={{ width: "85%" }} />
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <table className="table table-striped table-bordered table-hover mb-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td style={{ width: "70%" }} className="bold">Retención IVA</td>
                                                                        <td style={{ width: "30%" }} className="bold">{item ? <RenderCurrency value={item && item.finca && item.finca.retenedor_iva === true ? (item.retencion_iva ? item.retencion_iva : 0) : 0} decimalScale={2}/> : 0}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: "70%" }} className="bold">Retención ISR</td>
                                                                        <td style={{ width: "30%" }} className="bold">{item ? <RenderCurrency value={item && item.finca && item.finca.retenedor_iva === true ? (item.retencion_isr ? item.retencion_isr : 0) : 0} decimalScale={2}/> : 0}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: "70%" }} className="bold">Retención Pequeño Contribuyente</td>
                                                                        <td style={{ width: "30%" }} className="bold">{item ? <RenderCurrency value={item && item.finca && item.finca.retenedor_iva === true ? (item.retencion_peq_contribuyente ? item.retencion_peq_contribuyente : 0) : 0} decimalScale={2}/> : 0}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td style={{ width: "70%" }} className="bold">Insumos Agricolas</td>
                                                                        <td style={{ width: "30%" }} className="bold">{item ? <RenderCurrency value={item ? (item.total_insumos ? item.total_insumos : 0) : 0} decimalScale={2}/> : 0}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div className="react-bs-table-container total-final">
                                                                <div className="react-bs-table">
                                                                    <div className="react-bs-container-header table-header-wrapper">
                                                                        <table className="table table-hover table-bordered">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <th style={{ width: "55%" }} className="bold">TOTALES</th>
                                                                                    <th style={{ width: "15%" }} className="gris-total">{item ? <RenderCurrency value={item ? item.total_quetzales : 0} decimalScale={2}/> : 0}</th>
                                                                                    <th style={{ width: "15%" }} className="gris-total">{item ? <RenderCurrency value={item && item.finca && item.finca.retenedor_iva === true ? (item ? (item.retencion_isr + item.retencion_iva + item.retencion_peq_contribuyente + item.total_insumos) : 0) : (item ? item.total_insumos : 0)} decimalScale={2}/> : 0}</th>
                                                                                    <th style={{ width: "15%" }} className="total-gris">{item ? <RenderCurrency value={item && item.finca && item.finca.retenedor_iva === true ? (item ? (item.total_quetzales - item.retencion_isr - item.retencion_iva - item.retencion_peq_contribuyente - item.total_insumos) : 0) : item ? (item.total_quetzales - item.total_insumos) : 0} decimalScale={2}/> : ""}</th>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 d-flex justify-content-center columna-izquierda mt-2 p-0 m-0 mb-5">
                                            <div className="col-11 p-0 m-0">
                                                <div className="d-flex col-12 p-0 mt-3">
                                                    <div className="col-3 p-0">
                                                        <div className="col-12">
                                                            <span className="text-black">Preparado por: Liquidaciones</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 p-0">
                                                        <div className="col-12 text-center">
                                                            <span className="text-black">Revisado por: Depto. de Compras</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 p-0">
                                                        <div className="col-12 text-center">
                                                            <span className="text-black">Vo. Bo. Contabilidad</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-3 p-0">
                                                    <div className="col-12 text-right">
                                                        <span className="text-black">ERSA-F-GO-255-01</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PrintContainer>
                    </div>
                    </CardEmpty>
                </LoadMask>
                <div className="mb-4 col-12 p-0">
                    <div className="d-flex mb-5">
                        <div className="d-flex col-12">
                            <div className="col-12 d-flex justify-content-center mt-5">
                                <div className="col-9 buttons-box">
                                    <button type="button" className="btn btn-outline-dark mr-5" onClick={(e) => {
                                        e.preventDefault();
                                        this.props.history.goBack();
                                        this.props.history.goBack();
                                    }}>CERRAR</button>
                                    <button type="submit" className="btn btn-primary" onClick={this.imprimirBoleta}>IMPRIMIR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
