import React, { Component } from 'react'
import moment from 'moment'
import { PrintContainer, actions as printActions } from 'Utils/Print'
import CardEmpty from '../../../../Utils/Cards/CardEmpty'
import { RenderKilogramo } from '../../../../Utils/renderField/renderReadField'
import Grid from '../../../../Utils/Grid'
import { TableHeaderColumn } from 'react-bootstrap-table'

const getPesoFinca = recepciones => {
    let pesoFinca = 0
    recepciones.forEach(recepcion => {
        if (recepcion.pesof !== null) {
            pesoFinca += recepcion.pesof
        }
    })
    return pesoFinca
}

class RecepcionHistoricoBoleta extends Component {
    state = {
        fechaHoraImpresion: moment().format('DD/MM/YYYY hh:mm:ss A')
    }

    componentDidMount() {
        this.props.leerRecepcion(this.props.match.params.id)
    }

    imprimirBoleta = event => {
        this.setState({
            fechaHoraImpresion: moment().format('DD/MM/YYYY hh:mm:ss A')
        }, () => printActions.print('boleta-recepcion-historico'))
    }
    
    render() {
        const { item_recepcion, detalles_recepcion } = this.props
        const pesoFinca = getPesoFinca(detalles_recepcion.results)
        return (
            <React.Fragment>
                <CardEmpty>
                    <PrintContainer name="boleta-recepcion-historico">
                        <div className="col-12 p-0">
                            <div className="row contenido-impresion verde-secundario d-flex justify-content-center">
                                <div className="col-12 row">
                                    <div className="col-12 d-flex justify-content-center mt-0 mb-3">
                                        <div className="col-11 pl-2">
                                            <div className="row col-12 p-0 mt-3 d-flex justify-content-between align-items-center">
                                                <img
                                                    className="d-inline-block align-top"
                                                    src={require('../../../../../../../assets/img/logo.png')}
                                                    alt="Logo"
                                                    height="50px"
                                                />
                                                <label className="p-0 m-0">ACOPIO ERSA KM. 7.5 CARRETERA A TULATE</label>
                                                <div className="ml-4 mr-4"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center mt-0">
                                        <div className="col-11">
                                            <div className="row col-12 p-0 mt-2 justify-content-center align-items-start">
                                                <div className="col-7 p-0 fondo">
                                                    <div className="col-12 d-flex flex-column pl-0">
                                                        <div className="d-flex align-items-center">
                                                            <img src={require('../../../../../../../assets/img/icons/historico_activo.png')} alt="Boleta" className="title_img mr-2"/>
                                                            <img src={require('../../../../../../../assets/img/icons/linea.png')} alt="Linea" className="title_img mr-2"/>
                                                            <h6 className="text-black mb-0">BOLETA DE RECEPCION (HISTÓRICO)</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-5 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <span className="gris">Fecha de impresión:</span>
                                                        <span className="gris">{this.state.fechaHoraImpresion}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-12 p-0 mt-2">
                                                <div className="col-7 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">FINCA</label>
                                                        <span className="gris">{item_recepcion.finca ? item_recepcion.finca : ''}</span>
                                                    </div>
                                                </div>
                                                <div className="col-4 p-0">
                                                    <div className="col-12 d-flex align-items-baseline">
                                                        <label className="p-0 m-0">ID RECEPCIÓN</label>
                                                        <h5 className="gris bold pl-2">{item_recepcion.codentr ? item_recepcion.codentr.toString().padStart(5, 0) : ''.padStart(5, 0)}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-12 p-0 mt-2">
                                                <div className="col-7 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">VEHÍCULO</label>
                                                        <span className="gris">{item_recepcion.placa ? item_recepcion.placa : ''}</span>
                                                    </div>
                                                </div>
                                                <div className="col-5 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">PILOTO</label>
                                                        <span className="gris">{item_recepcion.nombre_piloto ? item_recepcion.nombre_piloto : ''}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-12 p-0 mt-2">
                                                <div className="col-7 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">PESO FINCA</label>
                                                        <span className="gris"><RenderKilogramo value={pesoFinca}/></span>
                                                        {/* <span className="gris"><RenderKilogramo value={item_recepcion.pesoentrada ? item_recepcion.pesoentrada : 0}/></span> */}
                                                    </div>
                                                </div>
                                                <div className="col-5 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">FECHA DE INGRESO DE MATERIA PRIMA</label>
                                                        <span className="gris">{item_recepcion.fecharegistro ? moment(item_recepcion.fecharegistro).format('DD/MM/YYYY HH:mm:ss A') : ''}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center p-0">
                                        <div className="col-11 form-group np-r p-0 mt-3 mb-1">
                                            <Grid striped data={detalles_recepcion} pagination={false} bordered={false}>
                                                <TableHeaderColumn
                                                    dataField="horaEntrada"
                                                    dataFormat={cell => moment(cell).format('DD/MM/YYYY HH:mm A')}
                                                >
                                                    ENTRADA
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="horaSalida"
                                                    dataFormat={cell => moment(cell).format('DD/MM/YYYY HH:mm A')}
                                                >
                                                    SALIDA
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    isKey
                                                    dataField="producto"
                                                >
                                                    PRODUCTO
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="pesoentrada"
                                                    dataFormat={cell => {
                                                        if (cell === null) {
                                                            return <RenderKilogramo value={0}/>
                                                        }
                                                        return <RenderKilogramo value={cell}/>
                                                    }}
                                                >
                                                    PESO ENTRADA
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="pesosalida"
                                                    dataFormat={cell => {
                                                        if (cell === null) {
                                                            return <RenderKilogramo value={0}/>
                                                        }
                                                        return <RenderKilogramo value={cell}/>
                                                    }}
                                                >
                                                    PESO SALIDA
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataFormat={(cell, row) => <RenderKilogramo value={row.pesoentrada - row.pesosalida}/>}
                                                >
                                                    PESO NETO
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="pesof"
                                                    dataFormat={cell => {
                                                        if (cell === null) {
                                                            return <RenderKilogramo value={0}/>
                                                        }
                                                        return <RenderKilogramo value={cell}/>
                                                    }}
                                                >
                                                    PESO FINCA KG
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataFormat={(cell, row) => {
                                                        if (row.pesoentrada === null || row.pesosalida === null || row.pesof === null) {
                                                            return <RenderKilogramo value={0}/>
                                                        }
                                                        return <RenderKilogramo value={row.pesoentrada - row.pesosalida - row.pesof}/>
                                                    }}
                                                >
                                                    DIFERENCIA
                                                </TableHeaderColumn>
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center mt-5 mb-5"/>
                                </div>
                            </div>
                        </div>
                    </PrintContainer>
                </CardEmpty>
                <div className="mb-4 col-12 p-0">
                    <div className="row mb-5">
                        <div className="col-12 row">
                            <div className="col-12 d-flex justify-content-center mt-5">
                                <div className="col-9 buttons-box">
                                    <button className="btn btn-outline-dark mr-5" onClick={() => this.props.history.goBack()}>CERRAR</button>
                                    <button type="submit" className="btn btn-primary" onClick={this.imprimirBoleta}>IMPRIMIR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default RecepcionHistoricoBoleta