import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/analisis_bodegas';
import AnalisisBodegaVer from './AnalisisBodegaVer';

const mstp = state => {
    return {...state.analisis_bodegas}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(AnalisisBodegaVer)
