import React, { Component } from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../FiltrosHeader';
import Reporte from './Reporte';
import CardEmpty from '../../../Utils/Cards/CardEmpty';

export default class ReporteTrasladosMateria extends Component {
    componentWillMount() {
        const { getReporteTrasladosMateria } = this.props;
        // getReporteTrasladosMateria();
    }

    render() {
        const {
            reporte_traslados: reporte,
            trasladoSolidoFechaInicialChange, trasladoSolidoFechaFinalChange,
            trasladoSolidoBodegaOrigenChange, trasladoSolidoBodegaDestinoChange,
        } = this.props;
        let fecha_inicial      = reporte.solido_fecha_inicial;
        let fecha_final        = reporte.solido_fecha_final;
        let almacen_origen_id  = reporte.solido_almacen_origen_id;
        let almacen_destino_id = reporte.solido_almacen_destino_id;
        let fechaInicialChange   = trasladoSolidoFechaInicialChange;
        let fechaFinalChange     = trasladoSolidoFechaFinalChange;
        let almacenOrigenChange  = trasladoSolidoBodegaOrigenChange;
        let almacenDestinoChange = trasladoSolidoBodegaDestinoChange;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="TRASLADOS ENTRE ALMACENES"
                    noBorder
                    subtitulo="Reporte">
                    <CardEmpty>
                        <FiltrosHeader {...this.props}
                            fechaInicialChange={fechaInicialChange} fecha_inicial={fecha_inicial} fechaFinalChange={fechaFinalChange} fecha_final={fecha_final}
                            filtroAlmacenOrigenChange={almacenOrigenChange} almacen_origen_id={almacen_origen_id}
                            filtroAlmacenDestinoChange={almacenDestinoChange} almacen_destino_id={almacen_destino_id}
                            filtrarXReporte={()=>{this.props.getReporteTrasladosMateria()}}
                            labelXReporte={"Filtrar"}
                        />
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <Reporte {...this.props} />
                </Card>
            </div>
        )
    }
}
