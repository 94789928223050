import {handleActions} from 'redux-actions';
import {api} from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import {actions as actionsAnalisisBodega} from '../analisis_bodegas'
import _ from "lodash";
import moment from 'moment';

const DATA = 'DATA_BODEGAS';
const PAGE = 'PAGE_BODEGAS';
const DATA_PILAS     = 'BODEGAS_DATA_PILAS';
const DATA_TANQUES   = 'BODEGAS_DATA_TANQUES';
const DATA_ALMACENES = 'BODEGAS_DATA_ALMACENES';
const PAGE_PILAS     = 'BODEGAS_PAGE_PILAS';
const PAGE_TANQUES   = 'BODEGAS_PAGE_TANQUES';
const PAGE_ALMACENES = 'BODEGAS_PAGE_ALMACENES';
const ITEM_DATA = 'ITEM_BODEGA';
const LOADER = 'LOADER_BODEGAS';
const SEARCH = 'SEARCH_BODEGAS';
const RECIPIENTES = 'RECIPIENTES';
const TIPO_SALIDA = 'TIPO_SALIDA';
const CUBICACIONES = 'CUBICACIONES';
const ORDERING = 'ORDERING_BODEGAS';
const CUBICACIONES_COPY = 'CUBICACIONES_COPY';
const MATERIALES_BODEGA = 'MATERIALES_BODEGA';
const FILTRO_TIPO_BODEGAS = 'FILTRO_TIPO_BODEGAS';
const PAGE_ALMACENES_PILAS = 'PAGE_ALMACENES_PILAS';
// Inventarios de bodegas
const ALMACENES_TOTALES_DATA    = 'BODEGAS_ALMACENES_TOTALES_DATA';
const INVENTARIO_PRODUCTOS_PILA_PAGE    = 'BODEGAS_INVENTARIO_PRODUCTOS_PILA_PAGE';
const INVENTARIO_PRODUCTOS_PILA_DATA    = 'BODEGAS_INVENTARIO_PRODUCTOS_PILA_DATA';
const INVENTARIO_PRODUCTOS_ALMACEN_PAGE = 'BODEGAS_INVENTARIO_PRODUCTOS_ALMACEN_PAGE';
const INVENTARIO_PRODUCTOS_ALMACEN_DATA = 'BODEGAS_INVENTARIO_PRODUCTOS_ALMACEN_DATA';
// Variables para el Reporte de Recepciones
const REPORTE_TRASLADOS_DATA                      = 'REPORTE_TRASLADOS_MATERIA_DATA';
const REPORTE_TRASLADOS_SOLIDO_PAGE               = 'REPORTE_TRASLADOS_MATERIA_SOLIDO_PAGE';
const REPORTE_TRASLADOS_SOLIDO_FECHA_INICIAL      = 'REPORTE_TRASLADOS_MATERIA_SOLIDO_FECHA_INICIAL';
const REPORTE_TRASLADOS_SOLIDO_FECHA_FINAL        = 'REPORTE_TRASLADOS_MATERIA_SOLIDO_FECHA_FINAL';
const REPORTE_TRASLADOS_SOLIDO_ALMACEN_ORIGEN_ID  = 'REPORTE_TRASLADOS_MATERIA_SOLIDO_ALMACEN_ORIGEN_ID';
const REPORTE_TRASLADOS_SOLIDO_ALMACEN_DESTINO_ID = 'REPORTE_TRASLADOS_MATERIA_SOLIDO_ALMACEN_DESTINO_ID';

// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});
const setCubicaciones = cubicaciones => ({
    type: CUBICACIONES,
    cubicaciones,
});

// ------------------------------------
// Actions
// ------------------------------------
const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().bodegas;
    const filtro = resource.filtro;
    const params = {page};
    params.ordering = resource.ordering;
    params.search = resource.search;
    filtro !== null && (params.tipo = filtro.value);
    dispatch(setLoader(true));
    api.get('bodega', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = (id, filtro = "almacen") => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'bodega'}/${id}`, {filtro}).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('BodegaForm', response));
        const cubicaciones = [];
        response.cubicaciones.forEach((cubicacion => {
            cubicaciones.push([{value: cubicacion.cm}, {value: cubicacion.kilogramo}])
        }));
        dispatch({type: CUBICACIONES, cubicaciones});
        dispatch({type: CUBICACIONES_COPY, cubicaciones_copy: cubicaciones});
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const obtenerBodega = (id, filtro = "almacen") => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`${'bodega'}/${id}`, {filtro}).then((response) => {
        dispatch(setItem(response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch, getStore) => {
    const store = getStore().bodegas;
    data.cubicaciones = store.cubicaciones;
    dispatch(setLoader(true));
    api.post('bodega', data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push('/bodegas'));
    }).catch(() => {
        NotificationManager.error('Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch, getStore) => {
    const store = getStore().bodegas;
    data.cubicaciones = store.cubicaciones;
    dispatch(setLoader(true));
    api.put(`${'bodega'}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/bodegas'));
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.eliminar(`${'bodega'}/${id}`).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.success('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const reajustarInventarioSolido = (idBodega, dataReajuste) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`bodega/${idBodega}/crear_reajuste_solidos`, dataReajuste).then(() => {
        dispatch(push('/almacenes'));
        NotificationManager.success('Reajuste de inventario exitoso', 'Éxito', 3000);
    }).catch((error) => {
        NotificationManager.error(error && error.detail ? error.detail : 'No se pudo completar la operación', 'Error', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const reajustarInventarioLatex = (idBodega, dataReajuste) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`bodega/${idBodega}/crear_reajuste_latex`, dataReajuste).then(() => {
        dispatch(push('/pilas'));
        NotificationManager.success('Reajuste de inventario exitoso', 'Éxito', 3000);
    }).catch((error) => {
        NotificationManager.error(error && error.detail ? error.detail : 'No se pudo completar la operación', 'Error', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const setTypeSalida = (typeSalida) => (dispatch) => {
    dispatch({type: TIPO_SALIDA, tipo_salida: typeSalida})
};

const get_tanques = (page = 1) => (dispatch) => {
    dispatch(setLoader(true));
    api.get('bodega/get_tanques', {page}).then((data) => {
        dispatch({type: DATA_TANQUES, tanques: data});
        dispatch({type: PAGE_TANQUES, page_tanques: page});
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const get_pilas = (page = 1) => (dispatch) => {
    dispatch(setLoader(true));
    api.get('bodega/get_pilas', {page}).then((data) => {
        dispatch({type: DATA_PILAS, pilas: data});
        dispatch({type: PAGE_PILAS, page_pilas: page});
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const get_almacenes = (page = 1) => (dispatch) => {
    dispatch(setLoader(true));
    api.get('bodega/get_almacenes', {page}).then((data) => {
        dispatch({type: DATA_ALMACENES, almacenes: data});
        dispatch({type: PAGE_ALMACENES, page_almacenes: page});
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getAlmacenesDataTotales = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('bodega/get_data_totales_almacenes').then((data) => {
        dispatch({type: ALMACENES_TOTALES_DATA, almacenes_totales: data});
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().bodegas.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const onFiltroChange = filtro => (dispatch) => {
    dispatch({type: FILTRO_TIPO_BODEGAS, filtro});
    dispatch(listar());
};

const crearFilas = (altura) => (dispatch, getStore) => {
    const store = getStore().bodegas;
    const cubicaciones = _.cloneDeep(store.cubicaciones_copy);
    let x = (cubicaciones.length > 0) ? (cubicaciones.length - 1) : 0;

    for (x; x <= parseFloat(altura); x++) {
        if (x === 1) {
            cubicaciones.push([{value: 0.01}, {value: null}]);
            cubicaciones.push([{value: x}, {value: null}])
        } else {
            cubicaciones.push([{value: x}, {value: null}])
        }
    }
    if ((altura + 2) < x) {
        for (let y = cubicaciones.length - 1; y > parseInt(altura + 1); y--) {
            cubicaciones.splice(y, 1)
        }
    }
    dispatch({type: CUBICACIONES, cubicaciones: cubicaciones});
};

const clearData = () => (dispatch) => {
    const cubicaciones = [];
    dispatch({type: CUBICACIONES, cubicaciones});
    dispatch({type: CUBICACIONES_COPY, cubicaciones_copy: cubicaciones});
};

const getProductosAlmacen = (id, page = 1) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`bodega/${id}/get_inventario_almacen`, {page}).then(data => {
        dispatch({type: INVENTARIO_PRODUCTOS_ALMACEN_PAGE, page});
        dispatch({type: INVENTARIO_PRODUCTOS_ALMACEN_DATA, data});
    }).catch(e => {

    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const getProductosPila = (id, page = 1) => (dispatch) => {
    dispatch(setLoader(true));
    api.get(`bodega/${id}/get_inventario_pila`, {page}).then(data => {
        dispatch({type: INVENTARIO_PRODUCTOS_PILA_PAGE, page});
        dispatch({type: INVENTARIO_PRODUCTOS_PILA_DATA, data});
    }).catch(e => {

    }).finally(() => {
        dispatch(setLoader(false));
    });
};
/**
 * Crea un nuevo análisis de laboratorio de la pila proporcionada, siempre que aún no tenga asociado alguno sin validar DRC.
 */
const crearActualizarAnalisis = (item) => async (dispatch) => {
    if (!item.analisis_laboratorio) {
        dispatch(setLoader(true));
        try {
            // Se crea el nuevo análisis de laboratorio para la pila indicada, y se recupera la información de la pila
            const parsed_dat = {
                peso_neto: item.peso_total,
                bodega_id: item.id,
            };
            await api.post('laboratorio/crear_analisis_bodega', parsed_dat);
            const pila = await api.get(`bodega/${item.id}`, {filtro: 'pila'});
            dispatch(setItem(pila));
            NotificationManager.success('Análisis de Pila creado con éxito', 'ÉXITO');
        } catch(error) {
            NotificationManager.error(error && error.detail ? error.detail : 'Ocurrió un error al intentar crear el Análisis de la Pila', 'ERROR');
        } finally {
            dispatch(setLoader(false));
        }
    }
};

/***
 * DEVUELVE EL LISTADO DE PILAS O ALMACENES DEPENDIENDO DEL TIPO DE SALIDA QUE SE VA A CREAR.
 * @returns {Function}
 */
const getRecipientes = () => (dispatch, getStore) => {
    const resource = getStore().bodegas;
    let params = {};
    (resource.item && resource.item !== {}) && (params['id_rec'] = resource.item.id);
    resource.tipo_salida === 'ALMACENES' && (params['almacenes'] = true);
    api.get('bodega/get_pilas_o_bodegas', params).then((data) => {
        const recipientes = data.results;
        if (recipientes.length > 0) {
            //Mutar cada producto pra que tenga value y label
            recipientes.forEach((recipiente) => {
                recipiente['value'] = recipiente.id;
                recipiente['label'] = recipiente.nombre;
                if (_.get(recipiente, 'cubicaciones', null) !== null) {
                    const cubicaciones_obj = {};
                    _.get(recipiente, 'cubicaciones', []).forEach(cubicacion => cubicaciones_obj[cubicacion.cm] = cubicacion.kilogramo);
                    recipiente['cubicaciones_obj'] = cubicaciones_obj;
                }
            });
        }
        dispatch({type: RECIPIENTES, recipientes: recipientes})
    }).catch(() => {
    }).finally(() => {
    });
};

/***
 * DEVUELVE EL LISTADO DE MATERIALES QUE COMPONEN EL LLENADO ACTUAL DE ESE ALMACEN.
 * @returns {Function}
 */
const getMaterialesByAlmacen = () => (dispatch, getStore) => {
    const resource = getStore().bodegas;
    let params = {};
    (resource.item && resource.item !== {}) && (params['id_almacen'] = resource.item.id);
    api.get('bodega/get_materiales_by_almacen', params).then((data) => {
        const materiales_bodega = data.results;
        if (materiales_bodega.length > 0) {
            //Mutar cada producto pra que tenga value y label
            materiales_bodega.forEach((material) => {
                material['value'] = material.materia.id;
                material['label'] = material.materia.nombre
            });
        }
        dispatch({type: MATERIALES_BODEGA, materiales_bodega: materiales_bodega})
    }).catch(() => {
    }).finally(() => {
    });
};


const eliminarRecipiente = (fields, index) => (dispatch, getStore) => {
    fields.remove(index);
};


/***
 * DEPENDIENDO DEL TIPO DE SALIDA QUE SE ESTÉ REALIZANDO, DEVUELVE LOS DATOS INICIALES DE LA FILA DE ESE RECIPIENTE
 * @param id
 * @param index
 * @returns {function(...[*]=)}
 */
const getDatoRecipiente = (id, index) => (dispatch, getStore) => {
    let resource = getStore().bodegas;
    let form = getStore().form.SalidaForm;
    let peso_inicial_origen = form.values.peso_inicial_origen;
    let recipiente = undefined;


    if (resource.tipo_salida === "PILAS") {
        const recipientes = resource.recipientes;
        recipiente = _.find(recipientes, {id: id});
        recipiente['altura_disponible'] = recipiente.altura_maxima - recipiente.altura_utilizada;
        recipiente['altura_inicial'] = recipiente.altura_utilizada;
        recipiente['altura_final'] = undefined;
        recipiente['diferencia'] = 0;
        recipiente['peso_diferencia'] = 0;
    } else {
        const recipientes = form.values.recipientes;
        const materiales_bodega = resource.materiales_bodega;
        let material = _.find(materiales_bodega, {id: id});
        recipiente = _.find(recipientes, {id_materia: material.materia.id});
        recipiente['peso_inicial'] = material.existencias;
        recipiente['a_transferir'] = undefined;
        recipiente['peso_final'] = material.existencias;

        form.values.peso_final_origen = peso_inicial_origen
    }
    form.values.recipientes[index] = recipiente;

};


/***
 * TAMBIÉN, DEPENDIENDO DEL TIPO DE SALIDA, CALCULA YA SEAN CMS FINALES EN CASO DE PILAS Y KGS FINALES EN CASO DE
 * SÓLIDOS, CON ESTE ÚLTIMO TAMBIÉN VA ALTERANDO EL VALOR DEL PESO FINAL DEL ORIGEN DE MANERA AUTOMÁTICA Y LO ESTABLECE
 * EN EL FORM.
 * @param valor
 * @param index
 * @returns {function(...[*]=)}
 */
const getDatoDiferencia = (valor, index) => (dispatch, getStore) => {
    let resource = getStore().bodegas;
    let form = getStore().form.SalidaForm;
    let recipiente = form.values.recipientes[index];


    if (resource.tipo_salida === "PILAS") {
        if (valor !== undefined && valor !== null && recipiente.id !== undefined) {
            // Se calcula la diferencia entre las cubicaciones final e inicial porque ese es el peso que realmente va a ingresar
            let cubicacion_final = _.find(recipiente['cubicaciones'], {cm: `${recipiente['altura_final']}`});
            let cubicacion_inicial = _.find(recipiente['cubicaciones'], {cm: `${recipiente['altura_inicial']}`});
            let peso_diferencia = (cubicacion_final !== undefined && cubicacion_inicial !== undefined) ? cubicacion_final.kilogramo - cubicacion_inicial.kilogramo : 0;
            recipiente['peso_diferencia'] = peso_diferencia;
            recipiente['diferencia'] = recipiente['altura_final'] - recipiente['altura_inicial'];
        } else {
            recipiente['peso_diferencia'] = 0;
            recipiente['diferencia'] = 0;
        }
    } else {
        let peso_inicial_origen = form.values.peso_inicial_origen;
        let calc_peso_final_origen = 0;

        if (valor !== undefined) {
            let peso_final = recipiente['peso_inicial'] - valor;
            recipiente['peso_final'] = peso_final;
            calc_peso_final_origen = peso_inicial_origen - valor
        } else {
            calc_peso_final_origen = peso_inicial_origen
        }
        form.values.peso_final_origen = calc_peso_final_origen
    }
    form.values.recipientes[index] = recipiente;


};

const crearSalida = (dataForm) => (dispatch, getStore) => {
    let resource = getStore().bodegas;
    let formData = dataForm;
    dispatch(setLoader(true));
    let url = resource.tipo_salida === "PILAS" ? 'bodega/crear_salida_latex' : 'bodega/crear_salida_solidos';
    api.post(url, formData).then(() => {
        NotificationManager.success('Salida de látex realizada con éxito', 'Éxito', 3000);
        resource.tipo_salida === "PILAS" ? dispatch(push('/pilas')) : dispatch(push('/almacenes'));
    }).catch((error) => {
        NotificationManager.error(error.detail ? error.detail : 'No se puede completar la acción', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });

};

const setAlturaFinalOrigen = (valor = undefined) => (dispatch, getStore) => {
    let resource = getStore().bodegas;
    let item = resource.item;
    item['altura_final_origen'] = (valor !== undefined ? valor : item.altura_utilizada);
    dispatch(setItem(item));
};

const initialLoad = () => (dispatch, getStore) => {
    let resource = getStore().bodegas;
    !_.isEmpty(resource.item) && (dispatch(getRecipientes()));
    (resource.tipo_salida === 'ALMACENES' && !_.isEmpty(resource.item)) && (dispatch(getMaterialesByAlmacen()));
    dispatch(setAlturaFinalOrigen())
};

const resetAlturaMaxima = () => (dispatch, getStore) => {
    let form = getStore().form.BodegaForm.values;
    dispatch(initializeForm('BodegaForm', {...form, 'altura_maxima': undefined}));
    const store = getStore().bodegas;
    dispatch({type: CUBICACIONES, cubicaciones: []});

};

const setBloqueoPila = (idPila, estadoBloqueo = False) => async (dispatch) => {
    dispatch(setLoader(true));
    try {
        const actualizar = await api.put(`bodega/${idPila}/set_bloqueo_pila`, {estado_bloqueo: estadoBloqueo});
        // Se vuelve a realizar la petición de la data
        const info = await api.get(`bodega/${idPila}`, {filtro: 'pila'});
        const inventario = await api.get(`bodega/${idPila}/get_inventario_pila`, {page: 1});
        dispatch(setItem(info));
        dispatch({type: INVENTARIO_PRODUCTOS_PILA_PAGE, page: 1});
        dispatch({type: INVENTARIO_PRODUCTOS_PILA_DATA, data: inventario});
        NotificationManager.success('Estado cambiado con éxito', 'Información', 3000);
    } catch (error) {
        NotificationManager.error((error) ? error.body.detail : 'Error en la creación', 'ERROR', 4000);
    } finally {
        dispatch(setLoader(false));
    }
};

// Funciones para el Reporte de traslados de materia
const trasladoSolidoFechaInicialChange = solido_fecha_inicial => (dispatch) => {
    dispatch({type: REPORTE_TRASLADOS_SOLIDO_FECHA_INICIAL, solido_fecha_inicial});
    //dispatch(getReporteTrasladosMateria());
};
const trasladoSolidoFechaFinalChange = solido_fecha_final => (dispatch) => {
    dispatch({type: REPORTE_TRASLADOS_SOLIDO_FECHA_FINAL, solido_fecha_final});
    //dispatch(getReporteTrasladosMateria());
};
const trasladoSolidoBodegaOrigenChange = solido_almacen_origen_id => (dispatch) => {
    dispatch({type: REPORTE_TRASLADOS_SOLIDO_ALMACEN_ORIGEN_ID, solido_almacen_origen_id});
    //dispatch(getReporteTrasladosMateria());
};
const trasladoSolidoBodegaDestinoChange = solido_almacen_destino_id => (dispatch) => {
    dispatch({type: REPORTE_TRASLADOS_SOLIDO_ALMACEN_DESTINO_ID, solido_almacen_destino_id});
    //dispatch(getReporteTrasladosMateria());
};
const getReporteTrasladosMateria = (solido_page = 1) => async (dispatch, getStore) => {
    // Obtiene la data para construir el Reporte de traslados de materia entre Bodegas
    // Realiza 2 peticiones hacia la API para obtener la data total y la data paginada.
    //
    // Construcción de los parámetros de búsqueda. Las fechas son requeridas
    const resource = getStore().bodegas;
    const reporte = resource.reporte_traslados;
    const params = {};
    params.page = solido_page === -1 ? reporte.solido_page : solido_page;
    reporte.solido_fecha_inicial      !== null && (params.fecha_inicial      = moment(reporte.solido_fecha_inicial).format("YYYY-MM-DD") + ' 00:00:00');
    reporte.solido_fecha_final        !== null && (params.fecha_final        = moment(reporte.solido_fecha_final).format("YYYY-MM-DD") + ' 23:59:59');
    reporte.solido_almacen_origen_id  !== -1   && (params.bodega_origen_id  = reporte.solido_almacen_origen_id);
    reporte.solido_almacen_destino_id !== -1   && (params.bodega_destino_id = reporte.solido_almacen_destino_id);
    if (params.fecha_inicial !== undefined && params.fecha_final !== undefined) {
        // Inicio de la petición al servidor
        dispatch(setLoader(true));
        try {
            const trasladosAll = await api.get('bodega/get_data_reporte_traslados/', params);
            params.paginar_data = true;
            const traslados = await api.get('bodega/get_data_reporte_traslados/', params);
            traslados.all_results = trasladosAll.results;
            traslados.all_count = trasladosAll.count;
            dispatch({type: REPORTE_TRASLADOS_SOLIDO_PAGE, solido_page: params.page});
            dispatch({type: REPORTE_TRASLADOS_DATA, traslados});
        } catch(error) {
        } finally {
            dispatch(setLoader(false));
        }
    }
};

export const actions = {
    leer,
    crear,
    editar,
    listar,
    eliminar,
    clearData,
    get_pilas,
    reajustarInventarioSolido,
    reajustarInventarioLatex,
    crearFilas,
    crearSalida,
    get_tanques,
    initialLoad,
    onSortChange,
    searchChange,
    get_almacenes,
    getAlmacenesDataTotales,
    obtenerBodega,
    setTypeSalida,
    getRecipientes,
    onFiltroChange,
    setCubicaciones,
    getProductosPila,
    getDatoDiferencia,
    getDatoRecipiente,
    resetAlturaMaxima,
    eliminarRecipiente,
    getProductosAlmacen,
    setAlturaFinalOrigen,
    getMaterialesByAlmacen,
    crearActualizarAnalisis,
    setBloqueoPila,
    // Reporte de traslados de materia
    trasladoSolidoFechaInicialChange,
    trasladoSolidoFechaFinalChange,
    trasladoSolidoBodegaOrigenChange,
    trasladoSolidoBodegaDestinoChange,
    getReporteTrasladosMateria,
};

export const reducers = {
    [LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, {data}) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, {item}) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, {page}) => {
        return {
            ...state,
            page,
        };
    },
    [PAGE_TANQUES]: (state, {page_tanques}) => {
        return {
            ...state,
            page_tanques,
        };
    },
    [PAGE_PILAS]: (state, {page_pilas}) => {
        return {
            ...state,
            page_pilas,
        };
    },
    [PAGE_ALMACENES]: (state, {page_almacenes}) => {
        return {
            ...state,
            page_almacenes,
        };
    },
    [ORDERING]: (state, {ordering}) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, {search}) => {
        return {
            ...state,
            search,
        };
    },
    [DATA_TANQUES]: (state, {tanques}) => {
        return {
            ...state,
            tanques,
        };
    },
    [DATA_PILAS]: (state, {pilas}) => {
        return {
            ...state,
            pilas,
        };
    },
    [ALMACENES_TOTALES_DATA]: (state, {almacenes_totales}) => {
        return {
            ...state,
            almacenes_totales,
        };
    },
    [DATA_ALMACENES]: (state, {almacenes}) => {
        return {
            ...state,
            almacenes,
        };
    },
    [FILTRO_TIPO_BODEGAS]: (state, {filtro}) => {
        return {
            ...state,
            filtro,
        };
    },
    [CUBICACIONES]: (state, {cubicaciones}) => {
        return {
            ...state,
            cubicaciones,
        };
    },
    [CUBICACIONES_COPY]: (state, {cubicaciones_copy}) => {
        return {
            ...state,
            cubicaciones_copy,
        };
    },
    [INVENTARIO_PRODUCTOS_ALMACEN_PAGE]: (state, {page}) => {
        return {
            ...state,
            productos_almacen: {...state.productos_almacen, page},
        };
    },
    [INVENTARIO_PRODUCTOS_ALMACEN_DATA]: (state, {data}) => {
        return {
            ...state,
            productos_almacen: {...state.productos_almacen, data},
        };
    },
    [INVENTARIO_PRODUCTOS_PILA_PAGE]: (state, {page}) => {
        return {
            ...state,
            productos_pila: {...state.productos_pila, page},
        };
    },
    [INVENTARIO_PRODUCTOS_PILA_DATA]: (state, {data}) => {
        return {
            ...state,
            productos_pila: {...state.productos_pila, data},
        };
    },
    [RECIPIENTES]: (state, {recipientes}) => {
        return {
            ...state,
            recipientes,
        };
    },
    [MATERIALES_BODEGA]: (state, {materiales_bodega}) => {
        return {
            ...state,
            materiales_bodega,
        };
    },
    [TIPO_SALIDA]: (state, {tipo_salida}) => {
        return {
            ...state,
            tipo_salida,
        };
    },
    // Reporte de traslados de materia
    [REPORTE_TRASLADOS_DATA]: (state, {traslados}) => {
        return {
            ...state,
            reporte_traslados: {...state.reporte_traslados, traslados},
        };
    },
    [REPORTE_TRASLADOS_SOLIDO_PAGE]: (state, {solido_page}) => {
        return {
            ...state,
            reporte_traslados: {...state.reporte_traslados, solido_page},
        };
    },
    [REPORTE_TRASLADOS_SOLIDO_FECHA_INICIAL]: (state, {solido_fecha_inicial}) => {
        return {
            ...state,
            reporte_traslados: {...state.reporte_traslados, solido_fecha_inicial},
        };
    },
    [REPORTE_TRASLADOS_SOLIDO_FECHA_FINAL]: (state, {solido_fecha_final}) => {
        return {
            ...state,
            reporte_traslados: {...state.reporte_traslados, solido_fecha_final},
        };
    },
    [REPORTE_TRASLADOS_SOLIDO_ALMACEN_ORIGEN_ID]: (state, {solido_almacen_origen_id}) => {
        return {
            ...state,
            reporte_traslados: {...state.reporte_traslados, solido_almacen_origen_id},
        };
    },
    [REPORTE_TRASLADOS_SOLIDO_ALMACEN_DESTINO_ID]: (state, {solido_almacen_destino_id}) => {
        return {
            ...state,
            reporte_traslados: {...state.reporte_traslados, solido_almacen_destino_id},
        };
    },
};

export const initialState = {
    loader: false,
    page: 1,
    item: {},
    search: '',
    data: {
        results: [],
        count: 0,
    },
    filtro: null,
    ordering: '',
    page_pilas: 1,
    pilas: {
        results: [],
        count: 0,
    },
    page_tanques: 1,
    tanques: {
        results: [],
        count: 0,
    },
    recipientes: [],
    materiales_bodega: [],
    page_almacenes: 1,
    almacenes_totales: {
        almacenes: 0,
        contenedores: 0,
        existencias: 0,
    },
    almacenes: {
        results: [],
        count: 0,
    },
    productos_pila: {
        page: 1,
        data: {
            count: 0,
            results: [],
        },
    },
    tipo_salida: "PILAS",
    cubicaciones: [
        [{value: 0}, {value: 0}],
        [{value: 0.01}, {value: 0}]
    ],
    cubicaciones_copy: [],
    productos_almacen: {
        page: 1,
        data: {
            count: 0,
            results: [],
        },
    },
    page_almacenes_pilas: 1,
    // Variables para el Reporte de traslados de materia
    reporte_traslados: {
        solido_page: 1,
        solido_fecha_inicial: moment().startOf('month'),
        solido_fecha_final:   moment().endOf('month'),
        solido_almacen_origen_id:  -1,
        solido_almacen_destino_id: -1,
        traslados: {}
    }
};

export default handleActions(reducers, initialState);
