import React from 'react';
import moment from 'moment';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import Grid from '../../../Utils/Grid';
import { RenderKilogramo, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';
import { standardActions } from '../../../Utils/Grid/StandardActions';

const expandComponent = (row) => {
    let _data = row.movimientos ? row.movimientos.slice() : [];
    return(
        <div className="row d-flex flex-row justify-content-center">
            <div className="col-11 ml-5">
                <div className="" style={{width: "100%"}}>
                    <BootstrapTable data={_data} headerStyle={ { backgroundColor: '#e24647' } }>
                        <TableHeaderColumn dataField="ingreso_mov_padre"
                            isKey
                            dataFormat={cell => cell ? cell.id : '--'}
                        >
                            ID RECEPCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ingreso_mov_padre"
                            dataFormat={cell => cell && cell.fecha_entrada ? moment(cell.modificado).format('DD-MM-YYYY') : '--'}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="finca"
                            dataFormat={cell => cell && cell.nombre ? cell.nombre : ''}
                        >
                            FINCA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="materia_prima"
                            dataFormat={cell => cell && cell.nombre ? cell.nombre : ''}
                        >
                            PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="peso_neto"
                            dataAlign="right"
                            dataFormat={cell => cell ? <RenderKilogramo value={cell} /> : '--'}
                        >
                            KG. HÚMEDOS BÁSCULA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="analisis_mov_padre"
                            dataAlign="right"
                            dataFormat={cell => cell ? <RenderNumberPercentage value={cell.drc_promedio} /> : '--'}
                        >
                            DRC
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="analisis_mov_padre"
                            dataAlign="right"
                            dataFormat={(cell, row) => cell ? <RenderKilogramo value={row.peso_neto * cell.drc_promedio} /> : '--'}
                        >
                            KG. SECOS
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div>
            </div>
        </div>
    )
};

let ReporteDetalle = (props) => {
    const {
        reporte_pila_almacen: reporte,
        bodegaDetallePageChange,
    } = props;
    let data = reporte.detalle_data && reporte.detalle_data.results ? reporte.detalle_data : [];
    return (
        <div>
            <div className="mb-4">
                <CardEmpty noShadow>
                    <div className=" col-12 form-group np-r p-0 mb-1 inicio-tabla">
                        {/* <Grid hover striped loading={reporte.loader} data={data}
                            pagination page={reporte.detalle_page} onPageChange={bodegaDetallePageChange}
                            className="pt-3" expandableRow={()=>true}
                            expandComponent={expandComponent}
                        >
                            <TableHeaderColumn dataField="analisis_laboratorio"
                                isKey
                                dataFormat={(cell,row) => row.nombre}
                            >
                                PILA
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="analisis_laboratorio"
                                dataFormat={cell => cell ? moment(cell.modificado).format('DD-MM-YYYY') : '--'}
                            >
                                FECHA ANÁLISIS
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="analisis_laboratorio"
                                dataAlign="right"
                                dataFormat={cell => cell && cell.total_kg_humedos_al_validar ? <RenderKilogramo value={cell.total_kg_humedos_al_validar} /> : '--'}
                            >
                                KG. HÚMEDOS PILA
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="analisis_laboratorio"
                                dataAlign="right"
                                dataFormat={cell => cell ? <RenderNumberPercentage value={cell.drc_promedio} /> : '--'}
                            >
                                DRC PILA
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="kg_secos_pila"
                                dataAlign="right"
                                dataFormat={cell => cell ? <RenderKilogramo value={cell} /> : '--'}
                            >
                                KG. SECOS PILA
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="kg_secos_recepciones"
                                dataAlign="right"
                                dataFormat={cell => cell ? <RenderKilogramo value={cell} /> : '--'}
                            >
                                KG. SECOS INGRESOS
                            </TableHeaderColumn>
                            <TableHeaderColumn dataField="kg_secos_recepciones"
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderKilogramo value={cell - row.kg_secos_pila}/>}
                            >
                                DIFERENCIA
                            </TableHeaderColumn>
                        </Grid> */}
                        <Grid hover striped loading={reporte.loader} data={data}
                            pagination page={reporte.detalle_page} onPageChange={bodegaDetallePageChange}
                            className="pt-3"
                        >
                            <TableHeaderColumn
                                dataField="id"
                                isKey
                                width="10%"
                                dataFormat={standardActions({ver: 'reporte_pila_almacen'})}
                            />
                            <TableHeaderColumn
                                dataField="nombre"
                            >
                                PILA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha_analisis"
                                dataFormat={cell => moment(cell).format('DD-MM-YYYY')}
                            >
                                FECHA ANALISIS
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="kg_humedos_pila"
                                dataFormat={cell => <RenderKilogramo value={cell}/>}
                            >
                                KG. HÚMEDOS PILA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="drc_pila"
                                dataFormat={cell => <RenderNumberPercentage value={cell}/>}
                            >
                                DRC PILA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="kg_secos_pila"
                                dataFormat={cell => <RenderKilogramo value={cell}/>}
                            >
                                KG. SECOS PILA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="kg_secos_ingresos"
                                dataFormat={cell => <RenderKilogramo value={cell}/>}
                            >
                                KG. SECOS INGRESOS
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataFormat={(cell, row) => <RenderKilogramo value={row.kg_secos_ingresos - row.kg_secos_pila}/>}
                            >
                                DIFERENCIA
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </CardEmpty>
            </div>
        </div>
    )
};
export default ReporteDetalle;