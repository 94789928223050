import React, {Component} from 'react'
import Grid from "Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card"
import { formatActivo } from '../../../../Utils/renderField/renderReadField';
import {RenderCM, formatoPorcentaje} from "Utils/renderField/renderReadField"

export default class TanquesList extends Component{
    componentWillMount() {
        const { get_tanques, page_tanques } = this.props;
        get_tanques(page_tanques);
    }

    render() {
        const { get_tanques: onPageChange, onSortChange, eliminar } = this.props;
        const { tanques, loader, page_tanques } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="bodega_activo"
                    titulo="TANQUES"
                    subtitulo="Producción/Maduración">
                    <Grid hover striped data={tanques} loading={loader} onPageChange={onPageChange}
                        onSortChange={onSortChange} page={page_tanques}
                        className="pb-3"
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            width='10%'
                            isKey
                            dataSort
                            dataFormat={standardActions({ver_bodega: "pila"})}
                        />
                        <TableHeaderColumn
                            dataField="nombre"
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="materia_prima"
                            dataFormat={cell => cell ? cell.nombre : '--'}
                        >
                            MATERIA PRIMA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="altura_maxima"
                            dataAlign="right"
                            dataFormat={(cell,row) => <RenderCM value={cell} />}
                        >
                            ALTURA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="altura_utilizada"
                            dataAlign="right"
                            dataFormat={cell => <RenderCM  value={cell}/>}
                        >
                            UTILIZADO CM
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="porcentaje"
                            dataFormat={formatoPorcentaje}
                        >
                            UTILIZADO %
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="center"
                            dataField="id"
                            width='8%'
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="acopio"
                            dataFormat={(cell) => cell ? cell.nombre : 'Planta ERSA'}
                        >
                            LUGAR
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="bloqueado"
                            dataAlign="center"
                            dataFormat={cell => formatActivo(!cell)}
                        >
                            ACTIVO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
