import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/producciones';
import ProduccionVer from './ProduccionVer';


const ms2p = (state) => {
  return {
    ...state.producciones,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ProduccionVer);
