import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './ConfiguracionForm';

export default class ConfiguracionEditar extends Component {
  state = {
      editar: false,
  };
  componentWillMount(){
      this.props.loadConfiguracion();
  }
    actualizar = (data) =>{
        this.props.editar(this.props.configuraciones.id, data)
    };
  render() {
    const { crear, editar } = this.props;
    const { configuraciones, loader } = this.props;
    configuraciones.precios_ingreso_manual = false;
    return (
      <div className="mb-4 col-12">
          <CardForm
              icon="configuraciones_activo"
              titulo="CONFIGURACIÓN"
              subtitulo="">
              <LoadMask loading={loader} blur>
                  <Form
                      onSubmit={this.actualizar}
                      configuraciones={configuraciones}
                      editando={true} />
              </LoadMask>
          </CardForm>
      </div>
    )
  }
}
