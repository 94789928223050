import React, { Component, Fragment } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import {ProgressBar, Step} from "react-step-progress-bar";
import CardEmpty from "Utils/Cards/CardEmpty";
import {Link} from "react-router-dom";
import moment from "moment/moment";
import Grid from "../../../Utils/Grid";
import { RenderKilogramo, RenderNumber, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';

export default class ProduccionVer extends Component {
    componentWillMount(){
        this.props.leer(this.props.match.params.id, 'finalizadas');
    }

    render() {
    const {item, loader} = this.props;
    let total_almacen = 0;
    if (item && item.materia_almacen) {
        for (let i = 0; i < item.materia_almacen.length; i++) {
            total_almacen += item.materia_almacen[i].peso_extraido;
            console.log(total_almacen);
        }
    }
    return (
        <div className="col-12">
          <CardForm
              icon="produccion_activo"
              titulo="PRODUCCIÓN"
              col="12"
              subtitulo="Detalle">
              <LoadMask loading={loader} blur>
                <div className="form-group grid-container d-flex align-items-center flex-column">
                    <div className="col-12 col-lg-8 d-flex flex-wrap justify-content-between align-items-baseline">
                        <h6 className="mb-3 ml-5">DATOS DE CIERRE</h6>
                        <label className="mb-3 mr-5">NO. PRODUCCIÓN <span className="gris text-md">{item.numero_produccion}</span></label>
                    </div>
                    <div className="col-12 col-lg-8 d-flex flex-wrap justify-content-between align-items-baseline">
                        <div className="d-flex flex-wrap flex-column ml-5">
                            <label className="p-0 m-0">FECHA DE INICIO</label>
                            <span className="mb-1 gris bold">{item ? moment(item.fecha_inicio_produccion).format("DD/MM/YYYY") : ""}</span>
                        </div>
                        <div className="d-flex flex-wrap flex-column mr-5">
                            <label className="p-0 m-0">PRODUCTO PRODUCIDO</label>
                            <span className="mb-1 gris bold">{item && item.materia_prima ? item.materia_prima.nombre : ""}</span>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <h6 className="bold">TURNOS DE SUPERVISORES</h6>
                        <Grid data={item.turnos_supervisores ? {results: item.turnos_supervisores} : {}} loading={loader} pagination={false}  >
                            <TableHeaderColumn width='15%'/>
                            <TableHeaderColumn
                                dataField="fecha_turno"
                                dataFormat={cell => moment(cell).format("DD/MM/YYYY")}
                            >
                                FECHA TURNO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="id"
                                isKey
                                hidden
                            />
                            <TableHeaderColumn
                                dataField="turno"
                            >
                                TURNO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="supervisor"
                                dataFormat={cell => cell ? cell.nombre : ""}
                            >
                                SUPERVISOR
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                    <div className="col-12 mt-4">
                        <h6 className="bold">ALMACENES Y PESOS</h6>
                        <Grid data={item.materia_almacen ? {results: item.materia_almacen} : {}} loading={loader} pagination={false}  >
                            <TableHeaderColumn width='10%'/>
                            <TableHeaderColumn
                                dataField="id"
                                isKey
                                hidden
                            />
                            <TableHeaderColumn
                                dataField="almacen"
                                dataAlign="center"
                                dataFormat={cell => cell ? cell.nombre : ""}
                            >
                                ALMACEN
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="peso_extraido"
                                dataAlign="right"
                                dataFormat={cell => <RenderKilogramo value={cell} />}
                            >
                                PESO EXTRAIDO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="materia_nombre"
                                dataAlign="center"
                            >
                                PRODUCTO
                            </TableHeaderColumn>
                            <TableHeaderColumn width='10%'/>
                        </Grid>
                    </div>
                    <div className="row col-12 col-lg-10 d-flex flex-wrap justify-content-center mt-4">
                        <div className="col-6">
                            <div className="contorno-externo">
                                <div className="interior-contorno">
                                    <div className="col-12 d-flex flex-wrap justify-content-center align-items-center">
                                        <div className="col-12 d-flex flex-wrap align-items-baseline justify-content-center p-1">
                                            <span className="gris bold mr-4">PESO TOTAL EXTRAIDO</span>
                                            <span className="bold text-sm"><RenderKilogramo value={total_almacen} /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <CardEmpty noShadow>
                            <div className="col-12 col-lg-12 d-flex flex-wrap justify-content-between align-items-center row">
                                <div className="d-flex flex-wrap flex-column ml-5 col-12 col-lg-2">
                                    <label className="p-0 m-0">FECHA DE CIERRE</label>
                                    <span className="mb-1 gris bold">{item ? moment(item.fecha_fin_produccion).format("DD/MM/YYYY") : ""}</span>
                                </div>
                                <div className="col-12 col-lg-9">
                                    <div className="contorno-externo">
                                        <div className="interior-contorno">
                                            <div className="col-12 d-flex flex-wrap justify-content-between align-items-center p-1">
                                                <div className="d-flex flex-wrap align-items-baseline justify-content-start ml-3 mt-2 mb-2">
                                                    <span className="gris bold mr-3">PESO PRODUCCIÓN FINAL</span>
                                                    <span className="bold text-sm">{item ? <RenderKilogramo value={item.peso_total} /> : ""}</span>
                                                </div>
                                                <div className="d-flex flex-wrap align-items-baseline justify-content-start mr-3 mt-2 mb-2">
                                                    <span className="gris bold mr-3">DRC PRODUCCIÓN</span>
                                                    <span className="bold text-sm">{item ? <RenderNumberPercentage value={item.drc_produccion ? item.drc_produccion : 0} /> : ""}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-2">
                                <h6 className="bold">PALLETS</h6>
                                <Grid data={item.pallets ? {results: item.pallets} : {}} loading={loader} pagination={false}  >
                                    <TableHeaderColumn width='15%'/>
                                    <TableHeaderColumn
                                        dataField="id"
                                        isKey
                                        hidden
                                    />
                                    <TableHeaderColumn
                                        dataField="numero_serie"
                                    >
                                        NO. SERIE
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="numero_pacas"
                                        dataAlign="right"
                                        dataFormat={cell => <RenderNumber value={cell} suffix={cell === 1 ? " Paca" : " Pacas"}/>}
                                    >
                                        NUMERO DE PACAS
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="peso_total"
                                        dataAlign="right"
                                        dataFormat={cell => <RenderKilogramo value={cell} />}
                                    >
                                        PESO TOTAL
                                    </TableHeaderColumn>
                                    <TableHeaderColumn width='15%'/>
                                </Grid>
                            </div>
                        </CardEmpty>
                    </div>
                </div>
                <div className=" col-12 p-0">
                    <div className="row">
                        <div className="col-12 row">
                            <div className="col-12 d-flex justify-content-center mt-5">
                                <div className="col-9 buttons-box">
                                    <Link className="btn btn-outline-dark mr-5" to="/producciones">REGRESAR</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </LoadMask>
          </CardForm>
      </div>
    )
  }
}
