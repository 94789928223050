import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/acopios/acopios';
import AcopioSave from './AcopioSave';

const mstp = state => {
    return {...state.acopios}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(AcopioSave)
