import React from 'react';
import moment from 'moment/moment';
import _ from 'lodash';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import Grid from '../../../Utils/Grid';
import { RenderKilogramo, RenderCurrency, RenderNumber, RenderDollar, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';
import { PrintContainer, actions as printActions } from 'Utils/Print';
import { FLETE_INTERNO_COSTO_POR_KG } from '../../../../../utility/constants';
const expandComponent = (row) => {
    let _data = row.detalles ? row.detalles.slice() : [];
    return(
        <div className="row d-flex flex-row justify-content-center">
            <div className="col-11 ml-5">
                <div className="" style={{width: "100%"}}>
                    <BootstrapTable data={_data} headerStyle={ { backgroundColor: '#e24647' } }>
                        <TableHeaderColumn dataField="id" isKey hidden>
                            NO. DETALLE ORDEN COMPRA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ingreso" dataFormat={cell => cell && cell.id ? cell.id : '--'}>
                            NO. RECEPCION
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="ingreso"
                            dataFormat={cell => cell && cell.fecha_entrada ? moment(cell.fecha_entrada).format('DD-MM-YYYY') : '--'}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="materia_prima"
                            dataFormat={cell => cell && cell.nombre ? cell.nombre : ''}
                        >
                            PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="kilos_humedos"
                            dataAlign="right"
                            dataFormat={cell => cell ? <RenderKilogramo value={cell} decimalScale={2} /> : '--'}
                        >
                            KG. HUMEDOS
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="drc_compra"
                            dataAlign="right"
                            dataFormat={cell => cell ? <RenderNumberPercentage value={cell} decimalScale={2} /> : '--'}
                        >
                            DRC
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="kilos_secos"
                            dataAlign="right"
                            dataFormat={cell => cell ? <RenderKilogramo value={cell} decimalScale={2} /> : '--'}
                        >
                            KG. SECOS
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="precio_venta"
                            dataAlign="right"
                            dataFormat={(cell, row) => {
                                // El precio_venta (precio unitario) debe incluir el precio unitario de flete
                                const { precio_venta=0, costo_flete=0, tipo_costo_flete=0 } = row;
                                const total_precio_venta = precio_venta + (tipo_costo_flete === FLETE_INTERNO_COSTO_POR_KG ? costo_flete : 0);
                                return <RenderDollar value={total_precio_venta} decimalScale={4}/>
                            }}
                        >
                            PRECIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="total_dolares"
                            dataAlign="right"
                            dataFormat={cell => cell ? <RenderDollar value={cell} decimalScale={2} /> : '--'}
                        >
                            TOTAL $
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="cambio_dia"
                            dataAlign="right"
                            dataFormat={cell => cell ? <RenderCurrency value={cell} decimalScale={5} /> : '--'}
                        >
                            CAMBIO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="total_quetzales"
                            dataAlign="right"
                            dataFormat={cell => cell ? <RenderCurrency value={cell} decimalScale={2} /> : '--'}
                        >
                            TOTAL Q
                        </TableHeaderColumn>

                    </BootstrapTable>
                </div>
            </div>
        </div>
    )
};

const Detalle = (props) => {
    const {loader, data, filtro_semanas_reporte_liq_semanal, filtro_anio_reporte_liq_semanal, item, page, pageChange} = props;
    return (
        <div>
            <div className="mb-4">
                <CardEmpty noShadow>
                    <div className="d-flex flex-wrap justify-content-center">
                        <h6 className="mb-1 mt-1 mr-5 bold">{"Fecha inicio: " + moment().year(filtro_anio_reporte_liq_semanal.value).isoWeekday(7).week(filtro_semanas_reporte_liq_semanal.value-1).format("YYYY-MM-DD")}</h6>
                        <h6 className="mb-1 mt-1 bold">{"Fecha fin: " + moment().year(filtro_anio_reporte_liq_semanal.value).isoWeekday(6).week(filtro_semanas_reporte_liq_semanal.value).format("YYYY-MM-DD")}</h6>
                    </div>
                    <div className=" col-12 form-group np-r p-0 mb-1 inicio-tabla mt-3 text-xs">
                        <Grid hover striped loading={loader} data={item}
                            pagination onPageChange={pageChange} page={page}
                            className="pt-3" expandableRow={()=>true}
                            expandComponent={expandComponent}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                dataSort
                                isKey
                                dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell}</span>}
                                // width="17"
                            >
                                NO. LIQ.
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="fecha_liquidacion"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell ? moment(cell).format('DD-MM-YYYY') : "#"+0}
                                // width="15"
                            >
                                FECHA
                            </TableHeaderColumn>
                            {/* <TableHeaderColumn
                                dataField="finca"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.codigo_proveedor_sae ? cell.codigo_proveedor_sae : ""}
                                // width="17"
                            >
                                SAE PROV
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="finca"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.codigo_cliente_sae ? cell.codigo_cliente_sae : ""}
                                // width="17"
                            >
                                SAE CLIE
                            </TableHeaderColumn> */}
                            <TableHeaderColumn
                                dataField="finca"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.nombre ? cell.nombre : ""}
                                // width="17"
                            >
                                FINCA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="finca"
                                dataSort
                                dataFormat={(cell,row) => cell && cell.razon_social ? cell.razon_social : ''}
                            >
                                BENEFICIARIO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="kilos_humedos"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderKilogramo value={cell ? cell : 0}/>}
                                // width="17"
                            >
                                KG. HUMEDOS
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="kilos_secos"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderKilogramo value={cell ? cell : 0}/>}
                                // width="17"
                            >
                                KG. SECOS
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total_dolares"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderDollar value={cell ? cell : 0}/>}
                                // width="17"
                            >
                                TOTAL $
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total_quetzales"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                // width="17"
                            >
                                TOTAL Q
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="retencion_iva"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderCurrency value={cell && !!_.get(row, 'finca.retenedor_iva', true) ? cell : 0}/>}
                                // width="17"
                            >
                                IVA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="retencion_isr"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderCurrency value={cell && !!_.get(row, 'finca.retenedor_iva', true) ? cell : 0}/>}
                                // width="17"
                            >
                                ISR
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="retencion_peq_contribuyente"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderCurrency value={cell && !!_.get(row, 'finca.retenedor_iva', true) ? cell : 0}/>}
                                // width="17"
                            >
                                PEQ. CONT
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total_insumos"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                // width="17"
                            >
                                INSUMOS
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total_liquidar"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => {
                                    // TODO: Cambio por ticket #1570
                                    // Si finca.retenedor_iva === false entonces total_liquidar = total_quetzales - total_insumos
                                    // No se toma total_liquidar ya que total_liquidar + (SUMA(impuestos_retenidos)) - total_insumos !== total_quetzales (que en
                                    // teoría debería dar lo mismo).
                                    let total = cell;
                                    if (!!_.get(row, 'finca.retenedor_iva', true) === false) {
                                        total = _.get(row, 'total_quetzales', 0);
                                    }
                                    return <RenderCurrency value={total - _.get(row, 'total_insumos', 0)}/>
                                }}
                                // width="17"
                            >
                                TOTAL Q
                            </TableHeaderColumn>
                            {/* <TableHeaderColumn
                                dataField="finca"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.banco && cell.banco.nombre ? cell.banco.nombre : ""}
                                // width="17"
                            >
                                BANCO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="finca"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.numero_cuenta ? cell.numero_cuenta : ""}
                                // width="17"
                            >
                                NO CUENTA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="finca"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.tipo_pago && cell.tipo_pago.nombre ? cell.tipo_pago.nombre : ""}
                                // width="17"
                            >
                                TIPO PAGO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="finca"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.perfil && cell.perfil.nombre ? cell.perfil.nombre : ""}
                                // width="17"
                            >
                                PERFIL
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="numero_factura"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell ? cell : "--"}
                                // width="17"
                            >
                                FACTURA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="finca"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.regimen_fiscal_nombre ? cell.regimen_fiscal_nombre: ""}
                                // width="17"
                            >
                                REGIMEN
                            </TableHeaderColumn> */}
                        </Grid>
                    </div>
                </CardEmpty>
            </div>
        </div>
    )
};

export default Detalle;
