import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/catalogo_proveedores';
import Catalogo_Proveedores from './Catalogo_Proveedores';


const ms2p = (state) => {
  return {
    ...state.cdp,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Catalogo_Proveedores);
