import { handleActions } from 'redux-actions';
import { api } from "api";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import { INGRESO_FINALIZADO, LATEX, SOLIDO, NH3_IDEAL, COSTO_AMONIACO, meses , A_CANAL_COAGULACION} from '../../../utility/constants';
import moment from 'moment';

const LOADER = 'LOADER_RECEPCIONES';
const DATA = 'DATA_RECEPCIONES';
const MOVIMIENTOS = 'MOVIMENTOS_RECEPCIONES';
const ITEM_DATA = 'ITEM_RECEPCIONES';
const PAGE = 'PAGE_RECEPCIONES';
const PESOS_BASCULA = 'PESOS_BASCULA';
const PAGE_ACTIVAS = 'PAGE_RECEPCIONES_ACTIVAS';
const PAGE_FINALIZADAS = 'PAGE_RECEPCIONES_FINALIZADAS';
const ORDERING = 'ORDERING_RECEPCIONES';
const SEARCH = 'SEARCH_RECEPCIONES';
const TIPOS_VEHICULOS_RECEPCIONES = 'TIPOS_VEHICULOS_RECEPCIONES';
const PRODUCTOS_LATEX = 'PRODUCTOS_LATEX';
const PRODUCTOS_SOLIDO = 'PRODUCTOS_SOLIDO';
const PRODUCTO_EDITAR = 'PRODUCTO_EDITAR';
const PILAS_VACIADO = 'PILAS_VACIADO';
const ALMACENES_VACIADO = 'ALMACENES_VACIADO';
const TAB_RECEPCIONES = 'TAB_RECEPCIONES';
const FILTRAR_RECEPCIONES_POR_ACOPIO = 'RECEPCIONES_FILTRAR_RECEPCIONES_POR_ACOPIO';
const FINALIZADAS_FECHA_INICIAL = 'RECEPCIONES_FINALIZADAS_FECHA_INICIAL';
const FINALIZADAS_FECHA_FINAL = 'RECEPCIONES_FINALIZADAS_FECHA_FINAL';
const FECHA_INGRESO = 'RECEPCIONES_CREAR_FECHA_INGRESO';
// Variables para el Reporte de Recepciones
const REPORTE_TIPO_MATERIA = 'REPORTE_RECEPCIONES_TIPO_MATERIA';
const REPORTE_RECEPCIONES_LATEX  = 'REPORTE_RECEPCIONES_DATA_LATEX';
const REPORTE_RECEPCIONES_SOLIDO  = 'REPORTE_RECEPCIONES_DATA_SOLIDO';
const REPORTE_LATEX_PAGE          = 'REPORTE_RECEPCIONES_LATEX_PAGE';
const REPORTE_LATEX_FINCA         = 'REPORTE_RECEPCIONES_LATEX_FILTRO_FINCA';
const REPORTE_LATEX_ACOPIO        = 'REPORTE_RECEPCIONES_LATEX_FILTRO_ACOPIO';
const REPORTE_LATEX_MATERIA       = 'REPORTE_RECEPCIONES_LATEX_FILTRO_MATERIA';
const REPORTE_LATEX_FECHA_INICIAL = 'REPORTE_RECEPCIONES_LATEX_FECHA_INICIAL';
const REPORTE_LATEX_FECHA_FINAL   = 'REPORTE_RECEPCIONES_LATEX_FECHA_FINAL';
const REPORTE_SOLIDO_PAGE          = 'REPORTE_RECEPCIONES_SOLIDO_PAGE';
const REPORTE_SOLIDO_FINCA         = 'REPORTE_RECEPCIONES_SOLIDO_FILTRO_FINCA';
const REPORTE_SOLIDO_ACOPIO        = 'REPORTE_RECEPCIONES_SOLIDO_FILTRO_ACOPIO';
const REPORTE_SOLIDO_MATERIA       = 'REPORTE_RECEPCIONES_SOLIDO_FILTRO_MATERIA';
const REPORTE_SOLIDO_FECHA_INICIAL = 'REPORTE_RECEPCIONES_SOLIDO_FECHA_INICIAL';
const REPORTE_SOLIDO_FECHA_FINAL = 'REPORTE_RECEPCIONES_SOLIDO_FECHA_FINAL';
// Variables para el Reporte de Recepciones anuales
const REPORTE_ANUAL_TIPO_MATERIA = 'REPORTE_ANUAL_RECEPCIONES_TIPO_MATERIA';
const REPORTE_ANUAL_RECEPCIONES_LATEX  = 'REPORTE_ANUAL_RECEPCIONES_DATA_LATEX';
const REPORTE_ANUAL_RECEPCIONES_SOLIDO = 'REPORTE_ANUAL_RECEPCIONES_DATA_SOLIDO';
const REPORTE_ANUAL_LATEX_PAGE         = 'REPORTE_ANUAL_RECEPCIONES_LATEX_PAGE';
const REPORTE_ANUAL_LATEX_MATERIA       = 'REPORTE_ANUAL_RECEPCIONES_LATEX_FILTRO_MATERIA';
const REPORTE_ANUAL_LATEX_ANIOS          = 'REPORTE_ANUAL_LATEX_ANIOS';
const REPORTE_ANUAL_SOLIDO_PAGE          = 'REPORTE_ANUAL_RECEPCIONES_SOLIDO_PAGE';
const REPORTE_ANUAL_SOLIDO_MATERIA       = 'REPORTE_ANUAL_RECEPCIONES_SOLIDO_FILTRO_MATERIA';
const REPORTE_ANUAL_SOLIDO_ANIOS         = 'REPORTE_ANUAL_SOLIDO_ANIOS';
const REPORTE_ANUAL_GRAFICA              = 'REPORTE_ANUAL_GRAFICA';
// Variables para el Reporte de DRC de Recepciones
const REPORTE_DRC_RECEPCIONES = 'REPORTE_RECEPCIONES_DRC_DATA';
const REPORTE_DRC_TIPO_MATERIA = 'REPORTE_RECEPCIONES_DRC_TIPO_MATERIA';
const REPORTE_DRC_MATERIAS_LATEX = 'REPORTE_RECEPCIONES_DRC_MATERIAS_LATEX';
const REPORTE_DRC_MATERIAS_SOLIDO = 'REPORTE_RECEPCIONES_DRC_MATERIAS_SOLIDO';
const REPORTE_DRC_LATEX_PAGE = 'REPORTE_RECEPCIONES_DRC_LATEX_PAGE';
const REPORTE_DRC_LATEX_FINCA = 'REPORTE_RECEPCIONES_DRC_LATEX_FILTRO_FINCA';
const REPORTE_DRC_LATEX_MATERIA = 'REPORTE_RECEPCIONES_DRC_LATEX_FILTRO_MATERIA';
const REPORTE_DRC_LATEX_FECHA_INICIAL = 'REPORTE_RECEPCIONES_DRC_LATEX_FECHA_INICIAL';
const REPORTE_DRC_LATEX_FECHA_FINAL = 'REPORTE_RECEPCIONES_DRC_LATEX_FECHA_FINAL';
const REPORTE_DRC_SOLIDO_PAGE = 'REPORTE_RECEPCIONES_DRC_SOLIDO_PAGE';
const REPORTE_DRC_SOLIDO_FINCA = 'REPORTE_RECEPCIONES_DRC_SOLIDO_FILTRO_FINCA';
const REPORTE_DRC_SOLIDO_MATERIA = 'REPORTE_RECEPCIONES_DRC_SOLIDO_FILTRO_MATERIA';
const REPORTE_DRC_SOLIDO_FECHA_INICIAL = 'REPORTE_RECEPCIONES_DRC_SOLIDO_FECHA_INICIAL';
const REPORTE_DRC_SOLIDO_FECHA_FINAL = 'REPORTE_RECEPCIONES_DRC_SOLIDO_FECHA_FINAL';
// Reporte de excedente de amoniaco
const REPORTE_PAGE_EXCEDENTE_AMONIACO = 'REPORTE_PAGE_EXCEDENTE_AMONIACO';
const REPORTE_PAGE_EXCEDENTE_AMONIACO_FINCA = 'REPORTE_PAGE_EXCEDENTE_AMONIACO_FINCA';
const FILTRO_REPORTE_FECHA_INICIAL_EXCEDENTE_AMONIACO = 'FILTRO_REPORTE_FECHA_INICIAL_EXCEDENTE_AMONIACO';
const FILTRO_REPORTE_FECHA_FINAL_EXCEDENTE_AMONIACO = 'FILTRO_REPORTE_FECHA_FINAL_EXCEDENTE_AMONIACO';
const FILTRO_REPORTE_FINCA_EXCEDENTE_AMONIACO = 'FILTRO_REPORTE_FINCA_EXCEDENTE_AMONIACO';
const REPORTE_PRECIO_AMONIACO = 'REPORTE_PRECIO_AMONIACO';
const REPORTE_CONCENTRACION_AMONIACO = 'REPORTE_CONCENTRACION_AMONIACO';
// Variables para el dashboard
const FILTRO_DASHBOARD_FECHA_INICIAL = 'FILTRO_DASHBOARD_FECHA_INICIAL';
const FILTRO_DASHBOARD_FECHA_FINAL = 'FILTRO_DASHBOARD_FECHA_FINAL';
const DASHBOARD_TIPO_MATERIA = 'DASHBOARD_TIPO_MATERIA';
// LATEX
const FILTRO_DASHBOARD_FINCA_LATEX = 'FILTRO_DASHBOARD_FINCA_LATEX';
const FILTRO_DASHBOARD_MES_LATEX = 'FILTRO_DASHBOARD_MES_LATEX';
const FILTRO_DASHBOARD_ANIOS_LATEX = 'FILTRO_DASHBOARD_ANIOS_LATEX';
const FILTRO_DASHBOARD_MATERIA_PRIMA_LATEX = 'FILTRO_DASHBOARD_MATERIA_PRIMA_LATEX';
// SOLIDO
const FILTRO_DASHBOARD_FINCA_SOLIDO = 'FILTRO_DASHBOARD_FINCA_SOLIDO';
const FILTRO_DASHBOARD_MES_SOLIDO = 'FILTRO_DASHBOARD_MES_SOLIDO';
const FILTRO_DASHBOARD_ANIOS_SOLIDO = 'FILTRO_DASHBOARD_ANIOS_SOLIDO';
const FILTRO_DASHBOARD_MATERIA_PRIMA_SOLIDO = 'FILTRO_DASHBOARD_MATERIA_PRIMA_SOLIDO';

// Variables para el Dashboard de Proveedores
const DASHBOARD_PROVEEDORES_FILTRO_MES_SELECTED = 'DASHBOARD_PROVEEDORES_FILTRO_MES_SELECTED';
const DASHBOARD_PROVEEDORES_FILTRO_FECHA_INICIAL = 'DASHBOARD_PROVEEDORES_FILTRO_FECHA_INICIAL';
const DASHBOARD_PROVEEDORES_FILTRO_FECHA_FINAL = 'DASHBOARD_PROVEEDORES_FILTRO_FECHA_FINAL';
const DASHBOARD_PROVEEDORES_FILTRO_FINCA_ID = 'DASHBOARD_PROVEEDORES_FILTRO_FINCA_ID';
const DASHBOARD_PROVEEDORES_DATA = 'DASHBOARD_PROVEEDORES_DATA';

//Variables para que no se crucen la datos de recepciones finalizadas con las activas
const DATA_RECEPCIONES_ACTIVAS = 'DATA_RECEPCIONES_ACTIVAS';
const DATA_RECEPCIONES_FINALIZADAS = 'DATA_RECEPCIONES_FINALIZADAS';

// -----------------------------------
// Constants
// -----------------------------------
const DRC = 10
const KG = 20

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setPageActivas = page_activas => ({
    type: PAGE_ACTIVAS,
    page_activas,
});

const setPageFinalizadas = page_finalizadas => ({
    type: PAGE_FINALIZADAS,
    page_finalizadas,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

const setReporteRecepcionesLatex = recepciones_latex => ({
    type: REPORTE_RECEPCIONES_LATEX,
    recepciones_latex,
});
const setReporteRecepcionesSolido = recepciones_solido => ({
    type: REPORTE_RECEPCIONES_SOLIDO,
    recepciones_solido,
});

const setReporteAnualRecepcionesLatex = recepciones_latex => ({
    type: REPORTE_ANUAL_RECEPCIONES_LATEX,
    recepciones_latex
})

const setReporteAnualRecepcionesSolido = recepciones_solido => ({
    type: REPORTE_ANUAL_RECEPCIONES_SOLIDO,
    recepciones_solido
})

const graficaReporte = grafica_reporte => ({
    type: REPORTE_ANUAL_GRAFICA,
    grafica_reporte
})

const setDataRecepcionesActivas = recepciones_activas => ({
    type: DATA_RECEPCIONES_ACTIVAS,
    recepciones_activas,
});
const setDataRecepcionesFinalizadas = recepciones_finalizadas => ({
    type: DATA_RECEPCIONES_FINALIZADAS,
    recepciones_finalizadas,
});
// ------------------------------------
// Actions
// ------------------------------------
const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().recepciones;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.filtrar_por_acopio = resource.filtrar_por_acopio;
    const tab = resource.tab;
    params.filtro = 'activas';
    if (tab === 'FINALIZADAS') {
        params.filtro = 'finalizadas';
        if (resource.fecha_inicial_finalizadas && resource.fecha_final_finalizadas) {
            params.fecha_inicial = moment(resource.fecha_inicial_finalizadas).format("YYYY-MM-DD") + ' 00:00:00';
            params.fecha_final = moment(resource.fecha_final_finalizadas).format("YYYY-MM-DD") + ' 23:59:59';
        }
    }
    dispatch(setLoader(true));
    api.get('recepcion', params).then((response) => {
        switch (tab) {
            case 'ACTIVAS':
                dispatch(setDataRecepcionesActivas(response));
                break;
            case 'FINALIZADAS':
                dispatch(setDataRecepcionesFinalizadas(response));
                break;
        }
        dispatch(initializeForm('ProductosForm', {}));
        dispatch(initializeForm('DatosForm', {}));
        dispatch(setPage(page))
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = (id) => (dispatch, getStore) => {
    const store = getStore().recepciones;
    const { tab, filtrar_por_acopio } = store;
    let params = {
        filtro: tab === 'FINALIZADAS' ? 'finalizadas' : 'activas',
        filtrar_por_acopio,
    };
    dispatch(setLoader(true));
    api.get(`recepcion/${id}`, params).then((response) => {
        dispatch(setItem(response));
        console.log(response)
        const productos = response.productos;
        productos.forEach((producto, index) => {
            producto.index = index;
        });
        dispatch(initializeForm('ProductosForm', {}));
        dispatch(initializeForm('DatosForm', {}));
        dispatch(initializeForm('ProductosForm', { productos }));
        dispatch(initializeForm('DatosForm', response));
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false));
    });
};

const fechaIngresoChange = fecha_ingreso => (dispatch) => {
    dispatch({ type: FECHA_INGRESO, fecha_ingreso })
};

const crear = data => (dispatch) => {
    const _data = _.cloneDeep(data);
    _data.vehiculo = data.vehiculo.id;
    _data.finca = data.finca.id;
    _data.piloto = data.piloto.id;
    _data.tipo_materia = data.tipo_materia.id;

    dispatch(setLoader(true));
    api.post('recepcion', _data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(push('/ingresos'));
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la creación', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crearYRecuperar = data => async(dispatch, getStore) => {
    const resource = getStore().recepciones;
    const _data = _.cloneDeep(data);
    _data.vehiculo = data.vehiculo.id;
    _data.finca = data.finca.id;
    _data.piloto = data.piloto.id;
    _data.tipo_materia = data.tipo_materia.id;
    _data.fecha = `${moment(data.fecha || moment()).format('YYYY-MM-DD')} ${data.hora_ingreso || moment().format('HH:mm:ss')}`;

    _data.fecha_analisis = moment(data.fecha).format("YYYY-MM-DD");
    _data.hora_analisis = data.hora_ingreso ? data.hora_ingreso : moment().format('HH:mm');

    dispatch(setItem({})); // Limpieza del registro anterior
    dispatch(setLoader(true));
    try {
        const registroIngreso = await api.post('recepcion', _data);
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(setItem(registroIngreso));
        dispatch(setTab('ACTIVAS')); // Para recuperar el registro tras la redirección a la vista de la boleta de Ingreso
        dispatch({ type: FECHA_INGRESO, fecha_ingreso: moment() })
    } catch (error) {
        NotificationManager.error((error) ? error.body.detail : 'Error en la creación', 'ERROR', 3000);
    } finally {
        dispatch(setLoader(false));
    }
};

const editar = (id, data) => (dispatch, getStore) => {
    const store = getStore().recepciones;
    const { filtrar_por_acopio } = store;
    let params = { filtrar_por_acopio };
    dispatch(setLoader(true));
    api.put(`recepcion/${id}`, data, params).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/ingresos'));
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch, getStore) => {
    const store = getStore().recepciones;
    const { filtrar_por_acopio } = store;
    let params = { filtrar_por_acopio };
    dispatch(setLoader(true));
    api.eliminar(`recepcion/${id}`, params).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la transacción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

// Guardar la boleta de ingreso o salida
const guardarBoletaPDF = (id, data, tipo) => (dispatch) => {
    const _data = {};
    _data.boleta_ingreso = data;
    _data.tipo = tipo;
    dispatch(setLoader(true));
    // TODO: Cambiar nombre de endpoint cuando se corrija el error con django-celery
    api.put(`${'recepcion'}/${id}/guardar_pdf_ingreso_temp`, _data).then((response) => {
        const { envio_por_correo, correos_enviados, boleta_guardada } = response;
        const msgEnvioCorreo = envio_por_correo && correos_enviados ? ` y enviada a ${correos_enviados} correo${correos_enviados > 1 ? 's' : ''}` : '';
        !!boleta_guardada && NotificationManager.success(`Boleta de recepción #${id} guardada${msgEnvioCorreo} con éxito.`, 'Éxito', 5000);
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error al intentar guardar la boleta de recepción', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

/**
 * Actualiza la Recepción para indicar que ya se imprimió su Boleta de salida
 * @param id ID de la recepción.
 */
const setBoletaSalidaImpresa = (id) => (dispatch) => {
    api.put(`${'recepcion'}/${id}/set_boleta_salida_impresa`, {})
        .then(() => {})
        .catch(() => {})
        .finally(() => {});
};

const pageActivasChange = page_activas => (dispatch) => {
    dispatch(setPageActivas(page_activas));
    dispatch(listar(page_activas));
}

const pageFinalizadasChange = page_finalizadas => (dispatch) => {
    dispatch(setPageFinalizadas(page_finalizadas));
    dispatch(listar(page_finalizadas));
}

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().recepciones.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

const getProductosLatex = id => (dispatch) => {
    dispatch(setLoader(true));
    api.get('materia_prima', { search: "Latex", no_paginar: true }).then((data) => {
        const productos = data.results;
        if (productos.length > 0) {
            //Mutar cada producto pra que tenga value y label
            productos.forEach((producto) => {
                producto['value'] = producto.id;
                producto['label'] = producto.nombre
            })
        }
        dispatch({ type: PRODUCTOS_LATEX, productos_latex: productos })
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false));
    });
};

const getProductosSolido = () => (dispatch) => {
    dispatch(setLoader(true));
    api.get('materia_prima', { search: "Sólido", no_paginar: true }).then((data) => {
        const productos = data.results;
        if (productos.length > 0) {
            //Mutar cada producto pra que tenga value y label
            productos.forEach((producto) => {
                producto['value'] = producto.id;
                producto['label'] = producto.nombre
            })
        }
        dispatch({ type: PRODUCTOS_SOLIDO, productos_solido: productos })
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false));
    });
};
const initialLoad = () => (dispatch) => {
    dispatch(getProductosLatex());
    dispatch(getProductosSolido());
};

const clearProductos = () => (dispatch, getStore) => {
    const form = getStore().form.IngresoForm;
    const valores = _.cloneDeep(form.values);
    valores.productos = [{ peso_finca_kg: 0, destino: 'Indefinido', destino_id: undefined }];
    dispatch(initializeForm('IngresoForm', valores))
};


const establecerKilogramosIngreso = (index) => async(dispatch, getStore) => {
    const form = getStore().form.IngresoForm;
    const valores = _.cloneDeep(form.values);

    if (valores.productos[index]['materia_prima'] && valores.productos[index]['peso_finca'] && valores.productos[index]['peso_finca_unidad']) {
        valores.productos[index]['peso_finca_kg'] = valores.productos[index]['peso_finca'] * valores.productos[index]['peso_finca_unidad'];
        let params = {
            materia_id: valores.productos[index]['materia_prima'],
            cantidad: valores.productos[index]['peso_finca_kg']
        };

        // try {

        // const almacen = await api.get(`recepcion/get_almacen`, params);
        // valores.productos[index]['destino_id'] = almacen.id;
        // valores.productos[index]['destino'] = almacen.nombre;
        // }   catch (e) {
        // valores.productos[index]['destino_id'] = null;
        // valores.productos[index]['destino'] = e.body.detail;

        // }

        dispatch(initializeForm('IngresoForm', valores));

    }
};
const establecerKilogramosPesajeIngreso = (index, nameForm = 'ProductosForm') => async(dispatch, getStore) => {
    const form = getStore().form[nameForm];
    const valores = _.cloneDeep(form.values);
    if (valores.productos[index]['materia_prima'] && !isNaN(valores.productos[index]['peso_finca']) && valores.productos[index]['peso_finca_unidad']) {
        valores.productos[index]['peso_finca_kg'] = valores.productos[index]['peso_finca'] * valores.productos[index]['peso_finca_unidad'];
        dispatch(initializeForm(nameForm, valores));
    }
};

const actualizarPesoTara = (peso_bascula = 0) => (dispatch, getStore) => {
    console.log("PESO BASCULA", peso_bascula);
    const form = getStore().form.PesajeForm;
    const valores = _.cloneDeep(form.values);
    let vaciado = 0;
    if ((valores && valores.peso_bascula && valores.peso_bascula > 0) || (peso_bascula && peso_bascula > 0)) {
        if (valores.peso_bascula > 0) {
            valores.peso_tara = valores.peso_bascula;
            valores.peso_neto = valores.peso_bruto_temp - valores.peso_bascula;
            vaciado = valores.peso_bruto_temp - valores.peso_bascula;
        }
        if (peso_bascula > 0) {
            valores.peso_tara = peso_bascula;
            valores.peso_neto = valores.peso_bruto_temp - peso_bascula;
            vaciado = valores.peso_bruto_temp - peso_bascula;
        }
        console.log("PESO NETO", valores.peso_neto);

        const producto = getStore().recepciones.producto;
        let params = {
            materia_id: producto.materia_prima,
            cantidad: vaciado
        };
        // valores && valores.vaciados && valores.vaciados.length === 1 && (valores.vaciados[0].peso = vaciado);
        valores.diferencia = Math.abs(valores.peso_neto - valores.peso_finca_kg);
        dispatch(initializeForm('PesajeForm', valores))
    } else {
        NotificationManager.error('Debe ingresar el peso de bascula', 'Error', 0);
    }
};

const getProducto = id => (dispatch) => {
    dispatch({ type: LOADER, loader: true });
    api.get(`recepcion/${id}/get_producto`).then(response => {
        if (response) {
            dispatch({ type: PRODUCTO_EDITAR, producto: response });
            //10 Latex - 20 Solido
            if (response.tipo_materia === 10)
                dispatch(get_pilas());
            if (response.tipo_materia === 20)
                dispatch(get_almacenes())
        }
        dispatch(initializeForm('PesajeForm', {...response, vaciados: [] }))
    }).catch(() => {}).finally(
        dispatch({ type: LOADER, loader: false })
    )
};
const get_pilas = () => (dispatch) => {
    api.get('bodega/get_pilas').then(response => {
        const pilas = response.results;
        pilas.forEach((pila, index) => {
            {
                pila.value = pila.id;
                pila.label = pila.nombre;
                pila.index = index
            }
        });
        dispatch({ type: PILAS_VACIADO, pilas })
    })
};
const get_almacenes = () => (dispatch) => {
    api.get('bodega/get_almacenes').then(response => {
        const almacenes = response.results;
        almacenes.forEach(almacen => {
            almacen.value = almacen.id;
            almacen.label = almacen.nombre
        });
        dispatch({ type: ALMACENES_VACIADO, almacenes })
    })
};

const pesosBascula = () => (dispatch) => {
    api.getPesosBascula().then((response) => {
        dispatch({ type: PESOS_BASCULA, peso_bascula: response.text })
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false));
    });
};

const setAlturaInicialVaciadoLatex = (index, formName = 'PesajeForm') => (dispatch, getStore) => {
    // Asigna como altura inicial del vaciado la altura utilizada de la bodega
    const values = _.get(getStore(), `form.${formName}.values`, []);
    values.vaciados[index].altura_inicial = _.get(values, `vaciados[${index}].almacen.altura_utilizada`, 0)
    dispatch(initializeForm(formName, values));
};

const actualizarProductos = (data = null) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    let data_update = data ? data : {};
    data_update['datos_ingreso'] = getStore().form.DatosForm.values;

    api.post('recepcion/actualizar_productos', data_update).then(res => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/ingresos'));
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false))
    })
};
const realizarVaciado = (data) => async(dispatch) => {
    // Se verifica que el total a vaciar sea mayor a cero
    let total_vaciados = 0;
    data.vaciados && data.vaciados.forEach(vaciado => {
        total_vaciados += vaciado.peso;
    });
    if (total_vaciados <= 0) {
        NotificationManager.error('El total a vaciar debe ser mayor a cero', 'ERROR', 3000);
    } else {
        clearInterval(window.interval)
        dispatch(setLoader(true));
        try {
            // Se realiza el vaciado del producto.
            // Si el producto es marcado como 'FINALIZADO', entonces se puede imprimir su boleta de salida de recepción.
            const ingreso = await api.post('bodega/vaciar', data);
            NotificationManager.success('Vaciado de productos exitoso', 'Éxito', 3000);
            if (ingreso.estado_ingreso === INGRESO_FINALIZADO) {
                // Se redirecciona a la vista preliminar para imprimir la Boleta de Salida de recepción
                dispatch(setTab('FINALIZADAS'));
                dispatch(setItem(ingreso));
                dispatch(push(`/ingreso/${ingreso.id}/boleta_salida`));
            } else {
                dispatch(push('/ingresos'));
            }
        } catch (error) {
            NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 0);
        } finally {
            dispatch(setLoader(false));
        }
    }
};

const mandarAlCanal = (data) => async(dispatch) => {
    clearInterval(window.interval)
    dispatch(setLoader(true));
    api.post('canal/enviar_recepcion_a_canal', data).then(res => {
        NotificationManager.success('Registro enviado a canal con exito', 'Éxito', 0);
        if (res.estado_ingreso === A_CANAL_COAGULACION){
            dispatch(setItem(res));
            dispatch(push(`/ingreso/${res.id}/boleta_salida`));
        }
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error al guardar', 'ERROR', 0);
    }
    ).finally(() => {
        dispatch(setLoader(false))
    }
    )
}

const setTab = (tab) => (dispatch) => {
    dispatch(setLoader(true));
    dispatch({ type: TAB_RECEPCIONES, tab });
    if (tab === "ACTIVAS") {
        dispatch(listar(1, 'activas'))
    } else {
        dispatch(listar(1, 'finalizadas'))
    }
};
const setFiltrarRecepcionesPorAcopio = (filtrar_por_acopio) => (dispatch) => dispatch({ type: FILTRAR_RECEPCIONES_POR_ACOPIO, filtrar_por_acopio });

/**
 * ----- ANULAR INGRESO -----
 * Con este método se envía a anular el ingreso / recepción siempre y cuando aún no tenga ninguno de sus pesos
 * registrados (movimientos)
 * @param id, es el id del ingreso
 * @param data, es la data donde irá la justificación de la anulación
 * @returns Status de la petición 200 si anulación con éxito, 400 si fallo
 */
const anularIngreso = (id, data = null) => (dispatch) => {
    api.put(`recepcion/${id}/anular_ingreso`, data).then(() => {
        dispatch(push('/ingresos'));
        NotificationManager.success(`La recepción No. ${id} fue anulada.`, 'Éxito', 3000);
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
    })
};
const fechaInicialFinalizadasChange = fecha_inicial_finalizadas => (dispatch) => {
    dispatch({ type: FINALIZADAS_FECHA_INICIAL, fecha_inicial_finalizadas })
    dispatch(listar())
}
const fechaFinalFinalizadasChange = fecha_final_finalizadas => (dispatch) => {
    dispatch({ type: FINALIZADAS_FECHA_FINAL, fecha_final_finalizadas })
    dispatch(listar())
}

// Actions para Reporte de Recepciones
const tipoMateriaChange = (tipo_materia) => (dispatch) => {
    dispatch({ type: REPORTE_TIPO_MATERIA, tipo_materia })
    //dispatch(getRecepcionesParaReporte())
}
const pageReporteLatexChange = (latex_page) => (dispatch) => {
    dispatch({ type: REPORTE_LATEX_PAGE, latex_page })
    dispatch(getRecepcionesParaReporte())
}
const fechaInicialLatexChange = (latex_fecha_inicial, latex_page = 1) => (dispatch) => {
    dispatch({ type: REPORTE_LATEX_FECHA_INICIAL, latex_fecha_inicial })
    dispatch({ type: REPORTE_LATEX_PAGE, latex_page })
    //dispatch(getRecepcionesParaReporte())
}
const fechaFinalLatexChange = (latex_fecha_final, latex_page = 1) => (dispatch) => {
    dispatch({ type: REPORTE_LATEX_FECHA_FINAL, latex_fecha_final })
    dispatch({ type: REPORTE_LATEX_PAGE, latex_page })
    //dispatch(getRecepcionesParaReporte())
}
const fincaLatexChange = (latex_finca_id, latex_page = 1) => (dispatch) => {
    dispatch({ type: REPORTE_LATEX_FINCA, latex_finca_id })
    dispatch({ type: REPORTE_LATEX_PAGE, latex_page })
    //dispatch(getRecepcionesParaReporte())
}
const materiaLatexChange = (latex_materia_id, latex_page=1) => (dispatch) => {
    dispatch({type: REPORTE_LATEX_MATERIA, latex_materia_id})
    dispatch({type: REPORTE_LATEX_PAGE, latex_page})
    //dispatch(getRecepcionesParaReporte())
}
const acopioLatexChange = (latex_acopio_id, latex_page=1)=> (dispatch) => {
    dispatch({type: REPORTE_LATEX_ACOPIO, latex_acopio_id})
    dispatch({type: REPORTE_LATEX_PAGE, latex_page})
    //dispatch(getRecepcionesParaReporte())
}
const pageReporteSolidoChange = (solido_page) => (dispatch) => {
    dispatch({ type: REPORTE_SOLIDO_PAGE, solido_page })
    dispatch(getRecepcionesParaReporte())
}
const fechaInicialSolidoChange = (solido_fecha_inicial, solido_page = 1) => (dispatch) => {
    dispatch({ type: REPORTE_SOLIDO_FECHA_INICIAL, solido_fecha_inicial })
    dispatch({ type: REPORTE_SOLIDO_PAGE, solido_page })
    //dispatch(getRecepcionesParaReporte())
}
const fechaFinalSolidoChange = (solido_fecha_final, solido_page = 1) => (dispatch) => {
    dispatch({ type: REPORTE_SOLIDO_FECHA_FINAL, solido_fecha_final })
    dispatch({ type: REPORTE_SOLIDO_PAGE, solido_page })
    //dispatch(getRecepcionesParaReporte())
}
const fincaSolidoChange = (solido_finca_id, solido_page = 1) => (dispatch) => {
    dispatch({ type: REPORTE_SOLIDO_FINCA, solido_finca_id })
    dispatch({ type: REPORTE_SOLIDO_PAGE, solido_page })
    //dispatch(getRecepcionesParaReporte())
}
const materiaSolidoChange = (solido_materia_id, solido_page=1) => (dispatch) => {
    dispatch({type: REPORTE_SOLIDO_MATERIA, solido_materia_id})
    dispatch({type: REPORTE_SOLIDO_PAGE, solido_page})
    //dispatch(getRecepcionesParaReporte())
}
const acopioSolidoChange = (solido_acopio_id, solido_page=1)=> (dispatch) => {
    dispatch({type: REPORTE_SOLIDO_ACOPIO, solido_acopio_id})
    dispatch({type: REPORTE_SOLIDO_PAGE, solido_page})
    //dispatch(getRecepcionesParaReporte())
}

// Actions para Reporte anual de Recepciones
const tipoMateriaAnualChange = (tipo_materia) => (dispatch) => {
    dispatch({ type: REPORTE_ANUAL_TIPO_MATERIA, tipo_materia })
    // dispatch(getRecepcionesParaReporteAnual())
}
const pageReporteAnualLatexChange = (latex_page) => (dispatch) => {
    dispatch({ type: REPORTE_ANUAL_LATEX_PAGE, latex_page })
    // dispatch(getRecepcionesParaReporteAnual())
}

const aniosLatexChange = (anios_latex, latex_page = 1) => dispatch => {
    dispatch({ type: REPORTE_ANUAL_LATEX_ANIOS, anios_latex })
    dispatch({ type: REPORTE_ANUAL_LATEX_PAGE, latex_page })
    // dispatch(getRecepcionesParaReporteAnual())
}

const materiaAnualLatexChange = (latex_materia_id, latex_page=1) => (dispatch) => {
    dispatch({ type: REPORTE_ANUAL_LATEX_MATERIA, latex_materia_id })
    dispatch({ type: REPORTE_ANUAL_LATEX_PAGE, latex_page })
    // dispatch(getRecepcionesParaReporteAnual())
}
const pageReporteAnualSolidoChange = (solido_page) => (dispatch) => {
    dispatch({ type: REPORTE_ANUAL_SOLIDO_PAGE, solido_page })
    // dispatch(getRecepcionesParaReporteAnual())
}

const aniosSolidoChange = (anios_solido, solido_page = 1) => dispatch => {
    dispatch({ type: REPORTE_ANUAL_SOLIDO_ANIOS, anios_solido })
    dispatch({ type: REPORTE_ANUAL_SOLIDO_PAGE, solido_page })
    // dispatch(getRecepcionesParaReporteAnual())
}

const materiaAnualSolidoChange = (solido_materia_id, solido_page=1) => (dispatch) => {
    dispatch({ type: REPORTE_ANUAL_SOLIDO_MATERIA, solido_materia_id })
    dispatch({ type: REPORTE_ANUAL_SOLIDO_PAGE, solido_page })
    // dispatch(getRecepcionesParaReporteAnual())
}
/**
 * Obtiene el listado de Movimientos, según los parámetros de búsqueda (mantenidos en el Store).
 * Cada Movimiento contiene dentro de sí el Ingreso al que está asociado.
 * Realiza 2 peticiones hacia la API para obtener la data total y la data paginada.
 */
const getRecepcionesParaReporte = (finca_reset=false) => async(dispatch, getStore) => {
    // Construcción de los parámetros de búsqueda. Las fechas son requeridas
    const resource = getStore().recepciones;
    const user = getStore().login;

    const proveedor = user.proveedor;
    const reporte = resource.reporte;
    const usuario = user.me;
    const params = {};
    params.tipo_materia = reporte.tipo_materia == 'LATEX' ? LATEX : SOLIDO;
    if (usuario.proveedor) {
        params.proveedor = true;
        params.id_usuario = usuario.id;
    }

    if (proveedor) {
        params.proveedor_id = proveedor;
    }
    switch (reporte.tipo_materia) {
        case 'LATEX':
            params.page = reporte.latex_page;
            reporte.latex_fecha_inicial !== null && (params.fecha_inicio = moment(reporte.latex_fecha_inicial).format("YYYY-MM-DD") + ' 00:00:00');
            reporte.latex_fecha_final !== null   && (params.fecha_fin = moment(reporte.latex_fecha_final).format("YYYY-MM-DD") + ' 23:59:59');
            !finca_reset && reporte.latex_finca_id !== -1 && (params.finca_id = reporte.latex_finca_id);
            reporte.latex_acopio_id !== -1       && (params.acopio_id = reporte.latex_acopio_id);
            reporte.latex_materia_id !== -1      && (params.materia_id = [reporte.latex_materia_id]);
            break;
        case 'SOLIDO':
            params.page = reporte.solido_page;
            reporte.solido_fecha_inicial !== null && (params.fecha_inicio = moment(reporte.solido_fecha_inicial).format("YYYY-MM-DD") + ' 00:00:00');
            reporte.solido_fecha_final !== null   && (params.fecha_fin = moment(reporte.solido_fecha_final).format("YYYY-MM-DD") + ' 23:59:59');
            !finca_reset && reporte.solido_finca_id !== -1 && (params.finca_id = reporte.solido_finca_id);
            reporte.solido_acopio_id !== -1       && (params.acopio_id = reporte.solido_acopio_id);
            reporte.solido_materia_id !== -1      && (params.materia_id = [reporte.solido_materia_id]);
            break;
    }
    switch(reporte.tipo_materia) {
        case 'LATEX':
            if (params.fecha_inicio !== null && params.fecha_fin !== null) {
                // Inicio de la petición al servidor
                dispatch(setLoader(true));
                try {
                    const recepcionesAll = await api.get('recepcion/get_movimientos_reporte/', params);
                    params.paginar_data = true;
                    const recepciones = await api.get('recepcion/get_movimientos_reporte/', params);
                    recepciones.all_results = recepcionesAll.results;
                    recepciones.all_count = recepcionesAll.count;
                    dispatch(setReporteRecepcionesLatex(recepciones));
                } catch(error) {
                } finally {
                    dispatch(setLoader(false));
                }
            }
            break;
        case 'SOLIDO':
            if (params.fecha_inicio !== null && params.fecha_fin !== null) {
                // Inicio de la petición al servidor
                dispatch(setLoader(true));
                try {
                    const recepcionesAll = await api.get('recepcion/get_movimientos_reporte/', params);
                    params.paginar_data = true;
                    const recepciones = await api.get('recepcion/get_movimientos_reporte/', params);
                    recepciones.all_results = recepcionesAll.results;
                    recepciones.all_count = recepcionesAll.count;
                    dispatch(setReporteRecepcionesSolido(recepciones));
                } catch(error) {
                } finally {
                    dispatch(setLoader(false));
                }
            }
    }
};

const getRecepcionesParaReporteAnual = () => async(dispatch, getStore) => {
    // Construcción de los parámetros de búsqueda. Las fechas son requeridas
    const resource = getStore().recepciones;
    const user = getStore().login;
    const reporte = resource.reporte_anual;
    const usuario = user.me;
    const params = {};
    params.tipo_materia = reporte.tipo_materia == 'LATEX' ? LATEX : SOLIDO;
    if (usuario.proveedor) {
        params.proveedor = true;
        params.id_usuario = usuario.id;
    }
    switch (reporte.tipo_materia) {
        case 'LATEX':
            params.page = reporte.latex_page;
            if (reporte.anios_latex.length > 0) {
                reporte.anios_latex.forEach((anio, index) => {
                    params[`anio_${index + 1}`] = anio.value
                })
            }
            // reporte.anios_latex !== null         && (params.anios_latex = reporte.anios_latex)
            reporte.latex_materia_id !== -1      && (params.materia_id = [reporte.latex_materia_id]);
            break;
        case 'SOLIDO':
            params.page = reporte.solido_page;
            if (reporte.anios_solido.length > 0) {
                reporte.anios_solido.forEach((anio, index) => {
                    params[`anio_${index + 1}`] = anio.value
                })
            }
            reporte.solido_materia_id !== -1      && (params.materia_id = [reporte.solido_materia_id]);
            break;
    }
    switch(reporte.tipo_materia) {
        case 'LATEX':
            // Inicio de la petición al servidor
            if (params.anio_1 !== null) {
                dispatch(setLoader(true));
                try {
                    const recepcionesAll = await api.get('recepcion/get_movimientos_reporte_anual/', params);
                    // const recepciones = recepcionesAll
                    params.paginar_data = true;
                    const recepciones = await api.get('recepcion/get_movimientos_reporte_anual/', params);
                    recepciones.all_results = recepcionesAll.results;
                    recepciones.all_count = recepcionesAll.count;
                    dispatch(setReporteAnualRecepcionesLatex(recepciones))
                } catch(error) {
                } finally {
                    dispatch(setLoader(false));
                }
                break;
            }
        case 'SOLIDO':
            // Inicio de la petición al servidor
            if (params.anio_1 !== null) {
                dispatch(setLoader(true));
                try {
                    const recepcionesAll = await api.get('recepcion/get_movimientos_reporte_anual/', params);
                    params.paginar_data = true;
                    const recepciones = await api.get('recepcion/get_movimientos_reporte_anual/', params);
                    recepciones.all_results = recepcionesAll.results;
                    recepciones.all_count = recepcionesAll.count;
                    dispatch(setReporteAnualRecepcionesSolido(recepciones))
                } catch(error) {
                } finally {
                    dispatch(setLoader(false));
                }
            }
    }
}

// FUNCIONES PARA EL REPORTE DE DRC DE RECEPCIONES
const drcTipoMateriaChange = tipo_materia => (dispatch) => {
    dispatch({ type: REPORTE_DRC_TIPO_MATERIA, tipo_materia })
    //dispatch(getReporteDRCRecepciones())
}
const drcLatexPageChange = latex_page => (dispatch) => {
    dispatch({ type: REPORTE_DRC_LATEX_PAGE, latex_page })
    //dispatch(getReporteDRCRecepciones())
}
const drcLatexFechaInicialChange = (latex_fecha_inicial, latex_page = 1) => (dispatch) => {
    dispatch({ type: REPORTE_DRC_LATEX_FECHA_INICIAL, latex_fecha_inicial })
    dispatch({ type: REPORTE_DRC_LATEX_PAGE, latex_page })
    //dispatch(getReporteDRCRecepciones())
}
const drcLatexFechaFinalChange = (latex_fecha_final, latex_page = 1) => (dispatch) => {
    dispatch({ type: REPORTE_DRC_LATEX_FECHA_FINAL, latex_fecha_final })
    dispatch({ type: REPORTE_DRC_LATEX_PAGE, latex_page })
    //dispatch(getReporteDRCRecepciones())
}
const drcLatexFincaChange = (latex_finca_id, latex_page = 1) => (dispatch) => {
    dispatch({ type: REPORTE_DRC_LATEX_FINCA, latex_finca_id })
    dispatch({ type: REPORTE_DRC_LATEX_PAGE, latex_page })
    //dispatch(getReporteDRCRecepciones())
}
const drcLatexMateriaChange = (latex_materia_id, latex_page = 1) => (dispatch) => {
    dispatch({ type: REPORTE_DRC_LATEX_MATERIA, latex_materia_id })
    dispatch({ type: REPORTE_DRC_LATEX_PAGE, latex_page })
    //dispatch(getReporteDRCRecepciones())
}
const drcSolidoPageChange = solido_page => (dispatch) => {
    dispatch({ type: REPORTE_DRC_SOLIDO_PAGE, solido_page })
    //dispatch(getReporteDRCRecepciones())
}
const drcSolidoFechaInicialChange = (solido_fecha_inicial, solido_page = 1) => (dispatch) => {
    dispatch({ type: REPORTE_DRC_SOLIDO_FECHA_INICIAL, solido_fecha_inicial })
    dispatch({ type: REPORTE_DRC_SOLIDO_PAGE, solido_page })
    //dispatch(getReporteDRCRecepciones())
}
const drcSolidoFechaFinalChange = (solido_fecha_final, solido_page = 1) => (dispatch) => {
    dispatch({ type: REPORTE_DRC_SOLIDO_FECHA_FINAL, solido_fecha_final })
    dispatch({ type: REPORTE_DRC_SOLIDO_PAGE, solido_page })
    //dispatch(getReporteDRCRecepciones())
}
const drcSolidoFincaChange = (solido_finca_id, solido_page = 1) => (dispatch) => {
    dispatch({ type: REPORTE_DRC_SOLIDO_FINCA, solido_finca_id })
    dispatch({ type: REPORTE_DRC_SOLIDO_PAGE, solido_page })
    //dispatch(getReporteDRCRecepciones())
}
const drcSolidoMateriaChange = (solido_materia_id, solido_page = 1) => (dispatch) => {
    dispatch({ type: REPORTE_DRC_SOLIDO_MATERIA, solido_materia_id })
    dispatch({ type: REPORTE_DRC_SOLIDO_PAGE, solido_page })
    //dispatch(getReporteDRCRecepciones())
}
const getReporteDRCRecepciones = () => async(dispatch, getStore) => {
    // Obtiene el listado de Movimientos, según los parámetros de búsqueda (mantenidos en el Store).
    // Cada Movimiento contiene dentro de sí el Ingreso al que está asociado.
    // Realiza 2 peticiones hacia la API para obtener la data total y la data paginada.
    //
    // Construcción de los parámetros de búsqueda. Las fechas son requeridas
    const resource = getStore().recepciones;
    const reporte = resource.reporte_drc_ingreso;
    const params = {};
    params.tipo_materia = reporte.tipo_materia == 'LATEX' ? LATEX : SOLIDO;
    switch (reporte.tipo_materia) {
        case 'LATEX':
            params.page = reporte.latex_page;
            reporte.latex_fecha_inicial !== null && (params.fecha_inicial = moment(reporte.latex_fecha_inicial).format("YYYY-MM-DD") + ' 00:00:00');
            reporte.latex_fecha_final !== null   && (params.fecha_final = moment(reporte.latex_fecha_final).format("YYYY-MM-DD") + ' 23:59:59');
            reporte.latex_finca_id !== -1        && (params.finca_id = reporte.latex_finca_id);
            reporte.latex_materia_id !== -1      && (params.materia_id = [reporte.latex_materia_id]);
            break;
        case 'SOLIDO':
            params.page = reporte.solido_page;
            reporte.solido_fecha_inicial !== null && (params.fecha_inicial = moment(reporte.solido_fecha_inicial).format("YYYY-MM-DD") + ' 00:00:00');
            reporte.solido_fecha_final !== null   && (params.fecha_final = moment(reporte.solido_fecha_final).format("YYYY-MM-DD") + ' 23:59:59');
            reporte.solido_finca_id !== -1        && (params.finca_id = reporte.solido_finca_id);
            reporte.solido_materia_id !== -1      && (params.materia_id = [reporte.solido_materia_id]);
            break;
    }
    if (params.fecha_inicial !== undefined && params.fecha_final !== undefined) {
        // Inicio de la petición al servidor
        dispatch(setLoader(true));
        try {
            const recepcionesAll = await api.get('recepcion/get_movimientos_reporte_drc/', params);
            params.paginar_data = true;
            const recepciones = await api.get('recepcion/get_movimientos_reporte_drc/', params);
            recepciones.all_results = recepcionesAll.results;
            recepciones.all_count = recepcionesAll.count;
            dispatch({ type: REPORTE_DRC_RECEPCIONES, recepciones });
        } catch (error) {} finally {
            dispatch(setLoader(false));
        }
    }
}

// REPORTE DE EXCEDENTE DE AMONIACO
const listarDataReporteExcedenteAmoniaco = (page = 1) => async(dispatch, getStore) => {
    const resource = getStore().recepciones;
    const params = {};
    const params_finca = {};
    params.page = resource.page_reporte_excendente_amoniaco;
    params.fecha_inicial = moment(resource.fecha_inicio_reporte_excendente_amoniaco).format("YYYY-MM-DD");
    params.fecha_fin = moment(resource.fecha_fin_reporte_excendente_amoniaco).format("YYYY-MM-DD") + ' 23:59:59';
    if (resource.finca_reporte_excendente_amoniaco.length > 0) {
        params.id_finca = [resource.finca_reporte_excendente_amoniaco];
    }

    params_finca.page = resource.page_reporte_excendente_amoniaco_finca;
    params_finca.fecha_inicial = moment(resource.fecha_inicio_reporte_excendente_amoniaco).format("YYYY-MM-DD");
    params_finca.fecha_fin = moment(resource.fecha_fin_reporte_excendente_amoniaco).format("YYYY-MM-DD") + ' 23:59:59';
    params_finca.precio_amoniaco = resource.reporte_precio_amoniaco ? resource.reporte_precio_amoniaco : 0;
    params_finca.concentracion_amoniaco = resource.reporte_concentracion_amoniaco ? resource.reporte_concentracion_amoniaco : 0;
    if (resource.finca_reporte_excendente_amoniaco.length > 0) {
        params_finca.id_finca = [resource.finca_reporte_excendente_amoniaco];
    }


    if (params.fecha_inicial && params.fecha_fin) {
        dispatch(setLoader(true));
        try {
            const recepciones_all = await api.get('recepcion/get_data_reporte_excedente_amoniaco', params);
            params.paginar_data = true;
            const recepciones = await api.get('recepcion/get_data_reporte_excedente_amoniaco', params);
            recepciones.all_results = recepciones_all.results;
            recepciones.all_count = recepciones_all.count;
            const fincas = await api.get('recepcion/get_fincas_reporte_amoniaco', params_finca);
            const fincas_grafica = await api.get('recepcion/get_todas_fincas_reporte_amoniaco', params_finca);
            recepciones.fincas = fincas;
            recepciones.fincas_grafica = fincas_grafica;
            dispatch(setData(recepciones));
        } catch (error) {} finally {
            dispatch(setLoader(false));
        }
    }

};
const pageReporteChangeExcedenteAmoniaco = (page_reporte_excendente_amoniaco) => (dispatch) => {
    dispatch({ type: REPORTE_PAGE_EXCEDENTE_AMONIACO, page_reporte_excendente_amoniaco });
    dispatch(listarDataReporteExcedenteAmoniaco());
}

const pageReporteChangeExcedenteAmoniacoFinca = (page_reporte_excendente_amoniaco_finca) => (dispatch) => {
    dispatch({ type: REPORTE_PAGE_EXCEDENTE_AMONIACO_FINCA, page_reporte_excendente_amoniaco_finca });
    dispatch(listarDataReporteExcedenteAmoniaco());
}

const filtroFechaInicioExcedenteAmoniaco = (fecha_inicio_reporte_excendente_amoniaco, page_reporte_excendente_amoniaco = 1, page_reporte_excendente_amoniaco_finca = 1) => (dispatch) => {
    dispatch({ type: FILTRO_REPORTE_FECHA_INICIAL_EXCEDENTE_AMONIACO, fecha_inicio_reporte_excendente_amoniaco });
    dispatch({ type: REPORTE_PAGE_EXCEDENTE_AMONIACO_FINCA, page_reporte_excendente_amoniaco_finca });
    dispatch({ type: REPORTE_PAGE_EXCEDENTE_AMONIACO, page_reporte_excendente_amoniaco });
    //dispatch(listarDataReporteExcedenteAmoniaco());
};

const filtroFechaFinExcedenteAmoniaco = (fecha_fin_reporte_excendente_amoniaco, page_reporte_excendente_amoniaco = 1, page_reporte_excendente_amoniaco_finca = 1) => (dispatch) => {
    dispatch({ type: FILTRO_REPORTE_FECHA_FINAL_EXCEDENTE_AMONIACO, fecha_fin_reporte_excendente_amoniaco });
    dispatch({ type: REPORTE_PAGE_EXCEDENTE_AMONIACO_FINCA, page_reporte_excendente_amoniaco_finca });
    dispatch({ type: REPORTE_PAGE_EXCEDENTE_AMONIACO, page_reporte_excendente_amoniaco });
    //dispatch(listarDataReporteExcedenteAmoniaco());
};
const filtroFincaExcedenteAmoniaco = (finca_reporte_excendente_amoniaco, page_reporte_excendente_amoniaco = 1, page_reporte_excendente_amoniaco_finca = 1) => (dispatch) => {
    dispatch({ type: FILTRO_REPORTE_FINCA_EXCEDENTE_AMONIACO, finca_reporte_excendente_amoniaco });
    dispatch({ type: REPORTE_PAGE_EXCEDENTE_AMONIACO_FINCA, page_reporte_excendente_amoniaco_finca });
    dispatch({ type: REPORTE_PAGE_EXCEDENTE_AMONIACO, page_reporte_excendente_amoniaco });
    //dispatch(listarDataReporteExcedenteAmoniaco());
};
const precioReporteAmoniaco = reporte_precio_amoniaco => (dispatch) => {
    dispatch({ type: REPORTE_PRECIO_AMONIACO, reporte_precio_amoniaco });
    //dispatch(listarDataReporteExcedenteAmoniaco());
};
const concentracionReporteAmoniaco = reporte_concentracion_amoniaco => (dispatch) => {
    dispatch({ type: REPORTE_CONCENTRACION_AMONIACO, reporte_concentracion_amoniaco });
    //dispatch(listarDataReporteExcedenteAmoniaco());
};

// DASHBOARD
const dataDashboard = () => (dispatch, getStore) => {
    const resource = getStore().recepciones;

    const proveedor = getStore().login.proveedor;

    const params = {};

    params.proveedor_id = proveedor;

    params.tipo_materia = resource.tipo_materia_dashboard === 'LATEX' ? LATEX : SOLIDO;

    switch (resource.tipo_materia_dashboard) {
        case 'LATEX':
            params.anio = resource.anio_dashboard_latex.value;
            if (resource.mes_dashboard_latex) {
                if (resource.mes_dashboard_latex.value) {
                    params.mes = resource.mes_dashboard_latex.value;
                }
            }
            if (resource.finca_dashboard_latex > 0) {
                params.id_finca = resource.finca_dashboard_latex;
            }
            if (resource.materia_prima_dashboard_latex > 0) {
                params.id_materia = resource.materia_prima_dashboard_latex;
            }
            break;
        case 'SOLIDO':
            params.anio = resource.anio_dashboard_solido.value;
            if (resource.mes_dashboard_solido) {
                if (resource.mes_dashboard_solido.value) {
                    params.mes = resource.mes_dashboard_solido.value;
                }
            }
            if (resource.finca_dashboard_solido > 0) {
                params.id_finca = resource.finca_dashboard_solido;
            }
            if (resource.materia_prima_dashboard_solido > 0) {
                params.id_materia = resource.materia_prima_dashboard_solido;
            }
            break;
    }

    if (params.anio) {
        dispatch(setLoader(true));
        api.get('recepcion/get_data_dashboard', params).then((response) => {
            dispatch(setData(response));
        }).catch(() => {}).finally(() => {
            dispatch(setLoader(false));
        });
    }
};
const tipoMateriaDashboardChange = (tipo_materia_dashboard) => (dispatch) => {
    dispatch({ type: DASHBOARD_TIPO_MATERIA, tipo_materia_dashboard })
    dispatch(dataDashboard())
};
// LATEX
const filtroFincaDashboardLatex = finca_dashboard_latex => (dispatch) => {
    dispatch({ type: FILTRO_DASHBOARD_FINCA_LATEX, finca_dashboard_latex });
    dispatch(dataDashboard());
};

const filtroAnioDashboardLatex = anio_dashboard_latex => (dispatch) => {
    dispatch({ type: FILTRO_DASHBOARD_ANIOS_LATEX, anio_dashboard_latex });
    dispatch(dataDashboard());
};

const filtroMesDashboardLatex = mes_dashboard_latex => (dispatch) => {
    dispatch({ type: FILTRO_DASHBOARD_MES_LATEX, mes_dashboard_latex });
    dispatch(dataDashboard());
};

const filtroMateriaPrimaDashboardLatex = materia_prima_dashboard_latex => (dispatch) => {
    dispatch({ type: FILTRO_DASHBOARD_MATERIA_PRIMA_LATEX, materia_prima_dashboard_latex });
    dispatch(dataDashboard());
};
// SOLIDO
const filtroFincaDashboardSolido = finca_dashboard_solido => (dispatch) => {
    dispatch({ type: FILTRO_DASHBOARD_FINCA_SOLIDO, finca_dashboard_solido });
    dispatch(dataDashboard());
};

const filtroAnioDashboardSolido = anio_dashboard_solido => (dispatch) => {
    dispatch({ type: FILTRO_DASHBOARD_ANIOS_SOLIDO, anio_dashboard_solido });
    dispatch(dataDashboard());
};

const filtroMesDashboardSolido = mes_dashboard_solido => (dispatch) => {
    dispatch({ type: FILTRO_DASHBOARD_MES_SOLIDO, mes_dashboard_solido });
    dispatch(dataDashboard());
};

const filtroMateriaPrimaDashboardSolido = materia_prima_dashboard_solido => (dispatch) => {
    dispatch({ type: FILTRO_DASHBOARD_MATERIA_PRIMA_SOLIDO, materia_prima_dashboard_solido });
    dispatch(dataDashboard());
};

// Funciones para el Dashboard de Proveedores
const dashboardProveedorMesSelectedChange = mes_selected => (dispatch) => {
    let fecha_inicial = moment([moment().year()]).month(mes_selected.value - 1);
    let fecha_final = moment([moment().year(), 0, 31]).month(mes_selected.value - 1);
    dispatch(dashboardProveedorFechaInicialChange(fecha_inicial));
    dispatch(dashboardProveedorFechaFinalChange(fecha_final));
    dispatch({ type: DASHBOARD_PROVEEDORES_FILTRO_MES_SELECTED, mes_selected });
    dispatch(getDataDashboardProveedor());
};
const dashboardProveedorFechaInicialChange = fecha_inicial => (dispatch) => {
    dispatch({ type: DASHBOARD_PROVEEDORES_FILTRO_FECHA_INICIAL, fecha_inicial });
};
const dashboardProveedorFechaFinalChange = fecha_final => (dispatch) => {
    dispatch({ type: DASHBOARD_PROVEEDORES_FILTRO_FECHA_FINAL, fecha_final });
};
const dashboardProveedorFincaChange = finca_id => (dispatch) => {
    dispatch({ type: DASHBOARD_PROVEEDORES_FILTRO_FINCA_ID, finca_id });
    dispatch(getDataDashboardProveedor());
};
const getDataDashboardProveedor = (finca_reset=false) => (dispatch, getStore) => {
    const resource = getStore().recepciones.dashboard_proveedores;
    const proveedor = getStore().login.proveedor;
    const params = {};

    params.proveedor_id = proveedor;
    params.fecha_inicial = moment(resource.fecha_inicial).format("YYYY-MM-DD") + ' 00:00:00';
    params.fecha_final = moment(resource.fecha_final).format("YYYY-MM-DD") + ' 23:59:59';

    !finca_reset && resource.finca_id !== -1 && (params.finca_id = resource.finca_id);
    // console.log('OBTENIENDO DATA PARA DASHBOARD DE PROVEEDOR', params);
    if (params.fecha_inicial && params.fecha_final) {
        dispatch(setLoader(true));
        api.get('recepcion/get_data_dashboard_proveedores', params).then((response) => {
            dispatch({ type: DASHBOARD_PROVEEDORES_DATA, data: response });
        }).catch(() => {}).finally(() => {
            dispatch(setLoader(false));
        });
    }
};
const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('IngresoForm', {
        fecha: moment(),
        hora_ingreso: moment().format('HH:mm'),
    }));
};

export const actions = {
    leer,
    fechaIngresoChange,
    crear,
    crearYRecuperar,
    editar,
    listar,
    setTab,
    setFiltrarRecepcionesPorAcopio,
    pageActivasChange,
    pageFinalizadasChange,
    eliminar,
    getProducto,
    setBoletaSalidaImpresa,
    initialLoad,
    onSortChange,
    searchChange,
    anularIngreso,
    clearProductos,
    realizarVaciado,
    establecerKilogramosIngreso,
    establecerKilogramosPesajeIngreso,
    actualizarPesoTara,
    actualizarProductos,
    fechaInicialFinalizadasChange,
    fechaFinalFinalizadasChange,
    pesosBascula,
    setAlturaInicialVaciadoLatex,
    // Funciones para el Reporte de recepciones
    tipoMateriaChange,
    pageReporteLatexChange,
    fechaInicialLatexChange,
    fechaFinalLatexChange,
    fincaLatexChange,
    materiaLatexChange,
    acopioLatexChange,
    pageReporteSolidoChange,
    fechaInicialSolidoChange,
    fechaFinalSolidoChange,
    fincaSolidoChange,
    materiaSolidoChange,
    acopioSolidoChange,
    getRecepcionesParaReporte,
    // Funciones para el Reporte de recepciones anuales
    tipoMateriaAnualChange,
    pageReporteAnualLatexChange,
    aniosLatexChange,
    materiaAnualLatexChange,
    pageReporteAnualSolidoChange,
    aniosSolidoChange,
    materiaAnualSolidoChange,
    getRecepcionesParaReporteAnual,
    graficaReporte,
    // Funciones para el Reporte de DRC de Recepciones
    drcTipoMateriaChange,
    drcLatexPageChange,
    drcLatexFechaInicialChange,
    drcLatexFechaFinalChange,
    drcLatexFincaChange,
    drcLatexMateriaChange,
    drcSolidoPageChange,
    drcSolidoFechaInicialChange,
    drcSolidoFechaFinalChange,
    drcSolidoFincaChange,
    drcSolidoMateriaChange,
    getReporteDRCRecepciones,
    // Reporte de excedente de amoniaco
    listarDataReporteExcedenteAmoniaco,
    pageReporteChangeExcedenteAmoniaco,
    pageReporteChangeExcedenteAmoniacoFinca,
    filtroFechaInicioExcedenteAmoniaco,
    filtroFechaFinExcedenteAmoniaco,
    filtroFincaExcedenteAmoniaco,
    precioReporteAmoniaco,
    concentracionReporteAmoniaco,
    // Dashboard
    dataDashboard,
    tipoMateriaDashboardChange,
    // LATEX
    filtroFincaDashboardLatex,
    filtroMesDashboardLatex,
    filtroAnioDashboardLatex,
    filtroMateriaPrimaDashboardLatex,
    // SOLIDO
    filtroFincaDashboardSolido,
    filtroMesDashboardSolido,
    filtroAnioDashboardSolido,
    filtroMateriaPrimaDashboardSolido,
    // Guardar boletas
    guardarBoletaPDF,
    // Funciones para el Dashboard de Proveedores
    dashboardProveedorMesSelectedChange,
    dashboardProveedorFincaChange,
    getDataDashboardProveedor,
    // Adicionales
    cleanForm,mandarAlCanal
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [MOVIMIENTOS]: (state, { movimientos }) => {
        return {
            ...state,
            movimientos,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [PAGE_ACTIVAS]: (state, { page_activas }) => {
        return {
            ...state,
            page_activas,
        };
    },
    [PAGE_FINALIZADAS]: (state, { page_finalizadas }) => {
        return {
            ...state,
            page_finalizadas,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [FECHA_INGRESO]: (state, { fecha_ingreso }) => {
        return {
            ...state,
            fecha_ingreso,
        };
    },
    [TIPOS_VEHICULOS_RECEPCIONES]: (state, { tipos_recepcion }) => {
        return {
            ...state,
            tipos_recepcion,
        };
    },
    [PRODUCTOS_LATEX]: (state, { productos_latex }) => {
        return {
            ...state,
            productos_latex,
        };
    },
    [PRODUCTOS_SOLIDO]: (state, { productos_solido }) => {
        return {
            ...state,
            productos_solido,
        };
    },
    [PRODUCTO_EDITAR]: (state, { producto }) => {
        return {
            ...state,
            producto,
        };
    },
    [PILAS_VACIADO]: (state, { pilas }) => {
        return {
            ...state,
            pilas,
        };
    },
    [ALMACENES_VACIADO]: (state, { almacenes }) => {
        return {
            ...state,
            almacenes,
        };
    },
    [TAB_RECEPCIONES]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
    [FILTRAR_RECEPCIONES_POR_ACOPIO]: (state, { filtrar_por_acopio }) => {
        return {
            ...state,
            filtrar_por_acopio,
        };
    },
    [FINALIZADAS_FECHA_INICIAL]: (state, { fecha_inicial_finalizadas }) => {
        return {
            ...state,
            fecha_inicial_finalizadas,
        };
    },
    [FINALIZADAS_FECHA_FINAL]: (state, { fecha_final_finalizadas }) => {
        return {
            ...state,
            fecha_final_finalizadas,
        };
    },
    // Reporte de Recepciones
    [REPORTE_RECEPCIONES_LATEX]: (state, {recepciones_latex}) => {
        return {
            ...state,
            reporte: {...state.reporte, recepciones_latex},
        };
    },
    [REPORTE_RECEPCIONES_SOLIDO]: (state, {recepciones_solido}) => {
        return {
            ...state,
            reporte: {...state.reporte, recepciones_solido},
        };
    },
    [REPORTE_TIPO_MATERIA]: (state, { tipo_materia }) => {
        return {
            ...state,
            reporte: {...state.reporte, tipo_materia },
        };
    },
    [REPORTE_LATEX_PAGE]: (state, { latex_page }) => {
        return {
            ...state,
            reporte: {...state.reporte, latex_page },
        };
    },
    [REPORTE_LATEX_FINCA]: (state, { latex_finca_id }) => {
        return {
            ...state,
            reporte: {...state.reporte, latex_finca_id },
        };
    },
    [REPORTE_LATEX_ACOPIO]: (state, { latex_acopio_id }) => {
        return {
            ...state,
            reporte: {...state.reporte, latex_acopio_id },
        };
    },
    [REPORTE_LATEX_MATERIA]: (state, {latex_materia_id}) => {
        return {
            ...state,
            reporte: {...state.reporte, latex_materia_id},
        };
    },
    [REPORTE_LATEX_FECHA_INICIAL]: (state, {latex_fecha_inicial}) => {
        return {
            ...state,
            reporte: {...state.reporte, latex_fecha_inicial, }
        };
    },
    [REPORTE_LATEX_FECHA_FINAL]: (state, { latex_fecha_final }) => {
        return {
            ...state,
            reporte: {...state.reporte, latex_fecha_final, }
        };
    },
    [REPORTE_SOLIDO_PAGE]: (state, { solido_page }) => {
        return {
            ...state,
            reporte: {...state.reporte, solido_page },
        };
    },
    [REPORTE_SOLIDO_FINCA]: (state, { solido_finca_id }) => {
        return {
            ...state,
            reporte: {...state.reporte, solido_finca_id },
        };
    },
    [REPORTE_SOLIDO_ACOPIO]: (state, { solido_acopio_id }) => {
        return {
            ...state,
            reporte: {...state.reporte, solido_acopio_id },
        };
    },
    [REPORTE_SOLIDO_MATERIA]: (state, {solido_materia_id}) => {
        return {
            ...state,
            reporte: {...state.reporte, solido_materia_id},
        };
    },
    [REPORTE_SOLIDO_FECHA_INICIAL]: (state, {solido_fecha_inicial}) => {
        return {
            ...state,
            reporte: {...state.reporte, solido_fecha_inicial, }
        };
    },
    [REPORTE_SOLIDO_FECHA_FINAL]: (state, { solido_fecha_final }) => {
        return {
            ...state,
            reporte: {...state.reporte, solido_fecha_final, }
        };
    },
    [PESOS_BASCULA]: (state, { peso_bascula }) => {
        return {
            ...state,
            peso_bascula,
        };
    },
    // Reporte de recepciones anual
    [REPORTE_ANUAL_TIPO_MATERIA]: (state, { tipo_materia }) => {
        return {
            ...state,
            reporte_anual: { ...state.reporte_anual, tipo_materia }
        }
    },
    [REPORTE_ANUAL_LATEX_PAGE]: (state, { latex_page }) => {
        return {
            ...state,
            reporte_anual: { ...state.reporte_anual, latex_page }
        }
    },
    [REPORTE_ANUAL_SOLIDO_PAGE]: (state, { solido_page }) => {
        return {
            ...state,
            reporte_anual: { ...state.reporte_anual, solido_page }
        }
    },
    [REPORTE_ANUAL_LATEX_ANIOS]: (state, { anios_latex }) => {
        return {
            ...state,
            reporte_anual: { ...state.reporte_anual, anios_latex }
        }
    },
    [REPORTE_ANUAL_SOLIDO_ANIOS]: (state, { anios_solido }) => {
        return {
            ...state,
            reporte_anual: { ...state.reporte_anual, anios_solido }
        }
    },
    [REPORTE_ANUAL_RECEPCIONES_LATEX]: (state, { recepciones_latex }) => {
        return {
            ...state,
            reporte_anual: { ...state.reporte_anual, recepciones_latex }
        }
    },
    [REPORTE_ANUAL_RECEPCIONES_SOLIDO]: (state, { recepciones_solido }) => {
        return {
            ...state,
            reporte_anual: { ...state.reporte_anual, recepciones_solido }
        }
    },
    [REPORTE_ANUAL_LATEX_MATERIA]: (state, { latex_materia_id }) => {
        return {
            ...state,
            reporte_anual: { ...state.reporte_anual, latex_materia_id }
        }
    },
    [REPORTE_ANUAL_SOLIDO_MATERIA]: (state, { solido_materia_id }) => {
        return {
            ...state,
            reporte_anual: { ...state.reporte_anual, solido_materia_id }
        }
    },
    [REPORTE_ANUAL_GRAFICA]: (state, { grafica_reporte }) => {
        return {
            ...state,
            reporte_anual: { ...state.reporte_anual, grafica_reporte }
        }
    },
    // Reporte de DRC de Recepciones
    [REPORTE_DRC_RECEPCIONES]: (state, { recepciones }) => {
        return {
            ...state,
            reporte_drc_ingreso: {...state.reporte_drc_ingreso, recepciones },
        };
    },
    [REPORTE_DRC_TIPO_MATERIA]: (state, { tipo_materia }) => {
        return {
            ...state,
            reporte_drc_ingreso: {...state.reporte_drc_ingreso, tipo_materia },
        };
    },
    [REPORTE_DRC_MATERIAS_LATEX]: (state, { materias_latex }) => {
        return {
            ...state,
            reporte_drc_ingreso: {...state.reporte_drc_ingreso, materias_latex },
        };
    },
    [REPORTE_DRC_MATERIAS_SOLIDO]: (state, { materias_solido }) => {
        return {
            ...state,
            reporte_drc_ingreso: {...state.reporte_drc_ingreso, materias_solido },
        };
    },
    [REPORTE_DRC_LATEX_PAGE]: (state, { latex_page }) => {
        return {
            ...state,
            reporte_drc_ingreso: {...state.reporte_drc_ingreso, latex_page },
        };
    },
    [REPORTE_DRC_LATEX_FINCA]: (state, { latex_finca_id }) => {
        return {
            ...state,
            reporte_drc_ingreso: {...state.reporte_drc_ingreso, latex_finca_id },
        };
    },
    [REPORTE_DRC_LATEX_MATERIA]: (state, { latex_materia_id }) => {
        return {
            ...state,
            reporte_drc_ingreso: {...state.reporte_drc_ingreso, latex_materia_id },
        };
    },
    [REPORTE_DRC_LATEX_FECHA_INICIAL]: (state, { latex_fecha_inicial }) => {
        return {
            ...state,
            reporte_drc_ingreso: {...state.reporte_drc_ingreso, latex_fecha_inicial, }
        };
    },
    [REPORTE_DRC_LATEX_FECHA_FINAL]: (state, { latex_fecha_final }) => {
        return {
            ...state,
            reporte_drc_ingreso: {...state.reporte_drc_ingreso, latex_fecha_final, }
        };
    },
    [REPORTE_DRC_SOLIDO_PAGE]: (state, { solido_page }) => {
        return {
            ...state,
            reporte_drc_ingreso: {...state.reporte_drc_ingreso, solido_page },
        };
    },
    [REPORTE_DRC_SOLIDO_FINCA]: (state, { solido_finca_id }) => {
        return {
            ...state,
            reporte_drc_ingreso: {...state.reporte_drc_ingreso, solido_finca_id },
        };
    },
    [REPORTE_DRC_SOLIDO_MATERIA]: (state, { solido_materia_id }) => {
        return {
            ...state,
            reporte_drc_ingreso: {...state.reporte_drc_ingreso, solido_materia_id },
        };
    },
    [REPORTE_DRC_SOLIDO_FECHA_INICIAL]: (state, { solido_fecha_inicial }) => {
        return {
            ...state,
            reporte_drc_ingreso: {...state.reporte_drc_ingreso, solido_fecha_inicial, }
        };
    },
    [REPORTE_DRC_SOLIDO_FECHA_FINAL]: (state, { solido_fecha_final }) => {
        return {
            ...state,
            reporte_drc_ingreso: {...state.reporte_drc_ingreso, solido_fecha_final, }
        };
    },
    // Reporte de excedente de amoniaco
    [REPORTE_PAGE_EXCEDENTE_AMONIACO]: (state, { page_reporte_excendente_amoniaco }) => {
        return {
            ...state,
            page_reporte_excendente_amoniaco
        };
    },
    [REPORTE_PAGE_EXCEDENTE_AMONIACO_FINCA]: (state, { page_reporte_excendente_amoniaco_finca }) => {
        return {
            ...state,
            page_reporte_excendente_amoniaco_finca
        };
    },
    [FILTRO_REPORTE_FECHA_INICIAL_EXCEDENTE_AMONIACO]: (state, { fecha_inicio_reporte_excendente_amoniaco }) => {
        return {
            ...state,
            fecha_inicio_reporte_excendente_amoniaco,
        };
    },
    [FILTRO_REPORTE_FECHA_FINAL_EXCEDENTE_AMONIACO]: (state, { fecha_fin_reporte_excendente_amoniaco }) => {
        return {
            ...state,
            fecha_fin_reporte_excendente_amoniaco,
        };
    },
    [FILTRO_REPORTE_FINCA_EXCEDENTE_AMONIACO]: (state, { finca_reporte_excendente_amoniaco }) => {
        return {
            ...state,
            finca_reporte_excendente_amoniaco,
        };
    },
    [REPORTE_PRECIO_AMONIACO]: (state, { reporte_precio_amoniaco }) => {
        return {
            ...state,
            reporte_precio_amoniaco,
        };
    },
    [REPORTE_CONCENTRACION_AMONIACO]: (state, { reporte_concentracion_amoniaco }) => {
        return {
            ...state,
            reporte_concentracion_amoniaco,
        };
    },
    // Reducers para el Dashboard
    [DASHBOARD_TIPO_MATERIA]: (state, { tipo_materia_dashboard }) => {
        return {
            ...state,
            tipo_materia_dashboard,
        };
    },
    [FILTRO_DASHBOARD_FECHA_INICIAL]: (state, { fecha_inicio_dashboard }) => {
        return {
            ...state,
            fecha_inicio_dashboard,
        };
    },
    [FILTRO_DASHBOARD_FECHA_FINAL]: (state, { fecha_fin_dashboard }) => {
        return {
            ...state,
            fecha_fin_dashboard,
        };
    },
    // LATEX
    [FILTRO_DASHBOARD_FINCA_LATEX]: (state, { finca_dashboard_latex }) => {
        return {
            ...state,
            finca_dashboard_latex,
        };
    },
    [FILTRO_DASHBOARD_MES_LATEX]: (state, { mes_dashboard_latex }) => {
        return {
            ...state,
            mes_dashboard_latex,
        };
    },
    [FILTRO_DASHBOARD_ANIOS_LATEX]: (state, { anio_dashboard_latex }) => {
        return {
            ...state,
            anio_dashboard_latex,
        };
    },
    [FILTRO_DASHBOARD_MATERIA_PRIMA_LATEX]: (state, { materia_prima_dashboard_latex }) => {
        return {
            ...state,
            materia_prima_dashboard_latex,
        };
    },
    // SOLIDO
    [FILTRO_DASHBOARD_FINCA_SOLIDO]: (state, { finca_dashboard_solido }) => {
        return {
            ...state,
            finca_dashboard_solido,
        };
    },
    [FILTRO_DASHBOARD_MES_SOLIDO]: (state, { mes_dashboard_solido }) => {
        return {
            ...state,
            mes_dashboard_solido,
        };
    },
    [FILTRO_DASHBOARD_ANIOS_SOLIDO]: (state, { anio_dashboard_solido }) => {
        return {
            ...state,
            anio_dashboard_solido,
        };
    },
    [FILTRO_DASHBOARD_MATERIA_PRIMA_SOLIDO]: (state, { materia_prima_dashboard_solido }) => {
        return {
            ...state,
            materia_prima_dashboard_solido,
        };
    },
    // REDUCERS para el Dashboard de Proveedores
    [DASHBOARD_PROVEEDORES_FILTRO_MES_SELECTED]: (state, { mes_selected }) => {
        return {
            ...state,
            dashboard_proveedores: {...state.dashboard_proveedores, mes_selected, }
        };
    },
    [DASHBOARD_PROVEEDORES_FILTRO_FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            dashboard_proveedores: {...state.dashboard_proveedores, fecha_inicial, }
        };
    },
    [DASHBOARD_PROVEEDORES_FILTRO_FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            dashboard_proveedores: {...state.dashboard_proveedores, fecha_final, }
        };
    },
    [DASHBOARD_PROVEEDORES_FILTRO_FINCA_ID]: (state, { finca_id }) => {
        return {
            ...state,
            dashboard_proveedores: {...state.dashboard_proveedores, finca_id, }
        };
    },
    [DASHBOARD_PROVEEDORES_DATA]: (state, { data }) => {
        return {
            ...state,
            dashboard_proveedores: {...state.dashboard_proveedores, data, }
        };
    },
    [DATA_RECEPCIONES_ACTIVAS]: (state, { recepciones_activas }) => {
        return {
            ...state,
            recepciones_activas
        };
    },
    [DATA_RECEPCIONES_FINALIZADAS]: (state, { recepciones_finalizadas }) => {
        return {
            ...state,
            recepciones_finalizadas
        };
    },
};

export const initialState = {
    //una variable para cada data del tab
    recepciones_activas: {
        results: [],
        count: 0,
    },
    recepciones_finalizadas: {
        results: [],
        count: 0,
    },
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    producto: {},
    productos_latex: [],
    productos_solido: [],
    item: {},
    page: 1,
    page_activas: 1,
    page_finalizadas: 1,
    ordering: '',
    search: '',
    tipos_recepcion: {},
    pilas: [],
    almacenes: [],
    fecha_ingreso: moment(),
    tab: 'ACTIVAS',
    filtrar_por_acopio: false,
    fecha_inicial_finalizadas: moment().startOf('month'),
    fecha_final_finalizadas: moment().endOf('month'),
    peso_bascula: 0,
    // Variables para el Reporte de recepciones
    reporte: {
        tipo_materia: 'LATEX',
        latex_page: 1,
        latex_fecha_inicial: moment().startOf('month'),
        latex_fecha_final: moment().endOf('month'),
        latex_finca_id: -1,
        latex_acopio_id: -1,
        latex_materia_id: -1,
        solido_page: 1,
        solido_fecha_inicial: moment().startOf('month'),
        solido_fecha_final: moment().endOf('month'),
        solido_finca_id: -1,
        solido_acopio_id: -1,
        solido_materia_id: -1,
        recepciones_latex: {
            count: 0,
            results: [],
        },
        recepciones_solido: {
            count: 0,
            results: [],
        },
        recepciones: {
            count: 0,
            results: [],
        },
    },
    // Variables para el Reporte de recepciones anual
    reporte_anual: {
        tipo_materia: 'LATEX',
        latex_page: 1,
        anios_latex: [],
        latex_materia_id: -1,
        solido_page: 1,
        anios_solido: [],
        solido_materia_id: -1,
        recepciones_latex: {
            count: 0,
            results: []
        },
        recepciones_solido: {
            count: 0,
            results: []
        },
        recepciones: {
            count: 0,
            results: []
        },
        grafica_reporte: {value: DRC, label: 'DRC Promedio'}
    },
    // Variables para el Reporte de DRC de ingresos
    reporte_drc_ingreso: {
        tipo_materia: 'LATEX',
        materias_latex: [],
        materias_solido: [],
        latex_page: 1,
        latex_fecha_inicial: moment().startOf('month'),
        latex_fecha_final: moment().endOf('month'),
        latex_finca_id: -1,
        latex_materia_id: -1,
        solido_page: 1,
        solido_fecha_inicial: moment().startOf('month'),
        solido_fecha_final: moment().endOf('month'),
        solido_finca_id: -1,
        solido_materia_id: -1,
        recepciones: {},
    },
    // Variables del reporte de excedente de amoniaco
    page_reporte_excendente_amoniaco: 1,
    page_reporte_excendente_amoniaco_finca: 1,
    fecha_inicio_reporte_excendente_amoniaco: moment().startOf('month'),
    fecha_fin_reporte_excendente_amoniaco: moment().endOf('month'),
    finca_reporte_excendente_amoniaco: [],
    reporte_precio_amoniaco: COSTO_AMONIACO,
    reporte_concentracion_amoniaco: NH3_IDEAL,
    // Variables Dashboard
    tipo_materia_dashboard: 'LATEX',
    // LATEX
    finca_dashboard_latex: -1,
    mes_dashboard_latex: meses.filter((item) => item.value === moment().month() + 1)[0],
    anio_dashboard_latex: { value: moment().year(), label: moment().year() },
    materia_prima_dashboard_latex: -1,
    // SOLIDO
    finca_dashboard_solido: -1,
    mes_dashboard_solido: meses.filter((item) => item.value === moment().month() + 1)[0],
    anio_dashboard_solido: { value: moment().year(), label: moment().year() },
    materia_prima_dashboard_solido: -1,
    // Variables para el Dashboard de Proveedores
    dashboard_proveedores: {
        finca_id: -1,
        mes_selected: meses.filter((item) => item.value === (moment().month() + 1)),
        fecha_inicial: moment().startOf('month'),
        fecha_final: moment().endOf('month'),
        data: {},
    },
};

export default handleActions(reducers, initialState);