import React, {Component} from 'react'
import Card from "Utils/Cards/Card"
import LaboratoriosSolidos from './laboratoriosSolidos';

export default class LaboratoriosList extends Component{
    componentWillMount() {
        const { setTab } = this.props;
        setTab('SÓLIDOS');
    }

    render() {
        const { data_solido_analisis: data } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="laboratorio_activo"
                    titulo="ANÁLISIS"
                    subtitulo="De laboratorio Sólidos"
                >
                    <LaboratoriosSolidos dataSolidos={data} {...this.props}/>
                </Card>
            </div>
        )
    }
}