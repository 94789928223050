import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class FiltrosFecha extends Component {
    render() {
        const {
            fecha_inicial, fecha_final,
            fechaInicioChange, fechaFinChange,
        } = this.props;

        return (
            <div className="d-flex justify-content-center">
                <div className="d-flex align-items-end mt-3 mb-3 mr-1" style={{borderRadius: "1em"}}>
                    <div className="p-0">
                        <label className="mb-1 mt-1 mr-2">Filtrar</label>
                    </div>
                </div>
                {(fechaInicioChange !== undefined) && (
                    <div className="d-flex flex-column mt-3 mb-3" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Fecha inicial</label>
                        </div>
                        <div className="p-0">
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="No especificado"
                                isClearable
                                onChange={(value) => {fechaInicioChange(value)}}
                                selected={fecha_inicial}
                            />
                        </div>
                    </div>
                )}
                {(fechaFinChange !== undefined) && (
                    <div className="d-flex flex-column mt-3 mb-3 ml-3" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Fecha final</label>
                        </div>
                        <div className="p-0">
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="No especificado"
                                isClearable
                                onChange={(value) => {fechaFinChange(value)}}
                                selected={fecha_final}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}