import React, { Component } from 'react';
import Card from '../../../Utils/Cards/Card';
import CardEmpty from '../../../Utils/Cards/CardEmpty'
import Header from 'Utils/Grid/Header';
import Grid from '../../../Utils/Grid';
import { TableHeaderColumn } from 'react-bootstrap-table';
import { standardActions } from '../../../Utils/Grid/StandardActions';

class ClientesList extends Component {
    componentDidMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props;
        const { data, loader, page } = this.props;
        return (
            <div className="mb-4 col-12">
                <Card
                    icon="configuraciones_activo"
                    titulo="CLIENTES"
                    subtitulo=""
                    noBorder
                >
                    <section className="d-flex justify-content-center mt-4">
                        <div
                            className="card p-3"
                            style={{width: '90%'}}
                        >
                            <Header to="clientes/crear" textBtn="CREAR CLIENTE" {...this.props}/>
                            <Grid
                                hover
                                striped
                                data={data}
                                loading={loader}
                                onPageChange={onPageChange}
                                onSortChange={onSortChange}
                                page={page}
                            >
                                <TableHeaderColumn
                                    dataField="id"
                                    dataAlign="center"
                                    dataSort
                                    width="10%"
                                    dataFormat={standardActions({ editar: 'clientes', eliminar })}
                                />
                                <TableHeaderColumn
                                    isKey
                                    dataField="nombre"
                                    dataSort
                                >
                                    NOMBRE
                                </TableHeaderColumn>
                            </Grid>
                        </div>
                    </section>
                </Card>
            </div>
        )
    }
}

export default ClientesList;