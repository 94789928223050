import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderSwitch } from '../../../Utils/renderField/renderField';

let ContaminanteForm = props => {
    const { handleSubmit } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column">
                <div className="row  col-md-6 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">NOMBRE DEL CONTAMINANTE</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/contaminantes">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
ContaminanteForm = reduxForm({
    form: 'ContaminanteForm',
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
        })
    }
})(ContaminanteForm);

export default ContaminanteForm
