import {api} from "api";
import React, {Fragment} from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom';
import {validate, validators} from 'validate-redux-form';
import {Field, reduxForm, formValueSelector, FieldArray,} from 'redux-form';
import {
    AsyncSelectField,
    AsyncSelectFieldFinca,
    renderField,
    renderFieldRadio,
    renderNumber,
    SelectField,
    renderDatePicker,
    renderTimeField,
} from '../../../Utils/renderField/renderField';
import DatePicker from "react-datepicker";
import {RenderAlmacen, RenderKilogramo} from '../../../Utils/renderField/renderReadField';
import {unidades_medida} from "constants"
import CardEmpty from "Utils/Cards/CardEmpty";
import validador from './validate'
import Alerta from "../../../Utils/Alerta/Alerta";
import moment from "moment";

let tipos_materia_prima = [];
const getTipoMateriaPrima = (search) => {
    return api.get("tipo_materia_prima", {search}).then((data) => {
        if (data) {
            tipos_materia_prima = data.results;
            return data.results;
        } else
            return [];
    }).catch(() => {
        return [];
    });
};
const getPilotos = (search) => {
    return api.get("piloto", {search}).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getFincas = (search) => {
    console.log("Buscando finca")
   
    return api.get("finca", {search,de_baja:false}).then((data) => {
        if (data) {
            console.log(data)
            return data.results;
        }
        return [];
    }).catch(() => {
        return [];
    });
};
const getVehiculos = (search) => {
    return api.get("vehiculo", {search}).then((data) => {
        if (data) {
            const datos = data.results;
            datos.forEach(dato => {
                dato.nombre = `${dato.tipo_vehiculo.nombre} ${dato.placa}`
            });
            return datos;
        }
        return [];
    }).catch(() => {
        return [];
    });
};

const renderProductos = ({fields, meta: {error, submitFailed}, productos_solido, productos_latex, productos, tipo_materia, establecerKilogramosIngreso}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                    <tr>
                        <th style={{width: "10%"}}/>
                        <th style={{width: "24%"}}>MATERIA PRIMA</th>
                        <th style={{width: "17%"}}>PESO FINCA</th>
                        <th style={{width: "17%"}}>UNIDAD</th>
                        <th style={{width: "24%"}}>KILOGRAMOS</th>
                        {/* <th style={{width: "16%"}}>DESTINO</th> */}
                    </tr>
                    </thead>
                </table>
            </div>
            {/*Contenido de la tabla*/}
            <div className="tabla-con-borde">
                <table className="table table-sm  m-0 table-striped">
                    <tbody>
                    {fields.map((producto, index) => {
                        let todos = fields.getAll();
                        todos[index]['index'] = index; //Mutar cada fila par agregarle el index.
                        const prod_actual = fields.get(index); //Obtiene el producto actual
                        return (
                            <tr key={index}>
                                <td className="text-center sin-borde-top" style={{width: "10%"}}>
                                    {index > 0 && (
                                        <img src={require('assets/img/icons/borrar.png')} alt="Borrar"
                                             title="Borrar" className="action_img"
                                             onClick={() => {
                                                 fields.remove(index)
                                             }}
                                        />
                                    )}
                                </td>
                                <td className="sin-borde-top" style={{width: "24%"}}>
                                    <Field name={`${producto}.materia_prima`}
                                           component={SelectField}
                                           options={(tipo_materia && tipo_materia.tipo === 10) ? productos_latex :
                                               (tipo_materia && tipo_materia.tipo === 20) ? productos_solido : []}
                                           onCambio={() => establecerKilogramosIngreso(index)}
                                           className="form-control"/>
                                </td>
                                <td className="sin-borde-top" style={{width: "17%"}}>
                                    <Field name={`${producto}.peso_finca`}
                                           type="number"
                                           onCambio={() => establecerKilogramosIngreso(index)}
                                           component={renderNumber}
                                           decimalScale={2}
                                           parse={val => parseFloat(val)}/>
                                </td>
                                <td style={{width: "17%"}}>
                                    <Field name={`${producto}.peso_finca_unidad`}
                                           component={SelectField}
                                           onCambio={() => establecerKilogramosIngreso(index)}
                                           options={unidades_medida}
                                           className="form-control"/>
                                </td>
                                <td style={{width: "24%"}}>
                                    <h6 className="bold gris"><RenderKilogramo value={prod_actual.peso_finca_kg}/></h6>
                                </td>
                                {/* <td style={{width: "16%"}}>
                                    <h6 className="bold"><RenderAlmacen value={prod_actual.destino} className={prod_actual.destino_id ? 'gris' : 'rojo'}/></h6>
                                </td> */}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            {(tipo_materia && tipo_materia.tipo === 20) && (<div className="d-flex justify-content-center mt-2">
                <button type="button" className="btn btn-outline-info py-0" onClick={() => fields.push({})}>
                    AGREGAR MATERIA PRIMA
                </button>
            </div>)}
        </div>
    </div>
);

let IngresoForm = props => {
    const {
        handleSubmit, productos_latex, productos_solido, tipo_materia, configuraciones, establecerKilogramosIngreso, values, peso,
        fechaIngresoChange, fecha_ingreso, peso_manual
    } = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="form-group grid-container d-flex align-items-center flex-column">
                    <div className="col-12 col-lg-9">
                        <h6 className="mb-4">DATOS GENERALES DE RECEPCIÓN</h6>
                        <div className="row col-md-12 p-0 d-flex flex-wrap align-items-end">
                            <div className="col-md-6 p-0">
                                <div className="col-12">
                                    <label htmlFor="fecha" className="m-0">FECHA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="fecha"
                                        component={renderDatePicker}
                                        todayButton="Hoy"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-12">
                                    <label htmlFor="hora_ingreso" className="m-0">HORA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="hora_ingreso"
                                        component={renderTimeField} type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row col-md-12 p-0 d-flex flex-wrap align-items-end">
                            <div className="col-md-6 p-0">
                                <div className="col-12">
                                    <label htmlFor="finca" className="m-0">FINCA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        valueKey="id"
                                        name="finca"
                                        label="nombre"
                                        component={AsyncSelectFieldFinca}
                                        loadOptions={getFincas}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-12">
                                    <label htmlFor="vehiculo" className="m-0">VEHÍCULO</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        valueKey="id"
                                        name="vehiculo"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField}
                                        loadOptions={getVehiculos}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row col-md-12 p-0 d-flex flex-wrap align-items-end">
                            <div className="col-md-6 p-0">
                                <div className="col-12">
                                    <label htmlFor="piloto" className="m-0">PILOTO</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        valueKey="id"
                                        name="piloto"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField}
                                        loadOptions={getPilotos}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-12">
                                    <label htmlFor="finca" className="m-0">TIPO DE MATERIA PRIMA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        valueKey="id"
                                        name="tipo_materia"
                                        labelKey="nombre"
                                        label="nombre"
                                        onCambio={props.clearProductos}
                                        component={AsyncSelectField}
                                        loadOptions={getTipoMateriaPrima}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className="row col-md-12 p-0 d-flex flex-wrap align-items-end">

                        </div> */}
                        <div className="row col-md-12 p-0 d-flex flex-wrap align-items-end">
                            <div className="col-md-6 form-group p-0">
                                <CardEmpty noShadow noPadding>
                                    <div className="col-12 pt-2">
                                        <label htmlFor="vehiculo" className="m-0">PESO BRUTO DE INGRESO</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        {peso_manual === false && (
                                            <Field name="peso_bruto" type="text" component={renderNumber} decimalScale={0}
                                                suffix=" Kg"
                                                disabled={!peso_manual}
                                                input={{value: peso}}
                                            />
                                        )}
                                        {peso_manual === true && (
                                            <Field name="peso_bruto" type="text" component={renderNumber} decimalScale={0}
                                                suffix=" Kg" parse={(value) => parseFloat(value)}
                                                disabled={!peso_manual}/>
                                        )}
                                    </div>

                                </CardEmpty>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 form-group d-flex align-content-center justify-content-center">
                                    <Alerta texto={
                                        configuraciones.peso_ingreso_manual
                                            ? `El peso se está tomando manualmente. Para tomarlo de báscula vaya a Configuración.`
                                            : `El peso se está tomando automaticamente de Báscula. Para ingresarlo manualmente se debe ir a Configuración.`
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FieldArray name="productos" component={renderProductos}
                            establecerKilogramosIngreso={establecerKilogramosIngreso}
                            productos_solido={productos_solido} productos={props.productos} tipo_materia={tipo_materia}
                            productos_latex={productos_latex}/>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/ingresos">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
IngresoForm = reduxForm({
    form: 'IngresoForm',
    asyncBlurFields: [],
    validate: validador,
    initialValues: {
        multiple_ingreso: false,
        productos: [{}],
        hora_ingreso: moment().format('HH:mm'),
        fecha: moment(),
    },
})(IngresoForm);

const selector = formValueSelector('IngresoForm');
IngresoForm = connect(state => {
    const form = state.form.IngresoForm;
    const peso_bascula = state.recepciones;
    let peso = 0;
    let productos = [];
    let values = {};
    (form && (form.values && form.values.productos)) && (productos = form.values.productos);
    const tipo_materia = selector(state, 'tipo_materia');
    const conversion = selector(state, 'unidad');
    const peso_finca = selector(state, 'peso_finca');
    const multiple_ingreso = selector(state, 'multiple_ingreso');
    let peso_finca_kg = 0;
    let destino = 'Indefinido';
    let destino_id = undefined;
    peso_finca && (peso_finca_kg = (peso_finca * conversion));
    (peso_finca_kg && form.values) && (form.values.peso_finca_kg = peso_finca_kg);

    // const { firstName, lastName } = selector(state, 'firstName', 'lastName')

    function getNumbersInString(string) {
        var tmp = string.split("");
        var map = tmp.map(function(current) {
          if (!isNaN(parseInt(current))) {
            return current;
          }
        });
        var numbers = map.filter(function(value) {
          return value != undefined;
        });
        return numbers.join("");
    }

    if (peso_bascula.peso_bascula) {
        if (peso_bascula.peso_bascula) {
            console.log("en el form estamos procesando el peso bascula")
            peso = getNumbersInString(peso_bascula.peso_bascula);
            (form && (form.values && form.values.productos)) && (form.values.peso_bruto = peso);
        }
    }
    return {
        tipo_materia,
        peso_finca_kg,
        multiple_ingreso,
        productos,
        values,
        peso
    }
})(IngresoForm);

export default IngresoForm
