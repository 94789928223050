import { connect } from 'react-redux';
import { actions } from '../../../../../../redux/modules/canal';
import CanalList from './CanalList';


const ms2p = (state) => {
  return {
    ...state.canal,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(CanalList);
