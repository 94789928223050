import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import VehiculoCrear from './VehiculoCrear';
import { actions } from '../../../../../redux/modules/vehiculos/vehiculos';

const selector = formValueSelector('VehiculoForm');
const mstp = (state) => {
    return {
        ...state.vehiculos,
        tipo_vehiculo: selector(state, 'tipo_vehiculo'),
    };
};

const mdtp = actions;

export default connect(mstp, mdtp)(VehiculoCrear)
