import { handleActions } from "redux-actions";
import { api } from "api";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import moment from "moment";

const OBTENER_DATA = "OBTENER_DATA_SUBMATERIA";
const LOADER = "LOADER_DATA_SUBMATERIA";

const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

const setData = (data) => ({
    type: OBTENER_DATA,
    data,
});

const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [OBTENER_DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
};

const initialState = {
    loader: false,
    data: [],
};

export const obtenerData = (tipo, anio) => (dispatch, getStore) => {
    const params = { tipo, anio };
    dispatch(setLoader(true));
    api.get("produccion_submateria/get_data_kg_submaterias", params)
        .then((response) => {
            dispatch(setData(response));
        }
        )
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        }
        );
};


export const actions = {
    obtenerData,
};

export default handleActions(reducers, initialState);
