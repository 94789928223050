import React, { Component } from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import Grid from '../../../Utils/Grid';
import { LATEX } from '../../../../../utility/constants';

const kilogramFormatter = (cell, row) => {
    return `${cell} kg`
}

const blankFormatter = (cell, row) => {
    if(cell === '') {
        return '-';
    }
    else {
        return cell;
    }
}

class AnulacionTableProductos extends Component {
    render() {
        const { data } = this.props;
        return (
            <section className="mt-3">
                {
                    data &&
                        <React.Fragment>
                            <h5>Datos de los productos</h5>
                            <Grid
                                data={{results: data}}
                                pagination={false}
                            >
                                <TableHeaderColumn
                                    isKey
                                    dataField="materia_prima"
                                    dataSort
                                >
                                    MATERIA PRIMA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="peso"
                                    dataSort
                                >
                                    PESO FINCA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="peso_kg"
                                    dataSort
                                    dataFormat={kilogramFormatter}
                                >
                                    PESO FINCA KG
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="pilas_bodegas"
                                    dataSort
                                    dataFormat={blankFormatter}
                                >
                                    { data.tipo_materia && data.tipo_materia === LATEX ? 'PILAS' : 'ALMACENES' }
                                    {/* PILAS */}
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="peso_bruto"
                                    dataSort
                                    dataFormat={blankFormatter}
                                >
                                    PESO BRUTO
                                </TableHeaderColumn>
                            </Grid>
                        </React.Fragment>
                }
            </section>
        )
    }
};

export default AnulacionTableProductos;