import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";

const LOADER = 'LOADER_CONFIGURACION';
const ITEM_DATA = 'ITEM_CONFIGURACION';
const CONFIGURACIONES = 'CONFIGURACIONES';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});
const setItem = item => ({
    type: ITEM_DATA,
    item,
});



export const loadConfiguracion = (initForm = true) => (dispatch) => {
    dispatch(setLoader(true));
    api.get('configuracion').then((response) => {
        dispatch({type: CONFIGURACIONES, configuraciones: response.results[0]});
        if (initForm)
            dispatch(initializeForm('ConfiguracionForm', response.results[0]));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (id, data) => (dispatch) => {
    dispatch(setLoader(true));
    api.put(`${'configuracion'}/${id}`, data).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(loadConfiguracion());
    }).catch(() => {
        NotificationManager.error('Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

export const actions = {
    loadConfiguracion,
    editar,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [CONFIGURACIONES]: (state, { configuraciones }) => {
        return {
            ...state,
            configuraciones,
        };
    },

};

export const initialState = {
    loader: false,
    configuraciones: {},
};

export default handleActions(reducers, initialState);
