import { connect } from 'react-redux'
import { actions } from '../../../../../redux/modules/despachos'
import DespachoEditar from './DespachoEditar'

const ms2p = state => {
    const configuraciones = state.configuracion.configuraciones
    const me = state.login.me
    return {
        ...state.despachos,
        configuraciones,
        me
    }
}

const md2p = { ...actions }

export default connect(ms2p, md2p)(DespachoEditar)