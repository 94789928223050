import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import {
    renderFieldRadio,
    renderCurrency,
} from '../../../Utils/renderField/renderField';
import CardEmpty from "Utils/Cards/CardEmpty";
import {PRECIO_ERSA, PRECIO_INTROSA, FLETE_EXTERNO_COSTO_FIJO, FLETE_EXTERNO_COSTO_POR_KG} from "../../../../../utility/constants";

let PasoCuatroForm = props => {
    const { handleSubmit, previousStep, desabilitarIntrosaLatex, desabilitarIntrosaSolido, desabilitarErsaLatex, desabilitarErsaSolido, item, precio_latex, precio_solido, tipo_costo_flete_externo } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <h6 className="ml-4 mb-4">DATOS DE PAGO DE FINCA</h6>
                <div className="row col-md-12 px-0 mx-0">
                    <div className="col-md-6 col-12 pl-md-0 pr-md-3 px-0 my-3">
                        <CardEmpty noShadow>
                            <label>PRECIO DE LÁTEX</label>
                            <div className="row col-md-12 m-0 px-0 px-md-0">
                                <div className="col-md-6 form-group px-md-2 mb-0">
                                    <div className="col-md-12 form-group p-0 mb-1">
                                        <Field name="tipo_precio_latex"
                                            label="INTROSA"
                                            type="radio"
                                            value={PRECIO_INTROSA}
                                            parse={val => +val}
                                            component={renderFieldRadio}
                                        />
                                        <Field name="precio_introsa_latex"
                                            className="form-control" type="text" disabled input={{value: precio_latex ? precio_latex : ""}}
                                            component={renderCurrency} prefix="$ " decimalScale={5}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 form-group px-md-2 mb-0">
                                    <div className="col-md-12 form-group p-0 mb-1">
                                        <Field name="tipo_precio_latex"
                                            label="ERSA"
                                            type="radio"
                                            value={PRECIO_ERSA}
                                            parse={val => +val}
                                            component={renderFieldRadio}
                                        />
                                        <Field name="precio_definido_latex"
                                            className="form-control" type="text" disabled={desabilitarErsaLatex ? false : true}
                                            component={renderCurrency} prefix="$ " decimalScale={5}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 form-group px-md-2 mb-0">
                                    <div className="col-md-12 form-group p-0">
                                        <label className="ml-0 mt-2">DIFERENCIAL</label>
                                        <Field name="diferencial_latex"
                                            className="form-control" type="text" disabled={desabilitarIntrosaLatex ? false : true}
                                            component={renderCurrency} prefix="$ " decimalScale={5}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardEmpty>
                    </div>
                    <div className="col-md-6 col-12 pr-md-0 pl-md-3 px-0 my-3">
                        <CardEmpty noShadow>
                            <label>PRECIO DE SÓLIDOS</label>
                            <div className="row col-md-12 m-0 px-0 px-md-0">
                                <div className="col-md-6 form-group px-md-2 mb-0">
                                    <div className="col-md-12 form-group p-0 mb-1">
                                        <Field name="tipo_precio_solido"
                                            label="INTROSA"
                                            type="radio"
                                            value={PRECIO_INTROSA}
                                            parse={val => +val}
                                            component={renderFieldRadio}
                                        />
                                        <Field name="precio_introsa_solido"
                                            className="form-control" type="text" disabled input={{value: precio_solido ? precio_solido : ""}}
                                            component={renderCurrency} prefix="$ " decimalScale={5}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 form-group px-md-2 mb-0">
                                    <div className="col-md-12 form-group p-0 mb-1">
                                        <Field name="tipo_precio_solido"
                                            label="ERSA"
                                            type="radio"
                                            value={PRECIO_ERSA}
                                            parse={val => +val}
                                            component={renderFieldRadio}
                                        />
                                        <Field name="precio_definido_solido"
                                            className="form-control" type="text" disabled={desabilitarErsaSolido ? false : true}
                                            component={renderCurrency} prefix="$ " decimalScale={5}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 form-group px-md-2 mb-0">
                                    <div className="col-md-12 form-group p-0">
                                        <label className="ml-0 mt-2">DIFERENCIAL</label>
                                        <Field name="diferencial_solido"
                                            className="form-control" type="text" disabled={desabilitarIntrosaSolido ? false : true}
                                            component={renderCurrency} prefix="$ " decimalScale={5}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardEmpty>
                    </div>
                </div>
                <h6 className="ml-4 mt-4 mb-4">DATOS DE FLETE PRODUCTOR</h6>
                <div className="row col-md-12 px-0 mx-0">
                    <div className="col-md-6 col-12 pl-md-0 pr-md-3 px-0 my-3">
                        <CardEmpty noShadow>
                            <label>LÁTEX</label>
                            <label>PRECIO FLETE AJENO AL PRODUCTOR</label>
                            <div className="row col-md-12 m-0 px-0 px-md-0">
                                <div className="col-6 col-md-6 form-group px-md-1 mb-0">
                                    <div className="col-md-12 form-group p-0 mb-0">
                                        <Field name="tipo_costo_flete_externo_latex"
                                            label="POR KG."
                                            type="radio"
                                            value={FLETE_EXTERNO_COSTO_POR_KG}
                                            parse={val => +val}
                                            component={renderFieldRadio}
                                        />
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 form-group px-md-1 mb-0">
                                    <div className="col-md-12 form-group p-0 mb-0">
                                        <Field name="tipo_costo_flete_externo_latex"
                                            label="POR FLETE"
                                            type="radio"
                                            value={FLETE_EXTERNO_COSTO_FIJO}
                                            parse={val => +val}
                                            component={renderFieldRadio}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 form-group px-md-2 mb-1 d-flex justify-content-start">
                                    <div className="col-md-7 form-group p-0">
                                        <Field name="costo_flete_externo_latex"
                                            className="form-control" type="text"
                                            component={renderCurrency}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardEmpty>
                    </div>
                        <div className="col-md-6 col-12 pr-md-0 pl-md-3 px-0 my-3">
                        <CardEmpty noShadow>
                            <label>LÁTEX</label>
                            <label>PRECIO FLETE PROPIO DEL PRODUCTOR</label>
                            <div className="row col-md-12 m-0 px-0 px-md-0">
                                <div className="col-md-7 form-group px-md-2 mb-1">
                                    <div className="col-md-12 form-group p-0">
                                        <label className="ml-0">POR KG.</label>
                                        <Field name="costo_flete_kg_interno_latex"
                                            className="form-control" type="text"
                                            component={renderCurrency} prefix="$ " decimalScale={3}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardEmpty>
                    </div>
                </div>
                <div className="row col-md-12 px-0 mx-0">
                    <div className="col-md-6 col-12 pl-md-0 pr-md-3 px-0 my-3">
                        <CardEmpty noShadow>
                            <label>SÓLIDO</label>
                            <label>PRECIO FLETE AJENO AL PRODUCTOR</label>
                            <div className="row col-md-12 m-0 px-0 px-md-0">
                                <div className="col-6 col-md-6 form-group px-md-1 mb-0">
                                    <div className="col-md-12 form-group p-0 mb-0">
                                        <Field name="tipo_costo_flete_externo_solido"
                                            label="POR KG."
                                            type="radio"
                                            value={FLETE_EXTERNO_COSTO_POR_KG}
                                            parse={val => +val}
                                            component={renderFieldRadio}
                                        />
                                    </div>
                                </div>
                                <div className="col-6 col-md-6 form-group px-md-1 mb-0">
                                    <div className="col-md-12 form-group p-0 mb-0">
                                        <Field name="tipo_costo_flete_externo_solido"
                                            label="POR FLETE"
                                            type="radio"
                                            value={FLETE_EXTERNO_COSTO_FIJO}
                                            parse={val => +val}
                                            component={renderFieldRadio}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 form-group px-md-2 mb-1 d-flex justify-content-start">
                                    <div className="col-md-7 form-group p-0">
                                        <Field name="costo_flete_externo_solido"
                                            className="form-control" type="text"
                                            component={renderCurrency}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardEmpty>
                    </div>
                        <div className="col-md-6 col-12 pr-md-0 pl-md-3 px-0 my-3">
                        <CardEmpty noShadow>
                            <label>SÓLIDO</label>
                            <label>PRECIO FLETE PROPIO DEL PRODUCTOR</label>
                            <div className="row col-md-12 m-0 px-0 px-md-0">
                                <div className="col-md-7 form-group px-md-2 mb-1">
                                    <div className="col-md-12 form-group p-0">
                                        <label className="ml-0">POR KG.</label>
                                        <Field name="costo_flete_kg_interno_solido"
                                            className="form-control" type="text"
                                            component={renderCurrency} prefix="$ " decimalScale={3}
                                        />
                                    </div>
                                </div>
                            </div>
                        </CardEmpty>
                    </div>
                </div>
                <div className="mt-3"></div>
                <div className="buttons-box mt-5">
                    <button className="btn btn-outline-dark mr-5" onClick={previousStep}>ANTERIOR</button>
                    <button type="submit" className="btn btn-primary">SIGUIENTE</button>
                </div>
            </div>
        </form>
    )
};
PasoCuatroForm = reduxForm({
    form: 'fincaForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
        })
    }
})(PasoCuatroForm);

const selector = formValueSelector('PasoCuatroForm');
PasoCuatroForm = connect(state => {
    const configuracion = state.configuracion;
    const datos_paso_cuatro = state.fincas.item;
    const form_paso_cuatro = state.form.fincaForm;
    let desabilitarIntrosaLatex = false;
    let desabilitarErsaLatex = true;
    let desabilitarIntrosaSolido = false;
    let desabilitarErsaSolido = true;
    let precio_solido = 0;
    let precio_latex = 0;
    let tipo_costo_flete_externo = 0;

    let dato_precio_definido_latex = 0

    if (form_paso_cuatro && form_paso_cuatro.values && datos_paso_cuatro) {
        const values = form_paso_cuatro.values;

        if (configuracion) {
            precio_latex = configuracion.configuraciones.precios_latex.precio_kilogramo;
            precio_solido = configuracion.configuraciones.precios_solido.precio_kilogramo;
        }

        if (values.tipo_precio_latex === PRECIO_INTROSA) {
            desabilitarIntrosaLatex = true;
            desabilitarErsaLatex = false;
        }
        if (values.tipo_precio_latex === PRECIO_ERSA) {
            desabilitarIntrosaLatex = false;
            desabilitarErsaLatex = true;
        }

        if (values.tipo_precio_solido === PRECIO_INTROSA) {
            desabilitarIntrosaSolido = true;
            desabilitarErsaSolido = false;
        }
        if (values.tipo_precio_solido === PRECIO_ERSA) {
            desabilitarIntrosaSolido = false;
            desabilitarErsaSolido = true;
        }
        tipo_costo_flete_externo = values.tipo_costo_flete_externo;
    }
    return {
        desabilitarIntrosaLatex,
        desabilitarIntrosaSolido,
        desabilitarErsaLatex,
        desabilitarErsaSolido,
        dato_precio_definido_latex,
        precio_latex,
        precio_solido,
        tipo_costo_flete_externo,
    }
})(PasoCuatroForm);

export default PasoCuatroForm
