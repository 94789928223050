import { handleActions } from 'redux-actions';
import { createReducer } from '../baseReducer/baseReducer';

export const { reducers, initialState, actions } = createReducer(
    'clientes',
    'cliente',
    'clienteForm',
    '/clientes',
)

export default handleActions(reducers, initialState);