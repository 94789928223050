import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/tipo_pago/tipo_pago';
import BancosList from './TiposPago';


const ms2p = (state) => {
  return {
    ...state.tipos_pago,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(BancosList);
