import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import LiquidacionVerForm from './LiquidacionVerForm';

export default class LiquidacionesCrear extends Component {
    componentWillMount(){
        this.setState({ title: 'CARGANDO...', message: 'Obteniendo la información', });
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
        }
    }

    actualizarFactura = (data) =>{
        this.setState({ title: 'ESPERE...', message: 'Se están completando las operaciones', });
        this.props.actualizarNumeroFactura(this.props.match.params.id, data)
    };
    regresar = () =>{
        this.props.history.goBack();
    };
    render() {
        const { updateData, loader, configuraciones, datos_drc, crear, fecha_final_liq, fecha_inicio_liq} = this.props;
        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="liquidaciones_activo"
                    titulo="LIQUIDACIONES"
                    subtitulo="Compras"
                    col="12"
                >
                    <LoadMask loading={loader} blur
                        title={this.state.title} message={this.state.message}
                    >
                        <LiquidacionVerForm
                            onSubmit={this.actualizarFactura}
                            updateData={updateData}
                            regresar={this.regresar}
                            {...this.props}
                            {...this.state}
                        />
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
