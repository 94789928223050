import React from 'react';
import {TableHeaderColumn} from 'react-bootstrap-table';
import moment from 'moment/moment';
import _ from 'lodash';
import Grid from '../../../Utils/Grid';
import { HeaderCompras } from '../../../Utils/Grid/Headers';
import {standardActions} from '../../../Utils/Grid/StandardActions';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import {RenderKilogramo, RenderNumberPercentage} from '../../../Utils/renderField/renderReadField';

const expandComponent = (row) => {
    let _data = row.ingresos ? row.ingresos.slice() : [];
    return(
        <div className="row">
            <div className="col-11 ml-5">
                <div className="tabla-adentro" style={{width: '100%'}}>
                    <BootstrapTable
                        headerStyle={ { backgroundColor: '#e24647' } }
                        data={_data}>
                        <TableHeaderColumn width="5%"/>
                        <TableHeaderColumn isKey hidden dataField="id">
                            Id
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="fecha_entrada"
                            width="15%"
                            dataFormat={cell => cell ? moment(cell).format('DD/MM/YYYY') : '---'}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="analisis"
                            width="10%"
                            dataFormat={cell => cell ? <RenderNumberPercentage value={cell.drc_promedio} /> : '---' }
                        >
                            DRC
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="id"
                            width="15%"
                        >
                            ID RECEPCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="materia_prima"
                            width="20%"
                            dataFormat={cell => cell ? cell.nombre : '---'}
                        >
                            MATERIA PRIMA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="piloto"
                            width="20%"
                            dataFormat={cell => cell ? cell.nombre : '---'}
                        >
                            PILOTO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="vehiculo"
                            width="10%"
                            dataFormat={cell => cell ? cell.placa : '---'}
                        >
                            PLACA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="acopio"
                            width="12%"
                            dataFormat={cell => cell ? cell.nombre : 'Planta ERSA'}
                        >
                            LUGAR
                        </TableHeaderColumn>
                        <TableHeaderColumn width="5%"/>
                    </BootstrapTable>
                </div>
            </div>
        </div>
    )
};

const Pendientes = props => {
    const {
        data, loader,
        page, id_finca, fecha_inicio, fecha_final,
        pageChange, filtroFincaChange, fechaInicio, fechaFinal, listar,
    } = props;
    return(
        <LoadMask loading={loader} blur noShowLoaderInfo>
            <HeaderCompras finca_id={id_finca} filtroFincaChange={filtroFincaChange}
                fecha_inicial={fecha_inicio} fechaInicialChange={fechaInicio}
                fecha_final={fecha_final} fechaFinalChange={fechaFinal} getDataCompras={listar} />
            <Grid hover striped loading={loader} data={data}
                pagination page={page} onPageChange={pageChange}
                expandableRow={()=>true} expandComponent={expandComponent}
                className="pb-3" noShowLoader noShowLoaderInfo
            >
                <TableHeaderColumn isKey dataField="id"
                    dataAlign="center"
                    width='20%'
                    dataFormat={standardActions({ generar_compra: "compra"})}
                />
                <TableHeaderColumn dataField="ingresos"
                    dataFormat={
                        cell => {
                            let kilos_totales = 0;
                            cell && cell.forEach(ingreso => kilos_totales+= _.get(ingreso, 'movimiento.peso_neto', 0));
                            return <RenderKilogramo value={kilos_totales} />
                        }
                    }
                >
                    KILOS INGRESADOS
                </TableHeaderColumn>
                <TableHeaderColumn dataField="nombre">
                    FINCA
                </TableHeaderColumn>
            </Grid>
        </LoadMask>
    )
};
export default Pendientes;
