import React, { Component } from 'react'
import { TableHeaderColumn } from 'react-bootstrap-table';
import Card from '../../../Utils/Cards/Card';
import Grid from '../../../Utils/Grid';
import { standardActions } from '../../../Utils/Grid/StandardActions';
import { RenderNumber, RenderNumberPercentage } from "../../../Utils/renderField/renderReadField";
import moment from 'moment';

const estadoFormater = (cell, row) => {
    let texto = <span>{row.estado_text}</span>;
    (cell === 20) ?
        texto = <span className="rojo">{row.estado_text}</span>
        :
        (cell === 30) ?
            texto = <span className="verde">{row.estado_text}</span>
            :
            texto = <span className="verde-secundario">{row.estado_text}</span>;
    return texto;
};

export default class IngresosLaboratorioList extends Component{
    componentWillMount() {
        const { setTab } = this.props;
        setTab('POR_VALIDAR');  // Llama a listar()
    }

    render() {
        const { data_pilas_validar:data, loader } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="laboratorio_activo"
                    titulo="PENDIENTES"
                    subtitulo="Validar DRC pila/tanque"
                >
                    <Grid hover striped data={data} loading={loader} pagination={false}
                        className="pb-3"
                    >
                        <TableHeaderColumn dataField="id"
                            dataAlign="center"
                            isKey
                            width='20%'
                            dataFormat={standardActions({ ver: "validar_drc_pilas"})}
                        />
                        <TableHeaderColumn dataField="estado"
                            dataFormat={estadoFormater}
                        >
                            ESTADO
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="drc_promedio"
                            dataFormat={cell => <RenderNumberPercentage value={cell} />}
                        >
                            DRC
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="tsc_promedio"
                            dataFormat={cell => <RenderNumberPercentage value={cell} />}
                        >
                            TSC
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="movimiento"
                            dataFormat={cell => cell && cell.bodega ? cell.bodega.nombre : '--'}
                        >
                            BODEGA
                        </TableHeaderColumn>
                        <TableHeaderColumn dataField="creado"
                            dataFormat={cell => moment(cell).format("DD/MM/YYYY")}
                        >
                            FECHA
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
