import React, {Component} from 'react'
import Popup from "reactjs-popup";
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {customActions} from "../../../Utils/Grid/CustomActions";
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import { TooltipFormat, formatActivo } from '../../../Utils/renderField/renderReadField';

export default class FincasList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }
    contactoFormat = (cell, row) => {
        if (row.de_baja){
            return (
                <Popup
                    trigger={<span className="tachado-touch">{row.nombre_contacto}</span>}
                    position="top right"
                    on="hover"
                >
                    <span className="popup-title">FINCA DADA DE BAJA</span>
                    <span className="popup-text">{row.justificacion_baja}</span>
                </Popup>
            )
        } else {
            return <span>{row.nombre_contacto}</span>
        }
    };

    render() {
        const { listar: onPageChange, onSortChange, eliminar, desactivar, activar } = this.props;
        const { data, loader, page } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="configuraciones_activo"
                    titulo="FINCA"
                    subtitulo="Del sistema">
                    <Header to="finca/crear" textBtn="CREAR FINCA" {...this.props} />
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange}
                          onSortChange={onSortChange} page={page} >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="center"
                            width='20%'
                            dataSort
                            dataFormat={customActions({ editar: "finca", ver:"finca", desactivar, activar})}
                        />
                        <TableHeaderColumn
                            isKey
                            dataField="nombre"
                            dataSort
                            // dataFormat={(cell, row) =>
                            //     <span className={row.de_baja ? 'tachado' : ''}>{cell}</span>
                            // }
                            dataFormat={TooltipFormat}
                        >
                            FINCA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="propietario"
                            dataSort
                            dataFormat={TooltipFormat}
                        >
                            PROPIETARIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="perfil"
                            dataSort
                            dataFormat={TooltipFormat}
                        >
                            PERFIL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            dataSort
                            dataFormat={this.contactoFormat}
                        >
                            CONTACTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="center"
                            dataField="id"
                            width='8%'
                            dataSort
                            dataFormat={TooltipFormat}
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="informacion_completa"
                            dataAlign="center"
                            dataFormat={cell => formatActivo(cell)}
                        >
                            LISTA
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
