import {combineReducers} from 'redux';
import {routerReducer as routing} from 'react-router-redux';
import {reducer as formReducer} from 'redux-form'
import login from './modules/cuenta/login';
import register from './modules/cuenta/register';
import profile from './modules/cuenta/profile';
import usuarios from './modules/usuarios/usuarios';
import notificaciones from './modules/notificaciones/notificaciones';
import bancos from './modules/bancos/bancos';
import propietarios from './modules/propietarios/propietarios';
import perfiles from './modules/perfiles/perfiles';
import vehiculos from './modules/vehiculos/vehiculos'
import tipos_vehiculo from './modules/tipos_vehiculo/tipos_vehiculo';
import pilotos from './modules/pilotos/pilotos'
import tipos_materia_prima from './modules/tipos_materia_prima';
import materia_prima from './modules/materia_prima';
import tipos_pago from './modules/tipo_pago/tipo_pago';
import regimenes_fiscales from './modules/regimenes_fiscales';
import fincas from './modules/fincas';
import bodegas from './modules/bodega';
import recepciones from './modules/recepciones';
import laboratorio from './modules/laboratorio';
import configuracion from './modules/configuracion';
import liquidaciones from './modules/liquidaciones';
import compras from './modules/compras';
import analisis_bodegas from './modules/analisis_bodegas';
import contaminantes from './modules/contaminantes/contaminantes';
import transportistas from './modules/transportistas/transportistas';
import roles from './modules/roles/roles';
import acopios from './modules/acopios/acopios';
import producciones from './modules/producciones';
import proveedores from './modules/proveedores';
import reportes from './modules/reportes';
import anulacion from './modules/anulacion';
import laboratoristas from './modules/laboratoristas';
import clientes from './modules/clientes';
import despachos from './modules/despachos';
import historico from './modules/historico';
import anual_proveedores from './modules/anual_proveedores';
import emailsbysendsReducer from './modules/envioNotificaciones';
import canal from './modules/canal';
import cdp from './modules/catalogo_proveedores'
import rht from './modules/reportehistorialtanques';
import laboratorio_estadisticas from './modules/laboratorio_estadisticas'
import pdsubmateria from './modules/produccion_submateria'
import canal_reporte from './modules/canal_reporte';

export default combineReducers({
    analisis_bodegas,
    bancos,
    bodegas,
    clientes,
    compras,
    configuracion,
    contaminantes,
    despachos,
    fincas,
    form: formReducer,
    laboratorio,
    laboratoristas,
    liquidaciones,
    login,
    materia_prima,
    notificaciones,
    perfiles,
    pilotos,
    producciones,
    profile,
    propietarios,
    proveedores,
    recepciones,
    regimenes_fiscales,
    reportes,
    register,
    roles,
    routing,
    tipos_materia_prima,
    tipos_pago,
    tipos_vehiculo,
    usuarios,
    vehiculos,
    transportistas,
    acopios,
    anulacion,
    historico,
    anual_proveedores,
    emailsbysendsReducer,
    canal,
    cdp,
    rht,
    laboratorio_estadisticas,
    pdsubmateria,
    canal_reporte,  
});
