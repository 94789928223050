import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/proveedores';
import ProveedorCrear from './ProveedorCrear';

const mstp = state => {
    return {...state.proveedores}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ProveedorCrear)
