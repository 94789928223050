import React, { Fragment } from 'react';
import {TableHeaderColumn} from "react-bootstrap-table";
import {recepcionActions} from "../../../Utils/Grid/RecepcionActions";
import moment from 'moment/moment';
import Grid from "../../../Utils/Grid";

const estadoFormater = (cell,row) => {
    return <span className={`${cell === 10 || cell === 35 ? 'rojo': cell === 20 ? 'azul-secundario': 'verde-secundario'}`}>
            {row.estado_ingreso_txt}
        </span>
};

const Activos = props => {
    const { dataActivos, eliminar, loader, onSortChange } = props;
    return(
        <Grid hover striped data={dataActivos} loading={loader} onSortChange={onSortChange} pagination={false}
            className="pb-3"
        >
            <TableHeaderColumn
                dataField="id"
                dataAlign="center"
                width='10%'
                dataSort
                dataFormat={recepcionActions({ editar: "ingreso", eliminar, ver: "ingreso"})}
            />
            <TableHeaderColumn
                dataField="id"
                dataSort
                dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell ? cell : ""}</span>}
            >
                ID
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="vehiculo"
                dataSort
                dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell && cell.placa ? cell.placa : ""}</span>}
            >
                PLACA
            </TableHeaderColumn>
            <TableHeaderColumn
                isKey
                dataField="estado_ingreso"
                dataSort
                dataFormat={estadoFormater}
            >
                ESTADO
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="tipo_materia"
                dataSort
                dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell && cell.nombre ? cell.nombre: ""}</span>}
            >
                TIPO MATERIA
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="piloto"
                dataSort
                dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell && cell.nombre ? cell.nombre: ""}</span>}
            >
                PILOTO
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="finca"
                dataSort
                dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell && cell.nombre ? cell.nombre: ""}</span>}
            >
                FINCA
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="fecha_entrada"
                dataSort
                dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell ? moment(cell).format('DD/MM/YYYY hh:mm A') : ""}</span>}
            >
                FECHA
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="acopio"
                dataSort
                dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell && cell.nombre ? cell.nombre : 'Planta ERSA'}</span>}
            >
                LUGAR
            </TableHeaderColumn>
        </Grid>
    )
};
export default Activos;
