import React, {Component} from 'react';
import Modal from 'react-responsive-modal';
import AnularRecepcionForm from "./AnularRecepcionForm";

const eliminar_img = require('assets/img/icons/borrar.png');
const ver_img = require('assets/img/icons/ver.png');
const editar_img = require('assets/img/icons/editar.png');
const activar_img = require('assets/img/icons/activar.png');
const desactivar_img = require('assets/img/icons/desactivar.png');
const pesa = require('assets/img/icons/pesa.png');

export default class AnularModal extends Component {
    constructor(props) {
        super(props);
        // this.state = { open: false }
    }

    closeModal = () => {
        this.setState({open: false})
    };
    onSubmit = (data) => {
        if (this.state.activar) {
            this.props.activar(this.props.id)
        } else {
            this.props.desactivar(this.props.id, data)
        }
    };

    render() {
        const {item, open, closeModal, anularRecepcion} = this.props;
        return (
            <Modal open={open} onClose={closeModal}>
                <div className="pb-4 titulo-modal d-flex justify-content-center">
                    <h2>ANULAR RECEPCIÓN ID: {item.ingreso ? item.ingreso.id : item.id}</h2>
                </div>
                <AnularRecepcionForm onSubmit={anularRecepcion} closeModal={closeModal}/>
            </Modal>
        );
    }
}
AnularModal.propTypes = {};

