import _ from 'lodash';

const validador = (values, props) => {
    const errors = {};
    if(!values.finca)
        errors.finca = 'Campo requerido.';
    if(!values.piloto)
        errors.piloto = 'Campo requerido.';
    if(!values.vehiculo)
        errors.vehiculo = 'Campo requerido.';
    if(!values.tipo_materia)
        errors.tipo_materia = 'Campo requerido.';
    if(!values.peso_bruto)
        errors.peso_bruto = 'Campo requerido.';


    const ProductosArrayErrors = [];
    if(!values.productos || values.productos.length <= 0){
        errors.errorProd = 'Se debe de agregar por lo menos un producto.'
    }else {
        values.productos.forEach((producto, productoIndex) => {
            const productoErrors = {};
            if(producto.materia_prima){
                const existe = _.find(values.productos, (p) => { return parseInt(p.materia_prima) === parseInt(producto.materia_prima)
                    && p.index !== productoIndex });
                if(existe){
                    productoErrors.materia_prima = 'El producto ya existe';
                    ProductosArrayErrors[productoIndex] = productoErrors
                }
            }
            if (!producto.peso_finca_kg && producto.peso_finca < 0) {
                productoErrors.peso_finca_kg = 'Campo requerido';
                ProductosArrayErrors[productoIndex] = productoErrors
            }
            if(Number(producto.peso_finca) < 0){
                productoErrors.peso_finca = "Debe ser una cantidad mayor o igual a 0.";
                ProductosArrayErrors[productoIndex] = productoErrors
            }
        });
        if (ProductosArrayErrors.length) {
            errors.productos = ProductosArrayErrors
        }
    }
    return errors;
}

export default validador;
