import { api } from "api";
import React, { Fragment } from 'react';
import {TableHeaderColumn} from "react-bootstrap-table";
import {recepcionActions} from "../../../Utils/Grid/RecepcionActions";
import moment from 'moment/moment';
import Grid from "../../../Utils/Grid";


const HistoricoIngresos = props => {
    const { dataHistorico, onSortChange, loader, page_finalizadas, pageFinalizadasChange } = props;
    return(
        <div style={{minHeight: '250px'}}>
            <Grid striped data={dataHistorico} loading={loader} onSortChange={onSortChange}
                pagination page={page_finalizadas} onPageChange={pageFinalizadasChange} >
                <TableHeaderColumn
                    dataField="id"
                    isKey
                    dataAlign="center"
                    width='10%'
                    dataSort
                    dataFormat={recepcionActions({ ver: "ingreso" })}
                />
                <TableHeaderColumn
                    dataField="id"
                    dataSort
                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado rojo" : ""}>{cell}</span>}
                >
                    ID
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="vehiculo"
                    dataSort
                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado rojo" : ""}>{cell && cell.placa ? cell.placa : ""}</span>}
                >
                    PLACA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="tipo_materia"
                    dataSort
                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado rojo" : ""}>{cell ? cell.nombre: ""}</span>}
                >
                    TIPO MATERIA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="piloto"
                    dataSort
                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado rojo" : ""}>{cell ? cell.nombre: ""}</span>}
                >
                    PILOTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="finca"
                    dataSort
                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado rojo" : ""}>{cell ? cell.nombre: ""}</span>}
                >
                    FINCA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha_entrada"
                    dataSort
                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado rojo" : ""}>{moment(cell).format('DD/MM/YYYY HH:mm:ss A')}</span>}
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="acopio"
                    dataSort
                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado rojo" : ""}>{cell ? cell.nombre : 'Planta ERSA'}</span>}
                >
                    LUGAR
                </TableHeaderColumn>
            </Grid>
        </div>
    )
};
export default HistoricoIngresos;
