import React, { Component, useEffect } from "react";
import DashboardProveedores from "./DashboardProveedores";
import DashboardGerencial from "./DashboardGerencial";

const Home = (props) => {
    const { me, dataDashboard, getDataDashboardProveedor, proveedor} = props;
    useEffect(() => {
        if (me.proveedor) {
            getDataDashboardProveedor();
        } else if (me.proveedor_admin) {
            getDataDashboardProveedor(true);
        } else {
            dataDashboard();
        }
    }, [proveedor]);
    return (
        <div className="mb-4 col-12 row p-0 m-0">
            {!me.proveedor &&
                me.proveedor !== undefined &&
                !me.proveedor_admin &&
                me.proveedor_admin !== undefined && (
                    <DashboardGerencial {...props} />
                )}
            {me.proveedor && me.proveedor !== undefined && (
                <DashboardProveedores {...props} />
            )}

            {me.proveedor_admin && me.proveedor_admin !== undefined && (
                <DashboardProveedores {...props} />
            )}
        </div>
    );
};

export default Home;