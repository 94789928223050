import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/recepciones';
import BoletaSalida from './BoletaSalida';

const mstp = state => {
    const item = state.recepciones.item;
    const profile = state.login.me;
    return {
        item,
        profile,
        loader: state.recepciones.loader,
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(BoletaSalida)
