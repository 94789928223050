import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { combine, validate, validators } from 'validate-redux-form';
import {formatActivo} from "Utils/renderField/renderReadField"
import { api } from "api";
import { plateValidator } from '../../../../../utility/validation';
import { renderField, AsyncSelectField } from '../../../Utils/renderField/renderField';

const getTiposVehiculos = (search) => {
    return api.get(
        "tipo_vehiculo",
        { search }
    ).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};
const getTransportistas = (search) => {
    return api.get("transportista", { search })
        .then((data) => data ? data.results : [])
        .catch(() => [])
        .finally(() => []);
};

const VehiculoForm = ({ handleSubmit, tipo_vehiculo }) => {
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="row  col-md-12 p-0">
                    <div className="col-6 p-0">
                        <div className="col-12">
                            <label htmlFor="tipo_vehiculo" className="m-0">TIPO DE VEHÍCULO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                valueKey="id"
                                name="tipo_vehiculo"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getTiposVehiculos}
                            />
                        </div>
                    </div>
                    <div className="col-6 p-0">
                        <div className="col-12">
                            <label htmlFor="transportista" className="m-0">TRANSPORTISTA</label>
                            {/* <span> (Opcional)</span> */}
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                valueKey="id"
                                isClearable
                                name="transportista"
                                labelKey="nombre_contacto"
                                label="nombre_contacto"
                                placeholder="No seleccionado"
                                component={AsyncSelectField}
                                loadOptions={getTransportistas}
                            />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-6 p-0">
                        <div className="col-12">
                            <label htmlFor="placa" className="m-0">No. PLACA</label>
                        </div>
                        <div className="col-12 d-flex align-items-center">
                            {tipo_vehiculo && (
                                <div className="mr-2 font-weight-bold">
                                    {tipo_vehiculo.prefijo_placa}
                                </div>
                            )}
                            <div>
                                <Field name="placa" component={renderField} type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="col-6 p-0">
                        <div className="col-12 d-flex justify-content-center">
                            <label htmlFor="placa" className="m-0">VALIDACIÓN DE PLACA</label>
                        </div>
                        <div className="col-12">
                            {tipo_vehiculo && (
                                <div className="d-flex justify-content-center">
                                    {formatActivo(tipo_vehiculo.validar_placa)}
                                </div>
                            )}

                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/vehiculos">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    );
};

export default reduxForm({
    form: 'VehiculoForm',
    validate: (data) => {
        const validarPlaca = data.tipo_vehiculo
            ? data.tipo_vehiculo.validar_placa
            : false;
        return validate(data, {
            tipo_vehiculo: validators.exists()('Campo requerido.'),
            placa: combine(
                validators.exists()('Campo requerido.'),
                plateValidator(validarPlaca)('Placa invalida.')
            ),
            transportista: validators.exists()('Campo requerido.'),
        });
    },
})(VehiculoForm);
