import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import Card from "Utils/Cards/Card"
import {standardActions} from "../../../Utils/Grid/StandardActions";
import moment from "moment";
import {RenderNumber, RenderNumberPercentage} from "../../../Utils/renderField/renderReadField";
import { ANALISIS_ESTADO_RECHAZADO } from '../../../../../utility/constants';

const HistoricosLatex = props => {
    const { dataLatex, onPageChange, onSortChange, loader} = props;
    const {page_finalizados_latex, pageFinalizadosLatexChange} = props;
    if (dataLatex && dataLatex.results) {
        dataLatex.results = dataLatex.results.map(analisis => {
            analisis.aditional_class = analisis.estado === ANALISIS_ESTADO_RECHAZADO ? 'tachado rojo' : '';
            return analisis;
        });
    }
    return(
        <div style={{minHeight: '250px'}}>
            <Grid hover striped data={dataLatex} loading={loader} onSortChange={onSortChange}
                pagination page={page_finalizados_latex} onPageChange={pageFinalizadosLatexChange}
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    dataSort
                    dataFormat={standardActions({ ver: "drc_validados_latex"})}
                />
                <TableHeaderColumn
                    dataField="ingreso"
                    dataSort
                    dataFormat={(cell, row) => <span className={row.aditional_class}>{cell ? cell.id : '--'}</span>}
                >
                    ID INGRESO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="drc_promedio"
                    dataSort
                    dataFormat={(cell, row) => <span className={row.aditional_class}><RenderNumberPercentage value={cell} /></span>}
                >
                    DRC
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="movimiento"
                    dataSort
                    dataFormat={(cell, row) => <span className={row.aditional_class}>{cell && cell.materia_prima ? cell.materia_prima.nombre : '--'}</span>}
                >
                    MATERIA PRIMA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="ingreso"
                    dataSort
                    dataFormat={(cell, row) => <span className={row.aditional_class}>{cell && cell.piloto ? cell.piloto.nombre : '--'}</span>}
                >
                    PILOTO
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="ingreso"
                    dataSort
                    dataFormat={(cell, row) => <span className={row.aditional_class}>{cell && cell.finca ? cell.finca.nombre : '--'}</span>}
                >
                    FINCA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="ingreso"
                    dataSort
                    dataFormat={(cell, row) => <span className={row.aditional_class}>{cell && cell.fecha_entrada ? moment(cell.fecha_entrada).format('DD/MM/YYYY hh:mm A') : '--'}</span>}
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="ingreso"
                    dataFormat={(cell, row) => <span className={row.aditional_class}>{cell && cell.acopio ? cell.acopio.nombre : 'Planta ERSA'}</span>}
                >
                    LUGAR
                </TableHeaderColumn>
            </Grid>
        </div>
    )
};
export default HistoricosLatex;
