import {handleActions} from 'redux-actions';
import {createReducer} from "../baseReducer/baseReducer";


// ------------------------------------
// Constants
// ------------------------------------

export const { reducers, initialState, actions } = createReducer(
    "bancos",
    "banco",
    "BancoForm",
    "/bancos",
);

export default handleActions(reducers, initialState);
