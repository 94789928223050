import { handleActions } from "redux-actions";
import { api } from 'api';
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import moment from 'moment';

const LOADER = 'LOADER';
const DATA = 'DATA';
const ITEM = 'ITEM';
const PAGE = 'PAGE';
const ORDERING = 'ORDERING';
const SEARCH = 'SEARCH';
const TAB_DESPACHOS = 'TAB_DESPACHOS';
const FECHA_INICIAL_FINALIZADOS = 'FECHA_INICIAL_FINALIZADOS';
const FECHA_FINAL_FINALIZADOS = 'FECHA_FINAL_FINALIZADOS';
const PESOS_BASCULA = 'PESOS_BASCULA';

const setLoader = loader => ({
    type: LOADER,
    loader,
})

const setData = data => ({
    type: DATA,
    data,
})

const setItem = item => ({
    type: ITEM,
    item,
})

const setPage = page => ({
    type: PAGE,
    page,
})

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
})

const setSearch = search => ({
    type: SEARCH,
    search,
})

const setFechaInicialFinalizados = fecha_inicial_finalizados => ({
    type: FECHA_INICIAL_FINALIZADOS,
    fecha_inicial_finalizados
})

const setFechaFinalFinalizados = fecha_final_finalizados => ({
    type: FECHA_FINAL_FINALIZADOS,
    fecha_final_finalizados
})

const setTab = tab => dispatch => {
    dispatch(setLoader(true))
    dispatch({ type: TAB_DESPACHOS, tab })
    if (tab === 'EN CURSO') {
        dispatch(listar(1, 'en curso'))
    } else {
        dispatch(listar(1, 'finalizados'))
    }
}

const fechaInicialFinalizadosChange = fecha_inicial_finalizados => dispatch => {
    dispatch(setFechaInicialFinalizados(fecha_inicial_finalizados))
    dispatch(listar())
}

const fechaFinalFinalizadosChange = fecha_final_finalizados => dispatch => {
    dispatch(setFechaFinalFinalizados(fecha_final_finalizados))
    dispatch(listar())
}

const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().despachos;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    const tab = resource.tab;
    params.filtro = 'en curso';
    if (tab === 'FINALIZADO') {
        params.filtro = 'finalizados';
        if (resource.fecha_inicial_finalizados && resource.fecha_final_finalizados) {
            params.fecha_inicial = moment(resource.fecha_inicial_finalizados).format('YYYY-MM-DD') + ' 00:00:00';
            params.fecha_final = moment(resource.fecha_final_finalizados).format('YYYY-MM-DD') + ' 23:59:59';
        }
    }
    
    dispatch(setLoader(true));
    api.get('despacho', params)
        .then(response => {
            dispatch(setData(response))
            dispatch(setPage(page))
        }).catch(() => {}).finally(() => {
            dispatch(setLoader(false));
        })
}

const leer = id => dispatch => {
    dispatch(setLoader(true));
    api.get(`despacho/${id}`)
        .then(response => {
            dispatch(setItem(response));
            // dispatch(initializeForm('despachoForm', response))
            dispatch(initializeForm('despachoEditarForm', response))
            dispatch(initializeForm('despachoForm', response))
        }).catch(() => {}).finally(() => {
            dispatch(setLoader(false));
        })
}

const crear = data => dispatch => {
    let formData = data;
    formData.cliente = data.cliente.id;
    formData.producto = data.producto.id;
    if (!formData.peso_neto) {
        if (!formData.peso_bruto_despacho) {
            formData.peso_neto = -formData.peso_tara_equipo - formData.peso_tara_empaque;
        }
        else {
            formData.peso_neto = formData.peso_bruto_despacho - formData.peso_tara_equipo - formData.peso_tara_empaque
        }
    }
    formData.fecha = `${moment(data.fecha || moment()).format('YYYY-MM-DD')} ${formData.hora_ingreso || moment().format('HH:mm:ss')}`
    if (typeof data.piloto == "object") {
        formData.piloto = data.piloto.id;
    }

    dispatch(setLoader(true));
    api.post('despacho', formData)
        .then(response => {
            NotificationManager.success('Registro creado', 'Éxito', 3000);
            dispatch(push('/despacho'));
        })
        .catch(error => {
            if (error && error.detail) {
                NotificationManager.error(error.detail, 'ERROR', 3000)
            }
            else {
                NotificationManager.error('Error en la creación', 'ERROR', 3000)
            }
            console.log(error);
        })
        .finally(() => dispatch(setLoader(false)));
}

const editar = (id, data) => dispatch => {
    let formData = data;
    formData.cliente = data.cliente.id;
    formData.producto = data.producto.id;
    if (typeof data.piloto == "object") {
        formData.piloto = data.piloto.id;
    }
    formData.peso_neto = formData.peso_bruto_despacho - formData.peso_tara_equipo - formData.peso_tara_empaque

    dispatch(setLoader(true));
    api.put(`despacho/${id}`, formData)
        .then(response => {
            NotificationManager.success('Registro Actualizado', 'Éxito', 3000);
            dispatch(push('/despacho'))
        }).catch(error => {
            if (error & error.detail) {
                NotificationManager.error(error.detail, 'ERROR');
            } else {
                NotificationManager.error('Error en la edición', 'ERROR');
            }
        }).finally(() => {
            dispatch(setLoader(false));
        })
}

const pesosBascula = () => dispatch => {
    api.getPesosBascula().then(response => {
        dispatch({ type: PESOS_BASCULA, peso_bascula: response.text })
    }).catch(() => {}).finally(() => {
        dispatch(setLoader(false))
    })
}

const eliminar = id => dispatch => {
    dispatch(setLoader(true));
    api.eliminar(`despacho/${id}`)
        .then(response => {
            dispatch(listar())
            NotificationManager.success('Registro eliminado', 'Éxito', 3000);
        }).catch(() => {
            NotificationManager.error('Error en la transacción', 'Error')
        }).finally(() => {
            dispatch(setLoader(false));
        })
}

const finalizar = item => dispatch => {
    dispatch(setLoader(true));
    api.post(`despacho/${item.id}/finalizar`)
        .then(() => {
            dispatch(listar());
            NotificationManager.success('Despacho finalizado', 'Éxito', 3000);
            dispatch(push('/despacho'));
        }).catch(error => {
            if (error && error.detail) {
                NotificationManager.error(error.detail, 'Error', 0)
            }
            else {
                NotificationManager.error('Error al finalizar el despacho', 'Error', 3000)
            }
        }).finally(() => {
            dispatch(setLoader(false));
        })
}

const clearItem = () => dispatch => {
    dispatch(setItem({}));
}

const searchChange = search => dispatch => {
    dispatch(setSearch(search));
    dispatch(listar());
}

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().despachos.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
}

const pageChange = page => dispatch => {
    dispatch(listar(page));
}

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    finalizar,
    clearItem,
    searchChange,
    onSortChange,
    pageChange,
    fechaInicialFinalizadosChange,
    fechaFinalFinalizadosChange,
    setTab,
    pesosBascula
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        }
    },
    [TAB_DESPACHOS]: (state, { tab }) => {
        return {
            ...state,
            tab,
        }
    },
    [FECHA_INICIAL_FINALIZADOS]: (state, { fecha_inicial_finalizados }) => {
        return {
            ...state,
            fecha_inicial_finalizados,
        }
    },
    [FECHA_FINAL_FINALIZADOS]: (state, { fecha_final_finalizados }) => {
        return {
            ...state,
            fecha_final_finalizados,
        }
    },
    [PESOS_BASCULA]: (state, { peso_bascula }) => {
        return {
            ...state,
            peso_bascula
        }
    }
}

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
    tab: 'EN CURSO',
    fecha_inicial_finalizados: moment().startOf('month'),
    fecha_final_finalizados: moment().endOf('month'),
    peso_bascula: 0
};

export default handleActions(reducers, initialState);