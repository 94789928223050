import React from 'react';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import Grid from '../../../Utils/Grid';
import { RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';
import { PrintContainer } from 'Utils/Print';

const Reporte = (props) => {
    const {loader, data,fecha_inicio_reporte, fecha_fin_reporte} = props;
    // Constante con la data
    const data_fincas = data && data.results ? data.results : [];

    // Variables de totales de meses.
    let total_enero = 0;
    let total_febrero = 0;
    let total_marzo = 0;
    let total_abril = 0;
    let total_mayo = 0;
    let total_junio = 0;
    let total_julio = 0;
    let total_agosto = 0;
    let total_septiembre = 0;
    let total_octubre = 0;
    let total_noviembre = 0;
    let total_diciembre = 0;
    let total_total = 0;

    // Variables de contadores.
    let contador_enero = 0;
    let contador_febrero = 0;
    let contador_marzo = 0;
    let contador_abril = 0;
    let contador_mayo = 0;
    let contador_junio = 0;
    let contador_julio = 0;
    let contador_agosto = 0;
    let contador_septiembre = 0;
    let contador_octubre = 0;
    let contador_noviembre = 0;
    let contador_diciembre = 0;
    let contador_total = 0;

    for (let i = 0; i < data_fincas.length; i++) {
        if(data_fincas[i].Enero.drc_promedio > 0){
            total_enero += data_fincas[i].Enero.drc_promedio;
            contador_enero += 1;
        }
        if(data_fincas[i].Febrero.drc_promedio > 0){
            total_febrero += data_fincas[i].Febrero.drc_promedio;
            contador_febrero += 1;
        }
        if(data_fincas[i].Marzo.drc_promedio > 0){
            total_marzo += data_fincas[i].Marzo.drc_promedio;
            contador_marzo += 1;
        }
        if(data_fincas[i].Abril.drc_promedio > 0){
            total_abril += data_fincas[i].Abril.drc_promedio;
            contador_abril += 1;
        }
        if(data_fincas[i].Mayo.drc_promedio > 0){
            total_mayo += data_fincas[i].Mayo.drc_promedio;
            contador_mayo += 1;
        }
        if(data_fincas[i].Junio.drc_promedio > 0){
            total_junio += data_fincas[i].Junio.drc_promedio;
            contador_junio += 1;
        }
        if(data_fincas[i].Julio.drc_promedio > 0){
            total_julio += data_fincas[i].Julio.drc_promedio;
            contador_julio += 1;
        }
        if(data_fincas[i].Agosto.drc_promedio > 0){
            total_agosto += data_fincas[i].Agosto.drc_promedio;
            contador_agosto += 1;
        }
        if(data_fincas[i].Septiembre.drc_promedio > 0){
            total_septiembre += data_fincas[i].Septiembre.drc_promedio;
            contador_septiembre += 1;
        }
        if(data_fincas[i].Octubre.drc_promedio > 0){
            total_octubre += data_fincas[i].Octubre.drc_promedio;
            contador_octubre += 1;
        }
        if(data_fincas[i].Noviembre.drc_promedio > 0){
            total_noviembre += data_fincas[i].Noviembre.drc_promedio;
            contador_noviembre += 1;
        }
        if(data_fincas[i].Diciembre.drc_promedio > 0){
            total_diciembre += data_fincas[i].Diciembre.drc_promedio;
            contador_diciembre += 1;
        }
        if(data_fincas[i].drc_promedio_anual > 0){
            total_total += data_fincas[i].drc_promedio_anual;
            contador_total += 1;
        }
    }

    total_enero = (total_enero > 0 && contador_enero > 0) ? total_enero/contador_enero : 0;
    total_febrero = (total_febrero > 0 && contador_febrero > 0) ? total_febrero/contador_febrero : 0;
    total_marzo = (total_marzo > 0 && contador_marzo > 0) ? total_marzo/contador_marzo : 0;
    total_abril = (total_abril > 0 && contador_abril > 0) ? total_abril/contador_abril : 0;
    total_mayo = (total_mayo > 0 && contador_mayo > 0) ? total_mayo/contador_mayo : 0;
    total_junio = (total_junio > 0 && contador_junio > 0) ? total_junio/contador_junio : 0;
    total_julio = (total_julio > 0 && contador_julio > 0) ? total_julio/contador_julio : 0;
    total_agosto = (total_agosto > 0 && contador_agosto > 0) ? total_agosto/contador_agosto : 0;
    total_septiembre = (total_septiembre > 0 && contador_septiembre > 0) ? total_septiembre/contador_septiembre : 0;
    total_octubre = (total_octubre > 0 && contador_octubre > 0) ? total_octubre/contador_octubre : 0;
    total_noviembre = (total_noviembre > 0 && contador_noviembre > 0) ? total_noviembre/contador_noviembre : 0;
    total_diciembre = (total_diciembre > 0 && contador_diciembre > 0) ? total_diciembre/contador_diciembre : 0;
    total_total = (total_total > 0 && contador_total > 0) ? total_total/contador_total : 0;

    return (
        <div>
            <div className="mb-4">
                <CardEmpty noShadow>
                    <PrintContainer name='reporte-drc-anual'>
                        <div className="no-mostrar text-center">
                            <h2 className="azul">REPORTE DRC ANUAL</h2>
                        </div>
                        <div className=" col-12 form-group np-r p-0 mb-1 inicio-tabla impresion-horizontal text-xs">
                            <Grid hover striped loading={loader} data={data}
                                pagination={false}
                                className="pt-3"
                            >
                                <TableHeaderColumn
                                    dataField="nombre"
                                    dataSort
                                    isKey
                                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell}</span>}
                                    width="10"
                                >
                                    FINCA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Enero"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumberPercentage value={cell && cell.drc_promedio ? cell.drc_promedio : 0}/>}
                                    width="7.5"
                                >
                                    ENE.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Febrero"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumberPercentage value={cell && cell.drc_promedio ? cell.drc_promedio : 0}/>}
                                    width="7.5"
                                >
                                    FEB.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Marzo"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumberPercentage value={cell && cell.drc_promedio ? cell.drc_promedio : 0}/>}
                                    width="7.5"
                                >
                                    MAR.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Abril"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumberPercentage value={cell && cell.drc_promedio ? cell.drc_promedio : 0}/>}
                                    width="7.5"
                                >
                                    ABR.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Mayo"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumberPercentage value={cell && cell.drc_promedio ? cell.drc_promedio : 0}/>}
                                    width="7.5"
                                >
                                    MAY.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Junio"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumberPercentage value={cell && cell.drc_promedio ? cell.drc_promedio : 0}/>}
                                    width="7.5"
                                >
                                    JUN.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Julio"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumberPercentage value={cell && cell.drc_promedio ? cell.drc_promedio : 0}/>}
                                    width="7.5"
                                >
                                    JUL.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Agosto"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumberPercentage value={cell && cell.drc_promedio ? cell.drc_promedio : 0}/>}
                                    width="7.5"
                                >
                                    AGO.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Septiembre"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumberPercentage value={cell && cell.drc_promedio ? cell.drc_promedio : 0}/>}
                                    width="7.5"
                                >
                                    SEP.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Octubre"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumberPercentage value={cell && cell.drc_promedio ? cell.drc_promedio : 0}/>}
                                    width="7.5"
                                >
                                    OCT.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Noviembre"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumberPercentage value={cell && cell.drc_promedio ? cell.drc_promedio : 0}/>}
                                    width="7.5"
                                >
                                    NOV.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Diciembre"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumberPercentage value={cell && cell.drc_promedio ? cell.drc_promedio : 0}/>}
                                    width="7.5"
                                >
                                    DIC.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="drc_promedio_anual"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumberPercentage value={cell  ? cell : 0}/>}
                                    width="7.5"
                                >
                                    TOTAL
                                </TableHeaderColumn>
                            </Grid>
                            <div className="total-final pb-4">
                                <div className="react-bs-table">
                                    <div className="react-bs-container-header table-header-wrapper">
                                        <table className="table table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "10%" }} className="text-left">TOTAL</th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumberPercentage value={total_enero}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumberPercentage value={total_febrero}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumberPercentage value={total_marzo}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumberPercentage value={total_abril}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumberPercentage value={total_mayo}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumberPercentage value={total_junio}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumberPercentage value={total_julio}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumberPercentage value={total_agosto}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumberPercentage value={total_septiembre}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumberPercentage value={total_octubre}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumberPercentage value={total_noviembre}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumberPercentage value={total_diciembre}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumberPercentage value={total_total}/></th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PrintContainer>
                </CardEmpty>

            </div>
        </div>
    )
};

export default Reporte;
