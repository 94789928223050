import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import ChangeForm from './ChangeForm';
import './change.css';
import LoadMask from "Utils/LoadMask/LoadMask";

class ChangePassword extends Component {
    onSubmitReset = (data) => {
        this.props.resetPassword({...data, token: this.props.match.params.token});
    };
    render() {
        const { changePassword, loader } = this.props;
        let recover = false;
        if(this.props.match && this.props.match.params && this.props.match.params.token){
            recover = true;
        }
        return (
            <div className="reset-pass-container d-flex justify-content-center">
                <div className="login-wrapper">
                    <LoadMask loading={loader} light>
                        <ChangeForm onSubmit={recover ? this.onSubmitReset : changePassword} />
                    </LoadMask>
                </div>
            </div>
        );
    }
}

export default ChangePassword;
