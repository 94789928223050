import React, { Component } from 'react';
import "./verify_login.css";


class VerifyLogin extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="verify_login">
                <div className="fondo-splash">
                </div>
                <div className="centrado-porcentual">
                    <div className="text-center">
                        <img src={require('assets/img/logo.png')} style={{height: "6rem"}}/>
                    </div>
                    <div className="letra">
                        Cargando...
                    </div>
                </div>
            </div>
        )
    }
}

export default VerifyLogin;
