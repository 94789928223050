import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/liquidaciones';
import LiquidacionesCrear from './LiquidacionesCrear';

const mstp = state => {
    return {...state.liquidaciones}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(LiquidacionesCrear)
