import { handleActions } from 'redux-actions';
import { createReducer } from '../baseReducer/baseReducer';

export const { reducers, initialState, actions } = createReducer(
    'laboratoristas',
    'laboratorista',
    'laboratoristaForm',
    '/laboratoristas',
);

export default handleActions(reducers, initialState);