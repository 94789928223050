import React from 'react';
import { Link } from 'react-router-dom';
import { Field, reduxForm } from 'redux-form';
import { validate, validators } from 'validate-redux-form';

import {renderField, renderFieldRadio} from '../../../Utils/renderField/renderField';

const PilotoForm = ({ handleSubmit }) => (
    <form onSubmit={handleSubmit}>
        <div className="form-group grid-container">
            <div className="row col-12 p-0">
                <div className="col-12 col-md-6 p-0">
                    <div className="col-md-12 col-sm-12">
                        <label htmlFor="nombre" className="m-0">MATERIA PRIMA</label>
                    </div>
                    <div className="col-md-12  form-group">
                        <Field name="nombre" component={renderField} type="text" className="form-control" />
                    </div>
                </div>
                <div className="col-12 col-md-6 p-0">
                    <div className="col-md-12 col-sm-12">
                        <label htmlFor="nombre" className="m-0">TIPO</label>
                    </div>
                    <div className="col-md-12  form-group">
                        <div className="d-flex justify-content-around">
                            <Field
                                label="LÁTEX"
                                type="radio"
                                value={10}
                                name="tipo"
                                parse={val => +val}
                                component={renderFieldRadio}
                            />
                            <Field
                                label="SÓLIDO"
                                type="radio"
                                value={20}
                                name="tipo"
                                parse={val => +val}
                                component={renderFieldRadio}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="buttons-box mt-5">
                <Link className="btn btn-outline-dark mr-5" to="/tipos_materia_prima">CANCELAR</Link>
                <button type="submit" className="btn btn-primary">GUARDAR</button>
            </div>
        </div>
    </form>
);

export default reduxForm({
    form: 'TiposMateriaPrimaForm',
    validate: data => validate(data, {
        nombre: validators.exists()('Campo requerido.'),
    }),
    initialValues: {
        tipo_materia: 10
    }
})(PilotoForm);
