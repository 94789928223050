import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/anulacion';
import Anulacion from './Anulacion';

const ms2p = state => {
    return {
        ...state.anulacion,
    }
}

const md2p = { ...actions };

export default connect(ms2p, md2p)(Anulacion);