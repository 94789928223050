import React from 'react';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import { RenderKilogramo, RenderCurrency } from '../../../Utils/renderField/renderReadField';
import { PrintContainer } from 'Utils/Print';
import { regimenes } from '../../../../../utility/constants';

const Reporte = (props) => {
    const {
        loader,
        reporte_mp_anual: reporte,
    } = props;
    // Procesamiento de la data y adaptación al formato requerido por los componentes
    const plantillaTotales = {
        kilos_secos: 0,
        total_quetzales: 0,
        retencion_iva: 0,
        retencion_isr: 0,
        retencion_peq_contribuyente: 0,
        total_insumos: 0,
    };
    let dataReporte = reporte && reporte.data ? reporte.data : [];
    dataReporte = dataReporte.map(dataPerfilFinca => {
        dataPerfilFinca.results = [];
        dataPerfilFinca.totales = {...plantillaTotales};
        regimenes.forEach(regimen => {
            let dataRegimen = dataPerfilFinca[`regimen_${regimen.value}`];
            if (dataRegimen !== undefined) {
                dataRegimen.perfil_nombre = dataPerfilFinca.results.length === 0 ? dataPerfilFinca.nombre : '';
                dataRegimen.regimen = regimen.value;
                dataRegimen.regimen_nombre = regimen.label;
                dataPerfilFinca.results.push(dataRegimen);
                // Sumatoria de totales
                Object.keys(dataPerfilFinca.totales).forEach(property => {
                    dataPerfilFinca.totales[property]+= dataRegimen[property] !== undefined && dataRegimen[property] !== null ? dataRegimen[property] : 0;
                });
            }
        });
        return dataPerfilFinca;
    });
    // Cálculo de los totales generales
    let TOTALES_GENERAL = {...plantillaTotales};
    dataReporte.forEach(dataPerfilFinca => {
        Object.keys(TOTALES_GENERAL).forEach(property => {
            TOTALES_GENERAL[property]+= dataPerfilFinca.totales[property];
        });
    });

    return (
        <div>
            <div className="mb-4">
                <CardEmpty noShadow>
                    <PrintContainer name='reporte-materia-prima-anual'>
                        <div className="no-mostrar text-center">
                            <h2 className="azul">REPORTE DE MATERIA PRIMA ANUAL</h2>
                        </div>
                        <div className=" col-12 form-group np-r p-0 mb-1 inicio-tabla impresion-horizontal">
                            { dataReporte.map((dataPerfil, index) => (
                                <div className=" col-12 form-group np-r p-0 mb-5 inicio-tabla">
                                    <div className="react-bs-table-container">
                                        <div className="react-bs-table">
                                            <div className="react-bs-container-header table-header-wrapper">
                                                <table className="table table-hover table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "10%" }}>PERFIL</th>
                                                            <th style={{ width: "18%" }}>RÉGIMEN</th>
                                                            <th style={{ width: "12%" }} className="text-right">KG. SECOS</th>
                                                            <th style={{ width: "12%" }} className="text-right">TOTAL FACT.</th>
                                                            <th style={{ width: "12%" }} className="text-right">RET. ISR</th>
                                                            <th style={{ width: "12%" }} className="text-right">RET. IVA</th>
                                                            <th style={{ width: "12%" }} className="text-right">PEQ. CONTRIBUYENTE</th>
                                                            <th style={{ width: "12%" }} className="text-right">INSUMOS</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <table className="table table-striped table-bordered table-hover mb-0">
                                        <tbody>
                                            { dataPerfil.results.map(dataRegimen => (
                                                <tr>
                                                    <td style={{ width: "10%" }}>{dataRegimen.perfil_nombre}</td>
                                                    <td style={{ width: "18%" }}>{dataRegimen.regimen_nombre}</td>
                                                    <td style={{ width: "12%" }} className="text-right"><RenderKilogramo value={dataRegimen.kilos_secos} /></td>
                                                    <td style={{ width: "12%" }} className="text-right"><RenderCurrency value={dataRegimen.total_quetzales} /></td>
                                                    <td style={{ width: "12%" }} className="text-right"><RenderCurrency value={dataRegimen.retencion_isr} /></td>
                                                    <td style={{ width: "12%" }} className="text-right"><RenderCurrency value={dataRegimen.retencion_iva} /></td>
                                                    <td style={{ width: "12%" }} className="text-right"><RenderCurrency value={dataRegimen.retencion_peq_contribuyente} /></td>
                                                    <td style={{ width: "12%" }} className="text-right"><RenderCurrency value={dataRegimen.total_insumos} /></td>
                                                </tr>
                                            )) }
                                        </tbody>
                                    </table>
                                    <div className={`react-bs-table-container ${index !== (dataReporte.length - 1) ? 'total-final' : 'total'}`}>
                                        <div className="react-bs-table">
                                            <div className="react-bs-container-header table-header-wrapper">
                                                <table className="table table-hover table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <th style={{ width: "28%" }} className="text-left">TOTAL {dataPerfil && dataPerfil.nombre ? dataPerfil.nombre.toUpperCase() : ''}</th>
                                                            <th style={{ width: "12%" }} className="text-right"><RenderKilogramo value={dataPerfil.totales['kilos_secos']}/></th>
                                                            <th style={{ width: "12%" }} className="text-right"><RenderCurrency value={dataPerfil.totales['total_quetzales']}/></th>
                                                            <th style={{ width: "12%" }} className="text-right"><RenderCurrency value={dataPerfil.totales['retencion_isr']}/></th>
                                                            <th style={{ width: "12%" }} className="text-right"><RenderCurrency value={dataPerfil.totales['retencion_iva']}/></th>
                                                            <th style={{ width: "12%" }} className="text-right"><RenderCurrency value={dataPerfil.totales['retencion_peq_contribuyente']}/></th>
                                                            <th style={{ width: "12%" }} className="text-right"><RenderCurrency value={dataPerfil.totales['total_insumos']}/></th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    { index === (dataReporte.length - 1) && (
                                        <div className="react-bs-table-container total-final">
                                            <div className="react-bs-table">
                                                <div className="react-bs-container-header table-header-wrapper">
                                                    <table className="table table-hover table-bordered">
                                                        <tbody>
                                                            <tr>
                                                                <th style={{ width: "28%" }} className="text-left">TOTAL GENERAL</th>
                                                                <th style={{ width: "12%" }} className="text-right"><RenderKilogramo value={TOTALES_GENERAL['kilos_secos']}/></th>
                                                                <th style={{ width: "12%" }} className="text-right"><RenderCurrency value={TOTALES_GENERAL['total_quetzales']}/></th>
                                                                <th style={{ width: "12%" }} className="text-right"><RenderCurrency value={TOTALES_GENERAL['retencion_isr']}/></th>
                                                                <th style={{ width: "12%" }} className="text-right"><RenderCurrency value={TOTALES_GENERAL['retencion_iva']}/></th>
                                                                <th style={{ width: "12%" }} className="text-right"><RenderCurrency value={TOTALES_GENERAL['retencion_peq_contribuyente']}/></th>
                                                                <th style={{ width: "12%" }} className="text-right"><RenderCurrency value={TOTALES_GENERAL['total_insumos']}/></th>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    ) }
                                </div>
                            )) }
                        </div>
                    </PrintContainer>
                </CardEmpty>
            </div>
        </div>
    )
};

export default Reporte;