import React, {Component} from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../FiltrosHeader';
import TabBar from 'rc-tabs/lib/TabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import Tabs, {TabPane} from 'rc-tabs';
import Reporte from './Reporte';
import CardEmpty from '../../../Utils/Cards/CardEmpty';

export default class ReporteProducciones extends Component {
    componentWillMount() {
        const { listarDataReportes, page } = this.props;
        // listarDataReportes(page);
    }

    render() {
        const {filtroFechaInicioProduccion, filtroFechaFinProduccion, filtroMateriaPrimaProduccion,
            fecha_inicio_reporte, fecha_fin_reporte, materia_prima_id_reporte, page_reporte, pageReporteChange} = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="PRODUCCIONES"
                    noBorder
                    subtitulo="Reporte">
                    <CardEmpty>
                        <FiltrosHeader {...this.props}
                            fechaInicialChange={filtroFechaInicioProduccion} fecha_inicial={fecha_inicio_reporte}
                            fechaFinalChange={filtroFechaFinProduccion} fecha_final={fecha_fin_reporte}
                            filtroMateriaChange={filtroMateriaPrimaProduccion}
                            tipo_materia={'SOLIDO'}
                            materia_id={materia_prima_id_reporte}
                            filtrarXReporte={()=>{this.props.listarDataReportes()}}
                            labelXReporte={"Filtrar"}
                        />
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <Reporte {...this.props} pageChange={pageReporteChange} page={page_reporte}></Reporte>
                </Card>
            </div>
        )
    }
}
