import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import {validate, validators} from 'validate-redux-form';
import {Field, reduxForm, formValueSelector,} from 'redux-form';
import {
    renderField,
    renderFieldRadio,
    renderCM2,
    renderNumber,
    AsyncSelectField,
    renderSwitchMessage
} from '../../../Utils/renderField/renderField';
import ReactDataSheet from 'react-datasheet';
import CardEmpty from 'Utils/Cards/CardEmpty';
import 'react-datasheet/lib/react-datasheet.css';
import {api} from "../../../../../utility/api";
import Alerta from '../../../Utils/Alerta/Alerta';

const fillMateriasPrimas = async (search) => {
    try {
        const tipos = await api.get('materia_prima', {search, no_paginar: true});
        return tipos.results;
    } catch (err) {
        console.error(err);
        return [];
    }
};

/**
 * Obtiene el listado de Acopios activos desde la API.
 * @param search campo de búsqueda (opcional)
 */
const getAcopios = (search) => {
    return api.get('acopio', {search})
        .then((data) => data ? data.results : [])
        .catch(() => []);
};

let BodegaForm = props => {
    const {handleSubmit, tipo, cubicaciones, crearFilas, editando, resetAlturaMaxima, creando} = props;

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column">
                <CardEmpty>
                    <div className="row col-12 mb-4">
                        <div className="col-12 p-0">
                            <label htmlFor="tipo">TIPO</label>
                        </div>
                        <div className="col-12 col-lg-6 p-0 d-flex justify-content-end">
                            <Field
                                label="ALMACÉN"
                                type="radio"
                                value={10}
                                disabled={editando}
                                name="tipo"
                                parse={val => +val}
                                onChange={resetAlturaMaxima}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="col-12 col-lg-6 p-0 d-flex justify-content-end">
                            <Field
                                label="PILA"
                                type="radio"
                                value={20}
                                name="tipo"
                                disabled={editando}
                                onChange={resetAlturaMaxima}
                                parse={val => +val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="col-12 col-lg-6 p-0 d-flex justify-content-end">
                            <Field
                                type="radio"
                                value={30}
                                parse={val => +val}
                                name="tipo"
                                disabled={editando}
                                onChange={resetAlturaMaxima}
                                label="TANQUE PRODUCCIÓN"
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="col-12 col-lg-6 p-0 d-flex justify-content-end">
                            <Field
                                value={40}
                                type="radio"
                                parse={val => +val}
                                name="tipo"
                                onChange={resetAlturaMaxima}
                                disabled={editando}
                                label="TANQUE MADURACIÓN"
                                component={renderFieldRadio}
                            />
                        </div>
                    </div>
                </CardEmpty>
                <div className="row col-md-12 p-0 mt-5">
                    <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12 ">
                            <label htmlFor="nombre" className="m-0">NOMBRE</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control"/>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12 ">
                            <label htmlFor="nombre " className="m-0 d-flex justify-content-between ">
                                MATERIA PRIMA 
                        </label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                name="materia_prima"
                                valueKey="id"
                                labelKey="nombre"
                                label="Materia prima"
                                disabled={editando && tipo == 20 }
                                component={AsyncSelectField}
                                loadOptions={fillMateriasPrimas}
                            />
                            <div className='px-2 pt-1'>
                                {creando && tipo == 20 && (
                                        <Alerta
                                            danger
                                            texto={`Una vez creada el ALMACÉN/PILA no se puede editar su MATERIA PRIMA`}
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12">
                            {
                                tipo > 10 ?
                                    <label htmlFor="altura_maxima" className="m-0">ALTURA MÁXIMA DE PILA</label>
                                    :
                                    <label htmlFor="altura_maxima" className="m-0">ALTURA MÁXIMA DE
                                        ALMACÉN</label>
                            }
                        </div>
                        <div className="col-md-12  form-group">
                            {
                                tipo > 10 ?
                                    < Field name="altura_maxima" component={renderNumber} onCambio={(e) => {
                                        setTimeout(function () {
                                            crearFilas(e);
                                        }, 500)
                                    }}
                                            suffix=" cms"
                                            type="text"
                                            className="form-control"/>
                                    :
                                    < Field name="altura_maxima"
                                            component={renderNumber}
                                            type="text"
                                            suffix=" kgs"
                                            className="form-control"/>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12">
                            {
                                <label htmlFor="altura_maxima" className="m-0">NÚMERO DE VACIADO</label>
                            }
                        </div>
                        <div className="col-md-12  form-group">
                            {
                                < Field name="numero_vaciado"
                                        component={renderNumber}
                                        type="text"
                                        className="form-control"/>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12">
                            {
                                <label htmlFor="cve_alm" className="m-0">CLAVE ALMACÉN SAE</label>
                            }
                        </div>
                        <div className="col-md-12  form-group">
                            {
                                < Field name="cve_alm"
                                        component={renderNumber}
                                        type="text"
                                        className="form-control"/>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12">
                            {
                                <label htmlFor="cve_alm_trading" className="m-0">CLAVE ALMACÉN SAE TRADING</label>
                            }
                        </div>
                        <div className="col-md-12  form-group">
                            {
                                < Field name="cve_alm_trading"
                                        component={renderNumber}
                                        type="text"
                                        className="form-control"/>
                            }
                        </div>
                    </div>
                    <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12 ">
                            <label htmlFor="factor_conversion" className="m-0">ACOPIO (opcional)</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                valueKey="id"
                                name="acopio"
                                labelKey="nombre"
                                label="nombre"
                                placeholder="Planta ERSA"
                                component={AsyncSelectField}
                                loadOptions={getAcopios}
                                isClearable
                                className="form-control"
                            />
                        </div>
                    </div>
                    {tipo > 10 && (
                    <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12 ">
                            <label htmlFor="factor_conversion" className="m-0">CONVERSIÓN DE CAPACIDAD</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="factor_conversion" component={renderNumber} suffix=" (kg * cm)" type="text"
                                   className="form-control"/>
                        </div>
                    </div>)}
                    <div className="col-md-6 p-0 d-flex align-items-center mt-3 mb-3">
                            <div className="col-md-9 col-sm-9">
                                <label htmlFor="es_submateria" className="m-0">SUBMATERIA</label>
                            </div>
                            <div className="col-md-3 col-sm-3">
                                <Field
                                    name="es_submateria"
                                    component={renderSwitchMessage}
                                    type="text"
                                />
                            </div>
                        </div>
                </div>


                {tipo > 10 && (
                    <div className="sheet-container">
                        <ReactDataSheet
                            data={cubicaciones}
                            valueRenderer={(cell) => {
                                return cell.value
                            }}
                            sheetRenderer={props => (
                                <table className={props.className}>
                                    <thead>
                                    <tr>
                                        <td className='cell read-only'>Centimetro</td>
                                        <td className="cell read-only">Kilogramos</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.children}
                                    </tbody>
                                </table>
                            )}
                            rowRenderer={(props) =>
                                <tr key={props.row}>
                                    {props.cells.map((cell, index) => {
                                        if (index === 0) {
                                            return (
                                                <td key={index}
                                                    className={`${index === 0 ? 'cell read-only' : 'cell'}`}>
                                                    {cell.value}
                                                </td>
                                            )
                                        } else
                                            return (props.children[1])
                                    })}
                                </tr>
                            }
                            onCellsChanged={changes => {
                                const _grid = cubicaciones.map(row => [...row]);
                                changes.forEach(({cell, row, col, value}) => {
                                    _grid[row][col] = {..._grid[row][col], value}
                                });
                                props.setCubicaciones(_grid)
                            }}
                        />
                    </div>
                )}
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/bodegas">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
BodegaForm = reduxForm({
    form: 'BodegaForm',
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
            'tipo': validators.exists()('Campo requerido.'),
            'altura_maxima': validators.exists()('Campo requerido.'),
            'numero_vaciado': validators.exists()('Campo requerido.'),
            'cve_alm': validators.exists()('Campo requerido.'),
            'cve_alm_trading': validators.exists()('Campo requerido.'),
        })
    },
    initialValues: {
        tipo: 10
    }
})(BodegaForm);

const selector = formValueSelector('BodegaForm');
BodegaForm = connect(state => {
    const tipo = selector(state, 'tipo');

    return {
        tipo
    }
})(BodegaForm);

export default BodegaForm
