import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/liquidaciones';
import LiquidacionVer from './LiquidacionVer';


const ms2p = (state) => {
  return {
    ...state.liquidaciones,
    me: state.login.me,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(LiquidacionVer);
