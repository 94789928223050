import React from "react";
import { Filtros } from "./Filtros";
import Card from "../../../Utils/Cards/Card";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardEmpty from "../../../Utils/Cards/CardEmpty";
import TablaCanal from "./TablaCanal";

const Canal_Reporte = (props) => {
    const { loader } = props;
    return (
        <Card
            icon="reportes_activo"
            titulo="CANAL"
            noBorder
            subtitulo="Reporte"
        >
            <LoadMask loading={loader} blur>
                <CardEmpty>
                    <Filtros {...props} />
                </CardEmpty>
                <div className="mb-4"></div>
                <CardEmpty>
                    <TablaCanal {...props} />
                </CardEmpty>
            </LoadMask>
        </Card>
    );
};

export default Canal_Reporte;
