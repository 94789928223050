import React, {Component} from 'react'
import Card from "Utils/Cards/Card"
import HistoricosLatex from './HistoricosLatex';
import { FiltrosFecha } from '../../../Utils/Filters';


export default class IngresosLaboratorioList extends Component{
    componentWillMount() {
        const { setTab, page } = this.props;
        setTab('LÁTEX', 'finalizados', page);
    }

    render() {
        const { data_latex_historico:data } = this.props;
        const {
            fecha_inicial_finalizados_latex, fecha_final_finalizados_latex,
            fechaInicialFinalizadosLatexChange, fechaFinalFinalizadosLatexChange,
        } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="laboratorio_activo"
                    titulo="FINALIZADOS"
                    subtitulo="De análisis Látex"
                >
                    <FiltrosFecha {...this.props}
                        fecha_inicial={fecha_inicial_finalizados_latex} fecha_final={fecha_final_finalizados_latex}
                        fechaInicioChange={fechaInicialFinalizadosLatexChange} fechaFinChange={fechaFinalFinalizadosLatexChange}
                    />
                    <HistoricosLatex dataLatex={data} {...this.props}/>
                </Card>
            </div>
        )
    }
}
