import React from "react";
import { Filtros } from "./Filtros";
import Card from "../../../Utils/Cards/Card";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import CardEmpty from "../../../Utils/Cards/CardEmpty";
import TablaPropietarios from "./TablaPropietarios";


const Catalogo_Proveedores = (props) => {
    const {loader } = props
    return (
            <Card
                icon="reportes_activo"
                titulo="CATALOGO DE PROVEEDORES"
                noBorder
                subtitulo="Reporte"
            >
                <LoadMask loading={loader} blur>
                    <CardEmpty>
                        <Filtros {...props}/>
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <CardEmpty>
                        <TablaPropietarios {...props}/>
                    </CardEmpty>
                </LoadMask>
            </Card>
    );
};

export default Catalogo_Proveedores;
