export {default as ReporteRecepciones } from './Recepciones';
export {default as ReporteRecepcionesAnual } from './RecepcionesAnual'
export {default as ReporteLiquidaciones } from './Liquidaciones';
export {default as ReporteCostoFletes } from './CostoFletes';
export {default as ReporteDRCRecepciones } from './DRCRecepciones';
export {default as ReporteRecepcionesKgSecos } from './RecepcionesKgSecos';
export {default as ReporteVersusDRC } from './DRCLabvsDRCProd';
export {default as ReporteProducciones } from './Producciones'
export {default as ReporteLaboratorio } from './Laboratorio'
export {default as ReporteFluctuacionPrecios } from './FluctuacionPrecios'
export {default as ReporteExcendenteAmoniaco } from './ExcendenteAmoniaco'
export {default as ReporteUbicacionFincas } from './UbicacionFincas';
export {default as ReporteTrasladosMateria } from './TrasladosMateria';
// NUEVOS REPORTES DETALLADOS
export {default as ReporteKgSecosAnual } from './KgSecosAnual';
export {default as ReporteDRCAnual } from './DRCAnual'
export {default as ReporteLiquidacionSemanal } from './LiquidacionSemanal'
export {default as ReporteMateriaPrimaAnual } from './MateriaPrimaAnual';
export {default as ReporteMateriaPrimaMensual } from './MateriaPrimaMensual';
export {default as ReportePilaAlmacen } from './PilaAlmacen';
export {default as DetallePilaAlmacen} from './PilaAlmacen/ver';
// REPORTES DE PROVEEDORES
export {default as ReporteLiquidacionesProveedores } from './LiquidacionesProveedores'
export {default as ReporteRecepcionesProveedores } from './RecepcionesProveedores'
export {default as ReporteLabProveedores } from './LaboratorioProveedores'
export {default as ReporteNh3DrcProveedores } from './Nh3DrcProveedores'
export {default as ReporteNh3VfaProveedores } from './Nh3VfaProveedores'
export {default as ReporteAnualProveedores } from './ReporteAnualProveedores'
// BITACORA
export { BitacoraDetalle, BitacoraListar } from './Bitacora'
