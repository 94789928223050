import {api} from "api";
import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderSwitch, SelectField, AsyncSelectField } from '../../../Utils/renderField/renderField';
import Alerta from 'Utils/Alerta/Alerta';
import { roles } from '../../../../../utility/constants';

const getPropietarios = (search) => {
    return api.get('propietario', {search})
    .then((data) => data ? data.results : [])
    .catch(() => []);
};

let ProveedorForm = props => {
    const { handleSubmit, editando } = props;
    return(
        <form onSubmit={handleSubmit}>
            {!editando &&(
                <Alerta texto="Se enviará por e-mail la contraseña temporal para el nuevo usuario." />
            )}
            <div className="form-group grid-container">
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="username" className="m-0">NOMBRE DE USUARIO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="username" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">NOMBRE COMPLETO / ENTIDAD</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">E-MAIL</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="rol" className="m-0">PROPIETARIO ASOCIADO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                valueKey="id"
                                name="propietario"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getPropietarios}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>

                {props.editando &&(<div className="row col-12 p-0">
                    <div className="col-md-6 d-flex align-items-center">
                        <div className="row">
                            <div className="col-md-8 text-gris">
                                <label htmlFor="activo" className="m-0">PROVEEDOR ACTIVO</label>
                            </div>
                            <div className="col-md-4">
                                <Field name="activo" component={renderSwitch} type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 p-0 ">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="new_password" className="m-0">NUEVA CONTRASEÑA</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="new_password" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>)}
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/proveedores">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
ProveedorForm = reduxForm({
    form: 'EditarProveedorForm',
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
            'email': validators.exists()('Campo requerido.'),
            'propietario': validators.exists()('Campo requerido.'),
            'telefono': validators.exists()('Campo requerido.'),
            'username': validators.exists()('Campo requerido.'),
            'password': validators.exists()('Campo requerido.'),
        })
    }
})(ProveedorForm);
const selector = formValueSelector('EditarProveedorForm');
ProveedorForm = connect(state => {
    const a = 'test';
    return {
        a
    };
})(ProveedorForm);
export const ProveedorUpdateForm = reduxForm({
    form: 'EditarProveedorForm',
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
            'rol_usuario': validators.exists()('Campo requerido.'),
            'username': validators.exists()('Campo requerido.'),
            'password': validators.exists()('Campo requerido.'),
        })
    },
    asyncBlurFields: []
})(ProveedorForm);

export default ProveedorForm

