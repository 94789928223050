import React, { Fragment } from 'react';
import CardEmpty from 'Utils/Cards/CardEmpty';
import {TableHeaderColumn} from 'react-bootstrap-table';
import moment from 'moment/moment';
import Grid from '../../../Utils/Grid';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { RenderKilogramo, RenderCurrency, RenderNumber, RenderDollar, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';
import { ComposedChart, Bar, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, Line, BarChart } from 'recharts';
import { SOLIDO, LATEX, analisis_laboratorio, DRC, TSC, VFA, NH3, VFA_MAXIMO, NH3_MINIMO, NH3_MAXIMO } from '../../../../../utility/constants';
import { renderLegend } from '../components';

const GENERAL = {};
GENERAL[SOLIDO] = 'SOLIDO';
GENERAL[LATEX] = 'LATEX';

const renderTooltip = props => {
    const { active, payload, label, grafica_reporte } = props;
    if (active && payload) {
            const dataKey = payload[0].dataKey ? payload[0].dataKey.replace('y_value_', '') : 'NaN';
            const valueNh3 = payload[1].value ? payload[1].value : 0;
            const valueDrc = payload[0].value ? payload[0].value : 0;
            const nombre = grafica_reporte.value === DRC ? "DRC Promedio" : (grafica_reporte.value === TSC ? "TSC Promedio" : (grafica_reporte.value === VFA ? "VFA Promedio" : (grafica_reporte.value === NH3 ? "NH3 Promedio" : "")))
    
            return (
                <CardEmpty noShadow>
                    <div className="d-flex flex-column pb-2">
                        <span className="mb-2">Fecha: {label}</span>
                        {
                            valueNh3 < NH3_MINIMO || valueNh3 > NH3_MAXIMO ?
                                <span>NH3: <RenderNumberPercentage value={valueNh3/100} decimalScale={3} style={{color: 'red'}} /></span>
                            :
                            <span>NH3: <RenderNumberPercentage value={valueNh3/100} decimalScale={3} /></span>
                        }
                        <span>DRC: <RenderNumberPercentage value={valueDrc/100} decimalScale={3} /></span>
                        <span className="mb-2"></span>
                    </div>
                </CardEmpty>
            );
    }
    return null;
};

const Reporte = (props) => {
    const {pageChange, page, loader, data,} = props;
    const {
        tipo_materia_reporte, fecha_inicio_reporte_latex,
        fecha_fin_reporte_latex, fecha_inicio_reporte_solido,
        fecha_fin_reporte_solido, grafica_reporte, graficaReporte, payload
    } = props;

    const TIPO_MATERIA = tipo_materia_reporte;
    // Procesamiento de la información para generar la gráfica de barras
    let fecha_inicio = null, fecha_final = null;
    // let kilos_secos= 0, kilos_humedos= 0, drc_promedio_1= 0;
    let drc_promedio= 0, tsc_promedio= 0, vfa_promedio= 0, nh3_promedio= 0;
    switch(TIPO_MATERIA) {
        case 'LATEX':
            fecha_inicio = fecha_inicio_reporte_latex;
            fecha_final = fecha_fin_reporte_latex;
            break;
        case 'SOLIDO':
            fecha_inicio = fecha_inicio_reporte_solido;
            fecha_final = fecha_fin_reporte_solido;
            break;
    }
    let mensajeError = '';
    let diferenciaDias = 0;
    let listaObjs = [], dataChart = [], totalesMateria = {};
    if (fecha_inicio === null || fecha_final === null) {
        mensajeError = 'FECHA INICIAL O FINAL NULO';
    } else if (fecha_final.diff(fecha_inicio, 'days') < 0) {
        mensajeError = 'FILTRO DE FECHAS NO VÁLIDO';
    } else {
        diferenciaDias = fecha_final.diff(fecha_inicio, 'days');
        listaObjs = Array(diferenciaDias + 1);
        console.log(listaObjs);
        let iterFecha = moment(fecha_inicio).clone();
        for (let index = 0; index < listaObjs.length; index++) {
            listaObjs[index] = {
                fecha: iterFecha.format('D/M/YY'),
                num_dia: 'Día '+ (index + 1),
                drc_promedio: [],
                tsc_promedio: [],
                vfa_promedio: [],
                nh3_promedio: [],
            };
            iterFecha.add(1, 'days');
        }
        const analisis_laboratorio_all = data && data.all_results ? data.all_results : [];
        // Sumatoria de kilos secos de las recepciones
        let cpFechaInicio = moment(fecha_inicio.format("YYYY-MM-DD")+' 00:00:00');
        diferenciaDias >= 0 && analisis_laboratorio_all && analisis_laboratorio_all.forEach((analisis) => {
            let cpFechaIngreso = moment(moment(analisis.ingreso.fecha_entrada).format("YYYY-MM-DD")+' 00:00:00');
            const index = cpFechaIngreso.diff(cpFechaInicio, 'days');
            if (index < listaObjs.length && index >= 0) {
                listaObjs[index].drc_promedio.push(analisis.drc_promedio || 0);
                listaObjs[index].tsc_promedio.push(analisis.tsc_promedio || 0);
                listaObjs[index].vfa_promedio.push(analisis.vfa || 0);
                listaObjs[index].nh3_promedio.push(analisis.nh3 || 0);
            }
            const materiaPrima = analisis.movimiento.materia_prima;
            let materia = totalesMateria[materiaPrima.id];
            if (materia === undefined) {
                materia = {...materiaPrima, drc_promedio: [], tsc_promedio: [], vfa_promedio: [], nh3_promedio: []};
                totalesMateria[materiaPrima.id] = materia;
            }
            materia.drc_promedio.push(analisis.drc_promedio || 0);
            materia.tsc_promedio.push(analisis.tsc_promedio || 0);
            materia.vfa_promedio.push(analisis.vfa || 0);
            materia.nh3_promedio.push(analisis.nh3 || 0);
        });
        // console.log(totalesMateria);

        // Construcción de la data para el gráfico
        listaObjs.forEach(obj => {
            if (obj.drc_promedio != 0 || obj.nh3_promedio != 0 || obj.tsc_promedio != 0 || obj.vfa_promedio != 0) {
                const data = {
                    num_dia: obj.num_dia,
                    x_value: obj.fecha,
                    drc_promedio: obj.drc_promedio,
                    tsc_promedio: obj.tsc_promedio,
                    vfa_promedio: obj.vfa_promedio,
                    nh3_promedio: obj.nh3_promedio,
                }
                // data[TIPO_MATERIA] = obj.kilos_secos;
                // data[`y_value_${TIPO_MATERIA}`] = obj.kilos_humedos;
                data[`drc_promedio${TIPO_MATERIA}`] = (obj.drc_promedio.length === 0 ? 0 : obj.drc_promedio.reduce((accum, nextVal) => accum + nextVal, 0)/obj.drc_promedio.length)*100;
                data[`tsc_promedio${TIPO_MATERIA}`] = (obj.tsc_promedio.length === 0 ? 0 : obj.tsc_promedio.reduce((accum, nextVal) => accum + nextVal, 0)/obj.tsc_promedio.length)*100;
                data[`vfa_promedio${TIPO_MATERIA}`] = (obj.vfa_promedio.length === 0 ? 0 : obj.vfa_promedio.reduce((accum, nextVal) => accum + nextVal, 0)/obj.vfa_promedio.length)*100;
                data[`nh3_promedio${TIPO_MATERIA}`] = (obj.nh3_promedio.length === 0 ? 0 : obj.nh3_promedio.reduce((accum, nextVal) => accum + nextVal, 0)/obj.nh3_promedio.length)*100;
                dataChart.push(data);
            }
        });

        // PROMEDIO DE DRC
        let contador_list_drc_promedio = 0;
        let sumador_list_drc_promedio = 0;
        if (analisis_laboratorio_all) {
            for (let i = 0; i < analisis_laboratorio_all.length; i++) {
                sumador_list_drc_promedio += analisis_laboratorio_all[i].drc_promedio;
                contador_list_drc_promedio += 1;
            }
        }
        if (sumador_list_drc_promedio === 0 || contador_list_drc_promedio === 0) {
            drc_promedio = 0;
        }else{
            drc_promedio = parseFloat(sumador_list_drc_promedio/contador_list_drc_promedio);
        };
        // PROMEDIO DE TSC
        let contador_list_tsc_promedio = 0;
        let sumador_list_tsc_promedio = 0;
        if (analisis_laboratorio_all) {
            for (let i = 0; i < analisis_laboratorio_all.length; i++) {
                sumador_list_tsc_promedio += analisis_laboratorio_all[i].tsc_promedio;
                contador_list_tsc_promedio += 1;
            }
        }
        if (sumador_list_tsc_promedio === 0 || contador_list_tsc_promedio === 0) {
            tsc_promedio = 0;
        }else{
            tsc_promedio = parseFloat(sumador_list_tsc_promedio/contador_list_tsc_promedio);
        };
        // PROMEDIO DE VFA
        let contador_list_vfa_promedio = 0;
        let sumador_list_vfa_promedio = 0;
        if (analisis_laboratorio_all) {
            for (let i = 0; i < analisis_laboratorio_all.length; i++) {
                sumador_list_vfa_promedio += analisis_laboratorio_all[i].vfa;
                contador_list_vfa_promedio += 1;
            }
        }
        if (sumador_list_vfa_promedio === 0 || contador_list_vfa_promedio === 0) {
            vfa_promedio = 0;
        }else{
            vfa_promedio = parseFloat(sumador_list_vfa_promedio/contador_list_vfa_promedio);
        };
        // PROMEDIO DE NH3
        let contador_list_nh3_promedio = 0;
        let sumador_list_nh3_promedio = 0;
        if (analisis_laboratorio_all) {
            for (let i = 0; i < analisis_laboratorio_all.length; i++) {
                sumador_list_nh3_promedio += analisis_laboratorio_all[i].nh3;
                contador_list_nh3_promedio += 1;
            }
        }
        if (sumador_list_nh3_promedio === 0 || contador_list_nh3_promedio === 0) {
            nh3_promedio = 0;
        }else{
            nh3_promedio = parseFloat(sumador_list_nh3_promedio/contador_list_nh3_promedio);
        };
    }
    return(
        <LoadMask loading={loader} blur>
            <div className="mb-4">
                <CardEmpty>
                    <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mt-4 mb-4">
                        <div className="col-12 col-md-12 d-flex flex-wrap justify-content-center align-items-center">
                            <div className="col-12 col-md-2 p-0 d-flex flex-row justify-content-center justify-content-md-start align-items-center">
                                <h6 className="m-0 mb-3 mb-md-0 bold">TOTALES</h6>
                            </div>
                            <div className="col-12 row col-md-10 d-flex flex-row flex-wrap justify-content-center align-items-center p-0">
                                {tipo_materia_reporte === 'SOLIDO' && (
                                    <div className="col-12 col-md-9 col-lg-9 d-flex flex-row flex-wrap justify-content-between justify-content-md-between align-items-center"></div>
                                )}
                                {tipo_materia_reporte === 'SOLIDO' && (
                                    <div className="col-12 col-md-3 col-lg-3 d-flex flex-row flex-wrap justify-content-between justify-content-md-between align-items-center">
                                        <span className="m-0 ml-0 ml-md-1 bold">DRC PROMEDIO:</span>
                                        <h3 className="mb-1 ml-2 gris" style={{whiteSpace: 'nowrap'}}>{<RenderNumberPercentage value={drc_promedio} />}</h3>
                                    </div>
                                )}
                                {tipo_materia_reporte === 'LATEX' && (
                                    <div className="col-12 col-md-3 col-lg-3 d-flex flex-row flex-wrap justify-content-between justify-content-md-between align-items-center">
                                        <span className="m-0 ml-0 ml-md-1 bold">DRC PROMEDIO:</span>
                                        <h3 className="mb-1 ml-2 gris" style={{whiteSpace: 'nowrap'}}>{<RenderNumberPercentage value={drc_promedio} />}</h3>
                                    </div>
                                )}
                                {tipo_materia_reporte === 'LATEX' && (
                                    <div className="col-12 col-md-3 col-lg-3 d-flex flex-row flex-wrap justify-content-between justify-content-md-between align-items-center">
                                        <span className="m-0 ml-0 ml-md-1 bold">TSC PROMEDIO:</span>
                                        <h3 className="mb-1 ml-2 gris" style={{whiteSpace: 'nowrap'}}>{<RenderNumberPercentage value={tsc_promedio} />}</h3>
                                    </div>
                                )}
                                {tipo_materia_reporte === 'LATEX' && (
                                    <div className="col-12 col-md-3 col-lg-3 d-flex flex-row flex-wrap justify-content-between justify-content-md-between align-items-center">
                                        <span className="m-0 ml-0 ml-md-1 bold">VFA PROMEDIO:</span>
                                        <h3 className="mb-1 ml-2 gris" style={{whiteSpace: 'nowrap'}}>{<RenderNumberPercentage value={vfa_promedio} decimalScale={3}/>}</h3>
                                    </div>
                                )}
                                {tipo_materia_reporte === 'LATEX' && (
                                    <div className="col-12 col-md-3 col-lg-3 d-flex flex-row flex-wrap justify-content-between justify-content-md-between align-items-center">
                                        <span className="m-0 ml-0 ml-md-1 bold">NH3 PROMEDIO:</span>
                                        <h3 className="mb-1 ml-2 gris" style={{whiteSpace: 'nowrap'}}>{<RenderNumberPercentage value={nh3_promedio} />}</h3>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                </CardEmpty>
            </div>
            <div className="mb-4">
                <CardEmpty>
                    <div className="d-flex flex-column">
                        <div className="flex-fill d-flex justify-content-center">
                        {
                                mensajeError.length === 0
                                ? <ResponsiveContainer width="85%" height="100%" minWidth={500} minHeight={300}>
                                    <ComposedChart
                                        width={730}
                                        height={250}
                                        data={dataChart}
                                    >
                                        <CartesianGrid strokeDasharray="3 3"  />
                                        <XAxis dataKey="x_value" domain = {[0]} padding={{ left: 20, right: 20 }}/>
                                        <YAxis yAxisId="left" orientation="left" unit="%" stroke="#215273"/>
                                        <YAxis yAxisId="right" orientation="right" unit="%" stroke="#359d9e"/>
                                        <Tooltip content={renderTooltip} grafica_reporte={grafica_reporte}/>
                                        <Legend content={renderLegend} graficaReporte={graficaReporte}
                                            grafica_reporte={grafica_reporte} align="left" verticalAlign="middle"
                                            layout="vertical" iconType="circle" tipo_materia_reporte={tipo_materia_reporte}
                                            title="GRÁFICA DE DRC" graficaComparativaDRC
                                        />

                                        <Area yAxisId="right" type="monotone" dataKey={`drc_promedio${TIPO_MATERIA}`} barSize={20} value={"DRC PROMEDIO"} fill={'#359d9e'} stroke={'#359d9e'} dot={{ stroke:'#359d9e'}} />
                                        <Bar yAxisId="left" dataKey={`nh3_promedio${TIPO_MATERIA}`} barSize={20} value={"NH3 PROMEDIO"} fill={'#215273'}>
                                            {
                                                dataChart.map((entry, index) => {
                                                    const color = entry.nh3_promedioLATEX < NH3_MINIMO || entry.nh3_promedioLATEX > NH3_MAXIMO ? '#ad1717' : '#215273';
                                                    return <Cell key={index} fill={color}/>
                                                })
                                            }
                                        </Bar>
                                    </ComposedChart>
                                </ResponsiveContainer>
                                : <div className="mb-3 rojo bold">{mensajeError}</div>
                            }
                        </div>
                    </div>
                </CardEmpty>
            </div>
            {/* TABLA CON LOS RESÚMENES */}
            <div className="mb-5">
                <CardEmpty>
                    <Grid hover striped loading={loader} data={data}
                        pagination onPageChange={pageChange} page={page}
                        className="pb-4 pt-3"
                    >
                        <TableHeaderColumn
                            dataField="id"
                            isKey
                            hidden
                        />
                        <TableHeaderColumn
                            dataField="ingreso"
                            dataSort
                            dataFormat={(cell,row) => cell && cell.id ? cell.id : ""}
                            width="10"
                        >
                            INGRESO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="movimiento"
                            dataSort
                            dataFormat={(cell,row) => cell && cell.materia_prima && cell.materia_prima.nombre ? cell.materia_prima.nombre: ""}
                            width="13"
                        >
                            MATERIA PRIMA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="ingreso"
                            dataSort
                            dataFormat={(cell,row) => cell && cell.fecha && cell.finca.nombre ? cell.finca.nombre: ""}
                            width="13"
                        >
                            FINCA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="ingreso"
                            dataSort
                            dataFormat={(cell,row) => cell && cell.fecha ? moment(cell.fecha).format('DD/MM/YYYY') : ""}
                            width="10"
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="movimiento"
                            dataSort
                            dataAlign="right"
                            width="13"
                            dataFormat={(cell,row) => cell && cell.peso_neto ? <RenderKilogramo value={cell.peso_neto}/> : ""}
                            >
                            KILOS HUMEDOS
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="movimiento"
                            dataFormat={(cell) => {
                                if (cell && cell.vaciados && cell.vaciados.length > 0) {
                                    let destinos = '';
                                    cell.vaciados.forEach(movimiento => {
                                        destinos.length > 0 && (destinos+= ', ');
                                        destinos+= movimiento.bodega.nombre;
                                    });
                                    return destinos;
                                }
                                return (cell && cell.bodega) ? cell.bodega.nombre : '--';
                            }}
                            width="10"
                        >
                            DESTINOS
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="hora_analisis"
                            dataSort
                            dataAlign="right"
                            dataFormat={(cell,row) => cell ? cell : "--"}
                            width="10"
                            >
                            HORA INICIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="drc_promedio"
                            dataSort
                            dataAlign="right"
                            dataFormat={(cell,row) => <RenderNumberPercentage value={cell ? cell : 0} />}
                            width="10"
                            >
                            DRC
                        </TableHeaderColumn>
                        {TIPO_MATERIA === 'LATEX' && (
                            <TableHeaderColumn
                                dataField="tsc_promedio"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderNumberPercentage value={cell ? cell : 0} />}
                                width="10"
                                >
                                TSC
                            </TableHeaderColumn>
                        )}
                        {TIPO_MATERIA === 'LATEX' && (
                            <TableHeaderColumn
                                dataField="vfa"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => {
                                    if(cell*100 > VFA_MAXIMO) {
                                        return <RenderNumberPercentage value={cell ? cell : 0} style={{color: 'red'}} />
                                    }
                                    else {
                                        return <RenderNumberPercentage value={cell ? cell : 0} />
                                    }
                                }}
                                width="10"
                                >
                                VFA
                            </TableHeaderColumn>
                        )}
                        {TIPO_MATERIA === 'LATEX' && (
                            <TableHeaderColumn
                                dataField="nh3"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => {
                                    if(cell*100 < NH3_MINIMO || cell*100 > NH3_MAXIMO) {
                                        return <RenderNumberPercentage value={cell ? cell : 0} style={{color: 'red'}} />
                                    }
                                    else {
                                        return <RenderNumberPercentage value={cell ? cell : 0} />
                                    }
                                }}
                                width="10"
                                >
                                NH3
                            </TableHeaderColumn>
                        )}
                    </Grid>
                </CardEmpty>
            </div>
        </LoadMask>
    )
};

export default Reporte;