import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { actions } from "../../../../../redux/modules/canal_reporte";
import DatePicker from "react-datepicker";
import { ErrorMessage } from "@hookform/error-message";

export const Filtros = (props) => {
    const {
        generarReporte,
        setSelectFechaFinal,
        setSelectFechaInicial,
        select_fecha_final,
        select_fecha_inicial,
    } = props;

    const {
        formState: { errors },
        control,
        register,
        handleSubmit,
    } = useForm();

    const onSubmit = (data) => generarReporte();

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="pb-3">
            <div className="row">
                <div className="col-12 col-md-3">
                    <div className="form-group">
                        <label htmlFor="fecha_inicial">Fecha Inicial</label>
                        <Controller
                            name="fecha_inicial"
                            control={control}
                            defaultValue={select_fecha_inicial}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <DatePicker
                                    showPopperArrow={false}
                                    dateFormat="DD/MM/YYYY"
                                    placeholderText="Fecha de Inicial"
                                    onChange={(value) => {
                                        setSelectFechaInicial(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={select_fecha_inicial}
                                    selected={select_fecha_inicial}
                                />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Este campo es requerido",
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="fecha_inicial"
                            render={({ message }) => (
                                <p className="text-danger p-0 m-0 pl-2">
                                    {message}
                                </p>
                            )}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="form-group">
                        <label htmlFor="fecha_final">Fecha Final</label>
                        <Controller
                            name="fecha_final"
                            control={control}
                            defaultValue={select_fecha_final}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <DatePicker
                                    showPopperArrow={false}
                                    dateFormat="DD/MM/YYYY"
                                    placeholderText="Fecha de Final"
                                    onChange={(value) => {
                                        setSelectFechaFinal(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={select_fecha_final}
                                    selected={select_fecha_final}
                                />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Este campo es requerido",
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="fecha_final"
                            render={({ message }) => (
                                <p className="text-danger p-0 m-0 pl-2">
                                    {message}
                                </p>
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="row justify-content-md-end">
                <div className="col-12 col-md-6 ">
                    <div className="row justify-content-md-end ">
                        <div className="col-16 col-md-4">
                            <button
                                type="submit"
                                className="btn btn-primary w-100 text-nowrap "
                            >
                                Generar reporte
                            </button>
                        </div>
                        {/* <div className="col-16 col-md-4"> // BOTON PARA OBTENER EXCEL FALTA IMPLEMENTAR
                            <button
                                type="button"
                                className="btn btn-primary w-100 text-nowrap "
                                onClick={(e) => {
                                    e.preventDefault();
                                    obtener_data_excel();
                                }}
                                disabled={loader_excel}
                            >
                                {loader_excel ? (
                                    <Fragment>
                                        Obteniendo excel...{" "}
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </Fragment>
                                ) : (
                                    <Fragment>Generar excel</Fragment>
                                )}
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </form>
    );
};

const mapStateToProps = (state) => ({
    ...state.canal_reporte,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filtros);
