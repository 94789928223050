import { connect } from 'react-redux'
import { actions } from '../../../../../redux/modules/recepciones'
import ReporteRecepcionesAnual from './ReporteRecepcionesAnual'

const ms2p = state => {
    return {
        ...state.recepciones,
    }
}

const md2p = { ...actions }

export default connect(ms2p, md2p)(ReporteRecepcionesAnual)