import React, {Component, Fragment} from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import "react-step-progress-bar/styles.css";
import {Link} from "react-router-dom";
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {RenderKilogramo} from "../../../Utils/renderField/renderReadField";
import DatosForm from "../Pesar/DatosForm";
import CardEmpty from "../../../Utils/Cards/CardEmpty";
import { INGRESO_ANULADO, INGRESO_FINALIZADO, INGRESO_EN_ORDEN_COMPRA, INGRESO_LIQUIDADO, LATEX } from '../../../../../utility/constants';
const alerta_img_danger = require('assets/img/icons/alerta_roja.png');

export default class IngresoVer extends Component {
    componentWillMount() {
        this.props.leer(this.props.match.params.id);
    }
    regresar(){
        this.props.history.goBack();
    }

    render() {
        const { item, loader, actualizarProductos, me } = this.props;
        const es_recepcion_por_anulacion = item.es_recepcion_por_anulacion || false;
        const estadosNoEditables = [INGRESO_FINALIZADO, INGRESO_ANULADO, INGRESO_EN_ORDEN_COMPRA, INGRESO_LIQUIDADO];
        const estado_ingreso = _.get(item, 'estado_ingreso', 0);
        // Los siguientes estados son posteriores tras finalizar una Recepción
        const estaFinalizado = [INGRESO_FINALIZADO, INGRESO_EN_ORDEN_COMPRA, INGRESO_LIQUIDADO].includes(estado_ingreso);
        const esEditable = !(estadosNoEditables.includes(estado_ingreso) || (!!me && me.proveedor));
        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="recepciones_activo"
                    titulo="RECEPCIÓN"
                    col="8"
                    subtitulo="Detalle">
                    <LoadMask loading={loader} blur>
                        {item.finca && (
                            <Fragment>
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-between align-items-baseline">
                                        <h6 className="mb-4">DATOS GENERALES DE RECEPCIÓN</h6>
                                        <label>ID RECEPCIÓN <span className="gris text-md">{item.id ? item.id.toString().padStart(5, 0) : ''.padStart(5, 0)}</span></label>
                                    </div>
                                    <DatosForm item={item} me={me} />
                                </div>
                                {item.anulado &&
                                <div className="row mt-4">
                                    <div className="col-md-12 ">
                                        <div className="mb-1 d-flex align-content-stretch">
                                            <img src={alerta_img_danger} height="35" alt="Alerta"
                                                 className={'mr-1 blink-danger'}/>
                                            <span className="ml-2 text-danger text-md bold">ANULADA</span>
                                        </div>
                                        <CardEmpty noShadow>
                                            <div className="col-12 pl-4 pb-3">
                                                <span
                                                    className="text-danger text-xs bold">JUSTIFICACIÓN DE ANULACIÓN</span>
                                                <div>
                                                <span
                                                    className="azul text-sm">{item.justificacion_anulado}</span>
                                                </div>
                                            </div>
                                        </CardEmpty>
                                    </div>
                                </div>
                                }

                                {/* PRODUCTOS */}
                                <div className="row mt-4">
                                    <div className="col-12">
                                        <h6 className="mb-2">DATOS DE LOS PRODUCTOS</h6>
                                    </div>
                                </div>
                                <Grid data={item.productos ? {results: item.productos} : {}} loading={loader}
                                      pagination={false}>
                                    <TableHeaderColumn
                                        dataField="materia_prima_nombre"
                                    >
                                        M. PRIMA
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="id"
                                        isKey
                                        hidden
                                    />
                                    <TableHeaderColumn
                                        dataField="peso_finca"
                                        dataFormat={(cell, row) => <span>{cell} {row.peso_finca_unidad_text}</span>}
                                    >
                                        PESO FINCA
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="peso_finca_kg"
                                        dataFormat={cell => <RenderKilogramo value={cell}/>}
                                    >
                                        PESO FINCA KG
                                    </TableHeaderColumn>
                                    <TableHeaderColumn
                                        dataField="vaciados"
                                        width='230px'
                                        dataFormat={(cell, row) => {
                                            if (es_recepcion_por_anulacion){
                                                return "CANAL DE COAGULACIÓN"
                                            }
                                            else {

                                                if (cell && cell.length > 0) {
                                                    let destinos = '';
                                                    cell.forEach(movimiento => {
                                                        destinos.length > 0 && (destinos+= ', ');
                                                        destinos+= movimiento.bodega.nombre;
                                                    });
                                                    return destinos;
                                                }
                                                return row.bodega ? row.bodega.nombre : '--';
                                            }
                                        }}
                                    >
                                        { item.tipo_materia && item.tipo_materia.tipo === LATEX ? 'PILAS' : 'ALMACENES' }
                                    </TableHeaderColumn>
                                    {!es_recepcion_por_anulacion && (
                                        <TableHeaderColumn
                                        dataField="peso_bruto"
                                        dataFormat={cell => <RenderKilogramo value={cell}/>}
                                        >
                                        PESO BRUTO
                                        </TableHeaderColumn>
                                    )}
                                </Grid>
                                {/*Datos de Laboratorio*/}
                                {/* {(item.estado_ingreso === 30 && item.tipo_materia.tipo === 10) && (
                                    <Fragment>
                                        <div className="row mt-5">
                                            <div className="col-12">
                                                <h6 className="mb-4">DATOS DE LABORATORIO</h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 d-flex flex-column">
                                                <label className="p-0 m-0">TSC</label>
                                                <span className="mb-1">{item.tsc_promedio}</span>
                                                <label className="p-0 m-0">DRC</label>
                                                <span className="mb-1"><RenderNumber value={item.drc_promedio}/></span>
                                            </div>
                                            <div className="col-6 d-flex flex-column">
                                                <label className="p-0 m-0">VFA</label>
                                                <span className="mb-1">{item.vfa}</span>
                                                <label className="p-0 m-0">NH3</label>
                                                <span className="mb-1"><RenderNumber value={item.nh3} suffix=" ha"/></span>
                                            </div>
                                        </div>
                                    </Fragment>
                                )} */}
                            </Fragment>
                        )}
                        <div className="buttons-box mt-5">
                            <button type="button" className="btn btn-outline-dark mr-5 px-5" onClick={(e) => {
                                e.preventDefault();
                                this.regresar()
                            }}>REGRESAR</button>
                            {/*
                                // El Ingreso:
                                // - Puede editarse siempre que esté activa (no anulado y no finalizado)
                                // - Tiene Boleta de salida siempre que ya esté finalizado
                            */}
                            { item && item.id && (
                                <Fragment>
                                    { esEditable && !item.anulado && (
                                        <button type="button" className="btn btn-primary mr-5" disabled={item.anulado} onClick={(e) => {
                                            e.preventDefault();
                                            actualizarProductos();
                                        }}>GUARDAR</button>
                                    ) }
                                    { !item.anulado && (
                                        <Link className="btn btn-secondary mr-5 px-5" to={`/ingreso/${item.id}/boleta_ingreso`}>RE-IMPRIMIR<br/>(Boleta Ingreso)</Link>
                                    ) }
                                    { estaFinalizado && (
                                        <Link className="btn btn-secondary mr-5 px-5" to={`/ingreso/${item.id}/boleta_salida`}>RE-IMPRIMIR<br/>(Boleta Salida)</Link>
                                    ) }
                                    {/*<Link className="btn btn-outline-primary" to={`/finca/${item.id}/editar`}>ACTUALIZAR</Link>*/}
                                </Fragment>
                            ) }
                        </div>
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
