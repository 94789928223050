import React, {Component} from 'react';
import {Link} from 'react-router-dom';
// import './acciones.css';
import Swal from 'sweetalert2';
import { FACTURA_ESPECIAL, EXPORTADOR,PRODUCCION_INTERNA } from '../../../../utility/constants';

const eliminar_img = require('assets/img/icons/borrar.png');
const ver_img = require('assets/img/icons/ver.png');
const ver_bodega_img = require('assets/img/icons/ver_bodega.png');
const editar_img = require('assets/img/icons/editar.png');
const aspel_img = require('assets/img/icons/aspel.png');
const alerta = require('assets/img/icons/alerta_roja.png');

class Acciones extends Component {
    constructor(props) {
        super(props);
        this.editar = this.editar.bind(this);
        this.ver = this.ver.bind(this);
    }

    eliminar = (id) => {
        return () => {
            const SwalMod = Swal.mixin({
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-dark mr-5',
                  content: 'd-flex justify-content-center mb-4',
                  title: 'verde-secundario',
                  image: 'blink-danger',
                },
                buttonsStyling: false,
                focusConfirm: false,
                focusCancel: false,
            })
            SwalMod.fire({
                title: '¿ELIMINAR?',
                text: 'Esta acción no puede ser revertida',
                imageUrl: alerta,
                imageAlt: 'Alerta',
                showCancelButton: true,
                confirmButtonText: 'ELIMINAR',
                cancelButtonText: 'CANCELAR',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    this.props.eliminar(id);
                }
            });
        }
    };

    editar(id, row) {
        return () => {
            this.props.editar(id, row);
        };
    }

    ver(id, row) {
        return () => {
            this.props.ver(id, row);
        };
    }

    setIdEnviarAVaciado(id, row) {
        return () => {
            this.props.ver_bodega(id, row);
        };
    }

    render() {
        const { id, row, ver, verValidado, ver_bodega, editar, eliminar, generar_compra, generar_liquidacion, aspel, liquidacion, ver_recepcion_reporte, ver_liquidacion_reporte, dar_salida, setIdEnviarAVaciado, ver_numero_recepcion} = this.props;
        let redirect = "almacen";
        if (row && row.tipo && row.tipo > 10) {
            redirect = "pila"
        }
        let no__recepcion = "";
        if (row && ver_numero_recepcion && row.id_recepcion !== undefined && row.id_recepcion !== null) {
            no__recepcion = String(row.id_recepcion);
        }

        return (
            <div className="d-flex justify-content-center">
                {(!!liquidacion && (row.posee_factura || row.finca && row.finca.regimen_fiscal && (row.finca.regimen_fiscal === FACTURA_ESPECIAL || row.finca.regimen_fiscal === EXPORTADOR || row.finca.regimen_fiscal === PRODUCCION_INTERNA))) && (
                    <Link to={`${liquidacion}/${id}/`} className="px-2"><img src={ver_img} alt="Ver-01"
                    className="action_img"/></Link>
                )}
                {(liquidacion !== undefined && row.posee_factura !== true && (row.finca && row.finca.regimen_fiscal && row.finca.regimen_fiscal !== FACTURA_ESPECIAL) && (row.finca && row.finca.regimen_fiscal && row.finca.regimen_fiscal !== EXPORTADOR) && (row.finca && row.finca.regimen_fiscal && row.finca.regimen_fiscal !== PRODUCCION_INTERNA)) && (
                    <Link to={`${liquidacion}/${id}/`} className="px-2"><img src={editar_img} alt="Ver"
                    className="action_img"/></Link>
                )}
                {(aspel !== undefined && row.posee_factura === true) && (
                    <Link to={'/'} className="btn btn-outline-primary py-0 px-3" ><img src={aspel_img} alt="Ver" className="action_img"/>ASPEL</Link>
                )}
                {(eliminar !== undefined) && (
                    <a className="px-2" style={{cursor: "pointer", color: "#c4183c"}} onClick={this.eliminar(id)}><img
                        src={eliminar_img} alt="Eliminar" className="action_img"/></a>
                )}
                {(editar !== undefined) && ((typeof editar) === "string") && (
                    <Link className="text-warning" to={`${editar}/${id}/editar`}><img src={editar_img} alt="Editar"
                                                                                      className="action_img"/></Link>
                )}
                {(editar !== undefined) && ((typeof editar) !== "string") && (
                    <a className="text-warning" onClick={this.editar(id, row)}>
                        <img src={editar_img} alt="Editar" className="action_img"/>
                    </a>
                )}
                {(ver !== undefined) && ((typeof ver) === "string") && (
                    <Link to={`${ver}/${id}/`} className="px-2"><img src={ver_img} alt="Ver"
                                                                     className="action_img"/></Link>
                )}
                {(ver !== undefined) && ((typeof ver) !== "string") && (
                    <a className="text-warning" onClick={this.editar(id, row)}>
                        <img src={ver_img} alt="Editar" className="action_img"/>
                    </a>
                )}
                {(ver_recepcion_reporte !== undefined) && (
                    <Link to={`/ingreso/${row.ingreso.id}/`} className="px-2" ><img src={ver_img} alt="Ver" className="action_img"/></Link>
                )}
                {(ver_liquidacion_reporte !== undefined) && (
                    <Link to={`/liquidacion/${id}/`} className="px-2" ><img src={ver_img} alt="Ver" className="action_img"/></Link>
                )}
                {(ver_bodega !== undefined) && (
                    <Link to={`${redirect}/${id}/`} className="px-2" ><img src={ver_bodega_img} alt="Ver" className="action_img"/></Link>
                )}
                {(verValidado !== undefined) && (
                    <Link to={`${verValidado}/${id}/`} className="px-2" ><img src={ver_bodega_img} alt="Ver" className="action_img"/></Link>
                )}
                {(generar_compra !== undefined) && (
                    <Link to={`${generar_compra}/${row.id}/generar`} className="px-2" ><img src={editar_img} alt="Ver" className="action_img"/></Link>
                )}
                {(generar_liquidacion !== undefined) && (
                    <Link to={`${generar_liquidacion}/${id}/generar`} className="px-2" ><img src={editar_img} alt="Ver" className="action_img"/></Link>
                )}
                {(dar_salida !== undefined) && (
                    <div onClick={() => setIdEnviarAVaciado(id, no__recepcion)}>
                        <Link to={`canal/${id}/salida`} className="px-2" ><img src={ver_bodega_img} alt="Dar salida" className="action_img"/></Link>
                    </div>
                )}
            </div>
        );
    }
}

Acciones.propTypes = {};

export function standardActions(acciones) {
    return (cell, row) => {
        return (<Acciones id={cell} row={row} {...acciones}/>)
    };
}
