/* Hay un poco de redundancia en este componente, pero es para que sea mas facil de leer y entender :)*/
import React, {useState} from "react";
import { connect } from "react-redux";
import { api } from "api";
import { Field, FieldArray, formValueSelector, reduxForm } from "redux-form";
import {
    AsyncSelectFieldStrict,
    renderNumber,
    renderNumberKG,
    SelectField,
} from "../../../../Utils/renderField/renderField";
import LoadMask from "../../../../Utils/LoadMask/LoadMask";
import Alerta from "../../../../Utils/Alerta/Alerta";
import { RenderKilogramo } from "../../../../Utils/renderField/renderReadField";
import { Fragment } from "react";
import { actions } from "../../../../../../redux/modules/canal";
import { Link } from "react-router-dom";
import CardEmpty from "../../../../Utils/Cards/CardEmpty";
import { store } from "../../../../../../router";

const pinchiTrocaAlv = require("../../../../../../../assets/img/icons/despachos_activo.png");

const getFincas = (search) => {
    return api.get("finca", {search}).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getVehiculos = (search) => {
    return api
        .get("vehiculo", { search })
        .then((data) => {
            if (data) {
                const datos = data.results;
                datos.forEach((dato) => {
                    dato.nombre = `${dato.tipo_vehiculo.nombre} ${dato.placa}`;
                });
                return datos;
            }
            return [];
        })
        .catch(() => {
            return [];
        });
};
let getPilotos = (search) => {
    return api
        .get("piloto", { search })
        .then((data) => {
            if (data) return data.results;
            return [];
        })
        .catch(() => {
            return [];
        });
};

const getStoreRedux = () => store.getState();

export let TableFormSalida = (props) => {
    const { recipientes, id_materia } = props.initialValues;

    const getAlmacenesParaVaciado = () => {
        let params = {};
        if (id_materia && id_materia.id && id_materia.id > 0) {
            params.id_materia = id_materia.id;
        }
        return api
            .get("canal/get_almacenes_para_vaciado", params)
            .then((response) => {
                return response ? response : [];
            })
            .catch(() => []);
    };
    const [accionRealizada, setAccionRealizada] = useState(false);
    const [enviarAccionRealizada, setEnviarAccionRealizada] = useState(false);
    const {
        loader_form, // Estado del loader del formulario
        salidaABodega, // Funcion Submit del Formulario
        peso_manual = true, // Si el peso se ingresa manualmente o se toma de la bascula
        valuesFormData, // Valores del formulario
        setPreviousStep, // Funcion para ir al paso anterior
        setNextStep, // Funcion para ir al siguiente paso
        step_form, // Paso actual del formulario ( 0 || 1 )
        recepcion_canal, // Los datos de recepcion guardados en la BD
        guardarEntrada,
        peso
    } = props;
    let nombre_materia =
        valuesFormData &&
        valuesFormData.id_materia &&
        valuesFormData.id_materia.nombre
            ? valuesFormData.id_materia.nombre
            : "";

    let peso_diferencia_value = 0;
    let peso_inicial_value_step_0 = 0;
    let peso_tara_value = 0;
    let peso_neto_value = 0;

    peso_diferencia_value =
        valuesFormData && valuesFormData.peso_bascula
            ? valuesFormData.peso_bascula
            : 0;
    peso_inicial_value_step_0 =
        valuesFormData && valuesFormData.peso_bascula_step_0
            ? valuesFormData.peso_bascula_step_0
            : 0;
    peso_tara_value =
        valuesFormData && valuesFormData.peso_bascula
            ? valuesFormData.peso_bascula
            : 0;
    peso_neto_value = recepcion_canal.peso_bruto - peso_tara_value;

    let is_form_step_0_valid = false;
    let is_form_step_1_valid = false;
    const state = getStoreRedux();
    const syncErrorsForm = state.form.TableFormSalida.syncErrors;
    // syncErrorsForm: son los errores capturados por redux-form en el validate
    if (
        !syncErrorsForm.vehiculo &&
        !syncErrorsForm.piloto &&
        !syncErrorsForm.peso_bascula_step_0 &&
        !syncErrorsForm.peso_ingreso &&
        !syncErrorsForm.finca_ingreso
    ) {
        is_form_step_0_valid = true;
    }
    if (
        !syncErrorsForm.peso_inicial &&
        !syncErrorsForm.peso_bascula &&
        !syncErrorsForm.peso_final &&
        !syncErrorsForm.id_materia &&
        !syncErrorsForm.id_almacen &&
        !syncErrorsForm.peso_tara &&
        !syncErrorsForm.peso_neto
    ) {
        is_form_step_1_valid = true;
    }

    return (
        <LoadMask loading={false} blur>
            <form>
                {recepcion_canal.peso_bruto === 0 && (
                    <Fragment>
                        <div className="col-12 p-0">
                            <div className="d-flex justify-content-center align-items-baseline">
                                <h6 className="mb-4 mr-5">
                                    PESAJE DE PESO BRUTO INGRESO &nbsp;{" "}
                                    <img
                                        src={pinchiTrocaAlv}
                                        style={{ width: "30px" }}
                                    />
                                </h6>
                            </div>
                            <div className="row">
                                <div className="col-6 ">
                                    <CardEmpty>
                                        <div className="col-12">
                                            <div className="col-12">
                                                <span className="bold m-0">
                                                    PESO BRUTO INGRESO
                                                </span>
                                            </div>
                                            <div className="col-12">
                                                <Field
                                                    name={`peso_ingreso`}
                                                    type="number"
                                                    decimalScale={0}
                                                    suffix=" kgs"
                                                    disabled
                                                    component={renderNumber}
                                                    parse={(val) =>
                                                        parseFloat(val)
                                                    }
                                                    input={{
                                                        value: peso_inicial_value_step_0,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="col-12">
                                                <span className="bold m-0">
                                                    PESO BASCULA
                                                </span>
                                            </div>
                                            <div className="col-12">
                                                {peso_manual === true && (
                                                    <Field
                                                        name={`peso_bascula_step_0`}
                                                        type="number"
                                                        decimalScale={0}
                                                        suffix=" Kg"
                                                        component={renderNumber}
                                                        parse={(val) =>
                                                            parseFloat(val)
                                                        }
                                                    />
                                                )}
                                                {peso_manual === false && (
                                                    <Field
                                                        name={`peso_bascula_step_0`}
                                                        type="text"
                                                        component={renderNumber}
                                                        decimalScale={0}
                                                        suffix=" Kg"
                                                        disabled={!peso_manual}
                                                        input={{ value: peso }}
                                                    />
                                                )}
                                            </div>
                                            &nbsp;&nbsp;&nbsp;
                                            <div className="col-8 offset-2">
                                                <Alerta
                                                    noMarginBottom={true}
                                                    texto={
                                                        peso_manual
                                                            ? `El peso se está tomando manualmente. Para tomarlo de báscula vaya a Configuración.`
                                                            : `El peso se está tomando automaticamente de Báscula. Para ingresarlo manualmente se debe ir a Configuración.`
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </CardEmpty>
                                </div>

                                <div className="col-6 ">
                                    <div className="col-md-12 p-0">
                                        <div className="col-12">
                                            <span className="m-0 bold">
                                                VEHÍCULO
                                            </span>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field
                                                valueKey="id"
                                                name={`vehiculo`}
                                                labelKey="nombre"
                                                label="nombre"
                                                component={
                                                    AsyncSelectFieldStrict
                                                }
                                                loadOptions={getVehiculos}
                                            />
                                        </div>
                                    </div>{" "}
                                    <div className="col-md-12 p-0">
                                        <div className="col-12">
                                            <span className="m-0 bold">
                                                PILOTO
                                            </span>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field
                                                valueKey="id"
                                                name={`piloto`}
                                                labelKey="nombre"
                                                label="nombre"
                                                component={
                                                    AsyncSelectFieldStrict
                                                }
                                                loadOptions={getPilotos}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12 p-0">
                                        <div className="col-12">
                                            <span className="m-0 bold">
                                                FINCA INGRESO
                                            </span>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field
                                                valueKey="id"
                                                name={`finca_ingreso`}
                                                labelKey="nombre"
                                                label="nombre"
                                                component={AsyncSelectFieldStrict}
                                                loadOptions={getFincas}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row col-sm-12 p-0 justify-content-center mt-5">
                            <Link
                                className="btn btn-secondary"
                                type="button"
                                to="/canal"
                            >
                                Regresar
                            </Link>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                                className="btn btn-secondary"
                                type="submit"
                                disabled={!is_form_step_0_valid || enviarAccionRealizada}
                                onClick={props.handleSubmit((data) => {
                                    if (is_form_step_0_valid) {
                                        setEnviarAccionRealizada(true); // Marca la acción de "Guardar" como realizada
                                        guardarEntrada(data);
                                    }
                                })
                                }
                            >
                                Guardar
                            </button>
                        </div>
                    </Fragment>
                )}
                {recepcion_canal.peso_bruto > 0 && (
                    <Fragment>
                        <div className=" col-sm-12 p-0">
                            <div className="d-flex justify-content-center align-items-baseline">
                                <h6 className="mb-4 mr-5">
                                    DATOS DE PESO EN BASCULA
                                </h6>
                                <h6 className="gris bold text-uppercase">
                                    {nombre_materia}
                                </h6>
                            </div>
                            <div className="row col-12 ">
                                <div className="col-12 col-md-3">
                                    <div className="col-12">
                                        <span className="bold m-0">
                                            PESO BRUTO (ACTUAL)
                                        </span>
                                    </div>
                                    <div className="col-12">
                                        <Field
                                            name={`peso_inicial`}
                                            type="number"
                                            disabled
                                            decimalScale={0}
                                            suffix=" kgs"
                                            component={renderNumber}
                                            parse={(val) => parseFloat(val)}
                                            input={{
                                                value: recepcion_canal.peso_bruto,
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="col-12">
                                        <span className="bold m-0">
                                            PESO TARA
                                        </span>
                                    </div>
                                    <div className="col-12">
                                        <Field
                                            name={`peso_tara`}
                                            suffix=" kgs"
                                            component={renderNumber}
                                            parse={(val) => parseFloat(val)}
                                            disabled
                                            input={{ value: peso_tara_value }} // PESO BRUTO - PESO TARA
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="col-12">
                                        <span className="bold m-0">
                                            PESO NETO
                                        </span>
                                    </div>
                                    <div className="col-12">
                                        <Field
                                            name={`peso_neto`}
                                            type="number"
                                            decimalScale={0}
                                            disabled={true}
                                            input={{ value: peso_neto_value }} // PESO BRUTO - PESO TARA
                                            suffix=" kgs"
                                            component={renderNumber}
                                            parse={(val) => parseFloat(val)}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-3">
                                    <div className="col-12">
                                        <span className="bold m-0">
                                            DIFERENCIA DE PESO
                                        </span>
                                    </div>
                                    <div className="col-12">
                                        <span className="bold verde">
                                            <RenderKilogramo
                                                value={
                                                    peso_diferencia_value || 0
                                                }
                                            />
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column justify-content-end align-items-center w-100  mt-4 mb-5">
                                <div className="w-25">
                                    <div className="">
                                        <span className="bold m-0">
                                            PESO BASCULA
                                        </span>
                                    </div>
                                    <div className="">
                                        {peso_manual === true && (
                                            <Field
                                                name={`peso_bascula`}
                                                type="number"
                                                decimalScale={0}
                                                suffix=" Kg"
                                                component={renderNumber}
                                                parse={(val) => parseFloat(val)}
                                            />
                                        )}
                                        {peso_manual === false && (
                                            <Field
                                                name={`peso_bascula`}
                                                type="text"
                                                component={renderNumber}
                                                decimalScale={0}
                                                suffix=" Kg"
                                                disabled={!peso_manual}
                                                input={{ value: peso }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className=" col-sm-12 form-group np-r p-0">
                                <div className="react-bs-container-header table-header-wrapper">
                                    <table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th style={{ width: "5%" }} />
                                                <th
                                                    style={{ width: "13%" }}
                                                    className="text-xs "
                                                >
                                                    MATERIA PRIMA
                                                </th>
                                                <th
                                                    style={{ width: "14%" }}
                                                    className="text-xs"
                                                >
                                                    DESTINO
                                                </th>
                                                <th
                                                    style={{ width: "13%" }}
                                                    className="text-xs "
                                                >
                                                    PESO DESTINO
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                                <div>
                                    <table className="table table-sm  m-0 table-striped">
                                        <tbody>
                                            <td
                                                className="sin-borde-top bold"
                                                style={{ width: "13%" }}
                                            >
                                                <Field
                                                    name={`id_materia`}
                                                    component={SelectField}
                                                    options={recipientes}
                                                    valueKey="id"
                                                    labelKey="nombre"
                                                    label="nombre"
                                                    disabled
                                                    className="form-control"
                                                />
                                            </td>
                                            <td
                                                className="sin-borde-top bold"
                                                style={{ width: "14%" }}
                                            >
                                                {id_materia &&
                                                id_materia.id &&
                                                id_materia.id > 0 ? (
                                                    <Field
                                                        valueKey="id"
                                                        labelKey="nombre"
                                                        label="nombre"
                                                        name={`id_almacen`}
                                                        component={
                                                            AsyncSelectFieldStrict
                                                        }
                                                        loadOptions={
                                                            getAlmacenesParaVaciado
                                                        }
                                                        className="form-control"
                                                    />
                                                ) : null}
                                            </td>
                                            <td
                                                style={{ width: "13%" }}
                                                className="sin-borde-top text-center"
                                            >
                                                <Field
                                                    name={`peso_final`}
                                                    type="number"
                                                    decimalScale={0}
                                                    disabled
                                                    input={{ value: peso_neto_value }}
                                                    suffix=" kgs"
                                                    component={renderNumber}
                                                    parse={() =>
                                                        parseFloat(val)
                                                    }
                                                />
                                            </td>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="row col-sm-12 p-0 justify-content-center mt-5">
                            <Link
                                className="btn btn-secondary"
                                type="button"
                                to="/canal"
                            >
                                Regresar
                            </Link>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <button
                                className="btn btn-secondary"
                                type="submit"
                                disabled={!is_form_step_1_valid || accionRealizada}
                                onClick={props.handleSubmit((data) => {
                                    if (is_form_step_1_valid) {
                                        setAccionRealizada(true);
                                        salidaABodega(data);
                                    }
                                })}
                            >
                                Guardar y Enviar
                            </button>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <Alerta
                                noMarginBottom={true}
                                texto={
                                    peso_manual
                                        ? `El peso se está tomando manualmente. Para tomarlo de báscula vaya a Configuración.`
                                        : `El peso se está tomando automaticamente de Báscula. Para ingresarlo manualmente se debe ir a Configuración.`
                                }
                            />
                        </div>
                    </Fragment>
                )}
            </form>
        </LoadMask>
    );
};

TableFormSalida = reduxForm({
    form: "TableFormSalida",
    validate: (data) => {
        /* Los initialValues se pasan desde el modulo de redux form en canal :) */

        // ##### Formulario paso 1

        //Validar vehiculo
        let es_vechiculo_valido = true;
        if (data.vehiculo) {
            if (data.vehiculo.id <= 0) {
                es_vechiculo_valido = false;
            }
        } else {
            es_vechiculo_valido = false;
        }

        //Validar piloto
        let es_piloto_valido = true;
        if (data.piloto) {
            if (data.piloto.id <= 0) {
                es_piloto_valido = false;
            }
        } else {
            es_piloto_valido = false;
        }

        //Validar finca
        let es_finca_valido = true;
        if (data.finca_ingreso) {
            if (data.finca_ingreso.id <= 0) {
                es_finca_valido = false;
            }
        } else {
            es_finca_valido = false;
        }
        //Validar peso bacula para step 0
        let es_peso_bacula_step_0_valido = true;
        if (data.peso_bruto > 0) {
            es_peso_bacula_step_0_valido = true
        } else if (data.peso_bascula_step_0 == 0) {
            es_peso_bacula_step_0_valido = false;
        } else if (data.peso_bascula_step_0 < 0) {
            es_peso_bacula_step_0_valido = false;
        } else if (!!!data.peso_bascula_step_0) {
            es_peso_bacula_step_0_valido = false;
        } else if (isNaN(data.peso_bascula_step_0)) {
            es_peso_bacula_step_0_valido = false;
        }

        // Validar peso bruto ingreso
        let es_peso_ingreso_valido = true;
        if (data.peso_ingreso == 0) {
            es_peso_ingreso_valido = false;
        } else if (data.peso_ingreso < 0) {
            es_peso_ingreso_valido = false;
        } else if (!!!data.peso_ingreso) {
            es_peso_ingreso_valido = false;
        }

        // #### Formulario paso 2

        // Validar peso bascula y peso tara
        let es_peso_bacula_valido = true;
        let message_peso_bascula = "Campo requerido";
        if (data.peso_bascula == 0) {
            es_peso_bacula_valido = false;
        } else if (data.peso_bascula < 0) {
            es_peso_bacula_valido = false;
        } else if (!!!data.peso_bascula) {
            es_peso_bacula_valido = false;
        } else if (isNaN(data.peso_bascula)) {
            es_peso_bacula_valido = false;
        } else if (data.peso_bascula >= data.peso_bruto) {
            message_peso_bascula =
                "El peso de la báscula debe ser menor al peso bruto ingresado";
            es_peso_bacula_valido = false;
        }

        // Validar peso neto y peso final
        let peso_neto = data.peso_bruto - data.peso_bascula;
        let es_peso_final_valido = true;
        let message_peso_final = "Campo requerido";
        if (data.peso_bascula == 0) {
            es_peso_final_valido = false;
        } else if (data.peso_bascula < 0) {
            es_peso_final_valido = false;
        } else if (!!!data.peso_bascula) {
            es_peso_final_valido = false;
        } else if (isNaN(data.peso_bascula)) {
            es_peso_final_valido = false;
        }

        //Validar id_materia
        let es_materia_valida = true;
        if (data.id_materia) {
            if (data.id_materia.id <= 0) {
                es_materia_valida = false;
            }
        } else {
            es_materia_valida = false;
        }

        //Validad id_almacen
        let es_almacen_valido = true;
        if (data.id_almacen) {
            if (data.id_almacen.id <= 0) {
                es_almacen_valido = false;
            }
        } else {
            es_almacen_valido = false;
        }

        //Validar peso tara
        let es_peso_tara_valido = true;
        let message_peso_tara = "Campo requerido";
        if (data.peso_bascula) {
            if (data.peso_bascula >= data.peso_bruto) {
                es_peso_tara_valido = false;
                message_peso_tara = "El peso tara debe ser menor al peso bruto";
            }

            if (data.peso_bascula <= 0) {
                es_peso_tara_valido = false;
                message_peso_tara = "El peso tara debe ser mayor a 0";
            }
        } else {
            es_peso_tara_valido = false;
            message_peso_tara = "El peso tara debe ser mayor a 0";
        }

        //Validamos peso neto
        let es_peso_neto_valido = true;
        let message_peso_neto = "Campo requerido";
        if (peso_neto >= 0) {
            if (peso_neto >= data.peso_bruto) {
                es_peso_neto_valido = false;
                message_peso_neto = "El peso neto debe ser menor al peso bruto";
            }
        } else {
            es_peso_neto_valido = false;
            message_peso_neto = "El peso neto debe ser mayor a 0";
        }

        return {
            //Formulario paso 1
            vehiculo: !es_vechiculo_valido ? "Campo requerido" : undefined,
            piloto: !es_piloto_valido ? "Campo requerido" : undefined,
            finca_ingreso: !es_finca_valido ? "Campo requerido" : undefined,
            peso_bascula_step_0: !es_peso_bacula_step_0_valido
                ? "Campo requerido"
                : undefined,
            peso_ingreso: !es_peso_bacula_step_0_valido
                ? "Campo requerido"
                : undefined,
            //Formulario paso 2
            peso_inicial: !es_peso_bacula_step_0_valido
                ? "Campo requerido"
                : undefined,
            peso_bascula: !es_peso_bacula_valido
                ? message_peso_bascula
                : undefined,
            peso_final: !es_peso_final_valido ? message_peso_final : undefined,
            id_materia: !es_materia_valida ? "Campo requerido" : undefined,
            id_almacen: !es_almacen_valido ? "Campo requerido" : undefined,
            peso_tara: !es_peso_tara_valido ? message_peso_tara : undefined,
            peso_neto: !es_peso_neto_valido ? message_peso_neto : undefined,
        };
    },
})(TableFormSalida);

/* Con este selector podras tomar cualquier valor del formulario,
o bien puedes hacerlo con la funcion getStoreRedux que te retornara el estado completo
de toda la aplicacion  :)
*/
const selector = formValueSelector("TableFormSalida");

const mapStateToProps = (state, props) => {
    const form = state.form.TableFormSalida;
    const canal = state.canal;
    let peso = 0

    function getNumbersInString(string) {
        var tmp = string.split("");
        var map = tmp.map((current) => {
            if (!isNaN(parseInt(current))) {
                return current;
            }
        });
        var numbers = map.filter((value) => {
            return value != undefined;
        });
        return numbers.join("");
    }

    /*
    La funcion que se queda pidiendo la data de la bascula enviara
    la informacion a peso_bascula o peso_bascula_step_0 dependiendo del step_form que se encuentre
    en el redux store "canal"
    */

    if (canal.peso_bascula) {
        if (canal.peso_bascula) {
            peso = getNumbersInString(canal.peso_bascula);
            if (form && form.values) {
                form.values.peso_bascula = peso;
            }
        }
    }

    if (canal.peso_bascula_step_0) {
        if (canal.peso_bascula_step_0) {
            peso = getNumbersInString(canal.peso_bascula_step_0);
            if (form && form.values) {
                form.values.peso_bascula_step_0 = peso;
            }
        }
    }

    return {
        ...state.canal,
        me: state.login.me,
        peso: peso,
        valuesFormData: form ? form.values : {},
    };
};

const mapDispatchToProps = {
    setPreviousStep: actions.setPreviousStep,
    setNextStep: actions.setNextStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(TableFormSalida);