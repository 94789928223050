import React from 'react';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm } from 'redux-form';
import {
    renderField,
    SelectField,
    SelectMunicipioField,
    renderNumber,
} from '../../../Utils/renderField/renderField';
import {DEPARTAMENTOS} from 'Utils/departamentos_municipios'
import { tipos_medida_finca } from '../../../../../utility/constants';


let PasoDosForm = props => {
    const { handleSubmit, previousStep, municipios } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <h6 className="ml-4 mb-4">UBICACIÓN GEOGRÁFICA DE FINCA</h6>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="lat" className="m-0">LATITUD</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="lat" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="long" className="m-0">LONGITUD</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="long" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end">
                    <div className="col-md-6 p-0">
                        <div className="row col-md-12 p-0 m-0 d-flex flex-wrap align-items-end">
                            <div className="col-7 col-md-7 p-0 mr-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="area_cultivada" className="m-0">ÁREA CULTIVADA</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field name="area_cultivada" component={renderNumber} type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="col-5 col-md-5 p-0">
                                <div className="col-md-12 col-sm-12 pl-3 pl-md-0">
                                    <label htmlFor="area_cultivada" className="m-0">MEDIDA</label>
                                </div>
                                <div className="col-md-12 form-group pl-3 pl-md-0">
                                    <Field name="medida_area_cultivada" component={SelectField} options={tipos_medida_finca} className="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="row col-md-12 p-0 m-0 d-flex flex-wrap align-items-end">
                            <div className="col-7 col-md-7 p-0 mr-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="area_finca" className="m-0">ÁREA TOTAL</label>
                                </div>
                                <div className="col-md-12 form-group">
                                    <Field name="area_finca" component={renderNumber} type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="col-5 col-md-5 p-0">
                                <div className="col-md-12 col-sm-12 pl-3 pl-md-0">
                                    <label htmlFor="area_cultivada" className="m-0">MEDIDA</label>
                                </div>
                                <div className="col-md-12 form-group pl-3 pl-md-0">
                                    <Field name="medida_area_finca" component={SelectField} options={tipos_medida_finca} className="form-control" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="mm_lluvia" className="m-0">MM. DE LLUVIA</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="mm_lluvia" component={renderNumber} suffix=" mm" type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="departamento" className="m-0">DEPARTAMENTO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="departamento" component={SelectField} options={DEPARTAMENTOS} is_departamentos className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="municipio" className="m-0">MUNICIPIO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="municipio" component={SelectMunicipioField} options={municipios} className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="descripcion_ubicacion" className="m-0">ALDEA / DESCRIPCIÓN</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="descripcion_ubicacion" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <button className="btn btn-outline-dark mr-5" onClick={previousStep}>ANTERIOR</button>
                    <button type="submit" className="btn btn-primary">SIGUIENTE</button>
                </div>
            </div>
        </form>
    )
};
PasoDosForm = reduxForm({
    form: 'fincaForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
        })
    }
})(PasoDosForm);

export default PasoDosForm
