import { round } from "lodash";
import React, { Fragment } from "react";
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
} from "recharts";

const ViewGrafica = ({ data, media, fecha_analisis }) => {
    if (!data || !media || !fecha_analisis || !data.length) {
        return null;
    }

    const minYValue = Math.min(...data.map((d) => d.frecuencia));
    const minXValue = Math.min(...data.map((d) => d.limite));
    const maxXValue = Math.max(...data.map((d) => d.limite));

    const closestPoint = data.reduce((prev, curr) => {
        return Math.abs(curr.limite - media) < Math.abs(prev.limite - media)
            ? curr
            : prev;
    });

    return (
        <Fragment>
            <div className="title-fecha px-2">
                Fecha: {fecha_analisis ? fecha_analisis : "No hay fecha"}
            </div>
            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    width={500}
                    height={300}
                    data={data}
                    margin={{
                        top: 20,
                        right: 50,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="limite"
                        type="number"
                        domain={[minXValue, maxXValue]}
                    />
                    <YAxis domain={[minYValue, "dataMax"]} />
                    <Tooltip
                        content={({ active, payload, label }) => {
                            if (active && payload && payload.length) {
                                return (
                                    <div className="custom-tooltip " style={{
                                        backgroundColor: "#fff",
                                        border: "1px solid #999",
                                        padding: "9px",
                                        borderRadius: "5px",
                                    }}>
                                        <p className="label p-0 m-0">
                                            {`Intervalo (Li - Ls): ${payload[0].payload.intervalo}`}
                                            <br />
                                            {`DRC promedio: ${payload[0].payload.drc_promedio}`}
                                            <br />
                                            {`Frecuencia: ${payload[0].payload.frecuencia}`}
                                            <br />
                                            {`Frecuencia acumulada: ${payload[0].payload.frecuencia_acumulada}`}
                                            <br />
                                            {`Frecuencia relativa: ${payload[0].payload.frecuencia_relativa} ( ${round(payload[0].payload.frecuencia_relativa * 100, 4) || 0 }% )`}
                                        </p>
                                        
                                    </div>
                                );
                            }
                            return null;
                        }}
                    />
                    <Legend />
                    <ReferenceLine
                        x={media}
                        ifOverflow={true}
                        stroke="red"
                        label={`Media: ${media}`}
                        strokeWidth={2}
                    />
                    <Line
                        type="monotone"
                        dataKey="gauss"
                        stroke="#8884d8"
                        strokeWidth={3}
                        activeDot={{ r: 5, fill: "#554fc7" }}
                    />
                    <Line
                        type="monotone"
                        dataKey="frecuencia_relativa"
                        stroke="#FF8000"
                        strokeWidth={3}
                        activeDot={{ r: 5, fill: "#554fc7" }}
                    />
                </LineChart>
            </ResponsiveContainer>
        </Fragment>
    );
};

export { ViewGrafica };
