import { connect } from 'react-redux';
import {actions} from '../../../../../../redux/modules/bodega';
import AlmacenSalida from './AlmacenSalida';

const mstp = state => {
    return {...state.bodegas}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(AlmacenSalida)
