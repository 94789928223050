import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import Card from "Utils/Cards/Card";
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import LoadMask from 'Utils/LoadMask/LoadMask';
import TabBar from "rc-tabs/lib/TabBar";
import TabContent from "rc-tabs/lib/TabContent";
import Tabs, {TabPane} from "rc-tabs";
import Form from './RegistroForm';
import EditarRegistroForm from './RegistroForm';
import EditarAlmacenForm from './AlmacenForm';
import EditarCierreForm from './CierreForm';

import Swal from 'sweetalert2';
const alerta = require('assets/img/icons/alerta_roja.png');

export default class ProduccionCrear extends Component {
    state = {
        editar: false,
    };
    componentWillMount(){
        if(this.props.match.params.id){
            this.props.leer(this.props.match.params.id);
            this.setState({editar: true});
        }
        this.props.numeroProduccion();
    }
    actualizar = (data) =>{
        this.props.editar(this.props.match.params.id, data)
    };

    cerrarProduccion = (data) => {
        this.props.cerrar_produccion(this.props.item.id);
    };

    confirmarEditar = (data) => {
        const SwalMod = Swal.mixin({
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-dark mr-5',
              content: 'd-flex justify-content-center mb-4',
              title: 'verde-primario',
              image: 'blink-danger',
            },
            buttonsStyling: false,
            focusConfirm: false,
            focusCancel: false,
        })
        SwalMod.fire({
            title: '¡ADVERTENCIA!',
            text: '¿Realmente desea guardar? Esta acción no puede ser revertida',
            imageUrl: alerta,
            imageAlt: 'Alerta',
            showCancelButton: true,
            confirmButtonText: 'SI',
            cancelButtonText: 'NO',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.editar(this.props.match.params.id, data);
            }
        });
    };

    render() {
        const { crear, editar } = this.props;
        const { updateData, loader } = this.props;
        const { setTabForm, tab_form } = this.props;
        // const { data, loader, page } = this.props;
        return (
            <div className="mb-4 col-12">
                {this.state.editar === false && (
                    <CardForm
                        icon="produccion_activo"
                        titulo="PRODUCCIÓN"
                        subtitulo="Registro"
                        noBorder
                        col="12"
                    >
                        <LoadMask loading={loader} blur>
                            <Form onSubmit={crear} {...this.props}/>
                        </LoadMask>
                    </CardForm>
                )}
                {this.state.editar && (
                    <Card
                        icon="produccion_activo"
                        titulo="PRODUCCIÓN"
                        subtitulo="Editar"
                        noBorder
                        col="12"
                    >
                        <Tabs
                            activeKey={tab_form}
                            tabBarPosition="top"
                            onChange={tab_form =>{setTabForm(tab_form)}}
                            renderTabBar={() => <TabBar />}
                            renderTabContent={() => <TabContent />}
                        >
                            <TabPane tab="REGISTRO" key="REGISTRO">
                                <CardEmpty noShadow>
                                    <LoadMask loading={loader} blur>
                                        <EditarRegistroForm
                                            onSubmit={this.actualizar}
                                            updateData={updateData}
                                            editando={true}
                                            {...this.props}
                                        />
                                    </LoadMask>
                                </CardEmpty>
                            </TabPane>
                            <TabPane tab="ALMACÉN" key="ALMACEN">
                                <CardEmpty noShadow>
                                    <LoadMask loading={loader} blur>
                                        <EditarAlmacenForm
                                            onSubmit={this.confirmarEditar}
                                            updateData={updateData}
                                            editando={true}
                                            {...this.props}
                                        />
                                    </LoadMask>
                                </CardEmpty>
                            </TabPane>
                            <TabPane tab="CIERRE" key="CIERRE">
                                <CardEmpty noShadow>
                                    <LoadMask loading={loader} blur>
                                        <EditarCierreForm
                                            onSubmit={this.actualizar}
                                            updateData={updateData}
                                            editando={true}
                                            cerrarProduccion={this.cerrarProduccion}
                                            {...this.props}
                                        />
                                    </LoadMask>
                                </CardEmpty>
                            </TabPane>
                        </Tabs>
                    </Card>
                )}

        </div>
        )
    }
}
