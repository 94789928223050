import React, { Component } from 'react';
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './VehiculoForm';

export default class VehiculoCrear extends Component {
    state = {
        editar: false,
    };

    componentWillMount() {
        const { match, leer } = this.props;
        if (match.params.id) {
            leer(match.params.id);
            this.setState({ editar: true });
        }
    }

    actualizar = (data) => {
        const { editar, match } = this.props;
        editar(match.params.id, {
            ...data,
            tipo_vehiculo: data.tipo_vehiculo.id,
            transportista: data.transportista ? data.transportista.id : null,
        });
    };

    crear = (data) => {
        const { crear } = this.props;
        crear({
            ...data,
            tipo_vehiculo: data.tipo_vehiculo.id,
            transportista: data.transportista ? data.transportista.id : null,
        });
    };

    render() {
        const { updateData, loader, tipo_vehiculo } = this.props;
        const { editar } = this.state;

        return (
            <div className="mb-4 col-12">
                <CardForm
                    icon="configuraciones_activo"
                    titulo="VEHÍCULO"
                    subtitulo="Nuevo"
                >
                    <LoadMask loading={loader} blur>
                        {editar ? (
                            <Form
                                updateData={updateData}
                                onSubmit={this.actualizar}
                                tipo_vehiculo={tipo_vehiculo}
                                editando
                            />
                        ) : (
                            <Form
                                onSubmit={this.crear}
                                tipo_vehiculo={tipo_vehiculo}
                            />
                        )}
                    </LoadMask>
                </CardForm>
            </div>
        );
    }
}
