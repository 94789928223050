import { createAction, handleActions } from 'redux-actions';
import { api } from 'api';
import {initialize as initializeForm} from 'redux-form';
import {NotificationManager} from 'react-notifications';
import {push} from 'react-router-redux';
import moment from 'moment';
import { PAGINATION_SIZE_PER_PAGE, FLETE_INTERNO_COSTO_POR_KG } from '../../../utility/constants';

const LOADER = 'LOADER_LIQUIDACIONES';
const FORCE_UPDATE_DATA = 'LIQUIDACIONES_FORCE_UPDATE_DATA';
const DATA = 'DATA_LIQUIDACIONES';
const ITEM_DATA = 'ITEM_LIQUIDACIONES';
const PAGE = 'PAGE_LIQUIDACIONES';
const ORDERING = 'ORDERING_LIQUIDACIONES';
const SEARCH = 'SEARCH_LIQUIDACIONES';
const TAB_LIQUIDACIONES = 'TAB_LIQUIDACIONES';
// Liquidaciones pendientes
const FILTRO_FECHA_INICIO_LIQ = 'LIQUIDACIONES_PENDIENTES_FILTRO_FECHA_INICIAL';
const FILTRO_FECHA_FINAL_LIQ = 'LIQUIDACIONES_PENDIENTES_FILTRO_FECHA_FINAL';
const FILTRO_FINCA_LIQ = 'LIQUIDACIONES_PENDIENTES_FILTRO_FINCA_ID';
const FILTRO_PENDIENTES_FINCA_TIPO_PAGO = 'LIQUIDACIONES_PENDIENTES_FILTRO_TIPO_PAGO_ID';
const FILTRO_PENDIENTES_FINCA_PERFIL = 'LIQUIDACIONES_PENDIENTES_FILTRO_PERFIL_FINCA_ID';
const FILTRO_PENDIENTES_FINCA_REGIMEN_FISCAL = 'LIQUIDACIONES_PENDIENTES_FILTRO_REGIMEN_FISCAL_ID';
const LIQUIDACIONES_PENDIENTES_FORCE_UPDATE_DATA = 'LIQUIDACIONES_PENDIENTES_FORCE_UPDATE_DATA';
const LIQUIDACIONES_PENDIENTES_LOADER = 'LIQUIDACIONES_PENDIENTES_LOADER';
const LIQUIDACIONES_PENDIENTES_PAGE = 'LIQUIDACIONES_PENDIENTES_PAGE';
const LIQUIDACIONES_PENDIENTES_DATA = 'LIQUIDACIONES_PENDIENTES_DATA';
const LIQUIDACIONES_PENDIENTES_TOTALES_LOADER = 'LIQUIDACIONES_PENDIENTES_TOTALES_LOADER';
const LIQUIDACIONES_PENDIENTES_TOTALES_DATA = 'LIQUIDACIONES_PENDIENTES_TOTALES_DATA';
// Listado de compras en liquidaciones pendientes
const LOADER_COMPRAS = 'LOADER_LIQUIDACIONES_COMPRAS';
const PAGE_COMPRAS = 'LIQUIDACIONES_PAGE_COMPRAS';
const DATA_COMPRAS = 'LIQUIDACIONES_DATA_COMPRAS';
// Liquidaciones finalizadas
const FILTRO_FECHA_INICIO_LIQ_LIQUIDADAS = 'LIQUIDACIONES_LIQUIDADAS_FILTRO_FECHA_INICIAL';
const FILTRO_FECHA_FINAL_LIQ_LIQUIDADAS = 'LIQUIDACIONES_LIQUIDADAS_FILTRO_FECHA_FINAL';
const FILTRO_FINCA_LIQ_LIQUIDADAS = 'LIQUIDACIONES_LIQUIDADAS_FILTRO_FINCA_ID';
const FILTRO_LIQUIDADAS_FINCA_TIPO_PAGO = 'LIQUIDACIONES_LIQUIDADAS_FILTRO_TIPO_PAGO_ID';
const FILTRO_LIQUIDADAS_FINCA_PERFIL = 'LIQUIDACIONES_LIQUIDADAS_FILTRO_PERFIL_FINCA_ID';
const FILTRO_LIQUIDADAS_FINCA_REGIMEN_FISCAL = 'LIQUIDACIONES_LIQUIDADAS_FILTRO_REGIMEN_FISCAL_ID';
const LIQUIDACIONES_LIQUIDADAS_FORCE_UPDATE_DATA = 'LIQUIDACIONES_LIQUIDADAS_FORCE_UPDATE_DATA';
const LIQUIDACIONES_LIQUIDADAS_LOADER = 'LIQUIDACIONES_LIQUIDADAS_LOADER';
const LIQUIDACIONES_LIQUIDADAS_PAGE = 'LIQUIDACIONES_LIQUIDADAS_PAGE';
const LIQUIDACIONES_LIQUIDADAS_DATA = 'LIQUIDACIONES_LIQUIDADAS_DATA';
const LIQUIDACIONES_LIQUIDADAS_TOTALES_LOADER = 'LIQUIDACIONES_LIQUIDADAS_TOTALES_LOADER';
const LIQUIDACIONES_LIQUIDADAS_TOTALES_DATA = 'LIQUIDACIONES_LIQUIDADAS_TOTALES_DATA';
// Reporte de liquidaciones
const REPORTE_FECHA_INICIAL = 'REPORTE_LIQUIDACIONES_FECHA_INICIAL';
const REPORTE_FECHA_FINAL   = 'REPORTE_LIQUIDACIONES_FECHA_FINAL';
const REPORTE_FINCA         = 'REPORTE_LIQUIDACIONES_FINCA';
const REPORTE_PAGE          = 'REPORTE_LIQUIDACIONES_PAGE';
const REPORTE_ORDENES_LIQUIDADAS = 'REPORTE_ORDENES_LIQUIDADAS';
// Reporte de fluctuacion de precios
const FILTRO_REPORTE_FECHA_INICIAL_FLUCTUACION_PRECIOS = 'FILTRO_REPORTE_FECHA_INICIAL_FLUCTUACION_PRECIOS';
const FILTRO_REPORTE_FECHA_FINAL_FLUCTUACION_PRECIOS = 'FILTRO_REPORTE_FECHA_FINAL_FLUCTUACION_PRECIOS';
const REPORTE_PAGE_FLUCTUACION = 'REPORTE_PAGE_FLUCTUACION';
const FILTRO_REPORTE_FINCA_FLUCTUACION_PRECIOS = 'FILTRO_REPORTE_FINCA_FLUCTUACION_PRECIOS';


// -----------------------------------
// Pure Actions
// -----------------------------------
const setLoader = loader => ({
    type: LOADER,
    loader,
});
const setData = data => ({
    type: DATA,
    data,
});
const setDataPendientes = data_pendientes => ({
    type: LIQUIDACIONES_PENDIENTES_DATA,
    data_pendientes,
});
const setDataCompras = data_compras => ({
    type: DATA_COMPRAS,
    data_compras,
});
const setDataLiquidadas = data_liquidadas => ({
    type: LIQUIDACIONES_LIQUIDADAS_DATA,
    data_liquidadas,
});
const setItem = item => ({
    type: ITEM_DATA,
    item,
});
const setPage = page => ({
    type: PAGE,
    page,
});
const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});
const setSearch = search => ({
    type: SEARCH,
    search,
});
const setOrdenesLiquidadas = ordenes_liquidadas => ({
    type: REPORTE_ORDENES_LIQUIDADAS,
    ordenes_liquidadas,
});

// ------------------------------------
// Actions
// ------------------------------------
const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().liquidaciones;
    const params = { page };
    if (resource.tab === 'PENDIENTES') {
        resource.id_tipo_pago_pend !== -1 && (params.id_tipo_pago = resource.id_tipo_pago_pend);
        resource.id_perfil_finca_pend !== -1 && (params.id_perfil_finca = resource.id_perfil_finca_pend);
        resource.regimen_fiscal_finca_pend !== -1 && (params.regimen_fiscal = resource.regimen_fiscal_finca_pend);
        if (resource.finca_liq > 0) {
            params.id_finca = resource.finca_liq;
        }
        params.fecha_inicial = moment(resource.fecha_inicio_liq).format('YYYY-MM-DD') + ' 00:00:00';
        params.fecha_final = moment(resource.fecha_final_liq).format('YYYY-MM-DD') + ' 23:59:59';   // Para incluir cualquier hora del día
        dispatch({type: LIQUIDACIONES_PENDIENTES_LOADER, loader: true});
        api.get('liquidacion/get_ordenes_liquidaciones', params).then((response) => {
            // Descripción de los pasos realizados:
            // - Acorta all_results a la cantidad de resultados devueltos por el endpoint.
            //   - Si response.count > all_results.length: Hay nuevos datos en BD. En este caso all_results mantiene su mismo tamaño.
            //   - Si response.count < all_results.length: Hay menos resultados en BD. Es decir, en memoria hay data que ya no debería aparecer y por lo tanto all_results se recorta.
            // - Si all_results.length < response.count se insertan '0' en all_results hasta que all_results.length === response.count
            // - El listado de items devueltos por el response se reemplazan en la 'pagina' en all_results en que deberían ir.
            //   En los index de la página correspondiente puede haber data o puede haber '0'; en cualquier caso se sustituye con Array.splice(start, deleteCount, item[, ...])
            //   @see https://developer.mozilla.org/es/docs/Web/JavaScript/Referencia/Objetos_globales/Array/splice
            //   Por ejemplo:
            //     page: 3
            //     response.count: 55
            //     response.results.length: 10
            //     all_results.length <== 55
            //     all_results[29 : 39] <== response.results
            const { data_pendientes } = resource;
            data_pendientes.count = response.count;
            data_pendientes.all_results = data_pendientes.all_results.slice(0, response.count);
            const faltantes = response.count - data_pendientes.all_results.length;
            faltantes >= 0 && Array(faltantes).fill(0).forEach(value => data_pendientes.all_results.push(value));
            const start_index = (page-1)*PAGINATION_SIZE_PER_PAGE;
            response.results.forEach((item, index) => {
                data_pendientes.all_results.splice(start_index + index, 1, item);
            });
            dispatch(setDataPendientes({...data_pendientes}));
            dispatch(pageChangePendientes(page));
            dispatch({type: LIQUIDACIONES_PENDIENTES_FORCE_UPDATE_DATA, force_update_data: false});
        }).catch(error => {
        }).finally(() => {
            dispatch({type: LIQUIDACIONES_PENDIENTES_LOADER, loader: false});
        });
    }
    if (resource.tab === 'LIQUIDADAS') {
        resource.id_tipo_pago_liq !== -1 && (params.id_tipo_pago = resource.id_tipo_pago_liq);
        resource.id_perfil_finca_liq !== -1 && (params.id_perfil_finca = resource.id_perfil_finca_liq);
        resource.regimen_fiscal_finca_liq !== -1 && (params.regimen_fiscal = resource.regimen_fiscal_finca_liq);
        if (resource.finca_liq_liquidadas > 0) {
            params.id_finca = resource.finca_liq_liquidadas;
        }
        // El filtro aplica a OrdenCompra.fecha_liquidacion que es un DateField (sin hora)
        params.fecha_inicial = moment(resource.fecha_inicio_liq_liquidadas).format('YYYY-MM-DD');
        params.fecha_final = moment(resource.fecha_final_liq_liquidadas).format('YYYY-MM-DD');
        dispatch({type: LIQUIDACIONES_LIQUIDADAS_LOADER, loader: true});
        api.get('liquidacion/get_ordenes_liquidadas', params).then((response) => {
            // Descripción de los pasos realizados:
            // - Acorta all_results a la cantidad de resultados devueltos por el endpoint.
            //   - Si response.count > all_results.length: Hay nuevos datos en BD. En este caso all_results mantiene su mismo tamaño.
            //   - Si response.count < all_results.length: Hay menos resultados en BD. Es decir, en memoria hay data que ya no debería aparecer y por lo tanto all_results se recorta.
            // - Si all_results.length < response.count se insertan '0' en all_results hasta que all_results.length === response.count
            // - El listado de items devueltos por el response se reemplazan en la 'pagina' en all_results en que deberían ir.
            //   En los index de la página correspondiente puede haber data o puede haber '0'; en cualquier caso se sustituye con Array.splice(start, deleteCount, item[, ...])
            //   @see https://developer.mozilla.org/es/docs/Web/JavaScript/Referencia/Objetos_globales/Array/splice
            //   Por ejemplo:
            //     page: 3
            //     response.count: 55
            //     response.results.length: 10
            //     all_results.length <== 55
            //     all_results[29 : 39] <== response.results
            const { data_liquidadas } = resource;
            data_liquidadas.count = response.count;
            data_liquidadas.all_results = data_liquidadas.all_results.slice(0, response.count);
            const faltantes = response.count - data_liquidadas.all_results.length;
            const start_index = (page-1)*PAGINATION_SIZE_PER_PAGE;
            faltantes >= 0 && Array(faltantes).fill(0).forEach(value => data_liquidadas.all_results.push(value));
            response.results.forEach((item, index) => {
                data_liquidadas.all_results.splice(start_index + index, 1, item);
            });
            dispatch(setDataLiquidadas({...data_liquidadas}));
            dispatch(pageChangeLiquidadas(page));
            dispatch({type: LIQUIDACIONES_LIQUIDADAS_FORCE_UPDATE_DATA, force_update_data: false});
        }).catch(error => {
        }).finally(() => {
            dispatch({type: LIQUIDACIONES_LIQUIDADAS_LOADER, loader: false});
        });
    }
};
const getTotalesParaLiquidaciones = () => (dispatch, getStore) => {
    const resource = getStore().liquidaciones;
    const params = {};
    if (resource.tab === 'PENDIENTES') {
        resource.id_tipo_pago_pend !== -1 && (params.id_tipo_pago = resource.id_tipo_pago_pend);
        resource.id_perfil_finca_pend !== -1 && (params.id_perfil_finca = resource.id_perfil_finca_pend);
        resource.regimen_fiscal_finca_pend !== -1 && (params.regimen_fiscal = resource.regimen_fiscal_finca_pend);
        resource.finca_liq !== -1 && (params.id_finca = resource.finca_liq);
        params.fecha_inicial = moment(resource.fecha_inicio_liq).format('YYYY-MM-DD') + ' 00:00:00';
        params.fecha_final = moment(resource.fecha_final_liq).format('YYYY-MM-DD') + ' 23:59:59';   // Para incluir cualquier hora del día
        dispatch({type: LIQUIDACIONES_PENDIENTES_TOTALES_LOADER, loader: true});
        api.get('liquidacion/get_totales_ordenes_pendientes', params).then((response) => {
            dispatch({type: LIQUIDACIONES_PENDIENTES_TOTALES_DATA, data: response});
        }).catch(error => {
        }).finally(() => {
            dispatch({type: LIQUIDACIONES_PENDIENTES_TOTALES_LOADER, loader: false});
        });
    }
    if (resource.tab === 'LIQUIDADAS') {
        resource.id_tipo_pago_liq !== -1 && (params.id_tipo_pago = resource.id_tipo_pago_liq);
        resource.id_perfil_finca_liq !== -1 && (params.id_perfil_finca = resource.id_perfil_finca_liq);
        resource.regimen_fiscal_finca_liq !== -1 && (params.regimen_fiscal = resource.regimen_fiscal_finca_liq);
        resource.finca_liq_liquidadas !== -1 && (params.id_finca = resource.finca_liq_liquidadas);
        // El filtro aplica a OrdenCompra.fecha_liquidacion que es un DateField (sin hora)
        params.fecha_inicial = moment(resource.fecha_inicio_liq_liquidadas).format('YYYY-MM-DD');
        params.fecha_final = moment(resource.fecha_final_liq_liquidadas).format('YYYY-MM-DD');
        dispatch({type: LIQUIDACIONES_LIQUIDADAS_TOTALES_LOADER, loader: true});
        api.get('liquidacion/get_totales_ordenes_liquidadas', params).then((response) => {
            dispatch({type: LIQUIDACIONES_LIQUIDADAS_TOTALES_DATA, data: response});
        }).catch(error => {
        }).finally(() => {
            dispatch({type: LIQUIDACIONES_LIQUIDADAS_TOTALES_LOADER, loader: false});
        });
    }
};
const listarCompras = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().liquidaciones;
    const params = { page };
    if (resource.finca_liq > 0) {
        params.id_finca = resource.finca_liq;
    }
    params.fecha_inicial = moment(resource.fecha_inicio_liq).format('YYYY-MM-DD') + ' 00:00:00';
    params.fecha_final = moment(resource.fecha_final_liq).format('YYYY-MM-DD') + ' 23:59:59';   // Para incluir cualquier hora del día
    dispatch({type: LOADER_COMPRAS, loader: true});
    api.get('compra/get_fincas_orden_compra', params).then((response) => {
        const { data_compras } = resource;
        data_compras.count = response.count;
        data_compras.all_results = data_compras.all_results.slice(0, response.count);
        const faltantes = response.count - data_compras.all_results.length;
        const start_index = (page-1)*PAGINATION_SIZE_PER_PAGE;
        faltantes >= 0 && Array(faltantes).fill(0).forEach(value => data_compras.all_results.push(value));
        response.results.forEach((item, index) => {
            data_compras.all_results.splice(start_index + index, 1, item);
        });
        dispatch(setDataCompras({...data_compras}));
        dispatch(pageChangeCompras(page));
    }).catch(error => {
    }).finally(() => {
        dispatch({type: LOADER_COMPRAS, loader: false});
    });
};
const leer = (id, estado = false) => (dispatch, getStore) => {
    dispatch(setLoader(true));
    const params = {};
    const dias= getStore().configuracion.configuraciones.fecha_estimada_pago;
    estado && (params.estado = estado);
    api.get(`liquidacion/${id}`, params).then((response) => {
        response.dias=dias;
        dispatch(setItem(response));
        dispatch(initializeForm('LiquidacionesForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const crear = data => (dispatch, getStore) => {
    const temp_detalles = data.detalles;
    const temp_insumos = data.insumos;
    const detalles = [];
    const insumos = [];
    const data_completa = {}
    var cont = 0;

    if (temp_detalles) {
        temp_detalles.map((detalle, index) => {
            if (detalle.liquidar) {
                // El precio_venta (precio unitario) debe incluir el precio unitario de flete
                const { precio_venta=0, costo_flete=0, tipo_costo_flete=0 } = detalle;
                const precio_venta_con_flete = precio_venta + (tipo_costo_flete === FLETE_INTERNO_COSTO_POR_KG ? costo_flete : 0);
                detalle.precio_venta = parseFloat(precio_venta_con_flete.toFixed(5));
                detalle.kilos_secos = parseFloat(detalle.kilos_secos.toFixed(2))
                detalle.total_dolares = parseFloat(detalle.total_dolares.toFixed(2))
                detalle.total_quetzales = parseFloat(detalle.total_quetzales.toFixed(2))
                detalle.liquidar = true;
                detalles.push(detalle);
                cont += 1;
            }
            else{
                detalle.liquidar = false;
                detalles.push(detalle);
            }
        })
    }

    if (temp_insumos) {
        temp_insumos.map((insumo, index) => {
            if (insumo.pagar_insumo) {
                insumo.pagar_insumo = true;
                insumos.push(insumo);
            }
            else{
                insumo.pagar_insumo = false;
                insumos.push(insumo);
            }
        })
    }

    if (cont <= 0) {
        NotificationManager.error('Debe seleccionar al menos un ingreso a liquidar.', 'ERROR');
        return true;
    }

    if (cont >= 1) {
        data_completa.detalles = detalles;
        data_completa.insumos = insumos;
        data_completa.id_finca = data.finca.id;
        data_completa.id_orden_compra = data.id;

        data_completa.kilos_secos_liquidar = parseFloat(data.kilos_secos_liquidar.toFixed(2));
        data_completa.kilos_humedos_liquidar = parseFloat(data.kilos_humedos_liquidar.toFixed(2));
        data_completa.retencion_iva = data.retencion_iva === null ? 0 : parseFloat(data.retencion_iva.toFixed(2));
        data_completa.retencion_isr = data.retencion_isr === null ? 0 : parseFloat(data.retencion_isr.toFixed(2));
        data_completa.retencion_peq_contribuyente = data.retencion_peq_contribuyente === null ? 0 : parseFloat(data.retencion_peq_contribuyente.toFixed(2));
        data_completa.total_quetzales = parseFloat(data.total_quetzales.toFixed(2));
        data_completa.total_dolares = parseFloat(data.total_dolares.toFixed(2));
        data_completa.total_liquidar = parseFloat(data.total_liquidar.toFixed(2));
        data_completa.numero_factura = data.numero_factura;
        data_completa.serie = data.serie;
        data_completa.total_fletes = parseFloat(data.total_fletes.toFixed(2));
        data_completa.fecha_liquidacion = moment().format('YYYY-MM-DD');
        data_completa.fecha_factura = moment(data.fecha_factura).format('YYYY-MM-DD');

        dispatch(setLoader(true));
        api.post('liquidacion', data_completa).then((response) => {
            NotificationManager.success('Orden compra liquidada correctamente', 'Éxito', 3000);
            // La compra liquidada aparecerá en la sección de LIQUIDADAS, por lo que se debe eliminar de PENDIENTES.
            // Si no se liquidan todos los ingresos de la compra, se crea una nueva compra y se ligan las recepciones no liquidadas a esta nueva.
            // No se fuerza la actualización de liquidaciones pendientes.
            const { data_pendientes } = getStore().liquidaciones;
            if (data_pendientes && data_pendientes.all_results) {
                data_pendientes.all_results = data_pendientes.all_results.filter(item => item.id !== data_completa.id_orden_compra);
                dispatch(setDataPendientes(data_pendientes));
                dispatch(pageChangePendientes(1));
            }
            const dias=getStore().configuracion.configuraciones.fecha_estimada_pago;
            response.dias=dias;
            dispatch(setItem(response));
            dispatch(push(`/liquidacion/${data.id}/boleta_liquidacion`));
        }).catch((error) => {
            NotificationManager.error(error && error.detail ? error.detail : 'Error en la liquidación', 'Error', 3000);
            return true;
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
};
const actualizarNumeroFactura = (id, data=null) => (dispatch, getStore) => {
    const temp = {}
    if (data.serie && data.numero_factura && data.fecha_factura) {
        temp.serie = data.serie;
        temp.numero_factura = data.numero_factura;
        temp.fecha_factura = moment(data.fecha_factura).format('YYYY-MM-DD');

        dispatch(setLoader(true));
        api.put(`liquidacion/${id}/definir_numero_factura`, temp).then(() => {
            NotificationManager.success(`Los datos de la factura fueron actualizados correctamente.`, 'Éxito', 3000);
            // No se fuerza la actualización de las liquidaciones ya liquidadas porque el item actualizado será consultado nuevamente al obtener sus detalles.
            dispatch(push('/liquidaciones'))
        }).catch((error) => {
            NotificationManager.error((error && error.detail) ? error.detail : 'Error en la edición', 'ERROR', 3000);
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
    else{
        NotificationManager.error('Debe ingresar la fecha de la factura, el numero de factura y la serie para poder guardar', 'ERROR', 3000);
    }
};
// Guardar la boleta de ingreso o salida
const guardarBoletaPDFLiquidaciones = (id, data) => (dispatch) => {
    const _data = {};
    _data.boleta_liquidacion = data;
    dispatch(setLoader(true));
    // TODO: Cambiar nombre de endpoint cuando se corrija el error con django-celery
    api.put(`liquidacion/${id}/guardar_pdf_liquidacion_temp`, _data).then((response) => {
        const { envio_por_correo, correos_enviados, boleta_guardada } = response;
        const msgEnvioCorreo = envio_por_correo && correos_enviados ? ` y enviada a ${correos_enviados} correo${correos_enviados > 1 ? 's' : ''}` : '';
        !!boleta_guardada && NotificationManager.success(`Boleta de liquidación #${id} guardada${msgEnvioCorreo} con éxito.`, 'Éxito', 5000);
    }).catch((error) => {
        NotificationManager.error((error && error.detail) ? error.detail : 'Error al intentar guardar la boleta de liquidación', 'ERROR', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
// Endpoint de leer async
const leerAsync = (id, estado = false) => async (dispatch) => {
    dispatch(setLoader(true));
    const params = {};
    estado && (params.estado = estado);
    try {
        const response = await api.get(`liquidacion/${id}`, params)
        dispatch(setItem(response));
        dispatch(initializeForm('LiquidacionesForm', response));
    } catch(err) {
    } finally {
        dispatch(setLoader(false));
    }
};
const pageChangePendientes = page => (dispatch, getStore) => {
    const { data_pendientes } = getStore().liquidaciones;
    const results = data_pendientes.all_results.slice((page-1)*PAGINATION_SIZE_PER_PAGE, page*PAGINATION_SIZE_PER_PAGE);
    let count = 0;
    results.forEach(value => value === 0 && count++);
    if (count > 0) {
        dispatch(listar(page));
    } else {
        data_pendientes.results = results;
        dispatch(setDataPendientes({...data_pendientes, page}));
    }
};
const pageChangeCompras = page => (dispatch, getStore) => {
    const { data_compras } = getStore().liquidaciones;
    const results = data_compras.all_results.slice((page-1)*PAGINATION_SIZE_PER_PAGE, page*PAGINATION_SIZE_PER_PAGE);
    let count = 0;
    results.forEach(value => value === 0 && count++);
    if (count > 0) {
        dispatch(listarCompras(page));
    } else {
        data_compras.results = results;
        dispatch(setDataCompras({...data_compras, page}));
    }
};
const pageChangeLiquidadas = page => (dispatch, getStore) => {
    const { data_liquidadas } = getStore().liquidaciones;
    const results = data_liquidadas.all_results.slice((page-1)*PAGINATION_SIZE_PER_PAGE, page*PAGINATION_SIZE_PER_PAGE);
    let count = 0;
    results.forEach(value => value === 0 && count++);
    if (count > 0) {
        dispatch(listar(page));
    } else {
        data_liquidadas.results = results;
        dispatch(setDataLiquidadas({...data_liquidadas, page}));
    }
};
const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};
const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().liquidaciones.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};
const setTab = (tab) => (dispatch) => {
    dispatch({type: TAB_LIQUIDACIONES, tab});
};
const filtroFincaChangeLiq = finca_liq => (dispatch) => {
    dispatch({type: FILTRO_FINCA_LIQ, finca_liq});
};
const fechaFinalLiq = fecha_final_liq => (dispatch) => {
    dispatch({type: FILTRO_FECHA_FINAL_LIQ, fecha_final_liq});
};
const fechaInicioLiq = fecha_inicio_liq => (dispatch) => {
    dispatch({type: FILTRO_FECHA_INICIO_LIQ, fecha_inicio_liq});
};
const filtroFincaTipoPagoPendChange = id_tipo_pago_pend => (dispatch) => {
    dispatch({type: FILTRO_PENDIENTES_FINCA_TIPO_PAGO, id_tipo_pago_pend});
}
const filtroFincaPerfilPendChange = id_perfil_finca_pend => (dispatch) => {
    dispatch({type: FILTRO_PENDIENTES_FINCA_PERFIL, id_perfil_finca_pend});
}
const filtroFincaRegimenFiscalPendChange = regimen_fiscal_finca_pend => (dispatch) => {
    dispatch({type: FILTRO_PENDIENTES_FINCA_REGIMEN_FISCAL, regimen_fiscal_finca_pend});
}
const filtroFincaChangeLiqLiquidadas = finca_liq_liquidadas => (dispatch) => {
    dispatch({type: FILTRO_FINCA_LIQ_LIQUIDADAS, finca_liq_liquidadas});
};
const fechaFinalLiqLiquidadas = fecha_final_liq_liquidadas => (dispatch) => {
    dispatch({type: FILTRO_FECHA_FINAL_LIQ_LIQUIDADAS, fecha_final_liq_liquidadas});
};
const fechaInicioLiqLiquidadas = fecha_inicio_liq_liquidadas => (dispatch) => {
    dispatch({type: FILTRO_FECHA_INICIO_LIQ_LIQUIDADAS, fecha_inicio_liq_liquidadas});
};
const filtroFincaTipoPagoLiqChange = id_tipo_pago_liq => (dispatch) => {
    dispatch({type: FILTRO_LIQUIDADAS_FINCA_TIPO_PAGO, id_tipo_pago_liq});
}
const filtroFincaPerfilLiqChange = id_perfil_finca_liq => (dispatch) => {
    dispatch({type: FILTRO_LIQUIDADAS_FINCA_PERFIL, id_perfil_finca_liq});
}
const filtroFincaRegimenFiscalLiqChange = regimen_fiscal_finca_liq => (dispatch) => {
    dispatch({type: FILTRO_LIQUIDADAS_FINCA_REGIMEN_FISCAL, regimen_fiscal_finca_liq});
}
/**
 * Obtiene el listado de Órdenes compra, según los parámetros de búsqueda.
 * Realiza 2 peticiones hacia la API para obtener la data total y la data paginada.
 */
const getLiquidacionesParaReporte = (reset_finca = false) => async (dispatch, getStore) => {
    // Construcción de los parámetros de búsqueda. Las fechas son requeridas
    const resource = getStore().liquidaciones;
    const user = getStore().login;

    const proveedor = user.proveedor
    const reporte = resource.reporte;
    const usuario = user.me;
    const params = {};
    params.page = reporte.page;
    if (usuario.proveedor) {
        params.proveedor = true;
        params.id_usuario = usuario.id;
    }
    if (proveedor) {
        params.proveedor_id = proveedor;
    }
    !reset_finca && reporte.finca_id !== -1         && (params.finca_id      = reporte.finca_id);
    reporte.fecha_inicial !== -null && (params.fecha_inicial = moment(reporte.fecha_inicial).format('YYYY-MM-DD') + ' 00:00:00');
    reporte.fecha_final !== -null   && (params.fecha_final   = moment(reporte.fecha_final).format('YYYY-MM-DD') + ' 23:59:59');
    if (params.fecha_final && params.fecha_final) {
        // Inicio de la petición al servidor
        try {
            dispatch(setLoader(true));
            const liquidacionesAll = await api.get('liquidacion/get_reporte_ordenes_liquidadas/', params);
            params.paginar_data = true;
            const liquidaciones = await api.get('liquidacion/get_reporte_ordenes_liquidadas/', params);
            liquidaciones.all_results = liquidacionesAll.results;
            liquidaciones.all_count = liquidacionesAll.count;
            dispatch(setOrdenesLiquidadas(liquidaciones));
        } catch(error) {
        } finally {
            dispatch(setLoader(false));
        }
    }
};
const pageReporteChange = (page) => (dispatch) => {
    dispatch({type: REPORTE_PAGE, page})
    dispatch(getLiquidacionesParaReporte())
}
const fechaInicialReporteChange = (fecha_inicial, page=1) => (dispatch) => {
    dispatch({type: REPORTE_FECHA_INICIAL, fecha_inicial});
    dispatch({type: REPORTE_PAGE, page})
    //dispatch(getLiquidacionesParaReporte());
}
const fechaFinalReporteChange = (fecha_final, page=1) => (dispatch) => {
    dispatch({type: REPORTE_PAGE, page})
    dispatch({type: REPORTE_FECHA_FINAL, fecha_final});
    //dispatch(getLiquidacionesParaReporte());
}
const fincaReporteChange = (finca_id, page=1) => (dispatch) => {
    dispatch({type: REPORTE_FINCA, finca_id});
    dispatch({type: REPORTE_PAGE, page})
    //dispatch(getLiquidacionesParaReporte());
}
/**
 * Obtiene la data para la construcción del reporte de fluctuaciones de precios, según los parámetros de búsqueda.
 * Realiza 2 peticiones hacia la API para obtener la data total y la data paginada.
 */
const getFluctuacionPreciosReporte = () => async (dispatch, getStore) => {
    const resource = getStore().liquidaciones;
    const params = {};
    params.page = resource.page_reporte_fluctuacion;
    params.fecha_inicial = moment(resource.fecha_inicio_reporte_fluctuacion).format('YYYY-MM-DD');
    params.fecha_fin = moment(resource.fecha_fin_reporte_fluctuacion).format('YYYY-MM-DD');

    // if (resource.materia_prima_id_reporte_fluctuacion > 0) {
    //     params.materia_prima_id = resource.materia_prima_id_reporte_fluctuacion;
    // }
    if (resource.finca_id_reporte_fluctuacion > 0) {
        params.finca_id = resource.finca_id_reporte_fluctuacion;
    }
    if (params.fecha_inicial && params.fecha_fin) {
        dispatch(setLoader(true));
        try {
            const detalles_orden_all = await api.get('liquidacion/get_reporte_fluctuacion', params);
            params.paginar_data = true;
            const detalles_orden = await api.get('liquidacion/get_reporte_fluctuacion', params);
            detalles_orden.all_results = detalles_orden_all.results;
            detalles_orden.all_count = detalles_orden_all.count;
            dispatch(setData(detalles_orden));
        } catch(error) {
        } finally {
            dispatch(setLoader(false));
        }
    }

};
const pageReporteFluctuacionChange = (page_reporte_fluctuacion) => (dispatch) => {
    dispatch({type: REPORTE_PAGE_FLUCTUACION, page_reporte_fluctuacion});
    dispatch(getFluctuacionPreciosReporte());
}
const filtroFechaInicioFluctuacion = (fecha_inicio_reporte_fluctuacion, page_reporte_fluctuacion=1) => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_FECHA_INICIAL_FLUCTUACION_PRECIOS, fecha_inicio_reporte_fluctuacion});
    dispatch({type: REPORTE_PAGE_FLUCTUACION, page_reporte_fluctuacion});
    dispatch(getFluctuacionPreciosReporte());
};
const filtroFechaFinFluctuacion = (fecha_fin_reporte_fluctuacion, page_reporte_fluctuacion=1) => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_FECHA_FINAL_FLUCTUACION_PRECIOS, fecha_fin_reporte_fluctuacion});
    dispatch({type: REPORTE_PAGE_FLUCTUACION, page_reporte_fluctuacion});
    dispatch(getFluctuacionPreciosReporte());
};
const filtroFincaFluctuacion = (finca_id_reporte_fluctuacion, page_reporte_fluctuacion=1) => (dispatch) => {
    dispatch({type: FILTRO_REPORTE_FINCA_FLUCTUACION_PRECIOS, finca_id_reporte_fluctuacion});
    dispatch({type: REPORTE_PAGE_FLUCTUACION, page_reporte_fluctuacion});
    dispatch(getFluctuacionPreciosReporte());
};

export const actions = {
    listar,
    leer,
    crear,
    pageChangePendientes,
    pageChangeCompras,
    pageChangeLiquidadas,
    searchChange,
    onSortChange,
    setTab,
    filtroFincaChangeLiq,
    fechaFinalLiq,
    fechaInicioLiq,
    filtroFincaChangeLiqLiquidadas,
    fechaFinalLiqLiquidadas,
    fechaInicioLiqLiquidadas,
    actualizarNumeroFactura,
    filtroFincaTipoPagoLiqChange,
    filtroFincaPerfilLiqChange,
    filtroFincaRegimenFiscalLiqChange,
    filtroFincaTipoPagoPendChange,
    filtroFincaPerfilPendChange,
    filtroFincaRegimenFiscalPendChange,
    listarCompras,
    getTotalesParaLiquidaciones,
    // Reporte de liquidaaciones
    pageReporteChange,
    fechaInicialReporteChange,
    fechaFinalReporteChange,
    fincaReporteChange,
    getLiquidacionesParaReporte,
    // Reporte de fluctuacion de precio
    getFluctuacionPreciosReporte,
    pageReporteFluctuacionChange,
    filtroFechaInicioFluctuacion,
    filtroFechaFinFluctuacion,
    filtroFincaFluctuacion,
    // Boletas
    guardarBoletaPDFLiquidaciones,
    leerAsync,
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [FORCE_UPDATE_DATA]: (state, { force_update_data }) => {
        return {
            ...state,
            force_update_data,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
    [TAB_LIQUIDACIONES]: (state, { tab }) => {
        return {
            ...state,
            tab,
        };
    },
    // Liquidaciones pendientes
    [FILTRO_FECHA_INICIO_LIQ]: (state, {fecha_inicio_liq}) => {
        return {
            ...state,
            fecha_inicio_liq,
        };
    },
    [FILTRO_FECHA_FINAL_LIQ]: (state, {fecha_final_liq}) => {
        return {
            ...state,
            fecha_final_liq,
        };
    },
    [FILTRO_FINCA_LIQ]: (state, {finca_liq}) => {
        return {
            ...state,
            finca_liq,
        };
    },
    [FILTRO_PENDIENTES_FINCA_TIPO_PAGO]: (state, {id_tipo_pago_pend}) => {
        return {
            ...state,
            id_tipo_pago_pend,
        };
    },
    [FILTRO_PENDIENTES_FINCA_PERFIL]: (state, {id_perfil_finca_pend}) => {
        return {
            ...state,
            id_perfil_finca_pend,
        };
    },
    [FILTRO_PENDIENTES_FINCA_REGIMEN_FISCAL]: (state, {regimen_fiscal_finca_pend}) => {
        return {
            ...state,
            regimen_fiscal_finca_pend,
        };
    },
    [LIQUIDACIONES_PENDIENTES_FORCE_UPDATE_DATA]: (state, {force_update_data}) => {
        return {
            ...state,
            data_pendientes: {...state.data_pendientes, force_update_data},
        };
    },
    [LIQUIDACIONES_PENDIENTES_LOADER]: (state, {loader}) => {
        return {
            ...state,
            data_pendientes: {...state.data_pendientes, loader},
        };
    },
    [LIQUIDACIONES_PENDIENTES_PAGE]: (state, {page}) => {
        return {
            ...state,
            data_pendientes: {...state.data_pendientes, page},
        };
    },
    [LIQUIDACIONES_PENDIENTES_DATA]: (state, {data_pendientes}) => {
        return {
            ...state,
            data_pendientes: {...state.data_pendientes, ...data_pendientes},
        };
    },
    // Sección de totales en Liquidaciones pendientes
    [LIQUIDACIONES_PENDIENTES_TOTALES_LOADER]: (state, {loader}) => {
        return {
            ...state,
            totales_pendientes: {...state.totales_pendientes, loader},
        };
    },
    [LIQUIDACIONES_PENDIENTES_TOTALES_DATA]: (state, {data}) => {
        return {
            ...state,
            totales_pendientes: {...state.totales_pendientes, data},
        };
    },
    // Listado de compras en liquidaciones pendientes
    [LOADER_COMPRAS]: (state, {loader}) => {
        return {
            ...state,
            data_compras: {...state.data_compras, loader},
        };
    },
    [PAGE_COMPRAS]: (state, {page}) => {
        return {
            ...state,
            data_compras: {...state.data_compras, page},
        };
    },
    [DATA_COMPRAS]: (state, {data_compras}) => {
        return {
            ...state,
            data_compras: {...state.data_compras, ...data_compras},
        };
    },
    // Liquidaciones ya liquidadas
    [FILTRO_FECHA_INICIO_LIQ_LIQUIDADAS]: (state, {fecha_inicio_liq_liquidadas}) => {
        return {
            ...state,
            fecha_inicio_liq_liquidadas,
        };
    },
    [FILTRO_FECHA_FINAL_LIQ_LIQUIDADAS]: (state, {fecha_final_liq_liquidadas}) => {
        return {
            ...state,
            fecha_final_liq_liquidadas,
        };
    },
    [FILTRO_FINCA_LIQ_LIQUIDADAS]: (state, {finca_liq_liquidadas}) => {
        return {
            ...state,
            finca_liq_liquidadas,
        };
    },
    [FILTRO_LIQUIDADAS_FINCA_TIPO_PAGO]: (state, {id_tipo_pago_liq}) => {
        return {
            ...state,
            id_tipo_pago_liq,
        };
    },
    [FILTRO_LIQUIDADAS_FINCA_PERFIL]: (state, {id_perfil_finca_liq}) => {
        return {
            ...state,
            id_perfil_finca_liq,
        };
    },
    [FILTRO_LIQUIDADAS_FINCA_REGIMEN_FISCAL]: (state, {regimen_fiscal_finca_liq}) => {
        return {
            ...state,
            regimen_fiscal_finca_liq,
        };
    },
    [LIQUIDACIONES_LIQUIDADAS_FORCE_UPDATE_DATA]: (state, {force_update_data}) => {
        return {
            ...state,
            data_liquidadas: {...state.data_liquidadas, force_update_data},
        };
    },
    [LIQUIDACIONES_LIQUIDADAS_LOADER]: (state, {loader}) => {
        return {
            ...state,
            data_liquidadas: {...state.data_liquidadas, loader},
        };
    },
    [LIQUIDACIONES_LIQUIDADAS_PAGE]: (state, {page}) => {
        return {
            ...state,
            data_liquidadas: {...state.data_liquidadas, page},
        };
    },
    [LIQUIDACIONES_LIQUIDADAS_DATA]: (state, {data_liquidadas}) => {
        return {
            ...state,
            data_liquidadas: {...state.data_liquidadas, ...data_liquidadas},
        };
    },
    // Sección de totales en Liquidaciones liquidadas
    [LIQUIDACIONES_LIQUIDADAS_TOTALES_LOADER]: (state, {loader}) => {
        return {
            ...state,
            totales_liquidadas: {...state.totales_liquidadas, loader},
        };
    },
    [LIQUIDACIONES_LIQUIDADAS_TOTALES_DATA]: (state, {data}) => {
        return {
            ...state,
            totales_liquidadas: {...state.totales_liquidadas, data},
        };
    },
    // Reporte de liquidaciones
    [REPORTE_FECHA_INICIAL]: (state, {fecha_inicial}) => {
        return {
            ...state,
            reporte: {...state.reporte, fecha_inicial},
        };
    },
    [REPORTE_FECHA_FINAL]: (state, {fecha_final}) => {
        return {
            ...state,
            reporte: {...state.reporte, fecha_final},
        };
    },
    [REPORTE_FINCA]: (state, {finca_id}) => {
        return {
            ...state,
            reporte: {...state.reporte, finca_id},
        };
    },
    [REPORTE_PAGE]: (state, {page}) => {
        return {
            ...state,
            reporte: {...state.reporte, page},
        };
    },
    [REPORTE_ORDENES_LIQUIDADAS]: (state, {ordenes_liquidadas}) => {
        return {
            ...state,
            reporte: {...state.reporte, ordenes_liquidadas},
        };
    },
    // Reporte de fluctuacion de precios
    [FILTRO_REPORTE_FECHA_INICIAL_FLUCTUACION_PRECIOS]: (state, { fecha_inicio_reporte_fluctuacion }) => {
        return {
            ...state,
            fecha_inicio_reporte_fluctuacion,
        };
    },
    [FILTRO_REPORTE_FECHA_FINAL_FLUCTUACION_PRECIOS]: (state, { fecha_fin_reporte_fluctuacion }) => {
        return {
            ...state,
            fecha_fin_reporte_fluctuacion,
        };
    },
    [REPORTE_PAGE_FLUCTUACION]: (state, {page_reporte_fluctuacion}) => {
        return {
            ...state,
            page_reporte_fluctuacion
        };
    },
    [FILTRO_REPORTE_FINCA_FLUCTUACION_PRECIOS]: (state, { finca_id_reporte_fluctuacion }) => {
        return {
            ...state,
            finca_id_reporte_fluctuacion,
        };
    },
};

export const initialState = {
    loader: false,
    page: 1,
    data: {
        count: 0,
        results: [],
    },
    data_pendientes: {
        force_update_data: false,
        loader: false,
        page: 1,
        count: 0,
        results: [],
        all_results: [],
    },
    totales_pendientes: {
        loader: false,
        data: {},
    },
    data_compras: {
        loader: false,
        page: 1,
        count: 0,
        results: [],
        all_results: [],
    },
    data_liquidadas: {
        force_update_data: false,
        loader: false,
        page: 1,
        count: 0,
        results: [],
        all_results: [],
    },
    totales_liquidadas: {
        loader: false,
        data: {},
    },
    item: {},
    ordering: '',
    search: '',
    tab: 'PENDIENTES',
    // Liquidaciones pendientes
    fecha_final_liq: moment().startOf('week').isoWeekday(6),
    fecha_inicio_liq: moment().startOf('week').isoWeekday(0),
    finca_liq: -1,
    id_tipo_pago_pend: -1,
    id_perfil_finca_pend: -1,
    regimen_fiscal_finca_pend: -1,
    // Liquidaciones finalizadas
    fecha_final_liq_liquidadas: moment().endOf('month'),
    fecha_inicio_liq_liquidadas: moment().startOf('month'),
    finca_liq_liquidadas: -1,
    id_tipo_pago_liq: -1,
    id_perfil_finca_liq: -1,
    regimen_fiscal_finca_liq: -1,
    // Variables para el reporte de liquidaciones
    reporte: {
        page: 1,
        fecha_inicial: moment().startOf('month'),
        fecha_final: moment().endOf('month'),
        finca_id: -1,
        ordenes_liquidadas: {},
    },
    // Variables para el reporte de Fluctuacion de precios
    page_reporte_fluctuacion: 1,
    fecha_inicio_reporte_fluctuacion: moment().startOf('month'),
    fecha_fin_reporte_fluctuacion:moment().endOf('month'),
    finca_id_reporte_fluctuacion: -1,
};

export default handleActions(reducers, initialState);
