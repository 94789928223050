import {api} from "api";
import React from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom';
import {Field, FieldArray, formValueSelector, reduxForm,} from 'redux-form';
import {
    AsyncSelectField,
    renderNumber,
    renderNumberG,
    renderTimeField,
    renderNumberPercentage,
    renderNumberML,
} from '../../../Utils/renderField/renderField';
import { RenderNumberPercentage, RenderGramos, RenderML, RenderNumber } from '../../../Utils/renderField/renderReadField';
import CardEmpty from "../../../Utils/Cards/CardEmpty";
import Modal from 'react-responsive-modal';
import {NotificationManager} from "react-notifications";

const ver_img = require('assets/img/icons/ver.png');

const getUsuariosLaboratoristas = (search) => {
    return api.get('laboratorista/listar_laboratoristas_pilas', {search}).then((data) => {
        if (data) {
            return data.results;
        }
        return [];
    }).catch(() => {
        return [];
    });
};

export const renderDatosDRC = ({
                                   fields, setDRCTemp, drc_temporal, clearDRCTemp, closeModal, openModal, open_drc,
                                   loadDataModal, updateData, drc_promedio, establecerLaboratorista
                               }) => {
    return (
        <div className="col-12">
            <div className="d-flex justify-content-between my-2">
                <button className="btn btn-secondary"
                        onClick={e => {
                            e.preventDefault();
                            clearDRCTemp();
                            openModal('drc')
                        }}>
                    AGREGAR DRC
                </button>
                <div className="d-flex align-items-center">
                    <span className="verde-secundario bold mr-2">DRC PROMEDIO</span>
                    <Field name="drc_promedio" component={renderNumberPercentage} disabled type="text" className="form-control"
                           input={{value: drc_promedio}}/>
                </div>
            </div>
            <Modal open={open_drc} onClose={() => { closeModal() }} closeOnEsc={false} closeOnOverlayClick={false}>
                <h2>DATOS DRC</h2>
                <div className="row p-0">
                    <div className="col-12">
                        <div className="row p-0">
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso_seco" className="m-0">PESO SECO</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="peso_seco" component={renderNumberG} type="text"
                                           className="form-control"
                                           input={{
                                               value: drc_temporal['peso_seco'], onChange: (e) => {
                                                   setDRCTemp('peso_seco', parseFloat(e));
                                               }
                                           }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso_plato" className="m-0">PESO PLATO</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="peso_plato" component={renderNumberG} type="text"
                                           className="form-control"
                                           input={{
                                               value: drc_temporal['peso_plato'], onChange: (e) => {
                                                   setDRCTemp('peso_plato', parseFloat(e));
                                               }
                                           }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso_humedo" className="m-0">PESO HÚMEDO</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="peso_humedo" component={renderNumberG} type="text"
                                           className="form-control"
                                           input={{
                                               value: drc_temporal['peso_humedo'], onChange: (e) => {
                                                   setDRCTemp('peso_humedo', parseFloat(e));
                                               }
                                           }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="hora_ingreso" className="m-0">HORA INGRESO</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="hora_ingreso" component={renderTimeField} type="text"
                                           className="form-control"
                                           input={{
                                               value: drc_temporal['hora_ingreso'], onChange: (e) => {
                                                   setDRCTemp('hora_ingreso', e);
                                               }
                                           }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="hora_salida" className="m-0">HORA SALIDA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="hora_salida" component={renderTimeField} type="text"
                                           className="form-control"
                                           input={{
                                               value: drc_temporal['hora_salida'], onChange: (e) => {
                                                   setDRCTemp('hora_salida', e);
                                               }
                                           }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="laboratorista" className="m-0">LABORATORISTA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="laboratorista"
                                        valueKey="id"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField}
                                        loadOptions={getUsuariosLaboratoristas}
                                        input={{
                                            value: drc_temporal['laboratorista'], onChange: (e) => {
                                                setDRCTemp('laboratorista', e);
                                                setDRCTemp('laboratorista_nombre', e.nombre);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <button className="btn btn-outline-dark mr-5"
                            onClick={e => {
                                e.preventDefault();
                                closeModal()
                            }}>
                        CANCELAR
                    </button>
                    <button type="submit" className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault();
                                if (!drc_temporal.peso_seco) {
                                    NotificationManager.error('Ingrese el peso seco.', 'ERROR', 3000);
                                }
                                if (!drc_temporal.peso_plato) {
                                    NotificationManager.error('Ingrese el peso plato.', 'ERROR', 3000);
                                }
                                if (!drc_temporal.peso_humedo) {
                                    NotificationManager.error('Ingrese el peso húmedo.', 'ERROR', 3000);
                                }
                                if (!drc_temporal.hora_ingreso) {
                                    NotificationManager.error('Ingrese la hora de ingreso.', 'ERROR', 3000);
                                }
                                if (!drc_temporal.laboratorista) {
                                    NotificationManager.error('Ingrese el laboratorista', 'ERROR', 3000);
                                }
                                if (drc_temporal.peso_seco && drc_temporal.peso_plato && drc_temporal.peso_humedo && drc_temporal.hora_ingreso && drc_temporal.laboratorista) {
                                    if (drc_temporal.peso_seco && drc_temporal.peso_plato && drc_temporal.peso_humedo) {
                                        drc_temporal.total =
                                            ((drc_temporal.peso_seco - drc_temporal.peso_plato) / drc_temporal.peso_humedo)
                                    }
                                    if (drc_temporal.hasOwnProperty('index'))
                                        updateData(drc_temporal, 'drc');
                                    else
                                        fields.push(drc_temporal);
                                    clearDRCTemp();
                                    closeModal();
                                }
                            }}
                    >
                        GUARDAR
                    </button>
                </div>
            </Modal>
            <div className="react-bs-table-container">
                <div className="react-bs-table">
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table table-hover table-bordered">
                            <tbody>
                            <tr>
                                <th style={{width: "5%"}}/>
                                <th style={{width: "13.5%"}}>PESO SECO</th>
                                <th style={{width: "13.5%"}}>PESO PLATO</th>
                                <th style={{width: "13.5%"}}>PESO HÚMEDO</th>
                                <th style={{width: "12%"}}>DRC</th>
                                <th style={{width: "13.5%"}}>HORA INGRESO</th>
                                <th style={{width: "13.5%"}}>HORA SALIDA</th>
                                <th style={{width: "15%"}}>LABORATORISTA</th>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <table className="table table-striped table-bordered table-hover">
                <tbody>
                {fields.map((pago, index) => {
                    const dato_actual = fields.get(index); // Pago actual
                    let todos = fields.getAll();
                    todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                    return (
                        <tr key={index}>
                            <td style={{width: "5%"}}>
                                <img src={require('assets/img/icons/borrar.png')} alt="Borrar"
                                     title="Borrar" className="action_img"
                                     onClick={() => {
                                         if (dato_actual.hasOwnProperty('id')) {
                                             dato_actual.activo = false;
                                             updateData(dato_actual, 'drc');
                                             setDRCTemp('test', false)
                                         } else
                                             fields.remove(index)
                                     }}
                                />
                                <img src={require('assets/img/icons/editar.png')} alt="Editar"
                                     title="Editar" className="action_img"
                                     onClick={() => {
                                         loadDataModal(dato_actual);
                                         openModal("drc");
                                         setDRCTemp('laboratorista', dato_actual.laboratorista);
                                         establecerLaboratorista(dato_actual.laboratorista, dato_actual);
                                     }}
                                />
                            </td>
                            <td style={{width: "13.5%"}}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <RenderGramos value={dato_actual.peso_seco}/>
                            </td>
                            <td style={{width: "13.5%"}}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <RenderGramos value={dato_actual.peso_plato}/>
                            </td>
                            <td style={{width: "13.5%"}}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <RenderGramos value={dato_actual.peso_humedo}/>
                            </td>
                            <td style={{width: "13%"}}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                {dato_actual.total ? <RenderNumberPercentage value={parseFloat(dato_actual.total)} /> : '--'}
                            </td>
                            <td style={{width: "13.5   %"}}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <span>{dato_actual.hora_ingreso} </span>
                            </td>
                            <td style={{width: "13.5   %"}}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <span>{dato_actual.hora_salida} </span>
                            </td>
                            <td style={{width: "15%"}}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <span>{dato_actual.laboratorista_nombre ? dato_actual.laboratorista_nombre : dato_actual.laboratorista.nombre} </span>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
};

export const renderDatosTSC = ({
                                   fields, setDRCTemp, drc_temporal, clearDRCTemp, openModal, closeModal, open_tsc,
                                   loadDataModal, updateData, tsc_promedio, establecerLaboratorista
                               }) => {
    return (
        <div className="col-12">
            <div className="d-flex justify-content-between my-2">
                <button className="btn btn-secondary"
                        onClick={e => {
                            e.preventDefault();
                            clearDRCTemp();
                            openModal('tsc')
                        }}>
                    AGREGAR TSC
                </button>
                <div className="d-flex align-items-center">
                    <span className="verde-secundario bold mr-2">TSC PROMEDIO</span>
                    <Field name="drc_promedio" component={renderNumberPercentage} disabled type="text" className="form-control"
                           input={{value: tsc_promedio}}/>
                </div>
            </div>
            <Modal open={open_tsc} onClose={() => { closeModal() }} closeOnEsc={false} closeOnOverlayClick={false}>
                <h2>DATOS TSC</h2>
                <div className="row p-0">
                    <div className="col-12">
                        <div className="row p-0">
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso_seco" className="m-0">PESO SECO</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="peso_seco" component={renderNumberG} type="text"
                                           className="form-control"
                                           input={{
                                               value: drc_temporal['peso_seco'], onChange: (e) => {
                                                   setDRCTemp('peso_seco', parseFloat(e));
                                               }
                                           }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso_plato" className="m-0">PESO PLATO</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="peso_plato" component={renderNumberG} type="text"
                                           className="form-control"
                                           input={{
                                               value: drc_temporal['peso_plato'], onChange: (e) => {
                                                   setDRCTemp('peso_plato', parseFloat(e));
                                               }
                                           }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso_humedo" className="m-0">PESO HÚMEDO</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="peso_humedo" component={renderNumberG} type="text"
                                           className="form-control"
                                           input={{
                                               value: drc_temporal['peso_humedo'], onChange: (e) => {
                                                   setDRCTemp('peso_humedo', parseFloat(e));
                                               }
                                           }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="laboratorista" className="m-0">LABORATORISTA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="laboratorista"
                                        valueKey="id"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField}
                                        loadOptions={getUsuariosLaboratoristas}
                                        input={{
                                            value: drc_temporal['laboratorista'], onChange: (e) => {
                                                setDRCTemp('laboratorista', e);
                                                setDRCTemp('laboratorista_nombre', e.nombre);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <button className="btn btn-outline-dark mr-5"
                            onClick={e => {
                                e.preventDefault();
                                closeModal()
                            }}>
                        CANCELAR
                    </button>
                    <button type="submit" className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault();
                                if (!drc_temporal.peso_seco) {
                                    NotificationManager.error('Ingrese el peso seco.', 'ERROR', 3000);
                                }
                                if (!drc_temporal.peso_plato) {
                                    NotificationManager.error('Ingrese el peso plato.', 'ERROR', 3000);
                                }
                                if (!drc_temporal.peso_humedo) {
                                    NotificationManager.error('Ingrese el peso húmedo.', 'ERROR', 3000);
                                }
                                if (!drc_temporal.laboratorista) {
                                    NotificationManager.error('Ingrese el laboratorista', 'ERROR', 3000);
                                }
                                if (drc_temporal.peso_seco && drc_temporal.peso_plato && drc_temporal.peso_humedo && drc_temporal.laboratorista) {
                                    if (drc_temporal.peso_seco && drc_temporal.peso_plato && drc_temporal.peso_humedo) {
                                        drc_temporal.total =
                                            ((drc_temporal.peso_seco - drc_temporal.peso_plato) / drc_temporal.peso_humedo)
                                    }
                                    if (drc_temporal.hasOwnProperty('index'))
                                        updateData(drc_temporal, 'tsc');
                                    else
                                        fields.push(drc_temporal);
                                    clearDRCTemp();
                                    closeModal();
                                }
                            }}
                    >GUARDAR
                    </button>
                </div>
            </Modal>
            <div className="react-bs-table-container">
                <div className="react-bs-table">
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table table-hover table-bordered">
                            <tbody>
                                <tr>
                                    <th style={{ width: "10%" }} />
                                    <th style={{ width: "15%" }}>PESO SECO</th>
                                    <th style={{ width: "15%" }}>PESO PLATO</th>
                                    <th style={{ width: "20%" }}>PESO HÚMEDO</th>
                                    <th style={{ width: "20%" }}>TSC</th>
                                    <th style={{ width: "20%" }}>LABORATORISTA</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <table className="table table-striped table-bordered table-hover">
                <tbody>
                {fields.map((pago, index) => {
                    const dato_actual = fields.get(index); // Pago actual
                    let todos = fields.getAll();
                    todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                    return (
                        <tr key={index}>
                            <td style={{width: "10%"}}>
                                <img src={require('assets/img/icons/borrar.png')} alt="Borrar"
                                     title="Borrar" className="action_img"
                                     onClick={() => {
                                         if (dato_actual.hasOwnProperty('id')) {
                                             dato_actual.activo = false;
                                             updateData(dato_actual, 'tsc');
                                             setDRCTemp('test', false)
                                         } else
                                             fields.remove(index)
                                     }}
                                />
                                <img src={require('assets/img/icons/editar.png')} alt="Editar"
                                     title="Editar" className="action_img"
                                     onClick={() => {
                                         loadDataModal(dato_actual);
                                         openModal("tsc");
                                         setDRCTemp('laboratorista', dato_actual.laboratorista);
                                         establecerLaboratorista(dato_actual.laboratorista, dato_actual);
                                     }}
                                />
                            </td>
                            <td style={{ width: "15%" }}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <RenderGramos value={dato_actual.peso_seco} />
                            </td>
                            <td style={{ width: "15%" }}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <RenderGramos value={dato_actual.peso_plato} />
                            </td>
                            <td style={{ width: "20%" }}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <RenderGramos value={dato_actual.peso_humedo} />
                            </td>
                            <td style={{ width: "20%" }}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <span>{dato_actual.total ? <RenderNumberPercentage value={dato_actual.total} /> : '--'}</span>
                            </td>
                            <td style={{ width: "20%" }}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <span>{dato_actual.laboratorista_nombre ? dato_actual.laboratorista_nombre : dato_actual.laboratorista.nombre} </span>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
};

export const renderDatosVFA = ({
                                   fields, setVFATemp, vfa_temporal, clearVFATemp, openModal, closeModal, open_vfa,
                                   loadDataModal2, updateData, tsc_promedio, vfa, establecerLaboratorista2
                               }) => {
    return (
        <div className="col-12">
            <div className="d-flex justify-content-between my-2">
                <button className="btn btn-secondary"
                        onClick={e => {
                            e.preventDefault();
                            clearVFATemp();
                            openModal('vfa')
                        }}>
                    AGREGAR VFA
                </button>
                <div className="d-flex align-items-center">
                    <span className="verde-secundario bold mr-2">VFA PROMEDIO</span>
                    <Field name="vfa" component={renderNumberPercentage} disabled type="text" className="form-control"
                           input={{value: vfa}} decimalScale={3}/>
                </div>
            </div>
            <Modal open={open_vfa} onClose={() => { closeModal() }} closeOnEsc={false} closeOnOverlayClick={false}>
                <h2>DATOS VFA</h2>
                <div className="row p-0">
                    <div className="col-12">
                        <div className="row p-0">
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="ml_bureta" className="m-0">ML. BURETA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="ml_bureta" component={renderNumberML} type="text"
                                           className="form-control"
                                           input={{
                                               value: vfa_temporal['ml_bureta'], onChange: (e) => {
                                                   setVFATemp('ml_bureta', parseFloat(e));
                                               }
                                           }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso" className="m-0">NORMALIDAD</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="peso" component={renderNumber} type="text" className="form-control" decimalScale={4}
                                           input={{
                                               value: vfa_temporal['peso'], onChange: (e) => {
                                                   setVFATemp('peso', parseFloat(e));
                                               }
                                           }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="laboratorista" className="m-0">LABORATORISTA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="laboratorista"
                                        valueKey="id"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField}
                                        loadOptions={getUsuariosLaboratoristas}
                                        input={{
                                            value: vfa_temporal['laboratorista'], onChange: (e) => {
                                                setVFATemp('laboratorista', e);
                                                setVFATemp('laboratorista_nombre', e.nombre);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            {/* <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="hora_ingreso" className="m-0">HORA INGRESO</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="hora_ingreso" component={renderTimeField} type="text"
                                           className="form-control"
                                           input={{
                                               value: vfa_temporal['hora_ingreso'], onChange: (e) => {
                                                   setVFATemp('hora_ingreso', e);
                                               }
                                           }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="hora_salida" className="m-0">HORA SALIDA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="hora_salida" component={renderTimeField} type="text"
                                           className="form-control"
                                           input={{
                                               value: vfa_temporal['hora_salida'], onChange: (e) => {
                                                   setVFATemp('hora_salida', e);
                                               }
                                           }}
                                    />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <button className="btn btn-outline-dark mr-5"
                            onClick={e => {
                                e.preventDefault();
                                closeModal()
                            }}>
                        CANCELAR
                    </button>
                    <button type="submit" className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault();
                                if (!vfa_temporal.ml_bureta) {
                                    NotificationManager.error('Ingrese el valor de ml. bureta.', 'ERROR', 3000);
                                }
                                if (!vfa_temporal.peso) {
                                    NotificationManager.error('Ingrese el valor de normalidad.', 'ERROR', 3000);
                                }
                                if (!vfa_temporal.laboratorista) {
                                    NotificationManager.error('Ingrese el laboratorista', 'ERROR', 3000);
                                }
                                if (vfa_temporal.ml_bureta && vfa_temporal.peso && vfa_temporal.laboratorista) {

                                    if (!isNaN(tsc_promedio)) {
                                        if (vfa_temporal.ml_bureta && vfa_temporal.peso) {
                                            const b = [100 - ((tsc_promedio * 100) - 3)] * 0.66 + 67.31;
                                            vfa_temporal.total = ([(vfa_temporal.ml_bureta * vfa_temporal.peso) - 0.0025] * (b) / (tsc_promedio * 100));
                                        }
                                    } else {
                                        NotificationManager.error('No se puede generar un valor VFA sin datos TSC', 'ERROR', 3000);
                                    }
                                    if (vfa_temporal.hasOwnProperty('index'))
                                        updateData(vfa_temporal, 'vfa');
                                    else
                                        fields.push(vfa_temporal);
                                    clearVFATemp();
                                    closeModal();
                                }
                            }}
                    >GUARDAR
                    </button>
                </div>
            </Modal>
            <div className="react-bs-table-container">
                <div className="react-bs-table">
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table table-hover table-bordered">
                            <tbody>
                                <tr>
                                    <th style={{ width: "15%" }} />
                                    <th style={{ width: "20%" }} >ML. BURETA</th>
                                    <th style={{ width: "20%" }} >NORMALIDAD</th>
                                    <th style={{ width: "20%" }} >VFA</th>
                                    {/* <th style={{ width: "15%" }} >HORA INGRESO</th>
                                    <th style={{ width: "15%" }} >HORA SALIDA</th> */}
                                    <th style={{ width: "25%" }}>LABORATORISTA</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <table className="table table-striped table-bordered table-hover">
                <tbody>
                {fields.map((pago, index) => {
                    const dato_actual = fields.get(index); // Pago actual
                    let todos = fields.getAll();
                    todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                    return (
                        <tr key={index}>
                            <td style={{ width: "15%" }}>
                                <img src={require('assets/img/icons/borrar.png')} alt="Borrar"
                                     title="Borrar" className="action_img"
                                     onClick={() => {
                                         if (dato_actual.hasOwnProperty('id')) {
                                             dato_actual.activo = false;
                                             updateData(dato_actual, 'vfa');
                                             setVFATemp('test', false)
                                         } else
                                             fields.remove(index)
                                     }}
                                />
                                <img src={require('assets/img/icons/editar.png')} alt="Editar"
                                     title="Editar" className="action_img"
                                     onClick={() => {
                                         loadDataModal2(dato_actual);
                                         openModal("vfa");
                                         setVFATemp('laboratorista', dato_actual.laboratorista);
                                         establecerLaboratorista2(dato_actual.laboratorista, dato_actual);
                                     }}
                                />
                            </td>
                            <td style={{width: "20%"}}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <span>{dato_actual.ml_bureta ? <RenderML value={dato_actual.ml_bureta}/> : '--'}</span>
                            </td>
                            <td style={{width: "20%"}}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <span>{dato_actual.peso ? <RenderNumber value={dato_actual.peso} decimalScale={4}/> : '--'}</span>
                            </td>
                            <td style={{width: "20%"}}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <span>{dato_actual.total ? <RenderNumberPercentage value={dato_actual.total/100} decimalScale={3}/> : '--'}</span>
                            </td>
                            {/* <td style={{width: "15%"}}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <span>{dato_actual.hora_ingreso}</span>
                            </td>
                            <td style={{width: "15%"}}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <span>{dato_actual.hora_salida}</span>
                            </td> */}
                            <td style={{width: "25%"}}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <span>{dato_actual.laboratorista_nombre ? dato_actual.laboratorista_nombre : dato_actual.laboratorista.nombre} </span>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
};

export const renderDatosNH3 = ({
                                   fields, setVFATemp, vfa_temporal, clearVFATemp, openModal, closeModal, open_nh3,
                                   loadDataModal2, updateData, nh3, establecerLaboratorista2
                               }) => {
    return (
        <div className="col-12">
            <div className="d-flex justify-content-between my-2">
                <button className="btn btn-secondary"
                        onClick={e => {
                            e.preventDefault();
                            clearVFATemp();
                            openModal('nh3')
                        }}>
                    AGREGAR NH3
                </button>
                <div className="d-flex align-items-center">
                    <span className="verde-secundario bold mr-2">NH3</span>
                    <Field name="nh3" component={renderNumberPercentage} disabled type="text" className="form-control"
                           input={{value: nh3}}/>
                </div>
            </div>
            <Modal open={open_nh3} onClose={() => { closeModal() }} closeOnEsc={false} closeOnOverlayClick={false}>
                <h2>DATOS NH3</h2>
                <div className="row p-0">
                    <div className="col-12">
                        <div className="row p-0">
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="ml_bureta" className="m-0">ML. BURETA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="ml_bureta" component={renderNumberML} type="text"
                                           className="form-control"
                                           input={{
                                               value: vfa_temporal['ml_bureta'], onChange: (e) => {
                                                   setVFATemp('ml_bureta', parseFloat(e));
                                               }
                                           }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="peso" className="m-0">PESO MUESTRA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="peso" component={renderNumber} type="text" className="form-control" decimalScale={4}
                                           input={{
                                               value: vfa_temporal['peso'], onChange: (e) => {
                                                   setVFATemp('peso', parseFloat(e));
                                               }
                                           }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="laboratorista" className="m-0">LABORATORISTA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field
                                        name="laboratorista"
                                        valueKey="id"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField}
                                        loadOptions={getUsuariosLaboratoristas}
                                        input={{
                                            value: vfa_temporal['laboratorista'], onChange: (e) => {
                                                setVFATemp('laboratorista', e);
                                                setVFATemp('laboratorista_nombre', e.nombre);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            {/* <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="hora_ingreso" className="m-0">HORA INGRESO</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="hora_ingreso" component={renderTimeField} type="text"
                                           className="form-control"
                                           input={{
                                               value: vfa_temporal['hora_ingreso'], onChange: (e) => {
                                                   setVFATemp('hora_ingreso', e);
                                               }
                                           }}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="hora_salida" className="m-0">HORA SALIDA</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="hora_salida" component={renderTimeField} type="text"
                                           className="form-control"
                                           input={{
                                               value: vfa_temporal['hora_salida'], onChange: (e) => {
                                                   setVFATemp('hora_salida', e);
                                               }
                                           }}
                                    />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <button className="btn btn-outline-dark mr-5"
                            onClick={e => {
                                e.preventDefault();
                                closeModal()
                            }}>
                        CANCELAR
                    </button>
                    <button type="submit" className="btn btn-primary"
                            onClick={(e) => {
                                e.preventDefault();
                                if (!vfa_temporal.ml_bureta) {
                                    NotificationManager.error('Ingrese el valor de ml. bureta.', 'ERROR', 3000);
                                }
                                if (!vfa_temporal.peso) {
                                    NotificationManager.error('Ingrese el peso muestra.', 'ERROR', 3000);
                                }
                                if (!vfa_temporal.laboratorista) {
                                    NotificationManager.error('Ingrese el laboratorista', 'ERROR', 3000);
                                }
                                if (vfa_temporal.ml_bureta && vfa_temporal.peso && vfa_temporal.laboratorista) {

                                    if (vfa_temporal.ml_bureta && vfa_temporal.peso) {
                                        vfa_temporal.total = (((1.7 * 0.1 * vfa_temporal.ml_bureta) / vfa_temporal.peso)/100);
                                    }
                                    if (vfa_temporal.hasOwnProperty('index'))
                                        updateData(vfa_temporal, 'nh3');
                                    else
                                        fields.push(vfa_temporal);
                                    clearVFATemp();
                                    closeModal();
                                }
                            }}
                    >GUARDAR
                    </button>
                </div>
            </Modal>
            <div className="react-bs-table-container">
                <div className="react-bs-table">
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table table-hover table-bordered">
                            <tbody>
                                <tr>
                                    <th style={{ width: "15%" }} />
                                    <th style={{ width: "20%" }} >ML. BURETA</th>
                                    <th style={{ width: "20%" }} >PESO MUESTRA</th>
                                    <th style={{ width: "20%" }} >NH3</th>
                                    <th style={{ width: "25%" }}>LABORATORISTA</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <table className="table table-striped table-bordered table-hover">
                <tbody>
                {fields.map((pago, index) => {
                    const dato_actual = fields.get(index); // Pago actual
                    let todos = fields.getAll();
                    todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                    return (
                        <tr key={index}>
                            <td style={{ width: "15%" }}>
                                <img src={require('assets/img/icons/borrar.png')} alt="Borrar"
                                     title="Borrar" className="action_img"
                                     onClick={() => {
                                         if (dato_actual.hasOwnProperty('id')) {
                                             dato_actual.activo = false;
                                             updateData(dato_actual, 'nh3');
                                             setVFATemp('test', false)
                                         } else
                                             fields.remove(index)
                                     }}
                                />
                                <img src={require('assets/img/icons/editar.png')} alt="Editar"
                                     title="Editar" className="action_img"
                                     onClick={() => {
                                         loadDataModal2(dato_actual);
                                         openModal("nh3");
                                         setVFATemp('laboratorista', dato_actual.laboratorista);
                                         establecerLaboratorista2(dato_actual.laboratorista, dato_actual);
                                     }}
                                />
                            </td>
                            <td style={{ width: "20%" }}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <span>{dato_actual.ml_bureta ? <RenderML value={dato_actual.ml_bureta}/> : '--'}</span>
                            </td>
                            <td style={{ width: "20%" }}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <span>{dato_actual.peso ? <RenderGramos value={dato_actual.peso}/> : '--'}</span>
                            </td>
                            <td style={{ width: "20%" }} className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <span>{dato_actual.total ? <RenderNumberPercentage value={dato_actual.total} /> : '--'}</span>
                            </td>
                            <td style={{ width: "25%" }}
                                className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                <span>{dato_actual.laboratorista_nombre ? dato_actual.laboratorista_nombre : dato_actual.laboratorista.nombre} </span>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
};

let AnalisisBodegasForm = props => {
    const {handleSubmit, item, item_bodega, toogleInfo, ver_info, enviarVaciado, vaciar, enviar_validacion_drc, tsc_promedio, openModal} = props;

    return (
        <form onSubmit={handleSubmit}>

            <div className="form-group grid-container">
                {(item && item.movimiento) && (
                    <div className="d-flex justify-content-end align-items-center verde-secundario mb-3">
                        <div className="d-flex align-items-baseline">
                            <span className="bold mr-2">TIPO BODEGA </span>
                            <h4 className="gris bold mr-3">{item_bodega.tipo_text}</h4>
                            <span className="bold mr-2">NOMBRE</span>
                            <h4 className="gris bold mr-3">{item_bodega.nombre}</h4>
                            <span className="bold mr-2">PRODUCTO</span>
                            <h4 className="gris bold">{item.movimiento.materia_prima.nombre}</h4>
                        </div>
                    </div>
                )}
                <div className="row mb-3">
                    <div className="col-12">
                        <CardEmpty noShadow noPadding>
                            <div className="row col-12 m-0 py-3">
                                <div className="row col-12">
                                    <h6 className="">DATOS DRC</h6>
                                </div>
                                <FieldArray name="datos_drc" component={renderDatosDRC} {...props} />
                            </div>
                        </CardEmpty>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">
                        <CardEmpty noShadow noPadding>
                            <div className="row col-12 m-0 py-3">
                                <div className="row col-12">
                                    <h6 className="">DATOS TSC</h6>
                                </div>
                                <FieldArray name="datos_tsc" component={renderDatosTSC} {...props} />
                            </div>
                        </CardEmpty>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">
                        <CardEmpty noShadow noPadding>
                            <div className="row col-12 m-0 py-3">
                                <div className="row col-12">
                                    <h6 className="">DATOS VFA</h6>
                                </div>
                                <FieldArray name="datos_vfa" component={renderDatosVFA} {...props} />
                            </div>
                        </CardEmpty>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">
                        <CardEmpty noShadow noPadding>
                            <div className="row col-12 m-0 py-3">
                                <div className="row col-12">
                                    <h6 className="">DATOS NH3</h6>
                                </div>
                                <FieldArray name="datos_nh3" component={renderDatosNH3} {...props} />
                            </div>

                            {/*{item.estado === 10 && (*/}
                            {/*    <div className="buttons-box mt-1 mb-2">*/}
                            {/*        <button className="btn btn-outline-dark mr-5"*/}
                            {/*                onClick={event => {*/}
                            {/*                    event.preventDefault();*/}
                            {/*                    openModal('anular_recepcion');*/}
                            {/*                }}>*/}
                            {/*            ANULAR RECEPCIÓN*/}
                            {/*        </button>*/}
                            {/*        <button type="button" className="btn btn-outline-primary mr-5" disabled={!vaciar}*/}
                            {/*                onClick={event => {*/}
                            {/*                    event.preventDefault();*/}
                            {/*                    enviarVaciado(item.id)*/}
                            {/*                }}>*/}
                            {/*            ENVIAR A VACIADO*/}
                            {/*        </button>*/}
                            {/*        <button type="button" className="btn btn-secondary"*/}
                            {/*                onClick={event => {*/}
                            {/*                    event.preventDefault();*/}
                            {/*                }}>*/}
                            {/*            ENVIAR A COÁGULO*/}
                            {/*        </button>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </CardEmpty>
                    </div>
                </div>
                <div style={{textAlign: 'end'}}>ERSA-F-LL-007-07</div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/laboratorio/analisis_pilas">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                    { (item.estado === 10 || item.estado === 40) && (
                        <button type="button" className="ml-5 btn btn-secondary" onClick={handleSubmit(enviar_validacion_drc)} disabled={!vaciar}>
                        ENVIAR A VALIDACIÓN
                        </button>
                    ) }
                </div>
            </div>
        </form>
    )
};
AnalisisBodegasForm = reduxForm({
    form: 'AnalisisBodegasForm',
    initialValues: {
        multiple_ingreso: false,
        drc: [{}],
        tsc: [{}],
        vfa: [{}],
        nh3: [{}]
    },
})(AnalisisBodegasForm);

const selector = formValueSelector('AnalisisBodegasForm');
AnalisisBodegasForm = connect(state => {
    const form = state.form.AnalisisBodegasForm;
    let tsc_promedio = 0;
    let drc_promedio = 0;
    let vfa = 0;
    let nh3 = 0;
    let vaciar = false;
    if (form && form.values) {
        const values = form.values;

        //NH3
        if (values.datos_nh3) {
            if (values.datos_nh3.length > 0) {
                nh3 = values.datos_nh3.slice(-1).pop();
                nh3 = nh3.total;
                // nh3 = nh3.activo === true ? nh3.total : 0;
                form.values.nh3 = nh3.toFixed(4);
            }
            else {
                nh3 = 0;
                form.values.nh3 = nh3.toFixed(4);
            }

        }
        else {
            nh3 = 0;
            form.values.nh3 = nh3.toFixed(4);
        }

        //VFA
        let count_vfa = 0;
        if (values.datos_vfa) {
            let sumatoria_vfa = 0;
            values.datos_vfa.forEach((dato) => {
                if (dato.total) {
                    if (dato.hasOwnProperty('activo')) {
                        if (dato.activo === true) {
                            count_vfa += 1;
                            sumatoria_vfa += dato.total
                        }
                    } else {
                        sumatoria_vfa += dato.total;
                        count_vfa += 1;
                    }
                }
            });
            form.values.vfa = (sumatoria_vfa / count_vfa)/100;
            vfa = (sumatoria_vfa / count_vfa)/100;
        }
        //Calculo de Promedio TSC
        let count_tsc = 0;
        if (values.datos_tsc) {
            let sumatoria_tsc = 0;
            values.datos_tsc.forEach((dato) => {
                if (dato.total) {
                    if (dato.hasOwnProperty('activo')) {
                        if (dato.activo === true) {
                            count_tsc += 1;
                            sumatoria_tsc += dato.total
                        }
                    } else {
                        sumatoria_tsc += dato.total;
                        count_tsc += 1;
                    }
                }
            });
            form.values.tsc_promedio = sumatoria_tsc / count_tsc;
            tsc_promedio = sumatoria_tsc / count_tsc;
            tsc_promedio = tsc_promedio.toFixed(4);
        }
        //Calculo de Promedio DR
        let count_drc = 0;
        if (values.datos_drc) {
            let sumatoria_drc = 0;
            values.datos_drc.forEach((dato) => {
                if (dato.total) {
                    if (dato.hasOwnProperty('activo')) {
                        if (dato.activo === true) {
                            count_drc += 1;
                            sumatoria_drc += dato.total
                        }
                    } else {
                        sumatoria_drc += dato.total;
                        count_drc += 1;
                    }
                }
            });
            form.values.drc_promedio = sumatoria_drc / count_drc;
            drc_promedio = sumatoria_drc / count_drc;
        }
        if (values.vfa && values.tsc_promedio)
            vaciar = true;
    }
    return {
        c: 0,
        tsc_promedio,
        drc_promedio,
        vfa,
        nh3,
        vaciar
    }
})(AnalisisBodegasForm);

export default AnalisisBodegasForm
