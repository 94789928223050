import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, } from 'redux-form';
import { validate, validators } from 'validate-redux-form';
import Alerta from '../../../../Utils/Alerta/Alerta';
import { SelectField, renderNumberKG, renderTextArea } from '../../../../Utils/renderField/renderField';

let ReajusteForm = props => {
    const { handleSubmit, productos_almacen } = props;
    const materias_select = [];
    productos_almacen && productos_almacen.data && productos_almacen.data.results.forEach(item => {
        materias_select.push({ value: item.materia.id, label: item.materia.nombre });
    });
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column">
                <Alerta texto="Para reajustar, la justificación es requerida." />
                <div className="row col-12 col-md-8 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label htmlFor="materia_id" className="m-0">MATERIA</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="materia_id" component={SelectField} options={materias_select} className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-12 col-md-8 p-0">
                    <div className="col-6 p-0">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label htmlFor="existencia_inicial" className="m-0">EXISTENCIA ACTUAL</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="existencia_inicial" component={renderNumberKG} type="text" className="form-control" disabled />
                        </div>
                    </div>
                    <div className="col-6 p-0">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label htmlFor="existencia_final" className="m-0">EXISTENCIA FINAL</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="existencia_final" component={renderNumberKG} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12 text-center">
                            <label htmlFor="justificacion" className="m-0">INGRESAR JUSTIFICACIÓN</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="justificacion" component={renderTextArea} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="col-12 row buttons-box mt-1 d-flex justify-content-center">
                    <button className="btn btn-outline-dark mr-md-5" onClick={(e)=> {e.preventDefault(); props.closeModal()}}>CANCELAR</button>
                    <button type="submit" className="btn btn-primary">ACTUALIZAR</button>
                </div>
            </div>
        </form>
    )
};
ReajusteForm = reduxForm({
    form: 'reajusteSolidoForm',
    validate: data => {
        return validate(data, {
            'materia_id': validators.exists()('Campo requerido.'),
            // 'existencia_inicial': validators.exists()('Campo requerido.'),
            'existencia_final': validators.exists()('Campo requerido.'),
            'justificacion': validators.exists()('Campo requerido.'),
        })
    }
})(ReajusteForm);

ReajusteForm = connect(state => {
    const productos_almacen = state.bodegas.productos_almacen;
    const form = state.form.reajusteSolidoForm;
    if (form && form.values && form.values.materia_id) {
        let productos = productos_almacen && productos_almacen.data && productos_almacen.data.results ? productos_almacen.data.results.filter(prod => prod.materia.id === form.values.materia_id) : [];
        productos.length > 0 && (form.values.existencia_inicial = productos[0].existencias);
    }
    return {}
})(ReajusteForm);

export default ReajusteForm
