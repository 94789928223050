import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/laboratorio';
import LaboratorioSolidoCrear from './LaboratorioSolidoCrear';

const mstp = state => {
    return {
       ...state.laboratorio,
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(LaboratorioSolidoCrear)
