import React, { Fragment, useEffect, useState } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import { connect } from "react-redux";
import Grid from "../../../Utils/Grid";
import { actions } from "../../../../../redux/modules/anual_proveedores";

export const TablaFinca = (props) => {
    const {
        page,
        data,
        data_full_to_pdf,
        obtener_data,
        loadingPrint = false,
    } = props;
    useEffect(() => {
        obtener_data();
    }, []);

    const fincas = data_full_to_pdf.results.map((item) => item.finca_nombre);
    const anios = data_full_to_pdf.results.map((item) => item.anio);
    const productos = data_full_to_pdf.results.map((item) => item.producto_nombre);
    const fincasSinDuplicados = fincas.filter((item, index) => fincas.indexOf(item) === index);
    const aniosSinDuplicados = anios.filter((item, index) => anios.indexOf(item) === index);
    const productosSinDuplicados = productos.filter((item, index) => productos.indexOf(item) === index);
    
    let dataReport = {};
    if (loadingPrint) {
        dataReport = data_full_to_pdf;
    } else {
        dataReport = data;
    }

    return (
        <Grid
            hover
            striped
            data={dataReport}
            className="pb-4 pt-3"
            tiitleGrid={loadingPrint ? "Reporte Anual Proveedores" : null}
            componentView={
                loadingPrint ? (
                    <div className="d-flex justify-content-between w-100">
                        <div className="mr-3">
                            <strong>Finca:</strong>
                            <br />
                            {fincasSinDuplicados.join(", ")}
                        </div>
                        <div className="mr-3">
                            <strong>Año:</strong>
                            <br />
                            {aniosSinDuplicados.join(", ")}
                        </div>
                        <div className="mr-3">
                            <strong>Producto:</strong>
                            <br />
                            {productosSinDuplicados.join(", ")}
                        </div>
                    </div>
                )
                : null
            }
            page={loadingPrint ? null : page}
            pagination={!loadingPrint}
            onPageChange={loadingPrint ? null : obtener_data}
        >
            <TableHeaderColumn
                dataField="id"
                isKey
                width="2%"
                dataFormat={(cell) => <div></div>}
            />
            <TableHeaderColumn dataField="finca_nombre">
                FINCA
            </TableHeaderColumn>
            <TableHeaderColumn dataField="anio">AÑO</TableHeaderColumn>
            <TableHeaderColumn dataField="producto_nombre">
                PRODUCTO
            </TableHeaderColumn>
            <TableHeaderColumn
                dataField="kg_secos_producidos"
                dataAlign="right"
                dataFormat={(cell) => {
                    return cell ? cell + " KG" : "0 KG";
                }}
            >
                KG SECOS
            </TableHeaderColumn>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    ...state.anual_proveedores,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaFinca);
