import React, {Component} from 'react';
import Modal from 'react-responsive-modal';
import CambioDRCForm from "./cambioDRCForm";
import moment from "moment/moment";
import {
    FLETE_INTERNO_COSTO_POR_KG, FLETE_EXTERNO_COSTO_POR_KG, FLETE_EXTERNO_COSTO_FIJO, FLETE_COSTO_NO_DEFINIDO,
    SOLIDO, LATEX,
} from '../../../../../../utility/constants';

const eliminar_img = require('assets/img/icons/borrar.png');
const ver_img = require('assets/img/icons/ver.png');
const editar_img = require('assets/img/icons/editar.png');
const activar_img = require('assets/img/icons/activar.png');
const desactivar_img = require('assets/img/icons/desactivar.png');
const pesa = require('assets/img/icons/pesa.png');

export default class cambioModal extends Component {
    actualizarDRC =  async (data) =>{
        await this.props.actualizarDRCFinal(this.props.item.analisis[0].id, data, this.props.id_compra, this.props.item,true)
        await this.props.actualizarPrecioFinal(this.props.item.id, data, this.props.id_compra, this.props.item.finca.id)
        await this.props.closeModal()
    };

    render() {
        const {item, open, closeModal} = this.props;
        // Determinación del tipo y costo del flete asociado a la recepción, en base al transportista y el tipo de materia prima
        let tipo_costo_flete = FLETE_INTERNO_COSTO_POR_KG;
        let costo_flete = 0;
        const tipo_materia_suffix = item.tipo_materia === SOLIDO ? 'solido' : 'latex';
        if (item.vehiculo && item.finca) {
            if (item.vehiculo && item.vehiculo.transportista === null) {
                tipo_costo_flete = FLETE_INTERNO_COSTO_POR_KG;
            } else {
                // Se toma el tipo_costo_flete definido en la finca, para el tipo de materia
                tipo_costo_flete = item.finca[`tipo_costo_flete_externo_${tipo_materia_suffix}`];
            }
            switch(tipo_costo_flete) {
                case FLETE_EXTERNO_COSTO_POR_KG:
                case FLETE_EXTERNO_COSTO_FIJO:
                    // tipo_costo_flete puede ser por kg_seco o por flete. En este punto no se determina cuál es el
                    // costo por kg_seco, solamente se guardará en el registro porque al recuperarlo es cuando se realizará
                    // el cálculo para obtener el costo_flete total y/o por kg_seco
                    costo_flete = item.finca[`costo_flete_externo_${tipo_materia_suffix}`];
                    break;
                default:
                    if (item.tipo_costo_flete == FLETE_COSTO_NO_DEFINIDO) {
                        costo_flete = item.finca[`costo_flete_kg_interno_${tipo_materia_suffix}`];
                    } else {
                        costo_flete = item.costo_flete;
                    }
                    break;
            }
        }
        return (
            <Modal open={open} onClose={closeModal} center={true} closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false}>
                <div className="row p-0">
                    <div className="col-12 row">
                        <div className="col-12 text-center">
                            <h2>DATOS DRC</h2>
                        </div>
                    </div>
                    <div className="col-12 row mb-4 mt-3">
                        <div className="col-2"></div>
                        <div className="col-9 row">
                            <div className="col-12 col-md-5 p-0 m-0">
                                <div className="col-md-12 col-sm-12">
                                    <label>ID RECEPCIÓN</label>
                                    <span className="ml-2 mb-1 text-dark"><b>{item.id_ingreso ? item.id_ingreso.toString().padStart(5, 0) : ''.padStart(5, 0)}</b></span>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <label>FINCA</label>
                                    <span className="ml-2 mb-1">{item.finca ? item.finca.nombre : ''}</span>
                                </div>
                            </div>
                            <div className="col-12 col-md-7 p-0 m-0">
                                <div className="col-md-12 col-sm-12">
                                    <label>PRODUCTO</label>
                                    <span className="ml-2 mb-1 text-dark" style={{fontSize: '0.8rem'}}><b>{item ? item.materia_prima_nombre : ""}</b></span>
                                </div>
                                <div className="col-md-12 col-sm-12">
                                    <label>FECHA DE TRANSACCIÓN</label>
                                    <span className="ml-2 mb-1">{moment().format("DD/MM/YYYY")}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-1"></div>
                    </div>
                    <CambioDRCForm
                        onSubmit={this.actualizarDRC}
                        closeModal={closeModal}
                        datos_drc={item}
                        initialValues = {{
                            drc_radioB: item && item.analisis && item.analisis[0] && item.analisis[0].tipo_drc_final ? item.analisis[0].tipo_drc_final : 30,
                            drc_anterior: item ? item.drc_anterior : 0,
                            drc_promedio: item ? item.drc_promedio : 0,
                            laboratorio: (item && item.analisis) ? (item.analisis[0].drc_promedio ? item.analisis[0].drc_promedio : 0) : 0,
                            definido: (item && item.analisis) ? (item.analisis[0].drc_modificaco === true ? (item.analisis[0].drc_final * 100) : "") : "",
                            tipo_precioB: item ? item.tipo_precio : 0,
                            precio_ersa: item ? item.precio_ersa : 0,
                            diferencial: item ? item.diferencial : 0,
                            precio_definido: item ? item.precio_venta : 0,
                            tipo_costo_flete,
                            costo_flete,
                        }}
                    />
                </div>
            </Modal>
        );
    }
}
cambioModal.propTypes = {};

