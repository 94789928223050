import React, { Fragment } from 'react';
import CardEmpty from 'Utils/Cards/CardEmpty';
import {TableHeaderColumn} from 'react-bootstrap-table';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import moment from 'moment/moment';
import Grid from '../../../Utils/Grid';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { RenderKilogramo } from '../../../Utils/renderField/renderReadField';
import { renderLegend } from '../components';

const COLORES_GRAFICOS = [
    { color: '#0c3856', veces_utilizado: 0 },
    { color: '#359d9e', veces_utilizado: 0 },
    { color: '#55c595', veces_utilizado: 0 },
    { color: '#7ce495', veces_utilizado: 0 },
    { color: '#bceb99', veces_utilizado: 0 },
    { color: '#678a74', veces_utilizado: 0 },
    { color: '#8ec640', veces_utilizado: 0 },
    { color: '#c9d99e', veces_utilizado: 0 },
];
let CONTADOR_COLORES_REPETIDOS = 1;

const renderTooltip = (props) => {
    const { active, payload, label } = props;
    if (active) {
        return (
            <CardEmpty noShadow>
                <div className="d-flex flex-column pb-2">
                    <span className="mb-2">Fecha: {label}</span>
                    { payload && payload.map(item => {
                        const dataKey = item.dataKey.replace('y_value_', '');
                        return (
                            <div className="d-flex flex-row justify-content-between">
                                <span className="mr-2">{item.payload.materias[dataKey].nombre}:</span>
                                <span className="mr-3"><RenderKilogramo value={item.payload.materias[dataKey].peso_neto} /></span>
                            </div>
                        );
                    }) }
                </div>
            </CardEmpty>
        );
    }
    return null;
};

const Reporte = (props) => {
    const {
        reporte_traslados: reporte,
        getReporteTrasladosMateria: pageChange, loader,
    } = props;
    const page = reporte.solido_page;
    // Procesamiento de la información para generar la gráfica de barras
    let fecha_inicial = reporte.solido_fecha_inicial, fecha_final = reporte.solido_fecha_final;
    let TOTALES_TRASLADO = {peso_neto: 0};
    let mensajeError = '';
    let diferenciaDias = 0;
    let listaObjs = [], dataChart = [], totalesMateria = {};
    if (fecha_inicial === null || fecha_final === null) {
        mensajeError = 'FECHA INICIAL O FINAL NULO';
    } else if (fecha_final.diff(fecha_inicial, 'days') < 0) {
        mensajeError = 'FILTRO DE FECHAS NO VÁLIDO';
    } else {
        diferenciaDias = fecha_final.diff(fecha_inicial, 'days');
        listaObjs = Array(diferenciaDias + 1);
        let iterFecha = moment(fecha_inicial).clone();
        for (let index = 0; index < listaObjs.length; index++) {
            listaObjs[index] = {
                fecha: iterFecha.format('D/M/YY'),
                num_dia: 'Día '+ (index + 1),
                peso_neto: 0,
                materias: {},
            };
            iterFecha.add(1, 'days');
        }
        const movimientosAll = reporte.traslados && reporte.traslados.all_results ? reporte.traslados.all_results : [];
        // Sumatoria de peso_neto de todos los traslados, agrupados por día
        let cpFechaInicio = moment(fecha_inicial.format("YYYY-MM-DD")+' 00:00:00');
        diferenciaDias >= 0 && movimientosAll && movimientosAll.forEach((movimiento) => {
            const materiaPrima = movimiento.materia_prima;
            let cpFechaIngreso = moment(moment(movimiento.creado).format("YYYY-MM-DD")+' 00:00:00');
            const index = cpFechaIngreso.diff(cpFechaInicio, 'days');
            if (index < listaObjs.length && index >= 0) {
                listaObjs[index].peso_neto+= movimiento.peso_neto;
                let materia = listaObjs[index].materias[materiaPrima.id];
                if (materia === undefined) {
                    materia = { ...materiaPrima, peso_neto: 0 };
                    listaObjs[index].materias[materiaPrima.id] = materia;
                }
                materia.peso_neto+= movimiento.peso_neto;
            }
            let materia = totalesMateria[materiaPrima.id];
            if (materia === undefined) {
                materia = {...materiaPrima, peso_neto: 0,};
                totalesMateria[materiaPrima.id] = materia;
                // Se busca alguno de los colores disponibles y se asigna a la materia actual.
                // Si todos han sido asignados, se aumenta el contador de repeticiones y se vuelve a buscar.
                let continuar = false;
                while (!continuar) {
                    for (let color of COLORES_GRAFICOS) {
                        if (color.veces_utilizado < CONTADOR_COLORES_REPETIDOS) {
                            totalesMateria[materiaPrima.id].COLOR_GRAFICO = color.color;
                            color.veces_utilizado++;
                            break;
                        }
                    }
                    if (totalesMateria[materiaPrima.id].COLOR_GRAFICO === undefined) {
                        CONTADOR_COLORES_REPETIDOS++;
                    } else {
                        continuar = true;
                    }
                }
            }
            materia.peso_neto+= movimiento.peso_neto;
        });
        // Construcción de la data para el gráfico
        listaObjs.forEach(obj => {
            if (obj.materias[1] && obj.materias[2] && obj.materias[27]){
                if (obj.materias[1].peso_neto != 0 || obj.materias[2].peso_neto != 0, obj.materias[27].peso_neto != 0) {
                    const data = {
                        num_dia: obj.num_dia,
                        x_value: obj.fecha,
                        peso_neto: obj.peso_neto,
                        materias: obj.materias,
                    }
                    Object.values(obj.materias).forEach(materia => {
                        data[`y_value_${materia.id}`] = materia.peso_neto;
                    });
                    data[`y_value`] = obj.peso_neto;
                    dataChart.push(data);
                    TOTALES_TRASLADO.peso_neto+= obj.peso_neto;
                }
            }else if (obj.materias[1] && obj.materias[2]){
                if (obj.materias[1].peso_neto != 0 || obj.materias[2].peso_neto != 0) {
                    const data = {
                        num_dia: obj.num_dia,
                        x_value: obj.fecha,
                        peso_neto: obj.peso_neto,
                        materias: obj.materias,
                    }
                    Object.values(obj.materias).forEach(materia => {
                        data[`y_value_${materia.id}`] = materia.peso_neto;
                    });
                    data[`y_value`] = obj.peso_neto;
                    dataChart.push(data);
                    TOTALES_TRASLADO.peso_neto+= obj.peso_neto;
                }
            }else if (obj.materias[2] && obj.materias[27]){
                if (obj.materias[27].peso_neto != 0 || obj.materias[2].peso_neto != 0) {
                    const data = {
                        num_dia: obj.num_dia,
                        x_value: obj.fecha,
                        peso_neto: obj.peso_neto,
                        materias: obj.materias,
                    }
                    Object.values(obj.materias).forEach(materia => {
                        data[`y_value_${materia.id}`] = materia.peso_neto;
                    });
                    data[`y_value`] = obj.peso_neto;
                    dataChart.push(data);
                    TOTALES_TRASLADO.peso_neto+= obj.peso_neto;
                }
            }else if (obj.materias[1] && obj.materias[27]){
                if (obj.materias[27].peso_neto != 0 || obj.materias[2].peso_neto != 0) {
                    const data = {
                        num_dia: obj.num_dia,
                        x_value: obj.fecha,
                        peso_neto: obj.peso_neto,
                        materias: obj.materias,
                    }
                    Object.values(obj.materias).forEach(materia => {
                        data[`y_value_${materia.id}`] = materia.peso_neto;
                    });
                    data[`y_value`] = obj.peso_neto;
                    dataChart.push(data);
                    TOTALES_TRASLADO.peso_neto+= obj.peso_neto;
                }
            }else if (obj.materias[1]){
                if (obj.materias[1].peso_neto != 0) {
                    const data = {
                        num_dia: obj.num_dia,
                        x_value: obj.fecha,
                        peso_neto: obj.peso_neto,
                        materias: obj.materias,
                    }
                    Object.values(obj.materias).forEach(materia => {
                        data[`y_value_${materia.id}`] = materia.peso_neto;
                    });
                    data[`y_value`] = obj.peso_neto;
                    dataChart.push(data);
                    TOTALES_TRASLADO.peso_neto+= obj.peso_neto;
                }
            }else if (obj.materias[2]){
                if (obj.materias[2].peso_neto != 0) {
                    const data = {
                        num_dia: obj.num_dia,
                        x_value: obj.fecha,
                        peso_neto: obj.peso_neto,
                        materias: obj.materias,
                    }
                    Object.values(obj.materias).forEach(materia => {
                        data[`y_value_${materia.id}`] = materia.peso_neto;
                    });
                    data[`y_value`] = obj.peso_neto;
                    dataChart.push(data);
                    TOTALES_TRASLADO.peso_neto+= obj.peso_neto;
                }
            }else if (obj.materias[27]){
                if (obj.materias[27].peso_neto != 0) {
                    const data = {
                        num_dia: obj.num_dia,
                        x_value: obj.fecha,
                        peso_neto: obj.peso_neto,
                        materias: obj.materias,
                    }
                    Object.values(obj.materias).forEach(materia => {
                        data[`y_value_${materia.id}`] = materia.peso_neto;
                    });
                    data[`y_value`] = obj.peso_neto;
                    dataChart.push(data);
                    TOTALES_TRASLADO.peso_neto+= obj.peso_neto;
                }
            }

        });
    }

    return(
        <LoadMask loading={loader} blur>
            <div className="mb-4">
                <CardEmpty>
                    <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mt-4 mb-4">
                        <div className="col-12 col-md-12 d-flex flex-wrap justify-content-center align-items-center">
                            <div className="col-12 col-md-2 p-0 d-flex flex-row justify-content-center justify-content-md-start align-items-center">
                                <h6 className="m-0 mb-3 mb-md-0 bold">TOTALES</h6>
                            </div>
                            <div className="col-12 col-md-6 col-sm-12 d-flex flex-wrap flex-column flex-md-row p-0 mb-3 mb-md-0">
                                { Object.values(totalesMateria).map(materia => {
                                    return (
                                        <div key={materia.id} className="col-md-6 col-sm-12 d-flex flex-row justify-content-between justify-content-md-between align-items-center mb-1 mb-md-2 px-1 px-md-2">
                                            <span className="mx-2 m-md-0 gris bold">{materia.nombre}</span>
                                            <h5 className="mx-2 m-md-0" style={{whiteSpace: 'nowrap'}}>{<RenderKilogramo value={materia.peso_neto}/>}</h5>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="col-12 col-md-4 p-0">
                                <div className="col-md-12 col-sm-12 d-flex flex-row justify-content-between justify-content-md-between align-items-center">
                                    <span className="m-0 ml-0 ml-md-5 bold">TOTAL</span>
                                    <h3 className="mb-1 ml-3 gris">{<RenderKilogramo value={TOTALES_TRASLADO.peso_neto} />}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardEmpty>
            </div>
            <div className="mb-4">
                <CardEmpty>
                    <div className="d-flex flex-column">
                        <div className="flex-fill d-flex justify-content-start">
                            { mensajeError.length === 0
                                ? <ResponsiveContainer width="85%" height="100%" minWidth={500} minHeight={300}>
                                    <BarChart width={730} height={250} data={dataChart}>
                                        <defs>
                                            <linearGradient id="colorLatex" x1="0%" y1="0%" x2="0%" y2="100%">
                                                <stop offset="0%" stopColor="#55c4d4" />
                                                <stop offset="100%" stopColor="#8ec640" />
                                            </linearGradient>
                                            <linearGradient id="colorSolido" x1="0%" y1="0%" x2="0%" y2="100%">
                                                <stop offset="0%" stopColor="#55c4d4" />
                                                <stop offset="100%" stopColor="#035192" />
                                            </linearGradient>
                                        </defs>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="x_value" />
                                        <YAxis />
                                        <Tooltip content={renderTooltip} />
                                        <Legend content={renderLegend} align="left" verticalAlign="middle" layout="vertical" iconType="circle" title="GRÁFICA DE TRASLADOS" />
                                        { Object.values(totalesMateria).map(materia => (
                                            <Bar dataKey={`y_value_${materia.id}`} value={materia.nombre} stackId="a" fill={materia.COLOR_GRAFICO} />
                                        )) }
                                    </BarChart>
                                </ResponsiveContainer>
                                : <div className="mb-3 rojo bold">{mensajeError}</div>
                            }
                        </div>
                    </div>
                </CardEmpty>
            </div>
            {/* TABLA CON LOS RESÚMENES */}
            <div className="mb-5">
                <CardEmpty>
                    <Grid hover striped loading={loader} data={reporte.traslados}
                        pagination onPageChange={pageChange} page={page}
                        className="pb-4 pt-3"
                    >
                        <TableHeaderColumn
                            dataField="id"
                            isKey
                            width="5%"
                            dataFormat={cell => <div></div>}
                        />
                        <TableHeaderColumn
                            dataField="creado"
                            dataFormat={cell => cell ? moment(cell).format('DD/MM/YYYY') : '--'}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="materia_prima"
                            dataFormat={cell => cell ? cell.nombre : '--'}
                        >
                            PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="bodega"
                            dataFormat={cell => cell ? cell.nombre : '--'}
                        >
                            ORIGEN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="acopio"
                            dataFormat={cell => cell ? cell.nombre : 'Planta ERSA'}
                        >
                            ACOPIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="movimientos_entrada"
                            dataFormat={
                                cell => cell && cell.length === 1 ? (cell[0] && cell[0].destino ? cell[0].destino.nombre : '--') : '--'
                            }
                        >
                            DESTINO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="movimientos_entrada"
                            dataFormat={
                                cell => cell && cell.length === 1 ? (cell[0] && cell[0].acopio ? cell[0].acopio.nombre : 'Planta ERSA') : '--'
                            }
                        >
                            ACOPIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="peso_neto"
                            dataAlign="right"
                            dataFormat={cell => cell ? <RenderKilogramo value={cell} /> : '--'}
                        >
                            PESO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="id"
                            width="5%"
                            dataFormat={cell => <div></div>}
                        />
                    </Grid>
                </CardEmpty>
            </div>
        </LoadMask>
    )
};

export default Reporte;
