import React, {Component} from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../FiltrosHeader';
import Reporte from './Reporte';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import { actions as printActions } from 'Utils/Print';

export default class ReporteDRCAnual extends Component {
    /* componentWillMount() {
        const { dataReporteDRCAnual } = this.props;
        dataReporteDRCAnual();
    } */

    imprimirBoleta = (event) => {
        printActions.print('reporte-drc-anual');
    }

    render() {
        const {
            filtroReporteDRCAnualProductos,
            filtroReporteDRCAnualPerfilFinca,
            filtroReporteDRCAnualAnio,
            filtro_anio_reporte_drc,
            filtro_perfil_finca_reporte_drc,
            filtro_productos_reporte_drc,
            descargarReporteDRCAnual,
            dataReporteDRCAnual,
        } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="DRC ANUAL"
                    noBorder
                    subtitulo="Reporte">
                    <CardEmpty>
                        <FiltrosHeader {...this.props}
                            filtroProductosChange={filtroReporteDRCAnualProductos} productos_ids={filtro_productos_reporte_drc}
                            filtroPerfilChange={filtroReporteDRCAnualPerfilFinca} perfiles_finca_ids={filtro_perfil_finca_reporte_drc}
                            filtroAnioChange={filtroReporteDRCAnualAnio} anio_reporte={filtro_anio_reporte_drc}
                            botonImprimir={this.imprimirBoleta} botonExportar={descargarReporteDRCAnual}
                            filtrar={dataReporteDRCAnual}
                        />
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <Reporte {...this.props}></Reporte>
                </Card>
            </div>
        )
    }
}
