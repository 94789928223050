
export const DEPARTAMENTOS = {
  '01': 'Alta Verapaz',
  '02': 'Baja Berapaz',
  '03': 'Chimaltenango',
  '04': 'Chiquimula',
  '05': 'El Progreso',
  '06': 'Escuintla',
  '07': 'Guatemala',
  '08': 'Huehuetenango',
  '09': 'Izabal',
  '10': 'Jalapa',
  '11': 'Jutiapa',
  '12': 'Petén',
  '13': 'Quetzaltenango',
  '14': 'Quiché',
  '15': 'Retalhuleu',
  '16': 'Sacatepéquez',
  '17': 'San Marcos',
  '18': 'Santa Rosa',
  '19': 'Sololá',
  '20': 'Suchitepéquez',
  '21': 'Totonicapán',
  '22': 'Zacapa'
};

export const MUNICIPIOS = [
  // ALTA VERAPAZ
  {id:'011', nombre: 'Chahal', departamento:"01"},
  {id:'012', nombre: 'Chisec', departamento:"01"},
  {id:'013', nombre: 'Cobán', departamento:"01"},
  {id:'014', nombre: 'Fray Bartolomé de las Casas', departamento:"01"},
  {id:'015', nombre: 'La Tinta', departamento:"01"},
  {id:'016', nombre: 'Lanquín', departamento:"01"},
  {id:'017', nombre: 'Panzós', departamento:"01"},
  {id:'018', nombre: 'Raxruhá', departamento:"01"},
  {id:'019', nombre: 'San Cristóbal Verapaz', departamento:"01"},
  {id:'0110', nombre: 'San Juan Chamelco', departamento:"01"},
  {id:'0111', nombre: 'San Pedro Carchá', departamento:"01"},
  {id:'0112', nombre: 'Santa Cruz Verapaz', departamento:"01"},
  {id:'0113', nombre: 'Santa María Cahabón', departamento:"01"},
  {id:'0114', nombre: 'Senahú', departamento:"01"},
  {id:'0115', nombre: 'Tamahú', departamento:"01"},
  {id:'0116', nombre: 'Tactic', departamento:"01"},
  {id:'0117', nombre: 'Tucurú', departamento:"01"},
  // Baja Verapaz
  {id:'021', nombre: 'Cubulco', departamento:"02"},
  {id:'022', nombre: 'Granados', departamento:"02"},
  {id:'023', nombre: 'Purulhá', departamento:"02"},
  {id:'024', nombre: 'Rabinal', departamento:"02"},
  {id:'025', nombre: 'Salamá', departamento:"02"},
  {id:'026', nombre: 'San Jerónimo', departamento:"02"},
  {id:'027', nombre: 'San Miguel Chicaj', departamento:"02"},
  {id:'028', nombre: 'Santa Cruz el Chol', departamento:"02"},
  // Chimaltenango
  {id:'031', nombre: 'Acatenango', departamento:"03"},
  {id:'032', nombre: 'Chimaltenango', departamento:"03"},
  {id:'033', nombre: 'El Tejar', departamento:"03"},
  {id:'034', nombre: 'Parramos', departamento:"03"},
  {id:'035', nombre: 'Patzicía', departamento:"03"},
  {id:'036', nombre: 'Patzún', departamento:"03"},
  {id:'037', nombre: 'Pochuta', departamento:"03"},
  {id:'038', nombre: 'San Andrés Itzapa', departamento:"03"},
  {id:'039', nombre: 'San José Poaquil', departamento:"03"},
  {id:'0310', nombre: 'San Juan Comalpa', departamento:"03"},
  {id:'0311', nombre: 'San Martín Jilotepeque', departamento:"03"},
  {id:'0312', nombre: 'Santa Apolonia', departamento:"03"},
  {id:'0313', nombre: 'Santa Cruz Balanyá', departamento:"03"},
  {id:'0314', nombre: 'Tecpán', departamento:"03"},
  {id:'0315', nombre: 'Yepocapa', departamento:"03"},
  {id:'0316', nombre: 'Zaragoza', departamento:"03"},
  // Chiquimula
  {id:'041', nombre: 'Camotán', departamento:"04"},
  {id:'042', nombre: 'Chiquimula', departamento:"04"},
  {id:'043', nombre: 'Concepción Las Minas', departamento:"04"},
  {id:'044', nombre: 'Esquipulas', departamento:"04"},
  {id:'045', nombre: 'Ipala', departamento:"04"},
  {id:'046', nombre: 'San Juan Ermita', departamento:"04"},
  {id:'047', nombre: 'Jocotán', departamento:"04"},
  {id:'048', nombre: 'Olopa', departamento:"04"},
  {id:'049', nombre: 'Quetzaltepeque', departamento:"04"},
  {id:'0410', nombre: 'San Jacinto', departamento:"04"},
  {id:'0411', nombre: 'San José la Arada', departamento:"04"},
  // El Progreso
  {id:'051', nombre: 'El Jícaro', departamento:"05"},
  {id:'052', nombre: 'Guastatoya', departamento:"05"},
  {id:'053', nombre: 'Morazán', departamento:"05"},
  {id:'054', nombre: 'San Agustín Acasaguastlán', departamento:"05"},
  {id:'058', nombre: 'San Antonio La Paz', departamento:"05"},
  {id:'056', nombre: 'San Cristóbal Acasaguastlán', departamento:"05"},
  {id:'057', nombre: 'Sanarate', departamento:"05"},
  {id:'058', nombre: 'Sansare', departamento:"05"},
  // Escuintla
  {id:'061', nombre: 'Escuintla', departamento:"06"},
  {id:'062', nombre: 'Guanagazapa', departamento:"06"},
  {id:'063', nombre: 'Iztapa', departamento:"06"},
  {id:'064', nombre: 'La Democracia', departamento:"06"},
  {id:'065', nombre: 'La Gomera', departamento:"06"},
  {id:'066', nombre: 'Masagua', departamento:"06"},
  {id:'067', nombre: 'Nueva Concepción', departamento:"06"},
  {id:'068', nombre: 'Palín', departamento:"06"},
  {id:'069', nombre: 'San José', departamento:"06"},
  {id:'0610', nombre: 'San Vincente Pacaya', departamento:"06"},
  {id:'0611', nombre: 'Santa Lucía Cotzumalguapa', departamento:"06"},
  {id:'0612', nombre: 'Sipacate', departamento:"06"},
  {id:'0613', nombre: 'Siquinilá', departamento:"06"},
  {id:'0614', nombre: 'Tiquisate', departamento:"06"},
  // Guatemala
  {id:'071', nombre: 'Amatitlán', departamento:"07"},
  {id:'072', nombre: 'Chinautla', departamento:"07"},
  {id:'073', nombre: 'Chuarrancho', departamento:"07"},
  {id:'074', nombre: 'Ciudad de Guatemala', departamento:"07"},
  {id:'075', nombre: 'Frajanes', departamento:"07"},
  {id:'076', nombre: 'Mixco', departamento:"07"},
  {id:'077', nombre: 'Palencia', departamento:"07"},
  {id:'078', nombre: 'San José del Golfo', departamento:"07"},
  {id:'079', nombre: 'San José Pinula', departamento:"07"},
  {id:'0710', nombre: 'San Juan Sacatepéquez', departamento:"07"},
  {id:'0711', nombre: 'San Miguel Petapa', departamento:"07"},
  {id:'0712', nombre: 'San Pedro Ayampuc', departamento:"07"},
  {id:'0713', nombre: 'San Pedro Sacatepéquez', departamento:"07"},
  {id:'0714', nombre: 'San Raymundo', departamento:"07"},
  {id:'0715', nombre: 'Santa Catarina Pinula', departamento:"07"},
  {id:'0716', nombre: 'Villa Canales', departamento:"07"},
  {id:'0717', nombre: 'Villa Nueva', departamento:"07"},
  // Huehuetenango
  {id:'081', nombre: 'Aguacatán', departamento:"08"},
  {id:'082', nombre: 'Chiantla', departamento:"08"},
  {id:'083', nombre: 'Colotenango', departamento:"08"},
  {id:'084', nombre: 'Concepción Huista', departamento:"08"},
  {id:'085', nombre: 'Cuilco', departamento:"08"},
  {id:'086', nombre: 'Huehuetenango', departamento:"08"},
  {id:'087', nombre: 'Jacaltenango', departamento:"08"},
  {id:'088', nombre: 'La Democracia', departamento:"08"},
  {id:'089', nombre: 'La Libertad', departamento:"08"},
  {id:'0810', nombre: 'Malacatancito', departamento:"08"},
  {id:'0811', nombre: 'Nentón', departamento:"08"},
  {id:'0812', nombre: 'Petatán', departamento:"08"},
  {id:'0813', nombre: 'San Antonio Huista', departamento:"08"},
  {id:'0814', nombre: 'San Gaspar Ixchil', departamento:"08"},
  {id:'0815', nombre: 'San Ildefonso Ixtahuacán', departamento:"08"},
  {id:'0816', nombre: 'San Juan Atitán', departamento:"08"},
  {id:'0817', nombre: 'San Juan Ixcoy', departamento:"08"},
  {id:'0818', nombre: 'San Mateo Ixtatán', departamento:"08"},
  {id:'0819', nombre: 'San Miguel Acatán', departamento:"08"},
  {id:'0820', nombre: 'San Pedro Nécta', departamento:"08"},
  {id:'0821', nombre: 'San Pedro Soloma', departamento:"08"},
  {id:'0822', nombre: 'San Rafael La Independencia', departamento:"08"},
  {id:'0823', nombre: 'San Rafael Pétzal', departamento:"08"},
  {id:'0824', nombre: 'San Sebastián Coatán', departamento:"08"},
  {id:'0825', nombre: 'San Sebastián Huehuetenango', departamento:"08"},
  {id:'0826', nombre: 'Santa Ana Huista', departamento:"08"},
  {id:'0827', nombre: 'Santa Bárbara', departamento:"08"},
  {id:'0828', nombre: 'Santa Cruz Barillas', departamento:"08"},
  {id:'0829', nombre: 'Santa Eulalia', departamento:"08"},
  {id:'0830', nombre: 'Santiago Chimaltenango', departamento:"08"},
  {id:'0831', nombre: 'Tectitán', departamento:"08"},
  {id:'0832', nombre: 'Todos Santos Cuchumatán', departamento:"08"},
  {id:'0833', nombre: 'Unión Cantinil', departamento:"08"},
  // Izabal
  {id:'091', nombre: 'El Estor', departamento:"09"},
  {id:'092', nombre: 'Livingston', departamento:"09"},
  {id:'093', nombre: 'Los Amates', departamento:"09"},
  {id:'094', nombre: 'Morales', departamento:"09"},
  {id:'095', nombre: 'Puerto Barrios', departamento:"09"},
  // Jalapa
  {id:'101', nombre: 'Jalapa', departamento:"10"},
  {id:'102', nombre: 'Mataquescuintla', departamento:"10"},
  {id:'103', nombre: 'Monjas', departamento:"10"},
  {id:'104', nombre: 'San Carlos Alzatate', departamento:"10"},
  {id:'105', nombre: 'San Luis Jilotepeque', departamento:"10"},
  {id:'106', nombre: 'San Manuel Chaparrón', departamento:"10"},
  {id:'107', nombre: 'San Pedro Pinula', departamento:"10"},
  // Jutiapa
  {id:'111', nombre: 'Agua Blanca', departamento:"11"},
  {id:'112', nombre: 'Asunción Mita', departamento:"11"},
  {id:'113', nombre: 'Atescatempa', departamento:"11"},
  {id:'114', nombre: 'Comapa', departamento:"11"},
  {id:'115', nombre: 'Conguaco', departamento:"11"},
  {id:'116', nombre: 'El Adelanto', departamento:"11"},
  {id:'117', nombre: 'El Progreso', departamento:"11"},
  {id:'118', nombre: 'Jalpatagua', departamento:"11"},
  {id:'119', nombre: 'Jerez', departamento:"11"},
  {id:'1110', nombre: 'Jutiapa', departamento:"11"},
  {id:'1111', nombre: 'Moyuta', departamento:"11"},
  {id:'1112', nombre: 'Pasaco', departamento:"11"},
  {id:'1113', nombre: 'Quesada', departamento:"11"},
  {id:'1114', nombre: 'San José Acatempa', departamento:"11"},
  {id:'1115', nombre: 'Santa Catarina Mita', departamento:"11"},
  {id:'1116', nombre: 'Yupiltepeque', departamento:"11"},
  {id:'1117', nombre: 'Zapotitlán', departamento:"11"},
  // Petén
  {id:'121', nombre: 'Dolores', departamento:"12"},
  {id:'122', nombre: 'El Chal', departamento:"12"},
  {id:'123', nombre: 'Flores', departamento:"12"},
  {id:'124', nombre: 'La Libertad', departamento:"12"},
  {id:'125', nombre: 'Las Cruces', departamento:"12"},
  {id:'126', nombre: 'Melchor de Mencos', departamento:"12"},
  {id:'127', nombre: 'Poptún', departamento:"12"},
  {id:'128', nombre: 'San Andrés', departamento:"12"},
  {id:'129', nombre: 'San Benito', departamento:"12"},
  {id:'1210', nombre: 'San Francisco', departamento:"12"},
  {id:'1211', nombre: 'San José', departamento:"12"},
  {id:'1212', nombre: 'San Luis', departamento:"12"},
  {id:'1213', nombre: 'Santa Ana', departamento:"12"},
  {id:'1214', nombre: 'Sayaxché', departamento:"12"},
  // Quetzaltenango
  {id:'131', nombre: 'Almolonga', departamento:"13"},
  {id:'132', nombre: 'Cabricán', departamento:"13"},
  {id:'133', nombre: 'Cajolá', departamento:"13"},
  {id:'134', nombre: 'Cantel', departamento:"13"},
  {id:'135', nombre: 'Coatepeque', departamento:"13"},
  {id:'136', nombre: 'Colomba Costa Cuca', departamento:"13"},
  {id:'137', nombre: 'Concepción Chiquirichapa', departamento:"13"},
  {id:'138', nombre: 'El Palmar', departamento:"13"},
  {id:'139', nombre: 'Flores Costa Cuca', departamento:"13"},
  {id:'1310', nombre: 'Génova', departamento:"13"},
  {id:'1311', nombre: 'Huitán', departamento:"13"},
  {id:'1312', nombre: 'La Esperanza', departamento:"13"},
  {id:'1313', nombre: 'Olintepeque', departamento:"13"},
  {id:'1314', nombre: 'Palestina de Los Altos', departamento:"13"},
  {id:'1315', nombre: 'Quetzaltenango', departamento:"13"},
  {id:'1316', nombre: 'Salcajá', departamento:"13"},
  {id:'1317', nombre: 'San Carlos Sija', departamento:"13"},
  {id:'1318', nombre: 'San Francisco La Unión', departamento:"13"},
  {id:'1319', nombre: 'San Juan Ostuncalco', departamento:"13"},
  {id:'1320', nombre: 'San Martín Sacatepéquez', departamento:"13"},
  {id:'1321', nombre: 'San Mateo', departamento:"13"},
  {id:'1322', nombre: 'San Miguel Sigüilá', departamento:"13"},
  {id:'1323', nombre: 'Sibilia', departamento:"13"},
  {id:'1324', nombre: 'Zunil', departamento:"13"},
  // Quiché
  {id:'141', nombre: 'Canillá', departamento:"14"},
  {id:'142', nombre: 'Chajul', departamento:"14"},
  {id:'143', nombre: 'Chicamán', departamento:"14"},
  {id:'144', nombre: 'Chiché', departamento:"14"},
  {id:'145', nombre: 'Santo Tomás Chichicastenango', departamento:"14"},
  {id:'146', nombre: 'Chinique', departamento:"14"},
  {id:'147', nombre: 'Cunén', departamento:"14"},
  {id:'148', nombre: 'Ixcán', departamento:"14"},
  {id:'149', nombre: 'Joyabaj', departamento:"14"},
  {id:'1410', nombre: 'Nebaj', departamento:"14"},
  {id:'1411', nombre: 'Pachalum', departamento:"14"},
  {id:'1412', nombre: 'Patzité', departamento:"14"},
  {id:'1413', nombre: 'Sacapulas', departamento:"14"},
  {id:'1414', nombre: 'San Andrés Sajcabajá', departamento:"14"},
  {id:'1415', nombre: 'San Antonio Ilotenango', departamento:"14"},
  {id:'1416', nombre: 'San Bartolomé Jocotenango', departamento:"14"},
  {id:'1417', nombre: 'San Juan Cotzal', departamento:"14"},
  {id:'1418', nombre: 'San Pedro Jocopilas', departamento:"14"},
  {id:'1419', nombre: 'Santa Cruz del Quiché', departamento:"14"},
  {id:'1420', nombre: 'Uspantán', departamento:"14"},
  {id:'1421', nombre: 'Zacualpa', departamento:"14"},
  // Retalhuleu
  {id:'151', nombre: 'Champerico', departamento:"15"},
  {id:'152', nombre: 'El Asintal', departamento:"15"},
  {id:'153', nombre: 'Nuevo San Carlos', departamento:"15"},
  {id:'154', nombre: 'Retalhuleu', departamento:"15"},
  {id:'155', nombre: 'San Andrés Villa Seca', departamento:"15"},
  {id:'156', nombre: 'San Felipe', departamento:"15"},
  {id:'157', nombre: 'San Martín Zapotitlán', departamento:"15"},
  {id:'158', nombre: 'San Sebastián', departamento:"15"},
  {id:'159', nombre: 'Santa Cruz Muluá', departamento:"15"},
  // Sacatepéquez
  {id:'161', nombre: 'Alotenango', departamento:"16"},
  {id:'162', nombre: 'Ciudad Vieja', departamento:"16"},
  {id:'163', nombre: 'Jocotenango', departamento:"16"},
  {id:'164', nombre: 'Antigua Guatemala', departamento:"16"},
  {id:'165', nombre: 'Magdalena Milpas Altas', departamento:"16"},
  {id:'166', nombre: 'Pastores', departamento:"16"},
  {id:'167', nombre: 'San Antonio Aguas Calientes', departamento:"16"},
  {id:'168', nombre: 'San Bartolomé Milpas Altas', departamento:"16"},
  {id:'169', nombre: 'San Lucas Sacatepéquez', departamento:"16"},
  {id:'1610', nombre: 'San Miguel Dueñas', departamento:"16"},
  {id:'1611', nombre: 'Santa Catarina Barahona', departamento:"16"},
  {id:'1612', nombre: 'Santa Lucía Milpas Altas', departamento:"16"},
  {id:'1613', nombre: 'Santa María de Jesús', departamento:"16"},
  {id:'1614', nombre: 'Santiago Sacatepéquez', departamento:"16"},
  {id:'1615', nombre: 'Santo Domingo Xenacoj', departamento:"16"},
  {id:'1616', nombre: 'Sumpango', departamento:"16"},
  // San Marcos
  {id:'171', nombre:'Ayutla', departamento:"17"},
  {id:'172', nombre:'Catarina', departamento:"17"},
  {id:'173', nombre:'Comitancillo', departamento:"17"},
  {id:'174', nombre:'Concepción Tutuapa', departamento:"17"},
  {id:'175', nombre:'El Quetzal', departamento:"17"},
  {id:'176', nombre:'El Tumbador', departamento:"17"},
  {id:'177', nombre:'Esquipulas Palo Gordo', departamento:"17"},
  {id:'178', nombre:'Ixchiguán', departamento:"17"},
  {id:'179', nombre:'La Blanca', departamento:"17"},
  {id:'1710', nombre:'La Reforma', departamento:"17"},
  {id:'1711', nombre:'Malacatán', departamento:"17"},
  {id:'1712', nombre:'Nuevo Progreso', departamento:"17"},
  {id:'1713', nombre:'Ocós', departamento:"17"},
  {id:'1714', nombre:'Pajapita', departamento:"17"},
  {id:'1715', nombre:'Río Blanco', departamento:"17"},
  {id:'1716', nombre:'San Antonio Sacatepéquez', departamento:"17"},
  {id:'1717', nombre:'San Cristóbal Cucho', departamento:"17"},
  {id:'1718', nombre:'San José El Rodeo', departamento:"17"},
  {id:'1719', nombre:'San José Ojetenam', departamento:"17"},
  {id:'1720', nombre:'San Lorenzo', departamento:"17"},
  {id:'1721', nombre:'San Marcos', departamento:"17"},
  {id:'1722', nombre:'San Miguel Ixtahuacán', departamento:"17"},
  {id:'1723', nombre:'San Pablo', departamento:"17"},
  {id:'1724', nombre:'San Pedro Sacatepéquez', departamento:"17"},
  {id:'1725', nombre:'San Rafael Pie de la Cuesta', departamento:"17"},
  {id:'1726', nombre:'Sibinal', departamento:"17"},
  {id:'1727', nombre:'Sipacapa', departamento:"17"},
  {id:'1728', nombre:'Tacaná', departamento:"17"},
  {id:'1729', nombre:'Tajumulco', departamento:"17"},
  {id:'1730', nombre:'Tejutla', departamento:"17"},
  // Santa Rosa
  {id:'181', nombre: 'Barberena', departamento:"18"},
  {id:'182', nombre: 'Casillas', departamento:"18"},
  {id:'183', nombre: 'Chiquimulilla', departamento:"18"},
  {id:'184', nombre: 'Cuilapa', departamento:"18"},
  {id:'185', nombre: 'Guazacapán', departamento:"18"},
  {id:'186', nombre: 'Nueva Santa Rosa', departamento:"18"},
  {id:'187', nombre: 'Oratorio', departamento:"18"},
  {id:'188', nombre: 'Pueblo Nuevo Viñas', departamento:"18"},
  {id:'189', nombre: 'San Juan Tecuaco', departamento:"18"},
  {id:'1810', nombre: 'San Rafael las Flores', departamento:"18"},
  {id:'1811', nombre: 'Santa Cruz Naranjo', departamento:"18"},
  {id:'1812', nombre: 'Santa María Ixhuatán', departamento:"18"},
  {id:'1813', nombre: 'Santa Rosa de Lima', departamento:"18"},
  {id:'1814', nombre: 'Taxisco', departamento:"18"},
  // Sololá
  {id:'191', nombre: 'Concepción', departamento:"19"},
  {id:'192', nombre: 'Nahualá', departamento:"19"},
  {id:'193', nombre: 'Panajachel', departamento:"19"},
  {id:'194', nombre: 'San Andrés Semetabaj', departamento:"19"},
  {id:'195', nombre: 'San Antonio Palopó', departamento:"19"},
  {id:'196', nombre: 'San José Chacayá', departamento:"19"},
  {id:'197', nombre: 'San Juan La Laguna', departamento:"19"},
  {id:'198', nombre: 'San Lucas Tolimán', departamento:"19"},
  {id:'199', nombre: 'San Marcos La Laguna', departamento:"19"},
  {id:'1910', nombre: 'San Pablo La Laguna', departamento:"19"},
  {id:'1911', nombre: 'San Pedro La Laguna', departamento:"19"},
  {id:'1912', nombre: 'Santa Catarina Ixtahuacán', departamento:"19"},
  {id:'1913', nombre: 'Santa Catarina Palopó', departamento:"19"},
  {id:'1914', nombre: 'Santa Clara La Laguna', departamento:"19"},
  {id:'1915', nombre: 'Santa Cruz La Laguna', departamento:"19"},
  {id:'1916', nombre: 'Santa Lucía Utatlán', departamento:"19"},
  {id:'1917', nombre: 'Santa María Visitación', departamento:"19"},
  {id:'1918', nombre: 'Santiago Atitlán', departamento:"19"},
  {id:'1919', nombre: 'Sololá', departamento:"19"},
  // Suchitepéquez
  {id:'201', nombre: 'Chicacao', departamento:"20"},
  {id:'202', nombre: 'Cuyotenango', departamento:"20"},
  {id:'203', nombre: 'Mazatenango', departamento:"20"},
  {id:'204', nombre: 'Patulul', departamento:"20"},
  {id:'205', nombre: 'Pueblo Nuevo Suchitepéquez', departamento:"20"},
  {id:'206', nombre: 'Río Bravo', departamento:"20"},
  {id:'207', nombre: 'Samayac', departamento:"20"},
  {id:'208', nombre: 'San Antonio Suchitepéquez', departamento:"20"},
  {id:'209', nombre: 'San Bernardino', departamento:"20"},
  {id:'2010', nombre: 'San Francisco Zapotitlán', departamento:"20"},
  {id:'2011', nombre: 'San Gabriel', departamento:"20"},
  {id:'2012', nombre: 'San José El Idolo', departamento:"20"},
  {id:'2013', nombre: 'San José La Máquina', departamento:"20"},
  {id:'2014', nombre: 'San Juan Bautista', departamento:"20"},
  {id:'2015', nombre: 'San Lorenzo', departamento:"20"},
  {id:'2016', nombre: 'San Miguel Panán', departamento:"20"},
  {id:'2017', nombre: 'San Pablo Jocopilas', departamento:"20"},
  {id:'2018', nombre: 'Santa Bárbara', departamento:"20"},
  {id:'2019', nombre: 'Santo Domingo Suchitepéquez', departamento:"20"},
  {id:'2020', nombre: 'Santo Tomás La Unión', departamento:"20"},
  {id:'2021', nombre: 'Zunilito', departamento:"20"},
  // Totonicapán
  {id:'211', nombre: 'Momostenango', departamento:"21"},
  {id:'212', nombre: 'San Andrés Xecul', departamento:"21"},
  {id:'213', nombre: 'San Bartolo', departamento:"21"},
  {id:'214', nombre: 'San Cristóbal Totonicapán', departamento:"21"},
  {id:'215', nombre: 'San Francisco El Alto', departamento:"21"},
  {id:'216', nombre: 'Santa Lucía La Reforma', departamento:"21"},
  {id:'217', nombre: 'Santa María Chiquimula', departamento:"21"},
  {id:'218', nombre: 'Totonicapán', departamento:"21"},
  // Zacapa
  {id:'221',nombre: 'Cabañas', departamento:"22"},
  {id:'222',nombre: 'Estanzuela', departamento:"22"},
  {id:'223',nombre: 'Gualán', departamento:"22"},
  {id:'224',nombre: 'Huité', departamento:"22"},
  {id:'225',nombre: 'La Unión', departamento:"22"},
  {id:'226',nombre: 'Río Hondo', departamento:"22"},
  {id:'227',nombre: 'San Diego', departamento:"22"},
  {id:'228',nombre: 'San Jorge', departamento:"22"},
  {id:'229',nombre: 'Teculután', departamento:"22"},
  {id:'2210',nombre: 'Usumatlán', departamento:"22"},
  {id:'2211',nombre: 'Zacapa', departamento:"22"}
];
