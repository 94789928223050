import { api } from "api";
import React, { Component } from 'react';
import classNames from "classnames";
import AsyncSelect from 'react-select/lib/Async';
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import { meses, ANIO_INICIAL, SOLIDO, LATEX } from "../../../utility/constants";
import moment from 'moment';

let listaFincas = [], listaFincasProveedor = [], listaAcopios = [], listaMateriasFiltradas = [], listaMateriasNoFiltradas = [];
// PETICIONES HACIA EL BACKEND
const getFincas = (search = null) => {
    const params = {};
    search !== null && (params.search = search);
    return api.get('finca', {...params })
        .then((data) => { listaFincas = data ? data.results : []; return listaFincas })
        .catch(() => []);
};
const getFincasByProveedor = (params, proveedor_id=null) => {
    return api.get('finca/fincas_por_proveedor', { ...params, proveedor_id, incluir_incompletas: true, })
        .then((data) => { listaFincasProveedor = data ? data.results : []; return listaFincasProveedor })
        .catch(() => []);
};

const getMateriasSinTipo = (search = null) => {
    const params = {};
    search !== null && (params.search = search);
    params.no_paginar = true;
    return api.get('materia_prima', {...params})
        .then((data) => { listaMateriasNoFiltradas = data ? data.results : []; return listaMateriasNoFiltradas })
        .catch(() => []);
};

const getMateriasPorTipo = (tipoMateria = null, search = null) => {
    const params = {};
    search !== null && (params.search = search);
    tipoMateria !== null && (params.tipo_materia_prima__tipo = tipoMateria);
    params.no_paginar = true;
    return api.get('materia_prima', {...params})
        .then((data) => { listaMateriasFiltradas = data ? data.results : []; return listaMateriasFiltradas })
        .catch(() => []);
};

// BÚSQUEDA DE OBJETOS POR ID EN LISTADOS
const getFincaByID = (idFinca) => {
    let fincaSelec = null;
    listaFincas.forEach(finca => finca.id === idFinca && (fincaSelec = finca));
    return fincaSelec;
};
const getFincaProveedorByID = (idFinca) => {
    let fincaSelec = null;
    listaFincasProveedor.forEach(finca => finca.id === idFinca && (fincaSelec = finca));
    return fincaSelec;
};

const getMateriaFiltradaByID = (idMateria) => {
    let materiaSelec = null;
    listaMateriasFiltradas.forEach(materia => materia.id === idMateria && (materiaSelec = materia));
    return materiaSelec;
};
const getMateriaNoFiltradaByID = (idMateria) => {
    let materiaSelec = null;
    listaMateriasNoFiltradas.forEach(materia => materia.id === idMateria && (materiaSelec = materia));
    return materiaSelec;
};


export default class FiltrosDashboard extends Component {
    render() {
        const {
            fechaFinalChange, fechaInicialChange, fecha_inicial, fecha_final,
            filtroFincaChange, finca_id, filtroMesChange, mes, filtroMateriaChange, materia_id,
            filtroFincaProveedorChange, finca_proveedor_id, filtroAnioChange, anio_dashboard, dashboardG,
            filtroMateriaChangeFiltrada, materia_id_filtrada, tipo_materia, proveedor_id = null,
        } = this.props;

        const TIPO_MATERIA = tipo_materia === 'LATEX' ? LATEX : SOLIDO;

        let anios = [];
        let anio_inicial = ANIO_INICIAL, anio_actual = moment().year();

        for (let i = anio_inicial; i < anio_actual+1; i++) {
            anios.push({value: i, label: i})
        }

        getMateriasPorTipo(TIPO_MATERIA);
        return (
            <div className="d-flex flex-wrap justify-content-center align-items-end p-0 m-0">
                <div className="d-flex flex-wrap align-items-end mt-3 mb-3 mr-3" style={{borderRadius: "1em"}}>
                    <div className="p-0">
                        <label className="mb-1 mt-1 mr-2">Filtrar</label>
                    </div>
                </div>
                {(filtroMesChange !== undefined && filtroMesChange !== null) && (
                    <div className={dashboardG === true ? "d-flex flex-wrap flex-column mt-3 mb-3 mr-2 pl-0 pr-0 col-2" : "d-flex flex-wrap flex-column mt-3 mb-3 mr-2 pl-0 pr-0 col-3"}
                    style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Mes</label>
                        </div>
                        <div className="p-0">
                            <Select
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isClearable={true}
                                isSearchable={true}
                                options={meses}
                                placeholder="Todos"
                                onChange={(value) => {filtroMesChange(value)}}
                                value={mes}
                            />
                        </div>
                    </div>
                )}
                {(filtroAnioChange !== undefined && filtroAnioChange !== null) && (
                    <div className={dashboardG === true ? "d-flex flex-wrap flex-column mt-3 mb-3 mr-2 pl-0 pr-0 col-2" : "d-flex flex-wrap flex-column mt-3 mb-3 mr-2 pl-0 pr-0 col-3"}
                    style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Año</label>
                        </div>
                        <div className="p-0">
                            <Select
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                options={anios}
                                placeholder="Filtrar año"
                                onChange={(value) => {filtroAnioChange(value)}}
                                value={anio_dashboard}
                            />
                        </div>
                    </div>
                )}
                {(fechaInicialChange !== undefined && fechaInicialChange !== null) && (
                    <div className="d-flex flex-wrap flex-column mt-3 mb-3 mr-2 pl-0 pr-0 col-3" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Fecha inicio</label>
                        </div>
                        <div className="p-0">
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha de inicio"
                                onChange={(value) => {fechaInicialChange(value)}}
                                selected={fecha_inicial}
                            />
                        </div>
                    </div>
                )}
                {(fechaFinalChange !== undefined && fechaFinalChange !== null) && (
                    <div className="d-flex flex-wrap flex-column mt-3 mb-3 mr-2 pl-0 pr-0 col-3" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Fecha final</label>
                        </div>
                        <div className="p-0">
                            <DatePicker
                                showPopperArrow={false}
                                dateFormat="DD/MM/YYYY"
                                placeholderText="Fecha final"
                                onChange={(value) => {fechaFinalChange(value)}}
                                selected={fecha_final}
                            />
                        </div>
                    </div>
                )}
                {(filtroFincaChange !== undefined && filtroFincaChange !== null) && (
                    <div className={dashboardG === true ? "d-flex flex-wrap flex-column mt-3 mb-3 mr-2 pl-0 pr-0 col-2" : "d-flex flex-wrap flex-column mt-3 mb-3 mr-2 pl-0 pr-0 col-3"}
                    style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Finca</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todas"
                                cacheOptions
                                defaultOptions
                                loadOptions={getFincas}
                                onChange={selected => { filtroFincaChange(selected ? selected.id : -1); }}
                                value={getFincaByID(finca_id)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroMateriaChange !== undefined && filtroMateriaChange !== null) && (
                    <div className={dashboardG === true ? "d-flex flex-wrap flex-column mt-3 mb-3 mr-2 pl-0 pr-0 col-2" : "d-flex flex-wrap flex-column mt-3 mb-3 mr-2 pl-0 pr-0 col-3"}
                    style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Materia</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todos"
                                cacheOptions
                                defaultOptions
                                loadOptions={(search) => getMateriasSinTipo(search)}
                                onChange={selected => { filtroMateriaChange(selected ? selected.id : -1); }}
                                value={getMateriaNoFiltradaByID(materia_id)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroMateriaChangeFiltrada !== undefined && filtroMateriaChangeFiltrada !== null) && (
                    <div className={dashboardG === true ? "d-flex flex-wrap flex-column mt-3 mb-3 mr-2 pl-0 pr-0 col-2" : "d-flex flex-wrap flex-column mt-3 mb-3 mr-2 pl-0 pr-0 col-3"}
                    style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Materia</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todos"
                                cacheOptions
                                defaultOptions={listaMateriasFiltradas}
                                loadOptions={(search) => getMateriasPorTipo(TIPO_MATERIA, search)}
                                onChange={selected => { filtroMateriaChangeFiltrada(selected ? selected.id : -1); }}
                                value={getMateriaFiltradaByID(materia_id_filtrada)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
                {(filtroFincaProveedorChange !== undefined && filtroFincaProveedorChange !== null) && (
                    <div className="d-flex flex-wrap flex-column mt-3 mb-3 mr-2 pl-0 pr-0 col-3" style={{borderRadius: "1em"}}>
                        <div className="p-0">
                            <label className="mb-1 mt-1 mr-2">Finca</label>
                        </div>
                        <div className="p-0">
                            <AsyncSelect
                                key={proveedor_id}
                                className={classNames('react-select-container')}
                                backspaceRemovesValue={false}
                                isSearchable={true}
                                isClearable
                                placeholder="Todas"
                                cacheOptions
                                defaultOptions
                                loadOptions={(params) => getFincasByProveedor(params, proveedor_id)}
                                onChange={selected => { filtroFincaProveedorChange(selected ? selected.id : -1); }}
                                value={getFincaProveedorByID(finca_proveedor_id)}
                                getOptionValue={(option) => (option['id'])}
                                getOptionLabel={(option) => (option['nombre'])}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
