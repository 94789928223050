import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import { renderField, renderSwitch, SelectField } from '../../../Utils/renderField/renderField';
import { tipoUsoEmail } from '../../../../../utility/constants';

let EmailForm = props => {
    const { handleSubmit } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column">
                <div className="row  col-md-6 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">CORREO EMAIL</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="email" component={renderField} type="email" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-6 p-0">
                    <div className="col-12 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="tipoUso" className="m-0">TIPO USO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="tipoUso"
                            component={SelectField}
                            options={tipoUsoEmail}
                            className="form-control"
                            />
                        </div>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/notificaciones">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
EmailForm = reduxForm({
    form: 'EmailForm',
    validate: data => {
        return validate(data, {
            'tipoUso': validators.exists()('Campo requerido.'),
            'email': validators.exists()('Campo requerido.'),
        })
    }
})(EmailForm);

export default EmailForm
