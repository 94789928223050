import React, { Component, useEffect } from "react";
import Card from "Utils/Cards/Card";
import FiltrosHeader from "../FiltrosHeader";
import CardEmpty from "../../../Utils/Cards/CardEmpty";
import Reporte from "./Reporte";

const ReporteNh3Drc = (props) => {
    const {
        tipo_materia_reporte,
        pageReporteChangeLatexProveedores,
        filtroFechaInicioLatexProveedores,
        filtroFechaFinLatexProveedores,
        filtroFincaLatexProveedores,
        filtroMateriaPrimaLatexProveedores,
        page_reporte_latex,
        fecha_inicio_reporte_latex,
        fecha_fin_reporte_latex,
        finca_id_reporte_latex,
        materia_prima_id_reporte_latex,
        filtrarDataProveedores,
        graficaReporte,
        grafica_reporte,
        tipoMateriaChangeProveedores,
        listarDataReportesProveedores,
        me,
        proveedor,
    } = props;
    useEffect(() => {
        tipoMateriaChangeProveedores("LATEX");
        // {me && me.proveedor_admin &&
        //     listarDataReportesProveedores(true);
        //     filtroFincaLatexProveedores(-1);
        // }
    }, [proveedor]);


    let fechaInicialChange = null,
        fechaFinalChange = null,
        fincaChange = null,
        materiaPrimaChange = null,
        pageChange = null;
    let fecha_inicial = null,
        fecha_final = null,
        finca_id = null,
        materia_prima_id = null,
        page = null;
    pageChange = pageReporteChangeLatexProveedores;
    fechaInicialChange = filtroFechaInicioLatexProveedores;
    fechaFinalChange = filtroFechaFinLatexProveedores;
    fincaChange = filtroFincaLatexProveedores;
    materiaPrimaChange = filtroMateriaPrimaLatexProveedores;
    page = page_reporte_latex;
    fecha_inicial = fecha_inicio_reporte_latex;
    fecha_final = fecha_fin_reporte_latex;
    finca_id = finca_id_reporte_latex;
    materia_prima_id = materia_prima_id_reporte_latex;

    return (
        <div className="mb-4 col-12">
            <Card
                icon="laboratorio_activo"
                titulo="REPORTES"
                noBorder
                subtitulo="NH3 VRS DRC"
            >
                <CardEmpty>
                    <FiltrosHeader
                        {...props}
                        fechaInicialChange={fechaInicialChange}
                        fecha_inicial={fecha_inicial}
                        fechaFinalChange={fechaFinalChange}
                        fecha_final={fecha_final}
                        filtroFincaProveedoresChange={fincaChange}
                        finca_proveedores_id={finca_id}
                        filtroMateriaChange={materiaPrimaChange}
                        materia_id={materia_prima_id}
                        tipo_materia={tipo_materia_reporte}
                        filtrar_data_laboratorio={filtrarDataProveedores}
                    />
                </CardEmpty>
                <div className="mb-4" />
                <Reporte
                    {...props}
                    pageChange={pageChange}
                    page={page}
                    graficaReporte={graficaReporte}
                    grafica_reporte={grafica_reporte}
                />
            </Card>
        </div>
    );
};

export default ReporteNh3Drc;