import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/canal_reporte';
import Canal_Reporte from './Canal_Reporte';


const ms2p = (state) => {
  return {
    ...state.canal_reporte,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(Canal_Reporte);
