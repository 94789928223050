import React from 'react';
import { connect } from 'react-redux'
import {validate, validators} from 'validate-redux-form';
import { Field, FieldArray, formValueSelector, reduxForm, } from 'redux-form';
import {renderFieldRadio, renderCurrency, renderNumberPercentage, renderNumberPercentageFixed} from '../../../../Utils/renderField/renderField';
import { RenderNumberPercentage, RenderDollar, RenderCambio, RenderCurrency, RenderKilogramo } from 'Utils/renderField/renderReadField';
import CardEmpty from "Utils/Cards/CardEmpty";
import {DRC_ANTERIOR, DRC_PROMEDIO, LABORATORIO, DEFINIDO, LATEX, SOLIDO, PRECIO_INTROSA, PRECIO_ERSA, PRECIO_DEFINIDO, FLETE_INTERNO_COSTO_POR_KG} from '../../../../../../utility/constants'

let cambioDRCForm = props => {
    const {handleSubmit, closeModal, datos_drc, dato_drc_modal, kilos_secos_drc_modal, total_quetzales_drc_modal,
        total_dolares_drc_modal, actualizarDRC, precio_materia, valor_diferencial, costo_flete} = props;
    return (
        <form onSubmit={handleSubmit}>
            <div className="col-12 row">
                <div className="col-12 row ml-1 mr-5 mb-2">
                    <div className="col-12 col-md-3 p-0">
                        <div className="col-md-12 col-sm-12">
                            <Field
                                label='DRC ANTERIOR'
                                type="radio"
                                value={DRC_ANTERIOR}
                                name="drc_radioB"
                                parse={val => +val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="col-md-12 m-2 form-group">
                            <Field name="drc_anterior" component={renderNumberPercentage} type="text"
                                className="form-control" disabled
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-3 p-0">
                        <div className="col-md-12 col-sm-12">
                            <Field
                                label='DRC PROMEDIO'
                                type="radio"
                                value={DRC_PROMEDIO}
                                name="drc_radioB"
                                parse={val => +val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="col-md-12 m-2 form-group">
                            <Field name="drc_promedio" component={renderNumberPercentage} type="text"
                                className="form-control" disabled
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-3 p-0">
                        <div className="col-md-12 col-sm-12">
                            <Field
                                label='LABORATORIO'
                                type="radio"
                                value={LABORATORIO}
                                checked
                                name="drc_radioB"
                                parse={val => +val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="col-md-12 m-2 form-group">
                            <Field name="laboratorio" component={renderNumberPercentage} type="text"
                                className="form-control" disabled
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-3 p-0">
                        <div className="col-md-12 col-sm-12">
                            <Field
                                label='DEFINIDO'
                                type="radio"
                                value={DEFINIDO}
                                name="drc_radioB"
                                parse={val => +val}
                                component={renderFieldRadio}
                            />
                        </div>
                        <div className="col-md-12 m-2 form-group">
                            <Field name="definido" component={renderNumberPercentageFixed} type="text"
                                className="form-control" decimalScale={2}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-12 ml-3 mr-3">
                    <CardEmpty noShadow>
                        <div className="col-12 row mr-3 mb-3">
                            {/* <div className="col-12 ml-3 mb-2">
                                <h6>PRECIOS</h6>
                            </div> */}
                            <div className="col-12 col-md-3 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <Field
                                        label='INTROSA'
                                        type="radio"
                                        value={PRECIO_INTROSA}
                                        name="tipo_precioB"
                                        parse={val => +val}
                                        component={renderFieldRadio}
                                    />
                                </div>
                                <div className="col-md-12 m-2 form-group">
                                    <Field name="precio_introsa" component={renderCurrency} prefix="$ " type="text"
                                        className="form-control" disabled decimalScale={5}
                                        input={{value: precio_materia}}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-3 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label className="font-weight-bold">DIFERENCIAL</label>
                                </div>
                                <div className="col-md-12 m-2 form-group">
                                    <Field name="diferencial" component={renderCurrency} prefix="$ " type="text"
                                        className="form-control" decimalScale={5}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-3 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <Field
                                        label='ERSA'
                                        type="radio"
                                        value={PRECIO_ERSA}
                                        name="tipo_precioB"
                                        parse={val => +val}
                                        component={renderFieldRadio}
                                    />
                                </div>
                                <div className="col-md-12 m-2 form-group">
                                    <Field name="precio_ersa" component={renderCurrency} prefix="$ " type="text"
                                        className="form-control" disabled decimalScale={5}
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-3 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <Field
                                        label='DEFINIDO'
                                        type="radio"
                                        value={PRECIO_DEFINIDO}
                                        name="tipo_precioB"
                                        parse={val => +val} 
                                        component={renderFieldRadio}
                                    />
                                </div>
                                <div className="col-md-12 m-2 form-group">
                                    <Field name="precio_definido" component={renderCurrency} prefix="$ " type="text"
                                        className="form-control" decimalScale={5}
                                    />
                                </div>
                            </div>
                        </div>
                    </CardEmpty>
                </div>
                {
                    (datos_drc.vehiculo.transportista === null) && (
                        <div className="col-12 ml-3 mr-3 mt-2">
                            <CardEmpty noShadow>
                                <div className="col-12 row mr-3 mb-3">
                                    <div className="col-12 col-md-6 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="costo_flete">FLETE PROPIO DE LA FINCA</label>
                                        </div>
                                        <div className="col-md-6 m-2 form-group">
                                            <Field name="costo_flete" component={renderCurrency} prefix="$ " type="text"
                                                className="form-control" decimalScale={3} />
                                        </div>
                                    </div>
                                </div>
                            </CardEmpty>
                        </div>

                    )
                }
                <div className="mb-3"></div>
                <div className="col-12 row ml-1 mr-4">
                    <div className="col-6 col-md-2 p-0">
                        <div className="col-md-12 col-sm-12 px-0">
                            <label className="m-0" style={{whiteSpace: 'pre'}}>KILOS HÚMEDOS</label>
                        </div>
                        <div className="col-md-12 form-group px-0">
                            <h6 className="m-3">{<RenderKilogramo value={datos_drc.kilos_ingresados.total}/>}</h6>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 p-0">
                        <div className="col-md-12 form-group px-0 px-md-3">
                            <label className="m-0">DRC</label>
                        </div>
                        <div className="col-md-12 form-group px-0 px-md-3">
                            <h6 className="m-3">
                                {dato_drc_modal ? <RenderNumberPercentage value={parseFloat(dato_drc_modal)} /> : '0%'}
                            </h6>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 p-0">
                        <div className="col-md-12 col-sm-12 px-0">
                            <label className="m-0">KILOS SECOS</label>
                        </div>
                        <div className="col-md-12 form-group px-0">
                            <h6 className="m-3">{<RenderKilogramo value={kilos_secos_drc_modal}/>}</h6>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 p-0">
                        <div className="col-md-12 col-sm-12 px-0">
                            <label className="m-0">TOTAL $.</label>
                        </div>
                        <div className="col-md-12 form-group px-0">
                            <h6 className="m-3">{<RenderDollar value={total_dolares_drc_modal} />}</h6>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 p-0">
                        <div className="col-md-12 col-sm-12 px-0">
                            <label className="m-0">TOTAL Q.</label>
                        </div>
                        <div className="col-md-12 form-group px-0">
                            <h6 className="m-3">{<RenderCurrency value={total_quetzales_drc_modal} />}</h6>
                        </div>
                    </div>
                    <div className="col-6 col-md-2 p-0">
                        <div className="col-md-12 col-sm-12 px-0">
                            <label className="m-0">CAMBIO</label>
                        </div>
                        <div className="col-md-12 form-group px-0">
                            <h6 className="m-3">{<RenderCambio value={datos_drc.tipo_cambio} decimalScale={5} />}</h6>
                        </div>
                    </div>
                </div>
            </div>
            <div className="buttons-box mt-3">
                <button className="btn btn-outline-dark mr-5"
                    onClick={e => {
                        e.preventDefault();
                        closeModal()
                    }}>
                    CANCELAR
                </button>
                <button type="submit" className="btn btn-primary">
                    VALIDAR
                </button>
            </div>
        </form>
    )
};

cambioDRCForm = reduxForm({
    form: 'cambioDRCForm',
    initialValues:{
        // drc_radioB: 30,
    }
})(cambioDRCForm);

const selector = formValueSelector('cambioDRCForm');
cambioDRCForm = connect(state => {
    const datos_drc = state.compras.datos_drc;
    const form_drc = state.form.cambioDRCForm;
    let dato_drc_modal = 0;
    let dato_precio_modal = 0;
    let kilos_secos_drc_modal = 0;
    let total_quetzales_drc_modal = 0;
    let total_dolares_drc_modal = 0;
    let precio_materia = 0;
    let drc_promedio = 0;
    let valor_diferencial = 0;

    if (form_drc && form_drc.values && datos_drc) {
        const values = form_drc.values;
        drc_promedio = datos_drc ? datos_drc.drc_anterior : 0;

        // AQUI SE EVALUA QUE DRC ESTA SIENDO ESCOGIDO Y SE LE ASIGNA EL VALOR AL DRC_FINAL//
        if (values.drc_radioB === DRC_ANTERIOR) {
            dato_drc_modal = datos_drc ? datos_drc.drc_anterior : 0;
        }
        if (values.drc_radioB === DRC_PROMEDIO) {
            dato_drc_modal = datos_drc ? datos_drc.drc_promedio : 0;
        }
        if (values.drc_radioB === LABORATORIO) {
            dato_drc_modal = (datos_drc && datos_drc['analisis'][0]) ? (datos_drc['analisis'][0]['drc_promedio'] ? datos_drc['analisis'][0]['drc_promedio'] : 0) : 0;
        }
        if (values.drc_radioB === DEFINIDO) {
            dato_drc_modal = values.definido === undefined ? 0 : (values.definido/100);
        }

        kilos_secos_drc_modal = dato_drc_modal === 0 ? 0 : (datos_drc.kilos_ingresados.total * dato_drc_modal);

        // SE EVALUAN LOS TIPOS DE PRECIO DE INTROSA PARA LATEX O PARA SOLIDO //
        if (datos_drc.tipo_materia === LATEX) {
            precio_materia = datos_drc.precios_latex.precio_kilogramo;
        }
        if (datos_drc.tipo_materia === SOLIDO) {
            precio_materia = datos_drc.precios_solido.precio_kilogramo
        }
        // AQUI SE EVALUA QUE PRECIO ES EL QUE ESTA SIENDO ESCOGIDO //
        if (values.tipo_precioB === PRECIO_INTROSA) {
            valor_diferencial = values.diferencial === undefined ? 0 : values.diferencial;
            dato_precio_modal = parseFloat(precio_materia) + parseFloat(valor_diferencial);
        }
        if (values.tipo_precioB === PRECIO_ERSA) {
            dato_precio_modal = values.precio_ersa === undefined ? 0 : values.precio_ersa;
        }
        if (values.tipo_precioB === PRECIO_DEFINIDO) {
            dato_precio_modal = values.precio_definido === undefined ? 0 : values.precio_definido;
        }
        dato_precio_modal = parseFloat(dato_precio_modal);
        // Sumando el costo de flete
        if (values.tipo_costo_flete == FLETE_INTERNO_COSTO_POR_KG) {
            dato_precio_modal+= values.costo_flete == '' ? 0 : parseFloat(values.costo_flete);
        }

        if (dato_drc_modal !== 0 && dato_precio_modal !== 0 && !isNaN(dato_precio_modal)) {
            total_quetzales_drc_modal = kilos_secos_drc_modal === 0 ? 0 : ((kilos_secos_drc_modal * dato_precio_modal) * datos_drc.tipo_cambio);
            total_dolares_drc_modal = kilos_secos_drc_modal === 0 ? 0 : (kilos_secos_drc_modal * dato_precio_modal);
        }
    }
    return {
        dato_drc_modal,
        kilos_secos_drc_modal,
        total_quetzales_drc_modal,
        total_dolares_drc_modal,
        drc_promedio,
        precio_materia,
        valor_diferencial
    }
})(cambioDRCForm);

export default cambioDRCForm;

