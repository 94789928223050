import { api } from "api";
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, FieldArray } from 'redux-form';
import { renderField, renderSwitch, renderDatePicker, AsyncSelectField, renderNumberKG } from '../../../Utils/renderField/renderField';
import CardEmpty from "../../../Utils/Cards/CardEmpty";
import Alerta from "../../../Utils/Alerta/Alerta";
import DatePicker from "react-datepicker";
import moment from "moment";
import validador from './validate';
import { RenderKilogramo } from "../../../Utils/renderField/renderReadField";


const getAlmacenes = (search) => {
    return api.get("bodega/get_almacenes", { search, no_paginar: true }).
        then((data) => {
            if (data){
                return data.results;
            }
            return [];
        }).catch(() => {
            return [];
    });
};

const getSupervisores = (search) => {
    return api.get("user/listado_supervisores", { search }).
        then((data) => {
            if (data){
                return data.results;
            }
            return [];
        }).catch(() => {
            return [];
    });
};

const renderMateriaAlmacenes = ({fields, updateData, fechaProduccion, fecha_inicio, informacionAlmacen}) => (

    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                    <tr>
                        <th style={{width: "10%"}} />
                        <th className="text-center" style={{width: "20%"}}>ALMACÉN</th>
                        <th className="text-center" style={{width: "20%"}}>PESO</th>
                        <th className="text-center" style={{width: "25%"}}>EXISTENCIA RESTANTE</th>
                        <th className="text-center" style={{width: "25%"}}>PRODUCTO</th>
                    </tr>
                    </thead>
                </table>
            </div>
            {/*Contenido de la tabla*/}
            <div>
                <table className="table table-sm  m-0 table-striped">
                    <tbody>
                    {fields.map((turno, index) => {
                        let todos = fields.getAll();
                        const dato_actual = fields.get(index); // Pago actual
                        console.log("dato_actual", dato_actual, "turno", turno);
                        todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                        return(
                        <tr key={index}>
                            <td className="text-center sin-borde-top" style={{width: "10%"}}>
                                {!dato_actual.hasOwnProperty('id') &&(
                                    <img src={require('assets/img/icons/borrar.png')} alt="Borrar"
                                        title="Borrar" className="action_img"
                                        onClick={() =>{
                                            fields.remove(index)
                                            // if (dato_actual.hasOwnProperty('id')) {
                                            //     dato_actual.activo = false;
                                            //     updateData(dato_actual, 'turnos');
                                            // } else {
                                            // }
                                        }}
                                    />
                                )}
                            </td>
                            <td className="sin-borde-top" style={{width: "20%"}}>
                                <Field
                                    valueKey="id"
                                    name={`${turno}.almacen`}
                                    labelKey="nombre"
                                    label="nombre"
                                    component={AsyncSelectField}
                                    loadOptions={getAlmacenes}
                                    disabled={dato_actual.hasOwnProperty('id') ? true : false}
                                    onCambio={() => informacionAlmacen(index)}
                                />
                            </td>
                            <td style={{width: "20%"}}>
                                <Field
                                    name={`${turno}.peso_extraido`}
                                    component={renderNumberKG}
                                    type="text"
                                    className="form-control"
                                    disabled={dato_actual.hasOwnProperty('id') ? true : false}
                                    onCambio={() => informacionAlmacen(index)}
                                />
                            </td>
                            <td style={{width: "25%"}}>
                                {!dato_actual.guardar ?
                                    <h6 className="bold gris text-right d-flex align-items-center justify-content-center mt-2 flex-column"><RenderKilogramo value={dato_actual.existencia ? dato_actual.existencia : 0}/>
                                    {dato_actual.existencia_antigua && (
                                        <Alerta danger 
                                        texto={`Se va a utilizar materia prima del mes de  ${dato_actual.mes_antiguo}`}
                                        customClassText="text-center"
                                        isBold={true}
                                        />
                                    )}  </h6>
                                    :
                                    <h6 className="bold rojo text-right">{"Supero la existencia"}</h6>
                                }        
                            </td>
                            <td style={{width: "25%"}}>
                                <h6 className="bold gris text-center">{dato_actual.materia_nombre ? dato_actual.materia_nombre : ""}</h6>
                            </td>
                        </tr>
                    )})}
                    </tbody>
                </table>
            </div>
            <div className="d-flex flex-wrap justify-content-center mt-2">
                <button type="button" className="btn btn-outline-info py-0" onClick={() => fields.push({})}>
                    AGREGAR MATERIA
                </button>
            </div>
        </div>
    </div>
);


let AlmacenForm = props => {
    const { handleSubmit, fecha_inicio_form, fechaProduccion, no_produccion, editando,informacionAlmacen, guardar, total_almacen} = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex flex-wrap align-items-center flex-column">
                <div className="col-12 col-lg-8 d-flex flex-wrap justify-content-between align-items-baseline">
                    <h6 className="mb-3 ml-5">DATOS DE ALMACENES</h6>
                    <label className="mb-3 mr-5">NO. PRODUCCIÓN <span className="gris text-md">{no_produccion}</span></label>
                </div>
                <div className="row col-12 col-lg-10 d-flex flex-wrap justify-content-center">
                    <div className="col-6">
                        <CardEmpty noShadow noPadding>
                            <div className="col-12 d-flex flex-wrap justify-content-center align-items-center mb-2 mt-2">
                                <div className="col-12 d-flex flex-wrap align-items-baseline justify-content-center">
                                    <span className="gris bold mr-2">PESO TOTAL</span>
                                    <h6 className="text-lgs"><RenderKilogramo value={total_almacen} /></h6>
                                </div>
                            </div>
                        </CardEmpty>
                    </div>
                </div>
                <div className="row col-12 col-lg-10">
                    <div className="col-12">
                        <div className="row col-12 m-0 py-3">
                            <FieldArray name="materia_almacen" component={renderMateriaAlmacenes} {...props} informacionAlmacen={informacionAlmacen} />
                        </div>
                    </div>
                </div>
                {editando && (
                    <div className="row col-12 col-lg-10 d-flex flex-wrap justify-content-center mt-3">
                        <Alerta  texto={`Recuerde guardar para continuar.`}/>
                    </div>
                )}
                <div className="mt-3">
                    {editando && (
                        <Link className="btn btn-outline-dark mr-5" to="/producciones">REGRESAR</Link>
                    )}
                    {!editando &&(
                        <Link className="btn btn-outline-dark mr-5" to="/producciones">CANCELAR</Link>
                    )}
                    <button type="submit" className="btn btn-primary" disabled={!guardar}>GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
AlmacenForm = reduxForm({
    form: 'AlmacenForm',
})(AlmacenForm);

const selector = formValueSelector('AlmacenForm');
AlmacenForm = connect(state => {
    const form = state.form.AlmacenForm;
    let guardar = true;
    let contador = 0;
    let no_produccion = "";
    let total_almacen = 0;

    if (form && form.values) {
        const values = form.values;
        if (values.numero_produccion) {
            no_produccion = values.numero_produccion;
        }
        for (let i = 0; i < values.materia_almacen.length; i++) {
            if (values.materia_almacen[i].existencia) {
                if (values.materia_almacen[i].guardar) {
                    console.log(values.materia_almacen[i].guardar);
                    contador+=1
                }
            }
            if (values.materia_almacen[i].peso_extraido) {
                total_almacen += parseFloat(values.materia_almacen[i].peso_extraido);
            }
        }

        if (contador > 0) {
            guardar = false;
        }
    }
    return {
        no_produccion,
        guardar,
        total_almacen,
    }
})(AlmacenForm);

export default AlmacenForm
