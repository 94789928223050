import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/anual_proveedores';
import ReporteAnualProveedores from './ReporteAnualProveedores';


const ms2p = (state) => {
  return {
    ...state.anual_proveedores,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ReporteAnualProveedores);
