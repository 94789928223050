import React, { Fragment } from 'react';
import CardEmpty from 'Utils/Cards/CardEmpty';
import {TableHeaderColumn} from 'react-bootstrap-table';
import moment from 'moment/moment';
import Grid from '../../../Utils/Grid';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { RenderKilogramo, RenderCurrency, RenderNumber, RenderDollar, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { SOLIDO, LATEX } from '../../../../../utility/constants';
import { renderLegend } from '../components';

const GENERAL = {};
GENERAL[SOLIDO] = 'SOLIDO';
GENERAL[LATEX] = 'LATEX';

const renderTooltip = (props) => {
    const { active, payload, label } = props;

    if (active && payload) {
        return (
            <CardEmpty noShadow>
                <div className="d-flex flex-column pb-2">
                    <span className="mb-2">Fecha: {label}</span>
                    { payload.map(item => {
                        return (<div className="d-flex flex-row justify-content-between">
                            {item.dataKey === 'peso_extraido'&&(
                                <div className="d-flex flex-row justify-content-between">
                                    <span className="mr-2">{'Peso extraido'}:</span>
                                    <span className="mr-3"><RenderKilogramo value={item.payload.peso_extraido} /></span>
                                </div>
                            )}
                            {item.dataKey === 'y_value'&&(
                                <div className="d-flex flex-row justify-content-between">
                                    <span className="mr-2">{'Peso producido'}:</span>
                                    <span className="mr-3"><RenderKilogramo value={item.payload.peso_producido} /></span>
                                </div>
                            )}
                        </div>);
                    }) }
                </div>
            </CardEmpty>
        );
    }
    return null;
};

const Reporte = (props) => {
    const {pageChange, page,  loader, data,fecha_inicio_reporte, fecha_fin_reporte} = props;
    let mensajeError = '';
    let diferenciaDias = 0;
    let listaObjs = [], dataChart = [];
    let fecha_inicial = null, fecha_final = null;
    let peso_extraido = 0, peso_producido = 0, drc_promedio = 0;
    fecha_inicial = moment(moment(fecha_inicio_reporte).format("YYYY-MM-DD")+' 00:00:00');
    fecha_final = moment(moment(fecha_fin_reporte).format("YYYY-MM-DD")+' 00:00:00');

    if (fecha_inicial === null || fecha_final === null) {
        mensajeError = 'FECHA INICIAL O FINAL NULO';
    } else if (fecha_final.diff(fecha_inicial, 'days') < 0) {
        mensajeError = 'FILTRO DE FECHAS NO VÁLIDO';
    } else {
        // Procesamiento de la información para generar la gráfica de barras
        diferenciaDias = fecha_final.diff(fecha_inicial, 'days');
        listaObjs = Array(diferenciaDias + 1);
        let iterFecha = moment(fecha_inicial).clone();
        // Construcción del array con los datos para el gráfico, inicialmente todos con cero
        for (let index = 0; index < listaObjs.length; index++) {
            listaObjs[index] = {
                fecha: iterFecha.format('D/M/YY'),
                num_dia: 'Día '+ (index + 1),
                peso_extraido: 0,
                peso_producido: 0,
            };
            iterFecha.add(1, 'days');
        }
        // Cuando la data esté realmente cargada
        const producciones_all = data.all_results;
        let cpFechaInicio = moment(fecha_inicial.format("YYYY-MM-DD")+' 00:00:00');
        diferenciaDias >= 0 && producciones_all && producciones_all.forEach((produccion) => {
            let cpFechaOrden = moment(moment(produccion.fecha_inicio_produccion).format("YYYY-MM-DD")+' 00:00:00');
            const index = cpFechaOrden.diff(cpFechaInicio, 'days');
            // La Orden compra puede tener varios detalles y pueden ser SÓLIDO o LÁTEX, por lo que hay que 'filtrarlos'
            // Sumatoria del valor monetario del tipo de materia seleccionado
            if (index < listaObjs.length && index >= 0) {
                listaObjs[index].peso_producido+= produccion.peso_total;
                produccion.materia_almacen.forEach(detalle => {
                    let materia = listaObjs[index];
                    if (materia !== undefined && materia !== null) {
                        materia.peso_extraido+= detalle.peso_extraido;
                    }
                });
            }
        });
        listaObjs.forEach(obj => {
            if (obj.peso_extraido != 0 || obj.peso_producido != 0) {
                const data = {
                    ...obj,
                    num_dia: obj.num_dia,
                    x_value: obj.fecha_inicio_produccion,
                    peso_producido: obj.peso_producido,
                }
                data[`y_value`] = obj.peso_producido;
                dataChart.push(data);
                peso_extraido+= obj.peso_extraido;
                peso_producido+= obj.peso_producido;
                drc_promedio = peso_producido / peso_extraido;
            }
        });
    }

    return(
        <LoadMask loading={loader} blur>
            <div className="mb-4">
                <CardEmpty>
                    <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mt-4 mb-4">
                        <div className="col-12 col-md-12 d-flex flex-wrap justify-content-center align-items-center">
                            <div className="col-12 col-md-2 p-0 d-flex flex-row justify-content-center justify-content-md-start align-items-center">
                                <h6 className="m-0 mb-3 mb-md-0 bold">TOTALES</h6>
                            </div>
                            <div className="col-12 row col-md-10 d-flex flex-row flex-wrap justify-content-center align-items-center p-0">
                                <div className="col-12 col-md-4 col-sm-4 d-flex flex-row justify-content-between justify-content-md-between align-items-center">
                                    <span className="m-0 ml-0 ml-md-1 bold">TOTAL EXTRAIDO</span>
                                    <h3 className="mb-1 ml-2 gris" style={{whiteSpace: 'nowrap'}}>{<RenderKilogramo value={peso_extraido} />}</h3>
                                </div>
                                <div className="col-12 col-md-4 col-sm-4 d-flex flex-row justify-content-between justify-content-md-between align-items-center">
                                    <span className="m-0 ml-0 ml-md-1 bold">TOTAL PRODUCIDO:</span>
                                    <h3 className="mb-1 ml-2 gris" style={{whiteSpace: 'nowrap'}}>{<RenderKilogramo value={peso_producido} />}</h3>
                                </div>
                                <div className="col-12 col-md-4 col-sm-4 d-flex flex-row justify-content-between justify-content-md-between align-items-center">
                                    <span className="m-0 ml-0 ml-md-1 bold">DRC PROMEDIO:</span>
                                    <h3 className="mb-1 ml-2 gris" style={{whiteSpace: 'nowrap'}}>{<RenderNumberPercentage value={drc_promedio} />}</h3>
                                </div>
                            </div>
                        </div>

                    </div>
                </CardEmpty>
            </div>
            <div className="mb-4">
                <CardEmpty>
                    <div className="d-flex flex-column">
                        <div className="flex-fill d-flex justify-content-center">
                            {
                                mensajeError.length === 0
                                ? <ResponsiveContainer width="85%" height="100%" minWidth={500} minHeight={300}>
                                    <AreaChart width={730} height={250} data={dataChart}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="fecha" />
                                        <YAxis />
                                        <Tooltip content={renderTooltip} />
                                        <Legend content={renderLegend} align="left" verticalAlign="middle" layout="vertical" iconType="circle" title="GRÁFICA DE PRODUCCIONES" />
                                        <Area dataKey={`y_value`} value={'PESO PRODUCIDO'} stackId="1" fill={'#215273'} stroke={'#215273'} dot={{ stroke: '#215273'}}/>
                                        <Area dataKey={`peso_extraido`} value={'PESO EXTRAIDO'} stackId="1" fill={'#359d9e'} stroke={'#359d9e'} dot={{ stroke: '#359d9e'}}/>
                                    </AreaChart>
                                </ResponsiveContainer>
                                : <div className="mb-3 rojo bold">{mensajeError}</div>
                            }
                        </div>
                    </div>
                </CardEmpty>
            </div>
            {/* TABLA CON LOS RESÚMENES */}
            <div className="mb-5">
                <CardEmpty>
                    <Grid hover striped loading={loader} data={data}
                        pagination onPageChange={pageChange} page={page}
                        className="pb-4 pt-3"
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataSort
                            isKey
                            dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell}</span>}
                            width="10"
                        >
                            ID
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="numero_produccion"
                            dataSort
                            dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell}</span>}
                            width="15"
                        >
                            NO. PRODUCCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_inicio_produccion"
                            dataSort
                            dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{moment(cell).format('DD/MM/YYYY')}</span>}
                            width="12"
                        >
                            FECHA INICIO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="fecha_fin_produccion"
                            dataSort
                            dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{moment(cell).format('DD/MM/YYYY')}</span>}
                            width="10"
                        >
                            FECHA FIN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="materia_prima"
                            dataSort
                            dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell ? cell.nombre: ""}</span>}
                            width="13"
                        >
                            PRODUCTO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="materia_almacen"
                            dataSort
                            dataAlign="right"
                            width="15"
                            dataFormat={
                                cell =>{
                                    if (cell) {
                                        var materia_almacen = 0;
                                        for (let i = 0; i < cell.length; i++) {
                                            materia_almacen += cell[i].peso_extraido;
                                        }
                                        return <RenderKilogramo value={materia_almacen} />
                                    }
                                }
                            }
                            >
                            PESO EXTRAIDO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="peso_total"
                            dataSort
                            dataAlign="right"
                            width="15"
                            dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{<RenderKilogramo value={cell}/>}</span>}
                            >
                            PESO PRODUCIDO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="drc_produccion"
                            dataSort
                            dataAlign="right"
                            dataFormat={(cell,row) => <RenderNumberPercentage value={cell ? cell : 0} />}
                            width="10"
                            >
                            DRC
                        </TableHeaderColumn>
                    </Grid>
                </CardEmpty>
            </div>
        </LoadMask>
    )
};

export default Reporte;
