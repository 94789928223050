import React, {Component} from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../FiltrosHeader';
import TabBar from 'rc-tabs/lib/TabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import Tabs, {TabPane} from 'rc-tabs';
import Reporte from './Reporte';
import CardEmpty from '../../../Utils/Cards/CardEmpty';

export default class ReporteRecepciones extends Component {
    componentWillMount() {
        const { getRecepcionesParaReporte, setTab, setFiltrarRecepcionesPorAcopio } = this.props;
        setTab('FINALIZADAS');
        setFiltrarRecepcionesPorAcopio(false);
        // getRecepcionesParaReporte(true);
    }

    render() {
        const {
            reporte, tipoMateriaChange,
            pageReporteLatexChange, fechaInicialLatexChange, fechaFinalLatexChange, fincaLatexChange, acopioLatexChange,
            pageReporteSolidoChange, fechaInicialSolidoChange, fechaFinalSolidoChange, fincaSolidoChange, acopioSolidoChange,
            materiaLatexChange, materiaSolidoChange
        } = this.props;
        let fechaInicialChange = null, fechaFinalChange = null, fincaChange = null, materiaChange = null,acopioChange = null, pageChange = null;
        let fecha_inicial = null, fecha_final = null, finca_id = null, acopio_id = null, materia_id = null, page = null;
        switch(reporte.tipo_materia) {
            case 'LATEX':
                pageChange = pageReporteLatexChange;
                fechaInicialChange = fechaInicialLatexChange;
                fechaFinalChange = fechaFinalLatexChange;
                fincaChange = fincaLatexChange;
                acopioChange = acopioLatexChange;
                materiaChange = materiaLatexChange;
                page = reporte.latex_page;
                fecha_inicial = reporte.latex_fecha_inicial;
                fecha_final = reporte.latex_fecha_final;
                finca_id = reporte.latex_finca_id;
                materia_id = reporte.latex_materia_id;
                acopio_id = reporte.latex_acopio_id;
                break;
            case 'SOLIDO':
                pageChange = pageReporteSolidoChange;
                fechaInicialChange = fechaInicialSolidoChange;
                fechaFinalChange = fechaFinalSolidoChange;
                fincaChange = fincaSolidoChange;
                acopioChange = acopioSolidoChange;
                materiaChange = materiaSolidoChange;
                page = reporte.solido_page;
                fecha_inicial = reporte.solido_fecha_inicial;
                fecha_final = reporte.solido_fecha_final;
                finca_id = reporte.solido_finca_id;
                materia_id = reporte.solido_materia_id;
                acopio_id = reporte.solido_acopio_id;
                break;
        }
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="RECEPCIONES"
                    noBorder
                    subtitulo="Reporte">
                    <Tabs
                        activeKey={reporte.tipo_materia}
                        tabBarPosition="top"
                        onChange={tipo_materia => {tipoMateriaChange(tipo_materia)}}
                        renderTabBar={() => <TabBar />}
                        renderTabContent={() => <TabContent />}>
                        <TabPane tab="LÁTEX" key="LATEX">
                            <div></div>
                        </TabPane>
                        <TabPane tab="SÓLIDOS" key="SOLIDO">
                            <div></div>
                        </TabPane>
                    </Tabs>
                    <CardEmpty>
                        <FiltrosHeader {...this.props}
                            fechaInicialChange={fechaInicialChange} fecha_inicial={fecha_inicial} fechaFinalChange={fechaFinalChange} fecha_final={fecha_final}
                            filtroFincaChange={fincaChange} finca_id={finca_id}
                            filtroAcopioChange={acopioChange} acopio_id={acopio_id}
                            filtroMateriasChange={materiaChange} materias_ids={materia_id} tipo_materia={reporte.tipo_materia}
                            precioReporteAmoniaco={undefined}
                            concentracionReporteAmoniaco={undefined}
                            filtrarXReporte={()=>{this.props.getRecepcionesParaReporte()}}
                            labelXReporte={"Filtrar"}
                        />
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <Reporte {...this.props} pageChange={pageChange} page={page}></Reporte>
                </Card>
            </div>
        )
    }
}
