import React, { Component } from 'react'
import moment from 'moment/moment';
import CardEmpty from 'Utils/Cards/CardEmpty';
import Grid from '../../../Utils/Grid';
import { RenderKilogramo, RenderNumber } from 'Utils/renderField/renderReadField';
import { PrintContainer, actions as printActions } from 'Utils/Print';
import 'react-step-progress-bar/styles.css';
import { LATEX as TIPO_LATEX } from '../../../../../utility/constants';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default class IngresoVerBoleta extends Component {
    state = {
        fechaHoraImpresion: moment().format('DD/MM/YYYY HH:mm:ss A'),
    }
    componentWillMount() {
        this.props.leer(this.props.match.params.id);
    }
    componentDidMount(){
        // this.crearPDF();
        // const dataPdf = this.crearPDF();
    }
    imprimirBoleta = (event) => {
        this.setState({
            fechaHoraImpresion: moment().format('DD/MM/YYYY HH:mm:ss A')
        }, () => printActions.print('boleta-ingreso'));
    }
    crearPDF() {
        const input = document.getElementById('PRINT-boleta-ingreso');
        const pdf = new jsPDF("portrait", "mm", "a4");
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                pdf.scaleFactor = 2;
                pdf.addImage(imgData,'PNG',0,0,210,110);
                // pdf.save("download.pdf");
                // pdf.save();
                // console.log(pdf.output('datauristring'));
                this.props.guardarBoletaPDF(this.props.match.params.id, btoa(pdf.output()), 'boleta_entrada');
            })
        ;
    }

    render() {
        const { recepcion, profile, loader } = this.props;
        const productos = recepcion.productos ? recepcion.productos : [];
        const data = {
            results: productos
        }
        const esLatex = recepcion.tipo_materia.tipo === TIPO_LATEX;
        return (
            <div>
                <CardEmpty>
                    <PrintContainer name='boleta-ingreso'>
                        <div className="col-12 p-0">
                            <div className="row contenido-impresion verde-secundario d-flex justify-content-center">
                                <div className="col-12 row">
                                    <div className="col-12 d-flex justify-content-center mt-0 mb-3">
                                        <div className="col-11 pl-2">
                                            <div className="row col-12 p-0 mt-3 d-flex justify-content-between align-items-center">
                                                    <img
                                                        className="d-inline-block align-top"
                                                        src={require('../../../../../../assets/img/logo.png')}
                                                        alt="Logo"
                                                        height="50px"
                                                    />
                                                    <label className="p-0 m-0">ACOPIO ERSA KM. 7.5 CARRETERA A TULATE</label>
                                                    <div className="ml-4 mr-4"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center mt-0">
                                        <div className="col-11">
                                            <div className="row col-12 p-0 mt-2 justify-content-center align-items-start">
                                                <div className="col-7 p-0 fondo">
                                                    <div className="col-12 d-flex flex-column pl-0">
                                                        <div className="d-flex align-items-center">
                                                            <img src={require(`../../../../../../assets/img/icons/recepciones_activo.png`)} alt="Usuario" className="title_img mr-2"/>
                                                            <img src={require('../../../../../../assets/img/icons/linea.png')} alt="Linea" className="title_img mr-2"/>
                                                            <h6 className="text-black mb-0">BOLETA DE INGRESO</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-5 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <span className="gris">Fecha de impresión:</span>
                                                        <span className="gris">{this.state.fechaHoraImpresion}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-12 p-0 pt-1">
                                                <div className="col-7 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">FINCA</label>
                                                        <span className="gris">{recepcion.finca ? recepcion.finca.nombre : ''}</span>
                                                    </div>
                                                </div>
                                                <div className="col-5 p-0">
                                                    <div className="col-12 d-flex align-items-baseline">
                                                        <label className="p-0 m-0">ID RECEPCIÓN</label>
                                                        <h5 className="gris bold pl-2">{recepcion.id ? recepcion.id.toString().padStart(5, 0) : ''.padStart(5, 0)}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-12 p-0 mt-2">
                                                <div className="col-7 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">VEHÍCULO</label>
                                                        <span className="gris">{recepcion.vehiculo ? recepcion.vehiculo.nombre : ''}</span>
                                                    </div>
                                                </div>
                                                <div className="col-5 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">PILOTO</label>
                                                        <span className="gris">{recepcion.piloto ? recepcion.piloto.nombre : ''}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-12 p-0 mt-2">
                                                <div className="col-7 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">PESO FINCA</label>
                                                        <span className="gris"><RenderKilogramo value={recepcion.peso_ingreso} /></span>
                                                    </div>
                                                </div>
                                                <div className="col-5 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">RECEPTOR</label>
                                                        <span className="gris">{profile.nombre}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-12 p-0 mt-2">
                                                <div className="col-8 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">FECHA INGRESO MATERIA PRIMA</label>
                                                        <span className="gris">{recepcion.fecha_entrada ? moment(recepcion.fecha_entrada).format('DD/MM/YYYY HH:mm:ss A') : ''}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center p-0">
                                        <div className=" col-11 form-group np-r p-0 mt-3 mb-1">
                                            <Grid striped data={data} loading={loader} pagination={false} bordered={false} >
                                                <TableHeaderColumn
                                                    dataField="id"
                                                    isKey
                                                    width="15px"
                                                    dataFormat={cell => ''}
                                                />
                                                <TableHeaderColumn
                                                    dataField="materia_prima_nombre"
                                                    dataFormat={cell => cell}
                                                >
                                                    MATERIA PRIMA
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="peso_finca"
                                                    dataFormat={cell => <RenderNumber value={cell} decimalScale={2} />}
                                                >
                                                    PESO FINCA
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="peso_finca_unidad_text"
                                                    dataFormat={cell => cell}
                                                >
                                                    UNIDAD
                                                </TableHeaderColumn>
                                                <TableHeaderColumn
                                                    dataField="peso_finca_kg"
                                                    dataFormat={cell => <RenderNumber value={cell} decimalScale={2} />}
                                                >
                                                    KILOGRAMOS
                                                </TableHeaderColumn>
                                                {/* <TableHeaderColumn
                                                    dataField="vaciados"
                                                    dataFormat={(cell, row) => {
                                                        if (cell && cell.length > 0) {
                                                            let destinos = '';
                                                            cell.forEach(movimiento => {
                                                                destinos.length > 0 && (destinos+= ', ');
                                                                destinos+= movimiento.bodega.nombre;
                                                            });
                                                            return destinos;
                                                        }
                                                        return row.bodega ? row.bodega.nombre : '--';
                                                    }}
                                                >
                                                    DESTINOS
                                                </TableHeaderColumn> */}
                                            </Grid>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center mt-3 p-0">
                                        <div className="col-11">
                                            <div className="row col-12 p-0 d-flex align-items-center">
                                                <div className="col-6 p-0">
                                                    <div className="col-12 d-flex flex-row align-items-end">
                                                        <div className="col-6">
                                                            <label className="p-0 m-0">FIRMA LABORATORIO</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <input className="espacio-firma mb-1" type="text" readOnly />
                                                        </div>
                                                    </div>
                                                    {
                                                        esLatex && <div className="col-12 d-flex flex-row align-items-center mt-2">
                                                            <div className="col-5">
                                                                <label className="p-0 m-0">CMS. INICIO PILA</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    readOnly
                                                                    width="75%"
                                                                    value={""}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="col-6 p-0">
                                                    <div className="col-12 d-flex flex-row align-items-end">
                                                        <div className="col-6">
                                                            <label className="p-0 m-0">FIRMA RECEPTOR</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <input className="espacio-firma mb-1" type="text" readOnly />
                                                        </div>
                                                    </div>
                                                    {
                                                        esLatex && <div className="col-12 d-flex flex-row align-items-center mt-2">
                                                            <div className="col-5">
                                                                <label className="p-0 m-0">CMS. FINAL PILA</label>
                                                            </div>
                                                            <div className="col-6">
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    readOnly
                                                                    width="75%"
                                                                    value={""}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center mt-5 mb-5">
                                        <div className="col-11">
                                            <div className="row col-12 p-0 mt-3">
                                                <div className="col-8 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        {/* <label className="p-0 m-0 text-black">ESTA BOLETA ES UNA COPIA</label> */}
                                                    </div>
                                                </div>
                                                <div className="col-4 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <span className="gris">ERSA-F-GO-025-06</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PrintContainer>
                </CardEmpty>
                <div className="mb-4 col-12 p-0">
                    <div className="row mb-5">
                        <div className="col-12 row">
                            <div className="col-12 d-flex justify-content-center mt-5">
                                <div className="col-9 buttons-box">
                                    <button className="btn btn-outline-dark mr-5" onClick={() => this.props.history.goBack()}>CERRAR</button>
                                    <button type="submit" className="btn btn-primary" onClick={this.imprimirBoleta}>IMPRIMIR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
