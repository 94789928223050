import React, { useEffect, useState } from "react";
import CardForm from "../../../../Utils/Cards/CardForm";
import CardEmpty from "../../../../Utils/Cards/CardEmpty";
import TableFormSalida from "./TableFormSalida";

const CanalSalida = ({
    no__recepcion,
    salidaABodega,
    guardarEntrada,
    pesosBascula,
    me,
    setPreviousStep,
    setNextStep,
    step_form,
}) => {
    const [peso_manual, setPesoManual] = useState(true);

    useEffect(() => {
        if (me) {
            if (me.acopio) {
                if (me.peso_ingreso_manual_acopio === false) {
                    console.log("Se esta tomando el peso con bascula")
                    setPesoManual(false);
                }
            } else {
                if (me.peso_ingreso_manual === false) {
                    console.log("Se esta tomando el peso con bascula")
                    setPesoManual(false);
                }
            }
        }
    }, [])

    useEffect(() => {
        if (!peso_manual) {
            var interval = null
            interval = setInterval(() => {
                pesosBascula()
            }, 500)

            return () => clearInterval(interval)
        }
    }, [peso_manual])

    return (
        <CardForm
            titulo="Salida"
            subtitulo={"recepcion :" + no__recepcion}
            col="12"
            icon="bodega_activo"
            noPaddingXContent={true}
        >
            <div
                style={{
                    minHeight: "370px",
                }}
            >
                <div
                    className="row col-12"
                    style={{ position: "absolute", top: "-40px" }}
                >
                    <div className="w-100 d-flex align-items-center justify-content-between flex-wrap col-12">
                        <div>
                            <div className="mb-1 ml-2 mr-2">
                                <span className="gris">PASO </span>
                                <span className="verde-primario text-md">
                                    {" "}
                                    {step_form + 1}{" "}
                                </span>
                                <span className="gris"> DE 2</span>
                            </div>
                        </div>
                    </div>
                </div>
                <TableFormSalida
                    salidaABodega={salidaABodega}
                    guardarEntrada={guardarEntrada}
                    peso_manual={peso_manual}
                />
            </div>
        </CardForm>
    );
};

export default CanalSalida;
