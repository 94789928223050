import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { Field, FieldArray, formValueSelector, reduxForm, } from 'redux-form';
import {
    renderField,
    renderNumber,
    renderFieldRadio,
    renderFieldCheck,
    renderCurrency,
    renderNumberBill,
    renderDatePicker
} from '../../../Utils/renderField/renderField';
import {RenderCurrency, RenderDollar,RenderKilogramo } from 'Utils/renderField/renderReadField';
import CardEmpty from "Utils/Cards/CardEmpty";
import Modal from 'react-responsive-modal';
import { NotificationManager } from "react-notifications";
import moment from "moment/moment";
import { FACTURA_ESPECIAL, PEQUEÑO_CONTRIBUYENTE, PAGOS_DIRECTOS, PAGOS_TRIMESTRALES, EXPORTADOR, PRODUCCION_INTERNA, FLETE_INTERNO_COSTO_POR_KG } from "../../../../../utility/constants";
import { RenderCambio, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';
import {formatActivo} from "Utils/renderField/renderReadField";
import Alerta from "Utils/Alerta/Alerta";

const ver_img = require('assets/img/icons/ver.png');
var number = 150780.5315;

export const renderDatosCompras = ({ fields, openModal, item, loadDataModal, ordenCompraTemp, tipo_drc, finca_nombre}) => {
    return (
        <div className="col-12">
            <div className="react-bs-table-container">
                <div className="react-bs-table">
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table table-hover table-bordered">
                            <tbody>
                                <tr>
                                    <th style={{ width: "5%" }} />
                                    <th style={{ width: "8%" }}>ID INGRESO</th>
                                    <th style={{ width: "9%" }}>FECHA</th>
                                    <th style={{ width: "13%" }}>MATERIA PRIMA</th>
                                    <th style={{ width: "8%" }} className="text-right">KILOS HUMEDOS</th>
                                    <th style={{ width: "8%" }} className="text-right">DRC</th>
                                    <th style={{ width: "11%" }} className="text-right">KILOS SECOS</th>
                                    <th style={{ width: "8%" }} className="text-right">PRECIO</th>
                                    <th style={{ width: "11%" }} className="text-right">SUBTOTAL $.</th>
                                    <th style={{ width: "8%" }} className="text-right">CAMBIO</th>
                                    <th style={{ width: "11%" }} className="text-right">SUBTOTAL Q.</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <table className="table table-striped table-bordered table-hover">
                <tbody>
                    {fields.map((pago, index) => {
                        const dato_actual = fields.get(index); // Pago actual
                        let todos = fields.getAll();
                        {/* Se suma el costo_flete al precio_unitario */}
                        const { precio_venta=0, costo_flete=0, tipo_costo_flete=0 } = dato_actual;
                        const total_precio_venta = precio_venta + (tipo_costo_flete === FLETE_INTERNO_COSTO_POR_KG ? costo_flete : 0);
                        return (
                            <tr key={index} >
                                <td style={{ width: "5%" }} className="pl-0 pt-0">
                                    <Field name={`${pago}.liquidar`} component={renderFieldCheck}/>
                                </td>
                                <td style={{ width: "8%" }}
                                    className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                    <span>{dato_actual && dato_actual.ingreso ? dato_actual.ingreso.id : '--'} </span>
                                </td>
                                <td style={{ width: "9%", paddingRight: 0, paddingLeft: 0 }}
                                    className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                    <span>{dato_actual && dato_actual.ingreso ? moment(dato_actual.ingreso.fecha_entrada).format('DD/MM/YYYY') : '--'} </span>
                                </td>
                                <td style={{ width: "13%" }}
                                    className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''}`}>
                                    <span>{dato_actual && dato_actual.materia_prima ? dato_actual.materia_prima.nombre : '--'} </span>
                                </td>
                                <td style={{ width: "8%" }}
                                    className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''} text-right`}>
                                    <RenderKilogramo value={
                                        dato_actual && dato_actual.kilos_humedos ? (dato_actual.kilos_humedos) : 0
                                    } />
                                </td>
                                <td style={{ width: "8%" }}
                                    className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''} text-right`}>
                                    <RenderNumberPercentage value={dato_actual ? dato_actual.drc_compra : '--'} />
                                </td>
                                <td style={{ width: "11%" }}
                                    className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''} text-right`}>
                                    <RenderKilogramo value={
                                        dato_actual && dato_actual.kilos_secos ? (dato_actual.kilos_secos) : 0
                                    } />
                                </td>
                                <td style={{ width: "8%" }}
                                    className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''} text-right`}>
                                    <RenderDollar value={total_precio_venta} decimalScale={3} />
                                </td>
                                <td style={{ width: "11%" }}
                                    className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''} text-right`}>
                                    <RenderDollar value={dato_actual && dato_actual.total_dolares ? dato_actual.total_dolares : 0} />
                                </td>
                                <td style={{ width: "8%" }}
                                    className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''} text-right`}>
                                    <RenderCambio value={dato_actual.cambio_dia} decimalScale={5} />
                                </td>
                                <td style={{ width: "11%" }}
                                    className={`${(dato_actual.activo !== undefined && dato_actual.activo === false) ? 'tachado' : ''} text-right`}>
                                    <RenderCurrency value={dato_actual && dato_actual.total_quetzales ? dato_actual.total_quetzales : 0} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
};

export const renderInsumos = ({fields}) => {
    return (
        <div className="col-12 text-center pl-0">
            <div className="react-bs-table-container">
                <div className="react-bs-table">
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table table-hover table-bordered">
                            <tbody>
                                <tr>
                                    <th style={{ width: "10%" }}/>
                                    <th style={{ width: "25%" }}>FECHA</th>
                                    <th style={{ width: "40%" }}>NO. FACTURA</th>
                                    <th style={{ width: "25%" }}>MONTO</th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <table className="table table-striped table-bordered table-hover">
                <tbody>
                    {fields.map((pago, index) => {
                        const dato_actual = fields.get(index); // Pago actual
                        let todos = fields.getAll();
                        todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                        return (
                            <tr key={index}>
                                <td style={{ width: "10%" }} className="pl-0 pt-2">
                                    <Field name={`${pago}.pagar_insumo`} component={renderFieldCheck}/>
                                </td>
                                <td style={{ width: "25%" }}>
                                    <span>{dato_actual ? moment(dato_actual.fecha_apli).format("DD/MM/YYYY") : "" } </span>
                                </td>
                                <td style={{ width: "40%" }}>
                                    <span>{dato_actual ? dato_actual.no_factura : "" } </span>
                                </td>
                                <td style={{ width: "25%" }}>
                                    <RenderCurrency value={dato_actual ? dato_actual.importe : 0} />
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
};

let LiquidacionesForm = props => {
    const { handleSubmit, item, mostrar_liquidacion, ordenCompraTemp, tipo_drc, fecha_final_liq, fecha_inicio_liq, clearIndexValidados,
        total_dolares, total_quetzales, kilos_secos_liquidar, kilos_humedos_liquidar, retencion_iva, retencion_isr, total_liquidar, retencion_peq_contribuyente, total_insumos} = props;
    
    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <div className="d-flex  justify-content-center mb-4">
                    <div className="col-8">
                        <div className="row col-12">
                                <h6 className="mb-4">DATOS GENERALES DE RECEPCIÓN</h6>
                        </div>
                        <div className="row col-12 col-md-12 p-0">
                            <div className="col-md-6 p-0">
                                <div className="col-12 d-flex flex-column">
                                    <label className="p-0 m-0">FECHA INICIO</label>
                                    <span className="mb-1">{moment(fecha_inicio_liq).format("DD/MM/YYYY")}</span>
                                    <label className="p-0 m-0">FECHA FINAL</label>
                                    <span className="mb-1">{moment(fecha_final_liq).format("DD/MM/YYYY")}</span>
                                    <label className="p-0 m-0">RETIENE IVA</label>
                                    <span className="mb-1">{item && item.finca && item.finca.retenedor_iva ? "SI" : "NO"}</span>
                                </div>
                            </div>
                            <div className="col-md-6 p-0">
                                <div className="col-12 d-flex flex-column">
                                    <label className="p-0 m-0">FINCA</label>
                                    <span className="mb-1">{item ? (item.finca ? item.finca.nombre : " ") : " "}</span>
                                    <label className="p-0 m-0">RÉGIMEN</label>
                                    <span className="mb-1">{item ? (item.finca ? item.finca.regimen_fiscal_nombre : (item.detalles  && item.detalles[0].orden_compra.regimen_fiscal !== 0 ? item.detalles[0].regimen_fiscal_compra : ' ') ) : ' '}</span>
                                    {/* <span className="mb-1">{item ? item.finca.regimen_fiscal_nombre : ' '}</span> */}
                                    {/* <span className="mb-1">{item ? (item.detalles  && item.detalles[0].orden_compra.regimen_fiscal !== 0 ? item.detalles[0].regimen_fiscal_compra : (item.finca ? item.finca.regimen_fiscal_nombre : " ") ) : " "}</span> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12">
                        <FieldArray name="detalles" component={renderDatosCompras} {...props} />
                    </div>
                </div>

                {/* <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
                                PARTE DE TOTALES
                >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}

                <div className="row">
                    <div className="col-12">
                        <CardEmpty noShadow noPadding>
                            <div className="row col-12 m-0 py-3">
                                <div className="row col-1"></div>
                                <div className="row col-10 p-0">
                                    <h6 className="">TOTALES</h6>
                                </div>
                            </div>
                            {/* TODO: Ahora se evalúa si la finca retiene impuestos */}
                            {/* {item && item.finca && item.finca.retenedor_iva === false && (
                                <div className="col-12 d-flex justify-content-center align-items-center">
                                    <Alerta texto="El valor de IVA reflejado en el formulario no afecta al pago de la liquidación."/>
                                </div>
                            )} */}
                            <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mb-4">
                                <div className="row col-12 col-md-10 d-flex flex-wrap justify-content-center align-items-end">
                                    <div className="col-12 col-md-4 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="retencion_peq_contribuyente" className="m-0">RETENCIÓN PEQ. CONTRIBUYENTE</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="retencion_peq_contribuyente" component={renderCurrency} disabled type="text" className="form-control"
                                                input={{ value: retencion_peq_contribuyente }} decimalScale={2} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="retencion_iva" className="m-0">RETENCIÓN IVA</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="retencion_iva" component={renderCurrency} disabled type="text" className="form-control"
                                                input={{ value: retencion_iva }} decimalScale={2} />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="retencion_isr" className="m-0">RETENCIÓN ISR</label>
                                        </div>
                                        <div className="col-md-12  form-group">
                                            <Field name="retencion_isr" component={renderCurrency} disabled type="text" className="form-control"
                                                input={{ value: retencion_isr }} decimalScale={2} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mt-4 mb-4">
                                <div className="row col-12 col-md-8 d-flex flex-wrap justify-content-center align-items-end">
                                    <div className="col-12 col-md-4 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="kilos_humedos" className="m-0">KILOS HUMEDOS</label>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                        <h6 className="mb-1 ml-3 gris"><b>{kilos_humedos_liquidar.toFixed(2) + " kilos"}</b></h6>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 p-0">
                                            <div className="col-md-12 col-sm-12">
                                                <label htmlFor="kilos_secos" className="m-0">KILOS SECOS</label>
                                            </div>
                                            <div className="col-md-12 mt-2">
                                                <h6 className="mb-1 ml-3 gris"><b>{kilos_secos_liquidar.toFixed(2) + " kilos"}</b></h6>
                                            </div>
                                        </div>
                                </div>
                            </div>

                            <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mt-4 mb-4">
                                <div className="col-12 col-md-8 d-flex flex-wrap justify-content-center align-items-end">
                                    <div className="col-12 col-md-4 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="total_dolares" className="m-0">TOTAL DOLARES</label>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <h6 className="mb-1 ml-3 gris"><b>{<RenderDollar value={total_dolares}/>}</b></h6>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="total_quetzales" className="m-0">TOTAL QUETZALES</label>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <h6 className="mb-1 ml-3 gris"><b>{<RenderCurrency value={total_quetzales}/>}</b></h6>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 p-0">
                                        <div className="col-md-12 col-sm-12">
                                            <label htmlFor="total_liquidar" className="m-0">TOTAL A LIQUIDAR</label>
                                        </div>
                                        <div className="col-md-12 mt-2">
                                            <h6 className="mb-1 ml-3 gris"><b>{<RenderCurrency value={total_liquidar - total_insumos} />}</b></h6>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </CardEmpty>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 col-lg-8 mt-4">
                        <CardEmpty noShadow >
                            <div className="col-12 text-center pr-0">
                                <h6 className="">VENTAS DE SAE</h6>
                            </div>
                            <div className="col-12 pr-0">
                                <FieldArray name="insumos" component={renderInsumos} {...props} />
                            </div>
                            <div className="col-12 text-right pr-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="total_insumos" className="m-0">TOTAL DE INSUMOS</label>
                                </div>
                                <div className="col-md-12 mt-2 mb-3">
                                    <h6 className="mb-1 ml-3 gris"><b>{<RenderCurrency value={total_insumos} />}</b></h6>
                                </div>
                            </div>
                        </CardEmpty>
                    </div>
                    <div className="col-12 col-lg-4 mt-4">
                        <CardEmpty noShadow>
                            <div className="col-12 d-flex justify-content-center mb-2 mt-2 m-0">
                                <div className="col-12 col-md-12">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="fecha_factura" className="m-0">FECHA DE FACTURA</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field
                                            name="fecha_factura"
                                            component={renderDatePicker}
                                            todayButton="Hoy"
                                            disabled={item.posee_factura === true ||
                                                (item ? (item.finca ? item.finca.regimen_fiscal === FACTURA_ESPECIAL || item.finca.regimen_fiscal === EXPORTADOR || item.finca.regimen_fiscal === PRODUCCION_INTERNA: "") : "") ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center mb-2 mt-2 m-0">
                                <div className="col-12 col-md-12">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="serie" className="m-0">SERIE DE FACTURA</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field name="serie" disabled={item ? (item.finca ? (item.finca.regimen_fiscal === FACTURA_ESPECIAL || item.finca.regimen_fiscal === EXPORTADOR || item.finca.regimen_fiscal === PRODUCCION_INTERNA ? true : false) : false) : false} component={renderField} type="text" className="form-control"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 d-flex justify-content-center mb-2 mt-2 m-0">
                                <div className="col-12 col-md-12">
                                    <div className="col-md-12 col-sm-12">
                                        <label htmlFor="numero_factura" className="m-0">NO. FACTURA</label>
                                    </div>
                                    <div className="col-md-12  form-group">
                                        <Field name="numero_factura" disabled={item ? (item.finca ? (item.finca.regimen_fiscal === FACTURA_ESPECIAL || item.finca.regimen_fiscal === EXPORTADOR || item.finca.regimen_fiscal === PRODUCCION_INTERNA ? true : false) : false) : false} component={renderField} type="text" className="form-control"/>
                                    </div>
                                </div>
                            </div>

                        </CardEmpty>
                    </div>
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/liquidaciones">
                        CANCELAR
                    </Link>
                    <button type="submit" className="btn btn-primary">
                        LIQUIDAR
                    </button>
                </div>
            </div>
        </form>
    )
};

LiquidacionesForm = reduxForm({
    form: 'LiquidacionesForm'
})(LiquidacionesForm);

const selector = formValueSelector('LiquidacionesForm');
LiquidacionesForm = connect(state => {
    const form = state.form.LiquidacionesForm;
    const VARIABLES = state.configuracion.configuraciones.variables_calculo_impuestos;
    let total_dolares = 0;
    let total_quetzales = 0;
    let valor_factura_sin_iva = 0;
    let valor_factura = 0;
    let total_temp = 0;
    let total_temp_isr = 0;
    let total_liquidar = 0;
    let total_insumos = 0;

    let kilos_secos_liquidar = 0;
    let kilos_humedos_liquidar = 0;

    let retencion_iva = 0;
    let descuento_iva = 0;
    let iva_materia_prima = 0;

    let retencion_isr = 0;
    let retencion_isr_pagos_directos = 0;
    let retencion_peq_contribuyente = 0;

    let isr_materia_prima = 0;
    let isr_materia_prima_restante = 0;

    if (form && form.values) {
        const values = form.values;
        let finca = values.finca;
        const detalles = values.detalles;
        const insumos = values.insumos;
        let total_fletes_quetzales = 0;
        const oden_compra = detalles[0].orden_compra;
        // finca.regimen_fiscal = oden_compra.regimen_fiscal !== 0 ? oden_compra.regimen_fiscal : finca.regimen_fiscal;
        // Iniciando valores para cálculo de impuestos
        form.values.retencion_iva = 0;
        form.values.retencion_isr = 0;
        form.values.retencion_peq_contribuyente = 0;

        for (let i = 0; i < detalles.length; i++) {

            if (detalles[i].liquidar) {
                total_dolares += detalles[i].total_dolares;
                total_quetzales += detalles[i].total_quetzales;
                kilos_secos_liquidar += detalles[i].kilos_secos;
                kilos_humedos_liquidar += detalles[i].kilos_humedos;

                form.values.total_dolares = total_dolares;
                form.values.total_quetzales = total_quetzales;
                form.values.kilos_secos_liquidar = kilos_secos_liquidar;
                form.values.kilos_humedos_liquidar = kilos_humedos_liquidar;
                if (detalles[i].tipo_costo_flete === FLETE_INTERNO_COSTO_POR_KG) {
                    total_fletes_quetzales+= detalles[i].costo_flete*detalles[i].kilos_secos*detalles[i].cambio_dia;
                }
            }
            form.values.total_fletes = total_fletes_quetzales;

        }

        if (insumos) {
            for (let i = 0; i < insumos.length; i++) {
                if (insumos[i].pagar_insumo) {
                    total_insumos += insumos[i].importe;
                }
            }
            form.values.total_insumos = total_insumos;
        }

        valor_factura = total_quetzales;
        valor_factura_sin_iva = valor_factura/(1 + VARIABLES['PORCENTAJE_NORMAL_IVA']);

        if (finca && finca.retenedor_iva === true) {
            /*
                valor_factura = Total de la liquidación en quetzales.
                valor_factura_sin_iva = Total de la liquidación sin iva por lo cual se hace la operación de: "valor_factura/1.12"
            */
            if (finca.regimen_fiscal === PEQUEÑO_CONTRIBUYENTE) {
                if (valor_factura > VARIABLES['REGIMEN_PEQUENIO_CONTRIBUYENTE']['MONTO_MINIMO_BASE']) {
                    iva_materia_prima = valor_factura * VARIABLES['REGIMEN_PEQUENIO_CONTRIBUYENTE']['PORCENTAJE_DESCUENTO_IVA'];
                    retencion_peq_contribuyente = iva_materia_prima;
                }
                form.values.retencion_peq_contribuyente = retencion_peq_contribuyente;
            }
            if (finca.regimen_fiscal === FACTURA_ESPECIAL) {
                iva_materia_prima = valor_factura_sin_iva * VARIABLES['REGIMEN_FACTURA_ESPECIAL']['PORCENTAJE_DESCUENTO_IVA'];
                isr_materia_prima = valor_factura_sin_iva * VARIABLES['REGIMEN_FACTURA_ESPECIAL']['PORCENTAJE_DESCUENTO_ISR'];

                retencion_iva = iva_materia_prima;
                retencion_isr = isr_materia_prima;

                form.values.retencion_iva = retencion_iva;
                form.values.retencion_isr = retencion_isr;
            }
            if (finca.regimen_fiscal === PAGOS_TRIMESTRALES) {
                if (valor_factura > VARIABLES['REGIMEN_PAGOS_TRIMESTRALES']['MONTO_MINIMO_BASE']) {
                    iva_materia_prima = valor_factura_sin_iva * VARIABLES['REGIMEN_PAGOS_TRIMESTRALES']['PORCENTAJE_DESCUENTO_IVA'];
                    descuento_iva = iva_materia_prima * VARIABLES['REGIMEN_PAGOS_TRIMESTRALES']['PORCENTAJE_DESCUENTO_RETENCION_IVA'];

                    retencion_iva = descuento_iva;
                }
                form.values.retencion_iva = retencion_iva;
            }
            if (finca.regimen_fiscal === PAGOS_DIRECTOS) {
                if (valor_factura > VARIABLES['REGIMEN_PAGOS_DIRECTOS']['MONTO_MINIMO_BASE']) {
                    // Calculo del IVA
                    iva_materia_prima = valor_factura_sin_iva * VARIABLES['REGIMEN_PAGOS_DIRECTOS']['PORCENTAJE_DESCUENTO_BASE_IVA'];
                    descuento_iva = iva_materia_prima * VARIABLES['REGIMEN_PAGOS_DIRECTOS']['PORCENTAJE_DESCUENTO_BASE_RETENCION_IVA'];

                    // Calculo del ISR
                    if (valor_factura_sin_iva >= VARIABLES['REGIMEN_PAGOS_DIRECTOS']['MONTO_MINIMO_SUPERIOR']) {
                        total_temp_isr = valor_factura_sin_iva - VARIABLES['REGIMEN_PAGOS_DIRECTOS']['MONTO_MINIMO_SUPERIOR'];

                        isr_materia_prima = VARIABLES['REGIMEN_PAGOS_DIRECTOS']['MONTO_MINIMO_SUPERIOR'] * VARIABLES['REGIMEN_PAGOS_DIRECTOS']['PORCENTAJE_DESCUENTO_MONTO_SUPERIOR_ISR'];
                        isr_materia_prima_restante = total_temp_isr * VARIABLES['REGIMEN_PAGOS_DIRECTOS']['PORCENTAJE_DESCUENTO_MONTO_RESTANTE_ISR'];
                        retencion_isr = isr_materia_prima + isr_materia_prima_restante;
                    }
                    else {
                        isr_materia_prima = valor_factura_sin_iva * VARIABLES['REGIMEN_PAGOS_DIRECTOS']['PORCENTAJE_DESCUENTO_MONTO_SUPERIOR_ISR'];
                        retencion_isr = isr_materia_prima;
                    }

                    retencion_iva = descuento_iva;
                    retencion_isr_pagos_directos = parseFloat(retencion_isr)      ;
                }

                form.values.retencion_iva = retencion_iva;
                form.values.retencion_isr = retencion_isr;
            }
            if (finca.regimen_fiscal === EXPORTADOR) {
                form.values.retencion_iva = retencion_iva;
                form.values.retencion_isr = retencion_isr;
            }
            if (finca.regimen_fiscal === PRODUCCION_INTERNA) {
                form.values.retencion_iva = retencion_iva;
                form.values.retencion_isr = retencion_isr;
            }
        }
        total_liquidar = parseFloat(valor_factura) - parseFloat(retencion_iva) - parseFloat(retencion_isr) - parseFloat(retencion_peq_contribuyente)
        form.values.total_liquidar = total_liquidar;
    }

    return {
        total_dolares,
        total_quetzales,
        kilos_secos_liquidar,
        kilos_humedos_liquidar,
        total_liquidar,
        retencion_isr,
        retencion_iva,
        retencion_peq_contribuyente,
        total_insumos
    }
})(LiquidacionesForm);

export default LiquidacionesForm
