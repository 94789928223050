import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/bancos/bancos';
import BancoCrear from './BancoCrear';

const mstp = state => {
    return {...state.bancos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(BancoCrear)
