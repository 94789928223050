import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './PerfilFincaForm';
import EditarPerfilForm from './PerfilFincaForm';

export default class PerfilFincaCrear extends Component {
  state = {
      editar: false,
      mostrar: false
  };
  componentWillMount(){

    const {data}= this.props;
    let result;
    if(data && data.results){
        result =  data.results.find(perfil => perfil.produccion_interna===true)
       this.setState({mostrar: result ? true : false })
    }

    if(this.props.match.params.id){
        this.props.leer(this.props.match.params.id);

        if(result && this.props.match.params.id == result.id ){
            this.setState({mostrar: false})
        }
        this.setState({editar: true});
    }
      
  }
    actualizar = (data) =>{
        this.props.editar(this.props.match.params.id, data)
    };
  render() {
    const { crear, editar } = this.props;
    const { updateData, loader } = this.props;
    return (
        <div className="mb-4 col-12">
          <CardForm
              icon="configuraciones_activo"
              titulo="PERFIL"
              subtitulo="Nuevo">
              <LoadMask loading={loader} blur>
                  {
                      this.state.editar ?
                          <EditarPerfilForm
                              onSubmit={this.actualizar}
                              updateData={updateData}
                              editando={true}
                              produccionInterna={this.state.mostrar}
                               />
                          :
                          <Form 
                          onSubmit={crear}
                          produccionInterna={this.state.mostrar}/>
                  }
              </LoadMask>
          </CardForm>
      </div>
    )
  }
}
