import React from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { actions } from "../../../../../redux/modules/reportehistorialtanques";
import DatePicker from "react-datepicker";
import { ErrorMessage } from "@hookform/error-message";
import { Fragment } from "react";

export const Filtros = (props) => {
    const {
        select_fecha_inicial,
        select_fecha_final,
        tanques,
        setSelectFechaFinal,
        setSelectFechaInicial,
        setSelectMovimiento,
        setSelectTanque,
        obtener_data,
        obtener_reporte_excel,
        loader_excel,
    } = props;

    const {
        formState: { errors },
        control,
        register,
        handleSubmit,
    } = useForm();

    const onSubmit = (data) => obtener_data();

    const MOVIMIENTOS = [
        { value: "entrada", label: "Entrada" },
        { value: "salida", label: "Salida" },
        { value: "reajuste", label: "Reajuste" },
    ];

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="pb-3">
            <div className="row">
                <div className="col-12 col-md-4">
                    <div className="form-group">
                        <label htmlFor="movimiento">Movimiento</label>
                        <Controller
                            name="movimiento"
                            control={control}
                            defaultValue=""
                            rules={{
                                validate: (value) => {
                                    return value && value.length > 0;
                                },
                            }}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <Select
                                    isMulti
                                    options={MOVIMIENTOS}
                                    placeholder="Seleccione un movimiento"
                                    noOptionsMessage={() => "No hay opciones"}
                                    onChange={(value) => {
                                        setSelectMovimiento(value);
                                        onChange(value);
                                    }}
                                    isClearable
                                    onBlur={onBlur}
                                    value={value}
                                />
                            )}
                        />

                        <ErrorMessage
                            errors={errors}
                            name="movimiento"
                            render={({ message }) => (
                                <p className="text-danger p-0 m-0 pl-2">
                                    Debe seleccionar al menos un movimiento
                                </p>
                            )}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="form-group">
                        <label htmlFor="fecha_inicial">Fecha Inicial</label>
                        <Controller
                            name="fecha_inicial"
                            control={control}
                            defaultValue={select_fecha_inicial}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <DatePicker
                                    showPopperArrow={false}
                                    dateFormat="DD/MM/YYYY"
                                    placeholderText="Fecha de Inicial"
                                    onChange={(value) => {
                                        setSelectFechaInicial(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={select_fecha_inicial}
                                    selected={select_fecha_inicial}
                                />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Este campo es requerido",
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="fecha_inicial"
                            render={({ message }) => (
                                <p className="text-danger p-0 m-0 pl-2">
                                    {message}
                                </p>
                            )}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-3">
                    <div className="form-group">
                        <label htmlFor="fecha_final">Fecha Final</label>
                        <Controller
                            name="fecha_final"
                            control={control}
                            defaultValue={select_fecha_final}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <DatePicker
                                    showPopperArrow={false}
                                    dateFormat="DD/MM/YYYY"
                                    placeholderText="Fecha de Final"
                                    onChange={(value) => {
                                        setSelectFechaFinal(value);
                                        onChange(value);
                                    }}
                                    onBlur={onBlur}
                                    value={select_fecha_final}
                                    selected={select_fecha_final}
                                />
                            )}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Este campo es requerido",
                                },
                            }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="fecha_final"
                            render={({ message }) => (
                                <p className="text-danger p-0 m-0 pl-2">
                                    {message}
                                </p>
                            )}
                        />
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="form-group">
                        <label htmlFor="tanques">Tanques</label>
                        <Controller
                            name="tanques"
                            control={control}
                            defaultValue=""
                            rules={{
                                validate: (value) => {
                                    return value && value.length > 0;
                                },
                            }}
                            render={({
                                field: { onChange, onBlur, value },
                            }) => (
                                <Select
                                    isMulti
                                    options={tanques}
                                    placeholder="Seleccione un tanque"
                                    noOptionsMessage={() => "No hay opciones"}
                                    onChange={(value) => {
                                        setSelectTanque(value);
                                        onChange(value);
                                    }}
                                    isClearable
                                    onBlur={onBlur}
                                    value={value}
                                />
                            )}
                        />

                        <ErrorMessage
                            errors={errors}
                            name="tanques"
                            render={({ message }) => (
                                <p className="text-danger p-0 m-0 pl-2">
                                    Debe seleccionar al menos un tanque
                                </p>
                            )}
                        />
                    </div>
                </div>
            </div>
            <div className="row justify-content-md-end">
                <div className="col-12 col-md-6 ">
                    <div className="row justify-content-md-end ">
                        <div className="col-12 col-md-4">
                            <button
                                type="submit"
                                className="btn btn-primary w-100 text-nowrap "
                            >
                                Generar reporte
                            </button>
                        </div>
                        <div className="col-12 col-md-4 ">
                            <button
                                className="btn btn-primary w-100 text-nowrap "
                                onClick={(e) => {
                                    e.preventDefault();
                                    obtener_reporte_excel();
                                }}
                                disabled={loader_excel}
                            >
                                {loader_excel ? (
                                    <Fragment>
                                        Obteniendo excel...{" "}
                                        <i className="fa fa-spinner fa-spin"></i>
                                    </Fragment>
                                ) : (
                                    <Fragment>Generar excel</Fragment>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

const mapStateToProps = (state) => ({
    ...state.rht,
});

const mapDispatchToProps = {
    ...actions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Filtros);
