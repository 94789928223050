import React, {Component} from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../FiltrosHeader';
import TabBar from 'rc-tabs/lib/TabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import Tabs, {TabPane} from 'rc-tabs';
import Reporte from './Reporte';
import CardEmpty from '../../../Utils/Cards/CardEmpty';

export default class ReporteExcedenteAmoniaco extends Component {
    componentWillMount() {
        const { listarDataReporteExcedenteAmoniaco, page } = this.props;
        // listarDataReporteExcedenteAmoniaco(page);
    }

    render() {
        const {
            pageReporteChangeExcedenteAmoniaco,
            pageReporteChangeExcedenteAmoniacoFinca,
            filtroFechaInicioExcedenteAmoniaco,
            filtroFechaFinExcedenteAmoniaco,
            filtroFincaExcedenteAmoniaco,
            precioReporteAmoniaco,
            concentracionReporteAmoniaco,
            page_reporte_excendente_amoniaco,
            page_reporte_excendente_amoniaco_finca,
            fecha_inicio_reporte_excendente_amoniaco,
            fecha_fin_reporte_excendente_amoniaco,
            reporte_precio_amoniaco,
            reporte_concentracion_amoniaco,
            finca_reporte_excendente_amoniaco,
    // filtroFechaInicioProduccion, filtroFechaFinProduccion, filtroMateriaPrimaProduccion,
    //         fecha_inicio_reporte, fecha_fin_reporte, materia_prima_id_reporte, page_reporte, pageReporteChange
        } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="EXCEDENTE AMONIACO"
                    noBorder
                    subtitulo="Reporte">
                    <CardEmpty>
                        <FiltrosHeader {...this.props}
                            fechaInicialChange={filtroFechaInicioExcedenteAmoniaco} fecha_inicial={fecha_inicio_reporte_excendente_amoniaco}
                            fechaFinalChange={filtroFechaFinExcedenteAmoniaco} fecha_final={fecha_fin_reporte_excendente_amoniaco}
                            precioReporteAmoniaco={precioReporteAmoniaco} reporte_precio_amoniaco={reporte_precio_amoniaco}
                            concentracionReporteAmoniaco={concentracionReporteAmoniaco} reporte_concentracion_amoniaco={reporte_concentracion_amoniaco}
                            filtroFincasChange={filtroFincaExcedenteAmoniaco} fincas_ids={finca_reporte_excendente_amoniaco}
                            filtrarXReporte={()=>{this.props.listarDataReporteExcedenteAmoniaco()}}
                            labelXReporte={"Filtrar"}
                        />
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <Reporte
                        {...this.props} pageChange={pageReporteChangeExcedenteAmoniaco} page={page_reporte_excendente_amoniaco}
                        pageReporteChangeExcedenteAmoniacoFinca={pageReporteChangeExcedenteAmoniacoFinca} page_reporte_excendente_amoniaco_finca={page_reporte_excendente_amoniaco_finca}
                        reporte_precio_amoniaco={reporte_precio_amoniaco} reporte_concentracion_amoniaco={reporte_concentracion_amoniaco}
                    ></Reporte>
                </Card>
            </div>
        )
    }
}
