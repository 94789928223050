import React from 'react';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import Grid from '../../../Utils/Grid';
import { RenderNumber } from '../../../Utils/renderField/renderReadField';
import { PrintContainer } from 'Utils/Print';

const Reporte = (props) => {
    const {loader, data,fecha_inicio_reporte, fecha_fin_reporte} = props;
    // Constante con la data
    const data_fincas = data && data.results ? data.results : [];

    // Variables de totales de meses.
    let total_enero = 0;
    let total_febrero = 0;
    let total_marzo = 0;
    let total_abril = 0;
    let total_mayo = 0;
    let total_junio = 0;
    let total_julio = 0;
    let total_agosto = 0;
    let total_septiembre = 0;
    let total_octubre = 0;
    let total_noviembre = 0;
    let total_diciembre = 0;
    let total_total = 0;

    for (let i = 0; i < data_fincas.length; i++) {
        total_enero += data_fincas[i].Enero.total_kilos_secos;
        total_febrero += data_fincas[i].Febrero.total_kilos_secos;
        total_marzo += data_fincas[i].Marzo.total_kilos_secos;
        total_abril += data_fincas[i].Abril.total_kilos_secos;
        total_mayo += data_fincas[i].Mayo.total_kilos_secos;
        total_junio += data_fincas[i].Junio.total_kilos_secos;
        total_julio += data_fincas[i].Julio.total_kilos_secos;
        total_agosto += data_fincas[i].Agosto.total_kilos_secos;
        total_septiembre += data_fincas[i].Septiembre.total_kilos_secos;
        total_octubre += data_fincas[i].Octubre.total_kilos_secos;
        total_noviembre += data_fincas[i].Noviembre.total_kilos_secos;
        total_diciembre += data_fincas[i].Diciembre.total_kilos_secos;
        total_total += data_fincas[i].total_kilos_secos_anuales;
    }

    return (
        <div>
            <div className="mb-4">
                <CardEmpty noShadow>
                    <PrintContainer name='reporte-kg-secos-anuales'>
                        <div className="no-mostrar text-center">
                            <h2 className="azul">REPORTE KG. SECOS ANUALES</h2>
                        </div>
                        <div className=" col-12 form-group np-r p-0 mb-1 inicio-tabla impresion-horizontal text-xs">
                            <Grid hover striped loading={loader} data={data}
                                pagination={false}
                                className="pt-3"
                            >
                                <TableHeaderColumn
                                    dataField="nombre"
                                    dataSort
                                    isKey
                                    dataFormat={(cell,row) => <span className={row.anulado ? "tachado" : ""}>{cell}</span>}
                                    width="10"
                                >
                                    FINCA
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Enero"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumber value={cell && cell.total_kilos_secos ? cell.total_kilos_secos : 0} decimalScale={2}/>}
                                    width="7.5"
                                >
                                    ENE.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Febrero"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumber value={cell && cell.total_kilos_secos ? cell.total_kilos_secos : 0} decimalScale={2}/>}
                                    width="7.5"
                                >
                                    FEB.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Marzo"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumber value={cell && cell.total_kilos_secos ? cell.total_kilos_secos : 0} decimalScale={2}/>}
                                    width="7.5"
                                >
                                    MAR.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Abril"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumber value={cell && cell.total_kilos_secos ? cell.total_kilos_secos : 0} decimalScale={2}/>}
                                    width="7.5"
                                >
                                    ABR.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Mayo"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumber value={cell && cell.total_kilos_secos ? cell.total_kilos_secos : 0} decimalScale={2}/>}
                                    width="7.5"
                                >
                                    MAY.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Junio"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumber value={cell && cell.total_kilos_secos ? cell.total_kilos_secos : 0} decimalScale={2}/>}
                                    width="7.5"
                                >
                                    JUN.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Julio"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumber value={cell && cell.total_kilos_secos ? cell.total_kilos_secos : 0} decimalScale={2}/>}
                                    width="7.5"
                                >
                                    JUL.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Agosto"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumber value={cell && cell.total_kilos_secos ? cell.total_kilos_secos : 0} decimalScale={2}/>}
                                    width="7.5"
                                >
                                    AGO.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Septiembre"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumber value={cell && cell.total_kilos_secos ? cell.total_kilos_secos : 0} decimalScale={2}/>}
                                    width="7.5"
                                >
                                    SEP.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Octubre"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumber value={cell && cell.total_kilos_secos ? cell.total_kilos_secos : 0} decimalScale={2}/>}
                                    width="7.5"
                                >
                                    OCT.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Noviembre"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumber value={cell && cell.total_kilos_secos ? cell.total_kilos_secos : 0} decimalScale={2}/>}
                                    width="7.5"
                                >
                                    NOV.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="Diciembre"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumber value={cell && cell.total_kilos_secos ? cell.total_kilos_secos : 0} decimalScale={2}/>}
                                    width="7.5"
                                >
                                    DIC.
                                </TableHeaderColumn>
                                <TableHeaderColumn
                                    dataField="total_kilos_secos_anuales"
                                    dataSort
                                    dataAlign="right"
                                    dataFormat={(cell,row) => <RenderNumber value={cell  ? cell : 0} decimalScale={2}/>}
                                    width="7.5"
                                >
                                    TOTAL
                                </TableHeaderColumn>
                            </Grid>
                            <div className="total-final pb-4">
                                <div className="react-bs-table">
                                    <div className="react-bs-container-header table-header-wrapper">
                                        <table className="table table-hover table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: "10%" }} className="text-left">TOTAL</th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumber value={total_enero} decimalScale={2}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumber value={total_febrero} decimalScale={2}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumber value={total_marzo} decimalScale={2}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumber value={total_abril} decimalScale={2}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumber value={total_mayo} decimalScale={2}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumber value={total_junio} decimalScale={2}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumber value={total_julio} decimalScale={2}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumber value={total_agosto} decimalScale={2}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumber value={total_septiembre} decimalScale={2}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumber value={total_octubre} decimalScale={2}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumber value={total_noviembre} decimalScale={2}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumber value={total_diciembre} decimalScale={2}/></th>
                                                    <th style={{ width: "7.5%" }} className="text-right"><RenderNumber value={total_total} decimalScale={2}/></th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PrintContainer>
                </CardEmpty>

            </div>
        </div>
    )
};

export default Reporte;
