import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/tipo_pago/tipo_pago';
import TipoPagoCrear from './TipoPagoCrear';

const mstp = state => {
    return {...state.tipos_pago}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(TipoPagoCrear)
