import React, { Component } from 'react'
import Card from '../../../../Utils/Cards/Card'
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import LoadMask from '../../../../Utils/LoadMask/LoadMask';
import DetalleRecepcionForm from './DetalleRecepcionForm';


class DetalleRecepcionHistorico extends Component {
    componentDidMount() {
        const { leerRecepcion } = this.props;
        leerRecepcion(this.props.match.params.id)
    }

    render() {
        const { item_recepcion, loader } = this.props
        return (
            <div className="mb-4 col-12">
                <Card
                    icon="historico_activo"
                    titulo="DATOS HISTÓRICOS"
                    noBorder
                    subtitulo="Recepciones"
                >
                    <LoadMask
                        loading={loader}
                        blur
                    >
                        <section className="d-flex justify-content-center mt-4">
                            <div
                                className="card p-3"
                                style={{width: '100%'}}
                            >
                                <DetalleRecepcionForm {...this.props} item={item_recepcion}/>
                            </div>
                        </section>
                    </LoadMask>
                </Card>
            </div>
        )
    }
}

export default DetalleRecepcionHistorico