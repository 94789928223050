import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import Form from './BodegaForm';
import EditarBodegaForm from './BodegaForm';

export default class BodegaCrear extends Component {
  state = {
      editar: false,
  };
  componentWillMount(){
      if(this.props.match.params.id){
          this.props.leer(this.props.match.params.id);
          this.setState({editar: true});
      }
  }
    actualizar = (data) =>{
        this.props.editar(this.props.match.params.id, data)
    };
  componentWillUnmount() {
      this.props.clearData()
  }

    render() {
    const { crear, editar } = this.props;
    const { updateData, loader, resetAlturaMaxima } = this.props;
    return (
        <div className="mb-4 col-12">
          <CardForm
              icon="configuraciones_activo"
              titulo="ALMACÉN / PILA"
              subtitulo="Nuevo"
              col="9">
              <LoadMask loading={loader} blur>
                  {
                      this.state.editar ?
                          <EditarBodegaForm
                              onSubmit={this.actualizar}
                              updateData={updateData}
                              resetAlturaMaxima={resetAlturaMaxima}
                              editando={true}
                              {...this.props}
                          />
                          :
                          <Form onSubmit={crear} {...this.props} creando={true}/>
                  }
              </LoadMask>
          </CardForm>
      </div>
    )
  }
}
