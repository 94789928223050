import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Field, FieldArray, reduxForm} from 'redux-form';
import _ from 'lodash';
import {renderNumberKG, SelectField, renderNumber} from '../../../Utils/renderField/renderField';
import validador from './validate';
import {RenderKilogramo, RenderNumber} from "../../../Utils/renderField/renderReadField";
import AnularModal from "./Anular/AnularModal";
import { SOLIDO, LATEX, unidades_medida } from '../../../../../utility/constants';

const renderProductos = (props) => {
    const {
        fields, meta: {error, submitFailed}, opciones, item, registrarFormater,
        productos_solido, productos_latex, establecerKilogramosPesajeIngreso,
    } = props;
    const { tipo_materia } = item;
    const es_recepcion_por_anulacion = item && item.es_recepcion_por_anulacion || false;
    let lista_materias = [];
    switch(tipo_materia.tipo) {
        case SOLIDO:
            lista_materias = [...productos_solido];
            break;
        case LATEX:
            lista_materias = [...productos_latex];
            break;
    }
    const esProductoSolido = tipo_materia && tipo_materia.tipo === SOLIDO;
    return (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                    <tr>
                        <th style={{width: "3%"}}/>
                        <th style={{width: "17%"}}/>
                        <th style={{width: "16%"}}>MATERIA PRIMA</th>
                        <th style={{width: "16%"}}>PESO FINCA</th>
                        <th style={{width: "16%"}}>PESO FINCA KG</th>
                        <th style={{width: "16%"}}>ALMACÉN</th>
                        {!es_recepcion_por_anulacion && (
                        <th style={{width: "16%"}}>PESO INGRESO (BRUTO)</th>)}
                    </tr>
                    </thead>
                </table>
            </div>
            {/*Contenido de la tabla*/}
            <div className="tabla-con-borde">
                <table className="table  m-0 table-striped">
                    <tbody>
                    {fields.map((producto, index) => {
                        const prod_actual = fields.get(index); //Obtiene el producto actual
                        const esProductoEditable = !prod_actual.peso_tara && !item.anulado;
                        return (
                            <tr key={index}>
                                <td className="text-center sin-borde-top" style={{width: "3%"}}>
                                    { esProductoEditable && esProductoSolido && !es_recepcion_por_anulacion &&(
                                        <img src={require('assets/img/icons/borrar.png')} alt="Borrar"
                                             title="Borrar" className="action_img"
                                             onClick={() => {
                                                 fields.remove(index)
                                             }}
                                        />
                                    ) }
                                </td>
                                <td className="text-center sin-borde-top" style={{width: "17%"}}>
                                    { (prod_actual.id && esProductoEditable) && (
                                        <Link className="btn btn-outline-info py-0"
                                                to={`/ingreso/${prod_actual.id}/pesar/registrar`}>
                                            REGISTRAR PESO
                                        </Link>
                                    ) }
                                </td>
                                <td className="sin-borde-top" style={{width: "16%"}}>
                                    { esProductoEditable ? (
                                        <Field name={`${producto}.materia_prima`}
                                            component={SelectField}
                                            options={lista_materias}
                                            valueKey="id"
                                            objAsValue={true}
                                            disabled={es_recepcion_por_anulacion}
                                            onCambio={() => establecerKilogramosPesajeIngreso(index)}
                                            className="form-control"/>
                                    ) : (
                                        <span>{prod_actual.materia_prima_nombre}</span>
                                    ) }
                                </td>
                                <td className="sin-borde-top" style={{width: "16%"}}>
                                    { esProductoEditable ? (
                                        <Fragment>
                                            <Field name={`${producto}.peso_finca`}
                                                type="number"
                                                onCambio={() => establecerKilogramosPesajeIngreso(index)}
                                                component={renderNumber}
                                                decimalScale={2}
                                                parse={val => parseFloat(val)}/>
                                            <Field name={`${producto}.peso_finca_unidad`}
                                                component={SelectField}
                                                onCambio={() => establecerKilogramosPesajeIngreso(index)}
                                                options={unidades_medida}
                                                className="form-control"/>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <RenderNumber value={prod_actual.peso_finca} decimalScale={0}/>
                                            &nbsp;{prod_actual.peso_finca_unidad_text}
                                        </Fragment>
                                    ) }
                                </td>
                                <td style={{width: "16%"}}>
                                    { esProductoEditable ? (
                                        <Field name={`${producto}.peso_finca_kg`}
                                                component={renderNumber}
                                                decimalScale={2}
                                                disabled={true}
                                                className="form-control"/>
                                    ) : (
                                        <RenderKilogramo value={prod_actual.peso_finca_kg}/>
                                    ) }
                                </td>
                                <td className="sin-borde-top" style={{width: "16%"}}>
                                    {es_recepcion_por_anulacion ? (<span>
                                        CANAL DE COAGULACIÓN
                                    </span>):(<span>{prod_actual.bodega ? prod_actual.bodega.nombre : '--'}</span>)}
                                </td>

                                {!es_recepcion_por_anulacion && (
                                <td style={{width: "16%"}}>
                                    {
                                        (prod_actual.peso_bruto && !prod_actual.peso_tara) ? (
                                            <Field name={`${producto}.peso_bruto`} component={renderNumberKG}
                                                   className="form-control"
                                                   parse={value => parseFloat(value)}/>
                                        ) : (
                                            <RenderKilogramo value={prod_actual.peso_bruto}/>
                                        )
                                    }
                                </td>
                                )}

                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            { esProductoSolido && !es_recepcion_por_anulacion && (
                <div className="d-flex justify-content-center mt-2">
                    <button type="button" className="btn btn-outline-info py-0" onClick={() => fields.push({index: fields.length})}>
                        AGREGAR MATERIA PRIMA
                    </button>
                </div>
            ) }
        </div>
    </div>
    );
};

let ProductosForm = props => {
    const {
        item, handleSubmit, open, openModal, closeModal, anularRecepcion, opciones, registrarFormater,
        productos_solido, productos_latex, establecerKilogramosPesajeIngreso,
        total_productos,
    } = props;
    return (
        <Fragment>
            <AnularModal open={open} closeModal={closeModal} item={item} anularRecepcion={anularRecepcion}/>
            <form onSubmit={handleSubmit} className="col-12">
                <div className="mt-3">
                    <FieldArray name="productos" component={renderProductos} opciones={opciones} item={item}
                        registrarFormater={registrarFormater} productos_solido={productos_solido} productos_latex={productos_latex}
                        establecerKilogramosPesajeIngreso={establecerKilogramosPesajeIngreso}
                    />
                </div>
                <div className="buttons-box mt-5">
                    <Link to="/ingresos" className="btn btn-outline-dark mr-5">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary mr-5" disabled={item.anulado || !total_productos}>GUARDAR</button>
                    <button type="button" onClick={(e) => {e.preventDefault(); openModal()}} disabled={item.anulado} className="btn btn-secondary">ANULAR</button>
                </div>
            </form>
        </Fragment>
    )
};
ProductosForm = reduxForm({
    form: 'ProductosForm',
    asyncBlurFields: [],
    validate: validador,
})(ProductosForm);

ProductosForm = connect(state => {
    const total_productos = _.get(state, 'form.ProductosForm.values.productos', []).length;
    return {
        total_productos,
    }
})(ProductosForm);

export default ProductosForm
