import React, { useCallback, useEffect, useRef } from "react";
import Card from "Utils/Cards/Card";
import CardEmpty from "../../../Utils/Cards/CardEmpty";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
import Filtros from "./Filtros";
import TablaMovimientosTanques from "./TablaMovimientosTanques";

const ReporteHistorialTanques = ({
    loader,
    obtenerDataInicial,
    loader_init,
}) => {
    useEffect(() => {
        obtenerDataInicial();
    }, []);

    return (
        <div className="mb-4 col-12">
            <Card
                icon="reportes_activo"
                titulo="HISTORIAL TANQUES"
                noBorder
                subtitulo="Reporte"
            >
                <LoadMask loading={loader_init || loader} blur>
                    <CardEmpty>
                        <Filtros />
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <CardEmpty>
                        <TablaMovimientosTanques />
                    </CardEmpty>
                </LoadMask>
            </Card>
        </div>
    );
};

export default ReporteHistorialTanques;
