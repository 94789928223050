import { handleActions } from 'redux-actions';
import { createReducer } from "../baseReducer/baseReducer";
import { api } from 'api';


// ------------------------------------
// Constants
// ------------------------------------
const storeId = 'materia_prima';
const endpoint = 'materia_prima';

const { reducers: _reducers, initialState: _initialState, actions: _actions } = createReducer(
    storeId,
    endpoint,
    "MateriaPrimaForm",
    "/materias_primas",
);

const constants = {
    MATERIAS_LATEX_CONTAMINADO: `${storeId.toUpperCase()}_MATERIAS_LATEX_CONTAMINADO`,
    LOADER: `${storeId.toUpperCase()}_LOADER`,
}

const getMateriasCoagulo = () => (dispatch, getStore) => {
    const params = {};
    params.latex_contaminado = true;
    params.no_paginar = true;
    dispatch({type: constants.LOADER, loader: true});
    api.get(endpoint, params).then((response) => {
        dispatch({type: constants.MATERIAS_LATEX_CONTAMINADO, materias_latex_contaminado: response.results});
    }).catch(() => {
    }).finally(() => {
        dispatch({type: constants.LOADER, loader: false});
    });
}

export const actions = {
    ..._actions,
    getMateriasCoagulo,
};

export const reducers = {
    ..._reducers,
    [constants.MATERIAS_LATEX_CONTAMINADO]: (state, { materias_latex_contaminado }) => {
        return {
            ...state,
            materias_latex_contaminado,
        };
    },
};

export const initialState = {
    ..._initialState,
    materias_latex_contaminado: [],
};

export default handleActions(reducers, initialState);
