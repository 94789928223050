import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import {
    renderField,
    SelectField,
    AsyncSelectField,
    renderFieldRadio,
} from '../../../Utils/renderField/renderField';
import { api } from "api";
import {regimenes} from "../../../../../utility/constants";

const getBancos = (search) => {
    return api.get("banco", { search }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};
const getTiposPago = (search) => {
    return api.get("tipo_pago", { search }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

let PasoTresForm = props => {
    const { handleSubmit, previousStep, regimenes_fiscales } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <h6 className="ml-4 mb-4">DATOS DE PAGO DE FINCA</h6>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre_cuenta" className="m-0">NOMBRE DE CUENTA BANCARIA</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre_cuenta" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="banco" className="m-0">BANCO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                valueKey="id"
                                name="banco"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getBancos}
                            />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="numero_cuenta" className="m-0">NÚMERO DE CUENTA BANCARIA</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="numero_cuenta" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="tipo_pago" className="m-0">TIPO DE PAGO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                valueKey="id"
                                name="tipo_pago"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getTiposPago}
                            />
                        </div>
                    </div>
                </div>
                 <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="direccion_fiscal" className="m-0">DIRECCIÓN FISCAL</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="direccion_fiscal" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nit" className="m-0">NIT</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nit" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="genero" className="m-0">RETIENE IVA</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <div className="d-flex justify-content-around">
                                <Field
                                    label="NO"
                                    type="radio"
                                    value={false}
                                    name="retenedor_iva"
                                    parse={val => val === 'true'}
                                    component={renderFieldRadio}
                                />
                                <Field
                                    label="SI"
                                    type="radio"
                                    value={true}
                                    name="retenedor_iva"
                                    parse={val => val === 'true'}
                                    component={renderFieldRadio}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="email" className="m-0">RÉGIMEN FISCAL</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="regimen_fiscal" component={SelectField} options={regimenes_fiscales}  type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="mt-3"></div>
                <div className="buttons-box mt-5">
                    <button className="btn btn-outline-dark mr-5" onClick={previousStep}>ANTERIOR</button>
                    <button type="submit" className="btn btn-primary">SIGUIENTE</button>
                </div>
            </div>
        </form>
    )
};
PasoTresForm = reduxForm({
    form: 'fincaForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
        })
    }
})(PasoTresForm);

const selector = formValueSelector('PasoTresForm');
PasoTresForm = connect(state => {
    const datos_paso_tres = state.fincas.item;
    const form_paso_tres = state.form.fincaForm;
    let regimenes_fiscales = [];
    if (form_paso_tres && form_paso_tres.values && datos_paso_tres) {
        if (form_paso_tres.values.perfil) {
            // Asignación de los regímenes fiscales en base al perfil de la finca

            if( form_paso_tres.values.perfil.produccion_interna && form_paso_tres.values.perfil.tipo_db !== 'EXPORTADOR'){
                regimenes_fiscales = regimenes.filter((item) => item.value === 60);
            }else{
                regimenes_fiscales = form_paso_tres.values.perfil.tipo_db === 'EXPORTADOR'
                    ? regimenes.filter((item) => item.value === 50)
                    : regimenes.filter((item) => item.value !== 50 && item.value !== 60 );
            } 
        }
    }
    return {
        regimenes_fiscales,
    }
})(PasoTresForm);

export default PasoTresForm
