import React from 'react';
import moment from 'moment/moment';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import Grid from '../../../Utils/Grid';
import { RenderKilogramo, RenderCurrency, RenderDollar, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';

const Detalle = (props) => {
    const {loader, data, item, page, pageChange} = props;
    return (
        <div>
            <div className="mb-4">
                <CardEmpty noShadow>
                    <div className=" col-12 form-group np-r p-0 mb-1 inicio-tabla text-xs">
                        <Grid hover striped loading={loader} data={item}
                            pagination onPageChange={pageChange} page={page}
                            className="pt-3"
                        >
                            <TableHeaderColumn
                                dataField="ingreso"
                                dataSort
                                isKey
                                dataFormat={(cell,row) => cell && cell.id ? cell.id : ""}
                                // width="17"
                            >
                                NO. RECEP.
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="ingreso"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.fecha_entrada ? moment(cell.fecha_entrada).format('DD-MM-YYYY') : "--"}
                                // width="15"
                            >
                                FECHA INGRESO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="finca"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.nombre ? cell.nombre : ""}
                                // width="17"
                            >
                                FINCA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="materia_prima"
                                dataSort
                                // dataAlign="right"
                                dataFormat={(cell,row) => cell && cell.nombre ? cell.nombre : ""}
                                // width="17"
                            >
                                PRODUCTO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="movimiento"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderKilogramo value={cell && cell.peso_neto ? cell.peso_neto : 0}/>}
                                // width="17"
                            >
                                PESO NETO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="movimiento"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderKilogramo value={cell && cell.peso_neto && row.analisis_laboratorio && row.analisis_laboratorio.drc_promedio ? (cell.peso_neto * row.analisis_laboratorio.drc_promedio) : 0}/>}
                                // width="17"
                            >
                                KG. SECOS
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="analisis_laboratorio"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderNumberPercentage value={cell && cell.drc_promedio ? cell.drc_promedio : 0}/>}
                                // width="17"
                            >
                                DRC
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="analisis_laboratorio"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderNumberPercentage value={cell && cell.nh3 ? cell.nh3 : 0}/>}
                                // width="17"
                            >
                                NH3
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="analisis_laboratorio"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderNumberPercentage value={cell && cell.vfa ? cell.vfa : 0}/>}
                                // width="17"
                            >
                                VFA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="precio_venta"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderDollar value={cell ? cell : 0}/>}
                                // width="17"
                            >
                                PRECIO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total_dolares"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderDollar value={cell ? cell : 0}/>}
                                // width="17"
                            >
                                TOTAL $
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="total_quetzales"
                                dataSort
                                dataAlign="right"
                                dataFormat={(cell,row) => <RenderCurrency value={cell ? cell : 0}/>}
                                // width="17"
                            >
                                TOTAL Q
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="orden_compra"
                                dataSort
                                dataFormat={(cell,row) => cell && cell.id ? cell.id : ""}
                                // width="17"
                            >
                                NO. LIQ.
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </CardEmpty>
            </div>
        </div>
    )
};

export default Detalle;
