import React from 'react';
import { TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import Grid from '../../../Utils/Grid';
import { HeaderLiquidaciones } from '../../../Utils/Grid/Headers';
import { standardActions } from '../../../Utils/Grid/StandardActions';
import { RenderKilogramo, RenderCurrency, RenderDollar } from '../../../Utils/renderField/renderReadField';


const Liquidadas = props => {
    const { data_liquidadas: data, totales_liquidadas, pageChangeLiquidadas: pageChange } = props;
    const { loader, page } = data;
    const { fecha_inicio_liq_liquidadas, fecha_final_liq_liquidadas } = props;
    const { filtroFincaChangeLiqLiquidadas, fechaInicioLiqLiquidadas, fechaFinalLiqLiquidadas } = props;
    const { id_perfil_finca_liq } = props;
    const { filtroFincaTipoPagoLiqChange, filtroFincaPerfilLiqChange, filtroFincaRegimenFiscalLiqChange, listar, getTotalesParaLiquidaciones } = props;
    return(
        <div style={{minHeight: '450px'}}>
            <HeaderLiquidaciones
                {...props}
                search={undefined}
                dataTotales={totales_liquidadas}
                filtroFincaChange={filtroFincaChangeLiqLiquidadas}
                fecha_inicial={fecha_inicio_liq_liquidadas} fecha_final={fecha_final_liq_liquidadas}
                fechaInicialChange={fechaInicioLiqLiquidadas} fechaFinalChange={fechaFinalLiqLiquidadas}
                filtroFincaTipoPagoChange={filtroFincaTipoPagoLiqChange} filtroFincaPerfilChange={filtroFincaPerfilLiqChange} filtroFincaRegimenFiscalChange={filtroFincaRegimenFiscalLiqChange}
                id_perfil_finca={id_perfil_finca_liq}
                disableButton={loader} getDataLiquidaciones={() => {
                    listar();
                    getTotalesParaLiquidaciones();
                }}
            />
            <Grid hover striped loading={loader} data={data}
                pagination page={page} onPageChange={pageChange}
                noShowLoaderInfo
            >
                <TableHeaderColumn
                    dataField="id"
                    dataAlign="center"
                    isKey
                    width='15%'
                    dataSort
                    // dataFormat={standardActions({ aspel: "aspel", ver: "liquidacion"})}
                    dataFormat={standardActions({liquidacion: "liquidacion"})}
                />
                <TableHeaderColumn
                    dataField="id"
                    dataSort
                    width='10%'
                >
                    ID COMPRA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="fecha_liquidacion"
                    dataSort
                    width='10%'
                    dataFormat={cell => cell ? moment(cell).format("DD/MM/YYYY") : '--'}
                >
                    FECHA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="finca"
                    dataSort
                    width='15%'
                    dataFormat={cell => cell ? cell.nombre : ""}
                >
                    FINCA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="detalles"
                    dataSort
                    width='12%'
                    dataAlign="right"
                    dataFormat={
                        cell =>{
                            var kilos_totales = 0;
                            if (cell) {
                                for (let i = 0; i < cell.length; i++) {
                                    kilos_totales += cell[i].kilos_secos;
                                }
                                return <RenderKilogramo value={kilos_totales}/>
                            }
                        }
                    }
                >
                    KILOS SECOS
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total_dolares"
                    dataSort
                    width='12%'
                    dataAlign="right"
                    dataFormat={cell => <RenderDollar value={cell} />}
                >
                    TOTAL $.
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="total_quetzales"
                    dataSort
                    width='12%'
                    dataAlign="right"
                    dataFormat={cell => <RenderCurrency value={cell} />}
                >
                    TOTAL Q.
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="posee_factura"
                    dataSort
                    width='10%'
                    dataAlign="center"
                    dataFormat={cell =>
                        <img
                            src={cell ? require('assets/img/icons/activo.png') : require('assets/img/icons/inactivo.png')}
                            alt={cell ? "Activo" : "Inactivo"}
                            className="action_img"
                        />
                    }
                >
                    FACTURA
                </TableHeaderColumn>
                <TableHeaderColumn
                    dataField="numero_factura"
                    dataSort
                    width='12%'
                    dataAlign="center"
                    dataFormat={cell => cell ? cell : '-----'}
                >
                    NO. FACTURA
                </TableHeaderColumn>

            </Grid>
        </div>
    )
};
export default Liquidadas;
