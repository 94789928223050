import React, {Component} from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../FiltrosHeader';
import TabBar from 'rc-tabs/lib/TabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import Tabs, {TabPane} from 'rc-tabs';
import Reporte from './Reporte';
import CardEmpty from '../../../Utils/Cards/CardEmpty';

export default class ReporteFluctuacionPrecios extends Component {
    componentWillMount() {
        const { getFluctuacionPreciosReporte } = this.props;
        getFluctuacionPreciosReporte();
    }

    render() {
        const {
            pageReporteFluctuacionChange,
            filtroFechaInicioFluctuacion,
            filtroFechaFinFluctuacion,
            filtroFincaFluctuacion,
            page_reporte_fluctuacion,
            fecha_inicio_reporte_fluctuacion,
            fecha_fin_reporte_fluctuacion,
            finca_id_reporte_fluctuacion,
        } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="FLUCTUACIÓN PRECIOS"
                    noBorder
                    subtitulo="Reporte">
                    <CardEmpty>
                        <FiltrosHeader {...this.props}
                            fechaInicialChange={filtroFechaInicioFluctuacion} fecha_inicial={fecha_inicio_reporte_fluctuacion}
                            fechaFinalChange={filtroFechaFinFluctuacion} fecha_final={fecha_fin_reporte_fluctuacion}
                            filtroFincaChange={filtroFincaFluctuacion} finca_id={finca_id_reporte_fluctuacion}
                        />
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <Reporte {...this.props} pageChange={pageReporteFluctuacionChange} page={page_reporte_fluctuacion}></Reporte>
                </Card>
            </div>
        )
    }
}
