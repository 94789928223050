import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/laboratorio';
import LaboratorioCrear from './LaboratorioCrear';

const mstp = state => {
    const user = state.login.me;
    return {
       ...state.laboratorio,
        user
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(LaboratorioCrear)
