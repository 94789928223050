import React, {Fragment} from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import {AsyncSelectField, renderField, renderSwitch, SelectField} from '../../../Utils/renderField/renderField';
import { api } from "api";
import CardEmpty from "Utils/Cards/CardEmpty";

const getPropietarios = (search) => {
    return api.get("propietario", { search }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};
const getPerfiles = (search) => {
    return api.get("perfil_finca", { search }).
    then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

let PasoUnoForm = props => {
    const { handleSubmit, setStep, mostrar_trading } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container">
                <h6 className="ml-4 mb-4">DATOS GENERALES DE FINCA</h6>
                <CardEmpty noShadow>
                    <div className="row  col-md-12 p-0">
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="codigo_cliente_sae" className="m-0">CÓDIGO CLIENTE SAE</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="codigo_cliente_sae" component={renderField} type="text" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="codigo_proveedor_sae" className="m-0">CÓDIGO PROVEEDOR SAE</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="codigo_proveedor_sae" component={renderField} type="text" className="form-control" />
                            </div>
                        </div>
                    </div>
                    <div className="row  col-md-12 p-0">
                        {/* <div className="col-md-6 p-0">
                            <div className="col-md-12 col-sm-12">
                                <label htmlFor="codigo_cliente_sae_training" className="m-0">CÓDIGO CLIENTE SAE (TRADING)</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <Field name="codigo_cliente_sae_training" component={renderField} type="text" className="form-control" />
                            </div>
                        </div> */}
                        {mostrar_trading && (
                            <div className="col-md-6 p-0">
                                <div className="col-md-12 col-sm-12">
                                    <label htmlFor="codigo_proveedor_sae_training" className="m-0">CÓDIGO PROVEEDOR SAE (TRADING)</label>
                                </div>
                                <div className="col-md-12  form-group">
                                    <Field name="codigo_proveedor_sae_training" component={renderField} type="text" className="form-control" />
                                </div>
                            </div>
                        )}
                    </div>
                </CardEmpty>
                <div className="row col-md-12 m-0 mt-4 d-flex flex-wrap align-items-end">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">NOMBRE DE FINCA</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="razon_social" className="m-0">RAZÓN SOCIAL</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="razon_social" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end">
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="propietario" className="m-0">PROPIETARIO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                valueKey="id"
                                name="propietario"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getPropietarios}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="perfil" className="m-0">PERFIL DE FINCA</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field
                                valueKey="id"
                                name="perfil"
                                labelKey="nombre"
                                label="nombre"
                                component={AsyncSelectField}
                                loadOptions={getPerfiles}
                            />
                        </div>
                    </div>
                </div>
                <div className="row col-md-12 m-0 d-flex flex-wrap align-items-end">
                    <div className="col-md-6 p-0 d-flex align-items-center">
                        <div className="col-md-9 col-sm-9">
                            <label htmlFor="es_externa" className="m-0">¿ES FINCA EXTERNA?</label>
                        </div>
                            <div className="col-md-3 col-sm-3">
                                <Field name="es_externa" component={renderSwitch} type="text" className="form-control"/>
                            </div>
                    </div>
                </div>


                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/fincas">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">SIGUIENTE</button>
                </div>
            </div>
        </form>
    )
};
PasoUnoForm = reduxForm({
    form: 'fincaForm',
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
            'razon_social': validators.exists()('Campo requerido.'),
            'propietario': validators.exists()('Campo requerido.'),
            'perfil': validators.exists()('Campo requerido.'),
            'codigo_cliente_sae': validators.exists()('Campo requerido.'),
            'codigo_proveedor_sae': validators.exists()('Campo requerido.'),
            // 'codigo_cliente_sae_training': validators.exists()('Campo requerido.'),
            // 'codigo_proveedor_sae_training': validators.exists()('Campo requerido.'),
        })
    }
})(PasoUnoForm);

const selector = formValueSelector('PasoUnoForm');
PasoUnoForm = connect(state => {
    const form_paso_uno = state.form.fincaForm;
    let mostrar_trading = false;

    if (form_paso_uno && form_paso_uno.values) {
        if (form_paso_uno.values.perfil && form_paso_uno.values.perfil.tipo_db === 'EXPORTADOR') {
            mostrar_trading = true;
        }
        else{
            mostrar_trading = false;
        }
    }

    return {
        mostrar_trading,
    }
})(PasoUnoForm);

export default PasoUnoForm
