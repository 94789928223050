import React, {Component} from 'react'
import Card from "Utils/Cards/Card"
import Header from "Utils/Grid/Header";
import TabBar from "rc-tabs/lib/TabBar";
import TabContent from "rc-tabs/lib/TabContent";
import Tabs, {TabPane} from "rc-tabs";
import Activos from "./Activos";
import HistoricoIngresos from "./HistoricoIngresos";
import { FiltrosFecha } from '../../../Utils/Filters';
import CardEmpty from '../../../Utils/Cards/CardEmpty';

export default class IngresosList extends Component{
    componentWillMount() {
        const { listar, page_finalizadas, setFiltrarRecepcionesPorAcopio } = this.props;
        setFiltrarRecepcionesPorAcopio(true);
        listar(page_finalizadas);
    }

    render() {
        const { setTab, tab } = this.props;
        const { data, loader } = this.props;
        const {
            fecha_inicial_finalizadas, fecha_final_finalizadas,
            fechaInicialFinalizadasChange, fechaFinalFinalizadasChange, recepciones_activas,
            recepciones_finalizadas
        } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="recepciones_activo"
                    titulo="RECEPCIONES"
                    subtitulo="Materia prima"
                    noBorder
                >   
                    <Tabs
                        activeKey={tab}
                        tabBarPosition="top"
                        onChange={tab =>{setTab(tab)}}
                        renderTabBar={() => <TabBar />}
                        renderTabContent={() => <TabContent />}
                    >
                        <TabPane tab="ACTIVAS" key="ACTIVAS">
                            <CardEmpty noShadow>
                                <Header to="ingreso/crear" textBtn="CREAR INGRESO" {...this.props} />
                                <Activos dataActivos={recepciones_activas} {...this.props} />
                            </CardEmpty>
                        </TabPane>
                        <TabPane tab="FINALIZADAS" key="FINALIZADAS">
                            <CardEmpty noShadow>
                                <FiltrosFecha {...this.props} fecha_inicial={fecha_inicial_finalizadas} fecha_final={fecha_final_finalizadas} fechaInicioChange={fechaInicialFinalizadasChange} fechaFinChange={fechaFinalFinalizadasChange}/>
                                <HistoricoIngresos dataHistorico={recepciones_finalizadas} {...this.props}/>
                            </CardEmpty>
                        </TabPane>
                    </Tabs>
                </Card>
            </div>
        )
    }
}
