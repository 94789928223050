import React, { Fragment, useEffect, useState } from "react";
import CardForm from "../../../../Utils/Cards/CardForm";
import CardEmpty from "../../../../Utils/Cards/CardEmpty";
import Tabs, { TabPane } from "rc-tabs";
import TabBar from "rc-tabs/lib/TabBar";
import TabContent from "rc-tabs/lib/TabContent";
import TableRecepciones from "./TableRecepciones";

const CanalList = ({getTotals, totales_data}) => {
    const [vista, setVista] = useState("1");
    useEffect(() => {
        getTotals();
    }, []);

    const {peso_humedo} = totales_data;
    return (
        <Fragment>
            <CardForm
                titulo="Listado de recepciones"
                col="12"
                icon="bodega_activo"
                noPaddingXContent={true}
                noPaddingYContent={true}
            >
                <div className="row col-12">
                    <div className="my-2 w-100 d-flex align-items-center justify-content-between flex-wrap col-12">
                        <div>
                            <div className="mb-1 ml-2 mr-2">
                                <span className="gris">PESO TOTAL </span>
                                <span className="verde-primario text-md">
                                    {" "}
                                    {peso_humedo} 
                                </span>
                                <span className="gris"> KG</span>
                            </div>
                        </div>
                    </div>
                </div>
            </CardForm>
            <Tabs
                activeKey={vista}
                tabBarPosition="top"
                renderTabBar={() => <TabBar />}
                renderTabContent={() => <TabContent />}
                onChange={(vistaKey) => {
                    setVista(vistaKey);
                }}
            >
                <TabPane tab="Recepciones en proceso de coagulacion" key="1">
                    <CardEmpty noShadow={true}>
                        <TableRecepciones /> 
                    </CardEmpty>
                </TabPane>
                {/* <TabPane tab="Salidas" key="2">
                        <CardEmpty noShadow={true}>
                            <div className="text-center">
                                <span className="gris">
                                    No hay salidas de recepciones
                                </span>
                            </div>
                        </CardEmpty>
                    </TabPane> */}
            </Tabs>
        </Fragment>
    );
};

export default CanalList;
