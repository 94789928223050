import React, {Fragment} from 'react';
import { Link } from 'react-router-dom';
import { validate, validators } from 'validate-redux-form';
import { Field, reduxForm, formValueSelector, } from 'redux-form';
import {renderField, renderSwitch, SelectField} from '../../../Utils/renderField/renderField';
import {tipo_bd} from '../../../../../utility/constants';

let PerfilFinca = props => {
    const { handleSubmit,produccionInterna } = props;
    return(
        <form onSubmit={handleSubmit}>
            <div className="form-group grid-container d-flex align-items-center flex-column">
                <div className="row  col-md-12 p-0">
                    <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="nombre" className="m-0">NOMBRE DE PERFIL</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="nombre" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="tipo_db" className="m-0">TIPO BASE DE DATOS</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="tipo_db" component={SelectField} options={tipo_bd} className="form-control" />
                        </div>
                    </div>
                </div>
                <div className="row  col-md-12 p-0">
                    <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="numero_concepto_inventario_sae" className="m-0">NÚMERO DE CONCEPTO</label>
                        </div>
                        <div className="col-md-12  form-group">
                            <Field name="numero_concepto_inventario_sae" component={renderField} type="text" className="form-control" />
                        </div>
                    </div>
                   { 
                    !produccionInterna &&(
                        <div className="col-12 col-md-6 p-0">
                        <div className="col-md-12 col-sm-12">
                            <label htmlFor="produccion_interna" className="m-0">PRODUCCIÓN INTERNA</label>
                        </div>
                        <div className="col-md-6 d-flex justify-content-center">
                            <Field name="produccion_interna" component={renderSwitch} />
                        </div>
                    </div>
                    )
                   }
                </div>
                <div className="buttons-box mt-5">
                    <Link className="btn btn-outline-dark mr-5" to="/perfiles">CANCELAR</Link>
                    <button type="submit" className="btn btn-primary">GUARDAR</button>
                </div>
            </div>
        </form>
    )
};
PerfilFinca = reduxForm({
    form: 'PerfilForm',
    validate: data => {
        return validate(data, {
            'nombre': validators.exists()('Campo requerido.'),
            'numero_concepto_inventario_sae': validators.exists()('Campo requerido.'),
        })
    }
})(PerfilFinca);

export default PerfilFinca
