import React, {Component} from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../FiltrosHeader';
import TabBar from 'rc-tabs/lib/TabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import Tabs, {TabPane} from 'rc-tabs';
import Reporte from './Reporte';
import CardEmpty from '../../../Utils/Cards/CardEmpty';

export default class ReporteLiquidaciones extends Component {
    componentWillMount() {
        const { getLiquidacionesParaReporte } = this.props;
        // getLiquidacionesParaReporte();
    }

    render() {
        const {
            reporte,
            pageReporteChange, fechaInicialReporteChange, fechaFinalReporteChange, fincaReporteChange,
        } = this.props;
        let page = reporte.page;
        let fecha_inicial = reporte.fecha_inicial;
        let fecha_final = reporte.fecha_final;
        let finca_id = reporte.finca_id;
        let pageChange = pageReporteChange;
        let fechaInicialChange = fechaInicialReporteChange;
        let fechaFinalChange = fechaFinalReporteChange;
        let fincaChange = fincaReporteChange;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="LIQUIDACIONES"
                    noBorder
                    subtitulo="Reporte">
                    <CardEmpty>
                        <FiltrosHeader {...this.props}
                            fechaInicialChange={fechaInicialChange} fecha_inicial={fecha_inicial} fechaFinalChange={fechaFinalChange} fecha_final={fecha_final}
                            filtroFincaChange={fincaChange}
                            finca_id={finca_id}
                            filtrarXReporte={()=>{this.props.getLiquidacionesParaReporte()}}
                            labelXReporte={"Filtrar"}
                        />
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <Reporte {...this.props} pageChange={pageChange} page={page}></Reporte>
                </Card>
            </div>
        )
    }
}
