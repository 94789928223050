import React, { Component } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import "react-step-progress-bar/styles.css";
import {TableHeaderColumn} from "react-bootstrap-table";
import Grid from "Utils/Grid";
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import ReajusteForm from "./ReajusteForm";
import Modal from "react-responsive-modal";
import InfoPila from "./InfoPila";

function formatoPorcentaje(cell, row){
    return <div >
        {/*<div className="CircularProgressbarContainer-sm">*/}
        {/*    <div className="circular-extra-text">{`${cell}%`}</div>*/}
        {/*    <div style={{position: 'absolute'}}>*/}
        {/*        <CircularProgressbar*/}
        {/*            value={cell}*/}
        {/*            strokeWidth={12}*/}
        {/*            styles={buildStyles({*/}
        {/*                pathColor: "#035192",*/}
        {/*                textColor: "#035192",*/}
        {/*            })}*/}
        {/*        />*/}
        {/*    </div>*/}
        {/*</div>*/}
    </div>
}

export default class FincaVer extends Component {
    state = {
        editar: false,
        step: 1,
        open: false,
    };
    componentWillMount(){
        // this.props.leer(this.props.match.params.id);
        this.props.getProductosAlmacen(this.props.match.params.id);
    }
    openModal =(activar) => {
        this.setState({ open: true})
    };
    closeModal = () => {
        this.setState({open: false})
    };

    render() {
        const { crear, item, loader, reajustar } = this.props;
        const data = {
            count: 2,
            results : [
                {id:1, ingresado: 40, cantidad:"60 Kl", finca:'Finca 01', materia:'Chipa', fecha: "21/09/2019"},
                {id:2, ingresado: 60, cantidad: "80 Kl", finca: "Finca 02", materia: "Chipa", fecha: "23/09/2019"}
            ]
        };
        return (
            <div className="mb-4 col-12">
                <Modal open={this.state.open} onClose={this.closeModal}>
                    <div className="pb-4 titulo-modal d-flex justify-content-center">
                        <h2>REAJUSTE</h2>
                    </div>
                    <ReajusteForm onSubmit={reajustar} closeModal={this.closeModal} />
                </Modal>
                <CardForm
                    icon="bodega"
                    titulo={item.nombre}
                    col="12"
                    subtitulo="Inventario">
                    <LoadMask loading={loader} blur>
                        <div className=" row col-12 d-flex flex-column flex-sm-row justify-content-between">
                            <div className="mb-1 mb-md-4 d-flex flex-wrap">
                                <button className="btn btn-outline-primary mb-2 mb-md-0 mr-md-4">CREAR SALIDA</button>
                                <button className="btn btn-outline-secondary" onClick={this.openModal}>REAJUSTE</button>
                            </div>
                            {/*Esto es solo de Ejemplo*/}
                            <div className="d-flex align-items-baseline">
                                <img src={require('assets/img/icons/linea.png')} alt="Linea" className="title_img mr-2"/>
                                <h2>{item.tipo > 10 ? 'Latex': 'Chipa'}</h2>
                            </div>
                        </div>
                        {item.tipo > 10 &&(
                            <InfoPila />
                        )}
                        <Grid hover striped data={data} loading={loader} page={1}>
                            <TableHeaderColumn
                                dataField="ingresado"
                                dataAlign="center"
                                dataFormat={formatoPorcentaje}
                            >
                                PORCENTAJE
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                isKey
                                dataField="cantidad"
                                dataSort
                            >
                                CANTIDAD
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                dataField="finca"
                                dataSort
                            >
                                FINCA
                            </TableHeaderColumn>
                            {item.tipo === 10 &&(<TableHeaderColumn
                                dataField="materia"
                                dataSort
                            >
                                MATERIA PRIMA
                            </TableHeaderColumn>)}
                            <TableHeaderColumn
                                dataField="fecha"
                                dataSort
                            >
                                FECHA DE INGRESO
                            </TableHeaderColumn>
                        </Grid>
                    </LoadMask>
                </CardForm>
            </div>
        )
    }
}
