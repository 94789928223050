import React, { Component } from "react";
import Grid from "../../../Utils/Grid";
import { TableHeaderColumn } from "react-bootstrap-table";
import { standardActions } from "../../../Utils/Grid/StandardActions";
import Link from "react-router-dom/es/Link";
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";

export default class BancosList extends Component {
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { listar: onPageChange, onSortChange, eliminar } = this.props;
        const { data, loader, page } = this.props;
        return (
            <div className="mb-4 col-12">
                <Card
                    icon="configuraciones_activo"
                    titulo="EMAILS"
                    // subtitulo="Del sistema"
                >
                    <Header
                        to="notificaciones/crear"
                        textBtn="CREAR EMAIL"
                        // {...this.props}
                    />
                    <Grid
                        hover
                        striped
                        data={data}
                        loading={loader}
                        onPageChange={onPageChange}
                        // onSortChange={onSortChange}
                        page={page}
                    >
                        <TableHeaderColumn
                            dataField="id"
                            dataAlign="left"
                            width="20%"
                            dataSort
                            dataFormat={standardActions({
                                editar: "notificaciones",
                                eliminar,
                            })}
                        />
                        <TableHeaderColumn
                            isKey
                            width="40%"
                            dataField="email"
                            dataSort
                        >
                            EMAIL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="left"
                            dataField="tipoUso"
                            width="35%"
                            dataSort
                            dataFormat={(cell, row) => {
                                return cell === 10 ? "RECEPCIONES FINCA ERSA" : "---";
                            }}
                        >
                            TIPO DE USO
                        </TableHeaderColumn>
                        <TableHeaderColumn width="50%" />
                    </Grid>
                </Card>
            </div>
        );
    }
}
