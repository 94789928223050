import React, { Component } from 'react';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import Card from '../../../Utils/Cards/Card';
import DespachoForm from './DespachoForm';

class DespachoCrear extends Component {
    state = {
        ver: false,
        peso_manual: true
    }
    
    componentDidMount() {
        const me = this.props.me;
        if (this.props.match.params.id != 'crear') {
            this.props.leer(this.props.match.params.id);
            this.setState({
                ver: true,
            })
        }
        else { 
            this.props.clearItem();
        }

        if (me) {
            if (me.acopio) {
                if (me.peso_ingreso_manual_acopio === false) {
                    this.interval = setInterval(() => this.props.pesosBascula(), 500)
                    this.setState({peso_manual: false});
                }
            } else {
                if (me.peso_ingreso_manual === false) {
                    this.interval = setInterval(() => this.props.pesosBascula(), 500)
                    this.setState({peso_manual: false});
                }
            }
        }
    }
    
    actualizar = data => {
        this.props.editar(this.props.match.params.id, data);
    }

    finalizar = () => {
        this.props.finalizar(this.props.item);
    }

    render() {
        const { crear } = this.props;
        const { loader } = this.props;
        return (
            <div className="mb-4 col-12">
                <Card
                    icon="despachos_activo"
                    titulo="DESPACHOS"
                    subtitulo=""
                    noBorder
                >
                    <LoadMask
                        loading={loader}
                        blur
                    >
                        <section className="d-flex justify-content-center mt-4">
                            <div
                                className="card p-3"
                                style={{width: '100%'}}
                            >
                            {
                                this.state.ver ?
                                    <DespachoForm
                                        onSubmit={() => {}}
                                        disabled={true}
                                        viendo={true}
                                        creando={false}
                                        finalizar={this.finalizar}
                                        peso_manual={this.state.peso_manual}
                                        {...this.props}
                                    />
                                :
                                    <DespachoForm
                                        onSubmit={crear}
                                        disabled={false}
                                        creando={true}
                                        viendo={false}
                                        finalizar={this.finalizar}
                                        peso_manual={this.state.peso_manual}
                                        {...this.props}
                                    />
                            }
                            </div>
                        </section>
                    </LoadMask>
                </Card>
            </div>
        )
    }
};

export default DespachoCrear;