import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/laboratorio';
import ReporteLaboratorio from './ReporteLaboratorio';


const ms2p = (state) => {
  return {
    ...state.laboratorio,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ReporteLaboratorio);
