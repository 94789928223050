import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/recepciones';
import IngresoVer from './IngresoVer';

const mstp = state => {
    return {
        ...state.recepciones,
        me: state.login.me,
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(IngresoVer)
