import _ from "lodash";
import Swal from 'sweetalert2';

const validador = (values, props) => {
    const errors = {};

    if (Number(values.peso_final_origen) < 0) {
        errors.peso_final_origen = "Peso final no válido, por favor revise la cantidad a transferir";
    }

    const RecipientesArrayErrors = [];
    if (!values.recipientes || values.recipientes.length <= 0) {
        errors.errorProd = 'Se debe de agregar por lo menos un recipiente.'
    } else {
        values.recipientes.forEach((recipiente, recipienteIndex) => {
            const recipienteErrors = {};
            if (recipiente.id === undefined) {
                recipienteErrors.id = 'Campo requerido';
                RecipientesArrayErrors[recipienteIndex] = recipienteErrors
            }
            if (recipiente.id_materia === undefined) {
                recipienteErrors.id_materia = 'Campo requerido';
                RecipientesArrayErrors[recipienteIndex] = recipienteErrors
            }
            if (recipiente.a_transferir === undefined) {
                recipienteErrors.a_transferir = 'Campo requerido';
                RecipientesArrayErrors[recipienteIndex] = recipienteErrors
            }
            if (Number(recipiente.a_transferir) > recipiente.peso_inicial) {
                recipienteErrors.a_transferir = "Excede el peso actual existente";
                RecipientesArrayErrors[recipienteIndex] = recipienteErrors
            }
        });
        if (RecipientesArrayErrors.length) {
            errors.recipientes = RecipientesArrayErrors
        }
    }

    return errors;
};

export default validador
