import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/usuarios/usuarios';
import {getMe} from "../../../../../redux/modules/cuenta/login";
import UsuarioCrear from './UsuarioCrear';

const mstp = state => {
    return {...state.usuarios}
};

const mdtp = {
    ...actions, getMe
};

export default connect(mstp, mdtp)(UsuarioCrear)
