import React, {Component} from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../FiltrosHeader';
import TabBar from 'rc-tabs/lib/TabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import Tabs, {TabPane} from 'rc-tabs';
import Resumen from './Resumen';
import Detalle from './Detalle';
import CardEmpty from '../../../Utils/Cards/CardEmpty';
import { actions as printActions } from 'Utils/Print';

export default class ReporteKgSecosAnual extends Component {
    /* componentWillMount() {
        const { dataReporteKgSecosAnual } = this.props;
        dataReporteKgSecosAnual();
    } */

    imprimirBoleta = (event) => {
        printActions.print('reporte-kg-secos-anuales');
    }

    render() {
        const {
            filtroReporteKgSecosAnualProductos,
            filtroReporteKgSecosAnualPerfilFinca,
            filtroReporteKgSecosAnualAnio,
            filtro_anio_reporte_kg_secos,
            filtro_perfil_finca_reporte_kg_secos,
            filtro_productos_reporte_kg_secos,
            descargarReporteKgSecosAnual,
            descargarDetalleReporteKgSecosAnual,
            setTabReporteKgSecosAnual,
            pageReporteKgSecosAnualChange,
            tab_reporte_kg_secos,
            page_reporte_kg_secos,
            dataReporteKgSecosAnual,
        } = this.props;
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="KG. SECOS ANUAL"
                    noBorder
                    subtitulo="Reporte">
                    <Tabs
                        activeKey={tab_reporte_kg_secos}
                        tabBarPosition="top"
                        onChange={tab_reporte =>{setTabReporteKgSecosAnual(tab_reporte)}}
                        renderTabBar={() => <TabBar />}
                        renderTabContent={() => <TabContent />}
                    >
                        <TabPane tab="DETALLE" key="DETALLE">
                            <CardEmpty>
                                <FiltrosHeader {...this.props}
                                    filtroProductosChange={filtroReporteKgSecosAnualProductos} productos_ids={filtro_productos_reporte_kg_secos}
                                    filtroPerfilChange={filtroReporteKgSecosAnualPerfilFinca} perfiles_finca_ids={filtro_perfil_finca_reporte_kg_secos}
                                    filtroAnioChange={filtroReporteKgSecosAnualAnio} anio_reporte={filtro_anio_reporte_kg_secos}
                                    botonExportar={descargarDetalleReporteKgSecosAnual}
                                    filtrar={dataReporteKgSecosAnual}
                                />
                            </CardEmpty>
                            <div className="mb-4"></div>
                            <Detalle {...this.props} pageChange={pageReporteKgSecosAnualChange} page={page_reporte_kg_secos}></Detalle>
                        </TabPane>
                        <TabPane tab="RESUMEN" key="RESUMEN">
                            <CardEmpty>
                                <FiltrosHeader {...this.props}
                                    filtroProductosChange={filtroReporteKgSecosAnualProductos} productos_ids={filtro_productos_reporte_kg_secos}
                                    filtroPerfilChange={filtroReporteKgSecosAnualPerfilFinca} perfiles_finca_ids={filtro_perfil_finca_reporte_kg_secos}
                                    filtroAnioChange={filtroReporteKgSecosAnualAnio} anio_reporte={filtro_anio_reporte_kg_secos}
                                    botonImprimir={this.imprimirBoleta} botonExportar={descargarReporteKgSecosAnual}
                                    filtrar={dataReporteKgSecosAnual}
                                />
                            </CardEmpty>
                            <div className="mb-4"></div>
                            <Resumen {...this.props}></Resumen>
                        </TabPane>
                    </Tabs>
                </Card>
            </div>
        )
    }
}
