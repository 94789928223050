import React, {Component} from 'react'
import Grid from "../../../Utils/Grid";
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from "Utils/Grid/StandardActions";
import Card from "Utils/Cards/Card";
import Header from "Utils/Grid/Header";

export default class PropietariosList extends Component{
    componentWillMount() {
        const { listar, page } = this.props;
        listar(page);
    }

    render() {
        const { data, page, loader} = this.props; //Constantes
        const { listar: onPageChange, onSortChange, eliminar } = this.props; //Funciones
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="configuraciones_activo"
                    titulo="PROPIETARIOS"
                    subtitulo="Finca">
                    <Header to="propietario/crear" textBtn="CREAR PROPIETARIO" {...this.props} />
                    <Grid hover striped data={data} loading={loader} onPageChange={onPageChange} onSortChange={onSortChange}
                            page={page}>
                        <TableHeaderColumn
                            isKey
                            dataField="id"
                            dataAlign="center"
                            dataSort
                            width='20%'
                            dataFormat={standardActions({ editar: "propietario", eliminar})}
                        />
                        <TableHeaderColumn
                            dataField="nombre"
                            dataSort
                        >
                            NOMBRE
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="telefono"
                            dataSort
                        >
                            TELÉFONO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="genero_display"
                            dataSort
                        >
                            GÉNERO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="email"
                            dataSort
                        >
                            E-MAIL
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataAlign="center"
                            dataField="id"
                            width='8%'
                            dataSort
                        >
                            CÓDIGO
                        </TableHeaderColumn>
                    </Grid>
                </Card>
            </div>
        )
    }
}
