import React, {Component} from 'react';
import Card from 'Utils/Cards/Card';
import FiltrosHeader from '../FiltrosHeader';
import TabBar from 'rc-tabs/lib/TabBar';
import TabContent from 'rc-tabs/lib/TabContent';
import Tabs, {TabPane} from 'rc-tabs';
import Reporte from './Reporte';
import CardEmpty from '../../../Utils/Cards/CardEmpty';

export default class ReporteVersusDRC extends Component {
    componentWillMount() {
        const { getReporteVsDRC } = this.props;
        // getReporteVsDRC();
    }

    render() {
        const {
            reporte_vs_drc: reporte,
            drcVsSolidoPageChange, drcVsSolidoFechaInicialChange, drcVsSolidoFechaFinalChange, drcVsSolidoFincaChange, drcVsSolidoMateriaChange,
        } = this.props;
        let fechaInicialChange = null, fechaFinalChange = null, fincaChange = null, materiaChange = null, pageChange = null;
        let fecha_inicial = null, fecha_final = null, finca_id = null, materia_id = null, page = null, materias = [];
        switch(reporte.tipo_materia) {
            case 'SOLIDO':
                pageChange = drcVsSolidoPageChange;
                fechaInicialChange = drcVsSolidoFechaInicialChange;
                fechaFinalChange = drcVsSolidoFechaFinalChange;
                fincaChange = drcVsSolidoFincaChange;
                materiaChange = drcVsSolidoMateriaChange;
                page = reporte.solido_page;
                fecha_inicial = reporte.solido_fecha_inicial;
                fecha_final = reporte.solido_fecha_final;
                finca_id = reporte.solido_finca_id;
                materia_id = reporte.solido_materia_id;
                materias = reporte.materias_solido;
                break;
        }
        return(
            <div className="mb-4 col-12">
                <Card
                    icon="reportes_activo"
                    titulo="DRC Compra VS DRC Lab"
                    noBorder
                    subtitulo="REPORTE">
                    <CardEmpty>
                        <FiltrosHeader {...this.props}
                            fechaInicialChange={fechaInicialChange} fecha_inicial={fecha_inicial} fechaFinalChange={fechaFinalChange} fecha_final={fecha_final}
                            filtroFincaChange={fincaChange} finca_id={finca_id}
                            filtroMateriaChange={materiaChange} materia_id={materia_id} tipo_materia={reporte.tipo_materia}

                            filtrarXReporte={()=>{this.props.getReporteVsDRC()}}
                            labelXReporte={"Filtrar"}
                        />
                    </CardEmpty>
                    <div className="mb-4"></div>
                    <Reporte {...this.props} pageChange={pageChange} page={page}></Reporte>
                </Card>
            </div>
        )
    }
}
