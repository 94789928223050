import {handleActions} from 'redux-actions';
import {api} from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import { KG_SECOS_REPORTES, DRC_PROMEDIO_REPORTE, meses } from '../../../utility/constants';
import moment from 'moment';
import { actions as pilaAlmacenActions, reducers as pilaAlmacenReducers, initialState as pilaAlmacenInitialState } from './pila_almacen';

const LOADER = 'LOADER_REPORTES';
const DATA = 'DATA_REPORTES';
const ITEM_DATA = 'ITEM_REPORTES';
const PAGE = 'PAGE_REPORTES';
const PAGE_ACTIVAS = 'PAGE_REPORTES_ACTIVAS';
const PAGE_FINALIZADAS = 'PAGE_REPORTES_FINALIZADAS';
const ORDERING = 'ORDERING_REPORTES';
const SEARCH = 'SEARCH_REPORTES';

// REPORTE DE KG SECOS ANUALES
const REPORTE_KG_SECOS_FILTRO_PRODUCTOS = 'REPORTE_KG_SECOS_FILTRO_PRODUCTOS';
const REPORTE_KG_SECOS_FILTRO_PERFIL_FINCA = 'REPORTE_KG_SECOS_FILTRO_PERFIL_FINCA';
const REPORTE_KG_SECOS_FILTRO_ANIO = 'REPORTE_KG_SECOS_FILTRO_ANIO';
const TAB_REPORTE_KG_SECOS = 'TAB_REPORTE_KG_SECOS';
const PAGE_REPORTE_KG_SECOS = 'PAGE_REPORTE_KG_SECOS';
// REPORTE DRC ANUAL
const REPORTE_DRC_FILTRO_PRODUCTOS = 'REPORTE_DRC_FILTRO_PRODUCTOS';
const REPORTE_DRC_FILTRO_PERFIL_FINCA = 'REPORTE_DRC_FILTRO_PERFIL_FINCA';
const REPORTE_DRC_FILTRO_ANIO = 'REPORTE_DRC_FILTRO_ANIO';
// REPORTE LIQUIDACIÓN SEMANAL
const REPORTE_LIQ_SEMANAL_FILTRO_FINCAS = 'REPORTE_LIQ_SEMANAL_FILTRO_FINCAS';
const REPORTE_LIQ_SEMANAL_FILTRO_SEMANA = 'REPORTE_LIQ_SEMANAL_FILTRO_SEMANA';
const REPORTE_LIQ_SEMANAL_FILTRO_ANIO = 'REPORTE_LIQ_SEMANAL_FILTRO_ANIO';
const TAB_REPORTE_LIQ_SEMANAL = 'TAB_REPORTE_LIQ_SEMANAL';
const PAGE_REPORTE_LIQ_SEMANAL = 'PAGE_REPORTE_LIQ_SEMANAL';
// Reporte de Materia Prima Anual
const REPORTE_MP_ANUAL_FILTRO_TIPO_MATERIA = 'REPORTE_MATERIA_PRIMA_ANUAL_FILTRO_TIPO_MATERIA';
const REPORTE_MP_ANUAL_FILTRO_ANIO         = 'REPORTE_MATERIA_PRIMA_ANUAL_FILTRO_ANIO';
const REPORTE_MP_ANUAL_DATA                = 'REPORTE_MATERIA_PRIMA_ANUAL_DATA';
const TAB_REPORTE_MP_ANUAL                 = 'TAB_REPORTE_MP_ANUAL';
const PAGE_REPORTE_MP_ANUAL                = 'PAGE_REPORTE_MP_ANUAL';
// Reporte de Materia Prima Mensual
const REPORTE_MP_MENSUAL_FILTRO_MES_SELECTED  = 'REPORTE_MATERIA_PRIMA_MENSUAL_FILTRO_MES_SELECTED';
const REPORTE_MP_MENSUAL_FILTRO_FECHA_INICIAL = 'REPORTE_MATERIA_PRIMA_MENSUAL_FILTRO_FECHA_INICIAL';
const REPORTE_MP_MENSUAL_FILTRO_FECHA_FINAL   = 'REPORTE_MATERIA_PRIMA_MENSUAL_FILTRO_FECHA_FINAL';
const REPORTE_MP_MENSUAL_FILTRO_ANIO_SELECTED = 'REPORTE_MATERIA_PRIMA_MENSUAL_FILTRO_ANIO_SELECTED';
const REPORTE_MP_MENSUAL_DATA                 = 'REPORTE_MATERIA_PRIMA_MENSUAL_DATA';
const TAB_REPORTE_MP_MENSUAL                  = 'TAB_REPORTE_MP_MENSUAL';
const PAGE_REPORTE_MP_MENSUAL                 = 'PAGE_REPORTE_MP_MENSUAL';
// BITACORA
const BITACORA_FILTRO_FECHA_INICIAL = 'BITACORA_FILTRO_FECHA_INICIAL';
const BITACORA_FILTRO_FECHA_FINAL   = 'BITACORA_FILTRO_FECHA_FINAL';
const BITACORA_FILTRO_USUARIO       = 'BITACORA_FILTRO_USUARIO';
const PAGE_BITACORA                 = 'PAGE_BITACORA';
const BITACORA_ACCION               = 'BITACORA_ACCION';


// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setPageActivas = page_activas => ({
    type: PAGE_ACTIVAS,
    page_activas,
});

const setPageFinalizadas = page_finalizadas => ({
    type: PAGE_FINALIZADAS,
    page_finalizadas,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------

// REPORTE KG SECOS ANUALES.
const dataReporteKgSecosAnual = () => async (dispatch, getStore) => {
    const resource = getStore().reportes;
    const params_detalle = {};
    const params_resumen = {};

    params_detalle.anio = resource.filtro_anio_reporte_kg_secos.value;
    params_detalle.page = resource.page_reporte_kg_secos;

    params_resumen.anio = resource.filtro_anio_reporte_kg_secos.value;
    if (resource.filtro_perfil_finca_reporte_kg_secos.length > 0) {
        params_detalle.perfiles_finca = [resource.filtro_perfil_finca_reporte_kg_secos];
        params_resumen.perfiles_finca = [resource.filtro_perfil_finca_reporte_kg_secos];
    }
    if (resource.filtro_productos_reporte_kg_secos.length > 0) {
        params_detalle.productos = [resource.filtro_productos_reporte_kg_secos];
        params_resumen.productos = [resource.filtro_productos_reporte_kg_secos];
    }
    if (params_resumen.anio) {
        dispatch(setLoader(true));
        try {
            const detalles_anual = await api.get('recepcion/get_detalle_ingresos_anuales', params_detalle);
            const consolidado_anual = await api.get('recepcion/get_data_reporte_kg_secos_anual', params_resumen);
            dispatch(setItem(detalles_anual));
            dispatch(setData(consolidado_anual));
        } catch(error) {
        } finally {
            dispatch(setLoader(false));
        }
    }
};
export const descargarReporteKgSecosAnual = () => (dispatch, getStore) => {
    const resource = getStore().reportes;
    let url_descarga = `/api/recepcion/descargar_reporte_anual?auth_token=${localStorage.getItem("token")}&tipo=${KG_SECOS_REPORTES}`;

    if (resource.filtro_anio_reporte_kg_secos.value) {
        url_descarga += `&anio=${resource.filtro_anio_reporte_kg_secos.value}`;
    }
    if (resource.filtro_perfil_finca_reporte_kg_secos.length > 0) {
        url_descarga += `&perfiles_finca=${[resource.filtro_perfil_finca_reporte_kg_secos]}`;
    }
    if (resource.filtro_productos_reporte_kg_secos.length > 0) {
        url_descarga += `&productos=${[resource.filtro_productos_reporte_kg_secos]}`;
    }
    window.location.replace(url_descarga);
};

export const descargarDetalleReporteKgSecosAnual = () => (dispatch, getStore) => {
    const resource = getStore().reportes;
    let url_descarga = `/api/recepcion/descargar_detalle_ingresos_anuales?auth_token=${localStorage.getItem("token")}`;

    if (resource.filtro_anio_reporte_kg_secos.value) {
        url_descarga += `&anio=${resource.filtro_anio_reporte_kg_secos.value}`;
    }
    if (resource.filtro_perfil_finca_reporte_kg_secos.length > 0) {
        url_descarga += `&perfiles_finca=${[resource.filtro_perfil_finca_reporte_kg_secos]}`;
    }
    if (resource.filtro_productos_reporte_kg_secos.length > 0) {
        url_descarga += `&productos=${[resource.filtro_productos_reporte_kg_secos]}`;
    }
    window.location.replace(url_descarga);
};

const filtroReporteKgSecosAnualProductos = filtro_productos_reporte_kg_secos => (dispatch) => {
    dispatch({type: REPORTE_KG_SECOS_FILTRO_PRODUCTOS, filtro_productos_reporte_kg_secos});
};

const filtroReporteKgSecosAnualPerfilFinca = filtro_perfil_finca_reporte_kg_secos => (dispatch) => {
    dispatch({type: REPORTE_KG_SECOS_FILTRO_PERFIL_FINCA, filtro_perfil_finca_reporte_kg_secos});
};
const filtroReporteKgSecosAnualAnio = filtro_anio_reporte_kg_secos => (dispatch) => {
    dispatch({type: REPORTE_KG_SECOS_FILTRO_ANIO, filtro_anio_reporte_kg_secos});

};

const setTabReporteKgSecosAnual = (tab_reporte_kg_secos) => (dispatch) => {
    dispatch({type: TAB_REPORTE_KG_SECOS, tab_reporte_kg_secos});
};

const pageReporteKgSecosAnualChange = (page_reporte_kg_secos) => (dispatch) => {
    dispatch({type: PAGE_REPORTE_KG_SECOS, page_reporte_kg_secos})
    dispatch(dataReporteKgSecosAnual());
};

// REPORTE DRC ANUAL
const dataReporteDRCAnual = () => (dispatch, getStore) => {
    const resource = getStore().reportes;
    const params = {};

    params.anio = resource.filtro_anio_reporte_drc.value;
    if (resource.filtro_perfil_finca_reporte_drc.length > 0) {
        params.perfiles_finca = [resource.filtro_perfil_finca_reporte_drc];
    }
    if (resource.filtro_productos_reporte_drc.length > 0) {
        params.productos = [resource.filtro_productos_reporte_drc];
    }
    if (params.anio) {
        dispatch(setLoader(true));
        api.get('recepcion/get_data_reporte_kg_secos_anual', params).then((response) => {
            dispatch(setData(response));
        }).catch(() => {
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
};
export const descargarReporteDRCAnual = () => (dispatch, getStore) => {
    const resource = getStore().reportes;
    let url_descarga = `/api/recepcion/descargar_reporte_anual?auth_token=${localStorage.getItem("token")}&tipo=${DRC_PROMEDIO_REPORTE}`;

    if (resource.filtro_anio_reporte_drc.value) {
        url_descarga += `&anio=${resource.filtro_anio_reporte_drc.value}`;
    }
    if (resource.filtro_perfil_finca_reporte_drc.length > 0) {
        url_descarga += `&perfiles_finca=${[resource.filtro_perfil_finca_reporte_drc]}`;
    }
    if (resource.filtro_productos_reporte_drc.length > 0) {
        url_descarga += `&productos=${[resource.filtro_productos_reporte_drc]}`;
    }
    window.location.replace(url_descarga);
};

const filtroReporteDRCAnualProductos = filtro_productos_reporte_drc => (dispatch) => {
    dispatch({type: REPORTE_DRC_FILTRO_PRODUCTOS, filtro_productos_reporte_drc});
};

const filtroReporteDRCAnualPerfilFinca = filtro_perfil_finca_reporte_drc => (dispatch) => {
    dispatch({type: REPORTE_DRC_FILTRO_PERFIL_FINCA, filtro_perfil_finca_reporte_drc});
};
const filtroReporteDRCAnualAnio = filtro_anio_reporte_drc => (dispatch) => {
    dispatch({type: REPORTE_DRC_FILTRO_ANIO, filtro_anio_reporte_drc});
};

// Funciones para el Reporte de Materia Prima anual
const mpAnualTipoMateriaChange = tipo_materia => (dispatch) => {
    dispatch({type: REPORTE_MP_ANUAL_FILTRO_TIPO_MATERIA, tipo_materia});
    dispatch(getDataReporteMateriaPrimaAnual());
};
const mpAnualAnioChange = anio => (dispatch) => {
    dispatch({type: REPORTE_MP_ANUAL_FILTRO_ANIO, anio});
    dispatch(getDataReporteMateriaPrimaAnual());
};
const getDataReporteMateriaPrimaAnual = () => (dispatch, getStore) => {
    const resource = getStore().reportes.reporte_mp_anual;
    const params = {};
    params.anio = resource.anio.value;
    resource.tipo_materia !== null && (params.tipo_materia = resource.tipo_materia.value);
    dispatch(setLoader(true));
    api.get('recepcion/get_data_materia_prima_anual', params).then((response) => {
        dispatch({type: REPORTE_MP_ANUAL_DATA, data: response});
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const setTabReporteMateriaPrimaAnual = (tab) => (dispatch) => {
    dispatch({type: TAB_REPORTE_MP_ANUAL, tab});
};

const pageReporteMateriaPrimaAnual = (page) => (dispatch) => {
    dispatch({type: PAGE_REPORTE_MP_ANUAL, page})
    dispatch(getDataReporteMateriaPrimaAnual());
}

export const descargarReporteMateriaPrimaAnual = () => (dispatch, getStore) => {
    const resource = getStore().reportes.reporte_mp_anual;
    let url_descarga = `/api/recepcion/descargar_reporte_materia_prima_anual?auth_token=${localStorage.getItem("token")}`;

    if (resource.anio.value) {
        url_descarga += `&anio=${resource.anio.value}`;
    }
    if (resource.tipo_materia) {
        if (resource.tipo_materia.value) {
            url_descarga += `&tipo_materia=${resource.tipo_materia.value}`;
        }
    }
    window.location.replace(url_descarga);
};

// Funciones para el Reporte de Materia Prima mensual
const mpMensualAnioChange = anio => (dispatch, getStore) => {
    dispatch({type: REPORTE_MP_MENSUAL_FILTRO_ANIO_SELECTED, anio});
    const dataStore = getStore().reportes.reporte_mp_mensual;
    let fecha_inicial = moment([anio.value]).month(dataStore.mes.value);
    let fecha_final = moment([anio.value, 0, 31]).month(dataStore.mes.value);
    dispatch({type: REPORTE_MP_MENSUAL_FILTRO_FECHA_INICIAL, fecha_inicial});
    dispatch({type: REPORTE_MP_MENSUAL_FILTRO_FECHA_FINAL, fecha_final});
    // dispatch(getDataReporteMateriaPrimaMensual());
};
const mpMensualMesChange = mes => (dispatch, getStore) => {
    dispatch({type: REPORTE_MP_MENSUAL_FILTRO_MES_SELECTED, mes});
    const dataStore = getStore().reportes.reporte_mp_mensual;
    let fecha_inicial = moment([dataStore.anio.value]).month(mes.value - 1);
    let fecha_final = moment([dataStore.anio.value, 0, 31]).month(mes.value - 1);
    dispatch({type: REPORTE_MP_MENSUAL_FILTRO_FECHA_INICIAL, fecha_inicial});
    dispatch({type: REPORTE_MP_MENSUAL_FILTRO_FECHA_FINAL, fecha_final});
    // dispatch(getDataReporteMateriaPrimaMensual());
};
const getDataReporteMateriaPrimaMensual = () => async (dispatch, getStore) => {
    const resource = getStore().reportes.reporte_mp_mensual;
    const params_resumen = {};
    const params_detalle = {};
    params_detalle.fecha_inicial = moment(resource.fecha_inicial).format("YYYY-MM-DD");
    params_detalle.fecha_final   = moment(resource.fecha_final).format("YYYY-MM-DD");
    params_detalle.page = resource.page;

    params_resumen.fecha_inicial = moment(resource.fecha_inicial).format("YYYY-MM-DD") + ' 00:00:00';
    params_resumen.fecha_final   = moment(resource.fecha_final).format("YYYY-MM-DD") + ' 23:59:59';
    if (params_resumen.fecha_inicial && params_resumen.fecha_final) {
        dispatch(setLoader(true));
        try {
            const detalles_mp_mensual= await api.get('recepcion/get_detalle_materia_prima_mensual', params_detalle);
            const consolidado_mp_mensual= await api.get('recepcion/get_data_materia_prima_mensual', params_resumen);
            dispatch(setItem(detalles_mp_mensual));
            dispatch({type: REPORTE_MP_MENSUAL_DATA, data: consolidado_mp_mensual});
        } catch(error) {
        } finally {
            dispatch(setLoader(false));
        }
    }
};
export const descargarReporteMateriaPrimaMensual = () => (dispatch, getStore) => {
    const resource = getStore().reportes.reporte_mp_mensual;
    let url_descarga = `/api/recepcion/descargar_reporte_materia_prima_mensual?auth_token=${localStorage.getItem("token")}`;

    if (resource.fecha_inicial && resource.fecha_final) {
        let fecha_inicial = moment(resource.fecha_inicial).format("YYYY-MM-DD") + ' 00:00:00';
        let fecha_final = moment(resource.fecha_final).format("YYYY-MM-DD") + ' 23:59:59';
        url_descarga += `&fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`;
        if (resource.anio.value) {
            url_descarga += `&anio=${resource.anio.value}`;
        }
        if (resource.mes.label) {
            url_descarga += `&mes=${resource.mes.label}`;
        }
        if (resource.mes[0]) {
            url_descarga += `&mes=${resource.mes[0].label}`;
        }
        window.location.replace(url_descarga);
    }
};

export const descargarDetalleReporteMateriaPrimaMensual = () => (dispatch, getStore) => {
    const resource = getStore().reportes.reporte_mp_mensual;
    let url_descarga = `/api/recepcion/descargar_detalle_reporte_materia_prima_mensual?auth_token=${localStorage.getItem("token")}`;

    if (resource.fecha_inicial && resource.fecha_final) {
        let fecha_inicial = moment(resource.fecha_inicial).format("YYYY-MM-DD");
        let fecha_final = moment(resource.fecha_final).format("YYYY-MM-DD");
        url_descarga += `&fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}`;
        if (resource.anio.value) {
            url_descarga += `&anio=${resource.anio.value}`;
        }
        if (resource.mes.label) {
            url_descarga += `&mes=${resource.mes.label}`;
        }
        if (resource.mes[0]) {
            url_descarga += `&mes=${resource.mes[0].label}`;
        }
        window.location.replace(url_descarga);
    }
};

const setTabReporteMateriaPrimaMensual = (tab) => (dispatch) => {
    dispatch({type: TAB_REPORTE_MP_MENSUAL, tab});
};

const pageReporteMateriaPrimaMensual = (page) => (dispatch) => {
    dispatch({type: PAGE_REPORTE_MP_MENSUAL, page})
    dispatch(getDataReporteMateriaPrimaMensual());
}

// REPORTE LIQUIDACIÓN SEMANAL
const dataReporteLiqSemanal = () => async (dispatch, getStore) => {
    const resource = getStore().reportes;
    const params_detalle = {};
    const params_resumen = {};
    params_detalle.fecha_inicial =  moment().year(resource.filtro_anio_reporte_liq_semanal.value).isoWeekday(7).week(resource.filtro_semanas_reporte_liq_semanal.value-1).format("YYYY-MM-DD");
    params_detalle.fecha_fin = moment().year(resource.filtro_anio_reporte_liq_semanal.value).isoWeekday(6).week(resource.filtro_semanas_reporte_liq_semanal.value).format("YYYY-MM-DD");
    params_detalle.page = resource.page_reporte_liq_semanal;    
    params_resumen.fecha_inicial =moment().year(resource.filtro_anio_reporte_liq_semanal.value).isoWeekday(7).week(resource.filtro_semanas_reporte_liq_semanal.value-1).format("YYYY-MM-DD");
    params_resumen.fecha_fin = moment().year(resource.filtro_anio_reporte_liq_semanal.value).isoWeekday(6).week(resource.filtro_semanas_reporte_liq_semanal.value).format("YYYY-MM-DD");
  
    if (resource.filtro_fincas_reporte_liq_semanal.length > 0) {
        params_detalle.finca_ids = [resource.filtro_fincas_reporte_liq_semanal];
        params_resumen.finca_ids = [resource.filtro_fincas_reporte_liq_semanal];
    }
    if (params_resumen.fecha_inicial && params_resumen.fecha_fin) {
        dispatch(setLoader(true));
        try {
            const detalles_liq = await api.get('liquidacion/get_data_detalle_liq_semanales', params_detalle);
            const consolidado_liq = await api.get('liquidacion/get_data_consolidado_liq_semanales', params_resumen);
            dispatch(setItem(detalles_liq));
            dispatch(setData(consolidado_liq));
        } catch(error) {
        } finally {
            dispatch(setLoader(false));
        }
    }
};
export const descargarReporteLiqSemanal = () => (dispatch, getStore) => {
    const resource = getStore().reportes;
    let url_descarga = `/api/liquidacion/descargar_consolidado_liq_semanales?auth_token=${localStorage.getItem("token")}`;
    if (resource.filtro_anio_reporte_liq_semanal.value && resource.filtro_semanas_reporte_liq_semanal.value) {
        let fecha_inicial =  moment().year(resource.filtro_anio_reporte_liq_semanal.value).isoWeekday(7).week(resource.filtro_semanas_reporte_liq_semanal.value-1).format("YYYY-MM-DD");
        let fecha_fin = moment().year(resource.filtro_anio_reporte_liq_semanal.value).isoWeekday(6).week(resource.filtro_semanas_reporte_liq_semanal.value).format("YYYY-MM-DD");
        let semana = resource.filtro_semanas_reporte_liq_semanal.value;
        let anio = resource.filtro_anio_reporte_liq_semanal.value;
        url_descarga += `&fecha_inicial=${fecha_inicial}&fecha_fin=${fecha_fin}&semana=${semana}&anio=${anio}`;
    }
    if (resource.filtro_fincas_reporte_liq_semanal.length > 0) {
        url_descarga += `&finca_ids=${[resource.filtro_fincas_reporte_liq_semanal]}`;
    }
    window.location.replace(url_descarga);
};
    export const descargarDetalleReporteLiqSemanal = () => (dispatch, getStore) => {
    const resource = getStore().reportes;
    
    let url_descarga = `/api/liquidacion/descargar_detalle_liq_semanales?auth_token=${localStorage.getItem("token")}`;

    if (resource.filtro_anio_reporte_liq_semanal.value && resource.filtro_semanas_reporte_liq_semanal.value) {
        let fecha_inicial =  moment().year(resource.filtro_anio_reporte_liq_semanal.value).isoWeekday(7).week(resource.filtro_semanas_reporte_liq_semanal.value-1).format("YYYY-MM-DD");
        let fecha_fin = moment().year(resource.filtro_anio_reporte_liq_semanal.value).isoWeekday(6).week(resource.filtro_semanas_reporte_liq_semanal.value).format("YYYY-MM-DD");
        let semana = resource.filtro_semanas_reporte_liq_semanal.value;
        let anio = resource.filtro_anio_reporte_liq_semanal.value;
        url_descarga += `&fecha_inicial=${fecha_inicial}&fecha_fin=${fecha_fin}&semana=${semana}&anio=${anio}`;
    }
    if (resource.filtro_fincas_reporte_liq_semanal.length > 0) {
        url_descarga += `&finca_ids=${[resource.filtro_fincas_reporte_liq_semanal]}`;
    }
    window.location.replace(url_descarga);
};

const filtroReporteLiqSemanalFincas = filtro_fincas_reporte_liq_semanal => (dispatch) => {
    dispatch({type: REPORTE_LIQ_SEMANAL_FILTRO_FINCAS, filtro_fincas_reporte_liq_semanal});
    //dispatch(dataReporteLiqSemanal());
};

const filtroReporteLiqSemanalSemana = filtro_semanas_reporte_liq_semanal => (dispatch) => {
    dispatch({type: REPORTE_LIQ_SEMANAL_FILTRO_SEMANA, filtro_semanas_reporte_liq_semanal});
    //dispatch(dataReporteLiqSemanal());
};
const filtroReporteLiqSemanalAnio = filtro_anio_reporte_liq_semanal => (dispatch) => {
    dispatch({type: REPORTE_LIQ_SEMANAL_FILTRO_ANIO, filtro_anio_reporte_liq_semanal});
    //dispatch(dataReporteLiqSemanal());
};

const setTabReporteLiqSemanal = (tab_reporte_liq_semanal) => (dispatch) => {
    dispatch({type: TAB_REPORTE_LIQ_SEMANAL, tab_reporte_liq_semanal});
};

const pageReporteLiqSemanalAnioChange = (page_reporte_liq_semanal) => (dispatch) => {
    dispatch({type: PAGE_REPORTE_LIQ_SEMANAL, page_reporte_liq_semanal})
    //dispatch(dataReporteLiqSemanal());
}

// BITACORA
const getDataBitacora = () => (dispatch, getStore) => {
    const resource = getStore().reportes;
    const params = {};
    if (resource.fecha_inicial_bitacora && resource.fecha_final_bitacora) {
        params.fecha_inicial = moment(resource.fecha_inicial_bitacora).format("YYYY-MM-DD") + ' 00:00:00';
        params.fecha_final = moment(resource.fecha_final_bitacora).format("YYYY-MM-DD") + ' 23:59:59';

        params.page = resource.page_bitacora;
        if (resource.usuario_bitacora > 0) {
            params.usuario = resource.usuario_bitacora;
        }
        resource.accion_bitacora && (params.search = resource.accion_bitacora);

        dispatch(setLoader(true));
        api.get('bitacora', params).then((response) => {
            dispatch(setData(response));
        }).catch(() => {
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
};

const getDetailBitacora = id => (dispatch, getStore) =>{
    const resource = getStore().reportes;
    const params = {};

    if (resource.fecha_inicial_bitacora && resource.fecha_final_bitacora) {
        params.fecha_inicial = moment(resource.fecha_inicial_bitacora).format("YYYY-MM-DD") + ' 00:00:00';
        params.fecha_final = moment(resource.fecha_final_bitacora).format("YYYY-MM-DD") + ' 23:59:59';

        params.page = resource.page_bitacora;

        dispatch(setLoader(true));
        api.get(`${'bitacora'}/${id}`, params).then((response) => {
            dispatch(setItem(response));
        }).catch(() => {
        }).finally(() => {
            dispatch(setLoader(false));
        });
    }
};

const filtroBitacoraFechaInicial = (fecha_inicial_bitacora) => (dispatch) => {
    dispatch({type: BITACORA_FILTRO_FECHA_INICIAL, fecha_inicial_bitacora})
//    dispatch(getDataBitacora());
}
const filtroBitacoraFechaFinal = (fecha_final_bitacora) => (dispatch) => {
    dispatch({type: BITACORA_FILTRO_FECHA_FINAL, fecha_final_bitacora})
    //dispatch(getDataBitacora());
}
const filtroBitacoraUsuarios = (usuario_bitacora) => (dispatch) => {
    dispatch({type: BITACORA_FILTRO_USUARIO, usuario_bitacora})
    //dispatch(getDataBitacora());
}
const filtroAccionBitacoraChange = (accion_bitacora) => (dispatch) => {
    dispatch({type: BITACORA_ACCION, accion_bitacora})
    //dispatch(getDataBitacora());
}
const pageBitacora = (page_bitacora) => (dispatch) => {
    dispatch({type: PAGE_BITACORA, page_bitacora})
    dispatch(getDataBitacora());
}

export const actions = {
    // Reporte de kg secos anuales.
    dataReporteKgSecosAnual,
    filtroReporteKgSecosAnualProductos,
    filtroReporteKgSecosAnualPerfilFinca,
    filtroReporteKgSecosAnualAnio,
    descargarReporteKgSecosAnual,
    setTabReporteKgSecosAnual,
    pageReporteKgSecosAnualChange,
    descargarDetalleReporteKgSecosAnual,
    // Reporte DRC anual
    dataReporteDRCAnual,
    filtroReporteDRCAnualProductos,
    filtroReporteDRCAnualPerfilFinca,
    filtroReporteDRCAnualAnio,
    descargarReporteDRCAnual,
    // Reporte Liquidaciones Semanal
    dataReporteLiqSemanal,
    filtroReporteLiqSemanalFincas,
    filtroReporteLiqSemanalSemana,
    filtroReporteLiqSemanalAnio,
    descargarReporteLiqSemanal,
    descargarDetalleReporteLiqSemanal,
    setTabReporteLiqSemanal,
    pageReporteLiqSemanalAnioChange,
    // Reporte de Materia Prima anual
    mpAnualTipoMateriaChange,
    mpAnualAnioChange,
    getDataReporteMateriaPrimaAnual,
    descargarReporteMateriaPrimaAnual,
    setTabReporteMateriaPrimaAnual,
    pageReporteMateriaPrimaAnual,
    // Reporte de Materia Prima mensual
    mpMensualAnioChange,
    mpMensualMesChange,
    getDataReporteMateriaPrimaMensual,
    descargarReporteMateriaPrimaMensual,
    setTabReporteMateriaPrimaMensual,
    pageReporteMateriaPrimaMensual,
    descargarDetalleReporteMateriaPrimaMensual,
    // BITACORA
    filtroBitacoraFechaInicial,
    filtroBitacoraFechaFinal,
    filtroBitacoraUsuarios,
    filtroAccionBitacoraChange,
    pageBitacora,
    getDataBitacora,
    getDetailBitacora,
    // Reporte de Pilas
    ...pilaAlmacenActions,
}

// -----------------------------------
// Reducers
// -----------------------------------

export const reducers = {
    [LOADER]: (state, {loader}) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, {data}) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, {item}) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, {page}) => {
        return {
            ...state,
            page,
        };
    },
    [PAGE_ACTIVAS]: (state, {page_activas}) => {
        return {
            ...state,
            page_activas,
        };
    },
    [PAGE_FINALIZADAS]: (state, {page_finalizadas}) => {
        return {
            ...state,
            page_finalizadas,
        };
    },
    [ORDERING]: (state, {ordering}) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, {search}) => {
        return {
            ...state,
            search,
        };
    },
    // Reporte Kg Secos anuales
    [REPORTE_KG_SECOS_FILTRO_ANIO]: (state, { filtro_anio_reporte_kg_secos }) => {
        return {
            ...state,
            filtro_anio_reporte_kg_secos,
        };
    },
    [REPORTE_KG_SECOS_FILTRO_PERFIL_FINCA]: (state, { filtro_perfil_finca_reporte_kg_secos }) => {
        return {
            ...state,
            filtro_perfil_finca_reporte_kg_secos,
        };
    },
    [REPORTE_KG_SECOS_FILTRO_PRODUCTOS]: (state, { filtro_productos_reporte_kg_secos }) => {
        return {
            ...state,
            filtro_productos_reporte_kg_secos,
        };
    },
    [TAB_REPORTE_KG_SECOS]: (state, {tab_reporte_kg_secos}) => {
        return {
            ...state,
            tab_reporte_kg_secos,
        };
    },
    [PAGE_REPORTE_KG_SECOS]: (state, {page_reporte_kg_secos}) => {
        return {
            ...state,
            page_reporte_kg_secos,
        };
    },
    // Reporte DRC anual
    [REPORTE_DRC_FILTRO_ANIO]: (state, { filtro_anio_reporte_drc }) => {
        return {
            ...state,
            filtro_anio_reporte_drc,
        };
    },
    [REPORTE_DRC_FILTRO_PERFIL_FINCA]: (state, { filtro_perfil_finca_reporte_drc }) => {
        return {
            ...state,
            filtro_perfil_finca_reporte_drc,
        };
    },
    [REPORTE_DRC_FILTRO_PRODUCTOS]: (state, { filtro_productos_reporte_drc }) => {
        return {
            ...state,
            filtro_productos_reporte_drc,
        };
    },
    // Reporte Liquidacion Semanal
    [REPORTE_LIQ_SEMANAL_FILTRO_ANIO]: (state, { filtro_anio_reporte_liq_semanal }) => {
        return {
            ...state,
            filtro_anio_reporte_liq_semanal,
        };
    },
    [REPORTE_LIQ_SEMANAL_FILTRO_FINCAS]: (state, { filtro_fincas_reporte_liq_semanal }) => {
        return {
            ...state,
            filtro_fincas_reporte_liq_semanal,
        };
    },
    [REPORTE_LIQ_SEMANAL_FILTRO_SEMANA]: (state, { filtro_semanas_reporte_liq_semanal }) => {
        return {
            ...state,
            filtro_semanas_reporte_liq_semanal,
        };
    },
    [TAB_REPORTE_LIQ_SEMANAL]: (state, {tab_reporte_liq_semanal}) => {
        return {
            ...state,
            tab_reporte_liq_semanal,
        };
    },
    [PAGE_REPORTE_LIQ_SEMANAL]: (state, {page_reporte_liq_semanal}) => {
        return {
            ...state,
            page_reporte_liq_semanal,
        };
    },
    // REDUCERS para el Reporte de Materia Prima anual
    [REPORTE_MP_ANUAL_FILTRO_TIPO_MATERIA]: (state, { tipo_materia }) => {
        return {
            ...state,
            reporte_mp_anual: {...state.reporte_mp_anual, tipo_materia,}
        };
    },
    [REPORTE_MP_ANUAL_FILTRO_ANIO]: (state, { anio }) => {
        return {
            ...state,
            reporte_mp_anual: {...state.reporte_mp_anual, anio,}
        };
    },
    [REPORTE_MP_ANUAL_DATA]: (state, { data }) => {
        return {
            ...state,
            reporte_mp_anual: {...state.reporte_mp_anual, data,}
        };
    },
    [TAB_REPORTE_MP_ANUAL]: (state, { tab }) => {
        return {
            ...state,
            reporte_mp_anual: {...state.reporte_mp_anual, tab,}
        };
    },
    [PAGE_REPORTE_MP_ANUAL]: (state, { page }) => {
        return {
            ...state,
            reporte_mp_anual: {...state.reporte_mp_anual, page,}
        };
    },
    // REDUCERS para el Reporte de Materia Prima mensual
    [REPORTE_MP_MENSUAL_FILTRO_MES_SELECTED]: (state, { mes }) => {
        return {
            ...state,
            reporte_mp_mensual: {...state.reporte_mp_mensual, mes,}
        };
    },
    [REPORTE_MP_MENSUAL_FILTRO_ANIO_SELECTED]: (state, { anio }) => {
        return {
            ...state,
            reporte_mp_mensual: {...state.reporte_mp_mensual, anio,}
        };
    },
    [REPORTE_MP_MENSUAL_FILTRO_FECHA_INICIAL]: (state, { fecha_inicial }) => {
        return {
            ...state,
            reporte_mp_mensual: {...state.reporte_mp_mensual, fecha_inicial,}
        };
    },
    [REPORTE_MP_MENSUAL_FILTRO_FECHA_FINAL]: (state, { fecha_final }) => {
        return {
            ...state,
            reporte_mp_mensual: {...state.reporte_mp_mensual, fecha_final,}
        };
    },
    [REPORTE_MP_MENSUAL_DATA]: (state, { data }) => {
        return {
            ...state,
            reporte_mp_mensual: {...state.reporte_mp_mensual, data,}
        };
    },
    [TAB_REPORTE_MP_MENSUAL]: (state, { tab }) => {
        return {
            ...state,
            reporte_mp_mensual: {...state.reporte_mp_mensual, tab,}
        };
    },
    [PAGE_REPORTE_MP_MENSUAL]: (state, { page }) => {
        return {
            ...state,
            reporte_mp_mensual: {...state.reporte_mp_mensual, page,}
        };
    },
    // BITACORA
    [BITACORA_FILTRO_FECHA_INICIAL]: (state, {fecha_inicial_bitacora}) => {
        return {
            ...state,
            fecha_inicial_bitacora,
        };
    },
    [BITACORA_FILTRO_FECHA_FINAL]: (state, {fecha_final_bitacora}) => {
        return {
            ...state,
            fecha_final_bitacora,
        };
    },
    [BITACORA_FILTRO_USUARIO]: (state, {usuario_bitacora}) => {
        return {
            ...state,
            usuario_bitacora,
        };
    },
    [PAGE_BITACORA]: (state, {page_bitacora}) => {
        return {
            ...state,
            page_bitacora,
        };
    },
    [BITACORA_ACCION]: (state, {accion_bitacora}) => {
        return {
            ...state,
            accion_bitacora,
        };
    },
    ...pilaAlmacenReducers,
}

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {
        results: [],
        count: 0,
    },
    page: 1,
    page_activas: 1,
    page_finalizadas: 1,
    ordering: '',
    search: '',
    // Reporte de kg secos anuales
    filtro_productos_reporte_kg_secos: [],
    filtro_perfil_finca_reporte_kg_secos: [],
    filtro_anio_reporte_kg_secos: {value: moment().year(), label: moment().year()},
    tab_reporte_kg_secos: 'DETALLE',
    page_reporte_kg_secos: 1,
    // Reporte DRC anual
    filtro_productos_reporte_drc: [],
    filtro_perfil_finca_reporte_drc: [],
    filtro_anio_reporte_drc: {value: moment().year(), label: moment().year()},
    // Reporte Liquidaciones Semanal
    filtro_fincas_reporte_liq_semanal:[],
    filtro_semanas_reporte_liq_semanal:{value: moment().week(), label: "Semana "+moment().week()},
    filtro_anio_reporte_liq_semanal:{value: moment().year(), label: moment().year()},
    tab_reporte_liq_semanal: 'DETALLE',
    page_reporte_liq_semanal: 1,
    reporte_mp_anual: {
        tipo_materia: null,
        anio: { value: moment().year(), label: moment().year() },
        data: [],
        tab: 'DETALLE',
        page: 1,
    },
    reporte_mp_mensual: {
        mes: meses.filter((item) => item.value === moment().month() + 1),
        anio: { value: moment().year(), label: moment().year() },
        fecha_inicial: moment().startOf('month'),
        fecha_final: moment().endOf('month'),
        data: [],
        tab: 'DETALLE',
        page: 1,
    },
    // BITACORA
    fecha_inicial_bitacora: moment().startOf('month'),
    fecha_final_bitacora: moment().endOf('month'),
    page_bitacora: 1,
    usuario_bitacora: -1,
    accion_bitacora: null,
    // Reporte de Pilas
    ...pilaAlmacenInitialState,
};

export default handleActions(reducers, initialState);
