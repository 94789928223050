import React, { Fragment, useEffect, useState } from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import { connect } from "react-redux";
import Grid from "../../../Utils/Grid";
import { actions } from "../../../../../redux/modules/catalogo_proveedores";
import rowExpandable from './rowExpandable';

export const TablaPropietarios = (props) => {
    const {
        page,
        data,
        listar,
    } = props;
    
    return (
        <Grid
            hover
            striped
            data={data}
            className="pb-4 pt-3"
            page={page}
            pagination={true}
            onPageChange={listar}
            expandableRow={() => true}
            expandComponent={rowExpandable}
        >
            <TableHeaderColumn
                dataField="id"
                isKey
                width="2%"
                dataFormat={(cell) => <div></div>}
            />
            <TableHeaderColumn dataField="nombre">
                PROPIETARIO
            </TableHeaderColumn>
            <TableHeaderColumn dataField="email_field">EMAIL</TableHeaderColumn>
            <TableHeaderColumn dataField="telefono_field">
                TELEFONO
            </TableHeaderColumn>
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    ...state.cdp,
});

const mapDispatchToProps = {
    ...actions,
};


export default connect(mapStateToProps, mapDispatchToProps)(TablaPropietarios);
