import React, { Fragment } from 'react';
import {Link} from 'react-router-dom';
import {Field, FieldArray, reduxForm} from 'redux-form';
import _ from 'lodash';
import {renderNumber, renderNumberKG, renderField, AsyncSelectField} from '../../../../Utils/renderField/renderField';
import {RenderKilogramo, RenderNumber} from 'Utils/renderField/renderReadField';
import Alerta from "../../../../Utils/Alerta/Alerta";
import validador from './validate';
import {connect} from 'react-redux'
import { api } from 'api';

let materiaPrimaId = -1;
let bodegasParaVaciado = [];

/**
 * Obtiene el listado de Bodegas disponibles para la materia prima especificada.
 * Para agilizar la búsqueda, convierte el Array de cubicaciones a un object
 * @param {*} search texto de búsqueda que se proporcionará al endpoint
 * @param {*} values_selected ids de las bodegas que ya están seleccionados en el formulario, para excluirlos de la lista a devolver.
 */
const getBodegasParaVaciado = (search, values_selected=[]) => {
    return api.get('bodega/get_bodegas_para_vaciado', { search, materia_id: materiaPrimaId })
        .then((response) => {
            const bodegas = response && response.results ? response.results.slice() : [];
            bodegasParaVaciado = bodegas.map(bodega => {
                bodega.cubicaciones_obj = {};
                bodega.cubicaciones.forEach(cubicacion => bodega.cubicaciones_obj[cubicacion.cm] = cubicacion.kilogramo);
                return bodega;
            });
            bodegasParaVaciado = bodegasParaVaciado.filter(bodega => !values_selected.includes(bodega.id));
            return bodegasParaVaciado;
        })
        .catch(() => []);
};

const getCanalParaVaciado = () => {
    return api.get('canal/get_canal_para_vaciado')
        .then((response) => {
            return response ? response : [];
        })
        .catch(() => []);
}

const renderPilas = ({fields, values_selected=[], setAlturaInicialVaciadoLatex}) => (
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                    <tr>
                        <th style={{width: "5%"}}/>
                        <th style={{width: "20%"}}>PILA</th>
                        <th style={{width: "15%"}}>ALTURA INICIAL</th>
                        <th style={{width: "15%"}}>ALTURA FINAL</th>
                        <th style={{width: "10%"}}>TOTAL CM</th>
                        <th style={{width: "15%"}}>PESO</th>
                        <th style={{width: "20%"}}>CAPACIDAD DISPONIBLE</th>
                    </tr>
                    </thead>
                </table>
            </div>
            {/*Contenido de la tabla*/}
            <div>
                <table className="table m-0 table-striped">
                    <tbody>
                    {fields.map((producto, index) => {
                        let todos = fields.getAll();
                        todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                        const prod_actual = fields.get(index);
                        if ((prod_actual.altura_inicial || prod_actual.altura_inicial === 0) && prod_actual.altura_final) {
                            todos[index].total_cm = prod_actual.altura_final - prod_actual.altura_inicial;
                        }
                        let peso_maximo = 0, peso_utilizado = 0;
                        if (prod_actual && prod_actual.almacen && prod_actual.almacen.cubicaciones_obj) {
                            const almacen = prod_actual.almacen;
                            peso_maximo = almacen.cubicaciones_obj[almacen.altura_maxima];
                            peso_utilizado = almacen.cubicaciones_obj[almacen.altura_utilizada];
                            // Cálculo del peso a vaciar según las cubicaciones de la pila destino
                            let altura_final = prod_actual.altura_final || 0;
                            let altura_inicial = prod_actual.altura_inicial || 0;
                            let peso_altura_final = prod_actual.almacen.cubicaciones_obj[altura_final] || 0;
                            let peso_altura_inicial = prod_actual.almacen.cubicaciones_obj[altura_inicial] || 0;
                            todos[index].peso = peso_altura_final - peso_altura_inicial;
                        }
                        return (
                            <tr key={index}>
                                <td className="text-center sin-borde-top" style={{width: "5%"}}>
                                    {index !== 0 && (
                                        <img src={require('assets/img/icons/borrar.png')} alt="Borrar"
                                            title="Borrar" className="action_img"
                                            onClick={() => {
                                                fields.remove(index)
                                            }}
                                        />
                                    )}
                                </td>
                                <td className="sin-borde-top" style={{width: "20%"}}>
                                    <Field
                                        valueKey="id"
                                        name={`${producto}.almacen`}
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField}
                                        loadOptions={(search) => getBodegasParaVaciado(search, values_selected)}
                                        onCambio={(event) => {
                                            !!setAlturaInicialVaciadoLatex && setAlturaInicialVaciadoLatex(index);
                                        }}
                                    />
                                </td>
                                <td className="sin-borde-top" style={{width: "15%"}}>
                                    <Field name={`${producto}.altura_inicial`} type="number" component={renderNumber}
                                        decimalScale={2} value={10}
                                        parse={val => parseFloat(val)} suffix=" cm"/>
                                </td>
                                <td className="sin-borde-top" style={{width: "15%"}}>
                                    <Field name={`${producto}.altura_final`} type="number" component={renderNumber}
                                        decimalScale={2}
                                        parse={val => parseFloat(val)} suffix=" cm"/>
                                </td>
                                <td style={{width: "10%"}}>
                                    <RenderNumber value={prod_actual.total_cm} suffix=" cm"/>
                                </td>
                                <td className="sin-borde-top" style={{width: "15%"}}>
                                    <h5 className={`${prod_actual.peso <= 0 ? 'rojo' : 'gris'} bold`}>
                                        <RenderKilogramo value={prod_actual.peso} decimalScale={2} />
                                    </h5>
                                    {/* <Field name={`${producto}.peso`} component={renderNumberKG}
                                           parse={val => parseFloat(val)}/> */}
                                </td>
                                <td className="sin-borde-top" style={{width: "20%"}}>
                                    <h5 className="gris bold">
                                        <RenderKilogramo value={peso_maximo - peso_utilizado}/>
                                    </h5>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-center mt-2">
                <button type="button" className="btn btn-outline-info py-0" onClick={() => fields.push({})}>
                    AGREGAR
                </button>
            </div>
        </div>
    </div>
);

const renderAlmacenes = ({fields, values_selected=[], item}) => {
    const es_recepcion_por_anulacion = item.es_recepcion_por_anulacion || false
    return(
    <div className=" col-sm-12 p-0">
        <div className=" col-sm-12 form-group np-r p-0">
            <div className="react-bs-container-header table-header-wrapper">
                <table className="table mb-0">
                    <thead>
                    <tr>
                        <th style={{width: "10%"}}/>
                        <th style={{width: "20%"}}>ALMACEN</th>
                        <th style={{width: "20%"}}>PESO</th>
                        {!es_recepcion_por_anulacion && (
                        <th style={{width: "20%"}}>CAPACIDAD DISPONIBLE</th>
                        )}
                    </tr>
                    </thead>
                </table>
            </div>
            {/*Contenido de la tabla*/}
            <div>
                <table className="table table-sm  m-0 table-striped">
                    <tbody>
                    {fields.map((producto, index) => {
                        let todos = fields.getAll();
                        todos[index]['index'] = index; //Mutar cada fila par agregarle el index
                        const bodega_actual = fields.get(index);
                        return (
                            <tr key={index}>
                                <td className="text-center sin-borde-top" style={{width: "10%"}}>
                                    {index !== 0 && (
                                        <img src={require('assets/img/icons/borrar.png')} alt="Borrar"
                                            title="Borrar" className="action_img"
                                            onClick={() => {
                                                fields.remove(index)
                                            }}
                                        />
                                    )}
                                </td>
                                <td className="sin-borde-top" style={{width: "20%"}}>
                                    <Field
                                        valueKey="id"
                                        name={`${producto}.almacen`}
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField}
                                        loadOptions={(search) => {
                                            if (es_recepcion_por_anulacion) {
                                                return getCanalParaVaciado()
                                            }
                                            else {
                                                return getBodegasParaVaciado(search, values_selected)
                                            }
                                        }}
                                        onCambio={() => {}}
                                    />
                                </td>
                                <td className="sin-borde-top" style={{width: "15%"}}>
                                    <Field name={`${producto}.peso`} type="number" component={renderNumber}
                                        decimalScale={2}
                                        parse={val => parseFloat(val)} suffix=" Kg"/>
                                </td>
                                {!es_recepcion_por_anulacion ? (
                                <td className="sin-borde-top" style={{width: "20%"}}>
                                    <h5 className="gris bold">
                                        <RenderKilogramo value={
                                            bodega_actual && bodega_actual.almacen ? bodega_actual.almacen.altura_maxima - bodega_actual.almacen.peso_total : 0
                                        }/>
                                    </h5>
                                </td>
                                ) : (<td className="sin-borde-top" style={{width: "10%"}}></td>)}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
            <div className="d-flex justify-content-center mt-2">
                <button type="button" className="btn btn-outline-info py-0" onClick={() => fields.push({})}>
                    AGREGAR
                </button>
            </div>
        </div>
    </div>
)};

let PesajeForm = props => {
    const {item, handleSubmit, datos, pilas, almacenes, configuraciones, realizarVaciado, valores, peso_manual, peso, mandarAlCanal} = props;
    const { setAlturaInicialVaciadoLatex } = props;
    datos && datos.materia_prima && datos.materia_prima.id && (materiaPrimaId = datos.materia_prima.id);
    if (peso > 0) {
        props.actualizarPesoTara(peso)
    }
    const values_selected = []; // Listado de bodegas ya seleccionadas en el formulario
    valores && valores.vaciados && valores.vaciados.forEach(vaciado => {
        const id_select = _.get(vaciado, 'almacen.id', null);
        id_select !== null && values_selected.push(id_select);
    });

    const es_recepcion_por_anulacion = item.es_recepcion_por_anulacion || false;

    let funcSendData = realizarVaciado;
    if (es_recepcion_por_anulacion) {
        funcSendData = mandarAlCanal
    }

    return (
        <form onSubmit={handleSubmit(funcSendData)}>
            <div className="d-flex justify-content-center">
                <div className="col-12 col-lg-8 ">
                    <div className="d-flex justify-content-between align-items-baseline">
                        <h6 className="mb-4">DATOS GENERALES DE RECEPCIÓN</h6>
                        <label>ID RECEPCIÓN <span className="gris text-md">{item.id}</span></label>
                    </div>
                    <div className="row col-md-12 p-0">
                        <div className="col-12 col-md-6 d-flex flex-column">
                            <label className="p-0 m-0">FINCA</label>
                            <span className="mb-1">{item && item.finca && item.finca.nombre || "---"}</span>
                            <label className="p-0 m-0">VEHÍCULO</label>
                            <span className="mb-1">{item.vehiculo.tipo_vehiculo.nombre}</span>
                            <label className="p-0 m-0">TIPO DE MATERIA</label>
                            <span className="mb-1">{item.tipo_materia.nombre}</span>
                            <label className="p-0 m-0">LUGAR</label>
                            <span className="mb-1">{item.acopio ? item.acopio.nombre : 'Planta ERSA'}</span>
                        </div>
                        <div className="col-12 col-md-6 d-flex flex-column">
                            <label className="p-0 m-0">PILOTO</label>
                            <span className="mb-1">{item.piloto.nombre}</span>
                            <label className="p-0 m-0">PLACA</label>
                            <span className="mb-1">{item.vehiculo.placa}</span>
                            <label className="p-0 m-0">PESO DE INGRESO</label>
                            <span className="mb-1">
                                <RenderKilogramo value={item.peso_ingreso}/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-baseline mt-5">
                <h6 className="mb-4 mr-5">DATOS DE PESO EN BASCULA</h6>
                <h6 className="gris bold text-uppercase">{datos.materia_prima_nombre}</h6>
            </div>
            <div className="row col-12">
                <div className="col-12 col-md-4">
                    <div className="col-12">
                        <span className="bold m-0">PESO BRUTO</span>
                    </div>
                    <div className="col-12">
                        <span className="bold gris"><RenderKilogramo value={datos.peso_bruto_temp}/></span>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="col-12">
                        <span className="bold m-0">PESO FINCA</span>
                    </div>
                    <div className="col-12">
                        <span className="bold gris"><RenderKilogramo value={datos.peso_finca_kg}/></span>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="col-12">
                        <span className="bold m-0">DIFERENCIA DE PESO</span>
                    </div>
                    <div className="col-12">
                        <span className="bold verde"><RenderKilogramo
                            value={valores.diferencia ? valores.diferencia : 0}/></span>
                    </div>
                </div>
            </div>
            <div className="row col-12 mt-4">
                <div className="col-12 col-md-4">
                    <div className="col-12">
                        <span className="bold m-0">PESO TARA</span>
                    </div>
                    <div className="col-12">
                        <Field name="peso_tara" component={renderNumberKG} disabled type="text"
                               className="form-control"/>
                    </div>
                </div>
                <div className="col-12 col-md-4">
                    <div className="col-12">
                        <span className="bold m-0">PESO NETO</span>
                    </div>
                    <div className="col-12">
                        <Field name="peso_neto" component={renderNumberKG} type="text" className="form-control"
                               disabled/>
                    </div>
                </div>
            </div>
            <div className="row col-12 d-flex flex-column align-items-center my-4">
                <span className="bold">PESO DE BÁSCULA</span>
                <div className="col-12 col-md-4">
                    {peso_manual === false && (
                        <Field name="peso_bascula" component={renderNumberKG} type="text"
                            parse={value => parseFloat(value)} className="form-control"
                            // onCambio={(value) => { props.actualizarPesoTara(peso); }}
                            disabled={!peso_manual}
                            input={{value: peso}}
                        />
                    )}
                    {peso_manual === true && (
                        <Field name="peso_bascula" component={renderNumberKG} type="text"
                            parse={value => parseFloat(value)} className="form-control"
                            onCambio={(value) => { props.actualizarPesoTara(); }}
                            disabled={!peso_manual}
                        />
                    )}
                </div>
                {/* <div className="d-flex justify-content-center mt-3">
                    <button className="btn btn-outline-info" onClick={(e) => {
                        e.preventDefault();
                        props.actualizarPesoTara()
                    }}>
                        ACTUALIZAR PESO
                    </button>
                </div> */}
            </div>
            <div className="row col-12 d-flex flex-column align-items-center">
                { peso_manual
                    ? <Alerta texto="El peso se esta tomando manualmente. Para tomarlo de báscula, vaya a Configuración."/>
                    : <Alerta texto="El peso se esta tomando automáticamente de Báscula. Para ingresarlo manualmente, vaya a Configuración."/>
                }
            </div>
            { es_recepcion_por_anulacion ? (
                <div className="row d-flex flex-column align-items-center">
                    <div className="react-bs-container-header table-header-wrapper">
                        <table className="table mb-0">
                            <thead>
                            <tr>
                                <th style={{width: "50%"}} scope="col">ALMACEN</th>
                                <th style={{width: "50%"}} scope="col">PESO ENTRADA A CANAL</th>
                            </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="tabla-con-borde w-100">
                        <table className="table  m-0 ">
                            <tbody>
                                <tr>
                                    <td style={{width: "50%"}} >CANAL DE COAGULACIÓN</td>
                                    <td style={{width: "50%"}} >
                                        <Field name="peso_neto" component={renderNumberKG} type="text" className="form-control"
                                        disabled/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            ) : (
                <Fragment>
                {datos.tipo_materia === 10 ?
                    <FieldArray name="vaciados" datos_mov={datos} component={renderPilas} opciones={pilas} values_selected={values_selected}
                                vaciados={props.vaciados} setAlturaInicialVaciadoLatex={setAlturaInicialVaciadoLatex}
                    item={item}/>
                    :
                    <FieldArray name="vaciados" datos_mov={datos} component={renderAlmacenes} opciones={almacenes} values_selected={values_selected} item={item}/>
                }
                </Fragment>
            )
            }
            <div className="row col-12 d-flex justify-content-center">
                <Field name="errorVaciados" component={renderField} type="text" disabled={true} addClass={'ocultar'}/>
            </div>
            <div className="buttons-box mt-5">
                <Link className="btn btn-outline-dark mr-5" to={`/ingreso/${datos.id_ingreso}/pesar`}>CANCELAR</Link>
                <button type="submit" className="btn btn-primary">GUARDAR</button>
            </div>
        </form>
    )
};
PesajeForm = reduxForm({
    form: 'PesajeForm',
    asyncBlurFields: [],
    initialValues: {
        vaciados: [{}]
    },
    validate: validador,
})(PesajeForm);
PesajeForm = connect(state => {
    const form = state.form.PesajeForm;
    const peso_bascula = state.recepciones;
    let peso = 0;
    let valores = {};
    let vaciados = [];
    (form && (form.values && form.values.vaciados)) && (vaciados = form.values.vaciados);
    (form && form.values) && (valores = form.values);
    // vaciados && vaciados.map(vaciado => {
    //     const altura_inicial = _.get(vaciado, 'almacen.altura_utilizada', null);
    //     altura_inicial !== null && (vaciado.altura_inicial = altura_inicial);
    //     return vaciado;
    // });

    function getNumbersInString(string) {
        var tmp = string.split("");
        var map = tmp.map(function(current) {
          if (!isNaN(parseInt(current))) {
            return current;
          }
        });
        var numbers = map.filter(function(value) {
          return value != undefined;
        });
        return numbers.join("");
    }

    if (peso_bascula.peso_bascula) {
        if (peso_bascula.peso_bascula) {
            peso = getNumbersInString(peso_bascula.peso_bascula);
            (form && (form.values)) && (form.values.peso_bascula = peso);
        }
    }
    return {
        vaciados,
        valores,
        peso
    }
})(PesajeForm);
export default PesajeForm
