import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/envioNotificaciones';
import EmailCrear from './EmailCrear';

const mstp = state => {
    return {...state.emailsbysendsReducer}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(EmailCrear)
