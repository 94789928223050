import _ from "lodash";
import Swal from 'sweetalert2';

const validador = (values, props) => {
    const errors = {};
    if (values.altura_final_origen === undefined) {
        errors.altura_final_origen = 'Campo requerido.';
    } else if (Number(values.altura_final_origen) > values.altura_inicio_origen) {
        errors.altura_final_origen = "La altura final no puede ser mayor a la altura inicial";
    } else if (Number(values.altura_final_origen) === values.altura_inicio_origen) {
        errors.altura_final_origen = "La altura final no puede igual a la altura inicial";
    } else if (Number(values.altura_final_origen) < 0) {
        errors.altura_final_origen = "La altura final no puede ser negativo";
    } else if (!!_.get(values, 'cubicaciones_obj_salida', null) && _.get(values, `cubicaciones_obj_salida.${values.altura_final_origen}`, undefined) === undefined) {
        errors.altura_final_origen = 'No existe una cubicación para la altura final';
    }

    const RecipientesArrayErrors = [];
    if (!values.recipientes || values.recipientes.length <= 0) {
        errors.errorProd = 'Se debe de agregar por lo menos un recipiente.'
    } else {
        values.recipientes.forEach((recipiente, recipienteIndex) => {
            const recipienteErrors = {};
            // if (recipiente.id) {
            //     const existe = _.find(values.recipientes, (rec) => {
            //         return parseInt(rec.id) === parseInt(recipiente.id)
            //             && rec.index !== recipienteIndex
            //     });
            //     if (existe) {
            //         recipienteErrors.id = 'El producto ya fue seleccionado';
            //         RecipientesArrayErrors[recipienteIndex] = recipienteErrors
            //     }
            // }
            if (recipiente.altura_final === undefined) {
                recipienteErrors.altura_final = 'Campo requerido';
                RecipientesArrayErrors[recipienteIndex] = recipienteErrors
            } else if (Number(recipiente.altura_final) <= recipiente.altura_inicial) {
                recipienteErrors.altura_final = "Debe ser mayor a altura inicial";
                RecipientesArrayErrors[recipienteIndex] = recipienteErrors
            } else if (Number(recipiente.altura_final) > recipiente.altura_maxima) {
                recipienteErrors.altura_final = "Excede el límite";
                RecipientesArrayErrors[recipienteIndex] = recipienteErrors
            } else if (_.get(recipiente, `cubicaciones_obj.${recipiente.altura_final}`, undefined) === undefined) {
                recipienteErrors.altura_final = 'No existe una cubicación para la altura final';
                RecipientesArrayErrors[recipienteIndex] = recipienteErrors
            }
            if (!recipiente.peso_finca) {
                recipienteErrors.peso_finca = 'Campo requerido.';
                RecipientesArrayErrors[recipienteIndex] = recipienteErrors
            }
            if (!recipiente.peso_finca_unidad) {
                recipienteErrors.peso_finca_unidad = 'Campo requerido';
                RecipientesArrayErrors[recipienteIndex] = recipienteErrors
            }
        });
        if (RecipientesArrayErrors.length) {
            errors.recipientes = RecipientesArrayErrors
        }
    }

    return errors;
};

export default validador
