import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/recepciones';
import ReporteRecepciones from './ReporteRecepciones';


const ms2p = (state) => {
  return {
    ...state.recepciones,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(ReporteRecepciones);
