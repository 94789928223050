import React, {Fragment} from 'react';
import {Field, reduxForm} from 'redux-form';
import _ from 'lodash';
import validador from './validate';
import {RenderKilogramo} from "../../../Utils/renderField/renderReadField";
import PropTypes from "prop-types";
import {AsyncSelectField, renderTextArea} from "../../../Utils/renderField/renderField";
import {api} from "../../../../../utility/api";
import { INGRESO_ANULADO, INGRESO_FINALIZADO, INGRESO_EN_ORDEN_COMPRA, INGRESO_LIQUIDADO } from '../../../../../utility/constants';

let tipos_materia_prima = [];
const getTipoMateriaPrima = (search) => {
    return api.get("tipo_materia_prima", {search}).then((data) => {
        if (data) {
            tipos_materia_prima = data.results;
            return data.results;
        } else
            return [];
    }).catch(() => {
        return [];
    });
};
const getPilotos = (search) => {
    return api.get("piloto", {search}).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};

const getFincas = (search) => {
    return api.get("finca", {search}).then((data) => {
        if (data) return data.results;
        return [];
    }).catch(() => {
        return [];
    });
};
const getVehiculos = (search) => {
    return api.get("vehiculo", {search}).then((data) => {
        if (data) {
            const datos = data.results;
            datos.forEach(dato => {
                dato.nombre = `${dato.tipo_vehiculo.nombre} ${dato.placa}`
            });
            return datos;
        }
        return [];
    }).catch(() => {
        return [];
    });
};

let DatosForm = props => {
    const {item, handleSubmit, me } = props;
    const estadosNoEditables = [INGRESO_FINALIZADO, INGRESO_ANULADO, INGRESO_EN_ORDEN_COMPRA, INGRESO_LIQUIDADO];
    const estado_ingreso = _.get(item, 'estado_ingreso', 0);
    const esNoEditable = estadosNoEditables.includes(estado_ingreso) || (!!me && me.proveedor);
    return (
        <Fragment>
            <form onSubmit={handleSubmit} className="col-12">
                <div className="row col-md-12 p-0">
                    <div className="row col-md-12 p-0">
                        <div className="col-md-6 p-0">
                            <div className="col-12">
                                <label htmlFor="finca" className="m-0">FINCA</label>
                            </div>
                            <div className="col-md-12  form-group">
                                { esNoEditable ? (
                                    <span className="ml-3">{item.finca.nombre}</span>
                                ) : (
                                    <Field
                                        valueKey="id"
                                        name="finca"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField}
                                        loadOptions={getFincas}/>
                                ) }
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-12">
                                <label htmlFor="piloto" className="m-0">PILOTO</label>
                            </div>
                            <div className="col-md-12  form-group">
                                { esNoEditable ? (
                                    <span className="ml-3">{item.piloto.nombre}</span>
                                ) : (
                                    <Field
                                        valueKey="id"
                                        name="piloto"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField}
                                        loadOptions={getPilotos}/>
                                ) }

                            </div>
                        </div>
                    </div>
                    <div className="row  col-md-12 p-0">
                        <div className="col-md-6 p-0">
                            <div className="col-12">
                                <label htmlFor="vehiculo" className="m-0">VEHÍCULO</label>
                            </div>
                            <div className="col-md-12  form-group">
                                { esNoEditable ? (
                                    <span className="ml-3">{item.vehiculo.nombre}</span>
                                ) : (
                                    <Field
                                        valueKey="id"
                                        name="vehiculo"
                                        labelKey="nombre"
                                        label="nombre"
                                        component={AsyncSelectField}
                                        loadOptions={getVehiculos}/>
                                ) }
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-12">
                                <label htmlFor="finca" className="m-0">TIPO DE MATERIA PRIMA</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <span className="ml-3">{item.tipo_materia.nombre}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row  col-md-12 p-0">
                        <div className="col-md-6 p-0">
                            <div className="col-12">
                                <label htmlFor="finca" className="m-0">PESO DE INGRESO</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <span className="ml-3"><RenderKilogramo value={item.peso_ingreso}/></span>
                            </div>
                        </div>
                        <div className="col-md-6 p-0">
                            <div className="col-12">
                                <label htmlFor="finca" className="m-0">LUGAR DE RECEPCIÓN</label>
                            </div>
                            <div className="col-md-12  form-group">
                                <span className="ml-3">{(item && item.acopio) ? item.acopio.nombre : 'Planta ERSA'}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row col-md-12 p-0">
                        <div className="col-md-12 p-0">
                            <div className="col-12">
                                <label htmlFor="observaciones" className="m-0">OBSERVACIONES</label>
                            </div>
                            <div className="col-md-12  form-group">
                                { esNoEditable ? (
                                    <div className="ml-3"><span>{item.observaciones ? item.observaciones : '--'}</span></div>
                                ) : (
                                    <Field name="observaciones" component={renderTextArea} placeholder="Observaciones" rows={2} />
                                ) }
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </Fragment>
    )
};
DatosForm.propTypes = {
    item: PropTypes.object,
};

DatosForm = reduxForm({
    form: 'DatosForm',
    asyncBlurFields: [],
    validate: validador,
})(DatosForm);

// DatosForm = connect(
//   state => ({
//     initialValues: { productos: state.recepciones.item.productos}
//   }),
// )(DatosForm);


export default DatosForm
