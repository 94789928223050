import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/perfiles/perfiles';
import PerfilesFincaList from './PerfilesFincas';


const ms2p = (state) => {
  return {
    ...state.perfiles,
  };
};

const md2p = { ...actions };

export default connect(ms2p, md2p)(PerfilesFincaList);
