import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { logOut, getMe, setProveedorValue } from "./redux/modules/cuenta/login";
import { loadConfiguracion} from "./redux/modules/configuracion";

// maquetado base
import SiderBar from './common/components/layout/Sidebar/SideBar';
import ChangePassword from './common/components/LoginRegister/ChangePassword';

import Footer from './common/components/layout/Footer/Footer';

import Navbar from "./common/components/layout/Navbar/Navbar";
import { VerifyLogin } from "./common/components/layout";


class PrivateRouteBase extends Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleOpen: true,
        };
    }

    navToggle = () => {
        this.setState({toggleOpen: !this.state.toggleOpen });
    };
    componentDidMount() {
        this.props.loadConfiguracion(false)
    }

    isAuthenticated = () => {
        const token = localStorage.getItem("token");
        const { getMe, login: { me } } = this.props;
        if (!!token && !!me.username) {
            return true;
        } else if(token) {
            getMe();
            return "Verifying"
        }
        return false;
    };

    render() {
        const { component: Component, logOut, setProveedorValue, login: { me, proveedor }, ...rest } = this.props;
        const isAuthenticated = this.isAuthenticated();
        if (me.reset_pass){
            return (
               <ChangePassword />
            )
        }
        return (
            <Route
                {...rest}
                render={props =>
                    isAuthenticated ? (
                        (isAuthenticated === true) ? (<div className="d-flex">
                            <SiderBar toggleOpen={this.state.toggleOpen} navToggle={this.navToggle} logOut={logOut} setProveedorValue={setProveedorValue} user={me}/>
                            <main className="main-content">
                                {/*<div className="main-navbar sticky-top">*/}
                                <div className="main-navbar">
                                    <div className="p-0 container-fluid">
                                        <Navbar 
                                            navToggle={this.navToggle}
                                            logOut={logOut}
                                            user={me}
                                            proveedor={proveedor}
                                            setProveedorValue={setProveedorValue}
                                            />
                                    </div>
                                </div>
                                <div className="main-content-container px-4 container-fluid">
                                    <Component {...props} />
                                </div>
                                {/*<Footer />*/}
                            </main>
                        </div>) : (
                            <VerifyLogin />
                        )
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: { from: props.location }
                            }}
                        />
                    )
                }
            />
        );
    }
}

const mstp = state => ({ ...state });

const mdtp = { logOut, setProveedorValue, getMe, loadConfiguracion };

const ProtectedRoute = connect(
    mstp,
    mdtp
)(PrivateRouteBase);

export default ProtectedRoute;

