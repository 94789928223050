import React, { Component, Fragment } from 'react'
import CardForm from 'Utils/Cards/CardForm';
import LoadMask from 'Utils/LoadMask/LoadMask';
import CardEmpty from "Utils/Cards/CardEmpty";
import {Link} from "react-router-dom";
import { RenderNumber, RenderCurrency, RenderDollar } from '../../../Utils/renderField/renderReadField';
import { PRECIO_INTROSA, PRECIO_ERSA, FLETE_EXTERNO_COSTO_POR_KG } from "../../../../../utility/constants";
import { PrintContainer, actions as printActions } from 'Utils/Print';

export default class FincaVer extends Component {
  state = {
      editar: false,
      step: 1
  };
    componentWillMount(){
        this.props.leer(this.props.match.params.id);
    }

    imprimirBoleta = (event) => {
        printActions.print('ficha_finca');
    }

    render() {
    const { crear, item, loader } = this.props;
    return (
        <div className="mb-4 col-12">
          <CardForm
              icon="configuraciones_activo"
              titulo="FINCA"
              col="12"
              subtitulo="Detalle">
              <LoadMask loading={loader} blur>
                  {item.propietario && (
                      <div className="row">
                          <div className="col-12">
                            <div className="print-only mt-0">
                                <PrintContainer name='ficha_finca'>
                                    <div className="col-12 ficha-finca">
                                        <CardEmpty noShadow>
                                            {/* DATOS GENERALES DE LA FINCA */}
                                            <div className="row col-12">
                                                <label className="p-0 m-0 gris">DATOS GENERALES DE FINCA</label>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-3 columna-finca d-flex flex-wrap flex-column">
                                                    <label className="p-0 m-0" >NOMBRE DE FINCA</label>
                                                    <span className="mb-1">{item.nombre}</span>
                                                    <label className="p-0 m-0">PROPIETARIO</label>
                                                    <span className="mb-1">{item.propietario.nombre}</span>
                                                </div>
                                                <div className="col-12 col-md-3 columna-finca d-flex flex-wrap flex-column">
                                                    <label className="p-0 m-0">CÓDIGO CLIENTE SAE</label>
                                                    <span className="mb-1">{item.codigo_cliente_sae ? item.codigo_cliente_sae : '--'}</span>
                                                    <label className="p-0 m-0">CÓDIGO PROVEEDOR SAE</label>
                                                    <span className="mb-1">{item.codigo_proveedor_sae ? item.codigo_proveedor_sae : '--'}</span>
                                                </div>
                                                <div className="col-12 col-md-3 columna-finca d-flex flex-wrap flex-column px-md-0">
                                                    <label className="p-0 m-0">CÓDIGO CLIENTE SAE (TRADING)</label>
                                                    <span className="mb-1">{item.codigo_cliente_sae_training ? item.codigo_cliente_sae_training : '--'}</span>
                                                    <label className="p-0 m-0">CÓDIGO PROVEEDOR SAE (TRADING)</label>
                                                    <span className="mb-1">{item.codigo_proveedor_sae_training ? item.codigo_proveedor_sae_training : '--'}</span>
                                                </div>
                                                <div className="col-12 col-md-3 columna-finca d-flex flex-wrap flex-column">
                                                    <label className="p-0 m-0" >RAZÓN SOCIAL</label>
                                                    <span className="mb-1">{item.razon_social}</span>
                                                    <label className="p-0 m-0">PERFIL DE FINCA</label>
                                                    <span className="mb-1">{item.perfil ? item.perfil.nombre : '--'}</span>
                                                </div>
                                            </div>
                                        </CardEmpty>
                                        <div className="mt-3"></div>
                                        <CardEmpty noShadow>
                                            {/*Datos geograficos*/}
                                            <div className="row col-12">
                                                <label className="p-0 m-0 gris">DATOS GEOGRÁFICOS DE FINCA</label>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 columna-finca d-flex flex-column">
                                                    <label className="p-0 m-0">COORDENADAS GPS LATITUD</label>
                                                    <span className="mb-1">{item.lat}</span>
                                                    <label className="p-0 m-0">ÁREA CULTIVADA</label>
                                                    <span className="mb-1"><RenderNumber value={item.area_cultivada} suffix={' ' + item.medida_area_cultivada_text + (item.area_cultivada === '1' ? '' : 's')}/></span>
                                                    <label className="p-0 m-0">MM. DE LLUVIA</label>
                                                    <span className="mb-1"><RenderNumber value={item.mm_lluvia} suffix=" mm"/></span>
                                                </div>
                                                <div className="col-12 col-md-4 columna-finca d-flex flex-column">
                                                    <label className="p-0 m-0">MUNICIPIO</label>
                                                    <span className="mb-1">{item.municipio_text}</span>
                                                    <label className="p-0 m-0">COORDENADAS GPS LONGITUD</label>
                                                    <span className="mb-1">{item.long}</span>
                                                    <label className="p-0 m-0">ÁREA TOTAL DE FINCA</label>
                                                    <span className="mb-1"><RenderNumber value={item.area_finca} suffix={' ' + item.medida_area_finca_text + (item.area_finca === '1' ? '' : 's')}/></span>
                                                </div>
                                                <div className="col-12 col-md-4 columna-finca d-flex flex-column">
                                                    <label className="p-0 m-0">DEPARTAMENTO</label>
                                                    <span className="mb-1">{item.departamento_text}</span>
                                                    <label className="p-0 m-0">DESCRIPCIÓN UBICACIÓN</label>
                                                    <span className="mb-1">{item.descripcion_ubicacion}</span>
                                                </div>
                                            </div>
                                        </CardEmpty>
                                        <div className="mt-3"></div>
                                        <CardEmpty noShadow>
                                            {/*Datos de Pago*/}
                                            <div className="row col-12">
                                                <label className="p-0 m-0 gris">DATOS DE PAGO DE FINCA</label>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-4 columna-finca d-flex flex-column">
                                                    <label className="p-0 m-0" >NOMBRE CUENTA BANCARIA</label>
                                                    <span className="mb-1">{item.nombre_cuenta}</span>
                                                    <label className="p-0 m-0">NÚMERO DE CUENTA</label>
                                                    <span className="mb-1">{item.numero_cuenta}</span>
                                                    <label className="p-0 m-0">DIRECCIÓN FISCAL</label>
                                                    <span className="mb-1">{item.direccion_fiscal}</span>
                                                </div>
                                                <div className="col-12 col-md-4 columna-finca d-flex flex-column">
                                                    <label className="p-0 m-0">RETIENE IVA</label>
                                                    <span className="mb-1">{`${item.retenedor_iva ? 'Si' : 'No'}`}</span>
                                                    <label className="p-0 m-0">BANCO</label>
                                                    <span className="mb-1">{item.banco ? item.banco.nombre : '--'}</span>
                                                    <label className="p-0 m-0">TIPO DE PAGO</label>
                                                    <span className="mb-1">{item.tipo_pago ? item.tipo_pago.nombre : '--'}</span>
                                                </div>
                                                <div className="col-12 col-md-4 columna-finca d-flex flex-column">
                                                    <label className="p-0 m-0">NIT</label>
                                                    <span className="mb-1">{item.nit}</span>
                                                    <label className="p-0 m-0">RÉGIMEN FISCAL</label>
                                                    <span className="mb-1">{item.regimen_fiscal_nombre}</span>
                                                </div>
                                            </div>
                                        </CardEmpty>
                                        <div className="mt-3"></div>
                                        <CardEmpty noShadow>
                                                <div className="row">
                                                    <div className="col-12 col-md-6 columna-finca-contactos">
                                                        <div className="row col-12">
                                                            <label className="p-0 m-0 gris">TIPO DE PRECIO LATEX</label>
                                                        </div>
                                                        {item.tipo_precio_latex === PRECIO_INTROSA && (
                                                            <div className="row">
                                                                <div className="d-flex flex-column col-6">
                                                                    <label className="p-0 m-0" >PRECIO INTROSA</label>
                                                                    <span className="mb-1">{
                                                                        parseFloat(item.precios_latex.precio_kilogramo).toFixed(4)
                                                                    }</span>
                                                                </div>
                                                                <div className="d-flex flex-column col-6">
                                                                    <label className="p-0 m-0" >DIFERENCIAL</label>
                                                                    <span className="mb-1">{
                                                                        parseFloat(item.diferencial_latex).toFixed(4)
                                                                    }</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {item.tipo_precio_latex === PRECIO_ERSA && (
                                                            <div className="d-flex flex-column">
                                                                <label className="p-0 m-0" >PRECIO ERSA</label>
                                                                <span className="mb-1">{
                                                                    parseFloat(item.precio_definido_latex).toFixed(4)
                                                                }</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-12 col-md-6 columna-finca-contactos">
                                                        <div className="row col-12">
                                                            <label className="p-0 m-0 gris">TIPO DE PRECIO SOLIDO</label>
                                                        </div>
                                                        {item.tipo_precio_solido === PRECIO_INTROSA && (
                                                            <div className="row">
                                                                <div className="d-flex flex-column col-6">
                                                                    <label className="p-0 m-0" >PRECIO INTROSA</label>
                                                                    <span className="mb-1">{
                                                                        parseFloat(item.precios_solido.precio_kilogramo).toFixed(4)
                                                                    }</span>
                                                                </div>
                                                                <div className="d-flex flex-column col-6">
                                                                    <label className="p-0 m-0" >DIFERENCIAL</label>
                                                                    <span className="mb-1">{
                                                                        parseFloat(item.diferencial_solido).toFixed(4)
                                                                    }</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {item.tipo_precio_solido === PRECIO_ERSA && (
                                                            <div className="d-flex flex-column">
                                                                <label className="p-0 m-0" >PRECIO ERSA</label>
                                                                <span className="mb-1">{
                                                                    parseFloat(item.precio_definido_solido).toFixed(4)
                                                                }</span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-6 columna-finca-contactos">
                                                        <div className="row col-12">
                                                            <label className="p-0 m-0 gris">PRECIO DE FLETE LÁTEX</label>
                                                        </div>
                                                        <div className="row">
                                                            <div className="d-flex flex-column col-6">
                                                                <label className="p-0 m-0" >AJENO AL PRODUCTOR</label>
                                                                <div className="d-flex flex-row">
                                                                    <RenderCurrency value={item.costo_flete_externo_latex} />
                                                                    <span className="mb-1 ml-2">({item.tipo_costo_flete_externo_latex === FLETE_EXTERNO_COSTO_POR_KG ? 'por kg.' : 'por flete'})</span>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-column col-6">
                                                                <label className="p-0 m-0" >PROPIO DEL PRODUCTOR</label>
                                                                <RenderDollar value={item.costo_flete_kg_interno_latex} decimalScale={3} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6 columna-finca-contactos">
                                                        <div className="row col-12">
                                                            <label className="p-0 m-0 gris">PRECIO DE FLETE SÓLIDO</label>
                                                        </div>
                                                        <div className="row">
                                                            <div className="d-flex flex-column col-6">
                                                                <label className="p-0 m-0" >AJENO AL PRODUCTOR</label>
                                                                <div className="d-flex flex-row">
                                                                    <RenderCurrency value={item.costo_flete_externo_solido} />
                                                                    <span className="mb-1 ml-2">({item.tipo_costo_flete_externo_solido === FLETE_EXTERNO_COSTO_POR_KG ? 'por kg.' : 'por flete'})</span>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex flex-column col-6">
                                                                <label className="p-0 m-0" >PROPIO DEL PRODUCTOR</label>
                                                                <RenderDollar value={item.costo_flete_kg_interno_solido} decimalScale={3} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardEmpty>
                                        <div className="mt-3"></div>
                                        <CardEmpty noShadow>
                                            <div className="row">
                                                <div className="col-12 col-md-6 columna-finca-contactos">
                                                    <div className="row col-12">
                                                        <label className="m-0 p-0 gris">DATOS DE CONTACTO DE FINCA</label>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-12 col-md-6 d-flex flex-column">
                                                            <label className="p-0 m-0" >NOMBRE ADMINISTRADOR</label>
                                                            <span className="mb-1">{item.nombre_contacto}</span>
                                                            <label className="p-0 m-0">E-MAIL ADMINISTRADOR</label>
                                                            <span className="mb-1">{item.email_contacto}</span>
                                                        </div>
                                                        <div className="col-12 col-md-6 d-flex flex-column">
                                                            <label className="p-0 m-0">TELÉFONO ADMINISTRADOR</label>
                                                            <span className="mb-1">{item.telefono_contacto}</span>
                                                            <label className="p-0 m-0">TELÉFONO FINCA</label>
                                                            <span>{item.telefono}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6 columna-finca-contactos">
                                                    <div className="row col-12">
                                                        <label className="m-0 p-0 gris">E-MAILS DE CONTACTO</label>
                                                    </div>
                                                    <div className="row">
                                                        {item.contactos.map(contacto => {
                                                        return (
                                                            <div className="col-12 col-md-6 d-flex flex-column">
                                                                <Fragment key={contacto.id}>
                                                                    <label className="p-0 m-0" >{contacto.nombre}</label>
                                                                    <span className="mb-1">{contacto.email}</span>
                                                                </Fragment>
                                                            </div>
                                                        )
                                                    })}
                                                    </div>
                                                </div>
                                            </div>
                                        </CardEmpty>
                                    </div>
                                </PrintContainer>
                            </div>
                          </div>
                      </div>
                  )}
                <div className="mb-4 col-12 p-0">
                    <div className="row mb-5">
                        <div className="col-12 row">
                            <div className="col-12 d-flex justify-content-center mt-5">
                                <div className="col-9 buttons-box">
                                    <Link className="btn btn-outline-dark mr-5" to="/fincas">SALIR</Link>
                                    <Link className="btn btn-primary mr-5" to={`/finca/${item.id}/editar`}>ACTUALIZAR</Link>
                                    <button type="submit" className="btn btn-outline-info" onClick={this.imprimirBoleta}>IMPRIMIR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </LoadMask>
          </CardForm>
      </div>
    )
  }
}
