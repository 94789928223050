import { connect } from 'react-redux';
import {actions} from '../../../../../../redux/modules/recepciones';
import EditarPeso from './EditarPesoProducto';

const mstp = state => {
    const configuraciones = state.configuracion.configuraciones;
    const me = state.login.me;
    return {
        ...state.recepciones,
        configuraciones,
        me
    }
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(EditarPeso)
