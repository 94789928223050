import React from 'react';
import CardEmpty from '../../../../Utils/Cards/CardEmpty';
import Grid from '../../../../Utils/Grid';
import {TableHeaderColumn} from "react-bootstrap-table";
import {standardActions} from '../../../../Utils/Grid/StandardActions'
import moment from "moment";

const Reporte = (props) => {
    const {
        loader, data,
        onPageChange, page
    } = props;

    return (
        <div>
            <div className="mb-4">
                <CardEmpty noShadow>
                    <div className=" col-12 form-group np-r p-0 mb-1 inicio-tabla impresion-horizontal">
                        <Grid hover striped data={data} loading={loader}
                            onPageChange={onPageChange} page={page}
                        >
                            <TableHeaderColumn
                                dataField="id"
                                dataAlign="center"
                                dataSort
                                isKey
                                width='20%'
                                dataFormat={standardActions({ ver: "bitacora"})}
                            />
                            <TableHeaderColumn
                                // dataAlign="center"
                                dataField="creado"
                                dataSort
                                width='20%'
                                dataFormat={(cell,row) => cell ? moment(cell).format('DD-MM-YYYY HH:mm:ss A') : ""}
                            >
                                FECHA
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                // dataAlign="center"
                                dataField="usuario"
                                dataSort
                                width='20%'
                                dataFormat={(cell,row) => cell && cell.nombre ? cell.nombre : ""}
                            >
                                USUARIO
                            </TableHeaderColumn>
                            <TableHeaderColumn
                                // dataAlign="center"
                                dataField="accion"
                                dataSort
                                width='40%'
                            >
                                ACCIÓN
                            </TableHeaderColumn>
                        </Grid>
                    </div>
                </CardEmpty>
            </div>
        </div>
    )
};

export default Reporte;
