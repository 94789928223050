import React, {Component} from 'react'
import Card from '../../../../Utils/Cards/Card';
import LoadMask from '../../../../Utils/LoadMask/LoadMask';
import "react-step-progress-bar/styles.css";
import Form from "./SalidaForm";

export default class PilaSalida extends Component {
    state = {
        editar: false,
        step: 1,
        open: false,
        enableOne: false
    };

    componentWillMount() {
        this.props.setTypeSalida('PILAS');
        this.props.initialLoad();
    }

    openModal = () => {
        this.setState({open: true})
    };
    closeModal = () => {
        this.setState({open: false})
    };
    crearSalida =(data)=>{
        const { crearSalida } = this.props
        if (!this.state.enableOne){
            this.setState({enableOne:true})
            crearSalida(data)
        }
    }
    render() {
        const {item, loader, setAlturaFinalOrigen} = this.props;
        return (
            <div className="mb-4 col-12">
                <Card
                    icon="bodega"
                    titulo="SALIDA"
                    col="12"
                    subtitulo={item.nombre}>
                    <LoadMask loading={loader} blur>
                        <Form onSubmit={this.crearSalida}
                              openModal={this.openModal}
                              closeModal={this.closeModal}
                              open={this.state.open} {...this.props}/>

                    </LoadMask>
                </Card>
            </div>
        )
    }
}
