import { handleActions } from "redux-actions";
import { api } from "api";
import { initialize as initializeForm } from "redux-form";
import { NotificationManager } from "react-notifications";
import { push } from "react-router-redux";
import moment from "moment";

const OBTENER_LISTA = "OBTENER_LISTA_CANAL_RECEPCIONES";
const LOADER = "LOADER_CANAL";
const SET_PAGE = "SET_PAGE_CANAL";
const OBTENER_DATA_TOTALES = "OBTENER_DATA_TOTALES";
const LOADER_TOTALES = "LOADER_TOTALES";
const ID_RECEPCION_CANAL = "ID_RECEPCION_CANAL";
const SET_RECEPCION_CANAL = "SET_RECEPCION_CANAL"
const SET_INITIAL_DATA = "SET_INITIAL_DATA";
const LOADER_FORM = "LOADER_FORM";
const SET_NO_RECEPCION = "SET_NO_RECEPCION";
const PESOS_BASCULA = "PESOS_BASCULA_CANAL";
const PESOS_BASCULA_STEP_0 = "PESOS_BASCULA_CANAL_STEP_0";
const SET_STEP_FORM = "SET_STEP_FORM";

const formInitBase = {
    id_materia: {
        id: 0,
        nombre: "",
    },
    id_almacen: {
        id: 0,
        nombre: "",
    },
    recipientes: [
        {
            id: 0,
            nombre: "",
        },
    ],
    vehiculo: null,
    piloto: null,
    finca_ingreso: null,
    peso_ingreso: 0,
    peso_tara: null,
    peso_neto: 0,
    peso_bascula: 0,
    peso_inicial: 0,
    peso_final: 0,
    peso_bascula_step_0: 0,
}

const setLoader = (loader) => ({
    type: LOADER,
    loader,
});

const setNoRecepcion = (no__recepcion) => ({
    type: SET_NO_RECEPCION,
    no__recepcion,
});

const setLoaderForm = (loader_form) => ({
    type: LOADER_FORM,
    loader_form,
});

const setInitialData = (initialValues) => ({
    type: SET_INITIAL_DATA,
    initialValues,
});

const setRecepcionCanal = recepcion_canal => ({
    type: SET_RECEPCION_CANAL,
    recepcion_canal
})

const setIdRecepcionCanal = (id_recepcion_canal) => ({
    type: ID_RECEPCION_CANAL,
    id_recepcion_canal,
});

const setData = (data) => ({
    type: OBTENER_LISTA,
    data,
});

const setLoaderTotales = (loader_totales) => ({
    type: LOADER_TOTALES,
    loader_totales,
});

const setDataTotales = (totales_data) => ({
    type: OBTENER_DATA_TOTALES,
    totales_data,
});

const setPage = (page) => ({
    type: SET_PAGE,
    page,
});

const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [OBTENER_LISTA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [SET_PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [OBTENER_DATA_TOTALES]: (state, { totales_data }) => {
        return {
            ...state,
            totales_data,
        };
    },
    [LOADER_TOTALES]: (state, { loader_totales }) => {
        return {
            ...state,
            loader_totales,
        };
    },
    [SET_RECEPCION_CANAL]: (state, { recepcion_canal }) => {
        return {
            ...state,
            recepcion_canal
        }
    },
    [ID_RECEPCION_CANAL]: (state, { id_recepcion_canal }) => {
        return {
            ...state,
            id_recepcion_canal,
        };
    },
    [SET_INITIAL_DATA]: (state, { initialValues }) => {
        return {
            ...state,
            initialValues,
        };
    },
    [LOADER_FORM]: (state, { loader_form }) => {
        return {
            ...state,
            loader_form,
        };
    },
    [SET_NO_RECEPCION]: (state, { no__recepcion }) => {
        return {
            ...state,
            no__recepcion,
        };
    },
    [PESOS_BASCULA]: (state, { peso_bascula }) => {
        return {
            ...state,
            peso_bascula,
        };
    },
    [SET_STEP_FORM]: (state, { step_form }) => {
        return {
            ...state,
            step_form,
        };
    },
    [PESOS_BASCULA_STEP_0]: (state, { peso_bascula_step_0 }) => {
        return {
            ...state,
            peso_bascula_step_0,
        };
    },
};

const initialState = {
    id_recepcion_canal: null,
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    page: 1,
    totales_data: {
        peso_total: 0,
        peso_humedo: 0,
        peso_seco: 0,
    },
    loader_totales: false,
    initialValues: formInitBase,
    recepcion_canal: {
        peso_bruto: 0
    },
    loader_form: true,
    no__recepcion: "",
    peso_bascula: 0,
    step_form: 0,
    peso_bascula_step_0: 0,
};

export const listar =
    (page = 1) =>
    (dispatch, getStore) => {
        const resource = getStore().canal;
        const params = { page };
        dispatch(setLoader(true));
        api.get("canal/get_movimientos_entrada_canal", params)
            .then((response) => {
                dispatch(setData(response));
                dispatch(setPage(page));
            })
            .catch((e) => {
                console.log(e);
            })
            .finally(() => {
                dispatch(setLoader(false));
            });
    };

export const getRecepcion = id => (dispatch, getStore) => {
    dispatch(setLoader(true))
    api.get(`canal/${id}/recepcion_boleta`)
        .then(response => {
            dispatch(setRecepcionCanal(response))
        })
        .catch(err => {
            NotificationManager.error(
                "Ocurrió un error al dar guardar los datos",
                "ERROR",
                5000
            )
        })
        .finally(() => {
            dispatch(setLoader(false))
        })
}

export const getTotals = () => (dispatch, getStore) => {
    const resource = getStore().canal;
    dispatch(setLoaderTotales(true));
    api.get("canal/get_totales_entrada_canal")
        .then((response) => {
            dispatch(setDataTotales(response));
        })
        .catch((e) => {
            console.log(e);
        })
        .finally(() => {
            dispatch(setLoaderTotales(false));
        });
};

export const getInitialValues = (id, no__recepcion) => (dispatch, getStore) => {
    dispatch(setLoaderForm(true));
    dispatch(setIdRecepcionCanal(id));
    dispatch(setNoRecepcion(no__recepcion));
    api.get(`canal/get_movimiento_entrada_canal`, { id })
        .then((response) => {
            dispatch(setInitialData(response));
            dispatch(setRecepcionCanal(response))
            dispatch(initializeForm("TableFormSalida", {...formInitBase, ...response}));
        })
        .catch((e) => {
            console.log(e);
        })
        .finally(() => {
            dispatch(setLoaderForm(false));
        });
};

export const guardarEntrada = data => (dispatch, getStore) => {
    const resource = getStore().canal
    dispatch(setLoaderForm(true))
    data["id_recepcion_canal"] = resource.id_recepcion_canal
    api.post("canal/guardar_entrada", data)
        .then(response => {
            dispatch(listar(resource.page))
            dispatch(getTotals())
            dispatch(push("/canal"))
            NotificationManager.success(
                "Registro creado exitosamente",
                "Éxito",
                5000
            )
        })
        .catch(err => {
            NotificationManager.error(
                "Ocurrió un error al dar guardar los datos",
                "ERROR",
                5000
            )
        })
        .finally(() => dispatch(setLoaderForm(false)))
}

export const salidaABodega = (data) => (dispatch, getStore) => {
    const resource = getStore().canal;
    dispatch(setLoaderForm(true));
    const id_recepcion_canal = resource.id_recepcion_canal
    data["id_recepcion_canal"] = id_recepcion_canal;
    api.post("canal/dar_salida_a_bodega", data)
        .then((response) => {
            dispatch(listar(resource.page));
            dispatch(getTotals());
            dispatch(push(`/canal/${id_recepcion_canal}/boleta_salida`));
            NotificationManager.success(
                "Registro creado exitosamente",
                "Éxito",
                5000
            );
        })
        .catch((e) => {
            console.log(e);
            NotificationManager.error(
                "Ocurrió un error al dar salida a bodega",
                "ERROR",
                5000
            );
        })
        .finally(() => {
            dispatch(setLoaderForm(false));
        });
};

const pesosBascula = () => (dispatch, getStore) => {
    const resource = getStore().canal;
    api.getPesosBascula()
        .then((response) => {
            // Si no se ha registrado un peso bruto de ingreso
            if (resource.recepcion_canal.peso_bruto === 0 || resource.recepcion_canal.peso_bruto === null) {
                // Peso ingreso
                dispatch({
                    type: PESOS_BASCULA_STEP_0,
                    peso_bascula_step_0: response.text,
                });
            }
            // Si ya se registró un peso bruto de ingreso
            else {
                // Peso tara
                dispatch({ type: PESOS_BASCULA, peso_bascula: response.text });
            }
        })
        .catch(() => {})
        .finally(() => {
            dispatch(setLoader(false));
        });
};

const setPreviousStep = () => (dispatch, getStore) => {
    const resource = getStore().canal;
    dispatch({ type: SET_STEP_FORM, step_form: resource.step_form - 1 });
};

const setNextStep = () => (dispatch, getStore) => {
    const resource = getStore().canal;
    dispatch({ type: SET_STEP_FORM, step_form: resource.step_form + 1 });
};

export const actions = {
    listar,
    getTotals,
    getInitialValues,
    salidaABodega,
    guardarEntrada,
    pesosBascula,
    setPreviousStep,
    setNextStep,
    getRecepcion
};

export default handleActions(reducers, initialState);
