import React, { Component } from 'react'
import moment from 'moment'
import { PrintContainer, actions as printActions } from 'Utils/Print'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import CardEmpty from '../../../Utils/Cards/CardEmpty'

class despachoVerBoleta extends Component {
    state = {
        fechaHoraImpresion: moment().format('DD/MM/YYYY HH:mm:ss A')
    }

    componentDidMount() {
        this.props.leer(this.props.match.params.id);
    }

    imprimirBoleta = event => {
        this.setState({
            fechaHoraImpresion: moment().format('DD/MM/YYYY HH:mm:ss A')
        }, () => printActions.print('boleta-despacho'))
    }

    render() {
        const { item } = this.props
        return (
            <React.Fragment>
                <CardEmpty>
                    <PrintContainer name='boleta-despacho'>
                        <div className="col-12 p-0">
                            <div className="row contenido-impresion verde-secundario d-flex justify-content-center">
                                <div className="col-12 row">
                                    <div className="col-12 d-flex justify-content-center mt-0 mb-3">
                                        <div className="col-11 pl-2">
                                            <div className="row col-12 p-0 mt-3 d-flex justify-content-between align-items-center">
                                                    <img
                                                        className="d-inline-block align-top"
                                                        src={require('../../../../../../assets/img/logo.png')}
                                                        alt="Logo"
                                                        height="50px"
                                                    />
                                                    <label className="p-0 m-0">ACOPIO ERSA KM. 7.5 CARRETERA A TULATE</label>
                                                    <div className="ml-4 mr-4"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center mt-0">
                                        <div className="col-11">
                                            <div className="row col-12 p-0 mt-2 justify-content-center align-items-start">
                                                <div className="col-7 p-0 fondo">
                                                    <div className="col-12 d-flex flex-column pl-0">
                                                        <div className="d-flex align-items-center">
                                                            <img src={require(`../../../../../../assets/img/icons/despachos_activo.png`)} alt="Usuario" className="title_img mr-2"/>
                                                            <img src={require('../../../../../../assets/img/icons/linea.png')} alt="Linea" className="title_img mr-2"/>
                                                            <h6 className="text-black mb-0">BOLETA DE DESPACHO</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-5 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <span className="gris">Fecha de impresión:</span>
                                                        <span className="gris">{this.state.fechaHoraImpresion}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-12 p-0 pt-1">
                                                <div className="col-7 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">CLIENTE</label>
                                                        <span className="gris">{item.cliente ? item.cliente.nombre : ''}</span>
                                                    </div>
                                                </div>
                                                <div className="col-5 p-0">
                                                    <div className="col-12 d-flex align-items-bseline">
                                                        <label className="p-0 m-0">ID DESPACHO</label>
                                                        <h5 className="gris bold pl-2">{item.id ? item.id.toString().padStart(5, 0) : ''.padStart(5, 0)}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-12 p-0 mt-2">
                                                <div className="col-7 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">FECHA DE ENTRADA DEL EQUIPO</label>
                                                        <span className="gris">{item.fecha ? moment(item.fecha).format('DD/MM/YYYY') : '--'} {item.hora ? ` - ${moment(item.hora, 'HH:mm:ss').format('HH:mm A')}` : ''}</span>
                                                    </div>
                                                </div>
                                                <div className="col-5 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">PRODUCTO</label>
                                                        <span className="gris">{item.producto ? item.producto.nombre : ''}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-12 p-0 mt-2">
                                                <div className="col-7 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">PILOTO</label>
                                                        <span className="gris">{item.piloto ? item.piloto.nombre : ''}</span>
                                                    </div>
                                                </div>
                                                <div className="col-5 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">NO. PLACA DE CABEZAL</label>
                                                        <span className="gris">{item.no_placa_cabezal ? item.no_placa_cabezal : ''}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-12 p-0 mt-2">
                                                <div className="col-7 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">CODIGO DE CONTENEDOR</label>
                                                        <span className="gris">{item.codigo_contenedor ? item.codigo_contenedor : ''}</span>
                                                    </div>
                                                </div>
                                                <div className="col-5 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">PESO DE ENTRADA</label>
                                                        <span className="gris">{item.peso_tara_equipo ? item.peso_tara_equipo + item.peso_tara_empaque : ''} kg</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-12 p-0 mt-2">
                                                <div className="col-7 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">PESO DE SALIDA</label>
                                                        <span className="gris">{item.peso_bruto_despacho ? item.peso_bruto_despacho : ''} kg</span>
                                                    </div>
                                                </div>
                                                <div className="col-5 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">PESO NETO</label>
                                                        <span className="gris">{item.peso_neto ? item.peso_neto : ''} kg</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row col-12 p-0 mt-2">
                                                <div className="col-7 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">NO. DE CARTA DE PORTE</label>
                                                        <span className="gris">{item.carta_porte}</span>
                                                    </div>
                                                </div>
                                                <div className="col-5 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <label className="p-0 m-0">LOTE</label>
                                                        <span className="gris">{item.lote}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center mt-3 p-0">
                                        <div className="col-11">
                                            <div className="row col-12 p-0 d-flex align-items-center">
                                                <div className="col-6 p-0">
                                                    <div className="col-12 d-flex flex-row align-items-end">
                                                        <div className="col-6">
                                                            <label className="p-0 m-0">FIRMA RECEPTOR</label>
                                                        </div>
                                                        <div className="col-6">
                                                            <input className="espacio-firma mb-1" type="text" readOnly />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 d-flex justify-content-center mt-5 mb-5">
                                        <div className="col-11">
                                            <div className="row col-12 p-0 mt-3">
                                                <div className="col-8 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                    </div>
                                                </div>
                                                <div className="col-4 p-0">
                                                    <div className="col-12 d-flex flex-column">
                                                        <span className="gris">ERSA-F-GO-051-00</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </PrintContainer>
                </CardEmpty>
                <div className="mb-4 col-12 p-0">
                    <div className="row mb-5">
                        <div className="col-12 row">
                            <div className="col-12 d-flex justify-content-center mt-5">
                                <div className="col-9 buttons-box">
                                    <button className="btn btn-outline-dark mr-5" onClick={() => this.props.history.goBack()}>CERRAR</button>
                                    <button type="submit" className="btn btn-primary" onClick={this.imprimirBoleta}>IMPRIMIR</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default despachoVerBoleta