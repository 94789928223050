import { createAction, handleActions } from 'redux-actions';
import { api } from "api";
import {initialize as initializeForm} from "redux-form";
import {NotificationManager} from "react-notifications";
import {push} from "react-router-redux";
import _ from "lodash";
import {reset} from 'redux-form';
import { PRECIO_INTROSA, PRECIO_ERSA } from '../../../utility/constants';

const LOADER = 'LOADER_PROVEEDORES';
const DATA = 'DATA_PROVEEDORES';
const ITEM_DATA = 'ITEM_PROVEEDORES';
const PAGE = 'PAGE_PROVEEDORES';
const ORDERING = 'ORDERING_PROVEEDORES';
const SEARCH = 'SEARCH_PROVEEDORES';

// -----------------------------------
// Pure Actions
// -----------------------------------

const setLoader = loader => ({
    type: LOADER,
    loader,
});

const setData = data => ({
    type: DATA,
    data,
});

const setItem = item => ({
    type: ITEM_DATA,
    item,
});

const setPage = page => ({
    type: PAGE,
    page,
});

const setOrdering = ordering => ({
    type: ORDERING,
    ordering,
});

const setSearch = search => ({
    type: SEARCH,
    search,
});

// ------------------------------------
// Actions
// ------------------------------------
const listar = (page = 1) => (dispatch, getStore) => {
    const resource = getStore().proveedores;
    const params = { page };
    params.ordering = resource.ordering;
    params.search = resource.search;
    params.tipo_usuario = 'proveedor';
    dispatch(setLoader(true));
    api.get('user', params).then((response) => {
        dispatch(setData(response));
        dispatch(setPage(page));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const leer = id => (dispatch) => {
    const params = {};
    params.tipo_usuario = 'proveedor';
    dispatch(setLoader(true));
    api.get(`${'user'}/${id}`, params).then((response) => {
        dispatch(setItem(response));
        dispatch(initializeForm('EditarProveedorForm', response));
    }).catch(() => {
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const crear = data => (dispatch) => {
    dispatch(setLoader(true));
    api.post('user', data).then(() => {
        NotificationManager.success('Registro creado', 'Éxito', 3000);
        dispatch(cleanForm()); // Limpiar el formulario
        dispatch(push('/proveedores'));
    }).catch((error) => {
        NotificationManager.error(error && error.detail ? error.detail : 'Error en la creación', 'ERROR');
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const editar = (data, id) => (dispatch) => {
    const params = {};
    params.tipo_usuario = 'proveedor';
    dispatch(setLoader(true));
    api.put(`${'user'}/${id}`, data, params).then(() => {
        NotificationManager.success('Registro actualizado', 'Éxito', 3000);
        dispatch(push('/proveedores'));
    }).catch((error) => {
        NotificationManager.error(error && error.detail ? error.detail : 'Error en la edición', 'ERROR', 0);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const eliminar = id => (dispatch) => {
    const params = {};
    params.tipo_usuario = 'proveedor';
    dispatch(setLoader(true));
    api.eliminar(`${'user'}/${id}`, params).then(() => {
        dispatch(listar());
        NotificationManager.success('Registro eliminado', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const desactivar = (id, data) => (dispatch) => {
    const params = {};
    params.tipo_usuario = 'proveedor';
    dispatch(setLoader(true));
    api.post(`user/${id}/desactivar`, data, params).then(() => {
        dispatch(listar());
        NotificationManager.success('Usuario Desactivada', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};
const activar = id => (dispatch) => {
    dispatch(setLoader(true));
    api.post(`user/${id}/activar`).then(() => {
        dispatch(listar());
        NotificationManager.success('Usuario Activada', 'Éxito', 3000);
    }).catch(() => {
        NotificationManager.error('Error en la transacción', 'Éxito', 3000);
    }).finally(() => {
        dispatch(setLoader(false));
    });
};

const cleanForm = () => (dispatch) => {
    dispatch(initializeForm('EditarProveedorForm', {}));
};

const searchChange = search => (dispatch) => {
    dispatch(setSearch(search));
    dispatch(listar());
};

const onSortChange = ordering => (dispatch, getStore) => {
    const sort = getStore().proveedores.ordering;
    if (ordering === sort) {
        dispatch(setOrdering(`-${ordering}`));
    } else {
        dispatch(setOrdering(ordering));
    }
    dispatch(listar());
};

export const actions = {
    listar,
    leer,
    crear,
    editar,
    eliminar,
    searchChange,
    onSortChange,
    desactivar,
    activar,
    cleanForm
};

export const reducers = {
    [LOADER]: (state, { loader }) => {
        return {
            ...state,
            loader,
        };
    },
    [DATA]: (state, { data }) => {
        return {
            ...state,
            data,
        };
    },
    [ITEM_DATA]: (state, { item }) => {
        return {
            ...state,
            item,
        };
    },
    [PAGE]: (state, { page }) => {
        return {
            ...state,
            page,
        };
    },
    [ORDERING]: (state, { ordering }) => {
        return {
            ...state,
            ordering,
        };
    },
    [SEARCH]: (state, { search }) => {
        return {
            ...state,
            search,
        };
    },
};

export const initialState = {
    loader: false,
    data: {
        results: [],
        count: 0,
    },
    item: {},
    page: 1,
    ordering: '',
    search: '',
};

export default handleActions(reducers, initialState);
