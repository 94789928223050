import React from 'react';
import CardEmpty from 'Utils/Cards/CardEmpty';
import {TableHeaderColumn} from 'react-bootstrap-table';
import moment from 'moment/moment';
import Grid from '../../../Utils/Grid';
import LoadMask from '../../../Utils/LoadMask/LoadMask';
import { RenderKilogramo, RenderCurrency, RenderNumberPercentage } from '../../../Utils/renderField/renderReadField';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceLine } from 'recharts';
import { SOLIDO, LATEX, EVAPORACION } from '../../../../../utility/constants';

const GENERAL = {};
GENERAL[SOLIDO] = 'SOLIDO';
GENERAL[LATEX] = 'LATEX';

const renderTooltip = (props) => {
    const { active, payload, label } = props;

    if (active) {
        return (
            <CardEmpty noShadow>
                <div className="d-flex flex-column pb-2">
                    <span className="mb-2">Finca: {label}</span>
                    { payload && payload.map((item, index) => {
                        return (<div key={index} className="d-flex flex-row justify-content-between">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="mr-2">{'Costo Amoniaco: '}:</span>
                                <span className="mr-3"><RenderCurrency value={
                                    item && item.payload && item.payload.costo_amoniaco ? item.payload.costo_amoniaco : 0
                                } /></span>
                            </div>
                        </div>);
                    }) }
                </div>
            </CardEmpty>
        );
    }
    return null;
};

const Reporte = (props) => {
    const {pageChange, page, loader, data,fecha_inicio_reporte, fecha_fin_reporte, pageReporteChangeExcedenteAmoniacoFinca, page_reporte_excendente_amoniaco_finca, reporte_precio_amoniaco, reporte_concentracion_amoniaco} = props;
    let mensajeError = '';
    let num_fincas = 0;
    let listaObjs = [], dataChart = [];
    let fecha_inicial = null, fecha_final = null;
    let peso_extraido = 0, peso_producido = 0, costo_amoniaco = 0;
    fecha_inicial = moment(moment(fecha_inicio_reporte).format("YYYY-MM-DD")+' 00:00:00');
    fecha_final = moment(moment(fecha_fin_reporte).format("YYYY-MM-DD")+' 00:00:00');

    if (fecha_inicial === null || fecha_final === null) {
        mensajeError = 'FECHA INICIAL O FINAL NULO';
    } else if (fecha_final.diff(fecha_inicial, 'days') < 0) {
        mensajeError = 'FILTRO DE FECHAS NO VÁLIDO';
    } else {
        // Procesamiento de la información para generar la gráfica de barras
        num_fincas = data && data.fincas_grafica ? data.fincas_grafica.length : 0;
        listaObjs = Array(num_fincas);
        // Construcción del array con los datos para el gráfico, inicialmente todos con cero
        for (let index = 0; index < listaObjs.length; index++) {
            listaObjs[index] = {
                id_finca: 0,
                finca: 'Finca '+ (index + 1),
                costo_amoniaco: 0,
            };
        }
        // Cuando la data esté realmente cargada
        const fincas = data && data.fincas_grafica ? data.fincas_grafica : [];
        const analisis_laboratorio_all = data.all_results;
        if (fincas) {
            for (let i = 0; i < fincas.length; i++) {
                if (analisis_laboratorio_all) {
                    for (let j = 0; j < analisis_laboratorio_all.length; j++) {
                        if (analisis_laboratorio_all[j].movimiento && analisis_laboratorio_all[j].movimiento.ingreso && analisis_laboratorio_all[j].movimiento.ingreso.finca) {
                            if (analisis_laboratorio_all[j].movimiento.ingreso.finca.id === fincas[i].id) {
                                listaObjs[i].costo_amoniaco += ((
                                    (((analisis_laboratorio_all[j].movimiento && analisis_laboratorio_all[j].movimiento.peso_neto ? analisis_laboratorio_all[j].movimiento.peso_neto : 0) * (analisis_laboratorio_all[j] && analisis_laboratorio_all[j].nh3 ? analisis_laboratorio_all[j].nh3 : 0))* EVAPORACION) -
                                    ((((analisis_laboratorio_all[j].movimiento && analisis_laboratorio_all[j].movimiento.peso_neto ? analisis_laboratorio_all[j].movimiento.peso_neto : 0) * reporte_concentracion_amoniaco) / 100) * EVAPORACION)
                                ) * reporte_precio_amoniaco)
                            }
                        }

                    }
                }
                listaObjs[i].id_finca = fincas[i].id;
                listaObjs[i].finca = fincas[i].nombre;
            }
        }
        listaObjs.forEach(obj => {
            const data = {
                ...obj,
                id_finca: obj.id_finca,
                finca: obj.finca,
                costo_amoniaco: obj.costo_amoniaco,
            }
            dataChart.push(data);
            costo_amoniaco+= obj.costo_amoniaco;
            // peso_producido+= obj.peso_producido;
            // drc_promedio = peso_producido / peso_extraido;
        });

        dataChart.sort(function (a, b) {
            if (a.costo_amoniaco < b.costo_amoniaco) {
              return 1;
            }
            if (a.costo_amoniaco > b.costo_amoniaco) {
              return -1;
            }
            // a must be equal to b
            return 0;
        });
    }

    const gradientOffset = () => {
        const dataMax = Math.max(...dataChart.map((i) => i.costo_amoniaco));
        const dataMin = Math.min(...dataChart.map((i) => i.costo_amoniaco));

        if (dataMax <= 0) {
          return 0;
        }
        if (dataMin >= 0) {
          return 1;
        }
        return dataMax / (dataMax - dataMin);
      };

    const off = gradientOffset();

    return(
        <div>
            <div className="mb-4">
                <CardEmpty>
                    <LoadMask loading={loader} blur noShowLoader>
                        <div className="row col-12 m-0 py-1 d-flex flex-wrap justify-content-center mt-4 mb-4">
                            <div className="col-12 col-md-12 d-flex flex-wrap justify-content-center align-items-center">
                                <div className="col-12 col-md-2 p-0 d-flex flex-row justify-content-center justify-content-md-start align-items-center">
                                    <h6 className="m-0 mb-3 mb-md-0 bold">TOTALES</h6>
                                </div>
                                <div className="col-12 row col-md-10 d-flex flex-row flex-wrap justify-content-center align-items-center p-0">
                                    <div className="col-12 col-md-8 col-sm-8 d-flex flex-row justify-content-between justify-content-md-between align-items-center">
                                        <span className="m-0 ml-0 ml-md-1 bold">COSTO DEL EXCEDENTE DE AMONIACO</span>
                                        <h3 className="mb-1 ml-2 gris" style={{whiteSpace: 'nowrap'}}>{<RenderCurrency value={costo_amoniaco} />}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </LoadMask>
                </CardEmpty>
            </div>
            <div className="mb-4">
                <CardEmpty>
                    <LoadMask loading={loader} blur>
                        <div className="d-flex flex-column">
                            <span className="recharts-legend-item-text mb-3">{"GRAFICA POR FINCAS DE EXCEDENTE DE AMONIACO"}</span>
                            <div className="flex-fill d-flex justify-content-center m-0 p-0">
                                { mensajeError.length === 0
                                    ? <ResponsiveContainer width="85%" height="100%" minWidth={500} minHeight={300}>
                                        <AreaChart width={730} height={250} data={dataChart}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="finca" />
                                            <YAxis />
                                            <Tooltip content={renderTooltip} />
                                            <defs>
                                                <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                                                    <stop offset={off} stopColor="red" stopOpacity={1} />
                                                    <stop offset={off} stopColor="#215273" stopOpacity={1} />
                                                </linearGradient>
                                            </defs>
                                            <ReferenceLine y={0} stroke="#000" />
                                            {/* <Legend content={renderLegend} align="left" verticalAlign="middle" layout="vertical" iconType="circle" title="GRÁFICA DE EXCEDENTE DE AMONIACO" /> */}
                                            <Area
                                                dataKey={`costo_amoniaco`}
                                                value={'FINCAS'}
                                                type="monotone"
                                                dot={{ stroke: '#215273'}}
                                                fill="url(#splitColor)"
                                            />
                                        </AreaChart>
                                    </ResponsiveContainer>
                                    : <div className="mb-3 rojo bold">{mensajeError}</div>
                                }
                            </div>
                        </div>
                    </LoadMask>
                </CardEmpty>
            </div>
            {/* TABLA CON LAS FINCAS QUE TIENEN INGRESOS */}
            <div className="mb-4">
                <CardEmpty>
                    {console.log("data fincas", data.fincas)}
                    <Grid hover striped loading={loader} data={data && data.fincas ? data.fincas : {"results": []}}
                        pagination onPageChange={pageReporteChangeExcedenteAmoniacoFinca} page={page_reporte_excendente_amoniaco_finca}
                        className="pb-4 pt-3"
                    >
                        <TableHeaderColumn
                            dataField="id"
                            isKey
                            hidden
                        />
                        <TableHeaderColumn width="20%"/>
                        <TableHeaderColumn
                            dataField="nombre"
                            // dataFormat={cell > cell && cell.ingreso && cell.ingreso.finca && cell.ingreso.finca.nombre ? cell.ingreso.finca.nombre : ""}
                            width="20%"
                        >
                            FINCA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="costo_amoniaco"
                            dataFormat={cell => <RenderCurrency value={cell} />}
                            dataAlign="right"
                            width="20%"
                            dataSort
                        >
                            COSTO AMONIACO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="costo_amoniaco"
                            dataFormat={cell => <RenderNumberPercentage value={costo_amoniaco ? cell/costo_amoniaco : 0} />}
                            dataAlign="right"
                            width="20%"
                            dataSort
                        >
                            PORCENTAJE DEL TOTAL
                        </TableHeaderColumn>
                        <TableHeaderColumn width="20%"/>
                    </Grid>
                </CardEmpty>
            </div>
            {/* TABLA CON LOS RESÚMENES */}
            <div className="mb-5">
                <CardEmpty>
                    <Grid hover striped loading={loader} data={data}
                        pagination onPageChange={pageChange} page={page}
                        className="pb-4 pt-3"
                    >
                        <TableHeaderColumn
                            dataField="id"
                            isKey
                            hidden
                        />
                        <TableHeaderColumn
                            dataField="movimiento"
                            dataSort
                            dataFormat={cell => cell && cell.ingreso && cell.ingreso.id ? cell.ingreso.id : ""}
                            // width="10"
                        >
                            RECEPCIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="movimiento"
                            dataSort
                            dataFormat={cell => cell && cell.ingreso.fecha_entrada ? moment(cell.ingreso.fecha_entrada).format('DD/MM/YYYY') : '--'}
                        >
                            FECHA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="movimiento"
                            dataSort
                            dataFormat={cell => cell && cell.ingreso && cell.ingreso.finca && cell.ingreso.finca.nombre ? cell.ingreso.finca.nombre : ""}
                            // width="15"
                        >
                            FINCA
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="movimiento"
                            dataSort
                            dataFormat={cell => <RenderKilogramo value={cell && cell.peso_neto ? cell.peso_neto : 0} />}
                            dataAlign="right"
                            // width="12"
                        >
                            PESO NETO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="nh3"
                            dataSort
                            dataFormat={(cell) => (cell*100).toFixed(2)}
                        >
                            NH3
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="movimiento"
                            dataSort
                            dataAlign   ="right"
                            dataFormat={(cell, row) =>
                                <RenderKilogramo value={
                                    (((cell && cell.peso_neto ? cell.peso_neto : 0) * (row && row.nh3 ? row.nh3 : 0)))
                                }/>
                            }
                        >
                            KG AMONIACO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="movimiento"
                            dataSort
                            dataFormat={(cell, row) =>
                                {return EVAPORACION}
                            }
                        >
                            EVAPORACIÓN
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="movimiento"
                            dataSort
                            dataAlign="right"
                            dataFormat={(cell, row) =>
                                <RenderKilogramo value={
                                    (((cell && cell.peso_neto ? cell.peso_neto : 0) * (row && row.nh3 ? row.nh3 : 0))) * EVAPORACION
                                }/>
                            }
                        >
                            KG REALES AMONIACO
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="movimiento"
                            dataSort
                            dataAlign="right"
                            dataFormat={(cell, row) =>
                                <RenderKilogramo value={
                                    (((cell && cell.peso_neto ? cell.peso_neto : 0) * reporte_concentracion_amoniaco) / 100) * EVAPORACION
                                }/>
                            }
                        >
                            DEBERIA TENER
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="movimiento"
                            dataSort
                            dataAlign="right"
                            dataFormat={(cell, row) =>
                                <RenderKilogramo value={(
                                    ((((cell && cell.peso_neto ? cell.peso_neto : 0) * (row && row.nh3 ? row.nh3 : 0))) * EVAPORACION) -
                                    ((((cell && cell.peso_neto ? cell.peso_neto : 0) * reporte_concentracion_amoniaco) / 100) * EVAPORACION)
                                )}/>
                            }
                        >
                            KG DE MAS
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="movimiento"
                            dataSort
                            dataAlign="right"
                            dataFormat={(cell, row) =>
                                <RenderCurrency value={reporte_precio_amoniaco}/>
                            }
                        >
                            COSTO POR KG
                        </TableHeaderColumn>
                        <TableHeaderColumn
                            dataField="movimiento"
                            dataSort
                            dataAlign="right"
                            dataFormat={(cell, row) =>
                                <RenderCurrency value={(
                                    ((((cell && cell.peso_neto ? cell.peso_neto : 0) * (row && row.nh3 ? row.nh3 : 0))) * EVAPORACION) -
                                    ((((cell && cell.peso_neto ? cell.peso_neto : 0) * reporte_concentracion_amoniaco) / 100) * EVAPORACION)
                                ) * reporte_precio_amoniaco}/>
                            }
                        >
                            COSTO PARA ERSA DE MAS
                        </TableHeaderColumn>
                    </Grid>
                </CardEmpty>
            </div>
        </div>
    )
};

export default Reporte;
