import { connect } from 'react-redux';
import {actions} from '../../../../../redux/modules/pilotos/pilotos';
import PilotoCrear from './PilotoCrear';

const mstp = state => {
    return {...state.pilotos}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(PilotoCrear)
