import React, {Fragment} from 'react';
import { Field, reduxForm } from 'redux-form';
import { validate, validators, combine } from 'validate-redux-form';
import { renderField } from '../../Utils/renderField';
import CardEmpty from 'Utils/Cards/CardEmpty';
import {Link} from 'react-router-dom';
import Alerta from 'Utils/Alerta/Alerta';

const ChangeForm = (props) => {
    const { handleSubmit } = props;
    return (
        <CardEmpty>
            <form name="loginForm" className="form-validate  d-flex flex-column flex-sm-row align-items-center mx-5" onSubmit={handleSubmit}>
                <img src={require('assets/img/logo.png')} alt="" className="logo-changepass m-3"/>
                <div className="d-flex flex-column">
                    <h2>BIENVENIDO</h2>
                    <Alerta texto="Ingrese una nueva contraseña para continuar." />
                    <div className="form-group has-feedback">
                        <label htmlFor="password">NUEVA CONTRASEÑA</label>
                        <Field name="password" component={renderField} type="password" className="form-control" />
                    </div>
                    <div className="form-group has-feedback">
                        <label htmlFor="password_confirmation">CONFIRMAR CONTRASEÑA</label>
                        <Field name="password_confirmation" component={renderField} type="password" className="form-control" />
                    </div>

                    <div className="buttons-box flex-column ml-0">
                        <button type="submit" className="btn btn-outline-primary mb-3">REGISTRAR</button>
                    </div>
                </div>
            </form>
        </CardEmpty>
    );
};

export default reduxForm({
    form: 'login', // a unique identifier for this form
    validate: (data) => {
        return validate(data, {
            password: validators.exists()('Este campo es requerido'),
            password_confirmation: combine(
                validators.exists()('Este campo es requerido'),
                validators.equals(data.password)('La contraseña no coincide')
            ),
        });
    },
})(ChangeForm);
