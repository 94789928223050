import { connect } from 'react-redux';
import { actions } from '../../../../../redux/modules/contaminantes/contaminantes';
import ContaminanteCrear from './ContaminanteCrear';

const mstp = state => {
    return {...state.contaminantes}
};

const mdtp = {
    ...actions
};

export default connect(mstp, mdtp)(ContaminanteCrear)
